import { useAppName } from "common/hooks/useAppName";
import { useTutorialSuspender } from "common/tutorial2/Tutorial";
import { AppLogoSmall } from "icons/AppLogo";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getConfig } from "utils/configure";
import { addInstallHooksCallback, mobea } from "utils/install";
import "./AndroidInstallOverlay.scss";

export interface AndroidInstallOverlayProps {
  onCancel(): void;
}

export function AndroidInstallOverlay({
  onCancel,
}: AndroidInstallOverlayProps): ReactElement | null {
  const { t } = useTranslation();

  const [opened, setOpened] = useState(!!mobea.installPrompt);

  const [installed, setInstalled] = useState(mobea.installed);

  const [insideApp, setInsideApp] = useState(mobea.isApp);

  const { iconBase64 } = getConfig();

  // run just once
  useEffect(() => {
    addInstallHooksCallback((mobea) => {
      setInsideApp(mobea.isApp);

      mobea.installPrompt && setOpened(true);

      if (mobea.installed) {
        setInstalled(true);

        setOpened(true);
      }
    });
  }, []);

  const closeInstallModal = () => {
    onCancel();

    setOpened(false);
  };

  const installApp = () => {
    if (mobea.installPrompt) {
      mobea.installPrompt.prompt();

      mobea.installPrompt.userChoice.then((result) => {
        if (result.outcome === "accepted") {
          setInstalled(true);
        } else {
          closeInstallModal();
        }

        mobea.installPrompt = null;
      });
    }
  };

  const prevent = insideApp || !opened;

  useTutorialSuspender("android-install", !prevent);

  const appName = useAppName();

  return prevent /* do not show overlay inside PWA or once we close it */ ? null : (
    <div className="mobea__android-install__overlay">
      <section className="mobea__android-install">
        <header className="mobea__android-install__header">
          <div className="mobea__android-install__header__logo">
            {iconBase64 ? (
              <img src={iconBase64} alt="app icon" />
            ) : (
              <AppLogoSmall />
            )}
          </div>
          <div className="mobea__android-install__header__text">
            <h2>
              {t("home.mobility_budget", {
                appName,
              })}
            </h2>
            <p>{window.location.hostname}</p>
          </div>
          <button
            className="mobea__android-install__close  mobea__close-button"
            onClick={closeInstallModal}
          >
            &nbsp;
          </button>
        </header>

        {installed ? (
          <a
            className="mobea__android-install__add"
            // open app and pass it the user id
            href={`${window.location.protocol}//${window.location.hostname}/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("install.open_in_app")}
          </a>
        ) : (
          <button className="mobea__android-install__add" onClick={installApp}>
            {t("install.add_to_home_screen")}
          </button>
        )}
      </section>
    </div>
  );
}
