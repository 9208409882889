import { VertsLogo } from "icons/logos/VertsLogo";
import { CommonTileProps } from "pages/providers/tiles";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Routes, TravelPassProvider } from "utils";
import { useCheckEnoughBudget } from "../dialogs/useInsufficientBudgetDialog";
import { ProviderTile, PublicProviderTileProps } from "./ProviderTile";

export function VertsTile(
  props: PublicProviderTileProps & CommonTileProps
): ReactElement {
  const { t } = useTranslation();

  const checkDeposit = useCheckEnoughBudget(
    props.provider,
    props.minPrice || 0
  );

  return (
    <ProviderTile
      {...props}
      to={
        props.type === "expense"
          ? Routes.ExpenseAdd.replace(":provider", TravelPassProvider.verts)
          : Routes.VertsOrder
      }
      kind={t("data.taxi")}
      beforeBuy={checkDeposit}
    >
      <VertsLogo />
    </ProviderTile>
  );
}
