import {
  CurrentBikeInfo,
  useCurrentBikeInfo,
} from "pages/mobit/hooks/useCurrentBikeInfo";
import { RefObject, useEffect, useRef } from "react";

const H = window.H;

export function useCurrentBike(
  icon: H.map.DomIcon,
  selectedBikeMarkersGroupRef: RefObject<H.map.Group>,
  bikeCode: string | null
) {
  const [bikeInfo] = useCurrentBikeInfo(bikeCode, true);

  const selectedBikeGroup = selectedBikeMarkersGroupRef.current;

  // const [currentBikeMarker, setCurrentBikeMarker] =
  //   useState<H.map.Marker | null>(null);
  const currentBikeMarkerRef = useRef<H.map.Marker | null>(null);

  const currentBikeInfoRef: { current: CurrentBikeInfo | null } = useRef(null);

  useEffect(() => {
    if (bikeInfo) {
      if (currentBikeMarkerRef.current) {
        currentBikeMarkerRef.current.setGeometry({
          lat: bikeInfo.lat,
          lng: bikeInfo.lng,
        });
      } else {
        currentBikeMarkerRef.current = new H.map.DomMarker(
          {
            lat: bikeInfo.lat,
            lng: bikeInfo.lng,
          },
          { icon }
        );
      }

      selectedBikeMarkersGroupRef.current?.addObject(
        currentBikeMarkerRef.current
      );
    }

    currentBikeInfoRef.current = bikeInfo;

    return () => {
      selectedBikeGroup?.removeAll();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bikeInfo]);

  return [bikeInfo, currentBikeInfoRef] as const;
}
