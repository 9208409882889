import { NestedPage, NestedPageProps } from "./NestedPage";

export function NestedPageFull(props: NestedPageProps) {
  return (
    <NestedPage
      {...props}
      css={{
        display: "flex",
        flexDirection: "column",
        "> section": {
          flex: "1 1 auto",
          display: "flex",
          flexDirection: "column",
        },
      }}
    />
  );
}
