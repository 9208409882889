import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

export function NoNetworkIcon({
  fill = "currentColor",
  className = "",
  height = "20",
  width = "20",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 88 88"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M62.3 25.7H47.7v7h14.6A11.3 11.3 0 0165.2 55l5.4 5.3a18.2 18.2 0 00-8.3-34.6zm-3.6 14.6h-8l7.3 7.4h.7v-7.4zM7.3 15.7L18.7 27a18.3 18.3 0 007 35.2h14.6v-7H25.7c-6.3 0-11.4-5-11.4-11.3 0-5.8 4.4-10.6 10.1-11.3l7.6 7.6h-2.7v7.4h10l8.4 8.3v6.3H54L68.7 77l4.6-4.6L12 11l-4.7 4.7z"
        fill={fill}
        fillRule="nonzero"
      />
    </svg>
  );
}
