import { DefaultIconProps } from "icons/props";
import { ReactElement } from "react";
import { HorizontalClassicFlagIcon } from "./HorizontalClassicFlag";

export function SlovakiaIcon(props: DefaultIconProps): ReactElement {
  return (
    <HorizontalClassicFlagIcon
      firstColor="#fff"
      secondColor="#1c448c"
      thirdColor="#d80027"
      {...props}
    />
  );
}
