import { DefaultIconProps } from "icons/props";
import { ReactElement } from "react";

export function TecLogo({
  className = "",
  width,
  height,
}: Pick<DefaultIconProps, "className" | "width" | "height">): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      clipRule="evenodd"
      fillRule="evenodd"
      viewBox="0 0 29 10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon
        fill="#E32826"
        points="9.6,9.8 11.6,0.3 16.9,0.3 16.5,2 13,2 11.7,8.2 15.2,8.2 14.9,9.8 "
      />
      <polygon fill="#FED307" points="28.9,4.3 28.4,6.3 0.1,6.3 0.6,4.3 " />
      <polygon
        fill="#E32826"
        points="6.6,9.8 8.2,2 10.4,2 10.8,0.3 4.7,0.3 4.3,2 6.4,2 4.8,9.8 "
      />
      <path
        fill="#E32826"
        d="M24,3.1c-0.6-1.2-3.1-2-5.1-0.1c-1.9,1.8-1,4.1-0.2,4.7c0.8,0.6,2.5,1.1,4.5-0.4l1.5,0.8c-2.9,2.4-5.3,2.2-7,1
	C16,8,14.7,4.8,17.6,1.9c2.9-2.9,6.5-2,7.9,0.1L24,3.1z"
      />
      <polygon fill="#E32826" points="14.9,5.7 15.4,3.6 13.6,3.6 13.2,5.7 " />
    </svg>
  );
}
