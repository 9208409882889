import { MobeaButton } from "common/forms";
import { FullScreenPage } from "common/page/FullScreenPage";
import { CircleCheckIcon } from "icons/CircleCheckIcon";
import { LoginTitle } from "pages/login/LoginTitle";
import { LogoWithLangHeader } from "pages/setPassword/LogoWithLangHeader";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Routes } from "utils";
import { AppColors } from "utils/colors";

export function SetPasswordSuccessPage() {
  const { t } = useTranslation();

  const history = useHistory();

  return (
    <FullScreenPage
      pageClassName="m_set-password"
      padding={{
        left: 32,
        right: 32,
      }}
      css={{
        "> section": {
          justifyContent: "space-between",
        },
      }}
    >
      <LogoWithLangHeader saveLanguage="onChange" />

      <div>
        <LoginTitle
          title={t("set_password_success.title")}
          text={t("set_password_success.text")}
        />

        <CircleCheckIcon
          width={80}
          height={80}
          css={{
            width: "100%",
            margin: "16px auto 30px",
            color: AppColors.PRIMARY,
          }}
        />
      </div>

      <MobeaButton onClick={() => history.replace(Routes.Default)}>
        {t("set_password_success.start")}
      </MobeaButton>
    </FullScreenPage>
  );
}
