import {
  BelgiumIcon,
  DenmarkIcon,
  FranceIcon,
  GermanyIcon,
  LuxembourgIcon,
  NetherlandsIcon,
  SlovakiaIcon,
} from "icons/flags";
import PortugesIcon from "icons/flags/PortugesIcon";
import RomaniaIcon from "icons/flags/RomaniaIcon";
import { ReactElement } from "react";
import "./CountryPhonePrefix.scss";

type Props = {
  phonePrefix: string;
};

export function CountryPhonePrefix({ phonePrefix }: Props): ReactElement {
  const getFlagIcon = () => {
    switch (phonePrefix) {
      case "+32":
        return <BelgiumIcon />;
      case "+33":
        return <FranceIcon />;
      case "+49":
        return <GermanyIcon />;
      case "+352":
        return <LuxembourgIcon />;
      case "+351":
        return <PortugesIcon />;
      case "+31":
        return <NetherlandsIcon />;
      case "+45":
        return <DenmarkIcon />;
      case "+421":
        return <SlovakiaIcon />;
      case "+40":
        return <RomaniaIcon />;
      default:
        return null;
    }
  };

  return (
    <div className="country-phone-prefix">
      {getFlagIcon()}
      <div className="country-phone-prefix__number">{phonePrefix}</div>
    </div>
  );
}
