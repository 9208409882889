import Color from "color";
import {
  ApplicationVariant,
  LOCAL_STORAGE_CONFIG_KEY,
  LOCAL_STORAGE_TRACKING_KEY,
  SUPPORTED_PHONE_PREFIXES,
} from "./constants";
import i18n from "./i18n";
import { initLogging } from "./logging";

declare global {
  interface Window {
    mobeaConfigFetch: Promise<Response | AppConfig>;
  }
}

interface RawAppConfig {
  app_icon_192: string;
  app_icon_512: string;
  app_icon_base64: string;
  app_name: Record<string, string>;
  browser_icon_32: string;
  client_id: number;
  client_name: string;
  client_name_short: string;
  cookie_policy: Record<string, string>;
  custom_font?: string;
  custom_tac: Record<string, string>;
  description: string;
  email_operations: string;
  family_font: string;
  faq: Record<string, string>;
  google_analytics_id: string;
  id: number;
  ios_icon_180: string;
  logo: string;
  phone_number: string;
  primary_color: string;
  primary_color_extra_light: string;
  primary_color_light: string;
  privacy_policy: Record<string, string>;
  secondary_color: string;
  sentry_link: string;
  slug: string;
  supported_languages: string[]; // ["en","nl","fr"]
  supported_phone_codes: string[]; // ["+33", "+31"]
  universal_app_name: string;
  use_arial: boolean;
}

export interface AppConfig {
  // reduced config fetch while logged out for login screen
  minimal: boolean;
  clientId: number;
  slug: string;
  clientName: string;
  clientNameShort: string;
  // language variants
  appNames: Record<string, string>;
  // default app name used when we do not have multi-language support
  // e.g. tab title or app manifest
  // @TODO maybe introduce languages there
  universalAppName: string;
  description: string;
  icon: string;
  iconApple: string;
  iconBase64: string;
  colors: {
    primary: string;
    primaryLight: string;
    primaryExtraLight: string;
    secondary: string;
  };
  fontName: "CustomFont" | "DaxlinePro" | "Lato";
  fontCode?: string;
  fontType?: string;
  useArial: boolean;
  clientLogo: string;
  languages: string[];
  phonePrefixes: string[];
  faqLink: Record<string, string>;
  privacyLink: Record<string, string>;
  cookiesLink: Record<string, string>;
  customTermsConditions: Record<string, string>;
  supportEmail: string;
  supportPhone: string;
  sentryLink?: string;
  googleAnalyticsId?: string;
}

let configuration: AppConfig; // TODO | undefined;

function initTracking(trackingId?: string) {
  // init tracking on load based on local settings
  let trackingAllowed;

  try {
    trackingAllowed = JSON.parse(
      window.localStorage.getItem(LOCAL_STORAGE_TRACKING_KEY) || "false"
    );
  } catch (e) {
    console.warn(e);
    trackingAllowed = false;
  }

  if (trackingAllowed) {
    window.mobeaAddTracking(trackingId);
  } else {
    window.mobeaClearTracking();
  }
}

export interface ClientConfigPersistModel {
  expiration: number;
  domain: string;
  config: AppConfig;
}

export function getConfig() {
  return configuration ?? {};
}

export function persistClientConfig() {
  // store config
  localStorage.setItem(
    LOCAL_STORAGE_CONFIG_KEY,
    JSON.stringify({
      expiration: Date.now() + 1000 * 3600 * 24 * 7,
      domain: window.location.origin,
      config: getConfig(),
    })
  );
}

export function getPersistedClientConfig(): AppConfig | null {
  const now = Date.now();

  const clientConfig: ClientConfigPersistModel = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_CONFIG_KEY) || '{"domain": ""}'
  );

  if (
    now < clientConfig.expiration &&
    clientConfig.domain === window.location.origin
  ) {
    return clientConfig.config;
  }

  return null;
}

function getCustomFontType(base64: string) {
  const match = base64.match(/data:application\/font-([^;]+);/);

  if (match === null) {
    console.warn(
      "Cannot identify custom font type - incorrectly formatted",
      base64.substring(0, 50)
    );
  }

  return (match && match[1]) || "woff2";
}

export function normalizeAppConfig(json: RawAppConfig) {
  const config: AppConfig = {
    // distinct initial config fetched before logging in from full one
    minimal: typeof json.slug === "undefined",
    clientId: json.id,
    slug: json.slug,
    clientName: json.client_name,
    clientNameShort: json.client_name_short,
    appNames: json.app_name || {},
    universalAppName: json.universal_app_name,
    description: json.description,
    clientLogo: json.logo,
    icon: json.browser_icon_32,
    iconApple: json.app_icon_192,
    iconBase64: json.app_icon_base64,
    colors: {
      primary: json.primary_color,
      primaryExtraLight: json.primary_color_extra_light,
      primaryLight: json.primary_color_light,
      secondary: json.secondary_color,
    },
    fontName: json.custom_font
      ? "CustomFont"
      : (json.family_font as "DaxlinePro" | "Lato"),
    fontCode: json.custom_font,
    fontType: json.custom_font ? getCustomFontType(json.custom_font) : "",
    useArial: !!json.use_arial,
    supportPhone: json.phone_number,
    supportEmail: json.email_operations,
    phonePrefixes: json.supported_phone_codes || [],
    faqLink: json.faq || {},
    cookiesLink: json.cookie_policy || {},
    privacyLink: json.privacy_policy || {},
    customTermsConditions: json.custom_tac || {},
    languages: json.supported_languages || [],
    googleAnalyticsId: json.google_analytics_id,
    sentryLink: json.sentry_link,
  };

  return config;
}

/**
 * https://github.com/nippur72/ifdef-loader
 * Define behaviour at the build time based on app version
 * so the app is consistent and cannot break with some bug in config
 * otherwise related component would be missing anyway
 */
let appVariant: ApplicationVariant;

                                                      
appVariant = ApplicationVariant.MOVEASY;
         
                                   
          

export const applicationVariant: ApplicationVariant = appVariant;

function rgb(color: string) {
  return Color(color).rgb().array().join();
}

export function customizeApp(config: AppConfig) {
  document.title = config.universalAppName;

  const head = document.getElementsByTagName("head")[0];

  const styleElement = document.createElement("style");

  let css = `:root {
    --rgb-primary: ${rgb(config.colors.primary)};
    --color-primary-light: ${config.colors.primaryLight};
    --color-primary-extra-light: ${config.colors.primaryExtraLight};
    --rgb-secondary: ${rgb(config.colors.secondary)};
    --color-secondary: rgb(var(--rgb-secondary));
    --font-family: ${config.fontName}, Arial;
    --font-family-secondary: ${config.useArial ? "Arial" : config.fontName};
    --font-family-numbers: ${config.useArial ? "Arial" : config.fontName};
  }`;

  if (config.fontCode) {
    css += `@font-face {
      font-family: "${config.fontName}";
      src: url(${config.fontCode}) format("${config.fontType}");
      font-style: normal;
      font-display: swap;
    }`;
  }

  styleElement.appendChild(document.createTextNode(css));

  head.appendChild(styleElement);

  const desc = document.head.querySelector("meta[name='description']");

  if (desc) {
    desc.textContent = config.description;
  }

  const icon = document.head.querySelector(
    "link[rel='icon']"
  ) as HTMLLinkElement;

  if (icon) {
    // prefer base64 variant as it's cached
    // fallback to icon url on first load before logging in
    icon.href = config.iconBase64 ? config.iconBase64 : config.icon;
  }

  const appleIcon = document.head.querySelector(
    "link[rel='apple-touch-icon']"
  ) as HTMLLinkElement;

  if (appleIcon) {
    appleIcon.href = config.iconApple;
  }

  configuration = config;

  if (!config.languages.includes(i18n.language)) {
    i18n.changeLanguage(
      navigator?.languages
        .map((lang) => lang.replace(/-.*/, ""))
        .find((lang) => config.languages.includes(lang)) ?? config.languages[0]
    );
  }

  initTracking(config.googleAnalyticsId);

  initLogging();

  return config;
}

export function getAllowedPhoneNumbers() {
  return SUPPORTED_PHONE_PREFIXES.filter((value) =>
    configuration.phonePrefixes.includes(value.code)
  );
}
