import { useEffect } from "react";
import { batch, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Auth } from "services/authTokenStore";
import { getProviders } from "services/providerService";
import {
  ProviderType,
  setProvidersAction,
  setProvidersLoadingAction,
  setProvidersTypesAction,
  TravelProvider,
} from "state/actions";
import {
  ProviderToTypeMappingType,
  TravelPassProvider,
  TravelPassType,
} from "utils/constants";

export function useProviders() {
  const history = useHistory();

  const dispatch = useDispatch();

  const loggedIn = Auth.hasToken();

  useEffect(() => {
    let canceled = false;

    async function fetchProviders() {
      try {
        dispatch(setProvidersLoadingAction(true));

        const json = await getProviders(history);

        if (!json.error) {
          const providers: TravelProvider[] = [];
          const typesMap = {} as ProviderToTypeMappingType;

          json.providers.forEach((rawProvider) => {
            if (rawProvider.status !== "ACTIVE") {
              return;
            }

            const providerType =
              rawProvider.slug.toLowerCase() as TravelPassProvider;

            const provider: Omit<TravelProvider, "type"> = {
              color: rawProvider.color,
              name: rawProvider.name,
              provider: providerType,
              minPrice: rawProvider.min_price,
              orderable: rawProvider.order_allowed,
              expensable: rawProvider.expense_allowed,
              textColor: rawProvider.second_color || rawProvider.color,
              testOrder: rawProvider.test_order_allowed,
              availableOnMap: rawProvider.available_on_map,
              types: rawProvider.types.map(
                (type) => type.toLowerCase() as TravelPassType
              ),
              // hide provider from the providers list as it serves for accepting expenses of other providers
              hidden: rawProvider.slug === "other",
            };

            if (!Object.values(TravelPassProvider).includes(providerType)) {
              console.warn(`Travel provider not recognized: ${providerType}`);
              return;
            }

            const type: ProviderType = rawProvider.order_allowed
              ? "order"
              : "expense";

            providers.push({
              ...provider,
              type,
            });

            typesMap[provider.provider] = provider.types;
          });

          const providersOnTheMapTypes = providers
            .filter((provider) => provider.availableOnMap)
            .flatMap((provider) => provider.types);
          batch(() => {
            if (!canceled) {
              dispatch(setProvidersAction(providers));
              dispatch(setProvidersLoadingAction(false));
              dispatch(
                setProvidersTypesAction(typesMap, providersOnTheMapTypes)
              );
            }
          });
        } else {
          !canceled && dispatch(setProvidersLoadingAction(false));
          console.error(
            "Failed to fetch travel providers",
            json.error_code,
            json.error_description
          );
        }
      } catch (error) {
        !canceled && dispatch(setProvidersLoadingAction(false));

        console.error("Failed to fetch travel providers", error);
      }
    }

    // do not fetch providers if user is not logged in yet
    loggedIn && fetchProviders();

    return () => {
      canceled = true;

      console.debug("Canceled hook useProviders");
    };
  }, [dispatch, history, loggedIn]);

  return [] as const;
}
