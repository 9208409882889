import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Routes } from "utils";
import {
  addNetworkChangeListener,
  getNetworkState,
  NetworkState,
  removeNetworkChangeListener,
} from "utils/network";

export function useNetwork(redirect = false) {
  const canceledRef = useRef(false);

  const history = useHistory();

  const location = useLocation();

  const [network, setNetwork] = useState(getNetworkState());

  const previousState = useRef(network);

  const updateNetworkState = useCallback(
    (state: NetworkState) => {
      if (canceledRef.current === false) {
        if (state === previousState.current) {
          return;
        }

        setNetwork(state);
        previousState.current = state;

        if (redirect && state === NetworkState.online) {
          const current = location.pathname;

          history.replace(Routes.Reload);

          window.setTimeout(() => {
            history.replace(current);
          }, 5);
        }
      }
    },
    [location, history, redirect]
  );

  useEffect(() => {
    addNetworkChangeListener(updateNetworkState);

    return () => {
      canceledRef.current = true;

      removeNetworkChangeListener(updateNetworkState);
    };
  }, [updateNetworkState]);

  return network;
}
