import styled from "@emotion/styled";
import { Carousel } from "common/carousel/Carousel";
import { useTranslation } from "react-i18next";
import { AppColors } from "utils/colors";

const Card = styled.div({
  label: "card",
  padding: "1.5rem",
  "& > svg": {
    color: AppColors.PRIMARY,
    width: "1.5rem",
    height: "1.5rem",
  },
  "& > h2": {
    fontSize: "1rem",
    color: AppColors.GRAY_400,
    lineHeight: 1.5,
    margin: "0.25rem 0 0.5rem",
  },
  "& > ul": {
    fontSize: "0.875rem",
    color: AppColors.GRAY_400,
    lineHeight: "1.25rem",
    margin: "1rem 0 0 1rem",
    padding: 0,
    paddingLeft: "1rem",
  },
});

type Props = { subStep: number; onChange(position: number): void };

export function Step2and3({ subStep, onChange }: Props) {
  const { t } = useTranslation();

  return (
    <>
      <h1>{t("moveasy_tutorial.step2_3_title")}</h1>

      <p>{t("moveasy_tutorial.step2_3_text")}</p>

      <Carousel
        position={subStep}
        onChange={onChange}
        css={{
          animation: "fadeIn 200ms",
          flexShrink: 0,
          alignSelf: "stretch",
          margin: "0.5rem 0",
          "& > div": {
            marginTop: "0.5rem", // otherwise shadow will be cut
          },
          "& .inactive, & .active": {
            borderRadius: "0.25rem",
            backgroundColor: "white",
            border: "1px solid rgba(212,212,212,0.5)",
            boxShadow: "0 4px 12px rgba(0,0,0,0.08)",
          },
        }}
        stepsPlacement="none"
        pages={[
          <Card key={1}>
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 24 24"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z"></path>
            </svg>
            <h2>{t("moveasy_tutorial.step2_3_card1_title")}</h2>
            <ul>
              <li>{t("moveasy_tutorial.step2_3_card1_item1")}</li>
              <li>{t("moveasy_tutorial.step2_3_card1_item2")}</li>
              <li>{t("moveasy_tutorial.step2_3_card1_item3")}</li>
            </ul>
          </Card>,
          // <Card key={2}>
          //   <ProfileIcon outlined />
          //   <h2>{t("moveasy_tutorial.step2_3_card2_title")}</h2>
          //   <ul>
          //     <li>{t("moveasy_tutorial.step2_3_card2_item1")}</li>
          //     <li>{t("moveasy_tutorial.step2_3_card2_item2")}</li>
          //     <li>{t("moveasy_tutorial.step2_3_card2_item3")}</li>
          //   </ul>
          // </Card>,
        ]}
      />
    </>
  );
}
