import { ReactElement, useEffect, useState } from "react";
import { getBelgiumTimezoneOffset, padWithZero } from "utils";

export interface ClockProps {
  className?: string;
}

export function Clock({ className = "" }: ClockProps): ReactElement {
  const getTime = (offsetDiff: number) => {
    const now = new Date();

    now.setMinutes(now.getMinutes() + offsetDiff);

    return [
      padWithZero(now.getHours()),
      padWithZero(now.getMinutes()),
      padWithZero(now.getSeconds()),
    ] as [string, string, string];
  };

  const offsetToBelgium =
    new Date().getTimezoneOffset() - getBelgiumTimezoneOffset();

  const [time, setTime] = useState<[string, string, string]>(
    getTime(offsetToBelgium)
  );

  useEffect(() => {
    const timer = window.setInterval(
      () => setTime(getTime(offsetToBelgium)),
      500
    );

    return () => {
      if (timer) {
        window.clearInterval(timer);
      }
    };
  }, [offsetToBelgium]);

  return (
    <div className={`mobea__clock ${className}`}>
      <span className="mobea__clock__hours">{time[0]}</span>
      <span className="mobea__clock__separator">:</span>
      <span className="mobea__clock__minutes">{time[1]}</span>
      <span className="mobea__clock__separator">:</span>
      <span className="mobea__clock__seconds">{time[2]}</span>
    </div>
  );
}
