import { ReactNode } from "react";
import { AppColors } from "utils/colors";

interface LoginTitleProps {
  title: ReactNode;
  text: ReactNode;
}

export function RegisterTitle({ title, text }: LoginTitleProps) {
  return (
    <div>
      <h1
        css={{
          fontSize: "2rem",
          fontWeight: 500,
          lineHeight: "2.25rem",
          textAlign: "center",
          margin: "0 0 24px",
        }}
      >
        {title}
      </h1>

      <p
        css={{
          fontSize: "1rem",
          lineHeight: 1.5,
          textAlign: "center",
          color: AppColors.GRAY_400,
          margin: "0 auto 32px",
        }}
      >
        {text}
      </p>
    </div>
  );
}
