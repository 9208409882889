import { BikeStation, BikeStationStatus } from "maps/bike/BikeStationTypes";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { getBlueBikeStation } from "services/blueBikeService";

export function useBlueBikeStation(stationId: string | null) {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [errorCode, setErrorCode] = useState<string | null>(null);

  const [station, setStation] = useState<BikeStation | null>(null);

  useEffect(() => {
    const loadStation = async (stationId: string) => {
      setLoading(true);

      try {
        const response = await getBlueBikeStation(stationId, history);

        if (!response.error) {
          setStation({
            id: response.id.toString(),
            status: BikeStationStatus.Open,
            availability: {
              bikes: response.bikes_available,
              slots: response.bikes_in_use,
            },
          });
        } else {
          setStation(null);

          setErrorCode(response.error_code);
        }
      } finally {
        setLoading(false);
      }
    };

    setErrorCode(null);

    if (stationId) {
      loadStation(stationId);
    } else {
      setStation(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stationId]);

  return [station, loading, errorCode] as const;
}
