import { DefaultIconProps } from "icons/props";
import { ReactElement } from "react";
import { HorizontalClassicFlagIcon } from "./HorizontalClassicFlag";

export function GermanyIcon(props: DefaultIconProps): ReactElement {
  return (
    <HorizontalClassicFlagIcon
      firstColor="#000"
      secondColor="#d80027"
      thirdColor="#ffda44"
      {...props}
    />
  );
}
