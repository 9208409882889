import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getConfig } from "utils/configure";

export function useAppName() {
  const language = useSelector((state) => state.user.language);

  const appName = useMemo(
    () => getConfig().appNames[language] || "",
    [language]
  );

  return appName;
}
