import { CancelOverlay } from "common/CancelOverlay";
import { InputGroup, InputGroupRef } from "common/forms";
import { useBooleanState, useViewportHeight } from "common/hooks";
import { Spinner } from "common/Spinner";
import { CloseIcon } from "icons/CloseIcon";
import { NavBackIcon } from "icons/NavBackIcon";
import { SearchIcon } from "icons/SearchIcon";
import { ReactElement, ReactNode, useLayoutEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { keyboardToggleAction } from "state/actions/AppUiActions";
import { AppColors } from "utils/colors";
import "./AutocompleteField.scss";

const AUTOCOMPLETE_FIELD_HEIGHT = 44;

export interface AutocompleteOption {
  id: string;
  label: string;
}

type Props = {
  className?: string;
  placeholder?: string;
  options: AutocompleteOption[];
  loading?: boolean;
  value: string;
  selectedValue?: string;
  onSearch?(): void;
  onChange(value: string): void;
  onSelect(option: AutocompleteOption): void;
  onCancel(): void;
  onBack?(): void;
  onClear?(): void;
  onFocus?: () => void;
  onBlur?: () => void;
  renderCustomOptions?(): ReactNode;
};

export function AutocompleteField({
  className,
  placeholder,
  options,
  loading = false,
  value,
  selectedValue,
  onSearch,
  onChange,
  onSelect,
  onCancel,
  onBack,
  onClear,
  onFocus,
  onBlur,
  renderCustomOptions,
}: Props): ReactElement {
  const dispatch = useDispatch();

  const viewportHeight = useViewportHeight();

  const [focused, setFocused, unsetFocused] = useBooleanState(false);

  const inputRef = useRef<InputGroupRef>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);

  const toOption = (option: AutocompleteOption) => (
    <div
      key={option.id}
      className="option"
      onMouseDown={(e) => e.preventDefault()}
      onClick={(e) => {
        e.currentTarget.focus();

        onSelect(option);
      }}
      tabIndex={0}
    >
      {option.label}
    </div>
  );

  const clearValue = () => {
    onChange("");

    inputRef.current?.focus();
  };

  const focus = () => {
    onFocus?.();

    setFocused();
    dispatch(keyboardToggleAction(true));
  };

  const blur = () => {
    onBlur?.();

    unsetFocused();
    // wait for keyboard to hide
    setTimeout(() => {
      dispatch(keyboardToggleAction(false));
    }, 200);
  };

  const clear = () => {
    onClear?.();
    inputRef.current?.focus();
  };

  const renderDefaultOptions = () => {
    if (value) {
      return (
        <div className="location-provider-options">{options.map(toOption)}</div>
      );
    }
    return null;
  };

  useLayoutEffect(() => {
    const wrapperEl = optionsRef.current;

    let y = 0;
    let moved = 0;
    let wrapperHeight;
    let height;

    const setHeights = () => {
      const scrollableEl = wrapperEl?.firstElementChild as HTMLElement;

      // restart movement calculation
      y = 0;

      if (wrapperEl && scrollableEl) {
        wrapperHeight = parseFloat(getComputedStyle(wrapperEl).height);
        height = parseFloat(getComputedStyle(scrollableEl).height);
      }
    };

    const moveContent = (e: TouchEvent) => {
      const scrollableEl = wrapperEl?.firstElementChild as HTMLElement;

      e.preventDefault();
      e.stopImmediatePropagation();

      if (y !== 0) {
        const movement = e.touches[0].clientY - y;

        if (scrollableEl) {
          // keep content inside view to prevent overscroll
          moved = Math.max(
            Math.min(0, moved + movement),
            wrapperHeight - height
          );

          scrollableEl.style.transform = `translateY(${moved}px)`;
        }
      }
      y = e.touches[0].clientY;
    };

    if (wrapperEl) {
      wrapperEl.addEventListener("touchstart", setHeights, {
        passive: false,
        capture: true,
      });

      wrapperEl.addEventListener("touchmove", moveContent, {
        passive: false,
        capture: true,
      });
    }

    return () => {
      if (wrapperEl) {
        wrapperEl.removeEventListener("touchstart", setHeights);
        wrapperEl.removeEventListener("touchmove", moveContent);
      }
    };
  }, [focused, value]);

  return (
    <div
      className={`mobea-autocomplete-field ${className} ${
        onBack ? "back-visible" : ""
      } ${onClear ? "clear-visible" : ""}`}
      ref={wrapperRef}
    >
      {focused && <CancelOverlay onCancel={onCancel} />}

      <InputGroup
        ref={inputRef}
        name="autocomplete"
        label=""
        placeholder={placeholder}
        value={selectedValue || value}
        onChange={(value) => onChange(value.toString())}
        inputAttributes={{
          autoComplete: "off",
          onFocus: focus,
          onBlur: blur,
        }}
      />

      {onBack && (
        <NavBackIcon
          className="back-icon"
          fill={AppColors.GRAY_200}
          onClick={onBack}
        />
      )}

      <div className="icon-container">
        {loading && <Spinner size={14} />}

        {(!value || !focused) && (
          <SearchIcon className="icon-container__icon" onClick={onSearch} />
        )}

        {value && focused && !onClear && (
          <CloseIcon
            className="icon-container__icon clear-icon"
            onClick={clearValue}
          />
        )}

        {(!value || !focused) && onClear && <div className="icon-separator" />}

        {onClear && (
          <CloseIcon
            className="icon-container__icon clear-icon"
            onClick={clear}
          />
        )}
      </div>

      <div
        className="mobea-autocomplete-field__options"
        css={{
          // 16px is vertical padding
          maxHeight: viewportHeight
            ? viewportHeight - AUTOCOMPLETE_FIELD_HEIGHT - 2 * 16
            : "50vh",
          opacity: focused ? 1 : 0,
          transition: "opacity 0.2s",
        }}
        ref={optionsRef}
      >
        {focused && renderCustomOptions
          ? renderCustomOptions()
          : renderDefaultOptions()}
      </div>
    </div>
  );
}
