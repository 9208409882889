/**
 * We need to add listeners after first load before events are fired
 * Cannot happen inside app as at that time they might be already fired
 * Especially when user navigates inside app (e.g. onboarding)
 */

type AndroidInstallPrompt = {
  prompt(): void;
  userChoice: Promise<{
    outcome: "accepted";
    platform: "web";
  }>;
};

export const mobea = {
  installPrompt: null as AndroidInstallPrompt | null,
  installed: false,
  isApp: false,
  isAndroid: /(android)/i.test(window.navigator.userAgent),
  isIos: /iPad|iPhone|iPod/.test(window.navigator.userAgent),
  isSafari:
    /Safari\//.test(window.navigator.userAgent) &&
    !/CriOS\//.test(window.navigator.userAgent),
};

export type MobeaGlobal = typeof mobea;

const lifecycleHookCallbacks: ((mobea: MobeaGlobal) => void)[] = [];

export function addInstallHooksCallback(cb: (mobea: MobeaGlobal) => void) {
  lifecycleHookCallbacks.push(cb);
}

/**
 * Execute callback stack
 */
const runInstallCallbacks = () => {
  lifecycleHookCallbacks.forEach((cb) => cb(mobea));
};

export function getEnvironment() {
  // iOS
  if (window.navigator["standalone"]) {
    return "app";
    // Android
  } else if (window.matchMedia("(display-mode: standalone)").matches) {
    return "app";
    // Browser
  } else {
    return "browser";
  }
}

export function addInstalLifecycleHooks() {
  window.addEventListener("beforeinstallprompt", (event) => {
    // Prevent the mini-infobar from appearing on mobile
    event.preventDefault();

    mobea.installPrompt = event as any;

    runInstallCallbacks();
  });

  window.addEventListener("appinstalled", () => {
    // open overlay and ask user to use app
    mobea.installed = true;

    runInstallCallbacks();
  });

  window.addEventListener("load", () => {
    mobea.isApp = getEnvironment() === "app";

    runInstallCallbacks();
  });
}
