import { ReactElement } from "react";
import { TravelPassType } from "utils";
import { TravelTypeIcon } from "./TravelTypeIcon";

type Props = {
  color?: string;
  width?: number;
  height?: number;
  transportType: TravelPassType;
};

export function TravelType({
  color,
  width,
  height,
  transportType,
}: Props): ReactElement {
  return (
    <div
      className={`mobea__travel-pass__header__type mobea__transport-type__${transportType}`}
      style={{ backgroundColor: color }}
    >
      <TravelTypeIcon width={width} height={height} type={transportType} />
    </div>
  );
}
