import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

export function CalendarIcon({
  fill = "currentColor",
  className = "",
  height = "24",
  width = "24",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 22c.5 0 1-.2 1.4-.6.4-.4.6-.9.6-1.4V6c0-.5-.2-1-.6-1.4A2 2 0 0019 4h-1V2h-2v2H8V2H6v2H5a2 2 0 00-1.4.6A2 2 0 003 6v14c0 .5.2 1 .6 1.4.4.4.9.6 1.4.6h14zm0-2H5V9h14v11zm-2-2v-5h-5v5h5z"
        fill={fill}
      />
    </svg>
  );
}
