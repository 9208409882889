import { ReactElement } from "react";
import { AppColors } from "utils/colors";

interface Props {
  on: boolean;
  width?: string;
  height?: string;
}

export function ToggleIcon({
  on,
  width = "68",
  height = "32",
}: Props): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 68 38"
      width={width}
      height={height}
    >
      <defs>
        <filter
          x="-20%"
          y="-20%"
          width="140%"
          height="140%"
          filterUnits="objectBoundingBox"
          id="a"
        >
          <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            stdDeviation="2"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.199501812 0"
            in="shadowBlurOuter1"
          />
        </filter>
      </defs>

      <g transform="translate(3 3)" fillRule="nonzero" fill="none">
        <rect
          stroke={on ? AppColors.PRIMARY : AppColors.GRAY_100}
          strokeWidth="2"
          fill={on ? AppColors.PRIMARY : AppColors.BACKGROUND}
          width="64"
          height="32"
          rx="16"
        />
        <rect
          x={on ? "33" : "1"}
          y="1"
          width="30"
          height="30"
          rx="15"
          fill="#000"
          filter="url(#a)"
        />
        <rect
          x={on ? "33" : "1"}
          y="1"
          width="30"
          height="30"
          rx="15"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
