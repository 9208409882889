import { padWithZero } from "./helpers";

// based on https://stackoverflow.com/a/36146278
export function getBelgiumTimezoneOffset() {
  const date = new Date();

  try {
    const base: any[] = date
      .toLocaleString("ja", { timeZone: "Europe/Brussels" })
      .split(/[/\s:]/);
    base[1]--;

    const utc = Date.UTC.apply(null, base as any);

    const timzeoneDate = new Date(date).setMilliseconds(0);

    return (timzeoneDate - utc) / 60 / 1000;
  } catch (e) {
    // FF on Android fallback (asume we are anyway in BE)
    return new Date().getTimezoneOffset();
  }
}

export function getTimezoneOffsetAsIsoString() {
  // offset in minutes (negative)
  const offset = getBelgiumTimezoneOffset();

  const sign = offset > 0 ? "-" : "+";

  const hours = padWithZero(Math.ceil(Math.abs(offset / 60)));

  const minutes = padWithZero(Math.ceil(Math.abs(offset % 60)));

  const formattedIsoTimezone = `${sign}${hours}:${minutes}`;

  return formattedIsoTimezone;
}

export function getEndOfDay(milis = Date.now()) {
  const d = new Date(milis);

  d.setHours(23);

  d.setMinutes(59);

  d.setSeconds(59);

  d.setMilliseconds(0);

  return d.valueOf();
}

export function formatDateToNmbsFormat(milis: number) {
  const d = new Date(milis);

  d.setHours(d.getTimezoneOffset() / -60);

  d.setMinutes(0);

  d.setSeconds(0);

  d.setMilliseconds(0);

  return d.toISOString().replace(".000Z", "");
}

/**
 * Fix time string from server sent in Belgian timezone but without timezone data
 */
export function addTimezoneToServerTime(timestring: string) {
  const offset = getTimezoneOffsetAsIsoString();

  const containsTimezone = /GMT|\+0[0-9]:00/;

  if (containsTimezone.test(timestring)) {
    return new Date(timestring);
  }

  return new Date(timestring + offset);
}

export function splitHoursMinutes(minutes: number): [number, number] {
  return [Math.floor(minutes / 60), Math.ceil(minutes % 60)];
}
