import { ReactElement } from "react";
import "./DisabledOverlay.scss";

export type DisabledOverlayProps = {
  active?: boolean;
};

export function DisabledOverlay({
  active = true,
}: DisabledOverlayProps): ReactElement | null {
  return active ? <div className="mobea__disabled-overlay" /> : null;
}
