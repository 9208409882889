import { useCurrentTicket } from "common/hooks/useCurrentTicket";
import { MobeaModal } from "common/modal";
import { NestedPage } from "common/page/NestedPage";
import { Spinner } from "common/Spinner";
import { NmbsTravelPass } from "common/travelPasses";
import { InfoIcon } from "icons/InfoIcon";
import { PhoneTicketIcon } from "icons/PhoneTicketIcon";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { NmbsTravelPass as NmbsTravelPassType } from "state/actions";
import { pushLocation, Routes } from "utils";
import { AppColors } from "utils/colors";
import { BackButtonBehaviour } from "utils/routing";
import "./NmbsTicketDetail.scss";

export interface NmbsTicketDetailProps {
  backButtonBehaviour?: BackButtonBehaviour;
}

export function NmbsTicketDetail({
  backButtonBehaviour = "goHome",
}: NmbsTicketDetailProps): ReactElement {
  const history = useHistory();

  const { id = "" } = useParams<{ id: string }>();

  const { t } = useTranslation();

  const [infoOpened, setInfoOpened] = useState(false);

  const locale = useSelector((state) => state.user.language);

  const [currentPass, loadingFailed, loadingDetail] =
    useCurrentTicket<NmbsTravelPassType>(id, (pass) => !pass.barCode);

  const showInfo = () => setInfoOpened(true);

  const hideInfo = () => setInfoOpened(false);

  const goBack = () => {
    if (backButtonBehaviour === "goHome") {
      pushLocation(history, Routes.Home);
    } else {
      history.goBack();
    }
  };

  return (
    <NestedPage
      pageClassName="mobea-ticket-detail"
      title={
        <>
          {t("ticked_detail.my_ticket")}
          <div className="mobea-ticket-detail__info" onClick={showInfo}>
            <InfoIcon />
          </div>
        </>
      }
      headerHeight="compact"
      background="default"
      css={{
        padding: "32px 16px 16px",
      }}
      onNavBack={goBack}
    >
      {loadingDetail && <Spinner />}

      {!loadingDetail && currentPass && (
        <NmbsTravelPass {...currentPass} locale={locale} />
      )}

      {!loadingDetail && loadingFailed && !currentPass && (
        <h2>Sorry cannot find travel pass.</h2>
      )}

      {infoOpened && (
        <MobeaModal
          title={t("ticked_detail.my_ticket")}
          confirmText={t("shared.got_it")}
          image={<PhoneTicketIcon fill={AppColors.GRAY_100} />}
          onConfirm={hideInfo}
        >
          <p>{t("ticked_detail.my_ticket_info_text")}</p>
        </MobeaModal>
      )}
    </NestedPage>
  );
}
