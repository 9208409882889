import { History } from "history";
import {
  ADMIN_API_BASE,
  ApiErrorResponse,
  TravelPassProvider,
  TravelPassType,
} from "utils";
import { API_ERROR_RESPONSE_CODES, callSecuredApiEndpoint } from "./apiCall";
import { fetchTimeoutIncident } from "./fetchTimeout";

export interface RawProviderData {
  color: string;
  id: number;
  name: string;
  expense_allowed: boolean;
  order_allowed: boolean;
  test_order_allowed: boolean;
  available_on_map: boolean;
  slug: string;
  status: "ACTIVE";
  second_color?: string;
  min_price?: number;
  types: TravelPassType[];
}

export type ProvidersServiceResponse =
  | {
      error: false;
      providers: RawProviderData[];
    }
  | ApiErrorResponse;

export function getProvidersRequest(authorization: string) {
  return fetchTimeoutIncident<ProvidersServiceResponse>(
    `${ADMIN_API_BASE}available-providers`,
    {
      method: "GET",
      headers: new window.Headers({
        Authorization: authorization,
      }),
    },
    (json) => {
      // do not modify response when it's AUTH error
      if (API_ERROR_RESPONSE_CODES.includes(json.code)) {
        return json;
      }

      if (json.error_code) {
        return {
          error: true,
          ...json,
        };
      }
      return {
        error: false,
        providers: json,
      };
    }
  );
}

export function getProviders(history: History) {
  return callSecuredApiEndpoint(getProvidersRequest, history);
}

export type ProvidersTermsResponse =
  | {
      error: false;
      accepted: boolean;
    }
  | ApiErrorResponse;

export function setProviderTermsRequest(
  provider: TravelPassProvider,
  accepted: boolean
) {
  return (authorization: string) =>
    fetchTimeoutIncident<ProvidersTermsResponse>(
      `${ADMIN_API_BASE}${provider}/terms`,
      {
        method: "POST",
        headers: new window.Headers({
          Authorization: authorization,
        }),
        body: JSON.stringify({
          accepted,
        }),
      }
    );
}

export function setProviderTerms(
  provider: TravelPassProvider,
  accepted: boolean,
  history: History
) {
  return callSecuredApiEndpoint(
    setProviderTermsRequest(provider, accepted),
    history
  );
}

export function getProviderTermsRequest(provider: TravelPassProvider) {
  return (authorization: string) =>
    fetchTimeoutIncident<ProvidersTermsResponse>(
      `${ADMIN_API_BASE}${provider}/terms`,
      {
        method: "GET",
        headers: new window.Headers({
          Authorization: authorization,
        }),
      }
    );
}

export function getProviderTerms(
  provider: TravelPassProvider,
  history: History
) {
  return callSecuredApiEndpoint(getProviderTermsRequest(provider), history);
}
