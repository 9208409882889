import { ReactElement } from "react";
import { DefaultIconProps } from "../props";

export function TrainIcon({
  fill = "currentColor",
  className = "",
  height = "20",
  width = "20",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={fill}
        d="M12 2c-4.4 0-8 .5-8 4v9.5c0 2 1.6 3.5 3.5 3.5L6 20.5v.5h12v-.5L16.5 19c2 0 3.5-1.6 3.5-3.5V6c0-3.5-3.6-4-8-4zM7.5 17a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm3.5-6H6V6h5v5zm5.5 6a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm1.5-6h-5V6h5v5z"
      />
    </svg>
  );
}
