import { MobeaButton } from "common/forms";
import { CircleSadIcon } from "icons/CircleSadIcon";
import { ReactElement, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ApiErrors } from "utils";
import { AppColors } from "utils/colors";

interface Props {
  error: ApiErrors;
  onConfirm(): void;
}

export function ResetPasswordError({ error, onConfirm }: Props): ReactElement {
  const { t } = useTranslation();

  const textKeys = useMemo(() => {
    switch (error) {
      case ApiErrors.CUSTOMER_TOKEN_EXPIRED:
        return {
          title: "forgotten_password.link_expired",
          text: "forgotten_password.link_expired_text",
        };

      case ApiErrors.CUSTOMER_TOKEN_USED:
        return {
          title: "forgotten_password.link_used",
          text: "forgotten_password.link_used_text",
        };

      case ApiErrors.CUSTOMER_TOKEN_WRONG:
        return {
          title: "forgotten_password.link_wrong",
          text: "forgotten_password.link_wrong_text",
        };

      default:
        return {
          title: "shared.something_went_wrong",
          text: "shared.something_went_wrong_text",
        };
    }
  }, [error]);

  return (
    <>
      <h2 className="mobea-reset-password__headline">{t(textKeys.title)}</h2>
      <p className="mobea-reset-password__text">
        <Trans i18nKey={textKeys.text} />
      </p>
      <CircleSadIcon
        className="result-icon"
        fill={AppColors.GRAY_200}
        width={70}
        height={70}
      />
      <div className="mobea-reset-password__spacer"></div>
      <MobeaButton type="primary" onClick={onConfirm}>
        {t("forgotten_password.reset_password")}
      </MobeaButton>
    </>
  );
}
