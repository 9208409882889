import { MobeaModal } from "common/modal";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ApiErrors, pushLocation, Routes } from "utils";

export interface OrderErrorProps {
  errorCode: string;
  closeError(): void;
}

export function OrderError({
  errorCode,
  closeError,
}: OrderErrorProps): ReactElement {
  const history = useHistory();

  const { t } = useTranslation();

  const goHome = () => pushLocation(history, Routes.Home);

  switch (errorCode) {
    case ApiErrors.PENDING_TRANSACTION:
      return (
        <MobeaModal
          confirmText={t("shared.ok")}
          title={t("shared.oops")}
          onConfirm={closeError}
          type="error"
        >
          <p>{t("shared.order_in_progress_text")}</p>
        </MobeaModal>
      );
    case ApiErrors.NO_ACTIVE_PLAN:
      return (
        <MobeaModal
          confirmText={t("shared.ok")}
          title={t("shared.oops")}
          onConfirm={goHome}
          type="error"
        >
          <p>{t("shared.no_active_plan_text")}</p>
        </MobeaModal>
      );
    case ApiErrors.WRONG_DATA_INTEGRATION:
      return (
        <MobeaModal
          confirmText={t("shared.ok")}
          title={t("shared.oops")}
          onConfirm={closeError}
          type="error"
        >
          <p>{t("shared.integration_no_data_text")}</p>
        </MobeaModal>
      );
    case ApiErrors.LOW_BUDGET:
      return (
        <MobeaModal
          confirmText={t("shared.ok")}
          title={t("shared.oops")}
          onConfirm={goHome}
          type="error"
        >
          <p>{t("shared.insufficient_budget_text")}</p>
        </MobeaModal>
      );
    default:
      return (
        <MobeaModal
          confirmText={t("shared.ok")}
          title={t("shared.oops")}
          onConfirm={closeError}
          type="error"
        >
          <p>{t("shared.network_error_text")}</p>
        </MobeaModal>
      );
  }
}
