import { cx } from "@emotion/css";
import { useBooleanState } from "common/hooks";
import { EyeIcon } from "icons/EyeIcon";
import { ChangeEvent, ReactNode, useRef } from "react";
import { useTranslation } from "react-i18next";
import { AppFonts } from "utils/colors";
import "./PasswordInput.scss";

type Props = {
  onChange: (value: string) => void;
  value: string;
  label?: string;
  placeholder?: string;
  noCheck?: boolean;
  variant?: "standard" | "outlined";
  error?: ReactNode;
  className?: string;
};

export function getPasswordStrength(value: string) {
  return value.length === 0
    ? "empty"
    : value.length < 7
    ? "short"
    : !/[\p{Lu}\p{P}\p{S}]/u.test(value)
    ? "missing_uppercase_and_symbol"
    : !/\p{Lu}/u.test(value)
    ? "missing_uppercase"
    : !/[\p{P}\p{S}]/u.test(value)
    ? "missing_symbol"
    : "ok";
}

export function PasswordInput({
  onChange,
  value,
  placeholder,
  label,
  noCheck,
  variant = "standard",
  error,
  className,
}: Props) {
  const { t } = useTranslation();

  const [pwShown, , , togglePwShown] = useBooleanState();

  const [focused, focus, blur] = useBooleanState();

  const inputRef = useRef<HTMLInputElement>(null);

  const handlePwShowClick = () => {
    togglePwShown();

    // we need to postpone it because we are changing input type
    setTimeout(() => {
      inputRef.current?.focus();
    });
  };

  const strength = noCheck ? undefined : getPasswordStrength(value);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    onChange(e.currentTarget.value);
  };

  return (
    <div className={cx("mobea-password", variant, className)}>
      <div
        className={cx(
          "pw-input",
          focused ? "focused" : "blured",
          pwShown ? "pw-shown" : "pw-hidden",
          value ? "non-empty" : "empty",
          error ? "error" : "no-error"
        )}
      >
        {label && <label>{label}</label>}

        <input
          ref={inputRef}
          type={pwShown ? "text" : "password"}
          onFocus={focus}
          onBlur={blur}
          onChange={handleChange}
          value={value}
          placeholder={placeholder}
          css={{ fontFamily: AppFonts.NUMBERS }}
        />

        <button
          type="button"
          onClick={handlePwShowClick}
          onFocus={focus}
          onBlur={blur}
        >
          <EyeIcon width="16" height="16" />
        </button>
      </div>

      {strength && (
        <div
          className={"pw-strength pw-" + strength}
          css={{ fontFamily: AppFonts.SECONDARY }}
        >
          <div>{t("password." + strength)}</div>
          <div></div>
        </div>
      )}

      <div
        className="error-message"
        css={{
          overflow: "hidden",
          transition: "max-height 300ms ease-in",
          maxHeight: error ? "2em" : 0,
        }}
      >
        {error || null}
      </div>
    </div>
  );
}
