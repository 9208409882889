import { useModal } from "common/hooks/useModal";
import { NestedPageFull } from "common/page/NestedPageFull";
import { ReactElement, ReactText, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createMolliePayment } from "services/mollieService";
import { ApiErrors, parseFloatWithComma, Routes } from "utils";
import {
  EURO,
  MAX_PRIVATE_AMOUNT,
  PRIVATE_TOP_UP_MAX,
  PRIVATE_TOP_UP_MIN,
} from "utils/constants";
import { AddMoneyErrorDialog } from "./components/AddMoneyErrorDialog";
import { AddMoneyFailure } from "./components/AddMoneyFailure";
import { AddMoneyProcessingPayment } from "./components/AddMoneyProcessing";
import {
  AddMoneySetAmount,
  ErrorMessage,
} from "./components/AddMoneySetAmount";
import { AddMoneySuccess } from "./components/AddMoneySuccess";
import { useTopUpStatus } from "./hooks/useTopUpStatus";

export function AddMoneyPage(): ReactElement {
  const { t } = useTranslation();
  const history = useHistory();

  const { language, privateAmount } = useSelector((state) => state.user);

  const [value, setValue] = useState("");
  const [errorMsg, setErrorMsg] = useState<ErrorMessage>(null);
  const [loadingRedirect, setLoadingRedirect] = useState(false);
  const [requestError, setRequestError] = useState<ApiErrors | null>(null);
  const [paymentId, setPaymentId] = useState<string | null>(null);

  const [checkingStatus, status, resetStatus] = useTopUpStatus(paymentId);

  const { show: showConfirmLeave } = useModal({
    type: "secondary",
    title: t("shared.leaving"),
    confirmText: t("shared.leave"),
    cancelText: t("shared.cancel"),
    cancelButtonType: "secondary",
    onConfirm: history.goBack,
    children: t("add_money.leave_confirm_text"),
  });

  const { show: openConfirmPayment, hide: closeConfirmPayment } = useModal({
    type: "secondary",
    title: t("add_money.confirm_payment"),
    confirmText: t("shared.continue"),
    cancelText: t("shared.cancel"),
    onConfirm: () => goToPayments(),
    children: t("add_money.confirm_payment_text"),
  });

  const amount = parseFloatWithComma(value.replace(EURO, ""));

  const onValueChange = (value: ReactText) => {
    const number = value.toString().replace(EURO, "");

    if (number.length === 0) {
      setValue("");
    } else if (/^[1-9]\d{0,2}[,.]?\d{0,2}$/.test(number)) {
      setValue(`${EURO}${number}`);
    }
  };

  const validate = (value: ReactText) => {
    const cleaned = value.toString().replaceAll(/[^0-9,.]/g, "");
    const number = parseFloatWithComma(cleaned);

    // ignore empty text
    if (!cleaned) {
      return true;
    }

    if (/[,.]$/.test(cleaned) || Number.isNaN(number)) {
      setErrorMsg("invalid");
      return false;
    } else if (number < PRIVATE_TOP_UP_MIN) {
      setErrorMsg("min");
      return false;
    } else if (number > PRIVATE_TOP_UP_MAX) {
      setErrorMsg("max");
      return false;
    } else if (number + privateAmount > MAX_PRIVATE_AMOUNT) {
      setErrorMsg("over");
      return false;
    }

    setErrorMsg(null);
    return true;
  };

  const onFocus = () => {
    if (value === "") {
      setValue(EURO);
    }
  };

  const onBlur = () => {
    if (value === EURO) {
      setValue("");
    } else if (value) {
      validate(value);
    }
  };

  const resetRedirectError = () => setRequestError(null);
  const goToHomePage = () => history.push(Routes.Home);

  const retry = () => {
    setPaymentId(null);
    resetStatus();
  };

  const goToPayments = async () => {
    // Safari prevent any call to window.open if made from async method
    const windowRef = window.open();

    closeConfirmPayment();
    setLoadingRedirect(true);

    if (windowRef) {
      windowRef.location.href = [
        "/redirect-to-mollie?",
        `title=${encodeURIComponent(
          t("add_money.redirecting_mollie") as string
        )}&`,
        `text=${encodeURIComponent(
          t("add_money.redirecting_mollie_text") as string
        )}&`,
        `link=${encodeURIComponent(
          t("add_money.redirecting_mollie_link") as string
        )}`,
      ].join("");
    }
    // else do nothing - we will redirect the entire page to Mollie

    try {
      const json = await createMolliePayment(amount, language, history);

      if (!json.error) {
        const link = json.payment_response._links.checkout.href;

        if (windowRef) {
          const hyperlink = windowRef.document.getElementById(
            "link"
          ) as HTMLAnchorElement | null;

          if (hyperlink) {
            hyperlink.href = link;
          }
          windowRef.location.href = link;
        } else {
          // redirect to Mollie link if tab does not opens - e.g. Ad blocker (??)
          location.href = link;
        }

        setPaymentId(json.id);
      } else {
        setRequestError(json.error_code as ApiErrors);
      }
    } catch (e) {
      setRequestError(ApiErrors.I_NETWORK);
      windowRef?.close();
      console.error("Error while loading Mollie redirect link", e);
    } finally {
      setLoadingRedirect(false);
    }
  };

  const goBack = () => {
    if (checkingStatus) {
      showConfirmLeave();
    } else {
      history.goBack();
    }
  };

  const getPaymentStatusScreen = () => {
    switch (status) {
      case "created":
        return <AddMoneyProcessingPayment />;

      case "paid":
        return <AddMoneySuccess />;

      case "canceled":
        return (
          <AddMoneyFailure
            title={t("add_money.payment_canceled")}
            text={t("add_money.payment_canceled_text")}
            onRetry={retry}
          />
        );

      case "expired":
        return (
          <AddMoneyFailure
            title={t("add_money.payment_expired")}
            text={t("add_money.payment_expired_text")}
            onRetry={retry}
          />
        );

      default:
        return (
          <AddMoneyFailure
            title={t("add_money.payment_failed")}
            text={t("add_money.payment_failed_text")}
            onRetry={retry}
          />
        );
    }
  };

  return (
    <NestedPageFull
      pageClassName="mobea-add-money"
      title={t("add_money.title")}
      headerInSecondaryColor
      css={{
        ".mobea-add-money__content": {
          display: "flex",
          flexDirection: "column",
          flex: "1 0 auto",
        },
      }}
      onNavBack={goBack}
    >
      {!paymentId ? (
        <AddMoneySetAmount
          value={value}
          amount={amount || 0}
          min={PRIVATE_TOP_UP_MIN}
          max={PRIVATE_TOP_UP_MAX}
          privateAmount={privateAmount}
          errorMsg={errorMsg}
          locale={language}
          buttonDisabled={!amount || !!errorMsg || loadingRedirect}
          buttonLoading={loadingRedirect}
          validate={validate}
          goToPayments={openConfirmPayment}
          onValueChange={onValueChange}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      ) : (
        getPaymentStatusScreen()
      )}

      {requestError && (
        <AddMoneyErrorDialog
          error={requestError}
          onTryAgain={resetRedirectError}
          onCancel={goToHomePage}
        />
      )}
    </NestedPageFull>
  );
}
