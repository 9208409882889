import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

export function PortraitIcon({
  fill = "currentColor",
  className = "",
  height = "20",
  width = "20",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 10.2a1.9 1.9 0 100-3.7 1.9 1.9 0 000 3.7zm3.8 3.3c0-1.2-2.6-1.8-3.8-1.8-1.3 0-3.8.6-3.8 1.8v.7h7.5v-.7zm2-11H4.2c-1 0-1.7.8-1.7 1.7v11.6c0 1 .8 1.7 1.7 1.7h11.6c1 0 1.7-.8 1.7-1.7V4.2c0-1-.8-1.7-1.7-1.7zm0 13.3H4.2V4.2h11.6v11.6z"
        fill={fill}
        fillRule="nonzero"
      />
    </svg>
  );
}
