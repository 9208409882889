import { OrderError } from "common/errors/OrderError";
import { usePendingOrder } from "common/hooks";
import { useSelectedWallet } from "common/hooks/useSelectedWallet";
import { MobeaModal } from "common/modal";
import { OrderFooter } from "common/order/OrderFooter";
import { NestedPageFull } from "common/page/NestedPageFull";
import { CircleCheckIcon } from "icons/CircleCheckIcon";
import { DeLijnLogo } from "icons/logos/DeLijnLogo";
import { ProviderTermsAndConditions } from "pages/conditions/ProviderTermsAndConditions";
import { ReactElement, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  formatCurrency,
  pushLocation,
  Routes,
  TravelPassProvider,
  WalletType,
} from "utils";
import "./DelijnOrderPage.scss";
import { useDeLijnOrder, useDeLijnOrderData } from "./hooks";

export function DelijnOrderPage(): ReactElement {
  const history = useHistory();

  const { t } = useTranslation();

  const { duration, price, tariff, ticketTypeId } = useSelector(
    (state) => state.ordering.deLijnSingleTicket
  );

  const locale = useSelector((state) => state.user.language);

  const [loading] = useDeLijnOrderData();

  const [orderPending] = usePendingOrder(TravelPassProvider.delijn);

  const wallet = useSelectedWallet(WalletType.BUSINESS);

  const [makeOrder, orderedPass, errored, ordering, errorCode] =
    useDeLijnOrder();

  const [errorDialogOpened, setErrorDialogOpened] = useState(true);

  const formattedPrice = formatCurrency(price || 0, locale);

  const order = () => {
    makeOrder(Date.now(), {
      wallet,
      tariff,
      ticketTypeId,
    });

    // reset to default so error can be shown again
    setErrorDialogOpened(true);
  };

  const closeError = () => setErrorDialogOpened(false);

  const showTicketDetail = (id: string) => {
    pushLocation(
      history,
      Routes.DeLijnTicketDetail.replace(":id", encodeURIComponent(id))
    );
  };

  return (
    <>
      <NestedPageFull
        pageClassName="mobea-order-delijn"
        title={t("order_de_lijn.order_de_lijn_ticket")}
      >
        <section className="mobea-order-delijn__logo">
          <DeLijnLogo />
        </section>

        <section className="mobea-order-delijn__ticket">
          <div className="mobea-order-delijn__ticket__details">
            <h2 className="mobea-order-delijn__ticket__details__name">
              <span className="mobea__bold">m-</span>ticket
            </h2>
            <p className="mobea-order-delijn__ticket__details__info">
              Geldig op alle voertuigen van De Lijn
              <br />
              <span className="mobea__medium">{duration} minuten</span>
            </p>
          </div>

          <OrderFooter
            formattedPrice={formattedPrice}
            disabled={loading || orderPending || ordering}
            buttonLabel={
              orderPending
                ? t("shared.order_in_progress")
                : t("order_de_lijn.order_m_ticket")
            }
            pending={loading || orderPending || ordering}
            onOrder={order}
            confirmText={
              <p>
                <Trans i18nKey="order_de_lijn.confirm_purchase">
                  <span className="mobea__arial">{{ duration }}</span>
                  <span className="mobea__arial">
                    {{ price: formattedPrice }}
                  </span>
                </Trans>
              </p>
            }
          />
        </section>

        {orderedPass?.id && (
          <MobeaModal
            confirmText={t("shared.super")}
            title={t("shared.ticket_is_bought")}
            image={<CircleCheckIcon />}
            onConfirm={() => showTicketDetail(orderedPass.id)}
          >
            <p>
              <Trans i18nKey="order_de_lijn.ticket_ready_text">
                <span className="mobea__arial">{{ duration }}</span>
              </Trans>
            </p>
          </MobeaModal>
        )}

        {errorDialogOpened && errored && (
          <OrderError errorCode={errorCode} closeError={closeError} />
        )}
      </NestedPageFull>

      <ProviderTermsAndConditions
        title={t("order_de_lijn.order_de_lijn_ticket")}
        provider={TravelPassProvider.delijn}
        description={t("order_de_lijn.terms_description")}
        termsExternalLink="https://static.delijn.be/Images/algemene_reisvoorwaarden_feb_2020_tcm3-24191.pdf"
      />
    </>
  );
}
