import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

export function UploadIcon({
  fill = "currentColor",
  className = "",
  height = "40",
  width = "56",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 8 56 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.15 23.43a17.48 17.48 0 00-32.67-4.67A13.99 13.99 0 0014 46.66h30.33C50.77 46.67 56 41.45 56 35c0-6.16-4.78-11.15-10.85-11.57zM44.33 42H14a9.33 9.33 0 01-1.03-18.6l2.5-.25 1.17-2.22A12.76 12.76 0 0128 14c6.11 0 11.39 4.34 12.58 10.34l.7 3.5 3.57.25A6.95 6.95 0 0144.33 42zM18.67 30.33h5.95v7h6.76v-7h5.95L28 21l-9.33 9.33z"
        fill={fill}
      />
    </svg>
  );
}
