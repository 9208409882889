import { History } from "history";
import {
  ApiErrorResponse,
  ApiErrors,
  LOCAL_STORAGE_AUTH_KEY,
  LoginResultEnum,
  PUBLIC_AUTH_ROUTES,
  pushLocation,
} from "utils";
import {
  getNetworkState,
  getRawNetworkState,
  NetworkState,
} from "utils/network";
import { getRedirectRoute } from "utils/routing";
import { Auth as auth } from "./authTokenStore";

export type ApiResponse =
  | ApiErrorResponse
  | {
      error: false;
      [x: string]: any;
    };

export const API_ERROR_RESPONSE_CODES = [
  "token_not_valid",
  "customer_not_found",
] as const;
export type ApiAuthErrorCodes = "token_not_valid" | "customer_not_found";

export type AuthErrorResponse = {
  detail: string;
  code: ApiAuthErrorCodes;
  messages: {
    token_class: "AccessToken";
    token_type: "access";
    message: string;
  }[];
};

/**
 * Redirect to login screen when we cannot authorize user anymore
 */
function redirectToVerification(history: History) {
  // for public routes remove auth token if it is invalid
  // we will receive new token
  for (const route of PUBLIC_AUTH_ROUTES) {
    if (history.location.pathname.startsWith(route)) {
      localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);
      console.log(
        "Removed old token and prevented redirection from public auth page"
      );
      return;
    }
  }
  pushLocation(history, getRedirectRoute());
}

/**
 * Creates api call against secured endpoint
 */
export async function callSecuredApiEndpoint<R extends ApiResponse>(
  requestConstructor: (authHeader: string) => Promise<R>,
  history: History
): Promise<R> {
  // exit with offline error when we are offline for graceful shutdown
  if (
    getRawNetworkState() === NetworkState.offline ||
    getNetworkState() === NetworkState.incident
  ) {
    return {
      error: true,
      error_code: ApiErrors.I_OFFLINE,
      error_type: "offline",
      error_description: "No network connection",
    } as R;
  }
  const token = await auth.getToken();

  // extend response with auth error
  const json = (await requestConstructor(`Bearer ${token}`)) as R &
    AuthErrorResponse;

  /**
   * Auth error format - different from internal error format
   * {
   *  "detail":"Given token not valid for any token type",
   *  "code":"token_not_valid" | "customer_not_found"
   *  "messages":[{"token_class":"AccessToken","token_type":"access","message":"Token is invalid or expired"}]}
   */

  // issue new token and try again
  if (API_ERROR_RESPONSE_CODES.includes(json.code)) {
    // force update token and rerun request
    const refreshed = await auth.updateToken();

    // refresh error
    if (refreshed !== LoginResultEnum.SUCCESS) {
      console.error("Auth token. Redirecting to verification!");

      redirectToVerification(history);
    }

    const updatedToken = await auth.getToken();
    const reloadedJson = (await requestConstructor(
      `Bearer ${updatedToken}`
    )) as R & AuthErrorResponse;

    if (reloadedJson.code) {
      console.error(
        "Auth token not valid. Redirecting to verification!",
        reloadedJson
      );

      redirectToVerification(history);

      return {
        error: true,
        error_code: ApiErrors.I_LOGGED_OUT,
        error_type: "logged_out",
        error_description: "User not logged in",
      } as R;
    }

    return reloadedJson as R;
  }
  return json as R;
}
