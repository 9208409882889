import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

export function QuestionMarkIcon({
  fill = "currentColor",
  className = "",
  height = "32",
  width = "32",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 88 88"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.333 66h7.334v-7.333h-7.334V66zM44 7.333C23.76 7.333 7.333 23.76 7.333 44 7.333 64.24 23.76 80.667 44 80.667c20.24 0 36.667-16.427 36.667-36.667C80.667 23.76 64.24 7.333 44 7.333zm0 66C27.83 73.333 14.667 60.17 14.667 44c0-16.17 13.163-29.333 29.333-29.333 16.17 0 29.333 13.163 29.333 29.333 0 16.17-13.163 29.333-29.333 29.333zM44 22c-8.103 0-14.667 6.563-14.667 14.667h7.334c0-4.034 3.3-7.334 7.333-7.334s7.333 3.3 7.333 7.334c0 7.333-11 6.416-11 18.333h7.334c0-8.25 11-9.167 11-18.333C58.667 28.563 52.103 22 44 22z"
        fill={fill}
      />
    </svg>
  );
}
