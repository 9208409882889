import { MobeaModal } from "common/modal";
import { CircleCheckIcon } from "icons/CircleCheckIcon";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { AppColors } from "utils/colors";
import { ApiErrors } from "utils/errors";
import { MobitErrorDialogs } from "../errors/MobitErrorDialogs";

interface UnlockBikeDialogsProps {
  bikeOpened: boolean;
  errorDialogNotClosed: boolean;
  openFailed: boolean;
  openFailureCode: string;
  goToTicket(): void;
  buyDayPass(): void;
  closeError(): void;
}

export function UnlockBikeDialogs({
  bikeOpened,
  errorDialogNotClosed,
  openFailed,
  openFailureCode,
  goToTicket,
  buyDayPass,
  closeError,
}: UnlockBikeDialogsProps): ReactElement {
  const { t } = useTranslation();

  return (
    <>
      {bikeOpened && (
        <MobeaModal
          confirmText={t("shared.super")}
          title={t("mobit_unlock.bike_activated")}
          image={<CircleCheckIcon fill={AppColors.PRIMARY} />}
          onConfirm={goToTicket}
        >
          <p>{t("mobit_unlock.bike_activated_text")}</p>
        </MobeaModal>
      )}

      {errorDialogNotClosed &&
        openFailed &&
        openFailureCode === ApiErrors.MOBIT_EXPIRED_DAY_PASS_ERROR && (
          <MobeaModal
            type="info"
            title={t("mobit_unlock.ride_cannot_be_started")}
            confirmText={t("mobit_unlock.buy_a_day_pass")}
            onConfirm={buyDayPass}
            cancelText={t("shared.understand")}
            cancelButtonType="secondary"
            onCancel={goToTicket}
          >
            {t("mobit_unlock.ride_cannot_be_started_text")}
          </MobeaModal>
        )}

      {errorDialogNotClosed &&
        openFailed &&
        openFailureCode !== ApiErrors.MOBIT_EXPIRED_DAY_PASS_ERROR && (
          <MobitErrorDialogs
            errorCode={openFailureCode as ApiErrors}
            onConfirm={closeError}
          />
        )}
    </>
  );
}
