import { CSSProperties, MouseEvent, ReactElement } from "react";
import { TravelPassProvider } from "utils";
import { DeLijnLogo } from "../../../icons/logos/DeLijnLogo";
import { VlaanderLogo } from "../../../icons/logos/VlaanderLogo";
import { TravelPassWrapper } from "../TravelPassWrapper";
import "./DeLijnTravelPass.scss";

export interface DeLijnTravelPassProps {
  isPreview?: boolean;
  price: number;
  endDateTimeFormatted: string;
  duration?: number;
  activation?: number;
  expiration: number;
  shortCheckSum: string;
  afzender: string;
  verificationCode: string;
  locale: string;
  style?: CSSProperties;
  onSelect?(): void;
}

export function DeLijnTravelPass({
  isPreview = false,
  price,
  duration,
  activation,
  expiration,
  endDateTimeFormatted,
  shortCheckSum,
  afzender,
  verificationCode,
  locale,
  style = {},
  onSelect,
}: DeLijnTravelPassProps): ReactElement {
  const showDetail = (e: MouseEvent) => {
    e.preventDefault();

    onSelect && onSelect();
  };

  // fallback to prevent formatting non numerical value and possible error
  // De Lijn ticket is always dutch!
  const formattedPrice = (price || 0).toLocaleString("nl", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <TravelPassWrapper
      className={`mobea__de-lijn-ticket ${
        isPreview ? "mobea__ticket-preview" : ""
      }`}
      provider={TravelPassProvider.delijn}
      activation={activation}
      expiration={expiration}
      locale={locale}
      style={style}
      onClick={showDetail}
    >
      <h2 className="mobea__de-lijn-ticket__name">
        <span className="mobea__bold">m-</span>ticket
      </h2>

      <>
        <div className="mobea__de-lijn-ticket__info">
          <p className="mobea__de-lijn-ticket__info__type">
            De Lijn m-ticket {duration} minuten
          </p>
          <p className="mobea__de-lijn-ticket__info__details">
            Geldig op alle voertuigen van De Lijn {endDateTimeFormatted}.
          </p>
          <p className="mobea__de-lijn-ticket__info__price">
            <span>Prijs:</span> {formattedPrice} euro
          </p>
        </div>

        <div
          className={`mobea__de-lijn-ticket__full-details ${
            isPreview ? "hidden" : "visible"
          }`}
        >
          <div className="mobea__de-lijn-ticket__verification">
            <div className="mobea__de-lijn-ticket__verification__stripe">
              {shortCheckSum}
            </div>
            <div className="mobea__de-lijn-ticket__verification__data">
              <p className="mobea__de-lijn-ticket__verification__data__label">
                <span>afzender:</span>
                <span>{afzender}</span>
              </p>
              <p>{verificationCode}</p>
            </div>
          </div>
          <div className="mobea__de-lijn-ticket__bottom-logos">
            <VlaanderLogo className="mobea__de-lijn-ticket__bottom-logos__vlaander" />
            <DeLijnLogo className="mobea__de-lijn-ticket__bottom-logos__delijn" />
          </div>
        </div>
      </>
    </TravelPassWrapper>
  );
}
