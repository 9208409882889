import { DropDownGroup, InputGroup, MobeaButton } from "common/forms";
import { MobeaModal } from "common/modal";
import { NestedPageOverlay } from "common/page/NestedPageOverlay";
import { FileAttachment } from "common/types";
import { FileUploader } from "common/uploads/FileUploader";
import { ReactElement, ReactText, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { reportBike } from "services/mobitService";
import "./MobitReportBikePage.scss";

const bikeProblemKeys = [
  // t("mobit_report.problem_basket")
  "basket",

  // t("mobit_report.problem_tyre")
  "tyre",

  // t("mobit_report.problem_light")
  "light",

  // t("mobit_report.problem_pedal")
  "pedal",

  // t("mobit_report.problem_gear")
  "gear",

  // t("mobit_report.problem_saddle")
  "saddle",

  // t("mobit_report.problem_lock")
  "lock",

  // t("mobit_report.problem_chain")
  "chain",

  // t("mobit_report.problem_unlock")
  "unlock",

  // t("mobit_report.problem_other")
  "other",
];

export interface MobitReportBikePageProps {
  id: string;
  goBack(): void;
}

export function MobitReportBikePage({
  id,
  goBack,
}: MobitReportBikePageProps): ReactElement {
  const { t } = useTranslation();

  const history = useHistory();

  const [uploading, setUploading] = useState(false);

  const problemOptions = useMemo(
    () => bikeProblemKeys.map((key) => t(`mobit_report.problem_${key}`)),
    [t]
  );

  const [bikeId, setBikeId] = useState(id);

  const [problem, setProblem] = useState("");

  const [filesToUpload, setFilesToUpload] = useState<FileAttachment[]>([]);

  const [reportSuccess, setReportSuccess] = useState(false);

  const [reportError, setReportError] = useState<string | null>(null);

  const onBikeIdChange = (value: ReactText) => {
    setBikeId(value.toString());
  };

  const onProblemChange = (value: string) => {
    setProblem(value);
  };

  const closeError = () => setReportError(null);

  const sendReport = async () => {
    setReportError(null);
    setUploading(true);

    try {
      const reportData = {
        problem,
        attachments: filesToUpload,
      };

      const result = await reportBike(bikeId, reportData, history);

      if (result.error) {
        setReportError(result.error_code);
      } else {
        setReportSuccess(true);
      }
    } catch (e) {
      console.warn("Error reporting bike defect.", e);

      setReportSuccess(false);
    } finally {
      setUploading(false);
    }
  };

  const isBikeIdValid = (value) => value.toString().length === 10;

  const dropdownLabel = t("mobit_report.problem");

  const allValid = isBikeIdValid(bikeId) && problem;

  return (
    <NestedPageOverlay
      pageClassName="mobea-mobit-report"
      title={t("mobit_report.report_bike_problem")}
      headerHeight="minimal"
      padding={{
        top: 24,
      }}
      onNavBack={goBack}
    >
      <div className="mobea-mobit-report__form">
        <InputGroup
          className="mobea-mobit-report__form__id"
          label={t("mobit_report.bike_id")}
          name="id"
          value={bikeId}
          errorMessage={t("mobit_report.code_too_short")}
          validation={isBikeIdValid}
          initialValidation={isBikeIdValid}
          inputAttributes={{
            inputMode: "numeric",
            maxLength: "10",
            autoComplete: "off",
          }}
          onChange={onBikeIdChange}
        />
        <DropDownGroup
          className="mobea-mobit-report__form__problem"
          label={problem ? dropdownLabel : ""}
          options={problemOptions}
          value={problem}
          placeholder={!problem ? dropdownLabel : ""}
          onChange={onProblemChange}
        />
      </div>

      <section className="mobea-mobit-report__uploads">
        <FileUploader
          filesToUpload={filesToUpload}
          filesTypeErrorKey="expense.invalid_file_type_text"
          supportedFilesTextKey="expense.supported_files_text"
          onFilesToUploadChange={setFilesToUpload}
          maxFileCount={1}
        />
      </section>

      <section className="mobea-mobit-report__button">
        <MobeaButton disabled={!allValid || uploading} onClick={sendReport}>
          {uploading ? t("shared.in_progress") : t("mobit_report.report")}
        </MobeaButton>
      </section>

      {reportError && reportError === "M005" && (
        <MobeaModal
          type="error"
          confirmText={t("shared.ok")}
          title={t("shared.oops")}
          onConfirm={closeError}
        >
          {t("mobit_report.wrong_code_error_text")}
        </MobeaModal>
      )}

      {reportError && reportError !== "M005" && (
        <MobeaModal
          type="error"
          confirmText={t("shared.ok")}
          title={t("shared.oops")}
          onConfirm={closeError}
        >
          {t("shared.network_error_text")}
        </MobeaModal>
      )}

      {reportSuccess && (
        <MobeaModal
          confirmText={t("shared.ok")}
          title={t("mobit_report.damage_reported")}
          onConfirm={goBack}
        >
          {t("mobit_report.damage_reported_text")}
        </MobeaModal>
      )}
    </NestedPageOverlay>
  );
}
