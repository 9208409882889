import { BudgetState } from "common/hooks/useBudgetState";
import { MobeaModal } from "common/modal";
import { useTranslation } from "react-i18next";

export type MapWarningType = "budgetNotActiveYet" | "budgetExpired";

interface MapWarningDialogsEaProps {
  budgetState: BudgetState;
  warningShown: Record<MapWarningType, boolean>;
  closeWarning: (kind: MapWarningType) => void;
}

export function MapWarningDialogsEa({
  budgetState,
  warningShown,
  closeWarning,
}: MapWarningDialogsEaProps) {
  const { t } = useTranslation();

  return (
    <>
      {budgetState === BudgetState.NotActiveYet &&
        !warningShown.budgetNotActiveYet && (
          <MobeaModal
            type="error"
            title={t("warning.no_budget")}
            confirmText={t("shared.ok")}
            onConfirm={() => closeWarning("budgetNotActiveYet")}
          >
            <p>{t("map.welcome_message_not_active")}</p>
          </MobeaModal>
        )}

      {budgetState === BudgetState.Expired && !warningShown.budgetExpired && (
        <MobeaModal
          type="error"
          title={t("warning.budget_expired")}
          confirmText={t("shared.ok")}
          onConfirm={() => closeWarning("budgetExpired")}
        >
          <p>{t("map.welcome_message_expired")}</p>
        </MobeaModal>
      )}
    </>
  );
}
