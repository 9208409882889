import { ActionButton } from "common/forms";
import { Steps } from "common/steps/Steps";
import { ArrowRightIcon } from "icons/ArrowRightIcon";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { tutorialFinishAction } from "state/actions";
import { Routes } from "utils";
import { AppColors } from "utils/colors";
import { Step1 } from "./Step1";
import { Step2and3 } from "./Step2and3";
import { Step4 } from "./Step4";

export function MoveasyTutorialPage() {
  const { t } = useTranslation();

  const history = useHistory();

  const dispatch = useDispatch();

  const location = useLocation();

  const [backToTutors] = useState(
    typeof location.state === "object" &&
      location.state?.["from"] === Routes.Tutorials
  );

  const [backSteps, setBackSteps] = useState(1);

  const done = () => {
    dispatch(tutorialFinishAction());

    if (backToTutors) {
      history.go(-backSteps);
    } else {
      history.push(Routes.Home);
    }
  };

  // this is to prevent flashing to different tutorial page when leaving the tutorial
  const [lastStep, setLastStep] = useState(1);

  const step = location.pathname.startsWith(Routes.Tutorial)
    ? Number(location.pathname.replace(/.*\//, "")) || 1
    : lastStep;

  useEffect(() => {
    setLastStep(step);
  }, [step]);

  const handleBackClick =
    (location.state as any)?.fromTutorialStep === step - 1
      ? () => {
          setBackSteps((s) => s - 1);
          history.goBack();
        }
      : () => {
          setBackSteps((s) => s + 1);
          history.push({
            pathname:
              step === 1 ? Routes.Tutorial : Routes.Tutorial + "/" + (step - 1),
            state: { fromTutorialStep: step },
          });
        };

  const handleNextClick =
    step === 4
      ? done
      : () => {
          setBackSteps((s) => s + 1);
          history.push({
            pathname: Routes.Tutorial + "/" + (step + 1),
            state: { fromTutorialStep: step },
          });
        };

  const handleCarouselChange = (position: number) => {
    history.push(Routes.Tutorial + "/" + (position + 2));
    setBackSteps((s) => s + 1);
  };

  return (
    <main
      css={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& > h1": {
          margin: "1.5rem",
          marginBottom: 0,
          textAlign: "center",
          color: AppColors.GRAY_500,
          fontSize: "2rem",
          fontWeight: "normal",
          animation: "fadeIn 200ms",
        },
        "& > p": {
          textAlign: "center",
          margin: "1.5rem",
          lineHeight: 1.5,
          color: AppColors.GRAY_400,
          animation: "fadeIn 200ms",
        },
      }}
    >
      <div
        css={{
          flex: "0 0 calc(50% - 220px)",
          alignSelf: "flex-end",
        }}
      >
        <button
          onClick={done}
          css={{
            textDecoration: "none",
            color: AppColors.GRAY_200,
            fontSize: "1.125rem",
            margin: "1.5rem",
            marginBottom: 0,
            border: 0,
            backgroundColor: "transparent",
            padding: 0,
            display: "inline-block",
            ":first-letter": {
              textTransform: "capitalize",
            },
          }}
        >
          {t("tutorial.skip")}
        </button>
      </div>

      {step === 1 ? (
        <Step1 />
      ) : step === 2 || step === 3 ? (
        <Step2and3 subStep={step - 2} onChange={handleCarouselChange} />
      ) : step === 4 ? (
        <Step4 />
      ) : null}

      <div
        css={{
          marginTop: "1.5rem",
          flexGrow: 1,
        }}
      />

      <div
        css={{
          display: "flex",
          justifyContent: "space-between",
          alignSelf: "stretch",
          alignItems: "center",
          margin: "0 1.5rem",
        }}
      >
        <ActionButton
          type="secondary"
          hidden={step === 1}
          onClick={handleBackClick}
        >
          <ArrowRightIcon css={{ transform: "rotate(180deg)" }} />
        </ActionButton>
        <ActionButton
          type="primary"
          shape={step === 4 ? "ellipse" : "circle"}
          onClick={handleNextClick}
        >
          {step === 4 ? t("moveasy_tutorial.lets_go") : <ArrowRightIcon />}
        </ActionButton>
      </div>

      <div css={{ height: 62 }}></div>

      <div
        css={{
          zIndex: -1,
          alignSelf: "stretch",
          display: "flex",
          position: "absolute",
          flexDirection: "column",
          alignItems: "center",
          bottom: 0,
          left: 0,
          right: 0,
          pointerEvents: "none",
        }}
      >
        <svg
          css={{
            position: "absolute",
            right: 0,
            top: 0,
            left: 0,
            bottom: 0,
          }}
          viewBox="2 0 16 4"
          width="100%"
          height="100%"
          preserveAspectRatio="none"
        >
          <circle cx={10} cy={10} r={10} fill="rgba(var(--rgb-primary), 0.1)" />
        </svg>

        <Steps
          currentStep={step - 1}
          stepCount={4}
          css={{
            margin: "1.5rem",
            marginTop: "2rem",
            zIndex: 10,
          }}
        />
      </div>
    </main>
  );
}
