import { DefaultIconProps } from "icons/props";
import { ReactElement } from "react";

export function DenmarkIcon({
  className = "country-phone-prefix__icon",
  width = "20",
  height = "20",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <rect id="dnm-flag-a" x="0" y="0" width="20" height="20" rx="10" />
      </defs>
      <use fill="#EB2148" xlinkHref="#dnm-flag-a" />
      <path
        d="M11.7 0v8.3H20v3.4h-8.3V20H8.3v-8.3H0V8.3h8.3V0h3.4z"
        fill="#fff"
        mask="url(#dnm-flag-a)"
      />
    </svg>
  );
}
