import { ReactElement } from "react";

export function FirstTutorialSlideImage({
  color,
  light,
}: {
  color: string;
  light: string;
}): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 222 217"
    >
      <defs>
        <path id="a" d="M0 0h222v222H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <g mask="url(#b)">
          <circle stroke={color} strokeWidth="3" cx="213.5" cy="63.5" r="6.5" />
          <circle stroke={color} strokeWidth="3" cx="65.5" cy="17.5" r="6.5" />
          <circle stroke={color} strokeWidth="3" cx="13.5" cy="165.5" r="8.5" />
          <path
            stroke={color}
            strokeWidth="3"
            d="M6.5 101v13M13 107.5H0M208.5 172v17M217 180.5h-17"
          />
          <circle
            fill={color}
            fillRule="nonzero"
            opacity=".1"
            cx="111"
            cy="111"
            r="65"
          />
          <g transform="translate(39 136)" fillRule="nonzero">
            <rect
              stroke={color}
              strokeWidth="3"
              fill="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              width="54"
              height="47"
              rx="5.9"
            />
            <path
              d="M34.3 16H54v15H34.3C33 31 32 30 32 28.6V18.4c0-1.3 1-2.4 2.3-2.4h0z"
              stroke={color}
              strokeWidth="3"
              fill={light}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M43.3 25.3a2.5 2.5 0 10-3.6-3.6 2.5 2.5 0 003.6 3.6z"
              fill={color}
            />
          </g>
          <g
            transform="translate(25.6 40.6)"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          >
            <circle
              fill={light}
              fillRule="nonzero"
              transform="rotate(-45 36.5 36.5)"
              cx="36.5"
              cy="36.5"
              r="25.5"
            />
            <path d="M22.1 33.2l11 11.8 16-17" />
          </g>
          <g
            stroke={color}
            strokeDasharray="8"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          >
            <path d="M30 141a85 85 0 011-60M160 183a81.2 81.2 0 01-76 9.6M184 65a84.7 84.7 0 0113.5 56M63 40.9A84.5 84.5 0 01168 46" />
          </g>
          <g transform="translate(107 57)" fillRule="nonzero">
            <rect
              stroke={color}
              strokeWidth="3"
              fill="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              transform="rotate(180 34 54.5)"
              width="68"
              height="109"
              rx="3.5"
            />
            <path
              stroke={color}
              strokeWidth="3"
              fill="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M68 6.5H0M0 99.5h68M37 104.5h-6"
            />
            <path
              stroke={color}
              strokeWidth="3"
              fill={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8 69h34v8H8z"
              fillOpacity="0.8"
            />
            <path
              d="M13 21.7v19c0 .8.7 1.5 1.5 1.5 0 1.5 1.3 2.8 2.9 2.8 1.6 0 2.8-1.3 2.8-2.8h8.6c0 1.5 1.2 2.8 2.8 2.8 1.6 0 2.9-1.3 2.9-2.8h.5c.6 0 1-.4 1-1V21.7a4 4 0 00-3.3-4 46.6 46.6 0 00-16.4 0 4 4 0 00-3.3 4zm5.4-1a29.7 29.7 0 0112.2 0 3 3 0 012.4 2.9V29c0 .6-.4 1-1 1H17a1 1 0 01-1-1v-5.4a3 3 0 012.4-3zM17 33h15c.6 0 1 .4 1 1v4c0 .6-.4 1-1 1H17a1 1 0 01-1-1v-4c0-.6.4-1 1-1zm3 5h-2a1 1 0 01-1-1v-1c0-.6.4-1 1-1h2c.6 0 1 .4 1 1v1c0 .6-.4 1-1 1zm10-3c.6 0 1 .4 1 1v1c0 .6-.4 1-1 1h-2a1 1 0 01-1-1v-1c0-.6.4-1 1-1h2zm10-11v6h-3v-6h3zm-29 0v6H8v-6h3z"
              fill={color}
            />
            <path
              stroke={color}
              strokeWidth="3"
              fill="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8 83.5h32M8 90.5h26M63.4 118c-9.7-6.1-19-18.6-19-18.6a4.5 4.5 0 01-.2-6.3 4.4 4.4 0 016.2 0l9.5 9.3h0L48.8 73a4.4 4.4 0 014.8-6c1.5.3 2.8 1.4 3.4 2.8l7.4 19.8-.8-2c-.8-2.3.3-4.9 2.6-5.7 2.3-.9 4.8.2 5.7 2.5l.3.9a4.4 4.4 0 014.8-6c1.5.2 2.8 1.3 3.4 2.8l.3.9a4.4 4.4 0 014.8-6c1.6.2 2.9 1.3 3.4 2.8l4.7 12.5c4.1 11-1.2 23.2-12 27.3a20.5 20.5 0 01-18.2-1.7M72 86l1 2M80 82l1 2"
            />
          </g>
          <path
            stroke={color}
            strokeWidth="3"
            d="M168.5 0v13M175 6.5h-13M78.5 200v17M87 208.5H70"
          />
        </g>
      </g>
    </svg>
  );
}
