import { usePreviousValue } from "common/hooks";
import { NestedPage } from "common/page/NestedPage";
import { SearchField, SearchFieldRef } from "common/search";
import { Spinner } from "common/Spinner";
import { useTutorialHighlighter } from "common/tutorial2/Tutorial";
import { SearchIcon } from "icons/SearchIcon";
import { filterProvidersByName } from "maps/mapUtils";
import { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { startTutorial } from "state/actions";
import {
  providerOrderList,
  pushLocation,
  Routes,
  TravelPassProvider,
} from "utils";
import { toTravelPassTypes } from "./filter/functions";
import { ProviderFilterType } from "./filter/ProviderFilterType";
import { ProvidersFilter } from "./filter/ProvidersFilter";
import { OtherProvider } from "./other/OtherProvider";
import "./ProvidersPage.scss";
import { toProviderTile } from "./tiles";

export function ProvidersPage(): ReactElement {
  const { t } = useTranslation();
  const history = useHistory();

  const searchFieldRef = useRef<SearchFieldRef>(null);

  const [selectedFilters, setSelectedFilters] = useState<ProviderFilterType[]>(
    []
  );

  const [searchFieldVisible, setSearchFieldVisible] = useState(false);

  const [searchText, setSearchText] = useState("");

  const setTutorialFilter = useSelector(
    (store) => store.tutorial.name === "providers" && store.tutorial.step === 2
  );

  useEffect(() => {
    if (setTutorialFilter) {
      setSelectedFilters([ProviderFilterType.PublicTransport]);
    }
  }, [setTutorialFilter]);

  const { providers, providersLoading } = useSelector((state) => state.passes);

  const filteredProviders = useMemo(() => {
    return filterProvidersByName(
      searchText,
      providerOrderList(providers)
    ).filter(
      (provider) =>
        selectedFilters.length === 0 ||
        provider.types.some((type) =>
          toTravelPassTypes(
            selectedFilters[0] ?? ProviderFilterType.All
          ).includes(type)
        )
    );
  }, [providers, searchText, selectedFilters]);

  const showSearchField = () => {
    setSearchFieldVisible(true);
    searchFieldRef.current?.focus();
  };

  const hideSearchField = () => {
    setSearchFieldVisible(false);
    setSearchText("");
  };

  useTutorialHighlighter("providers", 1, "tutorial-providers-title", {
    position: "bottom",
    offsetY: 10,
  });

  useTutorialHighlighter("providers", 3, "tutorial-delijn-tile", {
    position: "bottom",
    offsetY: 10,
  });

  const providersTutorialShown = useSelector(
    (store) => store.tutorial.providersTutorialShown
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!providersTutorialShown) {
      dispatch(startTutorial("providers"));
    }
  }, [dispatch, providersTutorialShown]);

  const providersTutorialActive = useSelector(
    (store) => store.tutorial.name === "providers"
  );

  const prevProvidersTutorialActive = usePreviousValue(providersTutorialActive);

  useEffect(() => {
    if (!providersTutorialActive && prevProvidersTutorialActive) {
      setSelectedFilters([]);
    }
  }, [providersTutorialActive, prevProvidersTutorialActive]);

  return (
    <NestedPage
      title={
        <span id="tutorial-providers-title">
          {t("providers.get_travel_pass")}
        </span>
      }
      pageClassName="mobea-providers"
      background="default"
      padding={{
        left: 16,
        right: 16,
      }}
      onNavBack={() => pushLocation(history, Routes.Home)}
      customCloseButton={
        <SearchIcon
          className={`search-icon ${searchFieldVisible ? "hidden" : ""}`}
          onClick={showSearchField}
        />
      }
    >
      {providersLoading && <Spinner />}

      {!providersLoading && (
        <>
          <SearchField
            ref={searchFieldRef}
            className={`mobea-providers__search-field ${
              searchFieldVisible ? "visible" : "hidden"
            }`}
            value={searchText}
            onChange={setSearchText}
            onClose={hideSearchField}
          />

          <ProvidersFilter
            selected={selectedFilters}
            onChange={setSelectedFilters}
            hiddenOptions={[ProviderFilterType.Walking]}
          />

          {filteredProviders.length > 0 && (
            <div className="mobea-providers__providers">
              {filteredProviders
                .filter((provider) => !provider.hidden)
                .map((tile) =>
                  tile.provider === TravelPassProvider.delijn ? (
                    <div
                      id="tutorial-delijn-tile"
                      key={`${tile.type}-${tile.provider}`}
                    >
                      {toProviderTile(tile)}
                    </div>
                  ) : (
                    toProviderTile(tile)
                  )
                )}
            </div>
          )}
          {providers.some((provider) => provider.provider === "other") && (
            <OtherProvider />
          )}
        </>
      )}
    </NestedPage>
  );
}
