import { ReactElement } from "react";
import { AppColors } from "utils/colors";

export type TabNavItem = {
  key: string;
  label: string;
};

export interface TabPaneNavProps {
  className?: string;
  tabs: TabNavItem[];
  active: number;
  width?: string | number;
  onTabChange(index: number): void;
}

export function TabPaneNav({
  className = "",
  tabs,
  width = "auto",
  active,
  onTabChange,
}: TabPaneNavProps): ReactElement {
  const tabsCount = tabs.length;

  const activeTab = tabs[active];

  const itemSize = (100 / tabsCount).toFixed(3);

  const itemStyle: any = {
    fontWeight: 600,
    float: "left",
    width: `${itemSize}%`,
    listStyle: "none",
    padding: 6,
    fontSize: 14,
    color: AppColors.GRAY_200,
    textAlign: "center",
    textTransform: "uppercase",
    height: "100%",
    borderRadius: 16,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <ul
      className={`mobea__tab-pane-nav ${className}`}
      css={{
        width,
        maxWidth: "100%",
        height: 32,
        margin: "0 auto",
        padding: 0,
        backgroundColor: AppColors.WHITE,
        borderRadius: 16,
        position: "relative",
      }}
    >
      {tabs.map((tab, index) => (
        <li
          key={tab.key}
          className="mobea__tab-pane-nav__tab"
          onClick={() => onTabChange(index)}
          css={{ ...itemStyle }}
        >
          {tab.label}
        </li>
      ))}

      <li
        className="mobea__tab-pane-nav__tab mobea__switch"
        css={[
          { ...itemStyle },
          {
            position: "absolute",
            top: 0,
            left: `${active * (100 / tabsCount)}%`,
            transition: "left 300ms ease-in",
            backgroundColor: AppColors.PRIMARY,
            color: AppColors.TEXT_INVERTED,
          },
        ]}
      >
        {activeTab.label}
      </li>
    </ul>
  );
}
