import { RawTravelPass } from "services/travelPassService";
import { RawVertsTicket } from "services/vertsService";
import {
  TravelProvider,
  vertsStatusMapper,
  VertsTravelPass,
} from "state/actions";
import { TravelPassProvider, TravelPassType } from "utils/constants";
import { VertsStatus } from "utils/VertsStatus";

export function normalizeVertsTravelPass(
  rawTravelPass: RawTravelPass,
  providers: TravelProvider[]
): VertsTravelPass {
  const cancelFee =
    providers.find((provider) => provider.provider === TravelPassProvider.verts)
      ?.minPrice || 0;

  const activation = new Date(rawTravelPass.created).getTime();

  const expiration = new Date(rawTravelPass.expire_at).getTime();

  const vertsTicketResponse = rawTravelPass.response as RawVertsTicket;

  const taxiVertDetail = rawTravelPass.taxi_vert_detail;

  const driverInfo = taxiVertDetail?.driver_info?.info;

  const vehicleInfo = taxiVertDetail?.vehicle_info?.info;

  const vehicleLocation =
    taxiVertDetail?.vehicle_info?.current_location?.coordinates;

  const getStatus = (): VertsStatus => {
    if (rawTravelPass.status === "CANCELED") {
      return VertsStatus.Cancelled;
    } else {
      const status = vertsStatusMapper[taxiVertDetail?.order_status || ""];

      if (!status) {
        console.warn(
          "Unrecognized Verts status: " + taxiVertDetail?.order_status
        );
      }

      return status || VertsStatus.Error;
    }
  };

  const getDisplayStatus = (): VertsStatus => {
    if (rawTravelPass.status === "CANCELED") {
      return VertsStatus.Cancelled;
    } else {
      const status = vertsStatusMapper[taxiVertDetail?.order_status || ""];

      if (status === VertsStatus.Created) {
        if (vertsTicketResponse.schedule_type === "planned") {
          return VertsStatus.Created;
        } else {
          return VertsStatus.Ordered;
        }
      }

      if (!status) {
        console.warn(
          "Unrecognized Verts status: " + taxiVertDetail?.order_status
        );
      }

      return status || VertsStatus.Error;
    }
  };

  const status = getStatus();
  const displayStatus = getDisplayStatus();

  const price =
    status === VertsStatus.Finished || status === VertsStatus.Cancelled
      ? parseFloat(rawTravelPass.amount)
      : vertsTicketResponse.min_rate || 0;

  return {
    id: rawTravelPass.id,
    orderTimestamp: new Date(vertsTicketResponse.createdAt).getTime(),
    activation,
    expiration,
    provider: TravelPassProvider.verts,
    types: [TravelPassType.Taxi],
    status,
    createdTime: vertsTicketResponse.createdAt,
    etaTime:
      typeof vertsTicketResponse.current_target?.eta === "string"
        ? undefined
        : vertsTicketResponse.current_target?.eta?.time,
    arrivalTime: vertsTicketResponse.arrivalAt,
    price: price,
    cancelFee,
    cancelledByOperator: taxiVertDetail?.cancelled_by_operator,
    origin: {
      address: vertsTicketResponse.source.address,
      location: vertsTicketResponse.source.coordinates,
    },
    destination: {
      address: vertsTicketResponse.destination.address,
      location: vertsTicketResponse.destination.coordinates,
    },
    driver: driverInfo
      ? `${driverInfo.first_name} ${driverInfo.last_name}`
      : undefined,
    vehicle: vehicleInfo
      ? {
          model: `${vehicleInfo.brand} ${vehicleInfo.model}`,
          plate: vehicleInfo.plaque,
          color: "black", // black color for now until Taxi Verts has different colors,
          location: vehicleLocation,
        }
      : undefined,
    plan: rawTravelPass.plan,
    displayStatus,
    requestedAt: vertsTicketResponse.requestedAt,
  };
}
