import { CheckMarkIcon } from "icons/CheckMarkIcon";
import { ReactElement, ReactNode } from "react";
import "./CheckboxGroup.scss";

export enum CheckboxGroupShape {
  Square = "square",
  Circle = "circle",
}

export interface CheckboxGroupProps {
  name: string;
  label: ReactNode;
  isChecked: boolean;
  disabled?: boolean;
  className?: string;
  shape?: CheckboxGroupShape;
  onChange: (checked: boolean) => void;
}

export function CheckboxGroup({
  name,
  label,
  isChecked,
  disabled,
  className = "",
  shape = CheckboxGroupShape.Square,
  onChange,
}: CheckboxGroupProps): ReactElement {
  const onCheckboxChange = () => onChange(!isChecked);

  return (
    <div
      className={`mobea__checkbox-group ${className} ${
        disabled ? "mobea__disabled" : ""
      } shape-${shape}`}
    >
      <input
        type="checkbox"
        name={name}
        id={name}
        checked={isChecked}
        disabled={disabled}
        onChange={onCheckboxChange}
      />

      <label htmlFor={name}>
        <CheckMarkIcon />
        {label}
      </label>
    </div>
  );
}
