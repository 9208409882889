import { SpendingTrackingData, trackTicketPurchase } from "analytics/events";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { getTicketDetail } from "services/travelPassService";
import { orderVeloDayPass } from "services/veloService";
import {
  addActiveTravelPassAction,
  addPurchaseAction,
  VeloTravelPass,
} from "state/actions";
import { ApiErrors, TravelPassProvider } from "utils";
import { normalizeVeloTravelPass } from "utils/normalizers/veloNormalizer";

export function useVeloOrder(price: number) {
  const history = useHistory();

  const dispatch = useDispatch();

  const [ordering, setOrdering] = useState(false);

  const [errorCode, setErrorCode] = useState<string | null>(null);

  const [result, setResult] = useState<VeloTravelPass[]>([]);

  const orderVelo = async (wallet: "private" | "business", amount: number) => {
    setOrdering(true);

    setResult([]);

    setErrorCode(null);

    const trackingPayload: SpendingTrackingData = {
      action: TravelPassProvider["velo-antwerpen"],
      label: "success" as "success" | "failure",
      value: price.toString(),
    };

    try {
      const ticketErrors: (string | null)[] = [];

      const tickets: VeloTravelPass[] = [];

      const response = await orderVeloDayPass(wallet, amount, history);

      for (const ticket of response) {
        if (ticket.error) {
          ticketErrors.push(ticket.error_code);

          continue;
        }

        trackTicketPurchase(trackingPayload);

        const ticketDetail = await getTicketDetail(
          ticket.transaction_id,
          history
        );

        if (!ticketDetail.error) {
          const normalizedTicket = normalizeVeloTravelPass(ticketDetail);

          tickets.push(normalizedTicket);

          ticketErrors.push(null);

          // dispatch even if navigated out
          dispatch(addActiveTravelPassAction(normalizedTicket));

          // add to history for case app goes offline so history is consistent
          dispatch(addPurchaseAction(normalizedTicket));
        } else {
          ticketErrors.push(ticketDetail.error_code);

          console.error(
            `Failed to find orderer Velo day pass: ${ticketDetail.error_code} - ${ticketDetail.error_description}`
          );
        }
      }

      setOrdering(false);

      setResult(tickets);

      const errorCode = ticketErrors.filter((error) => !!error)[0];

      setErrorCode(errorCode);

      if (errorCode) {
        trackingPayload.label = "failure";

        trackTicketPurchase(trackingPayload);
      }
    } catch (error) {
      trackingPayload.label = "failure";

      trackTicketPurchase(trackingPayload);

      setErrorCode(ApiErrors.I_NETWORK);

      console.error("Failed to order Velo tickets", error);
    } finally {
      setOrdering(false);
    }
  };

  return [orderVelo, ordering, result, errorCode] as const;
}
