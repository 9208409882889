import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  getMolliePaymentStatus,
  MolliePaymentStatus,
} from "services/mollieService";

export function useTopUpStatus(id: string | null) {
  const history = useHistory();

  // default status after payment was requested is open and we check for change
  const [status, setStatus] = useState<MolliePaymentStatus>("created");
  const [pending, setPending] = useState(false);

  const [pollingId, setPollingId] = useState<number | null>(null);

  const reset = useCallback(() => {
    setStatus("created");
    setPending(false);
  }, [setStatus, setPending]);

  useEffect(() => {
    let timeoutId: number;

    let canceled = false;
    let pending = false;

    async function fetchStatus(id: string) {
      setPending(true);
      pending = true;

      try {
        const orderJson = await getMolliePaymentStatus(id, history);

        if (!orderJson.error) {
          pending = orderJson.status === "created";
          setStatus(orderJson.status);

          if (!canceled) {
            setPending(pending);

            if (pending) {
              timeoutId = window.setTimeout(() => {
                // trigger rerunning the hook
                setPollingId(timeoutId);
              }, 5000);
            }
          }
        } else {
          if (!canceled) {
            pending = false;
            setPending(pending);
          }

          console.error(
            "Failed to update top up status",
            orderJson.error_description
          );
        }
      } catch (error) {
        if (!canceled) {
          pending = false;
          setPending(pending);
        }

        console.error("Failed to update top up status", error);
      }
    }

    id && fetchStatus(id);

    return () => {
      canceled = true;

      if (timeoutId) {
        window.clearTimeout(timeoutId);
      }
    };
  }, [pollingId, history, id]);

  return [pending, status, reset] as const;
}
