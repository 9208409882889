import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

export function SadHeadIcon({
  fill = "currentColor",
  className = "",
  height = "70",
  width = "70",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 70 70"
    >
      <path
        d="M35 70A35 35 0 1035.01.01 35 35 0 0035 70zm0-6.77A28.25 28.25 0 016.77 35 28.25 28.25 0 0135 6.77 28.25 28.25 0 0163.23 35 28.25 28.25 0 0135 63.23zM23.71 32.74a4.51 4.51 0 100-9.02 4.51 4.51 0 000 9.02zm22.58 0a4.51 4.51 0 10.01-9.02 4.51 4.51 0 00-.01 9.02zm2.92 20.66a3.4 3.4 0 00.44-4.77A19.03 19.03 0 0035 41.77c-5.67 0-11 2.5-14.65 6.86a3.38 3.38 0 105.2 4.34 12.28 12.28 0 0118.9 0 3.4 3.4 0 004.76.43z"
        fill={fill}
        fillRule="nonzero"
      />
    </svg>
  );
}
