import { AutocompleteField, LocationProviderOptions } from "common/search";
import {
  filterProvidersByName,
  LocationError,
  MyLocationAddress,
  useSearchLocation,
} from "maps";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LocationCoords, LocationSearchSuggestion } from "services/mapService";
import { TravelProvider } from "state/actions";
import { TravelPassProvider } from "utils";
import { useLocationSearchSuggestions } from "./useLocationSearchSuggestions";

type useSearchLocationAndProvidersProps = {
  initialText?: string;
  providers: TravelPassProvider[] | null;
  loadingProviders: boolean;
  userLocation: LocationCoords | null;
  searchLocation: LocationCoords | null;
  setSearchLocation: (
    location: LocationCoords | LocationSearchSuggestion | null
  ) => void;
  provider?: TravelPassProvider | null;
  setProvider?: (provider: TravelPassProvider | null) => void;
  previousLocation?: LocationSearchSuggestion | null;
  setPreviousLocation?: (LocationSearchSuggestion) => void;
  getLastLocation?: () => LocationSearchSuggestion | null;
  dragEnabled?: boolean;
  pinVisible?: boolean;
} & (
  | {
      allowMyLocation: true;
      myLocationAddress: MyLocationAddress | null;
      locationError?: LocationError;
    }
  | {
      allowMyLocation: false;
      myLocationAddress?: never;
      locationError?: never;
    }
);

export function useSearchLocationAndProviders({
  initialText,
  providers,
  loadingProviders,
  userLocation,
  searchLocation,
  setSearchLocation,
  provider,
  setProvider,
  previousLocation,
  setPreviousLocation,
  getLastLocation,
  dragEnabled = true,
  pinVisible = true,
  allowMyLocation,
  locationError,
  myLocationAddress,
}: useSearchLocationAndProvidersProps) {
  const { t } = useTranslation();

  const allProviders = useSelector((state) => state.passes.providers);

  const [setMapReferences] = useSearchLocation(
    searchLocation,
    setSearchLocation,
    dragEnabled,
    pinVisible
  );

  const [searchText, setSearchText] = useState(initialText ?? "");

  const [previousLocationDistance, setPreviousLocationDistance] = useState<
    number | null
  >(previousLocation?.distance || 0);

  const [
    locationSearchSuggestions,
    loadingLocationSearchSuggestions,
    locationSearchError,
  ] = useLocationSearchSuggestions(userLocation, searchText);

  const [locationSearchPlace, setLocationSearchPlace] =
    useState<LocationSearchSuggestion | null>(null);

  const providerOptions = useMemo(
    () =>
      filterProvidersByName(
        searchText,
        (providers || [])
          .map((provider) => {
            return allProviders.find(
              (allProvider) => allProvider.provider === provider
            );
          })
          // remove providers that are not allowed / available to the app
          // but defined
          .filter((provider) => !!provider) as TravelProvider[]
      ),
    [providers, searchText, allProviders]
  );

  const previousLocationOption = useMemo(() => {
    return previousLocation
      ? {
          ...previousLocation,
          distance: previousLocationDistance,
        }
      : null;
  }, [previousLocation, previousLocationDistance]);

  useEffect(() => {
    if (!userLocation) {
      setPreviousLocationDistance(null);
      return;
    }

    if (previousLocation) {
      setPreviousLocationDistance(
        new H.geo.Point(previousLocation.lat, previousLocation.lng).distance(
          userLocation
        )
      );
    }
  }, [previousLocation, userLocation]);

  const locationSuggestionSelected = (option: LocationSearchSuggestion) => {
    setLocationSearchPlace(option);

    setSearchLocation(option);

    setPreviousLocation && setPreviousLocation(option);
  };

  const providerSelected = (provider: TravelProvider) => {
    setProvider?.(provider.provider);
  };

  const searchCancelled = () => {
    const lastLocation = getLastLocation && getLastLocation();

    setSearchText(lastLocation?.label || "");
  };

  const searchTextChanged = (text: string) => {
    setSearchText(text);

    setLocationSearchPlace(null);
  };

  const getSelectedValue = () => {
    if (loadingLocationSearchSuggestions) {
      return searchText;
    }

    if (provider) {
      return t(`providers_names.${provider}`);
    }

    if (locationSearchPlace) {
      return locationSearchPlace.label;
    }

    return searchText;
  };

  const locationSearchSelected = () => {
    if (!provider && locationSearchPlace) {
      setSearchLocation(locationSearchPlace);
    }
  };

  const renderOptions = () => (
    <LocationProviderOptions
      previousLocation={previousLocationOption}
      searchText={searchText}
      locations={locationSearchSuggestions}
      loadingLocations={loadingLocationSearchSuggestions}
      providers={providerOptions}
      loadingProviders={loadingProviders}
      locationSelected={locationSuggestionSelected}
      providerSelected={providerSelected}
      allowMyLocation={allowMyLocation}
      userLocation={userLocation}
      myLocationAddress={myLocationAddress}
      locationError={locationError}
    />
  );

  const onClear = () => {
    if (provider) {
      setProvider?.(null);
    }

    if (locationSearchPlace) {
      setLocationSearchPlace(null);

      setSearchLocation({
        lat: locationSearchPlace.lat,
        lng: locationSearchPlace.lng,
      });
    }

    searchTextChanged("");
  };

  const backToLocation = () => {
    setProvider?.(null);

    const lastLocation = getLastLocation && getLastLocation();

    setLocationSearchPlace(lastLocation?.label ? lastLocation : null);

    setSearchText(lastLocation?.label || "");
  };

  const renderSearchField = () => (
    <AutocompleteField
      className="search-location-and-providers-field"
      placeholder={t("map.search_placeholder")}
      options={[]}
      loading={loadingLocationSearchSuggestions}
      value={searchText}
      selectedValue={getSelectedValue()}
      onChange={searchTextChanged}
      onSelect={() => undefined}
      onCancel={searchCancelled}
      onSearch={locationSearchSelected}
      onBack={provider && previousLocation ? backToLocation : undefined}
      onClear={getSelectedValue() ? onClear : undefined}
      renderCustomOptions={renderOptions}
    />
  );

  return [setMapReferences, renderSearchField, locationSearchError] as const;
}
