import { DefaultIconProps } from "icons/props";
import { ReactElement } from "react";

export function BellIcon({
  className = "",
  fill = "currentColor",
  height = "20",
  width = "20",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.535 12.296H6.362v-.918l.61-.297c.464-.226.752-.681.752-1.188V7.648c0-1.465 1.222-2.656 2.725-2.656 1.502 0 2.724 1.191 2.724 2.656v2.245c0 .507.289.962.753 1.188l.61.297v.918zm-4.086 1.991c-.502 0-.937-.268-1.173-.664h2.346c-.237.396-.671.664-1.173.664zm5.072-3.914l-.986-.48V7.648c0-1.86-1.316-3.422-3.087-3.859a1.016 1.016 0 00-1-.789c-.49 0-.9.339-.998.79-1.771.436-3.088 1.997-3.088 3.858v2.245l-.985.48a.663.663 0 00-.377.595v1.992c0 .366.305.663.681.663h2.14a2.71 2.71 0 002.628 1.992 2.71 2.71 0 002.628-1.992h2.14a.673.673 0 00.68-.663v-1.992a.663.663 0 00-.376-.595z"
        fill={fill}
      />
    </svg>
  );
}
