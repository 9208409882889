import { ReactElement } from "react";
import { animated, useSpring } from "react-spring";
import "./ProgressBar.scss";

export type ProgressBarProps = {
  className?: string;
  value: number;
  total: number;
};

export function ProgressBar({
  className = "",
  value,
  total,
}: ProgressBarProps): ReactElement {
  const progressStyle = useSpring({
    width: `${(value / total) * 100}%`,
  });

  return (
    <div className={`mobea__progress-bar ${className}`}>
      <animated.div
        className="mobea__progress-bar__progress"
        style={progressStyle}
      />
    </div>
  );
}
