import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

export function PreviousIcon({
  fill = "currentColor",
  className = "",
  height = "24",
  width = "24",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 21c2.5 0 4.625-.875 6.375-2.625S23 14.5 23 12s-.875-4.625-2.625-6.375S16.5 3 14 3s-4.625.875-6.375 2.625S5 9.5 5 12H2l3.89 3.89.048.141L10.015 12h-3c0-1.938.68-3.586 2.039-4.945 1.36-1.36 3.008-2.04 4.945-2.04 1.938 0 3.586.68 4.945 2.04 1.36 1.36 2.04 3.008 2.04 4.945 0 1.938-.68 3.586-2.04 4.945-1.36 1.36-3.008 2.04-4.945 2.04-1.938 0-3.578-.688-4.922-2.063l-1.453 1.453C9.406 20.125 11.531 21 14 21zm3.281-5.438l.703-1.218-3.468-2.11V8.016h-1.5v4.968l4.265 2.579z"
        fill={fill}
      />
    </svg>
  );
}
