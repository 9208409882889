import { css } from "@emotion/css";
import { useModal } from "common/hooks/useModal";
import { SadIcon } from "icons/SadIcon";
import { useTranslation } from "react-i18next";

export function useCustomerExistsModal() {
  const { t } = useTranslation();

  return useModal({
    confirmText: t("shared.ok"),
    title: t("set_password.error_title"),
    type: "error",
    className: css({
      "& svg": {
        color: "var(--color-error)",
        margin: "1rem",
      },
    }),
    children: (
      <>
        <SadIcon width="37" height="37" />
        <p>{t("register.error_customer_exists")}</p>
      </>
    ),
  });
}
