import "@here/maps-api-for-javascript/bin/mapsjs-ui.css";
import "@here/maps-api-for-javascript/bin/mapsjs.bundle";
import { useBooleanState } from "common/hooks";
import { MobeaModal } from "common/modal";
import { BottomSlider, BottomSliderRef } from "common/navigation";
import { MapOfflineOverlay } from "common/network/MapOfflineOverlay";
import {
  MapViewPage,
  SearchLocationFieldContainerStyles,
} from "common/page/MapViewPage";
import { MyLocationIcon } from "icons/navigation";
import {
  LocationError,
  MapInitPayload,
  useCenteredMap,
  useLocationUpdates,
  useMapInitializer,
  useProviderDataMarkers,
} from "maps";
import { useSearchLocationAndProviders } from "maps/effects/useSearchLocationAndProviders";
import { LocationAccessDeniedDialog } from "maps/LocationAccessDeniedDialog";
import { useMapData } from "pages/map/hooks/useMapData";
import { useRadiusCircle } from "pages/map/hooks/useRadiusCircle";
import { ReactElement, ReactNode, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LocationCoords, ProviderData } from "services/mapService";
import { TravelPassProvider } from "utils";

const INITIAL_MAP_ZOOM = 14;
const TRAVEL_PASS_TYPES = [];
const STATION_NAME_REGEX = /(\d+)-(.*)/;
const MAP_TOP_BOTTOM_PADDING = 100;

const H = window.H;

export interface BikeMapPageProps {
  provider: TravelPassProvider;
  searchLocation: LocationCoords | null;
  selectedStation: ProviderData | null;
  stationError: any;
  searchRadiusList: number[];
  tutorial: ReactNode;
  children: ReactNode;
  headerInSecondaryColor?: boolean;
  mapActionsOffsetSliderOpened?: number;
  mapActionsOffsetSliderClosed?: number;

  setRoutingMapGroup: (group: H.map.Group) => void;

  goToTicket: () => void;
  onSearchLocationChange: (location: LocationCoords | null) => void;
  onSelectedStationChange: (selected: ProviderData | null) => void;
}

export function BikeMapPage({
  provider,
  searchLocation,
  selectedStation,
  tutorial,
  children,
  stationError,
  searchRadiusList,
  headerInSecondaryColor = false,
  mapActionsOffsetSliderOpened = 260,
  mapActionsOffsetSliderClosed = 0,
  setRoutingMapGroup,
  goToTicket,
  onSearchLocationChange,
  onSelectedStationChange,
}: BikeMapPageProps): ReactElement {
  const { t } = useTranslation();

  const user = useSelector((state) => state.user);

  const [mapRef, mapClassRef, onInit, behaviourRef] = useMapInitializer(
    user.language,
    INITIAL_MAP_ZOOM
  );

  const radiusGroupRef = useRef<H.map.Group | null>(null);

  const [userLocation, setUserLocationGroup, locationError] =
    useLocationUpdates();

  const [searchRadius, stations, loadingStations, stationsError] = useMapData(
    searchLocation,
    TRAVEL_PASS_TYPES,
    provider,
    true,
    searchRadiusList
  );

  const [setStationsDataMapReferences] = useProviderDataMarkers(
    stations,
    selectedStation,
    onSelectedStationChange
  );

  const [
    setSearchLocationMapReferences,
    renderLocationSearchField,
    locationSearchError,
  ] = useSearchLocationAndProviders({
    providers: null,
    loadingProviders: false,
    userLocation,
    searchLocation,
    setSearchLocation: onSearchLocationChange,
    allowMyLocation: false,
  });

  const [errorDialogVisible, showErrorDialog, hideErrorDialog] =
    useBooleanState();

  const [
    locationErrorDialogVisible,
    showLocationErrorDialog,
    hideLocationErrorDialog,
  ] = useBooleanState();

  const bottomSliderRef = useRef<BottomSliderRef>(null);

  const bottomSliderVisible = !!selectedStation && !stationError;

  // set search location based on initial user location
  useEffect(() => {
    if (searchLocation) {
      return;
    }

    if (userLocation) {
      onSearchLocationChange(userLocation);
    }
    // only on user location change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLocation]);

  // customize map
  useEffect(() => {
    const customizeMap = ({ map }: MapInitPayload) => {
      const userLocationGroup = new H.map.Group({ zIndex: 1 });

      setUserLocationGroup(userLocationGroup);

      map.addObject(userLocationGroup);

      radiusGroupRef.current = new H.map.Group({ zIndex: 2 });

      map.addObject(radiusGroupRef.current!);

      const searchLocationGroup = new H.map.Group({ zIndex: 5 });

      map.addObject(searchLocationGroup);

      setSearchLocationMapReferences(
        map,
        searchLocationGroup,
        behaviourRef.current!
      );

      const routingGroup = new H.map.Group({ zIndex: 3 });

      setRoutingMapGroup(routingGroup);

      map.addObject(routingGroup);

      const clusteringProvider = new H.clustering.Provider([], {
        min: 7,
        max: 23,
        clusteringOptions: {
          eps: 32,
          minWeight: 2,
        },
      });

      const clusteringLayer = new H.map.layer.ObjectLayer(clusteringProvider);

      map.addLayer(clusteringLayer);

      map
        .getViewPort()
        .setPadding(MAP_TOP_BOTTOM_PADDING, 0, MAP_TOP_BOTTOM_PADDING, 0);

      setStationsDataMapReferences(map, clusteringProvider);
    };

    onInit.then(customizeMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useCenteredMap(
    searchLocation,
    mapClassRef,
    mapClassRef.current?.getZoom() || INITIAL_MAP_ZOOM
  );

  useRadiusCircle(mapClassRef, radiusGroupRef, searchLocation, searchRadius);

  // initialize map tap behavior
  useEffect(() => {
    const map = mapClassRef.current;

    if (!map) {
      return;
    }

    const mapClicked = (event) => {
      if (selectedStation) {
        onSelectedStationChange(null);
      } else {
        onSearchLocationChange(
          map.screenToGeo(
            event.currentPointer.viewportX,
            event.currentPointer.viewportY
          )
        );
      }
    };

    map.addEventListener("tap", mapClicked);

    return () => {
      map.removeEventListener("tap", mapClicked);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapClassRef.current, selectedStation]);

  // show error dialog on error
  useEffect(() => {
    if (
      (stationsError && !loadingStations) ||
      locationSearchError ||
      stationError
    ) {
      showErrorDialog();
    }
  }, [
    showErrorDialog,
    stationsError,
    loadingStations,
    locationSearchError,
    stationError,
  ]);

  const useMyLocation = () => {
    if (userLocation) {
      mapClassRef.current?.setCenter(userLocation, true);

      mapClassRef.current?.setZoom(INITIAL_MAP_ZOOM, true);
    } else if (locationError === LocationError.PermissionDenied) {
      showLocationErrorDialog();
    }
  };

  const getBottomSliderTitle = () => {
    if (!selectedStation) {
      return "";
    }

    const match = selectedStation.name.match(STATION_NAME_REGEX);

    return match ? `${match[1]} - ${match[2]}` : selectedStation?.name;
  };

  return (
    <MapViewPage
      mapRef={mapRef}
      title={t("velo_map.find_station")}
      onNavBack={goToTicket}
      pageClassName="bike-map"
      className={!bottomSliderVisible ? "full" : ""}
      headerHeight="minimal"
      customCloseButton={tutorial}
      mapActionsOffset={
        bottomSliderVisible
          ? mapActionsOffsetSliderOpened
          : mapActionsOffsetSliderClosed
      }
      headerInSecondaryColor={headerInSecondaryColor}
      css={[
        SearchLocationFieldContainerStyles,
        {
          ".m_page__header": {
            zIndex: 20,
            padding: "20px 24px 16px",

            ".mobea-ticket-detail__info": {
              width: 24,
              height: 24,
              position: "static",
              padding: 0,
            },
            ".map-container": {
              ".H_logo": {
                transform: "translate(-10px, -70px)",
              },
              ".H_copyright": {
                transform: "translate(-10px, -90px)",
              },
            },
          },
        },
      ]}
    >
      <div className="search-location-field-container">
        {renderLocationSearchField()}
      </div>

      <div
        className="map-actions"
        css={{
          bottom: 132,
        }}
      >
        <div className="map-action" onClick={useMyLocation}>
          <MyLocationIcon />
        </div>
      </div>

      <BottomSlider
        key={selectedStation?.id}
        ref={bottomSliderRef}
        title={getBottomSliderTitle()}
        hidden={!bottomSliderVisible}
        visibleHeight={70}
        defaultOpen={true}
        css={{
          ".mobea-bottom-slider__content": {
            paddingRight: 0,
            paddingLeft: 0,
          },
        }}
      >
        {children}
      </BottomSlider>

      {errorDialogVisible && (
        <MobeaModal
          title={t("shared.oops")}
          onConfirm={hideErrorDialog}
          type="error"
          confirmText={t("shared.ok")}
        >
          <p>{t("map.error")}</p>
        </MobeaModal>
      )}

      {locationErrorDialogVisible && (
        <LocationAccessDeniedDialog hide={hideLocationErrorDialog} />
      )}
      <MapOfflineOverlay shadow />
    </MapViewPage>
  );
}
