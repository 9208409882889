import { ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import { animated, useSpring } from "react-spring";
import { useGesture } from "react-use-gesture";
import { Steps } from "../steps/Steps";

const PAGE_OFFSET = -10;
const PAGE_GAP = 8;
const CHANGE_THRESHOLD = 100;

export type CarouselProps = {
  className?: string;
  stepClassName?: string;
  pages: ReactNode[];
  defaultPosition?: number;
  position?: number;
  stepsPlacement?: "top" | "bottom" | "none";
  onChange?(position: number): void;
  pageOffset?: number;
  pageGap?: number;
};

export function Carousel({
  className,
  pages,
  defaultPosition = 0,
  stepsPlacement = "top",
  position: controlledPosition,
  onChange,
  pageOffset = PAGE_OFFSET,
  pageGap = PAGE_GAP,
}: CarouselProps): ReactElement {
  const containerRef = useRef<HTMLDivElement>(null);

  const containerPosition = useRef(0);

  const [position, setPosition] = useState(defaultPosition);

  const effectivePosition = controlledPosition ?? position;

  const [containerStyle, setContainerStyle] = useSpring(() => ({
    transform: "translateX(0px)",
  }));

  useGesture(
    {
      onDrag: ({ event, movement: [x] }) => {
        if (x > 0) {
          event?.preventDefault();
        }

        setContainerStyle({
          transform: `translateX(${Math.min(
            -pageOffset,
            containerPosition.current + x
          )}px)`,
        });
      },
      onDragEnd: ({ movement: [x] }) => {
        let newPosition = effectivePosition;

        if (x > CHANGE_THRESHOLD) {
          newPosition = Math.max(effectivePosition - 1, 0);
        } else if (x < -CHANGE_THRESHOLD) {
          newPosition = Math.min(effectivePosition + 1, pages.length - 1);
        }

        if (newPosition !== effectivePosition) {
          onChange?.(newPosition);
          setPosition(newPosition);
        } else {
          setContainerStyle({
            transform: `translateX(${containerPosition.current}px)`,
          });
        }
      },
    },
    {
      domTarget: containerRef,
      eventOptions: { passive: false },
    }
  );

  useEffect(() => {
    const pageWidth = containerRef.current!.clientWidth;

    const getContainerPosition = () => {
      if (effectivePosition === 0) {
        return pageOffset;
      } else if (effectivePosition === pages.length - 1) {
        return (
          -pageWidth * effectivePosition -
          pageGap * effectivePosition -
          pageOffset
        );
      } else {
        return -pageWidth * effectivePosition - pageGap * effectivePosition;
      }
    };

    containerPosition.current = getContainerPosition();

    setContainerStyle({
      transform: `translateX(${containerPosition.current!}px)`,
    });
  }, [effectivePosition, pages.length, setContainerStyle, pageOffset, pageGap]);

  const steps = (
    <Steps
      css={{ justifyContent: "center" }}
      currentStep={effectivePosition}
      stepCount={pages.length}
    />
  );

  return (
    <div
      className={className}
      css={{
        display: "flex",
        flexDirection: "column",
        overflowX: "hidden",
        touchAction: "pan-y",
      }}
    >
      {stepsPlacement === "top" && steps}

      <animated.div
        ref={containerRef}
        css={{
          flexGrow: 1,
          display: "flex",
          alignItems: "stretch",
          margin:
            stepsPlacement === "top"
              ? "0.75rem 1.5rem 0 1.5rem"
              : "0 1.5rem 0.75rem 1.5rem",
        }}
        style={containerStyle}
      >
        {pages.map((page: ReactNode, index: number) => (
          <div
            key={index}
            css={{
              userSelect: "none",
              flex: "0 0 100%",
              "&:not(:first-of-type)": {
                marginLeft: "0.5rem",
              },
            }}
            className={effectivePosition === index ? "active" : "inactive"}
          >
            {page}
          </div>
        ))}
      </animated.div>

      {stepsPlacement === "bottom" && steps}
    </div>
  );
}
