import { InputGroup } from "common/forms";
import { MobeaModal } from "common/modal";
import { MouseEvent, ReactElement, useState } from "react";
import DayPicker from "react-day-picker";
import { useTranslation } from "react-i18next";
import {
  formatDateOfBirth,
  formatToServiceDate,
  getDaysOfTheWeekShort,
  getFirstDayOfTheWeek,
  getMonths,
} from "utils";
import "./DateOfBirthPicker.scss";
import { convertToDate, CURRENT_YEAR } from "./dateOfBirthUtils";
import { YearMonthPicker } from "./YearMonthPicker";

export interface DateOfBirthPickerProps {
  title: string;
  dateInputLabel: string;
  className?: string;
  selectedDate?: number | string;
  locale: string;
  disabled?: boolean;
  endYear?: number;
  onChange: (date: number, formattedDate: string) => void;
}

export function DateOfBirthPicker({
  title,
  dateInputLabel,
  className = "",
  locale,
  selectedDate,
  endYear = CURRENT_YEAR,
  disabled = false,

  onChange,
}: DateOfBirthPickerProps): ReactElement {
  const { t } = useTranslation();

  const daysOfTheWeek = getDaysOfTheWeekShort(t, locale);

  const months = getMonths(t, locale);

  const [date, setDate] = useState<Date | undefined>(
    convertToDate(selectedDate)
  );

  const [dateModalShown, setDateModalShown] = useState(false);

  const openDate = (e: MouseEvent) => {
    e.preventDefault();

    setDateModalShown(true);
  };

  const closeDate = () => setDateModalShown(false);

  const onDateChange = (value: Date, modifiers: DayPicker.DayModifiers) =>
    !modifiers.disabled && setDate(value);

  const onYearMonthChange = (value: Date) => setDate(value);

  const onCancel = () => {
    // reset date when canceled
    setDate(convertToDate(selectedDate));

    closeDate();
  };

  const onConfirm = () => {
    closeDate();

    date && onChange(date.getTime(), formatToServiceDate(date));
  };

  const dateFormatted = selectedDate
    ? formatDateOfBirth(selectedDate, locale)
    : "";

  return (
    <>
      <InputGroup
        className={className}
        label={dateInputLabel}
        type="text"
        name="date"
        value={dateFormatted}
        placeholder={dateInputLabel}
        toggleLabelAndPlaceholder
        inputAttributes={{
          onClick: disabled ? undefined : openDate,
          autoComplete: "off",
          readOnly: true,
        }}
        disabled={disabled}
      />

      {dateModalShown && (
        <MobeaModal
          className="mobea__datepicker-modal mobea__date-of-birth-picker-modal"
          onConfirm={onConfirm}
          title={title}
          confirmText={t("shared.select_this_date")}
          confirmDisabled={!date || date.valueOf() > Date.now()}
          onClose={onCancel}
        >
          <DayPicker
            className="mobea__datepicker__date-of-birth"
            numberOfMonths={1}
            firstDayOfWeek={getFirstDayOfTheWeek(locale)}
            selectedDays={date}
            locale={locale}
            months={months}
            month={date}
            weekdaysShort={daysOfTheWeek}
            captionElement={({ date }) => (
              <YearMonthPicker
                date={date}
                months={months}
                endYear={endYear}
                onChange={onYearMonthChange}
              />
            )}
            disabledDays={{
              after: new Date(),
            }}
            toMonth={new Date()}
            onDayClick={onDateChange}
            onMonthChange={onYearMonthChange}
          />
        </MobeaModal>
      )}
    </>
  );
}
