import { CheckboxGroup, CheckboxGroupShape, MobeaButton } from "common/forms";
import { MobeaModal } from "common/modal";
import { Spinner } from "common/Spinner";
import { InfoIcon } from "icons/InfoIcon";
import { PersonSearchIcon } from "icons/PersonSearchIcon";
import { QuestionMarkIcon } from "icons/QuestionMarkIcon";
import { ReactElement, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { NmbsTicket } from "services/nmbsService";
import { formatCurrency } from "utils";
import { AppColors } from "utils/colors";

type Props = {
  locale: string;
  tickets: NmbsTicket[];
  selected: NmbsTicket | null;
  loading: boolean;
  onChange: (ticket: NmbsTicket | null) => void;
  goBack(): void;
  onConfirm(): void;
};

export function NmbsChooseTicket({
  locale,
  tickets,
  loading,
  selected,
  onChange,
  goBack,
  onConfirm,
}: Props): ReactElement {
  const { t } = useTranslation();

  const [infoTicket, setInfoTicket] = useState<NmbsTicket | null>(null);

  const showTicketInfo = (ticket: NmbsTicket) => (e) => {
    e.preventDefault();

    e.stopPropagation();

    setInfoTicket(ticket);
  };

  const toTicket = (ticket: NmbsTicket) => (
    <div
      key={ticket.type}
      className="mobea-order-nmbs__choose-ticket__tickets__ticket"
      onClick={() => onChange(ticket)}
    >
      <div className="check-name-info">
        <CheckboxGroup
          name={`check_${ticket.type}`}
          shape={CheckboxGroupShape.Circle}
          label={
            <div className="check-name-info__name">
              {t(`order_nmbs.ticket.${ticket.type}.name`)}
            </div>
          }
          isChecked={selected == ticket}
          onChange={() => undefined}
        />
        <div className="check-name-info__icon">
          <InfoIcon
            fill={AppColors.GRAY_300}
            height="24"
            width="24"
            onClick={showTicketInfo(ticket)}
          />
        </div>
      </div>
      <div className="description-price">
        <div className="description-price__description">
          {t(`order_nmbs.ticket.${ticket.type}.description`)}
        </div>
        <div className="description-price__price">
          {formatCurrency(ticket.price, locale)}
        </div>
      </div>
    </div>
  );

  return (
    <section className="mobea-order-nmbs__choose-ticket">
      {loading && <Spinner />}

      {!loading && tickets.length > 0 && (
        <>
          <div className="mobea-order-nmbs__choose-ticket__tickets">
            {tickets.map(toTicket)}
          </div>
          <div className="mobea-order-nmbs__choose-ticket__confirm-button">
            <MobeaButton onClick={onConfirm} disabled={!selected}>
              {t("order_nmbs.add_passenger_details")}
            </MobeaButton>
          </div>
        </>
      )}

      {!loading && tickets.length == 0 && (
        <div className="mobea-order-nmbs__choose-ticket__no-result">
          <PersonSearchIcon width="88" height="88" />
          <div className="mobea-order-nmbs__choose-ticket__no-result__text">
            {t("order_nmbs.no_tickets_found")}
          </div>
          <div
            className="mobea-order-nmbs__choose-ticket__no-result__change-parameters"
            onClick={goBack}
          >
            {t("order_nmbs.change_parameters")}
          </div>
        </div>
      )}

      {infoTicket && (
        <MobeaModal
          title={t(`order_nmbs.ticket.${infoTicket.type}.name`)}
          confirmText={t("shared.got_it")}
          onConfirm={() => setInfoTicket(null)}
        >
          <QuestionMarkIcon width="88" height="88" fill={AppColors.GRAY_100} />
          <p>
            <Trans i18nKey={`order_nmbs.ticket.${infoTicket.type}.info`}>
              <span className="mobea__arial" /> Ticket info
            </Trans>
          </p>
        </MobeaModal>
      )}
    </section>
  );
}
