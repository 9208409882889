/* eslint-disable no-undef */

import { ApiErrorResponse, ApiErrors } from "utils/errors";
import { triggerApiIncident } from "utils/network";
import { API_ERROR_RESPONSE_CODES } from "./apiCall";

/**
 * Extends fetch with aborting request and emitting TimeoutError once timeout is reached
 * timeout is specified as part of request options
 *
 * @default timeout = 30s
 * @emits TimeoutError
 */
export function fetchTimeout(
  url: string,
  options: RequestInit & { timeout?: number } = {}
) {
  const { timeout = 20000 } = options;

  const controller = new AbortController();

  let timedOut = false;

  const id = setTimeout(() => {
    timedOut = true;
    controller.abort();
  }, timeout);

  // allow to pass own signal if desired
  return fetch(url, {
    signal: controller.signal,
    ...options,
  })
    .then((response) => {
      clearTimeout(id);

      return response;
    })
    .catch((error) => {
      // ensure that request was not aborted on purpose, but did timeout
      if (error.name === "AbortError" && timedOut) {
        const err = new Error("Request timeout");

        err.name = "TimeoutError";
        throw err;
      } else {
        // re-throw error if it's not the desired one
        throw error;
      }
    });
}

const emitApiIncident = (): ApiErrorResponse => {
  triggerApiIncident();

  return {
    error: true,
    error_code: ApiErrors.I_API_ISSUES,
    error_type: "no_content",
    error_description: "No content",
  };
};

const defaultJsonResponseMapper = (json) => {
  // do not modify response when it's AUTH error
  if (API_ERROR_RESPONSE_CODES.includes(json.code)) {
    return json;
  }

  return {
    ...json,
    error: !!json.error_code,
  };
};

/**
 * Extends fetch with aborting request and triggering API incident scenario
 * As well triggers API incident when incorrect response status is detected - 400, 500 - 600
 *
 * @param customResponseHandler to replace
 */
export function fetchTimeoutIncident<R>(
  url: string,
  options: RequestInit & { timeout?: number } = {},
  jsonResponseMapper: (json: any) => any = defaultJsonResponseMapper,
  customResponseHandler?: (response: Response) => Promise<R>
): Promise<R> {
  return fetchTimeout(url, options)
    .then((response) => {
      // @TODO maybe extend / adapt range based on further observations
      if (
        (response.status >= 500 && response.status < 600) ||
        response.status === 404
      ) {
        return emitApiIncident();
      } else if (customResponseHandler) {
        return customResponseHandler(response);
      }

      return response.json().then(jsonResponseMapper);
    })
    .catch((error) => {
      if (error.name === "TimeoutError") {
        // start incident and return error as would be provided by server
        return emitApiIncident();
      }

      // re-throw other errors for future handling
      throw error;
    });
}
