import { SpendingTrackingData, trackTicketPurchase } from "analytics/events";
import { useLoading } from "common/hooks/useLoading";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  NmbsOrderPayload,
  orderNmbsTicket,
  RawNmbsTicket,
} from "services/nmbsService";
import { getTicketDetail } from "services/travelPassService";
import {
  addActiveTravelPassAction,
  addPurchaseAction,
  NmbsTravelPass,
  TravelPass,
} from "state/actions";
import { ApiErrors, parseFloatWithComma, TravelPassProvider } from "utils";
import { normalizeNmbsTravelPass } from "utils/normalizers/nmbsNormalizer";

export function useNmbsOrder() {
  const history = useHistory();

  const dispatch = useDispatch();

  const [result, setResult] = useState<NmbsTravelPass[]>([]);

  const [failureCode, setFailureCode] = useState<string | null>(null);

  const [loading, setLoading, canceledRef] = useLoading("nmbsOrder");

  const order = useCallback(
    (orderRequest: NmbsOrderPayload | null) => {
      setResult([]);

      setFailureCode(null);

      async function orderNmbsTicketRequest(orderRequest: NmbsOrderPayload) {
        const trackingPayload: SpendingTrackingData = {
          action: TravelPassProvider.nmbs,
          label: "success" as "success" | "failure",
          value: orderRequest.price.toString(),
        };

        try {
          setLoading(true);

          const orderJson = await orderNmbsTicket(orderRequest, history);

          const setNoTicketError = () => {
            trackingPayload.label = "failure";

            trackTicketPurchase(trackingPayload);

            setFailureCode(ApiErrors.NMBS_NO_TICKET);
          };

          if (canceledRef.current) {
            return;
          }

          const tickets = [] as NmbsTravelPass[];

          const ticketErrors = [] as (string | null)[];

          const getFailureCode = () => {
            if (ticketErrors.length > 1) {
              if (ticketErrors[0] && ticketErrors[1]) {
                return ApiErrors.NMBS_RETURN_TICKET_ERROR;
              } else if (ticketErrors[0] && !ticketErrors[1]) {
                return ApiErrors.NMBS_SOURCE_TO_DESTINATION_TICKET_ERROR;
              } else if (!ticketErrors[0] && ticketErrors[1]) {
                return ApiErrors.NMBS_DESTINATION_TO_SOURCE_TICKET_ERROR;
              }
            } else if (ticketErrors[0]) {
              return ticketErrors[0] || ApiErrors.ORDER_ERROR;
            }

            return null;
          };

          for (const ticketResponse of orderJson) {
            if (ticketResponse.error) {
              ticketErrors.push(ticketResponse.error_code);

              continue;
            }

            const ticket = await getTicketDetail(
              ticketResponse.transaction_id,
              history
            );

            if (!ticket.error) {
              const normalizedTicket = normalizeNmbsTravelPass(
                ticket.response as RawNmbsTicket,
                {
                  id: ticket.id,
                  orderTimestamp: new Date(ticket.created).valueOf(),
                  price: parseFloatWithComma(ticket.amount) || 0,
                  travelEndDate: ticket.expire_at,
                  plan: ticket.plan,
                }
              );

              const ticketOverview: TravelPass = {
                types: normalizedTicket.types,
                provider: normalizedTicket.provider,
                price: normalizedTicket.price,
                id: normalizedTicket.id,
                orderTimestamp: normalizedTicket.orderTimestamp,
                expiration: normalizedTicket.expiration,
                plan: normalizedTicket.plan,
              };

              tickets.push(normalizedTicket);

              ticketErrors.push(null);

              trackTicketPurchase(trackingPayload);

              // dispatch even if navigated out
              dispatch(addActiveTravelPassAction(normalizedTicket));

              // add to history for case app goes offline so history is consistent
              dispatch(addPurchaseAction(ticketOverview));
            } else {
              setNoTicketError();
              break;
            }
          }

          setResult(tickets);

          const failureCode = getFailureCode();

          setFailureCode(failureCode);

          if (failureCode) {
            trackingPayload.label = "failure";

            trackTicketPurchase(trackingPayload);

            console.error(
              "Failed to fetch Nmbs order data",
              ticketErrors.filter((error) => !!error)[0]
            );
          }
        } catch (error) {
          if (!canceledRef.current) {
            setFailureCode(ApiErrors.ORDER_ERROR);
          }

          console.error("Failed to fetch Nmbs order data", error);
        } finally {
          setLoading(false);
        }
      }

      if (orderRequest !== null) {
        orderNmbsTicketRequest(orderRequest);
      }
    },
    [canceledRef, dispatch, history, setLoading]
  );

  const resetResult = () => {
    setResult([]);

    setLoading(false);

    setFailureCode(null);
  };

  return [order, result, loading, failureCode, resetResult] as const;
}
