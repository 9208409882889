import { TFunction } from "i18next";
import { formatDateWithLocale } from "utils";

export function formatDate(
  date: string | number | Date,
  language: string,
  withYear = false
) {
  const formattingOptions: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
    weekday: "short",
  };

  if (withYear) {
    formattingOptions.year = "numeric";
  }

  return formatDateWithLocale(date, language, formattingOptions);
}

export function todayString(locale: string) {
  return formatDate(new Date(Date.now()), locale);
}

export function yesterdayString(locale: string) {
  return formatDate(new Date(Date.now() - 24 * 3600 * 1000), locale);
}

export function translateDate(
  formatedDate: string,
  today: string,
  yesterday: string,
  t: TFunction
) {
  if (formatedDate === today) {
    return t("history.today");
  } else if (formatedDate === yesterday) {
    return t("history.yesterday");
  } else {
    return formatedDate;
  }
}

export type HistoryItemsGroup<T> = {
  date: string;
  items: T[];
};

export function groupHistoryItems<T>(
  items: T[],
  dateProp: keyof T,
  locale: string
): HistoryItemsGroup<T>[] {
  const blocks: HistoryItemsGroup<T>[] = [];

  const currentYear = new Date().getFullYear();

  let lastDate: string;

  let currentBlock: HistoryItemsGroup<T>;

  items.forEach((item, index) => {
    const orderDate = new Date(item[dateProp] as unknown as number);

    const orderDateString = formatDate(
      orderDate,
      locale,
      orderDate.getFullYear() !== currentYear
    );

    // always add header for first item and afterwards for separate days with purchase
    if (index === 0 || orderDateString !== lastDate) {
      currentBlock = {
        date: orderDateString,
        items: [],
      };

      blocks.push(currentBlock);

      lastDate = orderDateString;
    }

    currentBlock.items.push(item);
  });

  return blocks;
}
