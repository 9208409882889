import { AcceptConditionsModal } from "common/modal";
import { AddMoneyPage } from "pages/addMoney/AddMoneyPage";
import { PasswordPage } from "pages/changePassword/ChangePasswordPage";
import { ForgottenPasswordPage } from "pages/forgottenPassword/ForgottenPasswordPage";
import { HistoryPage } from "pages/history/HistoryPage";
import { HomePage } from "pages/home/HomePage";
import { InvalidLoginTokenPage } from "pages/invalidLoginToken/InvalidLoginTokenPage";
import { LoginPage } from "pages/login/LoginPage";
import { MoveasyTutorialPage } from "pages/moveasyTutorial/MoveasyTutorialPage";
import { MyWalletsPage } from "pages/myWallets/MyWalletsPage";
import { PreLoginPage } from "pages/preLogin/PreLoginPage";
import { ProfileRegisteredPage } from "pages/profile/ProfileRegisteredPage";
import { RefundBillingPage } from "pages/refundBilling/RefundBillingPage";
import { ResetPasswordPage } from "pages/resetPassword/ResetPasswordPage";
import { SetPasswordPage } from "pages/setPassword/SetPasswordPage";
import { SetPasswordLinkSendPage } from "pages/setPasswordLinkSend/SetPasswordLinkSendPage";
import { SetPasswordSuccessPage } from "pages/setPasswordSuccess/SetPasswordSuccessPage";
import { SettingsPage } from "pages/settings/SettingsPage";
import { TopUpDetailPage } from "pages/topUpDetail/TopUpDetailPage";
import { Route } from "react-router-dom";
import { Routes } from "utils";
import { RegisterPage } from "../pages/register/RegisterPage";

function getRoutesMoveasySecured(queryParams: Map<string, any>) {
  return [
    <Route key={Routes.Tutorial} path={Routes.Tutorial}>
      <MoveasyTutorialPage />
    </Route>,
    <Route key={Routes.TopUpDetail} path={Routes.TopUpDetail}>
      <TopUpDetailPage />
    </Route>,
    <Route key={Routes.History} path={Routes.History} exact>
      <HistoryPage />
    </Route>,
    <Route key={Routes.Profile} path={Routes.Profile}>
      <ProfileRegisteredPage />
    </Route>,
    <Route key={Routes.RefundBilling} path={Routes.RefundBilling}>
      <RefundBillingPage />
    </Route>,
    <Route key={Routes.MyWallets} path={Routes.MyWallets}>
      <MyWalletsPage />
    </Route>,
    <Route key={Routes.AddMoney} path={Routes.AddMoney}>
      <AddMoneyPage />
    </Route>,
    <Route key={Routes.Settings} path={Routes.Settings} exact>
      <SettingsPage />
    </Route>,
    <Route key={Routes.ChangePassword} path={Routes.ChangePassword}>
      <PasswordPage />
    </Route>,
    <Route key={Routes.SetPassword} path={Routes.SetPassword}>
      <SetPasswordPage />
    </Route>,
    <Route key={Routes.SetPasswordSuccess} path={Routes.SetPasswordSuccess}>
      <SetPasswordSuccessPage />
    </Route>,
    <Route key={Routes.Home} path={Routes.Home}>
      <AcceptConditionsModal />
      <HomePage />
    </Route>,
  ];
}

export const RoutesMoveasyPublic = [
  <Route key={Routes.SetPasswordLinkSend} path={Routes.SetPasswordLinkSend}>
    <SetPasswordLinkSendPage />
  </Route>,
  <Route key={Routes.Register} path={Routes.Register}>
    <RegisterPage />
  </Route>,
  <Route key={Routes.SetPasswordLinkSend} path={Routes.SetPasswordLinkSend}>
    <SetPasswordLinkSendPage />,
  </Route>,
  <Route key={Routes.InvalidLoginToken} path={Routes.InvalidLoginToken}>
    <InvalidLoginTokenPage />
  </Route>,
  <Route key={Routes.Login} path={Routes.Login}>
    <LoginPage />
  </Route>,
  <Route key={Routes.ResetPassword} path={Routes.ResetPassword}>
    <ResetPasswordPage />
  </Route>,
  <Route key={Routes.ForgottenPassword} path={Routes.ForgottenPassword}>
    <ForgottenPasswordPage />
  </Route>,
  <Route key={Routes.PreLogin} path={Routes.PreLogin}>
    <PreLoginPage />
  </Route>,
];

export function getRoutesMoveasyAll(queryParams: Map<string, any>) {
  return RoutesMoveasyPublic.concat(getRoutesMoveasySecured(queryParams));
}
