import * as Sentry from "@sentry/react";
import { ModalProvider } from "common/hooks/useModal";
import { ReactElement, ReactNode, useState } from "react";
import { ErrorInfo, ErrorPage } from "utils/logging/ErrorPage";

type Props = {
  children: ReactNode;
};

export function ErrorBoundary({ children }: Props): ReactElement {
  const [errorInfo, setErrorInfo] = useState<ErrorInfo | null>(null);

  const onError = (error: Error, componentStack: string, eventId: string) => {
    setErrorInfo({
      error,
      componentStack,
      eventId,
    });
  };

  const onReset = () => setErrorInfo(null);

  return (
    <>
      {errorInfo ? (
        <ModalProvider>
          <ErrorPage errorInfo={errorInfo} onReset={onReset} />
        </ModalProvider>
      ) : (
        <Sentry.ErrorBoundary onError={onError}>
          {children}
        </Sentry.ErrorBoundary>
      )}
    </>
  );
}
