import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

export function CircleCheckIcon({
  className = "",
  fill = "currentColor",
  height = "24px",
  width = "24px",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.59,7.58 L10,14.17 L6.41,10.59 L5,12 L10,17 L18,9 L16.59,7.58 Z M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M12,20 C7.58,20 4,16.42 4,12 C4,7.58 7.58,4 12,4 C16.42,4 20,7.58 20,12 C20,16.42 16.42,20 12,20 Z"
        fillRule="nonzero"
        fill={fill}
      />
    </svg>
  );
}
