import {
  BikeIcon,
  BusCarIcon,
  BusIcon,
  KickscooterIcon,
  ScooterIcon,
  ServicesIcon,
  TaxiIcon,
} from "icons/transportTypes";
import { WalkIcon } from "icons/WalkIcon";
import { TravelPassType } from "utils";
import { ProviderFilterType } from "./ProviderFilterType";

const dt = (value: string) => value;

export const ProvidersFilterOptions = [
  {
    name: dt("data.all"),
    icon: BusCarIcon,
    type: ProviderFilterType.All,
    types: [],
  },
  {
    name: dt("data.public_transport"),
    icon: BusIcon,
    type: ProviderFilterType.PublicTransport,
    types: [
      TravelPassType.Bus,
      TravelPassType.Train,
      TravelPassType.Tram,
      TravelPassType.Metro,
    ],
  },
  {
    name: dt("data.bikes"),
    icon: BikeIcon,
    type: ProviderFilterType.Bikes,
    types: [TravelPassType.Bike, TravelPassType.ElectricBike],
  },
  {
    name: dt("data.kickscooters"),
    icon: KickscooterIcon,
    type: ProviderFilterType.Kickscooters,
    types: [TravelPassType.Kickscooter],
  },
  {
    name: dt("data.cars,taxi"),
    icon: TaxiIcon,
    type: ProviderFilterType.CarsOrTaxi,
    types: [TravelPassType.Car, TravelPassType.Uber],
  },
  {
    name: dt("data.scooters"),
    icon: ScooterIcon,
    type: ProviderFilterType.Scooters,
    types: [TravelPassType.ElectricScooter],
  },
  {
    name: dt("data.walking"),
    icon: WalkIcon,
    type: ProviderFilterType.Walking,
    types: [],
  },
  {
    name: dt("data.services"),
    icon: ServicesIcon,
    type: ProviderFilterType.Services,
    types: [TravelPassType.Workspace],
  },
];
