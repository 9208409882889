import { LOCAL_STORAGE_KEY } from "utils/constants";

let savedData: any | null = null;

export function getAutoSavedState() {
  if (savedData === null) {
    try {
      // cast response to string if it is null - clear local data
      savedData = JSON.parse(
        window.localStorage.getItem(LOCAL_STORAGE_KEY) + ""
      );
    } catch (e) {
      console.warn("LocalStorage is disabled. Autosave won't work.");
    }
  }

  return savedData;
}
