import { useBooleanState } from "common/hooks";
import { MobeaModal } from "common/modal";
import { NmbsTravelPass } from "common/travelPasses";
import { MultiTicketDetail } from "common/travelPasses/MultiTicketDetail";
import { InfoIcon } from "icons/InfoIcon";
import { PhoneTicketIcon } from "icons/PhoneTicketIcon";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  NmbsTravelPass as NmbsTravelPassType,
  TravelPass,
} from "state/actions";
import { AppColors } from "utils/colors";
import { BackButtonBehaviour } from "utils/routing";

type Props = {
  backButtonBehaviour?: BackButtonBehaviour;
};

export function NmbsMultiTicketDetail({
  backButtonBehaviour = "goHome",
}: Props): ReactElement {
  const { t } = useTranslation();

  const locale = useSelector((state) => state.user.language);

  const [infoVisible, showInfo, hideInfo] = useBooleanState();

  const toPage = (pass: TravelPass) => (
    <NmbsTravelPass
      key={pass.id}
      {...(pass as NmbsTravelPassType)}
      locale={locale}
    />
  );

  return (
    <MultiTicketDetail
      backButtonBehaviour={backButtonBehaviour}
      toPage={toPage}
      info={
        <div className="mobea__multi-ticket-detail__info" onClick={showInfo}>
          <InfoIcon />
          {infoVisible && (
            <MobeaModal
              title={t("ticked_detail.my_ticket")}
              confirmText={t("shared.got_it")}
              image={<PhoneTicketIcon fill={AppColors.GRAY_100} />}
              onConfirm={hideInfo}
            >
              <p>{t("ticked_detail.my_ticket_info_text")}</p>
            </MobeaModal>
          )}
        </div>
      }
    />
  );
}
