import { ReactElement } from "react";

export function VlaanderLogo({ className = "" }): ReactElement {
  return (
    <svg
      className={className}
      viewBox="0 0 37 45"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#666666">
        <path stroke="#666666" d="M21 0l15.203 44.628" />
        <path d="M20.943 21.519c-1.135-.922-1.702 0-2.44-.058-.625-.058-1.192-1.037-1.646-.807-.908.461.34 2.132.908 2.478.51.288 1.135.576 1.249.634.737.346 1.021.865 1.135 1.671 0 .173 0 .634-.057.807-.34 1.44-2.838 2.766-4.257 1.729-.68-.461-1.305-1.095-1.476-2.132-.397-1.902-1.702-3.285-2.156-5.13-.284-1.094-.454-2.304-.738-3.457-.284-1.152-.568-2.305-.851-3.4-.228-.98-.682-2.478-1.022-3.342-1.362-3.63-1.987-3.343-1.987-3.343s.511.98 2.27 9.855c.057.345.739 4.264 1.193 5.532.17.46.454 1.44.68 1.844.568 1.268 2.1 3.17 2.157 4.898.057.98.17 1.844.17 2.594 0 .23.114 1.094.228 1.498.397 1.037 3.348 4.437 6.527 4.437v-2.593c-3.122 0-6.13-1.787-6.187-1.96-.057-.057.17-1.325.398-1.843.397-1.038 1.191-2.017 2.554-2.133 1.532-.115 2.44.346 3.235.346l.113-8.125zM7.265 12.183c-.114 1.96-3.065 4.553-3.973 6.281-.454.807-1.022 2.248-1.135 3.055-.398 2.305.113 3.745.68 5.07.966 2.364-.17 3.286.568 2.767.965-.807.795-2.593.738-3.746-.057-.922-.113-1.96 0-3.054.284-2.075 1.476-4.264 2.554-5.82 1.249-1.787.795-3.746.568-4.553M7.89 17.658s.34 1.556-1.022 5.474C3.348 33.39 9.989 34.37 11.918 36.79c0 0 .739-1.037-2.27-4.38-1.078-1.21-2.1-3.918-1.248-7.78 1.249-5.59-.51-6.972-.51-6.972M1.305 10.915c-.17-.634-.227-1.152-.17-1.67.17-2.421 2.327-3.4 2.838-3.747 0 0 .965-.691 1.078-1.383 0 0 1.022 2.536-1.305 3.977-1.135.806-1.873 1.67-2.44 2.823M6.754 8.553c.114.23 1.022 1.555-2.668 4.667-3.689 3.055-2.497 5.244-2.497 5.244s-3.916-2.19.568-6.05c4.484-3.804 3.575-4.61 3.575-4.61s.682.057 1.022.749M11.522 9.763c.624.057 1.021 2.074 2.497 2.535 1.078.346 2.213.173 2.497.807-.51.288-.113 1.153.398.98.397-1.153.567-5.705-5.392-4.322zm1.986.864c.057-.115.114 0 .284-.115.17-.173.34-.461.624-.519a.936.936 0 01.681.058c.114.057.057.346-.056.46-.17.174-.795-.114-.795.347 0 .749.965 0 1.476 0 .227 1.728-2.668 1.267-2.214-.23z" />
      </g>
    </svg>
  );
}
