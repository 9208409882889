import { DefaultIconProps } from "icons/props";
import { ReactElement } from "react";

export function DottLogo({
  className = "",
  width,
  height,
}: Pick<DefaultIconProps, "className" | "width" | "height">): ReactElement {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 84 28"
    >
      <path d="M62.2 21.5c-2.7-.4-2.5-3.2-2.5-5.4v-4.7h4.6a.7.7 0 00.7-.6v-5a.7.7 0 00-.7-.7h-4.6V.7A.7.7 0 0059 0h-5.5a.7.7 0 00-.7.7v14.6c0 2.8-.1 6 1.3 8.5 1.5 2.8 4.7 4 7.8 4h4.4a.7.7 0 00.6-.6v-5a.7.7 0 00-.6-.6l-4.1-.1zm17 0c-2.6-.4-2.5-3.2-2.5-5.4v-4.7h4.6a.7.7 0 00.7-.6v-5a.7.7 0 00-.7-.7h-4.6V.7A.7.7 0 0076 0h-5.5a.7.7 0 00-.7.7v14.6c0 2.8 0 6 1.3 8.5 1.5 2.8 4.7 4 7.8 4h4.4a.7.7 0 00.7-.6v-5a.7.7 0 00-.7-.6l-4-.1z" />
      <path
        fillRule="nonzero"
        d="M37.9 5.1a11.4 11.4 0 1011.4 11.5 11.4 11.4 0 00-3.4-8.1A11.4 11.4 0 0038 5zm0 16.9a5.4 5.4 0 01-5.5-5.4c0-3 2.5-5.5 5.5-5.5a5.4 5.4 0 015.4 5.5c0 1.4-.6 2.8-1.6 3.8a5.4 5.4 0 01-3.8 1.6zM16 .7V6A11.5 11.5 0 000 17.4c.6 8 9.4 13 16.5 9.4a11.5 11.5 0 006.3-10.2V.6a.7.7 0 00-.7-.6h-5.4a.7.7 0 00-.7.7zM11.5 22A5.4 5.4 0 016 16.6c0-3 2.5-5.5 5.5-5.5a5.4 5.4 0 015.4 5.5 5.5 5.5 0 01-5.4 5.4z"
      />
    </svg>
  );
}
