import { DefaultIconProps } from "icons/props";
import { ReactElement } from "react";

export function GetaroundLogo({
  className = "",
  width,
  height,
}: Pick<DefaultIconProps, "className" | "width" | "height">): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 760 220"
    >
      <path
        d="M136.7 31.7c-26.6 0-43.5 18-43.5 42.9 0 26.2 16.7 43.6 45 43.6 12.4 0 26.5-4.3 35.2-13.2l-13-12.8c-4.6 4.8-14.4 7.6-21.9 7.6-14.3 0-23-7.3-24.4-17.4h64c3-33.8-14-50.7-41.4-50.7zm-22.3 33.9c3-10.6 12-16 23-16 11.6 0 19.9 5.4 21.2 16z"
        fill="#b01aa7"
      />
      <path
        d="M218.5 98.8c-6.3 0-10.6-3.8-10.6-11.6V51.8h22.8V34.4H208V11l-20.3 2.2v73.9c0 20.4 11.6 30.5 29.4 29.9a45.2 45.2 0 0016.8-3.5l-5.7-17.3a23 23 0 01-9.8 2.5z"
        fill="#b01aa7"
      />
      <path
        d="M372 32c-8.2 0-16.7 1.5-23 11.7l-1.5-9.5h-18.8V116H349V72.9c0-15.1 9.8-20.8 20.4-20.8 6.7 0 9.5.9 13.6 4.4l9.2-17.6c-4.5-4.5-11.7-6.8-20.1-6.8z"
        fill="#b01aa7"
      />
      <path
        d="M431.7 32.5a41 41 0 00-42.4 42.7c0 23.6 16 42.7 42.5 42.7s42.7-19 42.7-42.7a41.4 41.4 0 00-42.8-42.7zm.1 66.8c-14.7 0-22.2-11.6-22.2-24S417.2 51 431.8 51c13.7 0 22.3 12 22.3 24.2 0 12.5-7.5 24.1-22.3 24.1z"
        fill="#b01aa7"
      />
      <path
        d="M544 76c0 12.5-8.4 23-20.8 23-13 0-19.8-9.5-19.8-22V34.3h-20.3v43c0 25.4 14.3 40.2 34 40.2 11.4 0 19.2-4 27.7-12.3l1.3 11.1h18v-82h-20z"
        fill="#b01aa7"
      />
      <path
        d="M622.1 32.9a36.9 36.9 0 00-26.9 12.3L594 34h-18.1v82h20V74.2c0-12.5 8.6-23 21-23 13 0 19.8 9.5 19.8 22V116h20.2V73c0-25.7-15.4-40.1-34.7-40.1z"
        fill="#b01aa7"
      />
      <path
        d="M733.4 0v45.3c-4.8-8.4-18.3-13.1-27-13.1-23.8 0-41.6 14.6-41.6 42.8 0 27 18.1 42.9 42.2 42.9 10 0 20-3.3 26.4-13.1l1.3 11.3h19V0zm-25.1 99.2a23 23 0 01-23.3-24c0-14.9 10.3-24 23.3-24a23.4 23.4 0 0123.7 24c0 14.6-10.9 24-23.7 24z"
        fill="#b01aa7"
      />
      <path
        d="M136.7 31.7c-26.6 0-43.5 18-43.5 42.9 0 26.2 16.7 43.6 45 43.6 12.4 0 26.5-4.3 35.2-13.2l-13-12.8c-4.6 4.8-14.4 7.6-21.9 7.6-14.3 0-23-7.3-24.4-17.4h64c3-33.8-14-50.7-41.4-50.7zm-22.3 33.9c3-10.6 12-16 23-16 11.6 0 19.9 5.4 21.2 16z"
        fill="#b01aa7"
      />
      <path
        d="M218.5 98.8c-6.3 0-10.6-3.8-10.6-11.6V51.8h22.8V34.4H208V11l-20.3 2.2v73.9c0 20.4 11.6 30.5 29.4 29.9a45.2 45.2 0 0016.8-3.5l-5.7-17.3a23 23 0 01-9.8 2.5z"
        fill="#b01aa7"
      />
      <path
        d="M372 32c-8.2 0-16.7 1.5-23 11.7l-1.5-9.5h-18.8V116H349V72.9c0-15.1 9.8-20.8 20.4-20.8 6.7 0 9.5.9 13.6 4.4l9.2-17.6c-4.5-4.5-11.7-6.8-20.1-6.8z"
        fill="#b01aa7"
      />
      <path
        d="M431.7 32.5a41 41 0 00-42.4 42.7c0 23.6 16 42.7 42.5 42.7s42.7-19 42.7-42.7a41.4 41.4 0 00-42.8-42.7zm.1 66.8c-14.7 0-22.2-11.6-22.2-24S417.2 51 431.8 51c13.7 0 22.3 12 22.3 24.2 0 12.5-7.5 24.1-22.3 24.1z"
        fill="#b01aa7"
      />
      <path
        d="M544 76c0 12.5-8.4 23-20.8 23-13 0-19.8-9.5-19.8-22V34.3h-20.3v43c0 25.4 14.3 40.2 34 40.2 11.4 0 19.2-4 27.7-12.3l1.3 11.1h18v-82h-20z"
        fill="#b01aa7"
      />
      <path
        d="M622.1 32.9a36.9 36.9 0 00-26.9 12.3L594 34h-18.1v82h20V74.2c0-12.5 8.6-23 21-23 13 0 19.8 9.5 19.8 22V116h20.2V73c0-25.7-15.4-40.1-34.7-40.1z"
        fill="#b01aa7"
      />
      <path
        d="M733.4 0v45.3c-4.8-8.4-18.3-13.1-27-13.1-23.8 0-41.6 14.6-41.6 42.8 0 27 18.1 42.9 42.2 42.9 10 0 20-3.3 26.4-13.1l1.3 11.3h19V0zm-25.1 99.2a23 23 0 01-23.3-24c0-14.9 10.3-24 23.3-24a23.4 23.4 0 0123.7 24c0 14.6-10.9 24-23.7 24z"
        fill="#b01aa7"
      />
      <path
        d="M303.4 35.5c-9.7-5.4-29-5.8-39.5-3a28.3 28.3 0 00-22 26.4l19.3 4.4c1.5-9.6 4.4-12.8 12.6-14.2 6-1 14.4-.7 19.1 4 2.6 2.7 2.9 4 2.5 8.3-.2 2-4.4 2.8-6.9 3.3-9 2-23 3-31.9 5.4-17.4 4.8-19.9 26-11.1 36.8 9 11.2 31.6 13.7 44.3 5.4 2-1.2 3.8-2.6 6.2-4.3v7.8h20.8v-21c.1-12.4.6-24.9.1-37.3a25.7 25.7 0 00-13.5-22zm-16 61.7a27.2 27.2 0 01-8.5 3 29 29 0 01-11.5.5c-2.7-.6-5.3-1.9-6.7-4.2a8 8 0 01-1.1-4.5 7.1 7.1 0 011.7-4.2 10.3 10.3 0 014.3-3.2c5.3-2.3 11.2-3 17-3.6l9.4-1 2.4-.2 1.1-.2c.4 0 .6 0 .6.4a26.5 26.5 0 01-.4 4.2 17.7 17.7 0 01-1 4 16 16 0 01-7.2 9z"
        fill="#b01aa7"
      />
      <path
        d="M42.5 117.9C15.9 117.9 0 98.8 0 75.2a41 41 0 0142.3-42.7H93S91 46 78.2 51H42.6C28 51 20.3 63 20.3 75.2c0 12.4 7.5 24 22.2 24s22.3-11.6 22.3-24a25.7 25.7 0 00-7.3-18h24a45.1 45.1 0 013.7 18c0 13.4-5.3 25.4-14.7 33.2a28 28 0 0114.7 26.4c0 23.3-17.8 37.7-42.3 37.7-23 0-40.4-13-42.6-33.4l20.6-2.2c1.2 9.8 10.8 16 22 16 12 0 21.4-6.4 21.4-18s-11-17-21.3-17z"
        fill="#b01aa7"
      />
    </svg>
  );
}
