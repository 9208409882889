import { ReactNode } from "react";
import { AppColors } from "utils/colors";

export interface InfoLineProps {
  label: string;
  value: ReactNode;
  dark?: boolean;
  children?: ReactNode;
}

export function AddMoneyInfoLine({
  label,
  value,
  children,
  dark,
}: InfoLineProps) {
  return (
    <div
      css={{
        color: dark ? AppColors.GRAY_500 : AppColors.GRAY_300,
        fontFamily: "var(--font-family-numbers)",
        fontSize: "1rem",
        lineHeight: 1.5,
        marginBottom: "0.75rem",
        clear: "both",
        overflow: "hidden",
      }}
    >
      <span
        css={{
          float: "left",
        }}
      >
        {label}
      </span>
      {children}
      <span css={{ float: "right", fontWeight: "bold" }}>{value}</span>
    </div>
  );
}
