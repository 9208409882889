import { CSSProperties, MouseEvent, ReactElement, ReactNode } from "react";
import { useSelector } from "react-redux";
import { TravelPassProvider, TravelPassType } from "utils";
import { TravelPassNameDate } from "./components/TravelPassNameDate";
import { TravelType } from "./components/TravelType";
import { ProviderLogo } from "./ProviderLogo";
import "./TravelPass.scss";

export interface TravelPassWrapperProps {
  className: string;
  provider: TravelPassProvider;
  activation?: number;
  expiration?: number;
  isReturnJourney?: boolean;
  locale: string;
  style?: CSSProperties;
  onClick?: (e: MouseEvent) => void;
  children?: ReactNode;
}

export function TravelPassWrapper({
  className,
  provider,
  activation,
  expiration,
  isReturnJourney,
  locale,
  children,
  style = {},
  onClick,
}: TravelPassWrapperProps): ReactElement {
  const allProviders = useSelector((state) => state.passes.providers);
  const travelProvider = allProviders.find(
    (travelProvider) => travelProvider.provider === provider
  );

  return (
    <div
      className={`mobea__travel-pass ${className}`}
      style={style}
      onClick={onClick}
    >
      <header className="mobea__travel-pass__header">
        <TravelType
          color={travelProvider?.color}
          transportType={travelProvider?.types?.[0] ?? TravelPassType.Unknown}
        />

        <TravelPassNameDate
          provider={provider}
          locale={locale}
          activation={activation}
          expiration={expiration}
          isReturnJourney={isReturnJourney}
        />

        <div className="mobea__travel-pass__header__logo">
          <ProviderLogo provider={provider} />
        </div>
      </header>
      {children}
    </div>
  );
}
