import { useViewportHeight } from "common/hooks";
import { ReactElement, ReactNode } from "react";
import { useHistory } from "react-router-dom";
import { AppColors } from "utils/colors";
import { PageHeader, PageHeaderPublicProps } from "./header/PageHeader";

export type NestedPageProps = {
  pageClassName: string;
  className?: string;
  backButtonVisible?: boolean;
  minViewportHeight?: number;
  ignoreViewportHeight?: boolean;
  noHeader?: boolean;
  background?: "default" | "white";
  padding?: {
    top?: number | string;
    right?: number | string;
    bottom?: number | string;
    left?: number | string;
  };
  children?: ReactNode;
  onNavBack?(): void;
} & PageHeaderPublicProps;

export function NestedPage({
  pageClassName,
  background = "white",
  className = "",
  children,
  minViewportHeight,
  ignoreViewportHeight = false,
  noHeader = false,
  padding = {},
  onNavBack,
  ...headerProps
}: NestedPageProps): ReactElement {
  const history = useHistory();
  // component uses viewport height as its minHeight (if not specified otherwise)
  // e.g. overcome issue with keyboard making the component visible height smaller on Android
  const height = useViewportHeight();

  const onBack = (e: React.MouseEvent) => {
    e.preventDefault();

    onNavBack ? onNavBack() : history.goBack();
  };

  return (
    <main
      className={`m_page ${pageClassName} ${className}`}
      css={{
        minHeight:
          ignoreViewportHeight || !height
            ? "100%"
            : Math.max(minViewportHeight || 0, height || 0),

        backgroundColor:
          background === "white" ? "white" : AppColors.BACKGROUND,
        paddingTop: padding.top ?? 32,
        paddingRight: padding.right ?? 24,
        paddingBottom: padding.bottom ?? 32,
        paddingLeft: padding.left ?? 24,
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0,
      }}
    >
      {!noHeader && <PageHeader {...headerProps} onBack={onBack} />}
      <section className={`${pageClassName}__content m_page__content`}>
        {children}
      </section>
    </main>
  );
}
