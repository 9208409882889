import { useTranslation } from "react-i18next";
import { BlueBikeStatus } from "state/actions/TravelPassActions";
import { AppColors } from "utils/colors";

interface Props {
  status: BlueBikeStatus;
}

export function BlueBikeStatusBadge({ status }: Props) {
  const { t } = useTranslation();

  const getColor = () => {
    switch (status) {
      case BlueBikeStatus.Ready:
        return AppColors.STATUS_GREY;

      case BlueBikeStatus.Active:
        return AppColors.STATUS_BLUE;

      case BlueBikeStatus.Expired:
      case BlueBikeStatus.Charged:
        return AppColors.STATUS_GREEN;
      default:
        return AppColors.STATUS_GREY;
    }
  };

  return (
    <div
      css={{
        position: "relative",
        left: 0,
        padding: "8px 16px",
        fontSize: "0.75rem",
        fontWeight: 500,
        lineHeight: "1.333",
        borderRadius: 2,
        color: getColor(),
        "@media screen and (max-width: 360px)": {
          maxWidth: 140,
        },
      }}
    >
      <div
        css={{
          opacity: 0.1,
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: getColor(),
        }}
      ></div>
      {t(`blue-bike_detail.status.${status}`)}
    </div>
  );
}
