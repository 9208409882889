import { CustomMobeaModal } from "common/modal";
import { InfoIcon } from "icons/InfoIcon";
import { ReactElement, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { providerTutorialWatchedAction } from "state/actions";
import { TravelPassProvider } from "utils";
import { Tutorial } from ".";
import { TutorialProps } from "./Tutorial";
import "./TutorialDialog.scss";

interface TutorialDialogProps extends TutorialProps {
  provider: TravelPassProvider;
  className?: string;
  forceShow?: boolean;
}

export function TutorialDialog({
  provider,
  className = "",
  forceShow = false,
  ...props
}: TutorialDialogProps): ReactElement {
  const dispatch = useDispatch();

  const tutorialWatched = useSelector(
    (state) => state.onboarding.providersTutorialWatched[provider] || false
  );

  const [tutorialVisible, setTutorialVisible] = useState(
    forceShow || !tutorialWatched
  );

  const closeTutorial = () => {
    setTutorialVisible(false);
    dispatch(providerTutorialWatchedAction(provider));
  };

  return (
    <div
      className="mobea-ticket-detail__info"
      onClick={() => setTutorialVisible(true)}
    >
      <InfoIcon />

      {tutorialVisible && (
        <CustomMobeaModal
          className={`mobea__tutorial-dialog ${className}`}
          withHeader={false}
        >
          <Tutorial {...props} onClose={closeTutorial} />
        </CustomMobeaModal>
      )}
    </div>
  );
}
