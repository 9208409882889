import { ReactNode } from "react";
import { AppColors } from "utils/colors";

type Props = {
  name: string;
  children: ReactNode;
};

export function Section({ name, children }: Props) {
  return (
    <div>
      <div
        css={{
          fontSize: "0.875rem",
          lineHeight: "1.25rem",
          fontWeight: "bold",
          color: AppColors.GRAY_300,
          marginTop: 32,
          marginBottom: 16,
        }}
      >
        {name}
      </div>
      <div
        css={{
          backgroundColor: "white",
          marginLeft: -24,
          marginRight: -24,
        }}
      >
        {children}
      </div>
    </div>
  );
}
