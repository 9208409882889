import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

export function CircleSadIcon({
  fill = "currentColor",
  className = "",
  height = "24",
  width = "24",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 70 70"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35 69.875c19.265 0 34.875-15.61 34.875-34.875C69.875 15.734 54.265.125 35 .125 15.734.125.125 15.735.125 35c0 19.266 15.61 34.875 34.875 34.875zm0-6.75C19.489 63.125 6.875 50.511 6.875 35 6.875 19.49 19.489 6.875 35 6.875 50.51 6.875 63.125 19.49 63.125 35c0 15.51-12.614 28.125-28.125 28.125zM23.75 32.75c2.489 0 4.5-2.01 4.5-4.5 0-2.489-2.011-4.5-4.5-4.5-2.49 0-4.5 2.011-4.5 4.5 0 2.49 2.01 4.5 4.5 4.5zm22.5 0c2.489 0 4.5-2.01 4.5-4.5 0-2.489-2.011-4.5-4.5-4.5-2.49 0-4.5 2.011-4.5 4.5 0 2.49 2.01 4.5 4.5 4.5zm2.91 20.588a3.384 3.384 0 00.437-4.754A18.962 18.962 0 0035 41.75a18.962 18.962 0 00-14.597 6.834 3.371 3.371 0 00.436 4.754 3.384 3.384 0 004.753-.436A12.234 12.234 0 0135 48.486c3.642 0 7.073 1.603 9.408 4.416a3.385 3.385 0 004.753.436z"
        fill={fill}
        fillRule="nonzero"
      />
    </svg>
  );
}
