import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import insidePolygon from "robust-point-in-polygon";
import { LocationCoords } from "services/mapService";
import { getMobitEfences } from "services/mobitService";

const SEARCH_DISTANCE = 0.0006; // circa 30-60 metres

export function useBikeInsideEfences(coordinates: LocationCoords | null) {
  const history = useHistory();

  const [result, setResult] = useState<boolean | null>(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let canceled = false;
    let isLoading = false;

    async function fetchEfences(coords: LocationCoords) {
      try {
        setLoading(true);
        isLoading = true;
        setResult(null);

        const json = await getMobitEfences(
          {
            lat: coords.lat - SEARCH_DISTANCE,
            lng: coords.lng - SEARCH_DISTANCE,
          },
          {
            lat: coords.lat + SEARCH_DISTANCE,
            lng: coords.lng + SEARCH_DISTANCE,
          },
          history
        );

        if (!json.error) {
          const efences = json.data;

          const isInside = efences.some((efence) => {
            const polygon: [number, number][] = efence.loc.points.map(
              ({ y: lat, x: lng }) => [lat, lng]
            );

            const result = insidePolygon(polygon, [coords.lat, coords.lng]);

            // inside or on the edge
            return result === 0 || result === -1;
          });

          if (!canceled) {
            setLoading(false);

            setResult(isInside);
          }
        } else {
          !canceled && setLoading(false);

          console.error(
            "Failed to fetch e-fences",
            json.error_code,
            json.error_description
          );
        }
      } catch (error) {
        !canceled && setLoading(false);

        console.error("Failed to fetch e-fences", error);
      } finally {
        isLoading = false;
      }
    }

    coordinates && fetchEfences(coordinates);

    return () => {
      canceled = true;

      isLoading && console.debug("Canceled hook useBikeInsideEfences");
    };
  }, [coordinates, history]);

  return [result, loading] as const;
}
