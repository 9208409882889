import { ProviderLogo } from "common/travelPasses";
import { TravelPassNameDate } from "common/travelPasses/components/TravelPassNameDate";
import { ReactElement } from "react";
import { useHistory } from "react-router-dom";
import { ExpenseStatus } from "state/actions";
import {
  formatCurrency,
  pushLocation,
  Routes,
  TravelPassProvider,
} from "utils";
import { HistoryExpenseStatus } from "./HistoryExpenseStatus";

export interface HistoryExpenseProps {
  locale: string;
  provider: TravelPassProvider;
  price: number;
  acceptedPrice: number;
  id: string;
  status: ExpenseStatus;
  expenseDate: number;
}

export function HistoryExpense({
  locale,
  id,
  provider,
  status,
  price,
  acceptedPrice,
  expenseDate,
}: HistoryExpenseProps): ReactElement {
  const history = useHistory();

  const formattedPrice = formatCurrency(price || 0, locale);

  const formattedAcceptedPrice = formatCurrency(acceptedPrice || 0, locale);

  // @TODO extend for other providers
  const detailRouteBase = Routes.HistoryExpensesDetail;

  const goToDetail = () =>
    pushLocation(
      history,
      detailRouteBase.replace(":id", encodeURIComponent(id))
    );

  return (
    <li className="mobea-history__costs__spending" onClick={goToDetail}>
      <span className="mobea-history__costs__spending__logo">
        <ProviderLogo provider={provider} />
      </span>

      <TravelPassNameDate
        provider={provider}
        activation={expenseDate}
        expiration={expenseDate}
        locale={locale}
      />

      <p
        className={`mobea-history__costs__spending__price mobea-history__costs__spending__${status.toLowerCase()}`}
      >
        {(acceptedPrice === price || acceptedPrice == 0) && (
          <span className="mobea-history__costs__spending__price__accepted-all">
            {status !== "DECLINED" && <span>-&nbsp;</span>}
            {acceptedPrice !== price ? formattedAcceptedPrice : formattedPrice}
          </span>
        )}

        {acceptedPrice !== price && acceptedPrice > 0 && (
          <span className="mobea-history__costs__spending__price__accepted-partial">
            <span className="mobea-history__costs__spending__price__accepted-partial__accepted">
              -&nbsp;{formattedAcceptedPrice}
            </span>
            <span className="mobea-history__costs__spending__price__accepted-partial__requested">
              -&nbsp;{formattedPrice}
            </span>
          </span>
        )}
      </p>

      <p className="mobea-history__costs__spending__status">
        <HistoryExpenseStatus status={status} />
      </p>
    </li>
  );
}
