import { FieldLabel, MobeaButton } from "common/forms";
import { FullScreenPage } from "common/page/FullScreenPage";
import {
  getPasswordStrength,
  PasswordInput,
} from "common/passwordInput/PasswordInput";
import { PhoneNumberPicker } from "common/phoneNumberPicker";
import { LoginTitle } from "pages/login/LoginTitle";
import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Auth } from "services/authTokenStore";
import { setCustomerPassword } from "services/customerService";
import { passwordSet } from "state/actions";
import { formatPhoneNumberWithoutPrefix, Routes } from "utils";
import { LogoWithLangHeader } from "./LogoWithLangHeader";
import { useSomethingWentWrongModal } from "./useSomethingWentWrongModal";

export function SetPasswordPage() {
  const { t } = useTranslation();

  const { phonePrefix, phoneNumber, customerId } = useSelector(
    (state) => state.user
  );

  const [password, setPassword] = useState("");

  const [saving, setSaving] = useState(false);

  const history = useHistory();

  const somethingWentWrongModal = useSomethingWentWrongModal();

  const dispatch = useDispatch();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    setSaving(true);

    setCustomerPassword(customerId, history, password)
      .then(() => {
        dispatch(passwordSet());

        history.replace(Routes.SetPasswordSuccess);
      })
      .catch(() => {
        somethingWentWrongModal.show();
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <FullScreenPage
      pageClassName="mobea-set-password"
      padding={{
        left: 32,
        right: 32,
      }}
    >
      <LogoWithLangHeader saveLanguage="onChange" />

      <form
        onSubmit={handleSubmit}
        css={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          justifyContent: "space-between",
          marginTop: 60,
        }}
      >
        <LoginTitle
          title={t("set_password.welcome")}
          text={t("set_password.prompt")}
        />
        <div>
          <FieldLabel>{t("shared.phone_number")}</FieldLabel>

          <PhoneNumberPicker
            prefix={{ code: phonePrefix, minLength: 4 }}
            valid
            localNumberFormatted={formatPhoneNumberWithoutPrefix(phoneNumber)}
            disabled
          />

          <div css={{ height: 16 }}></div>
          <FieldLabel>{t("set_password.password_placeholder")}</FieldLabel>

          <PasswordInput
            variant="outlined"
            value={password}
            onChange={setPassword}
            css={{
              marginBottom: 40,
            }}
          />
        </div>
        <div>
          <MobeaButton
            disabled={getPasswordStrength(password) !== "ok"}
            loading={saving}
          >
            {t("shared.save")}
          </MobeaButton>
          <a
            onClick={(e) => {
              e.preventDefault();

              Auth.clearTokens();
              history.replace(Routes.Login);
            }}
            href={Routes.Login}
            css={{
              display: "inline-block",
              marginTop: "1.5rem",
              width: "100%",
              textAlign: "center",
              fontSize: "1rem",
              fontWeight: 500,
              lineHeight: "1.5",
              textDecoration: "none",
            }}
          >
            {t("log_in.already_have_account")}
          </a>
        </div>
      </form>
    </FullScreenPage>
  );
}
