import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

export function UpIcon({
  fill = "currentColor",
  className = "",
  height = "9",
  width = "12",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="6 8 12 9"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={fill}
        fillRule="nonzero"
        d="M12 9l5 4.86L15.828 15 12 11.278 8.172 15 7 13.86z"
      />
    </svg>
  );
}
