import { MobeaButton } from "common/forms";
import { ScrollableNumbers } from "common/timepicker/ScrollableNumbers";
import { CloseIcon } from "icons/CloseIcon";
import { ReactElement, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { MODAL_ROOT_ELEMENT } from "utils";
import "./TimePicker.scss";

export type TimePickerProps = {
  title: string;
  value: Date;
  startDate?: Date;
  minutesStep?: number;
  onClose(): void;
  onChange: (time: Date) => void;
};

export function TimePicker({
  title,
  value,
  startDate,
  minutesStep = 5,
  onClose,
  onChange,
}: TimePickerProps): ReactElement {
  const { t } = useTranslation();

  const [selectedValue, setSelectedValue] = useState(value);

  const pickAreaRef = useRef<HTMLDivElement>(null);

  const [numbersContainerHeight, setNumbersContainerHeight] = useState(0);

  useEffect(() => {
    setNumbersContainerHeight(pickAreaRef.current?.clientHeight || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickAreaRef.current]);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const updateHours = (hours: number) => {
    const updated = new Date(selectedValue);
    updated.setHours(hours);
    setSelectedValue(updated);
  };

  const updateMinutes = (minutes: number) => {
    const updated = new Date(selectedValue);
    updated.setMinutes(minutes);
    setSelectedValue(updated);
  };

  const selectTime = () => onChange(selectedValue);

  return createPortal(
    <div className="mobea-time-picker">
      <div className="mobea-time-picker__overlay" onClick={onClose} />

      <div className="mobea-time-picker__content">
        <div className="mobea-time-picker__header">
          {title}
          <CloseIcon
            className="mobea-time-picker__header__close-icon"
            onClick={onClose}
          />
        </div>

        <div ref={pickAreaRef} className="mobea-time-picker__pick-area">
          <ScrollableNumbers
            className="mobea-time-picker__pick-area__hours"
            min={0}
            max={23}
            parentHeight={numbersContainerHeight}
            selectedIndex={selectedValue.getHours()}
            setSelectedIndex={updateHours}
          />

          <div className="mobea-time-picker__pick-area__separator">:</div>

          <ScrollableNumbers
            className="mobea-time-picker__pick-area__minutes"
            min={0}
            max={59}
            step={minutesStep}
            parentHeight={numbersContainerHeight}
            selectedIndex={Math.floor(selectedValue.getMinutes() / minutesStep)}
            setSelectedIndex={(index) => updateMinutes(index * minutesStep)}
          />

          <div className="mobea-time-picker__pick-area__hours-selection" />
          <div className="mobea-time-picker__pick-area__minutes-selection" />
          <div className="mobea-time-picker__pick-area__top-cover" />
          <div className="mobea-time-picker__pick-area__bottom-cover" />
        </div>

        <div className="mobea-time-picker__action">
          <MobeaButton
            onClick={selectTime}
            disabled={startDate && selectedValue < startDate}
          >
            {t("time.select_time")}
          </MobeaButton>
        </div>
      </div>
    </div>,
    MODAL_ROOT_ELEMENT
  );
}
