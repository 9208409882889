import { LabelValue, MobeaButton } from "common/forms";
import { useBooleanState } from "common/hooks";
import { MobeaModal } from "common/modal";
import { Spinner } from "common/Spinner";
import { ChevronIcon } from "icons/ChevronIcon";
import { CircleCheckIcon } from "icons/CircleCheckIcon";
import { ExclamationMarkIcon } from "icons/ExclamationMarkIcon";
import { PhoneIcon } from "icons/PhoneIcon";
import { QuestionMarkIcon } from "icons/QuestionMarkIcon";
import { useVertsCancel } from "pages/verts/hooks/useVertsCancel";
import { MouseEvent, ReactElement, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { animated, useSpring } from "react-spring";
import { VertsTravelPass as VertsTravelPassType } from "state/actions/TravelPassActions";
import {
  formatDateWithLocaleAsDigits,
  formatTimeWithLocale,
  pushLocation,
  Routes,
  VERTS_SUPPORT_PHONE_NUMBER,
} from "utils";
import { AppColors } from "utils/colors";
import { VertsStatus } from "utils/VertsStatus";
import { TravelPassWrapper } from "../TravelPassWrapper";
import { CarPlate } from "./CarPlate";
import { DateTimePrice } from "./DateTimePrice";
import { RouteSection } from "./RouteSection";
import { VertsTaxi } from "./VertsTaxi";
import "./VertsTravelPass.scss";

const VIEW_STATUSES = [
  VertsStatus.Searching,
  VertsStatus.Created,
  VertsStatus.DriverFound,
  VertsStatus.NoDriverFound,
  VertsStatus.Error,
];

const CONTACT_STATUSES = [
  VertsStatus.Error,
  VertsStatus.Ordered,
  VertsStatus.DriverFound,
  VertsStatus.Arriving,
  VertsStatus.Waiting,
  VertsStatus.Created,
];

const CANCELABLE_STATUSES = [VertsStatus.Ordered];

const CANCELABLE_STATUSES_NO_FEE = [
  VertsStatus.Searching,
  VertsStatus.Created,
  VertsStatus.NoDriverFound,
  VertsStatus.Error,
];

const MAP_STATUSES = [
  VertsStatus.DriverFound,
  VertsStatus.Arriving,
  VertsStatus.Waiting,
  VertsStatus.InProgress,
];

type Props = VertsTravelPassType & {
  isPreview?: boolean;
  locale: string;
  onSelect?(): void;
  refresh(): void;
  backButtonBehaviour?: "goBack" | "goTicket";
};

export function VertsTravelPass({
  isPreview = false,
  id,
  provider,
  status,
  origin,
  destination,
  activation,
  createdTime,
  requestedAt,
  etaTime,
  arrivalTime,
  displayStatus,
  price,
  cancelFee,
  cancelledByOperator,
  driver,
  vehicle,
  locale,
  onSelect,
  refresh,
  backButtonBehaviour = "goTicket",
}: Props): ReactElement {
  const history = useHistory();

  const { t } = useTranslation();

  const [
    cancelConfirmDialogVisible,
    showCancelConfirmDialog,
    hideCancelConfirmDialog,
  ] = useBooleanState();

  const [contactDialogVisible, showContactDialog, hideContactDialog] =
    useBooleanState();

  const [detailsVisible, setDetailsVisible] = useState(false);

  const [cancel, canceling, cancelResult] = useVertsCancel();

  const [waitTextVisible, setWaitTextVisible] = useState(false);

  const detailsStyle = useSpring({
    maxHeight: detailsVisible ? 140 : 0,
    padding: detailsVisible ? "16px 0" : "0px 0",
  });

  useEffect(() => {
    if (cancelResult) {
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelResult]);

  //
  useEffect(() => {
    if (status === VertsStatus.Searching) {
      const timeoutId = setTimeout(() => {
        setWaitTextVisible(!waitTextVisible);
      }, 3000);

      return () => {
        clearTimeout(timeoutId);
      };
    } else if (waitTextVisible) {
      setWaitTextVisible(false);
    }
  }, [status, waitTextVisible]);

  const showDetail = (e: MouseEvent) => {
    e.preventDefault();

    onSelect && onSelect();
  };

  const cancelRide = () => {
    cancel(id);
    hideCancelConfirmDialog();
  };

  const callTaxiVerts = () => {
    window.open(`tel:${VERTS_SUPPORT_PHONE_NUMBER}`);
  };

  const showMap = () => {
    pushLocation(
      history,
      `${Routes.VertsMap.replace(
        ":id",
        encodeURIComponent(id)
      )}?back=${backButtonBehaviour}`
    );
  };

  const getMapButtonText = () => {
    switch (status) {
      case VertsStatus.Waiting:
        return t("verts_detail.find_driver");
      case VertsStatus.InProgress:
        return t("verts_detail.follow_ride");
      default:
        return t("verts_detail.show_map");
    }
  };

  const getDateTime = () => {
    switch (status) {
      case VertsStatus.InProgress:
        return etaTime!;
      case VertsStatus.Finished:
        return arrivalTime!;
      case VertsStatus.Created:
        return requestedAt;
      default:
        return createdTime;
    }
  };

  const hasFine = () =>
    status === VertsStatus.Cancelled && !!price && price > 0;

  const { language } = useSelector((state) => state.user);

  return (
    <TravelPassWrapper
      className={`mobea__verts-ticket ${isPreview ? "preview" : ""}`}
      provider={provider}
      activation={activation}
      expiration={activation}
      locale={locale}
      onClick={showDetail}
    >
      {(!VIEW_STATUSES.includes(displayStatus) ||
        displayStatus === VertsStatus.Created) && (
        <section className="info-section">
          <div className="info-section__status-plate">
            <div
              className={`info-section__status-plate__status ${displayStatus}`}
            >
              <div className="background"></div>
              {t(`verts_detail.status.${displayStatus}`)}
            </div>
            {vehicle && <CarPlate plate={vehicle.plate} />}
          </div>

          <img
            className="info-section__car-image"
            src="/static/images/car.png"
            alt="car"
          />

          {status !== VertsStatus.Cancelled && driver && !isPreview && (
            <>
              <div
                className="info-section__details-toggle"
                onClick={() => setDetailsVisible(!detailsVisible)}
              >
                {detailsVisible
                  ? t("verts_detail.hide_details")
                  : t("verts_detail.show_details")}
                <ChevronIcon
                  className="info-section__details-toggle__icon"
                  fill={AppColors.PRIMARY}
                  orientation={detailsVisible ? "up" : "down"}
                />
              </div>

              <animated.section
                className="info-section__details"
                style={detailsStyle}
              >
                <LabelValue label={t("verts_detail.driver")} value={driver} />
                {vehicle && (
                  <LabelValue
                    className="info-section__details__car"
                    label={t("verts_detail.car")}
                    value={
                      <VertsTaxi color={vehicle.color} model={vehicle.model} />
                    }
                  />
                )}
              </animated.section>
            </>
          )}
        </section>
      )}

      {VIEW_STATUSES.includes(displayStatus) && (
        <section
          className="status-section"
          css={
            status === VertsStatus.Created
              ? {
                  padding: "0 !important",
                  "& .status-section__info-text": {
                    marginTop: 0,
                  },
                }
              : {}
          }
        >
          {status === VertsStatus.Searching && <Spinner />}

          {status === VertsStatus.DriverFound && (
            <CircleCheckIcon height={70} width={70} fill={AppColors.PRIMARY} />
          )}

          {status === VertsStatus.NoDriverFound && (
            <QuestionMarkIcon
              height={70}
              width={70}
              fill={AppColors.GRAY_200}
            />
          )}

          {status === VertsStatus.Error && (
            <ExclamationMarkIcon
              height={70}
              width={70}
              fill={AppColors.ERROR}
            />
          )}

          {status !== VertsStatus.Created && (
            <div className={`status-section__status ${displayStatus}`}>
              {t(`verts_detail.status.${displayStatus}`)}
            </div>
          )}

          <div className="status-section__info-text">
            {waitTextVisible && <span>{t("verts_detail.please_wait")}</span>}

            {!waitTextVisible && (
              <span>
                <Trans
                  i18nKey={`verts_detail.info.${displayStatus}`}
                  values={{
                    name: driver || "",
                    origin: origin.address,
                    date: formatDateWithLocaleAsDigits(
                      new Date(requestedAt),
                      language
                    ),
                    time: formatTimeWithLocale(new Date(requestedAt), language),
                  }}
                >
                  <span className="action" onClick={showCancelConfirmDialog}>
                    cancel
                  </span>
                </Trans>
              </span>
            )}
          </div>
        </section>
      )}

      <RouteSection origin={origin.address} destination={destination.address} />

      <div className="mobea__verts-ticket__separator" />

      {!isPreview && (
        <>
          <DateTimePrice
            status={displayStatus}
            dateTime={getDateTime()}
            price={price}
            hasFine={hasFine()}
          />
          <section className="actions-section">
            {CANCELABLE_STATUSES.includes(displayStatus) && (
              <MobeaButton
                type="secondary"
                onClick={showCancelConfirmDialog}
                loading={canceling}
                disabled={canceling || !!cancelResult}
              >
                {t("verts_detail.cancel")}
              </MobeaButton>
            )}

            {CANCELABLE_STATUSES_NO_FEE.includes(displayStatus) && (
              <MobeaButton
                type="secondary"
                onClick={showCancelConfirmDialog}
                loading={canceling}
                disabled={canceling || !!cancelResult}
              >
                {t("verts_detail.cancel_request")}
              </MobeaButton>
            )}

            {MAP_STATUSES.includes(displayStatus) && (
              <MobeaButton
                type="secondary"
                onClick={showMap}
                disabled={canceling || !!cancelResult}
              >
                {getMapButtonText()}
              </MobeaButton>
            )}

            {CONTACT_STATUSES.includes(displayStatus) && (
              <MobeaButton
                className="phone-button"
                type="secondary"
                onClick={showContactDialog}
              >
                <PhoneIcon outlined />
              </MobeaButton>
            )}

            {status === VertsStatus.Cancelled && (
              <div className="actions-section__fine-info">
                {hasFine() ? (
                  <Trans i18nKey="verts_detail.canceled_fine_text">
                    <span className="mobea__arial">{{ price }}</span>
                  </Trans>
                ) : cancelledByOperator ? (
                  t("verts_detail.canceled_by_operator_text")
                ) : (
                  t("verts_detail.canceled_no_fine_text")
                )}
              </div>
            )}
          </section>
        </>
      )}

      {cancelConfirmDialogVisible &&
        CANCELABLE_STATUSES_NO_FEE.includes(status) && (
          <MobeaModal
            title={t("verts_detail.cancel_confirm_title")}
            confirmText={t("shared.yes_cancel")}
            onConfirm={cancelRide}
            cancelText={t("shared.no")}
            onCancel={hideCancelConfirmDialog}
            usePortal
          >
            <p>{t("verts_detail.cancel_confirm_free_text")}</p>
          </MobeaModal>
        )}
      {
        // all the others states use default dialog layout
        cancelConfirmDialogVisible &&
          !CANCELABLE_STATUSES_NO_FEE.includes(status) && (
            <MobeaModal
              title={t("verts_detail.cancel_confirm_title")}
              confirmText={t("shared.no")}
              onConfirm={hideCancelConfirmDialog}
              cancelText={t("shared.yes_cancel")}
              onCancel={cancelRide}
              usePortal
            >
              <p>
                <Trans i18nKey="verts_detail.cancel_confirm_text">
                  <span className="mobea__arial">{{ fine: cancelFee }}</span>
                </Trans>
              </p>
            </MobeaModal>
          )
      }

      {contactDialogVisible && (
        <MobeaModal
          title={t("verts_detail.contact_title")}
          confirmText={t("verts_detail.contact_title")}
          onConfirm={callTaxiVerts}
          onClose={hideContactDialog}
          usePortal={true}
        >
          <PhoneIcon
            outlined
            fill={AppColors.GRAY_100}
            width="88"
            height="88"
          />
          <p>{t("verts_detail.contact_text")}</p>
        </MobeaModal>
      )}
    </TravelPassWrapper>
  );
}
