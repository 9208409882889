import { ReactElement } from "react";

export type SpinnerProps = {
  style?: "default" | "button" | string;
  size?: number;
};

export function Spinner({
  style = "default",
  size = 64,
}: SpinnerProps): ReactElement {
  const divStyle = {
    width: size,
    height: size,
    borderWidth: size / 8,
  };

  return (
    <div
      className={`mobea__spinner mobea__spinner__${style}`}
      style={{
        width: size,
        height: size,
      }}
    >
      <div style={divStyle} />
    </div>
  );
}
