import { MobeaButton } from "common/forms";
import { BudgetState } from "common/hooks/useBudgetState";
import { Spinner } from "common/Spinner";
import { LabelIcon } from "icons/LabelIcon";
import { LocationCursorIcon } from "icons/navigation";
import { WalkIcon } from "icons/WalkIcon";
import { useProviderActions } from "pages/map/hooks/useProviderActions";
import { ReactElement } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ProviderData } from "services/mapService";
import { ApplicationVariant, formatCurrency, formatDateWithLocale, TravelPassProvider } from "utils";
import { AppColors } from "utils/colors";
import { applicationVariant } from "utils/configure";
import "./ProviderDetails.scss";
import { RouteDirectionsButton } from "./RouteDirectionsButton";

type Props = {
  loading: boolean;
  provider: ProviderData;
  address: string | null;
  addressLoading: boolean;
  distance: number | null;
  distanceLoading: boolean;
  budgetState: BudgetState;
  showRoute?(): void;
};

export function ProviderDetails({
  loading,
  provider,
  address,
  addressLoading,
  distance,
  distanceLoading,
  budgetState,
  showRoute,
}: Props): ReactElement {
  const { t } = useTranslation();

  const { language, startDate, endDate } = useSelector((state) => state.user);

  const allProviders = useSelector((state) => state.passes.providers);

  const activePasses = useSelector((state) => state.passes.active);

  const travelProvider = allProviders.find(
    (travelProvider) => travelProvider.provider == provider.type
  );

  const [
    primaryActionName,
    onPrimaryAction,
    secondaryActionName,
    onSecondaryAction,
    primaryActionEnabled,
    secondaryActionEnabled,
  ] = useProviderActions(provider);

  const getDescriptionKey = () => {
    const budgetPostfix =
      budgetState === BudgetState.NotActiveYet ? `_${budgetState}` : "";

    switch (travelProvider?.provider) {
      case TravelPassProvider.verts:
        if (travelProvider?.type == "order") {
          // t("map.provider_info.description.verts_order")
          // t("map.provider_info.description.verts_order_not-active-yet")
          return `map.provider_info.description.verts_order${budgetPostfix}`;
        } else {
          // t("map.provider_info.description.verts_app")
          // t("map.provider_info.description.verts_app_not-active-yet")
          return `map.provider_info.description.verts_app${budgetPostfix}`;
        }
      case TravelPassProvider["velo-antwerpen"]:
        if (travelProvider?.type == "order") {
          // t("map.provider_info.description.velo-antwerpen")
          // t("map.provider_info.description.velo-antwerpen_not-active-yet")
          return `map.provider_info.description.velo-antwerpen${budgetPostfix}`;
        } else {
          // t("map.provider_info.description.velo-antwerpen_app")
          // t("map.provider_info.description.velo-antwerpen_app_not-active-yet")
          return `map.provider_info.description.velo-antwerpen_app${budgetPostfix}`;
        }
      case TravelPassProvider.mobit:
        if (travelProvider?.type == "order") {
          if (
            activePasses.some(
              (pass) => pass.provider === TravelPassProvider.mobit
            )
          ) {
            // t("map.provider_info.description.mobit_open")
            return "map.provider_info.description.mobit_open";
          } else {
            // t("map.provider_info.description.mobit_order")
            // t("map.provider_info.description.mobit_order_not-active-yet")
            return `map.provider_info.description.mobit_order${budgetPostfix}`;
          }
        } else {
          // t("map.provider_info.description.mobit_app")
          // t("map.provider_info.description.mobit_app_not-active-yet")
          return `map.provider_info.description.mobit_app${budgetPostfix}`;
        }

      case TravelPassProvider["blue-bike"]:
        if (travelProvider?.type == "order") {
          // t("map.provider_info.description.blue-bike")
          // t("map.provider_info.description.blue-bike_not-active-yet")
          return `map.provider_info.description.blue-bike${budgetPostfix}`;
        } else {
          // t("map.provider_info.description.blue-bike_app")
          // t("map.provider_info.description.blue-bike_app_not-active-yet")
          return `map.provider_info.description.blue-bike_app${budgetPostfix}`;
        }
      default:
        // t("map.provider_info.description.billy-bike")
        // t("map.provider_info.description.billy-bike_not-active-yet")
        // t("map.provider_info.description.nmbs")
        // t("map.provider_info.description.nmbs_not-active-yet")
        // t("map.provider_info.description.delijn")
        // t("map.provider_info.description.delijn_not-active-yet")
        // t("map.provider_info.description.mivb")
        // t("map.provider_info.description.mivb_not-active-yet")
        // t("map.provider_info.description.donkey-republic")
        // t("map.provider_info.description.donkey-republic_not-active-yet")
        // t("map.provider_info.description.tec")
        // t("map.provider_info.description.tec_not-active-yet")
        // t("map.provider_info.description.uber")
        // t("map.provider_info.description.uber_not-active-yet")
        // t("map.provider_info.description.villo")
        // t("map.provider_info.description.villo_not-active-yet")
        // t("map.provider_info.description.li-bia-velo")
        // t("map.provider_info.description.li-bia-velo_not-active-yet")
        return `map.provider_info.description.${travelProvider?.provider}${budgetPostfix}`;
    }
  };

  const renderFooter = () => {
    switch (travelProvider?.provider) {
      case TravelPassProvider.villo:
      case TravelPassProvider["li-bia-velo"]:
        const formattedUpdatedDate = formatDateWithLocale(
          provider.updated ?? new Date(),
          language
        );

        // t("map.provider_info.footer.villo")
        // t("map.provider_info.footer.li-bia-velo")
        return (
          <Trans
            i18nKey={`map.provider_info.footer.${provider.type}`}
            values={{
              date: formattedUpdatedDate,
            }}
          >
            <a
              href="https://developer.jcdecaux.com/#/opendata/vls?page=static"
              target="_blank"
              rel="noreferrer"
            ></a>
          </Trans>
        );

      default:
        return null;
    }
  };

  const renderPrice = () => {
    switch (travelProvider?.provider) {
      case TravelPassProvider["billy-bike"]: {
        const minPrice = travelProvider?.minPrice ?? 0;

        return (
          <div className="provider-details__price">
            <LabelIcon fill={AppColors.GRAY_200} width="20" height="20" />
            <div className="provider-details__price__name">
              {/*
                          t("map.provider_info.price.billy-bike")
                          t("map.provider_info.price.blue-bike")
                          t("map.provider_info.price.nmbs")
                          t("map.provider_info.price.mobit")
                          t("map.provider_info.price.delijn")
                          t("map.provider_info.price.velo-antwerpen")
                          t("map.provider_info.price.donkey-republic")
                          t("map.provider_info.price.mivb")
                          t("map.provider_info.price.tec")
                          t("map.provider_info.price.verts")
                          t("map.provider_info.price.villo")
                          t("map.provider_info.price.li-bia-velo")
                        */}
              <Trans i18nKey={`map.provider_info.price.${provider.type}`}>
                <span className="mobea__arial mobea__bold">
                  {{
                    price: formatCurrency(minPrice, language),
                  }}
                </span>
              </Trans>
            </div>
          </div>
        );
      }
      default:
        return travelProvider?.minPrice ? (
          <div className="provider-details__price">
            <LabelIcon fill={AppColors.GRAY_200} width="20" height="20" />
            <div className="provider-details__price__name">
              {t(`map.provider_info.price.${provider.type}`)}
            </div>
            <div className="provider-details__price__price">
              {formatCurrency(travelProvider.minPrice, language)}
            </div>
          </div>
        ) : null;
    }
  };

  const doPrimaryAction = () => {
    // switch (travelProvider?.provider) {
    //   case TravelPassProvider.nmbs:
    //     onPrimaryAction({
    //       nmbsSourceCode: provider.data.stop_id,
    //     });
    //     break;
    //   default:
    //     onPrimaryAction();
    //     break;
    // }

    onPrimaryAction();
  };

  const actionsAllowed =
    (applicationVariant === ApplicationVariant.EA &&
      budgetState === BudgetState.Active) ||
    applicationVariant === ApplicationVariant.MOVEASY;

  return (
    <div className="provider-details">
      {loading ? (
        <Spinner />
      ) : (
        <>
          {budgetState === BudgetState.Active &&
            (addressLoading ||
              address ||
              distanceLoading ||
              distance ||
              travelProvider?.minPrice) && (
              <>
                <div className="provider-details__address-button">
                  <div className="provider-details__address-button__address">
                    {(addressLoading || address) && (
                      <>
                        <LocationCursorIcon
                          fill={AppColors.GRAY_200}
                          width="24"
                          height="24"
                        />
                        <div className="provider-details__address-button__address__label">
                          {addressLoading && t("shared.loading")}
                          {!addressLoading && address}
                        </div>
                      </>
                    )}
                  </div>
                  {showRoute && travelProvider?.provider !== TravelPassProvider.nmbs && <RouteDirectionsButton onClick={showRoute} />}
                </div>
                <div className="provider-details__distance-price">
                  {(distanceLoading || distance) && (
                    <div className="provider-details__distance">
                      <WalkIcon
                        fill={AppColors.GRAY_200}
                        width="20"
                        height="20"
                      />
                      {distance && (
                        <div className="provider-details__distance__text">
                          {distanceLoading && t("shared.loading")}
                          {!distanceLoading &&
                            t("map.distance", {
                              distance,
                            })}
                        </div>
                      )}
                    </div>
                  )}
                  {renderPrice()}
                </div>
                <div className="separator" />
              </>
            )}

          <div className="provider-details__description">
            <Trans i18nKey={getDescriptionKey()}>
              Description
              {{
                providerName: travelProvider
                  ? t(`providers_names.${travelProvider.provider}`)
                  : "",
              }}
              {{
                startDate:
                  startDate && formatDateWithLocale(startDate, language),
              }}
              {{
                endDate: endDate && formatDateWithLocale(endDate, language),
              }}
            </Trans>
          </div>

          {actionsAllowed && (
            <div className="provider-details__actions">
              {secondaryActionName && (
                <MobeaButton
                  type="secondary"
                  onClick={onSecondaryAction}
                  disabled={!secondaryActionEnabled}
                >
                  {secondaryActionName}
                </MobeaButton>
              )}
              <MobeaButton
                onClick={doPrimaryAction}
                disabled={!primaryActionEnabled}
              >
                {primaryActionName}
              </MobeaButton>
            </div>
          )}
          <div className="provider-details__footer">{renderFooter()}</div>
        </>
      )}
    </div>
  );
}
