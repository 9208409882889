import { BrusselsCoords } from "maps/mapUtils";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  getLocationSearchSuggestions,
  LocationCoords,
  LocationSearchSuggestion,
  toLocationSearchSuggestions,
} from "services/mapService";

export function useLocationSearchSuggestions(
  location: LocationCoords | null,
  query: string,
  excludeLocalityType = false
) {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(false);

  const [suggestions, setSuggestions] = useState<LocationSearchSuggestion[]>(
    []
  );

  useEffect(() => {
    let canceled = false;

    if (!query.trim()) {
      setSuggestions([]);
      return;
    }

    setLoading(true);

    setError(false);

    const loadLocationSearchSuggestions = async () => {
      try {
        const response = await getLocationSearchSuggestions(
          location || BrusselsCoords,
          query,
          excludeLocalityType,
          history
        );

        if (canceled) {
          return;
        }

        if (response.error) {
          setLoading(false);

          setError(true);

          console.error(
            "Failed to fetch suggestions",
            response.error_description
          );
        } else {
          setSuggestions(
            toLocationSearchSuggestions(
              response,
              !!location,
              excludeLocalityType
            )
          );

          setLoading(false);
        }
      } catch (error) {
        if (!canceled) {
          setLoading(false);
          setError(true);
          console.error("Failed to fetch suggestions", error);
        }
      }
    };

    const timeoutID = window.setTimeout(loadLocationSearchSuggestions, 500);

    return () => {
      window.clearTimeout(timeoutID);
      canceled = true;
    };
    // we only care about query change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return [suggestions, loading, error] as const;
}
