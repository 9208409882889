export enum BikeStationStatus {
  Open = "OPN",
  Closed = "CLS",
}

export type BikeStation = {
  id: string;
  status: BikeStationStatus;
  availability: {
    bikes: number;
    slots: number;
  };
};
