import { MobeaModal } from "common/modal";
import { TabNavItem, TabPaneNav } from "common/navigation";
import "mobiscroll/css/mobiscroll.react.scss";
import * as mobiscroll from "mobiscroll/js/mobiscroll.react.min";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { TravelTimeOption } from "services/mapService";
import { capitalizeString, getDaysOfTheWeekShort, getMonthsShort } from "utils";
import { AppColors } from "utils/colors";

interface TravelTimeWheelPickerProps {
  initialDatetime: Date;
  initialTravelModeIndex: number; // 0 | 1 | 2
  onCancel: () => void;
  onSelect: (travelIndex: number, dateTime: Date) => void;
}

export function TravelTimeWheelPicker({
  initialDatetime,
  initialTravelModeIndex,
  onCancel,
  onSelect,
}: TravelTimeWheelPickerProps) {
  const { t } = useTranslation();

  const locale = useSelector((state) => state.user.language);

  const pickerRef = useRef<{
    getVal: () => Date;
    setVal: (d: Date) => void;
  } | null>(null);

  const modeRef = useRef(initialTravelModeIndex);

  const tabs: TabNavItem[] = Object.values(TravelTimeOption).map((value) => ({
    key: value,
    label: t(`map.travel_option_picker.${value}`),
  }));

  const [travelModeIndex, setTravelModeIndex] = useState(
    initialTravelModeIndex
  );

  useEffect(() => {
    // Sets the theme to iOS light
    mobiscroll.setOptions({
      theme: "ios",
      themeVariant: "light",
    });
  }, []);

  const confirmSelection = () => {
    onSelect(travelModeIndex, pickerRef.current?.getVal() ?? new Date());
  };

  const onDatetimeChange = (e) => {
    // force mode change only when we change actual date, not on update
    if (
      modeRef.current === 0 &&
      e.inst._tempValueText !== e.inst._oldValueText
    ) {
      // change from "Now" option if we change date / time
      modeRef.current = 1;
      setTravelModeIndex(1);
    }
  };

  const [now, setNow] = useState(new Date());

  useEffect(() => {
    const updateNowInterval = setInterval(() => {
      setNow(new Date());
    }, 2000);

    return () => {
      clearInterval(updateNowInterval);
    };
  }, []);

  const onModeChange = (index: number) => {
    modeRef.current = index;
    setTravelModeIndex(index);

    if (index === 0) {
      pickerRef.current?.setVal(new Date());
    }
  };

  const picker = useMemo(
    () => {
      return (
        <mobiscroll.Datepicker
          ref={pickerRef}
          display="inline"
          isOpen={true}
          touchUi
          min={now}
          controls={["datetime"]}
          timeWheels="|H|mm|"
          dateWheels="|DDD MMM D|"
          defaultSelection={initialDatetime}
          dayNamesShort={getDaysOfTheWeekShort(t, locale)}
          monthNamesShort={getMonthsShort(t, locale)}
          todayText={capitalizeString(t("history.today"))}
          onTempChange={onDatetimeChange}
          css={{
            width: "100%",
            border: "0 none !important",
            ".mbsc-scroller-wheel-item-3d": {
              backfaceVisibility: "hidden",
              WebkitBackfaceVisibility: "hidden",
            },
            ".mbsc-scroller-wheel-group-cont, .mbsc-scroller-wheel-line": {
              padding: 0,
              margin: 0,
            },
            ".mbsc-scroller-wheel-line": {
              borderRadius: "4px !important",
              background: AppColors.BACKGROUND,
              zIndex: "0 !important",
            },
            ".mbsc-scroller-wheel-group": {
              padding: "0.625em 0",
            },
            ".mbsc-ios.mbsc-scroller-wheel-cont-inline.mbsc-scroller-wheel-cont-3d":
              {
                background: AppColors.BACKGROUND,
              },
          }}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialDatetime, locale, now]
  );

  return (
    <MobeaModal
      title={t("map.select_travel_time")}
      type="secondary"
      usePortal
      confirmText={t("map.select_date_time")}
      onConfirm={confirmSelection}
      onClose={onCancel}
    >
      <TabPaneNav
        tabs={tabs}
        active={travelModeIndex}
        onTabChange={onModeChange}
        css={{
          height: 40,
          backgroundColor: AppColors.BACKGROUND,
          padding: 4,
          borderRadius: 4,
          ".mobea__tab-pane-nav__tab": {
            color: AppColors.GRAY_300,
            backgroundColor: AppColors.BACKGROUND,
            textTransform: "initial",
            fontWeight: "normal",
            borderRadius: 4,
          },
          ".mobea__switch": {
            backgroundColor: AppColors.PRIMARY,
            color: AppColors.TEXT_INVERTED,
            margin: 4,
            padding: 0,
            height: "calc(100% - 8px)",
            width: "calc(33.333% - 8px)",
          },
        }}
      />
      {picker}
    </MobeaModal>
  );
}
