import Color from "color";

export enum AppColors {
  PRIMARY = "var(--color-primary)",
  PRIMARY_LIGHT = "var(--color-primary-light)",
  PRIMARY_EXTRA_LIGHT = "var(--color-primary-extra-light)",
  SECONDARY = "var(--color-secondary)",
  TEXT_DEFAULT = "var(--color-text-default)",
  TEXT_INVERTED = "var(--color-text-inverted)",
  ERROR = "var(--color-error)",
  BACKGROUND = "var(--color-background)",
  TICKET_BACKGROUND = "var(--color-ticket-background)",
  BLUE = "var(--color-blue)",
  GRAY_100 = "var(--color-gray-100)",
  GRAY_200 = "var(--color-gray-200)",
  GRAY_300 = "var(--color-gray-300)",
  GRAY_400 = "var(--color-gray-400)",
  GRAY_500 = "var(--color-gray-500)",
  WHITE = "var(--color-white)",
  BLACK = "var(--color-black)",
  MOBIT = "var(--color-mobit)",
  BLUE_BIKE = "var(--color-blue-bike)",
  MOBIT_DISABLED = "var(--color-mobit-disabled)",
  VELO = "var(--color-velo)",
  STATUS_GREY = "var(--color-status-grey)",
  STATUS_BLUE = "var(--color-status-blue)",
  STATUS_GREEN = "var(--color-status-green)",
  STATUS_RED = "var(--color-status-red)",
}

export enum AppFonts {
  MAIN = "var(--font-family)",
  SECONDARY = "var(--font-family-secondary)",
  NUMBERS = "var(--font-family-numbers)",
}

const colorHelper = document.createElement("div");
colorHelper.style.display = "none";

document.body.appendChild(colorHelper);

export function getDerivedColor(name: string) {
  if (!/\bvar\(/.test(name)) {
    return name;
  }

  colorHelper.style.color = name;

  return getComputedStyle(colorHelper).color;
}

// force transparency if not included in the color by deriving it from the primary color
export function getDerivedAppColorTransparent(
  name: AppColors.PRIMARY_LIGHT | AppColors.PRIMARY_EXTRA_LIGHT
) {
  const color = getDerivedColor(name);

  if (color.startsWith("rgba")) {
    return color;
  } else {
    const opacity = name === AppColors.PRIMARY_LIGHT ? 0.56 : 0.1;

    return Color(getDerivedColor(AppColors.PRIMARY))
      .alpha(opacity)
      .rgb()
      .string();
  }
}
