import { RawDeLijnSingleTicket } from "services/delijnService";
import { DeLijnTravelPass } from "state/actions";
import { TravelPassProvider, TravelPassType, WalletType } from "../constants";
import { padWithZero } from "../helpers";
import { formatDateWithLocaleAsDigits } from "../intl";

export interface MissingDelijnDetailData {
  id: string;
  orderTimestamp?: number;
  plan: WalletType;
}

export function normalizeDeLijnTravelPass(
  rawTravelPass: RawDeLijnSingleTicket,
  missing: MissingDelijnDetailData
): DeLijnTravelPass {
  // fix for Safari - make De Lijn time string ISO 8601 compliant
  const validityStart = rawTravelPass.validityStart.replace("+0000", "Z");

  const validityEnd = rawTravelPass.validityEnd.replace("+0000", "Z");

  const start = new Date(validityStart);

  const end = new Date(validityEnd);

  const endDateFormatted = formatDateWithLocaleAsDigits(end, "nl");

  const endTimeFormatted = `${padWithZero(end.getHours())}u${padWithZero(
    end.getMinutes()
  )}`;

  const duration = (end.valueOf() - start.valueOf()) / 60000; // convert to minutes

  // @TODO handle type and provider other way than hardcoded - take from API?
  return {
    amount: 1,
    duration: duration || 60,
    price: rawTravelPass.tariff / 100,
    activation: start.valueOf(),
    expiration: end.valueOf(),
    endDateTimeFormatted: `tot ${endTimeFormatted} op ${endDateFormatted}`,
    shortCheckSum: rawTravelPass.quickChecksum,
    verificationCode: rawTravelPass.checksum,
    afzender: rawTravelPass.originatingAddress,
    types: [TravelPassType.Bus],
    provider: TravelPassProvider.delijn,
    orderTimestamp: start.valueOf(),
    ...missing,
  };
}
