import { CookiesDetailSection } from "common/cookies/DetailsSection";
import { ToggleGroup } from "common/forms";
import { NestedPage } from "common/page/NestedPage";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { advertisingChangeAction, analyticsChangeAction } from "state/actions";
import { getCookiesPolicyLink } from "utils";
import { getConfig } from "utils/configure";
import "./CookiesPage.scss";

export function CookiesPage(): ReactElement {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const language = useSelector((state) => state.user.language);

  const { advertisingAccepted, trackingAccepted } = useSelector(
    (state) => state.onboarding
  );

  const { clientNameShort } = getConfig();

  const changeAnalytics = () =>
    dispatch(analyticsChangeAction(!trackingAccepted));

  const changeAdvertising = () =>
    dispatch(advertisingChangeAction(!advertisingAccepted));

  const link = getCookiesPolicyLink(language);

  return (
    <NestedPage pageClassName="mobea-cookies" title={t("cookies.cookies")}>
      <section className="mobea-cookies__more">
        <span>{t("cookies.more_info", { clientNameShort })}</span>
        <span>&nbsp;</span>
        {link && (
          <a href={link} target="_blank" rel="noopener noreferrer">
            {link}
          </a>
        )}
      </section>

      <section className="mobea-cookies__section">
        <ToggleGroup
          enabled
          name="functional"
          label={
            <>
              {t("cookies.functional")}
              <span className="mobea-cookies__section__required">
                &nbsp;({t("shared.required")})
              </span>
            </>
          }
          onChange={() => undefined}
        />

        <CookiesDetailSection withTitle={false} kind="functional" />
      </section>

      <section className="mobea-cookies__section">
        <ToggleGroup
          enabled={trackingAccepted}
          name="analytical"
          label={t("cookies.analytical")}
          onChange={changeAnalytics}
        />

        <CookiesDetailSection withTitle={false} kind="analytical" />
      </section>

      <section className="mobea-cookies__section">
        <ToggleGroup
          enabled={advertisingAccepted}
          name="advertising"
          label={t("cookies.advertising")}
          onChange={changeAdvertising}
        />

        <CookiesDetailSection withTitle={false} kind="advertising" />
      </section>
    </NestedPage>
  );
}
