import { MobeaModal } from "common/modal";
import { CircleCheckIcon } from "icons/CircleCheckIcon";
import { ReactElement } from "react";
import { Trans, useTranslation } from "react-i18next";
import { AppColors } from "utils/colors";

interface ProfileUpdateSuccessDialogProps {
  onConfirm(): void;
}

export function ProfileUpdateSuccessDialog({
  onConfirm,
}: ProfileUpdateSuccessDialogProps): ReactElement {
  const { t } = useTranslation();

  return (
    <MobeaModal
      className="mobea__customer-update-success"
      type="secondary"
      title={t("profile.success")}
      image={<CircleCheckIcon fill={AppColors.PRIMARY} />}
      confirmText={t("shared.ok")}
      onConfirm={onConfirm}
    >
      <Trans i18nKey="profile.success_text" />
    </MobeaModal>
  );
}
