import { CheckMarkIcon } from "icons/CheckMarkIcon";
import { ReactElement } from "react";
import { Trans } from "react-i18next";
import { VertsPackage } from "services/vertsService";
import { formatCurrencyCustom } from "utils";
import "./VertsPackageTile.scss";

type Props = {
  language: string;
  data: VertsPackage;
  selected: boolean;
  onSelect(): void;
};

export function VertsPackageTile({
  language,
  data,
  selected,
  onSelect,
}: Props): ReactElement {
  const formatRate = (rate: number) =>
    formatCurrencyCustom(rate, language, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

  const minRate =
    data && typeof data.minRate === "number" ? formatRate(data.minRate) : "";

  const maxRate =
    data && typeof data.maxRate === "number" ? formatRate(data.maxRate) : "";

  return (
    <div className="mobea__verts-package" onClick={onSelect}>
      <div className={`selected-icon ${selected ? "selected" : ""}`}>
        {selected && <CheckMarkIcon fill="white" />}
      </div>
      <img className="car-image" src="/static/images/car.png" alt="car" />
      <div className="package-name">{data.name}</div>
      <div className="people-info">
        {data.capacity && (
          <Trans i18nKey="verts_order.people">{{ max: data.capacity }}</Trans>
        )}
      </div>
      <div className="price-range">
        € {minRate !== maxRate ? `${minRate} - ` : ""}
        {maxRate}*
      </div>
    </div>
  );
}
