import { ReactElement } from "react";
import { DefaultIconProps } from "../props";

export function ScooterIcon({
  fill = "currentColor",
  className = "",
  height = "20",
  width = "20",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={fill}>
        <path d="M19 7a2 2 0 00-2-2h-3v2h3v2.7L13.5 14H10V9H6a4 4 0 00-4 4v3h2a3 3 0 106 0h4.5l4.5-5.7V7zM7 17a1 1 0 01-1-1h2c0 .6-.5 1-1 1z" />
        <path d="M5 6h5v2H5zM19 13a3 3 0 100 6 3 3 0 000-6zm0 4a1 1 0 01-1-1c0-.6.4-1 1-1s1 .4 1 1-.4 1-1 1z" />
      </g>
    </svg>
  );
}
