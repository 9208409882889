import { useModal } from "common/hooks/useModal";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  ApplicationVariant,
  formatCurrencyCustom,
  Routes,
  TravelPassProvider,
  WalletType,
} from "utils";
import { applicationVariant } from "utils/configure";

export function useCheckEnoughBudget(
  provider: TravelPassProvider,
  price: number
) {
  const { amount: remainingBudget, privateAmount } = useSelector(
    (state) => state.user
  );

  const [wallet, setWallet] = useState(WalletType.BUSINESS);

  const ibd = useInsufficientBudgetDialog(
    provider,
    price,
    applicationVariant === ApplicationVariant.MOVEASY ? wallet : undefined
  );

  return function check(wallet: WalletType) {
    const nok =
      (wallet === WalletType.PRIVATE ? privateAmount : remainingBudget) < price;

    setWallet(wallet);

    if (nok) {
      ibd.show();
    }

    return !nok;
  };
}

function useInsufficientBudgetDialog(
  provider: TravelPassProvider,
  price: number,
  wallet?: WalletType
) {
  const { t } = useTranslation();

  const { language: locale } = useSelector((state) => state.user);

  const priceFormatted = formatCurrencyCustom(price, locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: "EUR",
    currencyDisplay: "symbol",
    style: "currency",
  });

  const location = useLocation();

  const history = useHistory();

  const isPrivateWallet = wallet === WalletType.PRIVATE;

  return useModal({
    type: "error",
    title: t("shared.insufficient_budget"),
    confirmText: isPrivateWallet ? t("home.add_money") : t("shared.understand"),
    onConfirm: isPrivateWallet
      ? () => history.push(Routes.AddMoney)
      : undefined,
    cancelText: isPrivateWallet ? t("verts_order.switch_providers") : undefined,
    onCancel:
      isPrivateWallet && location.pathname !== Routes.GetTravelPass
        ? () => history.push(Routes.GetTravelPass)
        : undefined,
    usePortal: true,
    children: (
      <p>
        {/*
      t("providers.insufficient_budget.delijn")
      t("providers.insufficient_budget.mobit")
      t("providers.insufficient_budget.nmbs")
      t("providers.insufficient_budget.velo-antwerpen")
      t("providers.insufficient_budget.verts")
      t("providers.insufficient_budget.blue-bike")
      // TODO others?
    */}
        <Trans
          i18nKey={`providers.insufficient_budget.${provider}`}
          values={{ price: priceFormatted }}
        />
        <span>&nbsp;</span>
        {!wallet
          ? t("providers.insufficient_budget.choose_other")
          : isPrivateWallet
          ? t("providers.insufficient_budget.choose_other_or_topup")
          : t("providers.insufficient_budget.choose_other_or_private")}
      </p>
    ),
  });
}
