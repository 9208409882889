import { BlueBikeImage } from "common/travelPasses/blueBike/BlueBikeImage";
import { TutorialDialog, TutorialLine } from "common/tutorial/";
import { ReactElement } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { SupportedLanguage, TravelPassProvider } from "utils";
import { AppColors } from "utils/colors";

type Props = {
  forceShow?: boolean;
};

export function BlueBikeTutorial({ forceShow = false }: Props): ReactElement {
  const { t } = useTranslation();

  const locale = useSelector((state) => state.user.language);

  const { returnTime, validity } = useSelector(
    (state) => state.ordering.blueBikeData
  );

  const charges: Record<SupportedLanguage, string> = {
    en: "https://www.blue-bike.be/en/fees-and-deductibles",
    fr: "https://www.blue-bike.be/fr/supplements-et-propres-risques",
    nl: "https://www.blue-bike.be/nl/tarieven",
  };

  return (
    <TutorialDialog
      provider={TravelPassProvider["blue-bike"]}
      className="blue-bike-tutorial"
      steps={[
        {
          headline: t("velo_detail.borrowing_bike"),
          image: (
            <div
              css={{
                textAlign: "center",
                "> svg": {
                  width: 90,
                },
              }}
            >
              <BlueBikeImage />
              <span
                css={{
                  color: AppColors.BLUE_BIKE,
                  fontSize: "1.375rem",
                  lineHeight: 1,
                  fontWeight: "bold",
                  display: "block",
                  marginTop: -4,
                }}
              >
                Blue bike
              </span>
            </div>
          ),
          info: (
            <>
              <TutorialLine
                infoText={t("blue-bike_detail.tutorial.1.find_station")}
              />
              <TutorialLine
                infoText={
                  <Trans i18nKey="blue-bike_detail.tutorial.1.enter_code">
                    <span className="mobea__arial"></span>
                  </Trans>
                }
              />
              <TutorialLine
                infoText={t("blue-bike_detail.tutorial.1.unlock")}
              />
              <TutorialLine
                infoText={
                  <Trans i18nKey="blue-bike_detail.tutorial.1.exchange">
                    <span className="mobea__arial">{{ returnTime }}</span>
                  </Trans>
                }
              />
              <TutorialLine
                infoText={
                  <Trans i18nKey="blue-bike_detail.tutorial.1.return_bike">
                    <span className="mobea__arial">{{ validity }}</span>
                  </Trans>
                }
              />
            </>
          ),
        },
        {
          headline: t("velo_detail.returning_bike"),
          image: (
            <div
              css={{
                width: 80,
                height: 80,
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                justifyContent: "center",
                border: `3px solid ${AppColors.BLUE_BIKE}`,
                margin: "0 auto",
              }}
            >
              <span
                css={{
                  color: AppColors.BLUE_BIKE,
                  fontSize: 48,
                  lineHeight: 1,
                  fontWeight: "bold",
                  display: "block",
                }}
              >
                P
              </span>
            </div>
          ),
          info: (
            <>
              <TutorialLine infoText={t("blue-bike_detail.tutorial.2.lock")} />
              <TutorialLine infoText={t("blue-bike_detail.tutorial.2.wait")} />
              <TutorialLine
                infoText={
                  <Trans i18nKey="blue-bike_detail.tutorial.2.contact_us">
                    <a
                      href={`https://www.blue-bike.be/${locale}/contact`}
                      style={{ whiteSpace: "nowrap" }}
                    ></a>
                  </Trans>
                }
              />
              <TutorialLine
                infoText={
                  <Trans i18nKey="blue-bike_detail.tutorial.2.fine">
                    <a
                      href={charges[locale]}
                      style={{ whiteSpace: "nowrap" }}
                    ></a>
                    <span className="mobea__arial">{{ validity }}</span>
                  </Trans>
                }
              />
            </>
          ),
        },
      ]}
      forceShow={forceShow}
    />
  );
}
