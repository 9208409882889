import { DefaultIconProps } from "icons/props";
import { ReactElement } from "react";

export function PoppyLogo({
  className = "",
  width,
  height,
}: Pick<DefaultIconProps, "className" | "width" | "height">): ReactElement {
  return (
    <img
      className={className}
      width={width}
      height={height}
      src=" data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAAAfCAMAAABQ4yrvAAAAY1BMVEX/QSv/inv/ZlT/Qiv/bl7/qZ//gnP/6eb/s6r/ZVL/rKL/ycL/pZr/1M//yML/kYT/hnj/STT/1dD/Szb/5OH/6+j/UDv/WEX/gXH/eWn/XUr/3tr/2tb/xL7/YU7//////v5+Cj4YAAAAH3RSTlPn8Ozl7PTv/PXr9fjz+ffx7+j56Pz96erv7ur6+vfrlUKjaQAAAN1JREFUSMftlckOgkAQRKEBWWbYZMf1/7/SQibpjopy4eCE8lC+dOcdSBMcz9koHn5bZVdbpW5djg8mwS24Z+xf1gnsozv0YNalOr5xInAg2AeHjOGkEuMAHKELdIoma9SN1voi1ApcC3WgdSDUNcZqpfqILoQ6R2dCXTlOeWe1QudWq5skSdQXNcaRfNZgJdQK3Py+EFavvRATq9T1YQ4Z9SnLskao3Xk8GPXZrMdgMv/rxQsxWXgbKzPjC3lLao26JKJR7HTEKcFX9HOV17sP6nFat+IDhuzq/1U/ACPKTFF14+yrAAAAAElFTkSuQmCC"
    />
  );
}
