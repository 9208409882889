import { ReactElement } from "react";
import { AppColors } from "utils/colors";

export function BottomBarBackground(): ReactElement {
  return (
    <svg
      width="1000"
      height="62"
      viewBox="0 0 1000 62"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <path
          d="M0,6 L446.738199,6 C454.212783,6.00000234 460.909599,10.6193884 463.564494,17.6065869 C470.942494,37.0241357 483.08722,46.7329102 499.99867,46.7329102 C516.823646,46.7329102 528.695227,37.1231711 535.613414,17.903693 C538.184221,10.761711 544.959035,6 552.549619,6 L1000,6 L1000,6 L1000,62 L0,62 L0,6 Z"
          id="path-1"
        ></path>
        <filter
          x="-0.8%"
          y="-13.4%"
          width="101.5%"
          height="126.8%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="0"
            dy="0"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            stdDeviation="2.5"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.08 0"
            type="matrix"
            in="shadowBlurOuter1"
          ></feColorMatrix>
        </filter>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <use
            fill="black"
            fillOpacity="1"
            filter="url(#filter-2)"
            xlinkHref="#path-1"
          ></use>
          <use
            fill={AppColors.BACKGROUND}
            fillRule="evenodd"
            xlinkHref="#path-1"
          ></use>
        </g>
      </g>
    </svg>
  );
}
