import { ChevronIcon } from "icons/ChevronIcon";
import { ReactElement } from "react";
import { useSelector } from "react-redux";
import { formatCurrency } from "utils";
import { AppColors, AppFonts } from "utils/colors";
export type WalletHeaderProps = {
  expanded: boolean;
  title: string;
  icon: ReactElement;
  amount: number;
  outOfBudgetPeriod?: boolean;
};

export function WalletHeader({
  expanded,
  title,
  icon,
  amount,
  outOfBudgetPeriod,
}: WalletHeaderProps) {
  const locale = useSelector((state) => state.user.language);

  const formattedAmount = formatCurrency(amount, locale);

  return (
    <>
      <div
        css={{
          backgroundColor:
            !outOfBudgetPeriod && amount
              ? "rgba(var(--rgb-primary), 0.2)"
              : "rgba(var(--rgb-gray-200), 0.2)",
          padding: "0.75rem",
          borderRadius: "9999px",
          color:
            !outOfBudgetPeriod && amount
              ? AppColors.PRIMARY
              : AppColors.GRAY_200,
          fontSize: "1.25rem",
          lineHeight: 0,
        }}
      >
        {icon}
      </div>
      <div
        css={{
          flexGrow: 1,
        }}
      >
        {title}
      </div>
      <div
        css={{
          color:
            !outOfBudgetPeriod && amount
              ? AppColors.PRIMARY
              : AppColors.GRAY_200,
          fontWeight: 700,
          fontFamily: AppFonts.NUMBERS,
        }}
      >
        {formattedAmount}
      </div>
      <ChevronIcon
        css={{
          flexShrink: 0,
          transition: "transform 500ms",
        }}
        orientation={expanded ? "up" : "down"}
      />
    </>
  );
}
