import { useExpenses } from "common/hooks";
import { BudgetState, useBudgetState } from "common/hooks/useBudgetState";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Expense } from "state/actions/TravelPassActions";
import { BUDGET_COOLDOWN_PERIOD_DAYS, Routes } from "utils";
import {
  groupHistoryItems,
  HistoryItemsGroup,
  HistoryNoCosts,
  todayString,
  translateDate,
  yesterdayString,
} from "../costs";
import { HistoryPageSpinner } from "../HistoryPageSpinner";
import { HistoryExpense } from "./HistoryExpense";
import "./HistoryExpensesList.scss";

export function HistoryExpensesList(): ReactElement {
  const { t } = useTranslation();

  const [loadingExpenses] = useExpenses();

  const { language: locale, remainingDays } = useSelector(
    (state) => state.user
  );

  const [loadingBudget, budgetState] = useBudgetState();

  const expenses = useSelector((state) => state.passes.expenses);

  const loading = loadingExpenses || loadingBudget;

  const today = todayString(locale);

  const yesterday = yesterdayString(locale);

  const toHistoryBlocks = (groups: HistoryItemsGroup<Expense>[]) =>
    groups.map((group) => {
      const isToday = group.date === today;

      return (
        <ul key={`ul__${group.date}`} className="mobea-history__costs__block">
          <li
            key={group.date}
            className={`mobea-history__costs__date ${isToday ? "today" : ""}`}
          >
            {translateDate(group.date, today, yesterday, t)}
          </li>

          {group.items.map((item) => (
            <HistoryExpense
              key={item.id}
              locale={locale}
              id={item.id}
              provider={item.provider}
              price={item.price}
              acceptedPrice={item.acceptedPrice}
              status={item.status}
              expenseDate={item.expensedDate}
            />
          ))}
        </ul>
      );
    });

  const dt = (value: string) => value;

  const getNoCostTranslateKey = () => {
    if (budgetState === BudgetState.Expired) {
      if (-remainingDays < BUDGET_COOLDOWN_PERIOD_DAYS) {
        return dt("history.no_expenses_text_budget_expired_days");
      } else {
        return dt("history.no_expenses_text_budget_expired");
      }
    } else {
      return dt("history.no_expenses_text");
    }
  };

  return (
    <>
      <HistoryPageSpinner loading={loading} />
      <div
        css={{
          paddingLeft: 32,
        }}
      >
        {expenses.length === 0 ? (
          <HistoryNoCosts
            noCostsTranslateKey={getNoCostTranslateKey()}
            daysLeft={BUDGET_COOLDOWN_PERIOD_DAYS + remainingDays}
            addCostTranslateKey={
              budgetState === BudgetState.Expired
                ? undefined
                : dt("history.add_expense_text")
            }
            startButtonTranslateKey={
              budgetState === BudgetState.Expired
                ? dt("history.apply_for_refund")
                : dt("history.start")
            }
            startLinkTo={Routes.ExpenseAddEmpty}
            active={
              budgetState === BudgetState.Active ||
              budgetState === BudgetState.NotActiveYet ||
              -remainingDays < BUDGET_COOLDOWN_PERIOD_DAYS
            }
          />
        ) : (
          <>
            <h2 className="mobea-history__costs__title">
              {t("history.submitted")}
            </h2>

            <div className="mobea-history__costs mobea-history__expense">
              {toHistoryBlocks(
                groupHistoryItems(
                  [...expenses].reverse(),
                  "createdDate",
                  locale
                )
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}
