import { MobeaModal } from "common/modal";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { ApiErrors } from "utils";

type Props = {
  errorCode: string;
  close(): void;
};

export function RoutePlannerError({ errorCode, close }: Props): ReactElement {
  const { t } = useTranslation();

  switch (errorCode) {
    case ApiErrors.ROUTE_PLANNER_SOURCE_OUTSIDE_OF_AREA_ERROR:
    case ApiErrors.ROUTE_PLANNER_DESTINATION_OUTSIDE_OF_AREA_ERROR:
    case ApiErrors.ROUTE_PLANNER_OUTSIDE_OF_AREA_ERROR:
      return (
        <MobeaModal
          type="error"
          confirmText={t("shared.ok")}
          title={t("shared.oops")}
          onConfirm={close}
          usePortal={true}
        >
          {errorCode === ApiErrors.ROUTE_PLANNER_OUTSIDE_OF_AREA_ERROR &&
            t("map.error_source_outside")}
          {errorCode === ApiErrors.ROUTE_PLANNER_SOURCE_OUTSIDE_OF_AREA_ERROR &&
            t("map.error_source_outside")}
          {errorCode ===
            ApiErrors.ROUTE_PLANNER_DESTINATION_OUTSIDE_OF_AREA_ERROR &&
            t("map.error_destination_outside")}
        </MobeaModal>
      );
    case ApiErrors.ROUTE_PLANNER_SOURCE_DESTINATION_EQUAL:
      return (
        <MobeaModal
          type="error"
          confirmText={t("shared.ok")}
          title={t("shared.oops")}
          onConfirm={close}
          usePortal={true}
        >
          {t("map.error_source_destination_same")}
        </MobeaModal>
      );
    default:
      return (
        <MobeaModal
          type="error"
          confirmText={t("shared.ok")}
          title={t("shared.oops")}
          onConfirm={close}
          usePortal={true}
        >
          {t("shared.network_error_text")}
        </MobeaModal>
      );
  }
}
