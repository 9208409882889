import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  LocationSearchPlace,
  LocationSearchPlaceFull,
} from "services/mapService";
import { getVertsPackages, VertsPackage } from "services/vertsService";
import { ApiErrors } from "utils";

export function useVertsGetPackages() {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [packages, setPackages] = useState<VertsPackage[]>([]);

  const [errorCode, setErrorCode] = useState<ApiErrors | null>(null);

  const loadVertsPackages = async (
    origin: LocationSearchPlaceFull,
    destination: LocationSearchPlace,
    requestedAt?: Date
  ) => {
    setLoading(true);

    setErrorCode(null);

    try {
      const response = await getVertsPackages(
        origin,
        destination,
        requestedAt,
        history
      );

      if (response.error) {
        setErrorCode(response.error_code as ApiErrors);

        console.error(
          `Failed to fetch Verts packages: ${response.error_code} - ${response.error_description}`
        );
      } else {
        setPackages(response.data);
      }
    } catch (error) {
      setErrorCode(ApiErrors.ORDER_ERROR);

      console.error("Failed to fetch Verts packages", error);
    } finally {
      setLoading(false);
    }
  };

  return [loadVertsPackages, packages, loading, errorCode] as const;
}
