import { TravelTypeIcon } from "common/travelPasses/components/TravelTypeIcon";
import { inSameLocation } from "maps";
import { MAX_ZOOM } from "maps/effects";
import { useEffect, useRef, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { useSelector } from "react-redux";
import { ProviderData } from "services/mapService";
import { AppColors } from "utils/colors";

const H = window.H;

export function useProviderDataMarkers(
  data: ProviderData[] | null,
  selectedProvider: ProviderData | null,
  setSelectedProvider?: (provider: ProviderData | null) => void
) {
  const { providers: allProviders, providersToTypes: providerToTypeMapping } =
    useSelector((state) => state.passes);

  const mapRef = useRef<H.Map | null>(null);

  const selectedProviderRef = useRef<ProviderData | null>(selectedProvider);

  const [clusteringProvider, setClusteringProvider] =
    useState<H.clustering.Provider | null>(null);

  //set theme and events
  useEffect(() => {
    if (!clusteringProvider) {
      return;
    }

    const getMarkerColor = (
      currentProvider: ProviderData,
      clusteredProviders: ProviderData[] = []
    ) => {
      // single marker or single type provider
      const singleProvider =
        new Set(
          clusteredProviders.map((clusterProvider) => clusterProvider.type)
        ).size <= 1;

      if (singleProvider) {
        return (
          allProviders.find(
            (travelProvider) => travelProvider.provider == currentProvider.type
          )?.color || AppColors.GRAY_300
        );
      }

      return AppColors.GRAY_300;
    };

    const createProviderMarker = (
      provider: ProviderData,
      position: H.geo.IPoint,
      minZoom: number,
      maxZoom?: number,
      clusteredProviders?: ProviderData[],
      clusterBounds?: H.geo.Rect
    ) => {
      const passTypes = providerToTypeMapping[provider.type];

      const color = getMarkerColor(provider, clusteredProviders);

      const isSelected = () =>
        selectedProviderRef.current &&
        (clusteredProviders || [provider]).some(
          (provider) => provider.id === selectedProviderRef.current!.id
        );

      const otherIsSelected = () => !!selectedProviderRef.current;

      const element = renderToStaticMarkup(
        <div
          className={`mobea__provider-marker ${isSelected() ? "selected" : ""}`}
        >
          <div
            className="background"
            css={{
              width: "100%",
              position: "absolute",
              height: "100%",
              top: 0,
              left: 0,
              borderRadius: "50%",
              backgroundColor: color,
            }}
          ></div>
          <TravelTypeIcon
            type={passTypes[0]}
            height={16}
            width={16}
            fill="white"
          />
          {clusteredProviders && clusteredProviders.length > 1 && (
            <div className="cluster-number" style={{ borderColor: color }}>
              {clusteredProviders.length}
            </div>
          )}
        </div>
      );

      const icon = new H.map.DomIcon(element, {
        // the function is called every time marker enters the viewport
        onAttach: (clonedElement, icon, marker) => {
          marker.getData().element = clonedElement;

          if (isSelected()) {
            clonedElement.classList.add("selected");

            clonedElement.classList.remove("disabled");
          } else {
            clonedElement.classList.remove("selected");

            otherIsSelected() && clonedElement.classList.add("disabled");
          }
        },
      });

      return new H.map.DomMarker(position, {
        icon,
        min: Math.min(minZoom, MAX_ZOOM),
        max:
          maxZoom &&
          Math.min(
            maxZoom,
            clusteredProviders && inSameLocation(clusteredProviders)
              ? maxZoom
              : MAX_ZOOM - 1
          ),
        data: {
          element,
          provider,
          clusteredProviders,
          clusterBounds,
        },
      });
    };

    const oldTheme = clusteringProvider.getTheme();

    clusteringProvider.setTheme(
      new (class implements H.clustering.ITheme {
        getClusterPresentation(cluster: H.clustering.ICluster): H.map.Object {
          const providers = [] as ProviderData[];

          cluster.forEachDataPoint((dataPoint) =>
            providers.push(dataPoint.getData())
          );

          return createProviderMarker(
            providers[0],
            cluster.getPosition(),
            cluster.getMinZoom(),
            cluster.getMaxZoom(),
            providers,
            (cluster as any).getBoundingBox()
          );
        }

        getNoisePresentation(
          noisePoint: H.clustering.INoisePoint
        ): H.map.Object {
          return createProviderMarker(
            noisePoint.getData(),
            noisePoint.getPosition(),
            noisePoint.getMinZoom()
          );
        }
      })()
    );

    const markerClicked = (e: H.util.Event) => {
      const marker = e.target as H.map.Marker;

      if (!marker.getData()) {
        return;
      }

      e.stopPropagation();

      if (
        marker.getData().clusterBounds &&
        !inSameLocation(marker.getData().clusteredProviders!)
      ) {
        mapRef.current?.getViewModel().setLookAtData(
          {
            bounds: marker.getData().clusterBounds,
          },
          true
        );
      } else {
        setSelectedProvider?.(marker.getData()?.provider);
      }
    };

    clusteringProvider.addEventListener("tap", markerClicked);

    return () => {
      if (clusteringProvider) {
        clusteringProvider.removeEventListener("tap", markerClicked);
        clusteringProvider.setTheme(oldTheme);
      }
    };
  }, [
    allProviders,
    clusteringProvider,
    providerToTypeMapping,
    setSelectedProvider,
  ]);

  // update markers
  useEffect(() => {
    if (!clusteringProvider) {
      return;
    }

    if (!data) {
      clusteringProvider.setDataPoints([]);
      return;
    }

    clusteringProvider.setDataPoints(
      data.map(
        (provider) =>
          new H.clustering.DataPoint(provider.lat, provider.lng, 1, provider)
      )
    );
  }, [clusteringProvider, data]);

  // update marker selection
  useEffect(() => {
    selectedProviderRef.current = selectedProvider;

    if (!clusteringProvider) {
      return;
    }

    // @types are not up to date
    (clusteringProvider as any)
      .getRootGroup()
      .getObjects(false)
      .forEach((marker) => {
        const markerProviderId = marker.getData()?.provider?.id;

        if (selectedProvider && selectedProvider.id === markerProviderId) {
          marker.setZIndex(100);
          marker.getData().element?.classList?.add("selected");
          marker.getData().element?.classList?.remove("disabled");
        } else if (!selectedProvider) {
          marker.setZIndex(0);
          marker.getData().element?.classList?.remove("selected", "disabled");
        } else {
          marker.setZIndex(0);
          marker.getData().element?.classList?.remove("selected");
          selectedProvider &&
            marker.getData().element?.classList?.add("disabled");
        }
      });
  }, [clusteringProvider, selectedProvider]);

  const setMapReferences = (
    map: H.Map,
    clusteringProvider: H.clustering.Provider
  ) => {
    mapRef.current = map;
    setClusteringProvider(clusteringProvider);
  };

  return [setMapReferences] as const;
}
