import { DefaultIconProps } from "icons/props";
import { ReactElement } from "react";

export function PlayIcon({
  className = "",
  fill = "currentColor",
  height = "37",
  width = "29",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 29 37"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M.17 2.38v32.25a1.8 1.8 0 002.76 1.5L28 20.02a1.8 1.8 0 000-3.01L2.93.87a1.8 1.8 0 00-2.76 1.5zm3.58 3.28L23.73 18.5 3.75 31.35V5.65z"
        fill={fill}
      />
    </svg>
  );
}
