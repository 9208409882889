import { DefaultIconProps } from "icons/props";
import { ReactElement } from "react";

export function DeskalotLogo({
  className = "",
  width,
  height,
}: Pick<DefaultIconProps, "className" | "width" | "height">): ReactElement {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
    >
      <rect fill="#78D09F" width="40" height="40" rx="3" />
      <path
        d="M30 32h2c.5 0 1-.5 1-1v-8c0-1-1-2-2-2H9c-1 0-2 1-2 2v8c0 .5.5 1 1 1h2c.5 0 1-.5 1-1v-5s0-1 1-1h16s1 0 1 1v5c0 .5.5 1 1 1Z"
        fill="#FFF"
        fillRule="nonzero"
      />
      <path
        d="m19.7 12.7-9.6 5.6c-.9.6-2 .6-2.7-.1-.6-.7-.5-2 .4-2.5l10.7-6.4c.7-.4 1.8-.4 2.5 0L32.2 16c1 .5 1 1.7.4 2.4-.7.8-1.8.7-2.7.2l-10.2-5.9Z"
        fill="#14161C"
      />
    </svg>
  );
}
