import { useBooleanState } from "common/hooks";
import { RefreshIcon } from "icons/RefreshIcon";
import { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./UpdateChecker.scss";

export function UpdateChecker(): ReactElement {
  const { t } = useTranslation();

  const [messageVisible, showMessage] = useBooleanState();

  useEffect(() => {
    const onUpdateReady = () => showMessage();

    window.addEventListener("updateready", onUpdateReady);

    return () => {
      window.removeEventListener("updateready", onUpdateReady);
    };
  }, [showMessage]);

  const refreshPage = () => location.reload();

  return (
    <div
      className={`mobea-update-checker ${messageVisible ? "visible" : ""}`}
      onClick={refreshPage}
    >
      <RefreshIcon />

      {t("warning.refresh_app")}
    </div>
  );
}
