import { Action } from "state";
import { getInitialStateWithPersistance } from "utils";
import { TutorialState } from "./stateTypes";

const initialState: TutorialState = {
  name: undefined,
  step: undefined,
  historyTutorialShown: false,
  providersTutorialShown: false,
  mainTutorialShown: false,
  mapTutorialShown: false,
};

export function TutorialReducer(
  state: TutorialState = getInitialStateWithPersistance(
    "tutorial",
    initialState
  ),
  action: Action
): TutorialState {
  switch (action.type) {
    case "START_TUTORIAL":
      return {
        ...state,
        step: 1,
        name: action.name,
      };

    case "END_TUTORIAL":
      return {
        ...state,
        step: undefined,
        name: undefined,
        providersTutorialShown:
          state.name === "providers" ? true : state.providersTutorialShown,
        mainTutorialShown:
          state.name === "main" ? true : state.mainTutorialShown,
      };

    case "NEXT_TUTORIAL_STEP":
      return { ...state, step: (state.step ?? 0) + 1 };

    case "PREV_TUTORIAL_STEP":
      return { ...state, step: (state.step ?? 0) - 1 };

    case "SET_HISTORY_TUTORIAL_SHOWN":
      return { ...state, historyTutorialShown: action.shown };

    case "SET_MAP_TUTORIAL_SHOWN":
      return { ...state, mapTutorialShown: action.shown };

    default:
      return state;
  }
}
