import { TutorialDialog, TutorialLine } from "common/tutorial/";
import { ParkingIcon } from "icons/ParkingIcon";
import { ReactElement } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  MOBIT_FAQ_URL,
  MOBIT_SUPPORT_PHONE_NUMBER,
  TravelPassProvider,
} from "utils";
import { AppColors } from "utils/colors";

type Props = {
  duration: number;
  forceShow?: boolean;
};

export function MobitTutorial({
  duration,
  forceShow = false,
}: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <TutorialDialog
      provider={TravelPassProvider.mobit}
      className="mobit-tutorial"
      steps={[
        {
          headline: t("mobit_detail.tutorial_1.borrowing_bike"),
          image: <img src="/static/images/mobit-return-bike.png" />,
          info: (
            <>
              <TutorialLine
                infoText={t("mobit_detail.tutorial_1.find_on_map")}
              />
              <TutorialLine infoText={t("mobit_detail.tutorial_1.scan_code")} />
              <TutorialLine
                infoText={t("mobit_detail.tutorial_1.lock_opens")}
              />
              <TutorialLine
                infoText={t("mobit_detail.tutorial_1.code_where")}
              />
              <TutorialLine infoText={t("mobit_detail.tutorial_1.enjoy")} />
            </>
          ),
        },
        {
          headline: t("mobit_detail.tutorial_2.returning_bike"),
          image: (
            <ParkingIcon
              className="mobea__sharpen-image"
              width={80}
              height={80}
              fill={AppColors.MOBIT}
            />
          ),
          info: (
            <>
              <TutorialLine
                infoText={
                  <Trans i18nKey="mobit_detail.tutorial_2.parking">
                    {"you will be "}
                    <a
                      href={MOBIT_FAQ_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      charged extra
                    </a>
                    .
                  </Trans>
                }
              />
              <TutorialLine infoText={t("mobit_detail.tutorial_2.close")} />
              <TutorialLine
                infoText={
                  <Trans i18nKey="mobit_detail.tutorial_2.fine">
                    {"longer then "}
                    <span className="mobea__arial">{{ duration }}</span> hours.
                  </Trans>
                }
              />
              <TutorialLine
                infoText={
                  <Trans i18nKey="mobit_detail.tutorial_2.contact">
                    {"Contact Mobit if you have any questions "}
                    <a
                      className="mobea__arial"
                      href={`tel:${MOBIT_SUPPORT_PHONE_NUMBER}`}
                    >
                      {{
                        phone: MOBIT_SUPPORT_PHONE_NUMBER,
                      }}
                    </a>
                    .
                  </Trans>
                }
              />
            </>
          ),
        },
      ]}
      forceShow={forceShow}
    />
  );
}
