import { MobeaDropdown } from "common/forms";
import { useBooleanState } from "common/hooks";
import { MobeaModal, useContactUsModal } from "common/modal";
import { BottomNavBar } from "common/navigation";
import { FaqIcon } from "icons/FaqIcon";
import { GlobeIcon } from "icons/GlobeIcon";
import { InfoIcon } from "icons/InfoIcon";
import { LockIcon } from "icons/LockIcon";
import { PhoneIcon } from "icons/PhoneIcon";
import { PowerOffIcon } from "icons/PowerOffIcon";
import { ProfileIcon } from "icons/ProfileIcon";
import { RefundIcon } from "icons/RefundIcon";
import { StudyIcon } from "icons/StudyIcon";
import { UpIcon } from "icons/UpIcon";
import { WalletAltIcon } from "icons/WalletAltIcon";
import { MouseEvent, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { updateCustomer } from "services/customerService";
import {
  ApplicationVariant,
  getFaqLink,
  getLanguages,
  getPrivacyLink,
  getTermsAndConditionsLink,
  LOCAL_STORAGE_AUTH_KEY,
  LOCAL_STORAGE_CONFIG_KEY,
  LOCAL_STORAGE_KEY,
  Routes,
} from "utils";
import { applicationVariant } from "utils/configure";
import i18n from "utils/i18n";
import { SettingsItem, SettingsSection } from "./sections";
import "./SettingsPage.scss";

export function SettingsPage(): ReactElement {
  const { t } = useTranslation();

  const history = useHistory();

  const { customerId, language } = useSelector((state) => state.user);

  const { show: showContactUs } = useContactUsModal();

  const changeLanguage = (value: string) => {
    if (value !== language) {
      i18n.changeLanguage(value);

      updateCustomer(
        customerId,
        {
          language: value,
        },
        history
      );
    }
  };

  const openContactUs = (e: MouseEvent) => {
    e.preventDefault();

    showContactUs();
  };

  const faqLink = getFaqLink(language);

  const privacyPolicyLink = getPrivacyLink(language);

  const termsLink = getTermsAndConditionsLink(language);

  const handleLogOutConfirm = () => {
    window.localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);

    window.localStorage.removeItem(LOCAL_STORAGE_CONFIG_KEY);

    const value = window.localStorage.getItem(LOCAL_STORAGE_KEY);

    if (value) {
      const { onboarding, tutorial } = JSON.parse(value);

      window.localStorage.setItem(
        LOCAL_STORAGE_KEY,
        JSON.stringify({ onboarding, tutorial, user: {} })
      );
    }

    window.location.href = Routes.PreLogin;
  };

  const [
    showLogOutConfirmation,
    setShowLogOutConfirmation,
    resetShowLogOutConfirmation,
  ] = useBooleanState();

  return (
    <main className="mobea__settings">
      <header className="mobea__settings__header">
        <h1 className="mobea__settings__header__headline">
          {t("settings.settings")}
        </h1>
      </header>

      <div className="mobea__settings__content">
        <SettingsSection headline={t("settings.account")}>
          <SettingsItem icon={<ProfileIcon />} label={t("settings.profile")}>
            <Link
              to={{
                pathname: Routes.Profile,
                state: { from: Routes.Settings },
              }}
              className="mobea__settings__link"
            >
              <UpIcon />
            </Link>
          </SettingsItem>

          {applicationVariant === ApplicationVariant.MOVEASY && (
            <SettingsItem icon={<LockIcon />} label={t("settings.password")}>
              <Link
                to={{
                  pathname: Routes.ChangePassword,
                  state: { from: Routes.Settings },
                }}
                className="mobea__settings__link"
              >
                <UpIcon />
              </Link>
            </SettingsItem>
          )}
          <SettingsItem icon={<GlobeIcon />} label={t("settings.language")}>
            <MobeaDropdown
              className="mobea__settings__language-picker"
              direction="down"
              options={Object.keys(getLanguages()).filter(
                (lang) => language != lang
              )}
              value={language}
              optionHeight={48}
              onChange={changeLanguage}
            />
          </SettingsItem>
        </SettingsSection>
        {applicationVariant === ApplicationVariant.MOVEASY && (
          <SettingsSection headline={t("settings.budget")}>
            <SettingsItem
              icon={<WalletAltIcon />}
              label={t("settings.my_wallets")}
            >
              <Link to={Routes.MyWallets} className="mobea__settings__link">
                <UpIcon />
              </Link>
            </SettingsItem>
            <SettingsItem
              icon={<RefundIcon />}
              label={t("settings.refund_billing")}
            >
              <Link to={Routes.RefundBilling} className="mobea__settings__link">
                <UpIcon />
              </Link>
            </SettingsItem>
          </SettingsSection>
        )}

        <SettingsSection headline={t("settings.help")}>
          <SettingsItem icon={<StudyIcon />} label={t("settings.tutorial")}>
            <Link to={Routes.Tutorials} className="mobea__settings__link">
              <UpIcon />
            </Link>
          </SettingsItem>

          {faqLink && (
            <SettingsItem icon={<FaqIcon />} label={t("settings.faqs")}>
              <a
                className="mobea__settings__link"
                href={faqLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <UpIcon />
              </a>
            </SettingsItem>
          )}

          <SettingsItem
            icon={<PhoneIcon outlined />}
            label={t("settings.contact_us")}
          >
            {/* TODO use button */}
            <a
              href=""
              className="mobea__settings__link"
              onClick={openContactUs}
            >
              <UpIcon />
            </a>
          </SettingsItem>
        </SettingsSection>

        <SettingsSection headline={t("settings.about")}>
          {privacyPolicyLink && (
            <SettingsItem
              icon={<InfoIcon />}
              label={t("welcome.privacy_policy")}
            >
              <a
                className="mobea__settings__link"
                href={privacyPolicyLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <UpIcon />
              </a>
            </SettingsItem>
          )}

          <SettingsItem
            icon={<InfoIcon />}
            label={t("welcome.terms_conditions")}
          >
            {termsLink ? (
              <a
                className="mobea__settings__link"
                href={termsLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <UpIcon />
              </a>
            ) : (
              <Link
                to={Routes.TermsAndConditions}
                className="mobea__settings__link"
              >
                <UpIcon />
              </Link>
            )}
          </SettingsItem>
          {applicationVariant === ApplicationVariant.EA && (
            <SettingsItem
              icon={<InfoIcon />}
              label={t("settings.cookie_policy")}
            >
              <Link
                to={{
                  pathname: Routes.Cookies,
                  state: { from: Routes.Settings },
                }}
                className="mobea__settings__link"
              >
                <UpIcon />
              </Link>
            </SettingsItem>
          )}
          {applicationVariant === ApplicationVariant.MOVEASY && (
            <SettingsItem
              icon={<PowerOffIcon />}
              label={t("settings.log_out")}
              className="log-out"
            >
              <button
                className="mobea__settings__link"
                onClick={setShowLogOutConfirmation}
              />
            </SettingsItem>
          )}
        </SettingsSection>
      </div>
      <BottomNavBar />

      {showLogOutConfirmation && (
        <MobeaModal
          title={t("log_out_confirm.title")}
          confirmText={t("log_out_confirm.confirm")}
          onCancel={resetShowLogOutConfirmation}
          onConfirm={handleLogOutConfirm}
          type="secondary"
        >
          {t("log_out_confirm.message")}
        </MobeaModal>
      )}
    </main>
  );
}
