import { useEffect, useRef } from "react";

export function useScrollRestorer(phase: "remember" | "restore") {
  const sr = useRef<number | undefined>();

  useEffect(() => {
    if (phase == "remember") {
      sr.current = window.scrollY;
    } else {
      let iRef: number | null = window.setInterval(() => {
        window.scrollTo({ top: sr.current, behavior: "smooth" });
      }, 50);

      let tRef: number | null = window.setTimeout(() => {
        if (iRef) {
          window.clearInterval(iRef);

          iRef = null;
        }

        tRef = null;
      }, 1000);

      return () => {
        if (iRef) {
          window.clearInterval(iRef);
        }

        if (tRef) {
          window.clearTimeout(tRef);
        }
      };
    }
  }, [phase]);
}
