import { DefaultIconProps } from "icons/props";
import { ReactElement } from "react";

export function FelyxLogo({
  className = "",
  width,
  height,
}: Pick<DefaultIconProps, "className" | "width" | "height">): ReactElement {
  return (
    <img
      className={className}
      width={width}
      height={height}
      src=" data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJYAAAA7CAMAAACuXYPgAAAArlBMVEUAAAAETCkETCkJUS0ARi4ETCkETCkETCkETCkHTyoETSoETCkETCkDTSkETSsAZjMETCkETCkETCkETSoETSsGTSsFTSoHTSsFTSsDUC0GUyYAVSsAgAAFTSoDSyoFTCsESysGTCoHSigIUy0ETCn///8+dVr4+vkeXz8LUS/q8O3l7OnK2dIVWDgITy3e5+Pb5eCzyb+sxLirw7iSsaF0nIhxmoZaiXJIfWM2cFQRKkW0AAAAJHRSTlMA/OUxC/Pq4NpDEPjRkjsF1cW5rYRwak00LBcGAtSZhH5bJiKvbruMAAACjElEQVRo3s3ZaV+jMBAG8KH2tvVcj9W9d8ZtXXXvVb//F1OIdALzSyZEKHleNQmEf2kIKUAKucQiM0gpaAVSSY5JD4aYogsxRRfiA+UxHirSvwsRV8bCKur/fOFLbsgkjZ8RN8lFv8k1vD5NBx+h80jWmvD+GunaZkl7Bs60eb3sY5kVIdF//CZ7x2q2wUJOzkL8JXrPemWZwX5Ld/Xea6p3W2XdGtYN8a+o35i6Z60MiwTLPksT/zHP2mf9LVkkWOGHap/1IynWxkWCNUmE9Uj002JBIixCpAasD6Zh0AkLmIVEyCxlIVarkyy7WZbRTqW4A0VOS9Z3es6a6EpjyZm/AxaUrKuchSULPCzZ0AELaizn0X3cDlhzw/pnsTIf63BTOBi0z6qNevpjWHKtJWrsKidLFi+57O+msn8x5O9Mq0Q4VDANYvmad+0W6eJJ643K4opRPOszmuzDufkwlizjWgu0xsJYFpeRG9RnEFpf77kUzQJ9JedsDZrJF3Es0FSwbMCSgUjWgruY++/ZEMGCWBb3NvLfsyGCBY1Z0rXrYs1FayALXsEK/OsCTVlD4EQOed0FDVjjU+B0M0FwdBZXBLKWrj3fYjXbZHHZeYXwHBEe11fc403O3Sze9MQ9nTA4ngVisDun0x0MmnsVl6i3V+1ngmVWGlPfvyQPa4gmF0XpuGz7GjKdur6mTL2aVz3qtrU9B1XVzNQe6ayLQJacAYJZkqCzYBnMsisXAPFn61hhuR96+TcdAgSy9LGVuVjwBcVjXn1m3AthHfmvRD2z8Wh8oj805o+TFJ74y/OfxIsIFyuDHsOM1E5WjZXGyyQLgpND3OQA+g6m+gI1RRWkqZKuKaSSYetn6gnWJ6Ho3FPEgQAAAABJRU5ErkJggg=="
    />
  );
}
