import "@here/maps-api-for-javascript/bin/mapsjs-ui.css";
import "@here/maps-api-for-javascript/bin/mapsjs.bundle";
import Color from "color";
import { useBooleanState, useReduxState } from "common/hooks";
import { BudgetState, useBudgetState } from "common/hooks/useBudgetState";
import { MobeaModal } from "common/modal";
import { BottomNavBar, BottomSlider, BottomSliderRef } from "common/navigation";
import { MapOfflineOverlay } from "common/network/MapOfflineOverlay";
import { MapViewPage } from "common/page/MapViewPage";
import { ProviderLogo } from "common/travelPasses";
import { TravelTypeIcon } from "common/travelPasses/components/TravelTypeIcon";
import { MyLocationIcon } from "icons/navigation";
import { RouteDirectionsIcon } from "icons/RouteDirectionsIcon";
import {
  BrusselsCoords,
  getDistanceInKm,
  LocationError,
  MapInitPayload,
  useCenteredMap,
  useLocationUpdates,
  useMapInitializer,
  useProviderDataMarkers,
  useReverseGeocode,
  useRouting,
} from "maps";
import { useRoutePlannerSource } from "maps/effects/useRouteSource";
import { useSearchLocationAndProviders } from "maps/effects/useSearchLocationAndProviders";
import { LocationAccessDeniedDialog } from "maps/LocationAccessDeniedDialog";
import { useMapData } from "pages/map/hooks/useMapData";
import { useRadiusCircle } from "pages/map/hooks/useRadiusCircle";
import { useTripRoute } from "pages/map/hooks/useTripRoute";
import { NearbyProviders } from "pages/map/NearbyProviders";
import { RoutePlanner } from "pages/map/routePlanner";
import { SearchLocationDetails } from "pages/map/SearchLocationDetails";
import {
  toProviderFilterName,
  toTravelPassTypes,
} from "pages/providers/filter/functions";
import { ProviderFilterType } from "pages/providers/filter/ProviderFilterType";
import { ProvidersFilter } from "pages/providers/filter/ProvidersFilter";
import { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  LocationCoords,
  LocationSearchPlace,
  LocationSearchPlaceWithId,
  LocationSearchSuggestion,
  MyLocationSearchPlace,
  ProviderData,
} from "services/mapService";
import {
  setMapMessagesDisplaysAction,
  setMapPreviousSearchLocationAction,
  setMapTutorialShown,
  setRoutePlannerVisibleAction,
  setSelectedRoutePlanTripAction,
} from "state/actions";
import { ApplicationVariant, Routes, TravelPassProvider } from "utils";
import {
  AppColors,
  getDerivedAppColorTransparent,
  getDerivedColor,
} from "utils/colors";
import { applicationVariant } from "utils/configure";
import { MapWarningDialogsEa } from "./dialogs/MapWarningDialogsEa";
import { useNearbyTransportData } from "./hooks/useNearbyTransportData";
import "./MapPage.scss";
import { MapTutorial } from "./MapTutorial";
import { ProviderDetails } from "./ProviderDetails";

const INITIAL_MAP_ZOOM = 14;

const INITIAL_LOCATION = BrusselsCoords;

const BOTTOM_SLIDER_VISIBLE_HEIGHT_SEARCH_LOCATION_DETAILS = 200;

const BOTTOM_SLIDER_VISIBLE_HEIGHT_ROUTE_PLANNER = 140;

const BOTTOM_SLIDER_VISIBLE_HEIGHT_TRIP_SELECTED = 155;

const BOTTOM_SLIDER_COLLAPSED_MAX = 30;

const MAP_TOP_BOTTOM_PADDING = 100;

const H = window.H;

export function MapPage(): ReactElement {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location: Location & {
    state?: {
      searchLocation?: LocationCoords | LocationSearchPlaceWithId | null;
      selectedProviderType?: TravelPassProvider | null;
      selectedProviderFilters?: ProviderFilterType[];
      from?: string;
    };
  } = useLocation();

  const [backToTutors] = useState(
    typeof location.state === "object" &&
      location.state?.from === Routes.Tutorials
  );

  const {
    language: locale,
    privateAmount,
    tester,
  } = useSelector((state) => state.user);

  const {
    providers: allProviders,
    providersLoading,
    providersToTypes: providerToTypeMapping,
  } = useSelector((state) => state.passes);
  const { mapTutorialShown } = useSelector((state) => state.tutorial);
  const keyboardOpened = useSelector((state) => state.appUi.keyboardOpened);

  // use proxy setter instead
  const [searchLocation, _setSearchLocation] = useState<LocationCoords | null>(
    //  null
    location.state?.searchLocation ?? null
  );

  const setSearchLocation = (searchLoc: LocationCoords | null) => {
    history.replace(location.href, {
      ...(location.state ?? {}),
      searchLocation: searchLoc,
    });
    _setSearchLocation(searchLoc);
  };

  const setProvider = (provider) => {
    if (provider) {
      nearbyProviderSelected(provider);
    } else {
      clearProviderType();
    }
  };

  const [previousSearchLocation, setPreviousSearchLocation] = useReduxState(
    (state) => state.map.previousSearchLocation,
    setMapPreviousSearchLocationAction
  );

  const routePlannerAvailable =
    applicationVariant === ApplicationVariant.MOVEASY || tester;

  const [mapDisplayed, setMapDisplayed] = useReduxState(
    (state) => state.map.mapDisplayed,
    setMapMessagesDisplaysAction
  );

  const [routePlannerVisible, setRoutePlannerVisible] = useReduxState(
    (state) => state.map.routePlannerVisible,
    setRoutePlannerVisibleAction
  );

  const [loadingBudget, businessBudgetState] = useBudgetState();

  const budgetState =
    applicationVariant === ApplicationVariant.MOVEASY && privateAmount > 0
      ? BudgetState.Active
      : businessBudgetState;

  const canSelectProvider =
    applicationVariant === ApplicationVariant.MOVEASY ||
    budgetState === BudgetState.Active ||
    budgetState === BudgetState.NotActiveYet;

  const [mapRef, mapClassRef, onInit, behaviorRef, platformRef] =
    useMapInitializer(
      locale,
      INITIAL_MAP_ZOOM,
      searchLocation || INITIAL_LOCATION
    );

  const radiusGroupRef = useRef<H.map.Group | null>(null);

  const clusteringProviderRef = useRef<H.clustering.Provider | null>(null);

  const [userLocation, setUserLocationGroup, locationError] =
    useLocationUpdates();

  const [
    locationErrorDialogVisible,
    showLocationErrorDialog,
    hideLocationErrorDialog,
  ] = useBooleanState();

  const [errorDialogVisible, showErrorDialog, hideErrorDialog] =
    useBooleanState();

  const [
    noProvidersDialogVisible,
    showNoProvidersDialog,
    hideNoProvidersDialog,
  ] = useBooleanState();

  // use proxy setter instead
  const [selectedProviderFilters, _setSelectedProviderFilters] = useState<
    ProviderFilterType[]
  >(location.state?.selectedProviderFilters ?? []);

  const setSelectedProviderFilters = (filterTypes: ProviderFilterType[]) => {
    history.replace(location.href, {
      ...(location.state ?? {}),
      selectedProviderFilters: filterTypes,
    });

    _setSelectedProviderFilters(filterTypes);
  };

  const [selectedProvider, setSelectedProvider] = useState<ProviderData | null>(
    null
  );

  // use proxy setter instead
  const [selectedProviderType, _setSelectedProviderType] =
    useState<TravelPassProvider | null>(
      location.state?.selectedProviderType ?? null
    );

  const setSelectedProviderType = (selected: TravelPassProvider | null) => {
    history.replace(location.href, {
      ...(location.state ?? {}),
      selectedProviderType: selected,
    });

    _setSelectedProviderType(selected);
  };

  const [selectedTrip, setSelectedTrip] = useReduxState(
    (state) => state.map.selectedRoutePlannerTrip,
    setSelectedRoutePlanTripAction
  );

  const [geoAddress, geoAddressLoading, geoAddressError] = useReverseGeocode(
    platformRef,
    selectedProvider?.id ? selectedProvider : null,
    budgetState === BudgetState.Active
  );

  const [userLocationAddress, userLocationAddressLoading] = useReverseGeocode(
    platformRef,
    userLocation
  );

  const [searchLocationAddress, searchLocationAddressLoading] =
    useReverseGeocode(platformRef, searchLocation);

  const providers = useSelector((state) => state.passes.providers);

  const prevSearchLocation = useRef<LocationCoords | null>(null);

  // remove selection if new (search) location is more than 1km away
  useEffect(() => {
    if (
      prevSearchLocation.current &&
      searchLocation &&
      getDistanceInKm(prevSearchLocation.current, searchLocation) > 1
    ) {
      setSelectedProvider(null);
    }

    prevSearchLocation.current = searchLocation;
  }, [searchLocation]);

  const [setRoutingMapGroup, routingDistance, routingDistanceLoading] =
    useRouting({
      platformRef,
      origin: searchLocation,
      destination: selectedProvider?.id ? selectedProvider : null,
      enabled: budgetState === BudgetState.Active && !routePlannerVisible,
      color: providers.find(
        (provider) => provider.provider === selectedProvider?.type
      )?.color,
    });

  const [setTripRouteMapGroup] = useTripRoute(selectedTrip);

  const selectedPassTypes = useMemo(
    () =>
      selectedProviderFilters.length
        ? toTravelPassTypes(selectedProviderFilters[0])
        : [],
    [selectedProviderFilters]
  );

  const [searchRadius, mapData, loadingMapData, mapDataError] = useMapData(
    searchLocation,
    selectedPassTypes,
    selectedProviderType
  );

  const [
    nearbyTransportData,
    loadingNearbyTransportData,
    nearbyTransportDataError,
  ] = useNearbyTransportData(selectedPassTypes.length ? null : searchLocation);

  const locationSearchPlace: LocationSearchSuggestion | null = (
    searchLocation as LocationSearchSuggestion
  )?.label
    ? (searchLocation as LocationSearchSuggestion)
    : null;

  const [
    setSearchLocationMapReferences,
    renderLocationSearchField,
    locationSearchError,
  ] = useSearchLocationAndProviders({
    initialText: location.state?.searchLocation?.["label"] || "",
    providers: nearbyTransportData,
    loadingProviders: loadingNearbyTransportData,
    userLocation,
    searchLocation,
    setSearchLocation,
    provider: selectedProviderType,
    setProvider,
    previousLocation: previousSearchLocation,
    setPreviousLocation: setPreviousSearchLocation,
    getLastLocation: () => locationSearchPlace,
    dragEnabled: !routePlannerVisible,
    pinVisible: !routePlannerVisible || !selectedProvider,
    allowMyLocation: true,
    myLocationAddress: userLocationAddress,
    locationError,
  });

  const markerData = useMemo(() => {
    if (!mapData || selectedTrip) {
      return [];
    }

    if (routePlannerVisible && selectedProvider) {
      return [selectedProvider];
    }

    return mapData;
  }, [routePlannerVisible, selectedProvider, mapData, selectedTrip]);

  const [setProviderMarkersMapReferences] = useProviderDataMarkers(
    markerData,
    selectedProvider,
    canSelectProvider ? setSelectedProvider : undefined
  );

  const routePlannerSearch = useSelector(
    (state) => state.map.routePlannerSearchResult
  );

  const [routePlannerSource, setRoutePlannerSource] =
    useState<LocationSearchPlace | null>(
      routePlannerSearch ? routePlannerSearch.source : null
    );

  const [setRoutePlannerGroupRef] = useRoutePlannerSource(
    routePlannerSource,
    userLocation
  );

  const bottomSliderRef = useRef<BottomSliderRef>(null);

  const bottomSliderVisible =
    routePlannerVisible ||
    !!selectedProvider ||
    (selectedProviderFilters.length === 0 &&
      !selectedProviderType &&
      searchLocation &&
      !nearbyTransportDataError);

  // customize map
  useEffect(() => {
    const customizeMap = ({ map }: MapInitPayload) => {
      const userLocationGroup = new H.map.Group({ zIndex: 1 });

      setUserLocationGroup(userLocationGroup);

      map.addObject(userLocationGroup);

      radiusGroupRef.current = new H.map.Group({ zIndex: 2 });

      map.addObject(radiusGroupRef.current!);

      const searchLocationGroup = new H.map.Group({ zIndex: 3 });

      map.addObject(searchLocationGroup);

      setSearchLocationMapReferences(
        map,
        searchLocationGroup,
        behaviorRef.current!
      );

      const routePlannerSourceDestinationGroup = new H.map.Group({
        zIndex: 4,
      });

      setRoutePlannerGroupRef(routePlannerSourceDestinationGroup);

      map.addObject(routePlannerSourceDestinationGroup);

      const routingGroup = new H.map.Group({ zIndex: 5 });

      setRoutingMapGroup(routingGroup);

      map.addObject(routingGroup);

      const tripRouteGroup = new H.map.Group({ zIndex: 6 });

      setTripRouteMapGroup(tripRouteGroup);

      map.addObject(tripRouteGroup);

      clusteringProviderRef.current = new H.clustering.Provider([], {
        min: 7,
        max: 23,
        clusteringOptions: {
          eps: 32,
          minWeight: 2,
        },
      });

      const clusteringLayer = new H.map.layer.ObjectLayer(
        clusteringProviderRef.current
      );

      map.addLayer(clusteringLayer);

      map
        .getViewPort()
        .setPadding(MAP_TOP_BOTTOM_PADDING, 0, MAP_TOP_BOTTOM_PADDING, 0);

      setProviderMarkersMapReferences(map, clusteringProviderRef.current);
    };

    onInit.then(customizeMap);

    return () => {
      radiusGroupRef.current?.dispose();

      clusteringProviderRef.current?.dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // initialize map tap behavior
  useEffect(() => {
    const map = mapClassRef.current;

    if (!map || routePlannerVisible) {
      return;
    }

    const mapClicked = (event) => {
      if (selectedProvider) {
        setSelectedProvider(null);
      } else {
        setSearchLocation(
          map.screenToGeo(
            event.currentPointer.viewportX,
            event.currentPointer.viewportY
          )
        );
      }
    };

    map.addEventListener("tap", mapClicked);

    return () => {
      map.removeEventListener("tap", mapClicked);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapClassRef.current, selectedProvider, routePlannerVisible]);

  useCenteredMap(
    searchLocation,
    mapClassRef,
    mapClassRef.current?.getZoom() || INITIAL_MAP_ZOOM
  );

  useRadiusCircle(mapClassRef, radiusGroupRef, searchLocation, searchRadius);

  // set search location based on user location
  useEffect(() => {
    if (searchLocation) {
      return;
    }

    if (userLocation) {
      setSearchLocation(userLocation);
    }
    // only on user location change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLocation]);

  // set radius circle for search location
  useEffect(() => {
    const map = mapClassRef.current;

    const radiusGroup = radiusGroupRef.current;

    if (!radiusGroup || !map) {
      return;
    }

    radiusGroup.removeAll();

    if (!searchLocation || selectedTrip) {
      return;
    }

    const radiusCircle = new H.map.Circle(searchLocation, searchRadius, {
      style: {
        lineWidth: 0,
        fillColor: getDerivedAppColorTransparent(AppColors.PRIMARY_EXTRA_LIGHT),
      },
    });

    radiusGroup.addObject(radiusCircle);

    if (
      !map
        .getViewModel()
        .getLookAtData()
        .bounds?.getBoundingBox()
        .containsRect(radiusCircle.getGeometry().getBoundingBox())
    ) {
      map.getViewModel().setLookAtData(
        {
          bounds: radiusCircle.getGeometry().getBoundingBox(),
        },
        true
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchLocation, searchRadius, selectedTrip, mapClassRef.current]);

  // show loading circle on data loading
  useEffect(() => {
    if (!loadingNearbyTransportData && !loadingMapData) {
      return;
    }

    const searchLocationGroup = radiusGroupRef.current;

    if (!searchLocationGroup || !searchLocation) {
      return;
    }

    const loadingCircle = new H.map.Circle(searchLocation, 0, {
      style: {
        lineWidth: 0,
        fillColor: getDerivedAppColorTransparent(AppColors.PRIMARY_EXTRA_LIGHT),
      },
      // @ts-ignore outdated types
      volatility: true,
    });

    searchLocationGroup.addObject(loadingCircle);

    const timeoutDelay = 750;

    let timeoutHandle = 0;

    const updateRadius = () => {
      const radiusStep = 20;

      const radius = loadingCircle.getRadius() + radiusStep;

      if (radius > searchRadius) {
        loadingCircle.setRadius(0);

        timeoutHandle = window.setTimeout(updateRadius, timeoutDelay);

        return;
      }

      loadingCircle.setRadius(radius);

      loadingCircle.setStyle({
        lineWidth: 0,
        fillColor: Color(getDerivedColor(AppColors.PRIMARY))
          .alpha((0.5 * (searchRadius - radius)) / searchRadius)
          .rgb()
          .string(),
      });

      timeoutHandle = window.setTimeout(updateRadius, 30);
    };

    timeoutHandle = window.setTimeout(updateRadius, timeoutDelay);

    return () => {
      window.clearTimeout(timeoutHandle);
      searchLocationGroup &&
        loadingCircle &&
        searchLocationGroup.removeObject(loadingCircle);
    };
  }, [
    loadingNearbyTransportData,
    loadingMapData,
    searchLocation,
    searchRadius,
  ]);

  // open bottom slider when provider is selected
  useEffect(() => {
    if (selectedProvider || routePlannerVisible) {
      bottomSliderRef.current?.open();
    }

    if (keyboardOpened) {
      bottomSliderRef.current?.close();
    }
  }, [selectedProvider, routePlannerVisible, keyboardOpened]);

  // clear selection when filter is changed
  useEffect(() => {
    setSelectedProvider(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProviderFilters]);

  // hide route planner when different provider is selected
  useEffect(() => {
    if (selectedProvider) {
      hideRoutePlanner();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProvider]);

  // show error dialog on error
  useEffect(() => {
    if (
      mapDataError ||
      nearbyTransportDataError ||
      locationSearchError ||
      geoAddressError
    ) {
      showErrorDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    showErrorDialog,
    mapDataError,
    nearbyTransportDataError,
    locationSearchError,
    geoAddressError,
  ]);

  useEffect(() => {
    if (
      !loadingMapData &&
      selectedProviderFilters.length &&
      mapData?.length === 0
    ) {
      showNoProvidersDialog();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingMapData, mapData]);

  // clear selected trip when route planner is hidden
  useEffect(() => {
    if (!routePlannerVisible) {
      setSelectedTrip(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routePlannerVisible]);

  const bottomSliderVisibleHeight = useMemo(() => {
    if (keyboardOpened) {
      return BOTTOM_SLIDER_COLLAPSED_MAX;
    } else if (selectedTrip) {
      return BOTTOM_SLIDER_VISIBLE_HEIGHT_TRIP_SELECTED;
    } else if (routePlannerVisible) {
      return BOTTOM_SLIDER_VISIBLE_HEIGHT_ROUTE_PLANNER;
    } else if (locationSearchPlace) {
      return BOTTOM_SLIDER_VISIBLE_HEIGHT_SEARCH_LOCATION_DETAILS;
    } else {
      return undefined;
    }
  }, [keyboardOpened, locationSearchPlace, routePlannerVisible, selectedTrip]);

  const useMyLocation = () => {
    if (userLocation) {
      mapClassRef.current?.setCenter(userLocation, true);

      mapClassRef.current?.setZoom(INITIAL_MAP_ZOOM, true);
    } else if (locationError === LocationError.PermissionDenied) {
      showLocationErrorDialog();
    }
  };

  const nearbyProviderSelected = (nearbyProvider: TravelPassProvider) => {
    setSelectedProviderType(nearbyProvider);
  };

  const getBottomSliderTitle = () => {
    if (routePlannerVisible) {
      return undefined;
    } else if (selectedProvider) {
      const provider = allProviders.find(
        (travelProvider) => travelProvider.provider == selectedProvider.type
      );

      return (
        <div className="provider-title">
          <div
            className="provider-title__icon"
            style={{ backgroundColor: provider?.color }}
          >
            <TravelTypeIcon
              type={providerToTypeMapping[selectedProvider.type][0]}
              height={16}
              width={16}
              fill="white"
            />
          </div>
          <div className="provider-title__name">
            {provider ? t(`providers_names.${provider.provider}`) : ""}
          </div>
          {provider && <ProviderLogo provider={provider.provider} />}
        </div>
      );
    } else if (locationSearchPlace) {
      return undefined;
    } else {
      return t("map.nearby_transportation");
    }
  };

  const clearProviderType = () => {
    setSelectedProviderType(null);
    setSelectedProvider(null);
  };

  const getRoutePlannerInitialSource = (): MyLocationSearchPlace | null => {
    if (userLocation) {
      return {
        ...userLocation,
        address: userLocationAddress?.label,
        loading: userLocationAddressLoading,
        label: t("map.my_location"),
      };
    } else {
      return null;
    }
  };

  const getRoutePlannerInitialDestination = (): LocationSearchPlace | null => {
    if (selectedProvider) {
      return {
        label: geoAddress?.label || t("shared.loading"),
        loading: geoAddressLoading || !geoAddress,
        ...selectedProvider,
      };
    } else if (locationSearchPlace) {
      return locationSearchPlace;
    } else if (searchLocation) {
      return {
        label: searchLocationAddress?.label || t("shared.loading"),
        loading: searchLocationAddressLoading || !searchLocationAddress,
        ...searchLocation,
      };
    } else {
      return null;
    }
  };

  const showRoutePlanner = () => setRoutePlannerVisible(true);

  const hideRoutePlanner = () => {
    setRoutePlannerVisible(false);

    setRoutePlannerSource(null);
  };

  const routeDestinationChanged = (destination: LocationSearchPlace | null) => {
    setSearchLocation(destination);

    setSelectedProvider(null);

    setSelectedProviderType(null);

    setSelectedProviderFilters([]);
  };

  const closeTutorial = () => {
    dispatch(setMapTutorialShown(true));

    if (backToTutors) {
      history.goBack();
    }
  };

  return (
    <MapViewPage
      pageClassName="mobea-map-view-page"
      className={!bottomSliderVisible ? "full" : ""}
      mapRef={mapRef}
      noHeader
      mapActionsOffset={bottomSliderVisible ? 120 : 40}
    >
      {!routePlannerVisible && (
        <div className="search-location-field-container">
          {renderLocationSearchField()}
        </div>
      )}

      {!selectedProviderType && !routePlannerVisible && (
        <ProvidersFilter
          selected={selectedProviderFilters}
          availabilityCheck="availableOnMap"
          onChange={setSelectedProviderFilters}
          hiddenOptions={[
            ProviderFilterType.All,
            ProviderFilterType.Kickscooters,
            ProviderFilterType.Scooters,
            ProviderFilterType.Services,
            ProviderFilterType.Walking,
          ]}
        />
      )}

      <div className="map-actions">
        {!locationSearchPlace &&
          !selectedProvider &&
          !routePlannerVisible &&
          routePlannerAvailable && (
            <div className="map-action" onClick={showRoutePlanner}>
              <RouteDirectionsIcon fill={AppColors.GRAY_400} outlined={false} />
            </div>
          )}
        <div className="map-action" onClick={useMyLocation}>
          <MyLocationIcon />
        </div>
      </div>

      <BottomSlider
        ref={bottomSliderRef}
        title={getBottomSliderTitle()}
        hidden={!bottomSliderVisible}
        defaultOpen={true}
        visibleHeight={bottomSliderVisibleHeight}
      >
        {routePlannerVisible && (
          <RoutePlanner
            initialSource={getRoutePlannerInitialSource()}
            initialDestination={getRoutePlannerInitialDestination()}
            onClose={hideRoutePlanner}
            onDestinationChange={routeDestinationChanged}
            onSourceChange={setRoutePlannerSource}
          />
        )}

        {!routePlannerVisible && !selectedProvider && locationSearchPlace && (
          <SearchLocationDetails
            locale={locale}
            location={locationSearchPlace}
            showRoute={showRoutePlanner}
          />
        )}

        {!routePlannerVisible && selectedProvider && (
          <ProviderDetails
            loading={providersLoading}
            provider={selectedProvider}
            address={geoAddress?.label || null}
            addressLoading={geoAddressLoading}
            distance={routingDistance}
            distanceLoading={routingDistanceLoading}
            budgetState={budgetState}
            showRoute={showRoutePlanner}
          />
        )}

        {!routePlannerVisible &&
          !selectedProvider &&
          selectedProviderFilters.length === 0 && (
            <NearbyProviders
              loading={providersLoading || loadingNearbyTransportData}
              title={
                getBottomSliderTitle()
                  ? undefined
                  : t("map.nearby_transportation")
              }
              providers={nearbyTransportData || []}
              providerSelected={nearbyProviderSelected}
            />
          )}
      </BottomSlider>

      <BottomNavBar />

      {errorDialogVisible && (
        <MobeaModal
          title={t("shared.oops")}
          onConfirm={hideErrorDialog}
          type="error"
          confirmText={t("shared.ok")}
        >
          <p>{t("map.error")}</p>
        </MobeaModal>
      )}

      {noProvidersDialogVisible && (
        <MobeaModal
          title={t("map.no_providers_title", {
            provider: t(
              toProviderFilterName(
                selectedProviderFilters[0] ?? ProviderFilterType.All
              ) || ""
            ).toLowerCase(),
          })}
          confirmText={t("shared.got_it")}
          onConfirm={hideNoProvidersDialog}
        >
          <p>{t("map.no_providers_text")}</p>
        </MobeaModal>
      )}

      {!loadingBudget &&
        budgetState === BudgetState.Active &&
        !mapTutorialShown && <MapTutorial close={closeTutorial} />}

      {!loadingBudget && (
        <MapWarningDialogsEa
          budgetState={budgetState}
          warningShown={mapDisplayed}
          closeWarning={(kind) => {
            setMapDisplayed({
              ...mapDisplayed,
              [kind]: true,
            });
          }}
        />
      )}

      {locationErrorDialogVisible && (
        <LocationAccessDeniedDialog
          hide={hideLocationErrorDialog}
          showClickPinMessage
        />
      )}

      <MapOfflineOverlay title={t("menu.map")} />
    </MapViewPage>
  );
}
