export const CURRENT_YEAR = new Date().getFullYear();

export function convertToDate(date?: string | number): Date | undefined {
  if (date) {
    return new Date(date);
  }
  return undefined;
}

export const numberOfYears = 100;

// well we cannot get birth tomorrow :)
export const startDate = Date.now() - numberOfYears * 365 * 24 * 3600 * 1000;
// handle just last 100 years - should be enough
export const endDate = Date.now();

const startYear = new Date(startDate).getFullYear();
const yearsList: number[] = [];

for (let i = 0; i <= numberOfYears; i++) {
  yearsList.push(startYear + i);
}

export const YEARS = yearsList.slice(0);
