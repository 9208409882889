import { Carousel } from "common/carousel/Carousel";
import { useBooleanState } from "common/hooks";
import { MobeaModal } from "common/modal";
import {
  DeLijnTravelPass,
  MobitTravelPass,
  NmbsTravelPass,
} from "common/travelPasses";
import { BlueBikeTravelPass } from "common/travelPasses/blueBike/BlueBikeTravelPass";
import { VeloTravelPass } from "common/travelPasses/velo/VeloTravelPass";
import { VertsTravelPass } from "common/travelPasses/verts/VertsTravelPass";
import { InfoIcon } from "icons/InfoIcon";
import { NavBackIcon } from "icons/NavBackIcon";
import { PhoneTicketIcon } from "icons/PhoneTicketIcon";
import { MobitTutorial } from "pages/mobit/detail/MobitTutorial";
import { VeloTutorial } from "pages/velo/detail/VeloTutorial";
import {
  CSSProperties,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Trans, useTranslation } from "react-i18next";
import ReactResizeDetector from "react-resize-detector";
import { animated, useSpring } from "react-spring";
import {
  BlueBikeTravelPass as BlueBikePassType,
  DeLijnTravelPass as DeLijnTravelPassType,
  MobitTravelPass as MobitTravelPassType,
  NmbsTravelPass as NmbsTravelPassType,
  TravelPass,
  VeloTravelPass as VeloTravelPassType,
  VertsTravelPass as VertsTravelPassType,
} from "state/actions";
import { combineParentTravelPasses, TravelPassProvider } from "utils";
import { AppColors } from "utils/colors";
import { TravelPassesImage } from "../TravelPassesImage";
import "./ActiveTicketsList.scss";
import { useScrollRestorer } from "./useScrollRestorer";

const PASS_OFFSET = 64;
const SELECTED_PASS_SPACER_OFFSET = 224;
const SELECTED_PASS_TOP = 80;
const PASS_FULL_HEIGHT = 1000;
const BELOW_PASS_MAX_HEIGHT = 90;

export interface ActiveTicketsListProps {
  activePasses: TravelPass[];
  selectedPassID: string | null;
  loadingPasses: boolean;
  onPassSelect: (pass: TravelPass | null) => void;
  locale: string;
  reloadPasses(): void;
  offset: number;
}

export function ActiveTicketsList({
  activePasses,
  selectedPassID,
  loadingPasses,
  locale,
  onPassSelect,
  reloadPasses,
  offset,
}: ActiveTicketsListProps): ReactElement {
  const { t } = useTranslation();

  const activeTravelPassPreviews = useMemo(
    () => combineParentTravelPasses(activePasses),
    [activePasses]
  );

  useScrollRestorer(selectedPassID ? "remember" : "restore");

  const selectedPass = useMemo(
    () => activeTravelPassPreviews.find((pass) => pass.id === selectedPassID),
    [selectedPassID, activeTravelPassPreviews]
  );

  const passesContainerRef = useRef<HTMLDivElement>(null);

  const [headlineOffset, setHeadlineOffset] = useState(0);

  const setHeadline = useCallback((headline: HTMLElement | null) => {
    if (headline) {
      setHeadlineOffset(headline.offsetTop + headline.clientHeight + 16);
    }
  }, []);

  const [passesHeight, setPassesHeight] = useState(0);

  const [passesContainerStyle, setPassesContainerStyle] = useSpring(() => ({
    top: 400,
  }));

  const [selectedPassHeight, setSelectedPassHeight] = useState(0);

  const [infoOpened, showInfo, hideInfo] = useBooleanState();

  useEffect(() => {
    const passesContainer = passesContainerRef.current;

    if (!passesContainer) {
      return;
    }

    if (selectedPass) {
      setPassesHeight(
        selectedPassHeight - SELECTED_PASS_SPACER_OFFSET + SELECTED_PASS_TOP
      );
    } else {
      setPassesHeight(
        PASS_OFFSET * (activeTravelPassPreviews.length - 1) +
          passesContainer.children.item(passesContainer.childElementCount - 1)!
            .clientHeight
      );
    }
  }, [selectedPass, selectedPassHeight, activeTravelPassPreviews]);

  useEffect(() => {
    setPassesContainerStyle({
      top: selectedPass ? 0 : offset + headlineOffset,
    });
  }, [selectedPass, setPassesContainerStyle, headlineOffset, offset]);

  const getInfoDialog = () => {
    if (!selectedPass) {
      return null;
    }

    switch (selectedPass.provider) {
      case TravelPassProvider.mobit: {
        const { duration = 24 } = selectedPass as MobitTravelPassType;
        return <MobitTutorial duration={duration} />;
      }
      case TravelPassProvider["velo-antwerpen"]:
        return <VeloTutorial />;
      case TravelPassProvider.verts:
        return null;
      default:
        return (
          <div
            className="mobea-active-tickets-list__passes__active-pass-header__info"
            onClick={showInfo}
          >
            <InfoIcon />
          </div>
        );
    }
  };

  const getInfoText = () => {
    if (!selectedPass) {
      return "";
    }

    switch (selectedPass.provider) {
      case TravelPassProvider.verts:
        return null;
      default:
        return t("ticked_detail.my_ticket_info_text");
    }
  };

  const getTitle = () => {
    switch (selectedPass?.provider) {
      case TravelPassProvider.nmbs: {
        const nmbsPass = selectedPass as NmbsTravelPassType;

        if (nmbsPass.returnTravelPass) {
          return (
            <Trans i18nKey="shared.my_tickets">
              <span className="mobea__arial">{{ tickets: 2 }}</span>
            </Trans>
          );
        } else {
          return t("ticked_detail.my_ticket");
        }
      }
      default:
        return t("ticked_detail.my_ticket");
    }
  };

  return (
    <div className="mobea-active-tickets-list">
      <header
        className={`mobea-active-tickets-list__passes__active-pass-header ${
          selectedPass ? "visible" : "hidden"
        }`}
      >
        <h1>
          <button
            className="mobea-active-tickets-list__passes__active-pass-header__back"
            onClick={() => onPassSelect(null)}
          >
            <NavBackIcon />
          </button>
          <span className="mobea-active-tickets-list__passes__active-pass-header__title">
            {getTitle()}
            {getInfoDialog()}
          </span>
        </h1>
      </header>

      <h2
        ref={setHeadline}
        css={{
          fontSize: "0.875rem",
          textTransform: "uppercase",
          fontWeight: 700,
          color: AppColors.GRAY_300,
          margin: "2rem 1.5rem 1rem",
          visibility: selectedPass ? "hidden" : "visible",
        }}
      >
        <span>{t("home.available_travel_passes")}</span>
        {activeTravelPassPreviews.length > 0 && (
          <span className="mobea-active-tickets-list__passes__headline__count">
            &nbsp;({activeTravelPassPreviews.length})
          </span>
        )}
      </h2>

      <div
        className={`mobea-active-tickets-list__passes__wrapper ${
          loadingPasses ? "mobea__loading" : ""
        }`}
      >
        {activeTravelPassPreviews.length === 0 && (
          <section className="mx-4 mobea-active-tickets-list__no-passes">
            <div className="mobea-active-tickets-list__no-passes__icon">
              <TravelPassesImage />
            </div>
            <p className="mobea-active-tickets-list__no-passes__text">
              {t("home.no_passes_text")}
            </p>
          </section>
        )}
        {activeTravelPassPreviews.length > 0 && (
          <animated.div
            ref={passesContainerRef}
            className="mobea-active-tickets-list__passes"
            style={passesContainerStyle}
          >
            {activeTravelPassPreviews.map((pass, index) => {
              const getMaxHeight = () => {
                if (selectedPass === pass) {
                  return PASS_FULL_HEIGHT;
                } else if (
                  !selectedPass &&
                  index < activeTravelPassPreviews.length - 1
                ) {
                  return BELOW_PASS_MAX_HEIGHT;
                } else if (!selectedPass) {
                  return PASS_FULL_HEIGHT;
                } else {
                  return 0;
                }
              };

              const getOverflow = () => {
                if (selectedPass === pass) {
                  return "visible";
                } else if (
                  !selectedPass &&
                  index < activeTravelPassPreviews.length - 1
                ) {
                  return "hidden";
                } else if (!selectedPass) {
                  return "visible";
                } else {
                  return "hidden";
                }
              };

              const style: CSSProperties = {
                top: selectedPass ? SELECTED_PASS_TOP : PASS_OFFSET * index,
                zIndex: index + 1,
                transform:
                  !selectedPass || selectedPass === pass
                    ? "translateY(0)"
                    : `translateY(${window.innerHeight}px)`,
                maxHeight: getMaxHeight(),
                overflow: getOverflow(),
                padding: selectedPass ? 0 : undefined,
                margin: selectedPass ? 0 : undefined,
                left: selectedPass ? 0 : undefined,
                right: selectedPass ? 0 : undefined,
              };

              const renderPass = () => {
                switch (pass.provider) {
                  case TravelPassProvider.delijn: {
                    const deLijnPass = pass as DeLijnTravelPassType;

                    return (
                      <DeLijnTravelPass
                        {...deLijnPass}
                        isPreview={selectedPass !== pass}
                        locale={locale}
                        onSelect={
                          selectedPass ? undefined : () => onPassSelect(pass)
                        }
                      />
                    );
                  }
                  case TravelPassProvider.nmbs: {
                    const nmbsPass = pass as NmbsTravelPassType;

                    if (nmbsPass.returnTravelPass && selectedPass === pass) {
                      return (
                        <Carousel
                          className="mobea-active-tickets-list__passes__pass__multi"
                          pages={[
                            <NmbsTravelPass
                              key={nmbsPass.id}
                              {...nmbsPass}
                              locale={locale}
                            />,
                            <NmbsTravelPass
                              key={nmbsPass.returnTravelPass.id}
                              {...nmbsPass.returnTravelPass}
                              locale={locale}
                            />,
                          ]}
                        />
                      );
                    } else {
                      return (
                        <NmbsTravelPass
                          {...nmbsPass}
                          isPreview={selectedPass !== pass}
                          locale={locale}
                          onSelect={
                            selectedPass ? undefined : () => onPassSelect(pass)
                          }
                        />
                      );
                    }
                  }

                  case TravelPassProvider.mobit: {
                    const mobitPass = pass as MobitTravelPassType;

                    return (
                      <MobitTravelPass
                        {...mobitPass}
                        locale={locale}
                        isPreview={selectedPass !== pass}
                        onSelect={
                          selectedPass ? undefined : () => onPassSelect(pass)
                        }
                      />
                    );
                  }

                  case TravelPassProvider.verts: {
                    const vertsPass = pass as VertsTravelPassType;

                    return (
                      <VertsTravelPass
                        {...vertsPass}
                        isPreview={selectedPass !== pass}
                        locale={locale}
                        onSelect={
                          selectedPass ? undefined : () => onPassSelect(pass)
                        }
                        refresh={reloadPasses}
                      />
                    );
                  }

                  case TravelPassProvider["velo-antwerpen"]: {
                    const veloPass = pass as VeloTravelPassType;

                    return (
                      <VeloTravelPass
                        {...veloPass}
                        isPreview={selectedPass !== pass}
                        locale={locale}
                        onSelect={
                          selectedPass ? undefined : () => onPassSelect(pass)
                        }
                        refresh={reloadPasses}
                      />
                    );
                  }

                  case TravelPassProvider["blue-bike"]: {
                    const blueBikePass = pass as BlueBikePassType;

                    return (
                      <BlueBikeTravelPass
                        {...blueBikePass}
                        isPreview={selectedPass !== pass}
                        locale={locale}
                        refresh={reloadPasses}
                        onSelect={
                          selectedPass ? undefined : () => onPassSelect(pass)
                        }
                      />
                    );
                  }

                  default:
                    console.warn("Unknown provider", pass);
                    return <div />;
                }
              };

              const passResized = (
                width: number | undefined,
                height: number | undefined
              ) => {
                if (
                  height !== undefined &&
                  (!selectedPass || selectedPass === pass)
                ) {
                  setSelectedPassHeight(height);
                }
              };
              return (
                <div
                  key={pass.id}
                  className="mobea-active-tickets-list__passes__pass"
                  style={style}
                >
                  <ReactResizeDetector
                    handleHeight
                    onResize={passResized}
                    refreshMode="debounce"
                    refreshRate={500}
                  >
                    {renderPass()}
                  </ReactResizeDetector>
                </div>
              );
            })}
          </animated.div>
        )}
        <div
          className="mobea-active-tickets-list__passes__spacer"
          style={{
            height: selectedPass ? passesHeight : passesHeight + 50,
          }}
        />
      </div>

      {infoOpened && (
        <MobeaModal
          title={t("ticked_detail.my_ticket")}
          confirmText={t("shared.got_it")}
          image={<PhoneTicketIcon fill={AppColors.GRAY_100} />}
          onConfirm={hideInfo}
        >
          <p>{getInfoText()}</p>
        </MobeaModal>
      )}
    </div>
  );
}
