import { ReactElement, ReactNode } from "react";
import "./PageBanner.scss";

type Props = {
  children?: ReactNode;
};

export function PageBanner({ children }: Props): ReactElement {
  return (
    <div className="mobea__banner">
      <p className="mobea__banner__text">{children}</p>
      <div className="mobea__banner__red-corner">&nbsp;</div>
      <div className="mobea__banner__blue-corner">&nbsp;</div>
    </div>
  );
}
