import { History } from "history";
import { ADMIN_API_BASE } from "utils";
import { callSecuredApiEndpoint } from "./apiCall";

function getFullConfigRequest(authorization: string) {
  return fetch(`${ADMIN_API_BASE}config`, {
    method: "POST",
    headers: new window.Headers({
      Authorization: authorization,
      "Content-Type": "application/json",
    }),
    body: "{}",
  }).then((response) => response.json());
}

export function getFullConfig(history: History) {
  return callSecuredApiEndpoint(getFullConfigRequest, history);
}
