import { DatePicker } from "common/datepicker/DatePicker";
import { DateRange, DateRangePicker } from "common/datepicker/DateRangePicker";
import { DisabledOverlay } from "common/DisabledOverlay";
import {
  CheckboxGroup,
  CheckboxGroupShape,
  DropDownGroup,
  InputGroup,
  InputGroupRef,
  MobeaButton,
} from "common/forms";
import {
  useBooleanState,
  usePendingOrder,
  useUpdatedCustomer,
} from "common/hooks";
import { useSelectedWallet } from "common/hooks/useSelectedWallet";
import { MobeaModal } from "common/modal";
import { TabNavItem, TabPaneNav } from "common/navigation";
import { NestedPageFull } from "common/page/NestedPageFull";
import { Steps } from "common/steps/Steps";
import { CircleCheckIcon } from "icons/CircleCheckIcon";
import { RouteIcon } from "icons/RouteIcon";
import { ToggleDirectionIcon } from "icons/ToggleDirectionIcon";
import { ProviderTermsAndConditions } from "pages/conditions/ProviderTermsAndConditions";
import { NmbsChooseTicket } from "pages/nmbs/order/NmbsChooseTicket";
import { NmbsOrderError } from "pages/nmbs/order/NmbsOrderError";
import { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { batch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  NmbsGetProductsPayload,
  NmbsOrderPayload,
  NmbsTicket,
} from "services/nmbsService";
import {
  JourneyType,
  NmbsTravelPass,
  PassengerType,
  TravelClass,
} from "state/actions";
import {
  ApiErrors,
  ApplicationVariant,
  capitalizeString,
  datesInYearsRange,
  formatDateToNmbsFormat,
  getSearchParams,
  pushLocation,
  Routes,
  TravelPassProvider,
  WalletType,
} from "utils";
import { applicationVariant } from "utils/configure";
import { NmbsTicketsError } from "./errors/NmbsTicketsError";
import { useNmbsGetTickets, useNmbsOrder, useNmbsStationsList } from "./hooks";
import "./NmbsOrderPage.scss";
import {
  AddPassengerDetails,
  PassengerDetails,
} from "./passenger/AddPassengerDetails";
import { StationPicker } from "./stationPicker/StationPicker";
import { NmbsStationPayload } from "./stationPicker/utils";

type StationPickerTarget = "origin" | "destination";
type StationPickerTargetWithNull = StationPickerTarget | null;

const AgeOptions = [
  {
    type: PassengerType.CHILD,
    label: "1 - 11",
    checkBirthDate: (dateOfBirth: Date | null) =>
      dateOfBirth ? datesInYearsRange(dateOfBirth, new Date(), 1, 11) : true,
  },
  {
    type: PassengerType.CHILD,
    label: "12 - 25",
    checkBirthDate: (dateOfBirth: Date | null) =>
      dateOfBirth ? datesInYearsRange(dateOfBirth, new Date(), 12, 25) : true,
  },
  {
    type: PassengerType.ADULT,
    label: "26 - 65",
    checkBirthDate: (dateOfBirth: Date | null) =>
      dateOfBirth ? datesInYearsRange(dateOfBirth, new Date(), 26, 65) : true,
  },
  {
    type: PassengerType.ADULT,
    label: "65+",
    checkBirthDate: () => true,
  },
];

const stationCodeRegExp = /^[0-9]+$/;

export function NmbsOrderPage(): ReactElement {
  const history = useHistory();

  const location = useLocation();

  const { t } = useTranslation();

  const queryParams = getSearchParams(location.search);

  const originStation: string = queryParams.get("origin");

  const destinationStation: string = queryParams.get("destination");

  const departure: number = parseInt(queryParams.get("departure"));

  const TripOptions: TabNavItem[] = useMemo(
    () => [
      {
        key: "one_way",
        label: t("order_nmbs.one_way"),
      },
      {
        key: "return",
        label: t("order_nmbs.return"),
      },
    ],
    [t]
  );

  useUpdatedCustomer();

  const {
    language: locale,
    endDate,
    dateOfBirth,
    privateAmount,
  } = useSelector((state) => state.user);

  const stations = useSelector((state) => state.ordering.nmbsStations);

  const [calcRequestPayload, setCalcRequestPayload] =
    useState<NmbsGetProductsPayload | null>(null);

  const [
    ticketsResult,
    getTicketsFailed,
    ticketsLoading,
    getTicketsFailureCode,
    resetTickets,
  ] = useNmbsGetTickets(calcRequestPayload);

  const [orderPending] = usePendingOrder(TravelPassProvider.nmbs);

  const [selectedTicket, setSelectedTicket] = useState<NmbsTicket | null>(null);

  const [
    closeConfirmDialogVisible,
    showCloseConfirmDialog,
    hideCloseConfirmDialog,
  ] = useBooleanState();

  const [loadingStations] = useNmbsStationsList();

  const [makeOrder, orderResult, ordering, orderFailureCode, resetOrder] =
    useNmbsOrder();

  const [allTickets, setAllTickets] = useState<NmbsTravelPass[]>([]);

  const [origin, setOrigin] = useState<NmbsStationPayload | undefined>(
    undefined
  );

  const [destination, setDestination] = useState<
    NmbsStationPayload | undefined
  >(undefined);

  const [stationPickerTarget, setStationPickerTarget] =
    useState<StationPickerTargetWithNull>(null);

  const [currentStep, setCurrentStep] = useState(0);

  const [activeTab, setActiveTab] = useState(0);

  const [firstTicket, setFirstTicket] = useState(true);

  const [departureDate, setDepartureDate] = useState<number | undefined>(
    departure || Date.now()
  );

  const [returnDate, setReturnDate] = useState<number | undefined>();

  const [age, setAge] = useState(
    AgeOptions.find((option) =>
      option.checkBirthDate(dateOfBirth ? new Date(dateOfBirth) : null)
    )!.label
  );

  const [travelClass, setTravelClass] = useState(TravelClass.SECOND);

  const [errorDialogOpened, showErrorDialog, hideErrorDialog] =
    useBooleanState();

  const originInputRef = useRef<InputGroupRef>(null);

  const destinationInputRef = useRef<InputGroupRef>(null);

  useEffect(() => {
    if (loadingStations || !originStation || origin || stations.length === 0) {
      return;
    }

    const decodedOriginStation = decodeURIComponent(originStation);

    const isCode = stationCodeRegExp.test(decodedOriginStation);
    const fullCode = parseInt(`10${decodedOriginStation}`);

    setOrigin(
      stations.find((station) => {
        if (isCode) {
          return station.id === fullCode;
        }
        return (
          station.label.toLowerCase() === decodedOriginStation.toLowerCase()
        );
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stations, loadingStations, originStation]);

  useEffect(() => {
    if (
      loadingStations ||
      !destinationStation ||
      destination ||
      stations.length === 0
    ) {
      return;
    }

    const decodedDestinationStation = decodeURIComponent(destinationStation);

    const isCode = stationCodeRegExp.test(decodedDestinationStation);
    const fullCode = parseInt(`10${decodedDestinationStation}`);

    setDestination(
      stations.find((station) => {
        if (isCode) {
          return station.id === fullCode;
        }
        return (
          station.label.toLowerCase() ===
          decodedDestinationStation.toLowerCase()
        );
      })
    );
  }, [stations, loadingStations, destinationStation, destination]);

  useEffect(() => {
    if (getTicketsFailed || orderFailureCode) {
      showErrorDialog();
    }
  }, [getTicketsFailed, orderFailureCode, showErrorDialog]);

  useEffect(() => {
    if (orderResult.length) {
      setAllTickets(allTickets.concat(orderResult));
    }
    // don't add all tickets or you create infinite loop!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderResult]);

  const clearTickets = () => {
    if (ticketsResult || getTicketsFailed) {
      resetTickets();
    }
  };

  const changeJourneyType = (journeyTypeIndex: number) => {
    // reset departure so it is consistent with return journey behaviour
    if (journeyTypeIndex === 0) {
      setReturnDate(undefined);
    } else {
      setReturnDate(departureDate);
    }

    clearTickets();

    setActiveTab(journeyTypeIndex);
  };

  const travelDateRange = useMemo(() => {
    const monthLater = new Date();
    monthLater.setMonth(monthLater.getMonth() + 1);

    // allow to purchase tickets up to one month ahead
    if (
      applicationVariant === ApplicationVariant.MOVEASY &&
      privateAmount > 0
    ) {
      return {
        startDate: Date.now(),
        endDate: monthLater.valueOf(),
      };
    }

    // if no private plan follow the validity of the business plan
    return {
      startDate: Date.now(),
      endDate: endDate || monthLater.valueOf(),
    };
  }, [endDate, privateAmount]);

  const openStationPicker = (target: StationPickerTarget) =>
    setStationPickerTarget(target);

  const closeStationPicker = () => setStationPickerTarget(null);

  const updateStation = (
    value: NmbsStationPayload,
    target: StationPickerTarget
  ) => {
    if (target === "origin") {
      setOrigin(value);

      originInputRef.current &&
        originInputRef.current.runValidation(value.label);
    } else {
      setDestination(value);

      destinationInputRef.current &&
        destinationInputRef.current.runValidation(value.label);
    }

    clearTickets();

    closeStationPicker();
  };

  const wallet = useSelectedWallet(WalletType.BUSINESS);

  const order = (passengerDetails: PassengerDetails) => {
    if (origin && destination && departureDate && selectedTicket) {
      const payload: NmbsOrderPayload = {
        departure: {
          station: origin.id,
          date: formatDateToNmbsFormat(departureDate),
        },
        destination: {
          station: destination.id,
          date: formatDateToNmbsFormat(departureDate),
        },
        journeyType: activeTab === 0 ? JourneyType.SINGLE : JourneyType.RETURN,
        price: selectedTicket.price,
        productId: selectedTicket.type,
        firstName: passengerDetails.name,
        lastName: passengerDetails.surname,
        dateOfBirth: passengerDetails.dateOfBirth,
        travelClass,
        travelType: PassengerType.ADULT,
        wallet,
      };

      if (returnDate && activeTab === 1) {
        payload.destination.date = formatDateToNmbsFormat(returnDate);
      }

      makeOrder(payload);
    } else {
      console.warn("Form content is not ready to order!");
    }
  };

  const reversOriginAndDestination = () => {
    batch(() => {
      setOrigin(destination);

      setDestination(origin);

      originInputRef.current &&
        originInputRef.current.runValidation(
          (destination && destination.label) || ""
        );

      destinationInputRef.current &&
        destinationInputRef.current.runValidation(
          (origin && origin.label) || ""
        );
    });

    clearTickets();
  };

  const setDateRange = ({ from, to }: DateRange) => {
    setDepartureDate(from.valueOf());

    setReturnDate(to.valueOf());

    clearTickets();
  };

  const showTickets = () => {
    setSelectedTicket(null);

    if (origin && destination && departureDate) {
      const payload: NmbsGetProductsPayload = {
        departure: {
          station: origin.id,
          date: formatDateToNmbsFormat(departureDate),
        },
        destination: {
          station: destination.id,
          date: formatDateToNmbsFormat(departureDate),
        },
        journeyType: activeTab === 0 ? JourneyType.SINGLE : JourneyType.RETURN,
        travelClass,
        travelType: PassengerType.ADULT, //AgeOptions.find(option => option.label == age)?.type TODO: uncomment when fixed on NMBS side
      };

      if (returnDate && activeTab === 1) {
        payload.destination.date = formatDateToNmbsFormat(returnDate);
      }

      setCalcRequestPayload(payload);

      setCurrentStep(1);
    } else {
      console.warn("Form content is not ready to calc price!");
    }
  };

  const showTicketDetail = (id: string) => {
    pushLocation(
      history,
      Routes.NmbsTicketDetail.replace(":id", encodeURIComponent(id))
    );
  };

  const originLabel = t("order_nmbs.from");

  const destinationLabel = t("order_nmbs.to");

  const departureDateLabel = t("order_nmbs.departure_date");

  const returnDateLabel = t("order_nmbs.return_date");

  const formContentReady =
    origin &&
    destination &&
    departureDate &&
    (activeTab === 1 ? returnDate : true);

  const showTicketsDisabled =
    loadingStations || ticketsLoading || getTicketsFailed || !formContentReady;

  const goStepBack = () => setCurrentStep(Math.max(0, currentStep - 1));

  const closeOrder = () => {
    history.goBack();
  };

  const chooseTicket = () => {
    setCurrentStep(2);
  };

  const buyAnotherTicket = () => {
    clearTickets();
    setSelectedTicket(null);
    setFirstTicket(false);
    resetOrder();
    setCurrentStep(0);
  };
  const retryFailedTicket = () => {
    hideErrorDialog();

    if (
      orderFailureCode === ApiErrors.NMBS_DESTINATION_TO_SOURCE_TICKET_ERROR
    ) {
      setOrigin(destination);

      setDestination(origin);
    }

    setActiveTab(0);

    buyAnotherTicket();
  };

  const showMultiTicketDetail = () => {
    pushLocation(
      history,
      `${Routes.NmbsMultiTicketDetail}?ids=${allTickets.map((pass) => pass.id)}`
    );
  };

  return (
    <>
      <NestedPageFull
        pageClassName="mobea-order-nmbs"
        title={
          <>
            {currentStep == 0 && (
              <span>{t("order_nmbs.order_nmbs_ticket")}</span>
            )}
            {currentStep == 1 && (
              <span>{t("order_nmbs.choose_your_ticket")}</span>
            )}
            {currentStep == 2 && (
              <span>{t("passenger.passenger_details")}</span>
            )}
          </>
        }
        padding={{ bottom: 24 }}
        onNavBack={currentStep > 0 ? goStepBack : undefined}
        onClose={showCloseConfirmDialog}
        headerHeight="minimal"
      >
        <DisabledOverlay active={ordering} />
        <Steps
          className="mobea-order-nmbs__steps"
          currentStep={currentStep}
          stepCount={3}
        />

        {currentStep == 0 && (
          <>
            <section className="mobea-order-nmbs__route">
              <RouteIcon className="mobea-order-nmbs__route__icon" />
              <div className="mobea-order-nmbs__route__form">
                <InputGroup
                  label={originLabel}
                  name="from"
                  placeholder={originLabel}
                  toggleLabelAndPlaceholder
                  value={(origin && origin.label) || ""}
                  ref={originInputRef}
                  className="mobea-order-nmbs__route__form__from"
                  inputAttributes={{
                    onClick: () => openStationPicker("origin"),
                    autoComplete: "off",
                    readOnly: true,
                  }}
                  validation={(value) => value.toString().length > 0}
                />
                <InputGroup
                  label={destinationLabel}
                  name="to"
                  placeholder={destinationLabel}
                  toggleLabelAndPlaceholder
                  value={(destination && destination.label) || ""}
                  className="mobea-order-nmbs__route__form__to"
                  ref={destinationInputRef}
                  inputAttributes={{
                    onClick: () => openStationPicker("destination"),
                    autoComplete: "off",
                    readOnly: true,
                  }}
                  validation={(value) => value.toString().length > 0}
                />
              </div>
              <button
                className="mobea-order-nmbs__route__reverse mobea__button"
                onClick={reversOriginAndDestination}
              >
                <ToggleDirectionIcon />
              </button>
              {stationPickerTarget && (
                <StationPicker
                  currentStation={
                    stationPickerTarget === "origin" ? origin : destination
                  }
                  label={
                    stationPickerTarget === "origin"
                      ? originLabel
                      : destinationLabel
                  }
                  stations={stations}
                  excluded={
                    stationPickerTarget === "origin" ? destination : origin
                  }
                  noResultsText={t("order_nmbs.no_results_text")}
                  startSearchingText={t("order_nmbs.start_searching_text")}
                  onSelect={(value) =>
                    updateStation(value, stationPickerTarget)
                  }
                  onClose={closeStationPicker}
                />
              )}
            </section>
            <section className="mobea-order-nmbs__ticket">
              <div className="mobea-order-nmbs__ticket__trip">
                <TabPaneNav
                  tabs={TripOptions}
                  active={activeTab}
                  onTabChange={changeJourneyType}
                />
                <div className="mobea-order-nmbs__ticket__trip__tabs_content">
                  {activeTab === 0 && (
                    <DatePicker
                      title={t("order_nmbs.trip_date")}
                      dateInputLabel={departureDateLabel}
                      className={`mobea-order-nmbs__ticket__trip__departure-date ${
                        departureDate ? "" : "mobea__empty"
                      }`}
                      locale={locale}
                      selectedDate={departureDate}
                      startDate={travelDateRange.startDate}
                      endDate={travelDateRange.endDate}
                      t={t}
                      onChange={setDepartureDate}
                    />
                  )}
                  {activeTab === 1 && (
                    <DateRangePicker
                      title={t("order_nmbs.trip_dates")}
                      dateFromLabel={departureDateLabel}
                      dateToLabel={returnDateLabel}
                      className={`mobea-order-nmbs__ticket__trip__date-range ${
                        returnDate ? "" : "mobea__empty"
                      }`}
                      locale={locale}
                      startDate={travelDateRange.startDate}
                      endDate={travelDateRange.endDate}
                      from={departureDate}
                      to={returnDate}
                      t={t}
                      onChange={setDateRange}
                    />
                  )}
                  <DropDownGroup
                    className="mobea-order-nmbs__ticket__trip__age-dropdown"
                    label={t("order_nmbs.age")}
                    options={AgeOptions.map((option) => option.label)}
                    value={age}
                    onChange={setAge}
                  />
                  <div className="mobea-order-nmbs__ticket__trip__travel-class">
                    <CheckboxGroup
                      label={t("order_nmbs.second_class")}
                      name="secondClass"
                      shape={CheckboxGroupShape.Circle}
                      isChecked={travelClass == TravelClass.SECOND}
                      onChange={() => setTravelClass(TravelClass.SECOND)}
                    />
                    <CheckboxGroup
                      label={t("order_nmbs.first_class")}
                      name="firstClass"
                      shape={CheckboxGroupShape.Circle}
                      isChecked={travelClass == TravelClass.FIRST}
                      onChange={() => setTravelClass(TravelClass.FIRST)}
                    />
                  </div>
                </div>
              </div>
              <MobeaButton onClick={showTickets} disabled={showTicketsDisabled}>
                {t(
                  ticketsLoading
                    ? "order_nmbs.loading_tickets"
                    : "order_nmbs.show_tickets"
                )}
              </MobeaButton>
            </section>
          </>
        )}

        {currentStep == 1 && (
          <NmbsChooseTicket
            locale={locale}
            tickets={ticketsResult}
            loading={ticketsLoading}
            selected={selectedTicket}
            onChange={setSelectedTicket}
            onConfirm={chooseTicket}
            goBack={goStepBack}
          />
        )}

        {currentStep == 2 && selectedTicket && (
          <AddPassengerDetails
            departure={origin?.label ?? ""}
            destination={destination?.label ?? ""}
            journeyType={
              activeTab === 0 ? JourneyType.SINGLE : JourneyType.RETURN
            }
            travelClass={travelClass}
            locale={locale}
            ticket={selectedTicket}
            onConfirm={order}
            ordering={ordering || orderPending}
            useCustomerData={firstTicket}
          />
        )}

        {errorDialogOpened && getTicketsFailed && (
          <NmbsTicketsError
            errorCode={getTicketsFailureCode}
            clearTickets={clearTickets}
          />
        )}

        {orderResult.length > 0 && allTickets.length === 1 && (
          <MobeaModal
            className="mobea-order-nmbs__ticket-success-modal"
            title={t("shared.ticket_is_bought")}
            image={<CircleCheckIcon />}
          >
            <p>
              <Trans i18nKey="order_nmbs.ticket_ready_text">
                {{
                  ticketName: t(
                    `order_nmbs.ticket.${orderResult[0].productId}.name`
                  ),
                }}
              </Trans>
            </p>
            <MobeaButton
              className="mobea-order-nmbs__view-ticket-button"
              onClick={() => showTicketDetail(allTickets[0].id)}
            >
              {t("order_nmbs.view_ticket")}
            </MobeaButton>
            <MobeaButton type="secondary" onClick={buyAnotherTicket}>
              {t("order_nmbs.buy_another_ticket")}
            </MobeaButton>
          </MobeaModal>
        )}

        {orderResult.length > 0 && allTickets.length > 1 && (
          <MobeaModal
            className="mobea-order-nmbs__ticket-success-modal"
            title={t("shared.tickets_bought")}
            image={<CircleCheckIcon />}
          >
            <p>
              {orderResult.length > 1 && (
                <Trans i18nKey="order_nmbs.two_tickets_ready_text">
                  {{
                    source: capitalizeString(origin?.label || ""),
                    destination: capitalizeString(destination?.label || ""),
                  }}
                </Trans>
              )}
              {orderResult.length === 1 && (
                <Trans i18nKey="order_nmbs.ticket_ready_text">
                  {{
                    ticketName: t(
                      `order_nmbs.ticket.${orderResult[0].productId}.name`
                    ),
                  }}
                </Trans>
              )}
            </p>
            <MobeaButton
              className="mobea-order-nmbs__view-ticket-button"
              onClick={showMultiTicketDetail}
            >
              {t("order_nmbs.view_tickets")}
            </MobeaButton>
            <MobeaButton type="secondary" onClick={buyAnotherTicket}>
              {t("order_nmbs.buy_another_ticket")}
            </MobeaButton>
          </MobeaModal>
        )}

        {errorDialogOpened && orderFailureCode && (
          <NmbsOrderError
            origin={capitalizeString(origin?.label || "")}
            destination={capitalizeString(destination?.label || "")}
            viewTicket={() => showTicketDetail(orderResult[0].id)}
            buyTicket={retryFailedTicket}
            closeOrder={closeOrder}
            errorCode={orderFailureCode}
            closeError={hideErrorDialog}
          />
        )}

        {closeConfirmDialogVisible && (
          <MobeaModal
            title={t("shared.leave_confirm")}
            confirmText={t("shared.no")}
            cancelText={t("shared.yes_leave")}
            onCancel={() => {
              hideCloseConfirmDialog();
              closeOrder();
            }}
            onConfirm={hideCloseConfirmDialog}
          >
            <p>{t("shared.leave_ticket_form")}</p>
          </MobeaModal>
        )}
      </NestedPageFull>

      <ProviderTermsAndConditions
        title={t("order_nmbs.order_nmbs_ticket")}
        provider={TravelPassProvider.nmbs}
        description={t("order_nmbs.terms_description")}
        termsExternalLink="https://www.belgiantrain.be/en/support/terms-and-conditions-for-transport"
      />
    </>
  );
}
