import { ProviderLogo, ProviderName } from "common/travelPasses";
import { ReactElement } from "react";
import { TravelPassProvider } from "utils";

export interface ExpenseProviderOptionProps {
  provider: TravelPassProvider;
}

export function ExpenseProviderOption({
  provider,
}: ExpenseProviderOptionProps): ReactElement {
  return (
    <div className="mobea-add-expense__form__providers__option">
      <ProviderName provider={provider} />
      <ProviderLogo provider={provider} />
    </div>
  );
}
