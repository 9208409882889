import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

export function VisibilityIcon({
  fill = "currentColor",
  className = "",
  height = "32",
  width = "32",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 9.333A6.67 6.67 0 0122.667 16a6.47 6.47 0 01-.48 2.44l3.893 3.893A15.756 15.756 0 0030.653 16c-2.306-5.853-8-10-14.666-10-1.867 0-3.654.333-5.307.933l2.88 2.88a6.47 6.47 0 012.44-.48zM2.667 5.693l3.04 3.04.613.614A15.739 15.739 0 001.333 16c2.307 5.853 8 10 14.667 10 2.067 0 4.04-.4 5.84-1.12l.56.56 3.907 3.893L28 27.64 4.36 4 2.667 5.693zm7.373 7.374l2.067 2.066c-.067.28-.107.574-.107.867 0 2.213 1.787 4 4 4 .293 0 .587-.04.867-.107l2.066 2.067c-.893.44-1.88.707-2.933.707A6.67 6.67 0 019.333 16c0-1.053.267-2.04.707-2.933zm5.747-1.04l4.2 4.2.026-.214c0-2.213-1.786-4-4-4l-.226.014z"
        fill={fill}
      />
    </svg>
  );
}
