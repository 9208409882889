import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RawDeLijnSingleTicket } from "services/delijnService";
import { RawNmbsTicket } from "services/nmbsService";
import { getTicketDetail } from "services/travelPassService";
import {
  BlueBikeTravelPass,
  DeLijnTravelPass,
  MobitTravelPass,
  NmbsTravelPass,
  updateActiveTravelPassAction,
  VeloTravelPass,
  VertsTravelPass,
} from "state/actions";
import { parseFloatWithComma, TravelPassProvider } from "utils";
import { normalizeMobitTravelPass } from "utils/normalizers";
import { normalizeBlueBikeTravelPass } from "utils/normalizers/blueBikeNormalizer";
import { normalizeDeLijnTravelPass } from "utils/normalizers/delijnNormalizer";
import { normalizeNmbsTravelPass } from "utils/normalizers/nmbsNormalizer";
import { normalizeVeloTravelPass } from "utils/normalizers/veloNormalizer";
import { normalizeVertsTravelPass } from "utils/normalizers/vertsNormalizer";

type TravelPassDetail =
  | DeLijnTravelPass
  | NmbsTravelPass
  | MobitTravelPass
  | VertsTravelPass
  | VeloTravelPass
  | BlueBikeTravelPass;

export function useTicketDetail<T>(detailId: string | null) {
  const dispatch = useDispatch();

  const history = useHistory();

  const { providers } = useSelector((state) => state.passes);

  const [result, setResult] = useState<TravelPassDetail | null>(null);

  const [failed, setFailed] = useState(false);

  const [loading, setLoading] = useState(false);

  const [failureCode, setFailureCode] = useState("-1");

  const [reloadTimestamp, setReloadTimestamp] = useState(0);

  useEffect(() => {
    let canceled = false;

    let loading = false;

    async function fetchTicketDetail(id: string) {
      try {
        setLoading(true);

        loading = true;

        const responseJson = await getTicketDetail(id, history);

        if (!responseJson.error) {
          const provider: TravelPassProvider =
            TravelPassProvider[responseJson.provider.slug];

          let travelPass: TravelPassDetail;

          if (provider === TravelPassProvider.delijn) {
            travelPass = normalizeDeLijnTravelPass(
              responseJson.response as RawDeLijnSingleTicket,
              {
                id: responseJson.id,
                plan: responseJson.plan,
              }
            );
          } else if (provider === TravelPassProvider.nmbs) {
            travelPass = normalizeNmbsTravelPass(
              responseJson.response as RawNmbsTicket,
              {
                id: responseJson.id,
                orderTimestamp: new Date(responseJson.created).valueOf(),
                price: parseFloatWithComma(responseJson.amount) || 0,
                travelEndDate: responseJson.expire_at,
                plan: responseJson.plan,
              }
            );
          } else if (provider === TravelPassProvider.mobit) {
            travelPass = normalizeMobitTravelPass(responseJson);
          } else if (provider === TravelPassProvider.verts) {
            travelPass = normalizeVertsTravelPass(responseJson, providers);
          } else if (provider === TravelPassProvider["velo-antwerpen"]) {
            travelPass = normalizeVeloTravelPass(responseJson);
          } else if (provider === TravelPassProvider["blue-bike"]) {
            travelPass = normalizeBlueBikeTravelPass(responseJson);
          } else {
            // @TODO add more providers!
            travelPass = {} as any;

            console.error(
              "Missing normalization impl for provider!",
              responseJson,
              responseJson.provider.slug
            );
          }

          // @TODO - extend cached ticket data in local cache
          // decide if it is useful for history

          if (!canceled) {
            setResult(travelPass);

            // do not try to update in case of missing impl
            if (travelPass.id) {
              dispatch(updateActiveTravelPassAction(travelPass));
            }
          }
        } else {
          if (!canceled) {
            setFailed(true);
            setFailureCode(responseJson.error_code);
          }

          console.error(
            "Failed to fetch ticket detail",
            responseJson.error_description
          );
        }
      } catch (error) {
        if (!canceled) {
          setFailed(true);
        }

        console.error("Failed to fetch ticket detail", error);
      } finally {
        if (!canceled) {
          loading = false;

          setLoading(false);
        }
      }
    }

    if (detailId !== null) {
      fetchTicketDetail(detailId);
    }

    return () => {
      canceled = true;

      if (loading) {
        console.debug("Canceled hook useTicketDetail");
      }
    };
  }, [detailId, reloadTimestamp, history, dispatch, providers]);

  const reload = () => setReloadTimestamp(Date.now());

  return [result, failed, loading, failureCode, reload] as [
    T | null,
    boolean,
    boolean,
    string,
    () => void
  ];
}
