import { SpendingTrackingData, trackTicketPurchase } from "analytics/events";
import { useLoading } from "common/hooks/useLoading";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  acknowledgeDeLijnTicketDelivery,
  DeLijnTicketOrderPayload,
  orderDeLijnSingleTicket,
} from "services/delijnService";
import {
  addActiveTravelPassAction,
  addPurchaseAction,
  DeLijnTravelPass,
  TravelPass,
} from "state/actions";
import { TravelPassProvider } from "utils";
import { normalizeDeLijnTravelPass } from "utils/normalizers/delijnNormalizer";

export function useDeLijnOrder() {
  const dispatch = useDispatch();

  const history = useHistory();

  const [result, setResult] = useState<DeLijnTravelPass | null>(null);

  const [failed, setFailed] = useState(false);

  const [loading, setLoading, canceledRef] = useLoading(
    "orderDeLijnSingleTicket"
  );

  const [failureCode, setFailureCode] = useState("-1");

  const order = useCallback(
    (id: number | null, orderPayload: DeLijnTicketOrderPayload) => {
      async function orderDeLijnTicket() {
        const trackingPayload: SpendingTrackingData = {
          action: TravelPassProvider.delijn,
          label: "success" as "success" | "failure",
          value: (orderPayload.tariff / 100).toString(),
        };

        try {
          setLoading(true);

          const orderJson = await orderDeLijnSingleTicket(
            orderPayload,
            history
          );

          if (!orderJson.error) {
            // @TODO handle type and provider other way than hardcoding - take from API?
            const normalizedTicket: DeLijnTravelPass =
              normalizeDeLijnTravelPass(orderJson, {
                id: orderJson.transaction_id,
                plan: orderPayload.wallet,
              });

            const ticketOverview: TravelPass = {
              id: normalizedTicket.id,
              price: normalizedTicket.price,
              expiration: normalizedTicket.expiration,
              orderTimestamp: normalizedTicket.orderTimestamp,
              provider: normalizedTicket.provider,
              types: normalizedTicket.types,
              plan: normalizedTicket.plan,
            };

            trackTicketPurchase(trackingPayload);

            // dispatch even if navigated out
            dispatch(addActiveTravelPassAction(normalizedTicket));

            // handle case when app goes offline after order so history is consistent
            dispatch(addPurchaseAction(ticketOverview));

            acknowledgeDeLijnTicketDelivery(
              orderJson.msgId,
              orderJson.txnId,
              history
            );

            if (!canceledRef.current) {
              setResult(normalizedTicket);
            }
          } else {
            trackingPayload.label = "failure";

            trackTicketPurchase(trackingPayload);

            if (!canceledRef.current) {
              setFailed(true);

              setFailureCode(orderJson.error_code);
            }

            console.error(
              "Failed to fetch De Lijn order data",
              orderJson.error_description
            );
          }
        } catch (error) {
          trackingPayload.label = "failure";

          trackTicketPurchase(trackingPayload);

          if (!canceledRef.current) {
            setFailed(true);
          }
          console.error("Failed to fetch De Lijn order data", error);
        } finally {
          setLoading(false);
        }
      }

      if (id !== null) {
        orderDeLijnTicket();
      }
    },
    [canceledRef, dispatch, history, setLoading]
  );

  return [order, result, failed, loading, failureCode] as const;
}
