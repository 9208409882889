import { ReactElement } from "react";
import { AppColors } from "utils/colors";
import { DefaultIconProps } from "./props";

export function TorchIcon({
  fill = AppColors.GRAY_400,
  className = "",
  height = "32",
  width = "32",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 18.7l4 4v6.6h8v-6.6l4-4V12H8v6.7zm6.7-16h2.6v4h-2.6v-4zm-10 5.1L6.5 6l2.9 2.8-2 1.9-2.7-2.9zm18 1L25.3 6l2 1.8-2.9 2.9-1.9-2z"
        fill={fill}
        fillRule="nonzero"
      />
    </svg>
  );
}
