import {
  BikeIcon,
  BusIcon,
  CarIcon,
  KickscooterIcon,
  TaxiIcon,
  TrainIcon,
  TramIcon,
} from "icons/transportTypes";
import { ReactElement } from "react";
import { TravelPassType } from "utils/constants";

type Props = {
  type: TravelPassType;
  width?: number;
  height?: number;
  fill?: string;
};

export function TravelTypeIcon({
  type,
  width,
  height,
  fill,
}: Props): ReactElement | null {
  switch (type) {
    case TravelPassType.Bus:
      return (
        <BusIcon
          width={width?.toString()}
          height={height?.toString()}
          fill={fill}
        />
      );
    case TravelPassType.Car:
      return (
        <CarIcon
          width={width?.toString()}
          height={height?.toString()}
          fill={fill}
        />
      );
    case TravelPassType.Uber:
    case TravelPassType.Taxi:
      return (
        <TaxiIcon
          width={width?.toString()}
          height={height?.toString()}
          fill={fill}
        />
      );
    case TravelPassType.Train:
      return (
        <TrainIcon
          width={width?.toString()}
          height={height?.toString()}
          fill={fill}
        />
      );
    case TravelPassType.Tram:
    case TravelPassType.Metro:
      return (
        <TramIcon
          width={width?.toString()}
          height={height?.toString()}
          fill={fill}
        />
      );
    case TravelPassType.Bike:
    case TravelPassType.ElectricBike:
      return (
        <BikeIcon
          width={width?.toString()}
          height={height?.toString()}
          fill={fill}
        />
      );
    case TravelPassType.Kickscooter:
      return (
        <KickscooterIcon
          width={width?.toString()}
          height={height?.toString()}
          fill={fill}
        />
      );
    default:
      return null;
  }
}
