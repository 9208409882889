import { MouseEvent, ReactNode } from "react";
import { AppColors } from "utils/colors";

export interface ActionButtonProps {
  type?: "primary" | "secondary";
  shape?: "circle" | "ellipse";
  className?: string;
  disabled?: boolean;
  hidden?: boolean;
  onClick?: () => void;
  children: ReactNode;
}

/**
 * Abstract implementation with generic styling
 * Should never be imported directly
 */
export function ActionButton({
  children,
  type = "primary",
  shape = "circle",
  className = "",
  disabled = false,
  hidden = false,
  onClick,
}: ActionButtonProps) {
  const clickHandler = (e: MouseEvent) => {
    e.preventDefault();

    e.stopPropagation();

    onClick?.();
  };

  return (
    <button
      className={`mobea__button mobea__action-button mobea__button__${type} ${className}`}
      disabled={disabled}
      onClick={onClick && clickHandler}
      css={[
        {
          width: shape === "circle" ? "3.5rem" : "auto",
          height: shape === "circle" ? "3.5rem" : "auto", // 3rem for single line
          border: 0,
          padding: "1rem",
          borderRadius: "100vw",
          textDecoration: "none",
          fontSize: "1rem",
          lineHeight: 1,
          transition: "opacity 200ms",
          opacity: hidden ? 0 : 1,
          pointerEvents: hidden ? "none" : "auto",

          "&::first-letter": {
            textTransform: "uppercase",
          },
          "&:disabled": {
            color: AppColors.GRAY_100,
          },
          // this is a hack for iOS where the text is otherwise shifted down
          // https://stackoverflow.com/a/47818418/289827
          "@supports (-webkit-touch-callout: none)": {
            position: "relative",
            top: -2,
          },
        },
        type === "primary"
          ? {
              color: AppColors.TEXT_INVERTED,
              backgroundColor: AppColors.PRIMARY,
              "&:disabled": {
                backgroundColor: AppColors.GRAY_100,
              },
            }
          : null,
        type === "secondary"
          ? {
              color: AppColors.GRAY_300,
              boxShadow: "0 2px 4px 0 rgba(0,0,0,0.16)",
              backgroundColor: "white",
            }
          : null,
      ]}
    >
      {children}
    </button>
  );
}
