import { NestedPage } from "common/page/NestedPage";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Routes } from "utils";
import "./SetPasswordLinkSendPage.scss";

export function SetPasswordLinkSendPage() {
  const { t } = useTranslation();

  const history = useHistory();

  const { state } = history.location;

  const phoneNumber = typeof state === "object" && state?.["phoneNumber"];

  return (
    <NestedPage
      pageClassName="mobea-forgotten-password"
      title={t("set_password.link_sent_title")}
      headerInSecondaryColor
    >
      <div className="mobea-forgotten-password__sent">
        <h2 className="mobea-forgotten-password__headline">
          {t("set_password.link_sent")}
        </h2>

        <p className="mobea-forgotten-password__text">
          <Trans i18nKey="set_password.link_sent_text">
            We’ve sent you a SMS with the recovery link to this phone number
            <br />
            <span className="mobea__arial phone-number">{{ phoneNumber }}</span>
            .
          </Trans>
        </p>

        <p className="mobea-forgotten-password__text last">
          {t("forgotten_password.did_not_get_it")}
        </p>

        <Link className="send_again_link" to={Routes.Login}>
          {t("forgotten_password.send_again")}
        </Link>
      </div>
    </NestedPage>
  );
}
