import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { getConfig } from "utils/configure";

export interface CookiesDetailSectionProps {
  kind: string;
  withTitle?: boolean;
}

export function CookiesDetailSection({
  kind,
  withTitle = true,
}: CookiesDetailSectionProps): ReactElement {
  const { t } = useTranslation();

  const { clientName } = getConfig();

  return (
    <div className="mobea__cookies__details__section">
      {withTitle && (
        <h2 className="mobea__cookies__details__section__title">
          {t(`cookies.${kind}_title`)}
        </h2>
      )}
      <p className="mobea__cookies__details__section__content">
        {t(`cookies.${kind}_text`, { clientName })}
      </p>
    </div>
  );
}
