import { ReactElement } from "react";
import { DefaultIconProps } from "../props";

export function KickscooterIcon({
  fill = "currentColor",
  className = "",
  height = "20",
  width = "20",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={fill}>
        <path d="M7.8 16H15v-1a4 4 0 014-4h.7l-1.9-8.4a2 2 0 00-2-1.6H12v2h3.9l1.4 6.3A6 6 0 0013 14H7.8a3 3 0 100 2zM5 16a1 1 0 01-1-1c0-.6.5-1 1-1s1 .4 1 1-.5 1-1 1z" />
        <path d="M19 12a3 3 0 100 6 3 3 0 000-6zm0 4a1 1 0 01-1-1c0-.6.4-1 1-1s1 .4 1 1-.4 1-1 1zM11 20H7l6 3v-2h4l-6-3z" />
      </g>
    </svg>
  );
}
