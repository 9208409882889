import { History } from "history";
import { getDistanceInKm } from "maps/mapUtils";
import { decodeWaypoints } from "pages/map/functions";
import { ProviderFilterType } from "pages/providers/filter/ProviderFilterType";
import {
  API_ERROR_RESPONSE_CODES,
  callSecuredApiEndpoint,
} from "services/apiCall";
import {
  addTimezoneToServerTime,
  ApiErrorResponse,
  CORE_API_BASE,
  LOCATION_SUGGESTIONS_LIMIT,
  ROUTE_PLANNER_TRIP_LIMIT,
  TravelPassProvider,
  TravelPassType,
} from "utils";
import { fetchTimeoutIncident } from "./fetchTimeout";

const toDataResponseObject = (json) => ({
  error: !!json.error_code || !!json.errorCode,
  error_code: json.error_code || json.errorCode?.toString(),
  data: json.error_code || json.errorCode ? null : json,
});

const toDataResponseObjectWithAuth = (json) =>
  API_ERROR_RESPONSE_CODES.includes(json.code)
    ? json
    : toDataResponseObject(json);

export interface LocationCoords {
  lat: number;
  lng: number;
}

export interface NullableLocationCoords {
  lat: number | null;
  lng: number | null;
}

interface RawProviderData {
  id: string;
  type: TravelPassProvider;
  name: string;
  lat: number;
  lon: number;
  last_updated: string; // Date
}

interface RawNearbyProvider {
  slug: string;
  types: string[];
}

export interface ProviderData {
  id: string | null;
  type: TravelPassProvider;
  name: string;
  lat: number;
  lng: number;
  data?: any;
  destination?: string;
  updated?: Date;
}

const toProviderData = (rawData: RawProviderData) => ({
  ...rawData,
  lng: rawData.lon,
  updated: addTimezoneToServerTime(rawData.last_updated),
});

export type NearbyTransportDataResponse =
  | {
      error: false;
      data: RawNearbyProvider[];
    }
  | ApiErrorResponse;

export type NearbyTransportData = TravelPassProvider[];

export function toNearbyTransportData(
  response: NearbyTransportDataResponse
): NearbyTransportData {
  return response.error
    ? []
    : response.data.map((item) => item.slug as TravelPassProvider);
}

const getNearbyTransportDataRequest =
  (location: LocationCoords) => (authorization: string) =>
    fetchTimeoutIncident<NearbyTransportDataResponse>(
      `${CORE_API_BASE}maps/nearby?lat=${location.lat.toFixed(
        5
      )}&lon=${location.lng.toFixed(5)}`,
      {
        method: "GET",
        headers: new window.Headers({
          Authorization: authorization,
        }),
      },
      toDataResponseObjectWithAuth
    );

export function getNearbyTransportData(
  location: LocationCoords,
  history: History
) {
  return callSecuredApiEndpoint(
    getNearbyTransportDataRequest(location),
    history
  );
}

export type MapDataResponse =
  | {
      error: false;
      data: RawProviderData[];
    }
  | ApiErrorResponse;

export type MapData = (ProviderData & {
  updated?: Date;
})[];

export function toMapData(response: MapDataResponse): MapData {
  return response.error ? [] : response.data.map(toProviderData);
}

function getMapDataRequest(
  location: LocationCoords,
  types: TravelPassType[],
  provider: TravelPassProvider | null,
  radius: number
) {
  return (authorization: string) => {
    const filter = provider
      ? `provider=${provider}`
      : `filter=${types.join(",")}`;

    return fetchTimeoutIncident<MapDataResponse>(
      `${CORE_API_BASE}maps/static?lat=${location.lat.toFixed(
        5
      )}&lon=${location.lng.toFixed(5)}&radius=${radius}&${filter}`,
      {
        method: "GET",
        headers: new window.Headers({
          Authorization: authorization,
        }),
      },
      toDataResponseObjectWithAuth
    );
  };
}

export function getMapData(
  location: LocationCoords,
  types: TravelPassType[],
  provider: TravelPassProvider | null,
  radius: number,
  history: History
) {
  return callSecuredApiEndpoint(
    getMapDataRequest(location, types, provider, radius),
    history
  );
}

export type LocationAddress = {
  city: string;
  countryCode: string;
  countryName: string;
  county: string;
  label: string;
  postalCode: string;
  state: string;
  stateCode: string;
  street: string;
  houseNumber?: string;
};

export type LocationSearchSuggestionsResponse =
  | {
      error: false;
      data: {
        title: string;
        id: string;
        address: {
          label: string;
        };
        position: LocationCoords;
        distance: number;
        resultType: "street" | "locality" | "place";
        highlights: {
          address: {
            label: { start: number; end: number }[];
          };
          title: { start: number; end: number }[];
        };
      }[];
    }
  | ApiErrorResponse;

export interface LocationSearchSuggestion extends LocationSearchPlace {
  locationId: string;
  distance: number | null;
  highlights: {
    start: number;
    end: number;
  }[];
}

export function toLocationSearchSuggestions(
  response: LocationSearchSuggestionsResponse,
  useDistance: boolean,
  excludeLocatityType: boolean
): LocationSearchSuggestion[] {
  return response.error
    ? []
    : response.data
        .filter((item) =>
          excludeLocatityType
            ? !["locality", "administrativeArea"].includes(item.resultType)
            : true
        )
        .filter((_, index) =>
          excludeLocatityType ? index < LOCATION_SUGGESTIONS_LIMIT : true
        )
        .map((item) => {
          const matchesTitle = item.highlights.title.length > 0;
          const label = matchesTitle ? item.title : item.address.label;
          const highlight = matchesTitle
            ? item.highlights.title
            : item.highlights.address.label;

          return {
            id: item.id,
            label,
            locationId: item.id,
            distance: useDistance ? item.distance : null,
            highlights: highlight,
            ...item.position,
          };
        });
}

function getLocationSearchSuggestionsRequest(
  location: LocationCoords,
  query: string,
  excludeLocalityType: boolean
) {
  return (authorization: string) =>
    fetch(
      `${CORE_API_BASE}maps/autosuggest?q=${query}&lat=${location.lat.toFixed(
        5
      )}&lon=${location.lng.toFixed(5)}&limit=${
        excludeLocalityType
          ? LOCATION_SUGGESTIONS_LIMIT + 2 // helps to reach desired number after filtering
          : LOCATION_SUGGESTIONS_LIMIT
      }`,
      {
        method: "GET",
        headers: new window.Headers({
          Authorization: authorization,
        }),
      }
    ).then((response) => response.json());
}

export function getLocationSearchSuggestions(
  location: LocationCoords,
  query: string,
  excludeLocalityType,
  history: History
) {
  return callSecuredApiEndpoint(
    getLocationSearchSuggestionsRequest(location, query, excludeLocalityType),
    history
  ).then(toDataResponseObject) as Promise<LocationSearchSuggestionsResponse>;
}

export type LocationSearchResultType =
  | "locality"
  | "street"
  | "place"
  | "administrativeArea"
  | "intersection"
  | "addressBlock"
  | "houseNumber"
  | "postalCodePoint";

export type LocationSearchPlaceDataResponse = {
  title: string;
  id: string;
  position: {
    lat: number;
    lng: number;
  };
  address: LocationAddress;
  resultType: LocationSearchResultType;
};

export type LocationSearchPlaceResponse =
  | {
      error: false;
      data: LocationSearchPlaceDataResponse;
    }
  | ApiErrorResponse;

export interface LocationSearchPlace extends LocationCoords {
  label: string;
  loading?: boolean;
}

export interface MyLocationSearchPlace extends LocationSearchPlace {
  address?: string | null;
}

export interface LocationSearchPlaceWithId extends LocationSearchPlace {
  id: string;
}
export interface LocationSearchPlaceFull extends LocationSearchPlaceWithId {
  address: {
    resultType: LocationSearchResultType;
    placeName?: string;
    streetName: string;
    houseNumber?: string;
    postalCode: string;
    city: string;
    label: string;
    labelArray: string[]; // length 3 or 4 for places
  };
}

export function toLocationAddress(data: LocationSearchPlaceDataResponse) {
  const {
    resultType,
    title,
    address: { street = "", houseNumber, postalCode, city, countryName },
  } = data;

  const labelArray = [
    title,
    `${street} ${houseNumber ?? ""}`,
    `${postalCode ?? ""} ${city}`,
    countryName,
  ];

  // remove place name if it is not place as it is same as full address
  if (resultType !== "place") {
    labelArray.shift();
  }

  return {
    resultType,
    placeName: resultType === "place" ? title : undefined,
    streetName: street,
    houseNumber,
    postalCode,
    city,
    label: labelArray.join(","),
    labelArray,
  };
}

export function toLocationSearchPlace(
  response: LocationSearchPlaceResponse
): LocationSearchPlaceWithId | null {
  if (response.error) {
    return null;
  }

  const {
    id,
    title,
    position: { lat, lng },
  } = response.data;

  return {
    id,
    label: title,
    lat,
    lng,
  };
}
function getLocationSearchPlaceRequest(locationId: string) {
  return (authorization: string) =>
    fetch(
      `${CORE_API_BASE}maps/autosuggest/detail?id=${encodeURIComponent(
        locationId
      )}`,
      {
        method: "GET",
        headers: new window.Headers({
          Authorization: authorization,
        }),
      }
    ).then((response) => response.json());
}

export function getLocationSearchPlace(locationId: string, history: History) {
  return callSecuredApiEndpoint(
    getLocationSearchPlaceRequest(locationId),
    history
  ).then(toDataResponseObject) as Promise<LocationSearchPlaceResponse>;
}

// map.trip_title. map.trip_description.

export enum SegmentType {
  // t("map.trip_title.train") t("map.trip_description.train")
  Train = "train",

  // t("map.trip_title.bus") t("map.trip_description.bus")
  Bus = "bus",

  // t("map.trip_title.tram") t("map.trip_description.tram")
  Tram = "tram",

  // t("map.trip_title.subway") t("map.trip_description.subway")
  Subway = "subway",

  // t("map.trip_title.walk") t("map.trip_description.walk")
  Walk = "walk",

  // t("map.trip_title.bicycle") t("map.trip_description.bicycle")
  Bicycle = "bicycle",

  // t("map.trip_title.bicycle-share") t("map.trip_description.bicycle-share")
  BicycleShare = "bicycle-share",

  // t("map.trip_title.bicycle-electric-share") t("map.trip_description.bicycle-electric-share")
  BicycleElectricShare = "bicycle-electric-share",

  // t("map.trip_title.car") t("map.trip_description.car")
  Car = "car",

  // t("map.trip_title.car-share") t("map.trip_description.car-share")
  CarShare = "car-share",

  // t("map.trip_title.taxi") t("map.trip_description.taxi")
  Taxi = "taxi",

  // t("map.trip_title.taxi") t("map.trip_description.taxi-wait")
  TaxiWait = "taxi-wait",

  // t("map.trip_title.wait") t("map.trip_description.wait")
  Transfer = "transfer",
}

export enum TravelTimeOption {
  // t("map.travel_option.leave_now")
  LeaveNow = "leave_now",

  // t("map.travel_option.leave_at")
  LeaveAt = "leave_at",

  // t("map.travel_option.arrive_at")
  ArriveAt = "arrive_at",
}

interface RawPlanSegment {
  id: string;
  startTime: number;
  endTime: number;
  segmentTemplateHashCode: number;
  serviceNumber?: string;
  serviceDirection?: string;
}

type TripMode =
  | "multimodal"
  | "pt_pub"
  | "cy_bic-s_blue-bike"
  | "cy_bic-s_velo-antwerpen"
  | "ps_tax"
  | "me_car"
  | "me_mic_bic"
  | "wa_wal";

interface RawPlanTrip {
  id: string;
  depart: number;
  arrive: number;
  caloriesCost: number;
  carbonCost: number;
  hassleCost: number;
  availability: "AVAILABLE" | "MISSED_PREBOOKING_WINDOW" | "CANCELLED";
  mainSegmentHashCode: number;
  weightedScore: number;
  segments: RawPlanSegment[];
  trip_mode: TripMode;
}

interface RawPlanGroup {
  category: RoutePlanCategory;
  trips: RawPlanTrip[];
}

interface RouteSection {
  time: string; // Datetime
  place: {
    type: string; // "place",
    location: LocationCoords;
    originalLocation: LocationCoords;
  };
}

interface RawSegmentTemplate {
  hashCode: number;
  metres: number;
  modeIdentifier: string;
  modeInfo: {
    localIcon: SegmentType;
  };
  providers?: TravelPassProvider[];
  items?: RawProviderData[];
  from: LocationCoords & {
    address: string;
  };
  to: LocationCoords & {
    address: string;
  };
  location?: LocationCoords & {
    address: string;
  };
  shapes?: {
    travelled: boolean;
    stops: RoutePlanSegmentStop[];
    encodedWaypoints: string;
  }[];
  streets?: {
    encodedWaypoints: string;
  }[];

  route: {
    routes: {
      id: string;
      sections: {
        id: string;
        type: string; //"pedestrian",
        departure: RouteSection;
        arrival: RouteSection;
        summary: {
          duration: number;
          length: number;
          baseDuration: number;
        };
        polyline: string; // encoded polyline
        transport: {
          mode: string; // "pedestrian"
        };
      }[];
    }[];
  };
}

interface RawPlanData {
  groups: RawPlanGroup[];
  segmentTemplates: RawSegmentTemplate[];
}

export type PlanDataResponse =
  | {
      error: false;
      data: RawPlanData;
    }
  | ApiErrorResponse;

export interface RoutePlanSegmentInfo {
  type?: SegmentType;
  providers: ProviderData[];
}

export interface RoutePlanSegmentStop extends LocationCoords {
  code: string;
  name: string;
  relativeDeparture: number;
  relativeArrival: number;
}

export interface RoutePlanTripSegment extends RoutePlanSegmentInfo {
  id: string;
  startTime: number;
  endTime: number;
  distance?: number;
  serviceNumber?: string;
  serviceDirection?: string;
  from?: LocationCoords & {
    address: string;
  };
  to?: LocationCoords & {
    address: string;
  };
  stops: RoutePlanSegmentStop[];
  waypoints: LocationCoords[];
  bikeRoute?: string[];
}

export interface RoutePlanTrip {
  id: string;
  depart: number;
  arrive: number;
  carbonCost: number; // kg of CO2
  score: number; // the lower the better
  mainSegmentInfo: RoutePlanSegmentInfo;
  segments: RoutePlanTripSegment[];
  tripMode: TripMode;
}

export type RoutePlanCategory =
  | "best"
  | "multimodal"
  | "public"
  | "shared"
  | "private";

export interface RoutePlanGroup {
  category: RoutePlanCategory;
  trips: RoutePlanTrip[];
}
export interface RoutePlanData {
  bestTrip: RoutePlanTrip | undefined;
  groups: RoutePlanGroup[];
  tripsCount: number;
}

const toSegmentProviders = (template: RawSegmentTemplate): ProviderData[] => {
  const createProviderData = (provider: TravelPassProvider): ProviderData => ({
    id: template.hashCode.toString(),
    type: provider,
    lat: template.from.lat,
    lng: template.from.lng,
    name: template.from.address,
    destination: template.to.address,
  });

  if (template.items && template.items.length > 0) {
    return template.items.map(toProviderData);
  }
  if (template.providers && template.providers.length > 0) {
    return template.providers.map(createProviderData);
  }
  return [];
};

const getSegmentType = (template?: RawSegmentTemplate) => {
  // special exception to types - it exists in API response, but we don't want to have it in type
  if ((template?.modeInfo.localIcon as any) === "wait") {
    if (template?.modeIdentifier === "ps_tax") {
      return SegmentType.TaxiWait;
    }
  }

  return template?.modeInfo.localIcon;
};

const toPlanTripSegment = (
  segment: RawPlanSegment,
  segmentTemplates: RawSegmentTemplate[]
): RoutePlanTripSegment[] => {
  const template = segmentTemplates.find(
    (template) => template.hashCode === segment.segmentTemplateHashCode
  );

  const shapes =
    template?.shapes && template.shapes.filter((shape) => shape.travelled);

  const streets = template?.streets;

  const bikeRouteSections = template?.route?.routes[0]?.sections;
  const bikeDeparturePlace = bikeRouteSections?.[0].departure.place;
  const bikeDestinationPlace = bikeRouteSections?.[0].arrival.place;

  const originalFrom = Object.assign({}, template?.from);
  const from = template?.from;

  const originalTo = Object.assign({}, template?.to || template?.location);
  const to = template?.to || template?.location;

  let distanceToBike = template?.metres;
  let walkingToBikeStartTime = segment.startTime * 1000;

  let distanceFromBike = template?.metres;
  let walkingFromBikeEndTime = segment.endTime * 1000;

  // in case of extrapolated bike set station location as bike segment location
  if (bikeDeparturePlace && from) {
    from.lat = bikeDeparturePlace.location.lat;
    from.lng = bikeDeparturePlace.location.lng;

    // derive location distance and guess time to walk
    // we have no real information about path here so this is just rough estimate
    distanceToBike = originalFrom
      ? getDistanceInKm(originalFrom, from) * 1000
      : 0;
    walkingToBikeStartTime -= distanceToBike * 3600;
  }

  if (bikeDestinationPlace && to) {
    to.lat = bikeDestinationPlace.location.lat;
    to.lng = bikeDestinationPlace.location.lng;

    // derive location distance and guess time to walk
    // we have no real information about path here so this is just rough estimate
    distanceFromBike = originalTo ? getDistanceInKm(originalTo, to) * 1000 : 0;
    walkingFromBikeEndTime += distanceFromBike * 3600;
  }

  const mainSegment = {
    id: segment.id,
    from: from,
    to: to,
    serviceNumber: segment.serviceNumber,
    serviceDirection: segment.serviceDirection,
    startTime: segment.startTime * 1000,
    endTime: segment.endTime * 1000,
    distance: template?.metres,
    type: getSegmentType(template),
    providers: template ? toSegmentProviders(template) : [],
    stops: shapes?.flatMap((shape) => shape.stops) || [],
    waypoints:
      (shapes || streets)?.flatMap((item) =>
        decodeWaypoints(item.encodedWaypoints)
      ) || [],
    bikeRoute: bikeRouteSections?.map((route) => route.polyline),
  };

  if (bikeRouteSections) {
    const segments: RoutePlanTripSegment[] = [];

    segments.push({
      id: `tb${segment.id}`,
      from: originalFrom,
      // service start use as destination
      to: from,
      serviceNumber: undefined,
      serviceDirection: undefined,
      startTime: walkingToBikeStartTime,
      endTime: segment.startTime * 1000,
      distance: distanceToBike,
      type: SegmentType.Walk,
      providers: [],
      stops: [],
      waypoints: [],
    });

    segments.push(mainSegment);

    segments.push({
      id: `fb${segment.id}`,
      from: to,
      // service start use as destination
      to: originalTo,
      serviceNumber: undefined,
      serviceDirection: undefined,
      startTime: segment.endTime * 1000,
      endTime: walkingFromBikeEndTime,
      distance: distanceFromBike,
      type: SegmentType.Walk,
      providers: [],
      stops: [],
      waypoints: [],
    });

    return segments;
  }

  return [mainSegment];
};

const isValidSegment = (segment: RoutePlanTripSegment) => {
  const durationSec = (segment.endTime - segment.startTime) / 1000;

  // remove waiting segments bellow 30 seconds
  return (
    segment.type &&
    Object.values(SegmentType).includes(segment.type) &&
    (segment.type !== SegmentType.Transfer || durationSec > 30)
  );
};

const toPlanTrip = (
  planTrip: RawPlanTrip,
  segmentTemplates: RawSegmentTemplate[]
): RoutePlanTrip => {
  const mainSegment = segmentTemplates.find(
    (template) => template.hashCode === planTrip.mainSegmentHashCode
  );

  const segments = planTrip.segments
    .flatMap((segment) => toPlanTripSegment(segment, segmentTemplates))
    .filter(isValidSegment);

  const isExtrapolatedBikeTrip = segments.some(
    (segment) => !!segment.bikeRoute
  );

  return {
    id: planTrip.id,
    depart:
      (isExtrapolatedBikeTrip && segments?.[0].startTime) ||
      planTrip.depart * 1000,
    arrive:
      (isExtrapolatedBikeTrip &&
        segments &&
        segments[segments.length - 1]?.endTime) ||
      planTrip.arrive * 1000,
    carbonCost: planTrip.carbonCost,
    mainSegmentInfo: {
      type: mainSegment?.modeInfo.localIcon,
      providers: mainSegment ? toSegmentProviders(mainSegment) : [],
    },
    score: planTrip.weightedScore,
    tripMode: planTrip.trip_mode,
    segments,
  };
};

export function toPlanData(response: PlanDataResponse): RoutePlanData | null {
  if (response.error) {
    return null;
  }

  let tripsCount = 0;
  let allTripsToFindBest: RoutePlanTrip[] = [];

  const groups = response.data.groups.map(({ category, trips: rawTrips }) => {
    const trips = rawTrips
      .filter((trip) => trip.availability === "AVAILABLE")
      .map((trip) => toPlanTrip(trip, response.data.segmentTemplates))
      .filter((trip) => trip.segments.length > 0);

    allTripsToFindBest = allTripsToFindBest.concat(trips);

    tripsCount += trips.length;

    return {
      category,
      trips,
    };
  });

  // private car / bike / something else start with me_ while walking is wa_
  const hasOtherTripAsPrivateCarOrBike = allTripsToFindBest.some(
    (trip) => !trip.tripMode.startsWith("me_")
  );

  const bestTrip = allTripsToFindBest.reduce((bestScoreTrip, trip) => {
    if (!bestScoreTrip) {
      return trip;
    } else {
      // ignore private car or bike if we have other option available and do not pick them as best trip!
      if (hasOtherTripAsPrivateCarOrBike && trip.tripMode.startsWith("me_")) {
        return bestScoreTrip;
      }
      if (trip.score < bestScoreTrip.score) {
        return trip;
      }
    }

    return bestScoreTrip;
  }, undefined as RoutePlanTrip | undefined);

  return {
    bestTrip,
    groups,
    tripsCount,
  };
}

const getRoutePlannerDataRequest =
  (
    from: LocationCoords,
    to: LocationCoords,
    travelTimeOption: TravelTimeOption,
    travelTimeDate: Date,
    transportTypes: ProviderFilterType[]
  ) =>
  (authorization: string) => {
    const query =
      `from_lat=${from.lat.toFixed(5)}` +
      `&from_lon=${from.lng.toFixed(5)}` +
      `&to_lat=${to.lat.toFixed(5)}` +
      `&to_lon=${to.lng.toFixed(5)}` +
      `&limit=${ROUTE_PLANNER_TRIP_LIMIT}` +
      (travelTimeOption === TravelTimeOption.LeaveAt
        ? `&leave_at=${travelTimeDate.toISOString()}`
        : "") +
      (travelTimeOption === TravelTimeOption.ArriveAt
        ? `&arrive_at=${travelTimeDate.toISOString()}`
        : "") +
      (transportTypes.length
        ? "&transport=" +
          transportTypes
            .map((tt) =>
              tt === ProviderFilterType.Bikes
                ? "bikes"
                : tt === ProviderFilterType.PublicTransport
                ? "public_transport"
                : tt === ProviderFilterType.CarsOrTaxi
                ? "cars-taxis"
                : tt === ProviderFilterType.Walking
                ? "walk"
                : null
            )
            .filter((x) => x)
            .join(",")
        : "");

    return fetch(`${CORE_API_BASE}maps/plan?${query}`, {
      method: "GET",
      headers: new window.Headers({
        Authorization: authorization,
      }),
    }).then((response) => response.json());
  };

export function getRoutePlanData(
  from: LocationCoords,
  to: LocationCoords,
  travelTimeOption: TravelTimeOption,
  travelTimeDate: Date,
  transportTypes: ProviderFilterType[],
  history: History
) {
  return callSecuredApiEndpoint(
    getRoutePlannerDataRequest(
      from,
      to,
      travelTimeOption,
      travelTimeDate,
      transportTypes
    ),
    history
  ).then(toDataResponseObject) as Promise<PlanDataResponse>;
}
