import { DefaultRootState } from "react-redux";
import {
  NmbsTravelPass,
  TravelPass,
  TravelProvider,
  VertsTravelPass,
} from "state/actions/TravelPassActions";
import { getAutoSavedState } from "state/getAutoSavedState";
import { AppOrWebsiteLinks, TravelPassProvider } from "utils/constants";
import { mobea } from "utils/install";
import { VertsStatus } from "./VertsStatus";

const orderList = [
  "NMBS/SNCB",
  "MIVB/STIB",
  "De Lijn",
  "TEC",
  "Taxis Verts",
  "Dott",
  "Lime",
  "Uber",
  "Bird",
  "Villo",
  "Velo Antwerpen",
  "Mobit",
  "Poppy",
  "Felyx",
  "Getaround",
  "Wheels",
  "Blue Bike",
  "Cozycar",
  "De Fietsambassade",
  "Dégage",
  "Donkey Republic",
  "Li bia velo",
  "Deskalot",
];

/**
 *  Convert search params string into map
 */
export function getSearchParams(search = "?") {
  return search
    .substring(1)
    .split("&")
    .reduce((accumulator, pair = "") => {
      const [key, value] = pair.split("=");

      if (key && value) {
        accumulator.set(key, value);
      }
      return accumulator;
    }, new Map<string, any>());
}

export function getInitialStateWithPersistance<S>(
  stateKey: keyof DefaultRootState,
  initialState: S
): S {
  const autoSaved = getAutoSavedState();

  // copy state
  const state = JSON.parse(JSON.stringify(initialState));

  if (autoSaved) {
    const autosavedSubstate = autoSaved[stateKey] || {};

    // merge saved state
    Object.keys(autosavedSubstate).forEach((key) => {
      state[key] = autosavedSubstate[key];
    });
  }

  return state;
}

export function removeSpaces(value: string) {
  return value.replace(/\s/g, "");
}

export function padWithZero(value: number | string, targetLength = 2) {
  const val = value.toString();

  let prefix = "";

  for (let i = val.length; i < targetLength; i++) {
    prefix += "0";
  }

  return prefix + val;
}

export function parseFloatWithComma(textNumber: string) {
  return parseFloat((textNumber || "").toString().replace(",", "."));
}

export function sortByDate<T>(key: keyof T) {
  return (a: T, b: T) =>
    new Date(a[key] as any).valueOf() - new Date(b[key] as any).valueOf();
}

export function filterActiveTravelPasses(travelPasses: TravelPass[]) {
  return travelPasses.filter((pass) => {
    switch (pass.provider) {
      case TravelPassProvider.verts:
        return (
          pass.expiration > Date.now() &&
          (pass as VertsTravelPass).status !== VertsStatus.Cancelled
        );
      case TravelPassProvider.delijn:
      case TravelPassProvider.nmbs:
      case TravelPassProvider.mobit:
      case TravelPassProvider["velo-antwerpen"]:
      case TravelPassProvider["blue-bike"]:
        return pass.expiration > Date.now();

      default:
        console.warn("Unknown provider", pass);
        return false;
    }
  });
}

export function combineParentTravelPasses(travelPasses: TravelPass[]) {
  // make a copy, so we don't overwrite store values
  travelPasses = travelPasses.map((pass) => ({ ...pass }));

  return travelPasses.filter((pass) => {
    switch (pass.provider) {
      case TravelPassProvider.nmbs:
        if (pass.parentTicketId) {
          const parentTravelPass = travelPasses.find(
            (activePass) => activePass.id === pass.parentTicketId
          ) as NmbsTravelPass;
          if (parentTravelPass) {
            parentTravelPass.returnTravelPass = pass as NmbsTravelPass;
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      default:
        return true;
    }
  });
}

export function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(email);
}

export function formatToServiceDate(date: Date) {
  const d = new Date(date.getTime());

  d.setHours(12); // ensure we are mid day

  return `${d.getFullYear()}-${padWithZero(d.getMonth() + 1)}-${padWithZero(
    d.getDate()
  )}`;
}

export function datesInYearsRange(
  date: Date,
  otherDate: Date,
  minYears: number,
  maxYears: number
) {
  const years =
    Math.abs(date.getTime() - otherDate.getTime()) /
    (1000 * 60 * 60 * 24 * 365); // approximately (not accounting for leap years)
  return years >= minYears && years <= maxYears;
}

export function findParentElementByClassName(
  element: HTMLElement | null,
  className: string
) {
  while (
    element &&
    !element.className.toString().split(" ").includes(className)
  ) {
    element = element.parentElement;
  }

  return element;
}

export function decapitalizeString(text: string) {
  return text ? text.substring(0, 1).toLowerCase() + text.substring(1) : "";
}

export function capitalizeString(text: string) {
  return text ? text.substring(0, 1).toUpperCase() + text.substring(1) : "";
}

export function openNativeApp(app: AppOrWebsiteLinks) {
  if (app.website) {
    window.open(app.website);
  } else {
    mobea.isIos ? window.open(app.ios) : window.open(app.android);
  }
}

export function providerComparator(
  a: Pick<TravelProvider, "name">,
  b: Pick<TravelProvider, "name">
) {
  return a.name.localeCompare(b.name);
}

export function providerOrderList(a: TravelProvider[]): TravelProvider[] {
  const notMatched = a
    .filter((provider) => orderList.indexOf(provider?.name) === -1)
    .sort(providerComparator);
  return orderList
    .map((name) => a.find((p) => name.toLowerCase() === p?.name.toLowerCase()))
    .filter((p) => p)
    .concat(notMatched) as TravelProvider[];
}
