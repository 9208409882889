import { DefaultIconProps } from "icons/props";
import { ReactElement } from "react";

export function BlueBikeLogo({
  className = "",
  width,
  height,
}: Pick<DefaultIconProps, "className" | "width" | "height">): ReactElement {
  return (
    <img
      className={className}
      width={width}
      height={height}
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkBAMAAACCzIhnAAAAElBMVEUAAAADtv9oxf+p2v/c8P////+9p+r0AAAABXRSTlMA5Ojv98a42VoAAAN+SURBVFjD7ZhNkqwqEIWNeCs4gPOLJfOHlgtAYQEFnP1v5Q3wr9WuaCvu5EW0Q8vP5GSeTCmqqpo0fn49q6qq/sGt609VVfoegttBgD+Vu4uoyt5FxG0pQIVf5Bf5e4js9T3kEUh3DxmmTsbbWgZ7W0u6Ld/Y7xHpp75rmlOjv0EUyzUdEPcmyoxQCz/1fnm5iD9AoiLJVfUb+WJB2JKcVlH+TcZWxAXuVjO8MUxYECser5+VckX0FwF03bfIMDMZALckc4xvqm+45GrY3UvvEP/oe+/DCJhVc8i+e4MMgGggQgDtWq3XAHHw0X5hfSDzKILPi01qWtOHXWUPyGyzSVC+1nXNCqdLRJK+f5Cc0uIswajIqe89xytkKPklaRebKNrZCi3tGZFMxpaKxgUZsuJYdJh8UUpq5QqS9ewsxpDnuorgzk6OEBEYSNLVtqyrp1vqWqdzV1rAzxZIpRlNNnltf0F9QEwGYHTJdC5ZDnGIQP06eHpBhghAOV26syEASUsHLMNvQTfEAZARxpOkTQDqLGk3X6t0RCwA+CZDPD2dB2SIinprTHmJSJL008Q4TE/SqgwAqqxI5AMSNICa25WHjIJgfvYSMXskxAVp9ffIPgppFwTXUTYt3EbtF0RcIng8wy7IruJXGTOL61YKAMJuip3qorZBItocWNrE7Ab/qfoyf/nx8XSHF509tl9DsMtXYveis5Nh1heW58oePazj+dwvkOtbiqnLgFDLrL3oSmBIS7NpALLT5clxGQOXQ2kCgMd+/gDtmwkDtMx9/+ThUze8mWNAS5Lp+Nl6fj8tAYi+7y42P33f/K92faJpAPFViewAPLrD3a0ryWnz985DJh7ubkgOtEckAWjHbxGHEFWCBjTQzE4Uc28JDaHPiDZRJZmhElrm+as+lYUFJ5mP5g8Ww0slmVEnwUwHwDBQm6iSoh7yurPZtHjaOYqkHopyBGeiSiYK2jafM0Y9R1FZqCR7axIGZ6LKtIKNPCGuCW6OokimmrGOBWEuE+6csVl+nVT2fhKdXqKQWtL76Sx/zdgyJM0cJQUneFGX8RFeKkl2Q5LsHh0Ak0SRX2dwbLoL+VYlQTJhnnyGpDZRZUk7bBuZBXl630GOaH0/QnpvAcin79COcsLTCe/H379vv8hHyAeHKh8c3XxwQPTBMdQHh113w/xbVfcP7v4DNQIGlUPPfNkAAAAASUVORK5CYII="
    />
  );
}
