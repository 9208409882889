import { AppColors, getDerivedColor } from "utils/colors";

export const MobitBikeSvgIcon = `<svg width="24" height="24" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
<circle cx="15" cy="15" r="15" fill="${AppColors.MOBIT_DISABLED}" />
<path transform="translate(6, 6) scale(0.75)" d="M19 18a1.98 1.98 0 01-1.53-3.28l.59 1.62a1 1 0 001.88-.68l-.59-1.63A2 2 0 0119 18zM8.86 15a4 4 0 00-1.42-2.15l.69-1.61L10.28 15H8.86zM5 18a2 2 0 01-.24-3.98l-.68 1.59A1 1 0 005 17h1.72A2 2 0 015 18zm14-6c-.13 0-.25.03-.38.04L15.7 4H13a1 1 0 000 2h1.3l1.44 3.95-3.59 4.3L9.72 10H10a1 1 0 000-2H6a1 1 0 000 2h.48l-.88 2.06c-.2-.03-.4-.06-.6-.06a4 4 0 000 8 4 4 0 003.86-3H12c.13 0 .26-.03.37-.08l.07-.03a1 1 0 00.3-.23l.03-.02 3.76-4.52.2.59A4 4 0 1019 12z" fill="white" />
</svg>`;

export const MobitSelectedBikeSvgIcon = `
<div class="mobea__mobit-selected-marker">
  <svg width="48" height="48" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="14" fill="${AppColors.MOBIT}"/>
    <path
        transform="translate(7.5, 7.5) scale(0.6)"
        d="M18.2 10l-1.7-4.7A2 2 0 0014.6 4H12v2h2.6l1.5 4h-4.9L11 9H12V7H7v2h1.8l1.8 5h-.7a5 5 0 00-4.7-4A5 5 0 000 15a5 5 0 005 5 5 5 0 004.9-4h4.2a5 5 0 004.7 4 5 5 0 005.2-5 5 5 0 00-5-5h-.8zM7.8 16A3 3 0 015 18a3 3 0 01-3-3 3 3 0 013-3 3 3 0 012.8 2H5v2h2.8zm6.3-2h-1.4l-.7-2h3c-.4.6-.8 1.3-.9 2zm4.9 4a3 3 0 01-3-3 3 3 0 011-2.3l1 2.7 1.9-.7-1-2.7h.1a3 3 0 013 3 3 3 0 01-3 3z"
        fill="white"
    />
  </svg>
</div>
`;

export const MobitEfenceStyle = {
  strokeColor: getDerivedColor(AppColors.MOBIT_DISABLED),
  fillColor: "rgba(104,185,72,0.16)",
  lineWidth: 2,
  lineCap: "square" as const,
  lineJoin: "bevel" as const,
};
