import { ReactElement } from "react";
import { CURRENT_YEAR, YEARS } from "./dateOfBirthUtils";

export interface YearMonthPickerProps {
  date: Date;
  months: string[];
  endYear?: number;
  onChange(date: Date): void;
}

export function YearMonthPicker({
  date,
  months,
  endYear = CURRENT_YEAR,
  onChange,
}: YearMonthPickerProps): ReactElement {
  const augmentedYears = YEARS.filter((year) => year <= endYear);

  const handleChange = (e: any) => {
    const { year, month } = e.target.form;

    onChange(new Date(year.value, month.value));
  };

  const isMonthDisabled = (index) => {
    const now = new Date();

    if (date.getFullYear() < now.getFullYear()) {
      return false;
    } else {
      return index > now.getMonth();
    }
  };

  return (
    <form className="DayPicker-Caption">
      <select
        name="month"
        className="mobea__datepicker__date-of-birth__month"
        onChange={handleChange}
        value={date.getMonth()}
      >
        {months.map((month, i) => (
          <option
            key={month}
            value={i}
            disabled={endYear === CURRENT_YEAR ? isMonthDisabled(i) : false}
          >
            {month}
          </option>
        ))}
      </select>

      <select
        name="year"
        className="mobea__datepicker__date-of-birth__year"
        onChange={handleChange}
        value={date.getFullYear()}
      >
        {augmentedYears.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </form>
  );
}
