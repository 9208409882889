import { FirstTutorialSlideImage } from "pages/tutorial/slides/FirstSlideImage";
import { useTranslation } from "react-i18next";
import { AppColors } from "utils/colors";

export function Step1() {
  const { t } = useTranslation();

  return (
    <>
      <h1>{t("moveasy_tutorial.step1_title")}</h1>

      <p>{t("moveasy_tutorial.step1_desc")}</p>

      <div
        css={{
          flexGrow: 1,
          display: "flex",
          alignItems: "flex-end",
          animation: "fadeIn 200ms",
          width: "10rem",
          margin: "0 2rem",
          "& > svg": {
            width: "100%",
          },
        }}
      >
        <FirstTutorialSlideImage
          color={AppColors.PRIMARY}
          light={AppColors.PRIMARY_LIGHT}
        />
      </div>
    </>
  );
}
