import { DefaultRootState } from "react-redux";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { Action } from "state";
import i18n from "utils/i18n";
import { loggingReduxEnhancer } from "utils/logging";
import { autoSaveEditor } from "./autosave";
import {
  MapReducer,
  OnboardingReducer,
  OrderingReducer,
  TravelPassReducer,
  TutorialReducer,
  UserReducer,
} from "./reducers";
import { AppUiReducer } from "./reducers/AppUiReducer";

// @ts-ignore
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  combineReducers<DefaultRootState, Action>({
    onboarding: OnboardingReducer,
    user: UserReducer,
    passes: TravelPassReducer,
    ordering: OrderingReducer,
    map: MapReducer,
    tutorial: TutorialReducer,
    appUi: AppUiReducer,
  }),
  composeEnhancer(applyMiddleware(thunkMiddleware), loggingReduxEnhancer)
);

store.subscribe(autoSaveEditor);

// Bind on language change from the i18n and sync value to store
// only this way we have unidirectional flow
i18n.on("languageChanged", (language) => {
  // deffer execution to the next tick
  Promise.resolve().then(() => {
    if (store.getState().user.language !== language) {
      store.dispatch({
        type: "SET_LANGUAGE",
        language,
      });
    }
  });
});
