import { InputGroup, LabelValue } from "common/forms";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import {
  formatDateOfBirth,
  formatIban,
  formatPhoneNumber,
  removeSpaces,
  validateEmail,
  validateIban,
} from "utils";

interface ProfileFormProps {
  nameAndSurname: string;
  showName?: boolean;
  phonePrefix: string;
  phoneNumber: string;
  dateOfBirth: string;
  iban?: string;
  email: string;
  language: string;
  onIbanChange?: (iban: string) => void;
  onEmailChange: (email: string) => void;
}

export function ProfileForm({
  nameAndSurname,
  showName = false,
  phonePrefix,
  phoneNumber,
  dateOfBirth,
  iban,
  email,
  language,
  onIbanChange,
  onEmailChange,
}: ProfileFormProps): ReactElement {
  const { t } = useTranslation();

  const formattedPhoneNumber = formatPhoneNumber(phonePrefix, phoneNumber);

  const emailValid = validateEmail(email.toString());

  return (
    <section className="mobea-profile__form">
      {showName && (
        <LabelValue label={t("profile.name")} value={nameAndSurname} />
      )}
      <LabelValue
        label={t("profile.date_of_birth")}
        value={formatDateOfBirth(dateOfBirth, language)}
      />
      <LabelValue
        label={t("shared.phone_number")}
        value={formattedPhoneNumber}
      />
      <InputGroup
        className="mobea-profile__email"
        label={t("profile.email")}
        name="email"
        placeholder={t("profile.email")}
        value={email}
        toggleLabelAndPlaceholder
        onChange={(value) => onEmailChange(value.toString())}
        errorMessage={email && !emailValid && t("shared.invalid_email")}
      />
      {typeof iban === "string" && onIbanChange && (
        <InputGroup
          className="mobea-profile__iban"
          label={t("profile.iban")}
          name="iban"
          toggleLabelAndPlaceholder
          value={formatIban(removeSpaces(iban))}
          placeholder="BE00 0000 0000 0000"
          errorMessage={t("iban.invalid_text")}
          validation={(value) => validateIban(value.toString()).valid}
          onChange={(iban) => onIbanChange(removeSpaces(iban.toString()))}
        />
      )}
    </section>
  );
}
