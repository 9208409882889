import { FileAttachment } from "common/types";
import { RawExpenseDetail } from "services/historyService";
import { Expense } from "state/actions";
import { parseFloatWithComma } from "utils";
import { TravelPassProvider } from "utils/constants";

export function normalizeExpense(rawExpense: RawExpenseDetail): Expense {
  const uploads: FileAttachment[] = rawExpense.attachments.map(
    (attachment) => ({
      name: attachment.name,
      preview: attachment.preview,
      path: attachment.original,
    })
  );

  const price = parseFloatWithComma(rawExpense.amount);

  // fallback to accepting entire price if accepted price is not provided
  const acceptedPrice = rawExpense.accepted_amount
    ? parseFloatWithComma(rawExpense.accepted_amount)
    : price;

  return {
    id: rawExpense.id.toString(),
    provider: rawExpense.provider.toLowerCase() as TravelPassProvider,
    price,
    acceptedPrice,
    status: rawExpense.status,
    expensedDate: new Date(rawExpense.expense_date).valueOf(),
    createdDate: new Date(rawExpense.created).valueOf(),
    iban: rawExpense.iban,
    acceptedDate: rawExpense.last_updated
      ? new Date(rawExpense.last_updated).valueOf()
      : undefined,
    refusalReason: rawExpense.decline_reason || undefined,
    uploads,
  };
}
