import { MobeaButton } from "common/forms";
import { NestedPage } from "common/page/NestedPage";
import {
  getPasswordStrength,
  PasswordInput,
} from "common/passwordInput/PasswordInput";
import { Spinner } from "common/Spinner";
import { CircleCheckIcon } from "icons/CircleCheckIcon";
import { ReactElement, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Auth } from "services/authTokenStore";
import { checkTokenValidity, resetPassword } from "services/passwordService";
import { setCustomerIdAction } from "state/actions";
import { ApiErrors, Routes } from "utils";
import { AppColors } from "utils/colors";
import { ResetPasswordError } from "./ResetPasswordError";
import "./ResetPasswordPage.scss";

export function ResetPasswordPage(): ReactElement {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { token } = useParams<{ token: string }>();

  const [checkingToken, setCheckingToken] = useState(true);
  const [password, setPassword] = useState("");
  const [resettingPassword, setResettingPassword] = useState(false);
  const [resetError, setResetError] = useState<ApiErrors | null>(null);
  const [resetSuccess, setResetSuccess] = useState(false);

  useEffect(() => {
    checkTokenValidity(token)
      .then((response) => {
        if (response.error) {
          console.warn("Token invalid", response.error_description);
          setResetError(response.error_code as ApiErrors);
        }
        // success - proceed to the page
      })
      .catch((e) => {
        // just log this case as we will check token again with reset
        // so no point in preventing user to proceed due to timeout
        console.warn("Token check error", e);
      })
      .finally(() => {
        setCheckingToken(false);
      });
  }, [token]);

  const doResetPassword = () => {
    setResettingPassword(true);

    resetPassword(password, token)
      .then((response) => {
        if (!response.error) {
          if (response.customerId) {
            dispatch(setCustomerIdAction(response.customerId));
          } else {
            console.error(
              "Error customer id not found after logging in!",
              Auth["token"]
            );
          }

          setResetSuccess(true);
        } else {
          console.warn("Password reset error", response.error_code);
          setResetError(response.error_code as ApiErrors);
        }
      })
      .catch((e) => {
        console.warn("Password reset error", e);
        setResetError(ApiErrors.I_NETWORK);
      })
      .finally(() => setResettingPassword(false));
  };

  const goToLogin = () => history.push(Routes.Login);

  const goToHome = () => history.push(Routes.Home);

  const goToForgottenPassword = () => history.push(Routes.ForgottenPassword);

  return (
    <NestedPage
      pageClassName="mobea-reset-password"
      title={t("forgotten_password.password_recovery")}
      headerInSecondaryColor
      onNavBack={goToLogin}
    >
      {!resetSuccess && !resetError && (
        <>
          <h2 className="mobea-reset-password__headline">
            {t("forgotten_password.new_password")}
          </h2>

          <p className="mobea-reset-password__text">
            <Trans i18nKey="forgotten_password.new_password_text" />
          </p>

          {checkingToken ? (
            <Spinner />
          ) : (
            <div>
              <label className="mobea-reset-password__label">
                {t("forgotten_password.new_password")}
              </label>

              <PasswordInput
                value={password}
                variant="outlined"
                onChange={setPassword}
              />
            </div>
          )}

          <div className="mobea-reset-password__spacer"></div>

          <MobeaButton
            type="primary"
            disabled={getPasswordStrength(password) !== "ok"}
            loading={resettingPassword}
            onClick={doResetPassword}
          >
            {t("forgotten_password.change_password")}
          </MobeaButton>
        </>
      )}

      {resetSuccess && (
        <>
          <h2 className="mobea-reset-password__headline">
            {t("forgotten_password.password_updated")}
          </h2>

          <p className="mobea-reset-password__text">
            <Trans i18nKey="forgotten_password.password_updated_text" />
          </p>

          <CircleCheckIcon
            className="result-icon"
            fill={AppColors.PRIMARY}
            width={70}
            height={70}
          />

          <div className="mobea-reset-password__spacer"></div>

          <MobeaButton type="primary" onClick={goToHome}>
            {t("shared.start")}
          </MobeaButton>
        </>
      )}

      {resetError && (
        <ResetPasswordError
          error={resetError}
          onConfirm={goToForgottenPassword}
        />
      )}
    </NestedPage>
  );
}
