import { NmbsStation } from "state/actions";

export type NmbsStationPayload = {
  id: number;
  label: string;
};

export type StationSuggestion = {
  id: number;
  label: string;
  highlights: {
    start: number;
    end: number;
  }[];
};

export function filterStations(
  value: string,
  stations: NmbsStation[],
  excluded: NmbsStationPayload
): StationSuggestion[] {
  const valueLowerCase = value.toLowerCase();
  const regexp = new RegExp(
    // escape reg exp specail chars -
    // @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
    valueLowerCase.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
    "i"
  );

  return (
    stations
      .filter(
        (station) => station.id !== excluded.id && regexp.test(station.label)
      )
      // no language filtering
      .map((station) => {
        const start = station.label.indexOf(valueLowerCase);

        return {
          id: station.id,
          label: station.label,
          highlights: [
            {
              start,
              end: start + value.length,
            },
          ],
        };
      })
  );
}
