import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RawDeLijnSingleTicket } from "services/delijnService";
import { RawNmbsTicket } from "services/nmbsService";
import { getTravelPasses } from "services/travelPassService";
import {
  setActiveTravelPassesAction,
  setPurchasesAction,
  TravelPass,
} from "state/actions";
import { parseFloatWithComma, TravelPassProvider } from "utils";
import {
  normalizeDeLijnTravelPass,
  normalizeMobitTravelPass,
} from "utils/normalizers";
import { normalizeBlueBikeTravelPass } from "utils/normalizers/blueBikeNormalizer";
import { normalizeNmbsTravelPass } from "utils/normalizers/nmbsNormalizer";
import { normalizeVeloTravelPass } from "utils/normalizers/veloNormalizer";
import { normalizeVertsTravelPass } from "utils/normalizers/vertsNormalizer";

export function useTravelPasses(filter: "active" | "all" = "all") {
  const history = useHistory();

  const dispatch = useDispatch();

  const { providers, providersToTypes: providersTypes } = useSelector(
    (state) => state.passes
  );

  const [loading, setLoading] = useState(false);

  const [key, setKey] = useState(Date.now());

  useEffect(() => {
    let canceled = false;
    let loading = false;

    async function fetchActiveTravelPasses(filter: "active" | "all") {
      try {
        setLoading(true);
        loading = true;

        const json = await getTravelPasses(filter, history);

        if (!json.error) {
          const normalizedActivePasses: TravelPass[] = json.active.map(
            (rawTicket) => {
              const provider: TravelPassProvider =
                TravelPassProvider[rawTicket.provider.slug];

              switch (provider) {
                case TravelPassProvider.delijn:
                  return normalizeDeLijnTravelPass(
                    rawTicket.response as RawDeLijnSingleTicket,
                    {
                      id: rawTicket.id,
                      orderTimestamp: new Date(rawTicket.created).valueOf(),
                      plan: rawTicket.plan,
                    }
                  );
                case TravelPassProvider.nmbs:
                  return normalizeNmbsTravelPass(
                    rawTicket.response as RawNmbsTicket,
                    {
                      id: rawTicket.id,
                      orderTimestamp: new Date(rawTicket.created).valueOf(),
                      price: parseFloatWithComma(rawTicket.amount) || 0,
                      travelEndDate: rawTicket.expire_at,
                      plan: rawTicket.plan,
                    }
                  );
                case TravelPassProvider.mobit:
                  return normalizeMobitTravelPass(rawTicket);
                case TravelPassProvider.verts:
                  return normalizeVertsTravelPass(rawTicket, providers);
                case TravelPassProvider["velo-antwerpen"]:
                  return normalizeVeloTravelPass(rawTicket);
                case TravelPassProvider["blue-bike"]:
                  return normalizeBlueBikeTravelPass(rawTicket);
                default:
                  console.debug(`No implementation for provider: ${provider}`);

                  // fallback impl which does not cache ticket details
                  return {
                    provider: provider,
                    types: providersTypes[provider],
                    id: rawTicket.id,
                    orderTimestamp: new Date(rawTicket.created).valueOf(),
                    expiration: new Date(rawTicket.expire_at).valueOf(),
                    price: parseFloatWithComma(rawTicket.amount) || 0,
                    plan: rawTicket.plan,
                  } as TravelPass;
              }
            }
          );

          if (!canceled) {
            if (filter === "active") {
              dispatch(setActiveTravelPassesAction(normalizedActivePasses));
            }

            if (filter === "all") {
              dispatch(setPurchasesAction(normalizedActivePasses));
            }

            setLoading(false);
            loading = false;
          }
        } else {
          if (!canceled) {
            setLoading(false);
            loading = false;
          }

          console.error(
            "Failed to fetch travel passes",
            json.error_code,
            json.error_description
          );
        }
      } catch (error) {
        if (!canceled) {
          setLoading(false);
          loading = false;
        }

        console.error("Failed to fetch travel passes", error);
      }
    }

    fetchActiveTravelPasses(filter);

    return () => {
      canceled = true;

      if (loading) {
        console.debug("Canceled hook useActiveTravelPasses");
      }
    };
  }, [dispatch, filter, history, key, providers, providersTypes]);

  const reload = () => setKey(Date.now());

  return [loading, reload] as const;
}
