import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

export function WalletAltIcon({
  fill = "currentColor",
  className = "",
  height = "24",
  width = "24",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 18.2c1.4 0 2.5-1 2.5-2.4V6.4c0-1.4-1.1-2.5-2.5-2.5v-.6c0-1.4-1.1-2.5-2.5-2.5H3.7C1.7.8 0 2.4 0 4.5v10c0 2 1.7 3.7 3.7 3.7h13.8zm0-1.8H3.7c-1 0-1.8-.9-1.8-1.9v-10c0-1 .8-1.9 1.8-1.9H15c.3 0 .6.3.6.7v1.2H4.4a.6.6 0 100 1.3h13.1c.3 0 .6.2.6.6v9.4c0 .3-.3.6-.6.6zM15 12a1.3 1.3 0 100-2.5 1.3 1.3 0 000 2.5z"
        fill={fill}
        fillRule="nonzero"
      />
    </svg>
  );
}
