import { BillyLogo } from "icons/logos/BillyLogo";
import { BirdLogo } from "icons/logos/BirdLogo";
import { BlueBikeLogo } from "icons/logos/BlueBike";
import { CozywheelsLogo } from "icons/logos/CozywheelsLogo";
import { DeFietsambassadeLogo } from "icons/logos/DeFietsambassadeLogo";
import { DegageLogo } from "icons/logos/DegageLogo";
import { DeLijnLogo } from "icons/logos/DeLijnLogo";
import { DeskalotLogo } from "icons/logos/DeskalotLogo";
import { DonkeyRepublicLogo } from "icons/logos/DonkeyRepublicLogo";
import { DottLogo } from "icons/logos/DottLogo";
import { FelyxLogo } from "icons/logos/FelyxLogo";
import { GetaroundLogo } from "icons/logos/GetaroundLogo";
import { LiBiaVeloLogo } from "icons/logos/LiBiaVeloLogo";
import { LimeLogo } from "icons/logos/LimeLogo";
import { MivbLogo } from "icons/logos/MivbLogo";
import { MobitLogo } from "icons/logos/MobitLogo";
import { NmbsLogo } from "icons/logos/NmbsLogo";
import { PoppyLogo } from "icons/logos/PoppyLogo";
import { TecLogo } from "icons/logos/TecLogo";
import { UberLogo } from "icons/logos/UberLogo";
import { VeloAntwerpenLogo } from "icons/logos/VeloAntwerpsLogo";
import { VertsLogo } from "icons/logos/VertsLogo";
import { VilloLogo } from "icons/logos/VilloLogo";
import { WheelsLogo } from "icons/logos/WheelsLogo";
import { ReactElement } from "react";
import { TravelPassProvider } from "utils";

export interface ProviderLogoProps {
  provider: TravelPassProvider;
}

export function ProviderLogo({
  provider,
}: ProviderLogoProps): ReactElement | null {
  const className = `mobea__provider-logo mobea__${provider}`;

  switch (provider) {
    case TravelPassProvider.delijn:
      return <DeLijnLogo className={className} />;
    case TravelPassProvider.nmbs:
      return <NmbsLogo className={className} />;
    case TravelPassProvider.mobit:
      return <MobitLogo className={className} />;
    case TravelPassProvider.lime:
      return <LimeLogo className={className} />;
    case TravelPassProvider.mivb:
      return <MivbLogo className={className} />;
    case TravelPassProvider.verts:
      return <VertsLogo className={className} />;
    case TravelPassProvider.uber:
      return <UberLogo className={className} />;
    case TravelPassProvider.tec:
      return <TecLogo className={className} />;
    case TravelPassProvider["billy-bike"]:
      return <BillyLogo className={className} />;
    case TravelPassProvider.bird:
      return <BirdLogo className={className} />;
    case TravelPassProvider.dott:
      return <DottLogo className={className} />;
    case TravelPassProvider["li-bia-velo"]:
      return <LiBiaVeloLogo className={className} />;
    case TravelPassProvider.poppy:
      return <PoppyLogo className={className} />;
    case TravelPassProvider.villo:
      return <VilloLogo className={className} />;
    case TravelPassProvider.wheels:
      return <WheelsLogo className={className} />;
    case TravelPassProvider["velo-antwerpen"]:
      return <VeloAntwerpenLogo className={className} />;
    case TravelPassProvider["donkey-republic"]:
      return <DonkeyRepublicLogo className={className} />;
    case TravelPassProvider["de-fietsambassade"]:
      return <DeFietsambassadeLogo className={className} />;
    case TravelPassProvider.felyx:
      return <FelyxLogo className={className} />;
    case TravelPassProvider.cozycar:
      return <CozywheelsLogo className={className} />;
    case TravelPassProvider.getaround:
      return <GetaroundLogo className={className} />;
    case TravelPassProvider.degage:
      return <DegageLogo className={className} />;
    case TravelPassProvider["blue-bike"]:
      return <BlueBikeLogo className={className} />;
    case TravelPassProvider.deskalot:
      return <DeskalotLogo className={className} />;
    case TravelPassProvider.other:
      return null;
    default:
      console.warn("Unknown provider. No logo for it.", provider);
      return null;
  }
}
