import { cx } from "@emotion/css";
import { ReactElement } from "react";
import { AppColors } from "utils/colors";

export type StepsProps = {
  className?: string;
  currentStep: number;
  stepCount: number;
};

export function Steps({
  className,
  currentStep,
  stepCount,
}: StepsProps): ReactElement {
  return (
    <div
      className={cx(
        "mobea__steps", // for backward compatibility
        className
      )}
      css={{
        display: "flex",
        alignItems: "center",
        height: "6px",
      }}
    >
      {[...Array(stepCount).keys()].map((index: number) => (
        <div
          key={index}
          css={{
            height: "6px",
            width: "6px",
            opacity: "0.16",
            borderRadius: "3px",
            backgroundColor: AppColors.PRIMARY,
            transition: "opacity 0.2s, width 0.2s linear",
            "&:not(:first-of-type)": {
              marginLeft: "8px",
            },
            ...(index == currentStep
              ? {
                  opacity: 1,
                  width: "16px",
                }
              : {}),
          }}
        />
      ))}
    </div>
  );
}
