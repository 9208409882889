import { ReactElement, ReactNode } from "react";
import "./ToggleGroup.scss";
import { ToggleIcon } from "./ToggleIcon";

export interface ToggleGroupProps {
  name: string;
  label: ReactNode;
  enabled: boolean;
  className?: string;
  onChange: (checked: boolean) => void;
}

export function ToggleGroup({
  name,
  label,
  enabled,
  className = "",
  onChange,
}: ToggleGroupProps): ReactElement {
  const onCheckboxChange = () => onChange(!enabled);

  return (
    <div className={`mobea__toggle-group ${className}`}>
      <div className="mobea__toggle-group__checkbox">
        <input
          type="checkbox"
          name={name}
          id={name}
          checked={enabled}
          onChange={onCheckboxChange}
        />

        <ToggleIcon on={enabled} />
      </div>

      <label htmlFor={name}>{label}</label>
    </div>
  );
}
