import { RawNmbsTicket } from "services/nmbsService";
import {
  JourneyType,
  NmbsTravelPass,
  TravelClass,
} from "state/actions/TravelPassActions";
import { addTimezoneToServerTime } from "utils";
import { TravelPassProvider, TravelPassType, WalletType } from "../constants";

export interface MissingNmbsDetailData {
  id: string;
  orderTimestamp: number;
  price: number;
  travelEndDate: string;
  plan: WalletType;
}

export function normalizeNmbsTravelPass(
  rawTravelPass: RawNmbsTicket,
  missing: MissingNmbsDetailData
): NmbsTravelPass {
  const ticket = rawTravelPass.Tickets.Ticket[0];

  const travelDate = new Date(ticket.TravelDate).valueOf();

  const expiration = addTimezoneToServerTime(missing.travelEndDate).valueOf();

  // @TODO handle type and provider other way than hardcoded - take from API?
  return {
    barCode: ticket.Barcode,
    departureStationName: ticket.DepartureStationName,
    destinationStationName: ticket.DestinationStationName,
    activation: travelDate,
    expiration: expiration,
    journeyType: rawTravelPass.journeyType as JourneyType,
    longCode: ticket.LongSmsCode,
    productId: ticket.ProductId,
    productName: ticket.ProductName,
    shortCode: ticket.ShortSmsCode,
    ticketNumber: ticket.TicketNumber,
    travelDate: ticket.TravelDate,
    travelEndDate: new Date(expiration).toISOString(),
    travelClass: rawTravelPass.travelClass as TravelClass,
    parentTicketId: rawTravelPass.parent_transaction_id,
    id: missing.id,
    orderTimestamp: missing.orderTimestamp,
    price: missing.price,
    passengerName: rawTravelPass.first_name || "",
    passengerSurname: rawTravelPass.last_name || "",
    types: [TravelPassType.Train],
    provider: TravelPassProvider.nmbs,
    plan: missing.plan,
  };
}
