import { ReactElement } from "react";
import { AppColors } from "utils/colors";

export interface AmountChartProps {
  amount: number;
  totalAmount: number;
  className?: string;
  radius?: number;
  strokeWidth?: number;
}

export function AmountChart({
  amount,
  totalAmount,
  className,
  strokeWidth = 16,
  radius = 90,
}: AmountChartProps): ReactElement {
  const circumference = 2 * Math.PI * radius;

  const strokeDasharray = (amount / totalAmount) * circumference;

  return (
    <svg
      className={className}
      viewBox={`${-strokeWidth / 2} ${-strokeWidth / 2} ${
        2 * radius + strokeWidth
      } ${2 * radius + strokeWidth}`}
      fill="none"
    >
      <circle
        cx={radius}
        cy={radius}
        r={radius}
        strokeWidth={strokeWidth}
        css={{ stroke: AppColors.PRIMARY }}
        strokeOpacity="0.16"
      />

      {totalAmount > 0 && amount > 0 && (
        <circle
          cx={radius}
          cy={radius}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={`${strokeDasharray}, ${
            circumference - strokeDasharray
          }`}
          strokeLinecap="round"
          strokeDashoffset={circumference / 4}
          css={{ stroke: AppColors.PRIMARY }}
        />
      )}
    </svg>
  );
}
