import { ReactNode } from "react";
import { LocationCoords } from "services/mapService";
import { TravelProvider } from "state/actions/TravelPassActions";
import { AppColors } from "utils/colors";
import { removeAccents } from "utils/intl";

function getLocation() {
  // eslint-disable-next-line no-undef
  return new Promise<GeolocationPosition>((resolve, reject) => {
    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(resolve, reject, {
        timeout: 20000,
      });
    } else {
      reject("No location available");
    }
  });
}

export function getLocationWithFallback() {
  return getLocation()
    .then(({ coords }) => ({
      lat: coords.latitude,
      lng: coords.longitude,
    }))
    .catch((error) => {
      console.warn(error);
      return {
        lat: null,
        lng: null,
      };
    });
}

export const BrusselsCoords = {
  lat: 50.85045,
  lng: 4.34878,
};

export function inSameLocation(markers: H.geo.IPoint[]) {
  return markers.every(
    (marker, index) =>
      index == 0 ||
      (markers[0].lat == marker.lat && markers[0].lng == marker.lng)
  );
}

export function isSameLocation(
  a: LocationCoords | null,
  b: LocationCoords | null
) {
  if (a && b) {
    return a.lat === b.lat && a.lng === b.lng;
  }
  return false;
}

export function normalizeSearchText(searchText: string) {
  return removeAccents(searchText.trim().toLowerCase());
}

type Highlight = { start: number; end: number };

export function getHighlights(text: string, searchText: string): Highlight[] {
  const normalizedText = normalizeSearchText(text);

  const normalizedSearchText = normalizeSearchText(searchText);

  const highlights = [] as Highlight[];

  let start = 0;

  while (start < normalizedText.length) {
    start = normalizedText.indexOf(normalizedSearchText, start);

    if (start === -1) {
      break;
    }

    highlights.push({
      start,
      end: start + normalizedSearchText.length,
    });

    start += normalizedSearchText.length;
  }

  return highlights;
}

export function splitToHighlights(
  text: string,
  searchText: string,
  highlights: Highlight[] = getHighlights(text, searchText)
) {
  if (highlights.length === 0) {
    return text;
  }

  const chunks: ReactNode[] = [];

  let start = 0;

  for (const highlight of highlights) {
    if (highlight.start !== 0) {
      chunks.push(text.substring(start, highlight.start));
    }

    chunks.push(
      <span
        key={start}
        className="highlighted"
        css={{
          textDecoration: "underline",
          fontWeight: 600,
          color: AppColors.PRIMARY,
        }}
      >
        {text.substring(highlight.start, highlight.end)}
      </span>
    );

    start = highlight.end;
  }

  if (start < text.length) {
    chunks.push(text.substring(start));
  }

  return chunks;
}

export function filterProvidersByName(
  searchText: string,
  providers: TravelProvider[]
) {
  const normalizedSearchText = normalizeSearchText(searchText);

  return providers.filter(
    (provider) =>
      !searchText.trim() ||
      removeAccents(provider.name).toLowerCase().includes(normalizedSearchText)
  );
}

export function getDistanceInKm(l1: LocationCoords, l2: LocationCoords) {
  const [lat1, lon1, lat2, lon2] = [l1.lat, l1.lng, l2.lat, l2.lng];

  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
}

function deg2rad(deg: number) {
  return deg * (Math.PI / 180);
}
