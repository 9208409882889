import { MobeaButton } from "common/forms";
import { useContactUsModal } from "common/modal";
import { ExclamationMarkIcon } from "icons/ExclamationMarkIcon";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { AppColors } from "utils/colors";

interface AddMoneyFailureProps {
  title: string;
  text: string;
  onRetry: () => void;
}

export function AddMoneyFailure({
  title,
  text,
  onRetry,
}: AddMoneyFailureProps): ReactElement {
  const { t } = useTranslation();

  const { show: showContactUs } = useContactUsModal();

  return (
    <div
      css={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        flex: "1 0 auto",
      }}
    >
      <h2
        css={{
          fontSize: "2rem",
          lineHeight: 1.25,
          marginBottom: "1.5rem",
          fontWeight: 400,
          marginTop: "2.25rem",
        }}
      >
        {title}
      </h2>
      <p
        css={{
          fontSize: "1rem",
          lineHeight: 1.5,
          marginTop: 0,
          marginBottom: "3.5rem",
          color: AppColors.GRAY_400,
        }}
      >
        {text}
      </p>
      <ExclamationMarkIcon
        fill={AppColors.ERROR}
        width={68}
        height={68}
        css={{
          margin: "0 auto",
        }}
      />
      <div
        css={{
          flex: "1 1 auto",
        }}
      ></div>
      <MobeaButton
        onClick={onRetry}
        css={{
          marginBottom: "0.5rem",
        }}
      >
        {t("shared.try_again")}
      </MobeaButton>
      <MobeaButton type="secondary" onClick={showContactUs}>
        {t("settings.contact_us")}
      </MobeaButton>
    </div>
  );
}
