import { CalendarIcon } from "icons/CalendarIcon";
import { TicketIcon } from "icons/navigation";
import { PortraitIcon } from "icons/PortraitIcon";
import { MouseEvent, ReactElement, useRef } from "react";
import { useTranslation } from "react-i18next";
import { usePinch } from "react-use-gesture";
import {
  JourneyType,
  NmbsTravelPass as NmbsTravelPassType,
  TravelClass,
} from "state/actions/TravelPassActions";
import { formatDateWithLocaleAsDefault, padWithZero } from "utils";
import { Clock } from "../components/Clock";
import { TravelPassWrapper } from "../TravelPassWrapper";
import "./NmbsTravelPass.scss";
import { NmbsTravelPassItem } from "./NmbsTravelPassItem";
import { NmbsTravelPassRoute } from "./NmbsTravelPassRoute";

const IMAGE_SIZE_DEFAULT = 150;
const IMAGE_MAX_SIZE = 300;

export interface NmbsTravelPassProps extends NmbsTravelPassType {
  isPreview?: boolean;
  locale: string;
  onSelect?(): void;
}

export function NmbsTravelPass({
  isPreview = false,
  provider,
  barCode,
  departureStationName,
  destinationStationName,
  journeyType,
  travelDate,
  travelEndDate,
  returnTravelPass,
  orderTimestamp,
  shortCode,
  longCode,
  productId,
  ticketNumber,
  travelClass,
  passengerName,
  passengerSurname,
  locale,
  onSelect,
}: NmbsTravelPassProps): ReactElement {
  const { t } = useTranslation();

  const showDetail = (e: MouseEvent) => {
    e.preventDefault();

    onSelect && onSelect();
  };

  const qrCodeSrc = ["data:image/png;base64,", barCode].join("");

  const imageRef = useRef<HTMLImageElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  usePinch(
    ({ movement, event }) => {
      if (isPreview) {
        return;
      }

      const target = imageRef.current;
      const wrapper = imageRef.current?.parentElement;

      if (wrapper && target) {
        event.preventDefault();

        const imageSize = target.getBoundingClientRect();

        const newWidth = Math.min(
          Math.max(imageSize.width + movement[0], IMAGE_SIZE_DEFAULT),
          IMAGE_MAX_SIZE
        );
        const newHeight = Math.min(
          Math.max(imageSize.height + movement[0], IMAGE_SIZE_DEFAULT),
          IMAGE_MAX_SIZE
        );

        wrapper.style.height = `${newHeight}px`;
        target.style.transform = `scale(${newWidth / IMAGE_SIZE_DEFAULT},${
          newHeight / IMAGE_SIZE_DEFAULT
        })`;
      }
    },

    {
      domTarget: containerRef,
      eventOptions: { passive: false },
    }
  );

  const returnJourney =
    !!returnTravelPass || journeyType === JourneyType.RETURN;

  const date = new Date(travelDate);

  const travelDateFormatted = formatDateWithLocaleAsDefault(date, locale);

  const endDate = returnTravelPass
    ? new Date(returnTravelPass.travelDate)
    : travelEndDate && new Date(travelEndDate);

  const travelEndDateFormatted = endDate
    ? formatDateWithLocaleAsDefault(endDate, locale)
    : "";

  const purchase = new Date(orderTimestamp);

  const purchaseFormatted = `${padWithZero(purchase.getDate())}/${padWithZero(
    purchase.getMonth() + 1
  )}/${purchase.getFullYear()} ${padWithZero(
    purchase.getHours()
  )}:${padWithZero(purchase.getMinutes())}:${padWithZero(
    purchase.getSeconds()
  )}`;

  const departure = (departureStationName || "").toLowerCase();

  const destination = (destinationStationName || "").toLowerCase();

  return (
    <TravelPassWrapper
      className={`mobea__nmbs-ticket ${
        isPreview ? "mobea__ticket-preview" : ""
      }`}
      provider={provider}
      activation={date.valueOf()}
      expiration={endDate ? endDate.valueOf() : undefined}
      isReturnJourney={returnJourney}
      locale={locale}
      onClick={showDetail}
    >
      <div ref={containerRef}>
        <section className="mobea__nmbs-ticket__scanners">
          <Clock className="mobea__nmbs-ticket__scanners__timer" />

          <div className="mobea__nmbs-ticket__scanners__qr-code">
            <img
              ref={imageRef}
              src={qrCodeSrc}
              alt="nmbs ticket bar code"
              css={{
                width: IMAGE_SIZE_DEFAULT,
                height: IMAGE_SIZE_DEFAULT,
              }}
            />
          </div>
        </section>

        <section className="mobea__nmbs-ticket__journey">
          <NmbsTravelPassRoute
            departure={departure}
            destination={destination}
            journeyType={journeyType}
          />

          <hr className="mobea__nmbs-ticket__hr-line" />

          <div className="mobea__nmbs-ticket__journey__info basic">
            <NmbsTravelPassItem
              label={
                <>
                  <TicketIcon className="ticket-icon" width="16" height="16" />
                  {t("nmbs_detail.ticket_type")}
                </>
              }
              type="block"
            >
              {t(`order_nmbs.ticket.${productId}.name`)}
            </NmbsTravelPassItem>

            <NmbsTravelPassItem
              label={
                <>
                  <CalendarIcon width="16" height="16" />
                  {t("time.date")}
                </>
              }
              type="block"
            >
              <span>{travelDateFormatted}</span>
              {(returnJourney ||
                travelEndDateFormatted !== travelDateFormatted) && (
                <span>&nbsp;-&nbsp;{travelEndDateFormatted}</span>
              )}
            </NmbsTravelPassItem>
          </div>

          <hr className="mobea__nmbs-ticket__hr-line" />

          <div className="mobea__nmbs-ticket__journey__info name">
            <NmbsTravelPassItem label={<PortraitIcon />} type="inline">
              {passengerName}&nbsp;{passengerSurname}
            </NmbsTravelPassItem>
          </div>

          <hr className="mobea__nmbs-ticket__hr-line" />

          <div className="mobea__nmbs-ticket__journey__info details">
            <NmbsTravelPassItem label={t("nmbs_detail.purchase_date")}>
              {purchaseFormatted}
            </NmbsTravelPassItem>

            <NmbsTravelPassItem label={t("nmbs_detail.class")}>
              {travelClass === TravelClass.FIRST
                ? t("order_nmbs.first_class")
                : t("order_nmbs.second_class")}
            </NmbsTravelPassItem>

            <NmbsTravelPassItem label={t("nmbs_detail.control_numbers")}>
              {shortCode}&nbsp;/&nbsp;{longCode}
            </NmbsTravelPassItem>

            <NmbsTravelPassItem label={t("nmbs_detail.product_code")}>
              {productId}
            </NmbsTravelPassItem>

            <NmbsTravelPassItem label={t("nmbs_detail.ticket_number")}>
              {ticketNumber}
            </NmbsTravelPassItem>
          </div>
        </section>
      </div>
    </TravelPassWrapper>
  );
}
