import { cx } from "@emotion/css";
import { CountryPhonePrefix } from "pages/userVerification/CountryPhonePrefix";
import { ChangeEvent, ReactElement, useState } from "react";
import { formatPhoneNumberWithoutPrefix, generatePlaceholder } from "utils";
import { getAllowedPhoneNumbers } from "utils/configure";
import { PhonePrefix } from "utils/constants";
import { MobeaDropdown } from "../forms";
import "./PhoneNumberPicker.scss";

interface PhoneNumberPickerProps {
  prefix: PhonePrefix;
  localNumberFormatted: string;
  valid: boolean;
  onPrefixChange?: (value: string) => void;
  onPhoneNumberChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onSubmit?(): void;
  disabled?: boolean;
}

export function PhoneNumberPicker({
  prefix,
  localNumberFormatted,
  valid,
  onPrefixChange,
  onPhoneNumberChange,
  onSubmit,
  disabled,
}: PhoneNumberPickerProps): ReactElement {
  const allowedPhoneNumbers = getAllowedPhoneNumbers();

  const [touched, setTouched] = useState(false);

  const [focused, setFocused] = useState(false);

  // TODO rather use `<form onSubmit={...}`
  const onInputKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      valid && onSubmit && onSubmit();
    }
  };

  const onFocus = () => {
    setTouched(true);
    setFocused(true);
  };

  const onBlur = () => {
    setFocused(false);
  };

  const toCountryPhonePrefix = (phonePrefix: string) => (
    <CountryPhonePrefix phonePrefix={phonePrefix} />
  );

  return (
    <div
      className={cx(
        "mobea__phone-number-picker",
        disabled && "disabled",
        !touched || valid ? "mobea__valid" : "mobea__invalid",
        focused && "mobea__phone-number-picker__focused"
      )}
    >
      <MobeaDropdown
        value={prefix.code}
        direction="down"
        options={allowedPhoneNumbers
          .filter((item, pos) => allowedPhoneNumbers.indexOf(item) == pos)
          .map((prefix) => prefix.code)}
        optionHeight={36}
        className="mobea__phone-number-picker__prefix"
        onChange={onPrefixChange}
        valueTransformer={toCountryPhonePrefix}
        optionTransformer={toCountryPhonePrefix}
        disabled={disabled}
      />

      <input
        className="mobea__phone-number-picker__number"
        type="tel"
        value={localNumberFormatted}
        maxLength={16}
        placeholder={formatPhoneNumberWithoutPrefix(
          generatePlaceholder(prefix.minLength)
        )}
        onChange={onPhoneNumberChange}
        onKeyDown={onInputKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
      />
    </div>
  );
}
