export const startTutorial = (name: "main" | "providers") => ({
  type: "START_TUTORIAL" as const,
  name,
});

export const endTutorial = () => ({
  type: "END_TUTORIAL" as const,
});

export const nextTutorialStep = () => ({
  type: "NEXT_TUTORIAL_STEP" as const,
});

export const prevTutorialStep = () => ({
  type: "PREV_TUTORIAL_STEP" as const,
});

export const setHistoryTutorialShown = (shown: boolean) => ({
  type: "SET_HISTORY_TUTORIAL_SHOWN" as const,
  shown,
});

export const setMapTutorialShown = (shown: boolean) => ({
  type: "SET_MAP_TUTORIAL_SHOWN" as const,
  shown,
});

export type TutorialActions =
  | ReturnType<typeof startTutorial>
  | ReturnType<typeof endTutorial>
  | ReturnType<typeof nextTutorialStep>
  | ReturnType<typeof prevTutorialStep>
  | ReturnType<typeof setHistoryTutorialShown>
  | ReturnType<typeof setMapTutorialShown>;
