import { useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getCustomer } from "services/customerService";
import { setCustomerProfileDataAction } from "state/actions/UserActions";

export function useUpdatedCustomer() {
  const history = useHistory();

  const dispatch = useDispatch();

  const customerId = useSelector((state) => state.user.customerId);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let canceled = false;

    async function fetchUserData() {
      try {
        setLoading(true);

        const customerJson = await getCustomer(customerId, history);

        if (!customerJson.error) {
          const iban = customerJson.iban || "";

          const name = customerJson.name || "";

          const surname = customerJson.surname || "";

          const email = customerJson.email || "";

          const dateOfBirth = customerJson.date_of_birth || "";

          batch(() => {
            if (!canceled) {
              dispatch(
                setCustomerProfileDataAction({
                  name,
                  surname,
                  iban,
                  email,
                  dateOfBirth,
                })
              );

              setLoading(false);
            }
          });
        } else {
          !canceled && setLoading(false);

          console.error(
            "Failed to fetch user data",
            customerJson.error && customerJson.error_description
          );
        }
      } catch (error) {
        !canceled && setLoading(false);

        console.error("Failed to fetch user data", error);
      }
    }

    fetchUserData();

    return () => {
      canceled = true;

      console.debug("Canceled hook fetchCustomerIban");
    };
  }, [customerId, dispatch, history]);

  return loading;
}
