import { useReduxState } from "common/hooks";
import { useState } from "react";
import { useHistory } from "react-router";
import { setProviderTerms } from "services/providerService";
import { providerTermsAcceptedAction } from "state/actions";
import { ApiErrors, TravelPassProvider } from "utils";

export function useProviderTerms(provider: TravelPassProvider) {
  const history = useHistory();

  const [accepting, setAccepting] = useState(false);

  const [errorCode, setErrorCode] = useState<string | null>(null);

  const [accepted, setAccepted] = useReduxState(
    (state) => state.ordering.termsAccepted[provider] || false,
    (accepted: boolean) => providerTermsAcceptedAction({ [provider]: accepted })
  );

  const acceptProviderTerms = async () => {
    setAccepting(true);

    setErrorCode(null);

    try {
      const response = await setProviderTerms(provider, true, history);
      if (!response.error) {
        setAccepted(true);
      } else {
        setErrorCode(response.error_code);
      }
    } catch (e) {
      setErrorCode(ApiErrors.WRONG_DATA_INTEGRATION);
    } finally {
      setAccepting(false);
    }
  };

  return [accepted, acceptProviderTerms, accepting, errorCode] as const;
}
