import { MobitLogo } from "icons/logos/MobitLogo";
import { CommonTileProps } from "pages/providers/tiles";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Routes, TravelPassProvider } from "utils";
import { useCheckEnoughBudget } from "../dialogs/useInsufficientBudgetDialog";
import { ProviderTile, PublicProviderTileProps } from "./ProviderTile";

export function MobitTile(
  props: PublicProviderTileProps & CommonTileProps
): ReactElement {
  const { t } = useTranslation();

  const checkDeposit = useCheckEnoughBudget(
    props.provider,
    props.minPrice || 0
  );

  return (
    <ProviderTile
      {...props}
      kind={t("data.bike")}
      to={
        props.type === "expense"
          ? Routes.ExpenseAdd.replace(":provider", TravelPassProvider.mobit)
          : Routes.MobitOrder
      }
      beforeBuy={checkDeposit}
    >
      <MobitLogo width="53px" height="23px" />
    </ProviderTile>
  );
}
