import { useState } from "react";
import { useHistory } from "react-router";
import { activateBlueBikeTicket } from "services/blueBikeService";
import { ApiErrors } from "utils";

export function useBlueBikeActivate() {
  const history = useHistory();

  const [activating, setActivating] = useState(false);

  const [errorCode, setErrorCode] = useState<string | null>(null);

  const [success, setSuccess] = useState<true | null>(null);

  const activate = async (ticketId: string) => {
    setActivating(true);

    setErrorCode(null);

    try {
      const response = await activateBlueBikeTicket(ticketId, history);

      if (!response.error) {
        setSuccess(true);
      } else {
        setErrorCode(response.error_code);
        console.error("Blue bike activation error", response.error_code);
      }
    } catch (error) {
      setErrorCode(ApiErrors.I_API_ISSUES);
      console.error("Blue bike activation error", error);
    } finally {
      setActivating(false);
    }
  };

  return [activate, activating, success, errorCode] as const;
}
