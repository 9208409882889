import { LabelValue, MobeaButton } from "common/forms";
import { useModal } from "common/hooks/useModal";
import { KeyIcon } from "icons/KeyIcon";
import { LabelIcon } from "icons/LabelIcon";
import { QuestionMarkIcon } from "icons/QuestionMarkIcon";
import { useBlueBikeData } from "pages/blueBike/hooks/useBlueBikeData";
import { MouseEvent, ReactElement, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  BlueBikeStatus,
  BlueBikeTravelPass as BlueBikeTravelPassType,
} from "state/actions/TravelPassActions";
import { formatCurrency, pushLocation, Routes, SupportedLanguage } from "utils";
import { AppColors } from "utils/colors";
import { TicketStartEnd } from "../components/time/TicketStartEnd";
import { TravelPassInfoLine } from "../components/TravelPassInfoLine";
import { TravelPassWrapper } from "../TravelPassWrapper";
import { BlueBikeImage } from "./BlueBikeImage";
import { BlueBikeStatusBadge } from "./components/BlueBikeStatusBadge";
import { useBlueBikeActivate } from "./hooks/useBlueBikeActivate";

type Props = BlueBikeTravelPassType & {
  isPreview?: boolean;
  locale: string;
  refresh: () => void;
  onSelect?: () => void;
};

export function BlueBikeTravelPass({
  isPreview = false,
  provider,
  status,
  id,
  password,
  price,
  activation,
  expiration,
  wrongParkingFine,
  locale,
  refresh,
  onSelect,
}: Props): ReactElement {
  const history = useHistory();
  const { t } = useTranslation();

  const allProviders = useSelector((state) => state.passes.providers);
  const { validity, returnTime } = useSelector(
    (state) => state.ordering.blueBikeData
  );

  const travelProvider = allProviders.find(
    (travelProvider) => travelProvider.provider === provider
  );

  const [activate, activating, activationSuccess] = useBlueBikeActivate();

  useBlueBikeData(isPreview);

  const { show: showConfirmActivationDialog } = useModal({
    title: t("blue-bike_detail.confirm_activation"),
    confirmText: t("shared.activate"),
    confirmHides: true,
    onConfirm: () => {
      activate(id);
    },
    cancelText: t("shared.cancel"),
    cancelHides: true,
    children: (
      <Trans i18nKey="blue-bike_detail.activate_confirm_text">
        <span className="mobea__arial">{{ validity }}</span>
      </Trans>
    ),
  });

  const { show: showNeedHelp } = useModal({
    type: "secondary",
    title: t("contact_us.need_help"),
    image: (
      <QuestionMarkIcon width={88} height={88} fill={AppColors.GRAY_100} />
    ),
    confirmText: t("blue-bike_detail.contact_blue-bike"),
    onConfirm: () => {
      window.open(`https://www.blue-bike.be/${locale}/contact`, "_blank");
    },
    confirmHides: true,
    cancelText: t("shared.send_sms"),
    onCancel: () => {
      window.open("sms:8686");
    },
    cancelHides: true,
    showCloseButton: true,
    children: t("blue-bike_detail.need_help_text"),
  });

  useEffect(() => {
    if (activationSuccess) {
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activationSuccess]);

  const showDetail = (e: MouseEvent) => {
    if (onSelect) {
      e.preventDefault();

      onSelect();
    }
  };

  const goToMap = () => {
    pushLocation(history, Routes.BlueBikeMap.replace(":id", id));
  };

  const feesInfoLinks: Record<SupportedLanguage, string> = {
    en: "https://www.blue-bike.be/en/fees-and-deductibles",
    fr: "https://www.blue-bike.be/fr/supplements-et-propres-risques",
    nl: "https://www.blue-bike.be/nl/tarieven",
  };

  return (
    <TravelPassWrapper
      className="mobea__blue-bike-ticket"
      provider={provider}
      activation={activation}
      expiration={expiration}
      locale={locale}
      onClick={showDetail}
      css={{
        backgroundColor: isPreview
          ? AppColors.WHITE
          : AppColors.TICKET_BACKGROUND,
        overflow: "hidden",
      }}
    >
      <section>
        <div
          css={{
            paddingTop: 32,
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: isPreview ? 24 : 32,
          }}
        >
          <BlueBikeStatusBadge status={status} />

          <div
            css={{
              fontSize: "1.375rem",
              fontWeight: 600,
              lineHeight: "2rem",
              textAlign: "right",
              color: travelProvider?.textColor,
              opacity: isPreview ? 0.48 : 1,
              transition: "opacity 0.2s",
            }}
          >
            {t("blue-bike_order.ticket")}
          </div>
        </div>

        <div
          css={{
            width: isPreview ? 105 : 160,
            opacity: isPreview ? 0.48 : 1,
            transition: "width 0.2s, opacity 0.2s",
            margin: "0 auto",
            marginBottom: isPreview ? 24 : 32,
          }}
        >
          <BlueBikeImage />
        </div>
      </section>
      <section
        css={{
          margin: "0 -16px -16px",
          padding: 16,
          backgroundColor: isPreview
            ? AppColors.TICKET_BACKGROUND
            : AppColors.WHITE,
        }}
      >
        <div
          css={{
            marginBottom: isPreview ? 0 : 16,
          }}
        >
          {status === BlueBikeStatus.Ready && (
            <>
              <div
                css={{
                  transition: "max-height 0.2s",
                  maxHeight: isPreview ? 0 : 250,
                  overflow: "hidden",
                }}
              >
                <TravelPassInfoLine>
                  <Trans
                    i18nKey="blue-bike_detail.info_active"
                    values={{ active: validity }}
                  >
                    <span className="mobea__bold mobea__arial"></span>
                  </Trans>
                </TravelPassInfoLine>
                <TravelPassInfoLine>
                  <Trans i18nKey="blue-bike_detail.info_return"></Trans>
                </TravelPassInfoLine>
                <TravelPassInfoLine>
                  <Trans
                    i18nKey="blue-bike_detail.info_broken"
                    values={{ returnTime }}
                  >
                    <span className="mobea__bold mobea__arial"></span>
                  </Trans>
                </TravelPassInfoLine>
                <TravelPassInfoLine>
                  <Trans
                    i18nKey="blue-bike_detail.info_fine"
                    values={{ validity }}
                  >
                    <a
                      href={feesInfoLinks[locale]}
                      target="_blank"
                      rel="noreferrer"
                    ></a>
                  </Trans>
                </TravelPassInfoLine>
              </div>
              {isPreview && (
                <div>
                  <LabelValue
                    label={t("time.from")}
                    value={t("velo_detail.valid_after_activation")}
                  />
                </div>
              )}
            </>
          )}
          {status !== BlueBikeStatus.Ready && (
            <div
              css={{
                display: "grid",
                gridTemplateColumns: "repeat(3, auto)",
                gridTemplateRows: "repeat(3, auto)",
                justifyContent: "space-between",
                gridColumnGap: 0,
                gridRowGap: isPreview ? 0 : 16,
                marginBottom: isPreview ? 0 : 32,
                opacity: isPreview ? 0.48 : 1,
                transition:
                  "margin-bottom 0.2s, grid-row-gap 0.2s, opacity 0.2s",
                "> .mobea__label-value.from": {
                  gridArea: "1 / 1 / 2 / 2",
                },
                "> .mobea__ticket-start-end__separator": {
                  gridArea: "1 / 2 / 2 / 3",
                  minWidth: 24,
                },
                "> .mobea__label-value.to": {
                  gridArea: "1 / 3 / 2 / 4",
                },
                ".mobea__label-value__label": {
                  color: AppColors.TEXT_DEFAULT,
                  fontSize: "0.875rem",
                  lineHeight: "1rem",
                },
              }}
            >
              <TicketStartEnd
                validityStart={activation}
                validityEnd={expiration}
                noWrapper
              />
              <div
                css={{
                  position: "relative",
                  height: 1,
                  backgroundColor: AppColors.BACKGROUND,
                  gridArea: "2 / 1 / 3 / 4",
                  transition: "opacity 0.2s",
                  opacity: isPreview ? 0 : 1,
                }}
              />
              <LabelValue
                icon={<KeyIcon />}
                label={t("blue-bike_detail.code")}
                value={password}
                css={{
                  gridArea: "3 / 1 / 4 / 2",
                  transition: "max-height 0.2s",
                  maxHeight: isPreview ? 0 : 50,
                  overflow: "hidden",
                  ".mobea__label-value__label": {
                    color: AppColors.GRAY_200,
                  },
                }}
              />
              <LabelValue
                icon={<LabelIcon width={16} height={16} outlined />}
                label={t("shared.price")}
                value={formatCurrency(price, locale)}
                css={{
                  gridArea: "3 / 3 / 4 / 4",
                  transition: "max-height 0.2s",
                  maxHeight: isPreview ? 0 : 50,
                  overflow: "hidden",
                  ".mobea__label-value__label": {
                    color: AppColors.GRAY_200,
                  },
                  ".mobea__label-value__value": {
                    ...(status === BlueBikeStatus.Charged
                      ? {
                          color: AppColors.ERROR,
                          fontWeight: "bold",
                        }
                      : {
                          fontWeight: "normal",
                        }),
                  },
                }}
              />
            </div>
          )}

          <div
            css={{
              color: AppColors.ERROR,
              fontSize: "0.75rem",
              lineHeight: "1rem",
              transition: "max-height 0.2s",
              maxHeight: isPreview ? 0 : 50,
            }}
          >
            {status === BlueBikeStatus.Charged && !wrongParkingFine && (
              <Trans i18nKey="blue-bike_detail.fined_text">
                <a
                  href={feesInfoLinks[locale]}
                  target="_blank"
                  rel="noreferrer"
                ></a>
              </Trans>
            )}
            {status === BlueBikeStatus.Charged && wrongParkingFine && (
              <Trans i18nKey="blue-bike_detail.fined_parking_text">
                <a
                  href={feesInfoLinks[locale]}
                  target="_blank"
                  rel="noreferrer"
                ></a>
              </Trans>
            )}
          </div>
        </div>
        <div
          css={{
            display: "flex",
            maxHeight: isPreview ? 0 : 100,
            overflow: isPreview ? "hidden" : "visible",
            justifyContent:
              status === BlueBikeStatus.Expired ? "end" : "space-between",
          }}
        >
          {status === BlueBikeStatus.Ready && (
            <MobeaButton
              onClick={showConfirmActivationDialog}
              type="secondary"
              loading={activating}
              disabled={activating}
              css={{
                marginRight: 12,
              }}
            >
              {t("blue-bike_detail.activate_ticket")}
            </MobeaButton>
          )}
          {status === BlueBikeStatus.Active && (
            <MobeaButton
              onClick={goToMap}
              type="secondary"
              css={{
                marginRight: 12,
              }}
            >
              {t("blue-bike_detail.find_station")}
            </MobeaButton>
          )}
          <MobeaButton
            onClick={showNeedHelp}
            type="secondary"
            css={{
              flex: "0 0 auto",
              width: 48,
              padding: "10px !important",
              height: 48,
            }}
          >
            <QuestionMarkIcon width={24} height={24} />
          </MobeaButton>
        </div>
      </section>
    </TravelPassWrapper>
  );
}
