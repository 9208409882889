import { useTutorialHighlighter } from "common/tutorial2/Tutorial";
import { ReactElement, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ProviderFilterType } from "./ProviderFilterType";
import "./ProvidersFilter.scss";
import { ProvidersFilterOptions } from "./ProvidersFilterOptions";

type Props = {
  disabled?: boolean;
  selected: ProviderFilterType[];
  // defines how we determine filter availability based on providers
  availabilityCheck?: "allowed" | "availableOnMap" | "none";
  onChange(selected: ProviderFilterType[]): void;
  hiddenOptions?: ProviderFilterType[];
  multiple?: boolean;
};

export function ProvidersFilter({
  disabled = false,
  selected,
  availabilityCheck = "allowed",
  onChange,
  hiddenOptions = [],
  multiple,
}: Props): ReactElement {
  const { t } = useTranslation();

  const allProviderTypes = useSelector(
    (state) =>
      new Set(
        state.passes[
          availabilityCheck === "allowed"
            ? "providersTypes"
            : "mapProvidersTypes"
        ]
      )
  );

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useTutorialHighlighter("providers", 2, "tutorial-filter", {
    position: "bottom",
    offsetY: 10,
  });

  const toFilterOption = (
    filterOption: typeof ProvidersFilterOptions[number]
  ) => {
    const isSelected =
      (selected.length === 0 && filterOption.type === ProviderFilterType.All) ||
      selected.includes(filterOption.type);

    const toggleOption = () => {
      if (disabled) {
        return;
      }

      scrollContainerRef
        .current!.querySelector(`[data-filter='${filterOption.type}']`)
        ?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });

      return onChange(
        filterOption.type === ProviderFilterType.All
          ? []
          : isSelected
          ? selected.filter((item) => item !== filterOption.type)
          : multiple
          ? [...selected, filterOption.type]
          : [filterOption.type]
      );
    };

    return (
      <div
        id={
          filterOption.type === ProviderFilterType.PublicTransport
            ? "tutorial-filter"
            : undefined
        }
        key={filterOption.type}
        data-filter={filterOption.type}
        className={`mobea-providers-filter__option ${
          isSelected ? "selected" : ""
        }`}
        onClick={toggleOption}
      >
        <filterOption.icon className="mobea-providers-filter__option__icon" />
        <span className="mobea-providers-filter__option__name">
          {t(filterOption.name)}
        </span>
      </div>
    );
  };

  const availableFilterOptions = useMemo(() => {
    return ProvidersFilterOptions.filter(
      (option) => !hiddenOptions.includes(option.type)
    ).filter((option) => {
      if (availabilityCheck === "none") {
        return true;
      }
      return option.types.some((type) => allProviderTypes.has(type));
    });
  }, [allProviderTypes, availabilityCheck, hiddenOptions]);

  return (
    <div ref={scrollContainerRef} className="mobea-providers-filter">
      {availableFilterOptions.map(toFilterOption)}
    </div>
  );
}
