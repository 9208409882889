import { OrderError, OrderErrorProps } from "common/errors/OrderError";
import { MobeaModal } from "common/modal";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ApiErrors, pushLocation, Routes } from "utils";

export function MobitOrderError({
  errorCode,
  closeError,
}: OrderErrorProps): ReactElement {
  const history = useHistory();

  const { t } = useTranslation();

  const goHome = () => pushLocation(history, Routes.Home);

  switch (errorCode) {
    case ApiErrors.MOBIT_DAY_PASS_ALREADY_PURCHASED_ERROR:
      return (
        <MobeaModal
          confirmText={t("shared.ok")}
          title={t("shared.oops")}
          onConfirm={goHome}
          type="error"
        >
          <p>{t("mobit_order.already_purchased_error_text")}</p>
        </MobeaModal>
      );
    default:
      return <OrderError errorCode={errorCode} closeError={closeError} />;
  }
}
