import { MobeaModal } from "common/modal";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { ApiErrors } from "utils";

export interface OrderErrorProps {
  errorCode: string;
  clearTickets(): void;
}

export function NmbsTicketsError({
  errorCode,
  clearTickets,
}: OrderErrorProps): ReactElement {
  const { t } = useTranslation();

  switch (errorCode) {
    case ApiErrors.I_NMBS_NO_PRODUCT:
      return (
        <MobeaModal
          confirmText={t("shared.ok")}
          title={t("shared.oops")}
          onConfirm={clearTickets}
          type="error"
        >
          <p>{t("order_nmbs.calculate_price_no_product_text")}</p>
        </MobeaModal>
      );
    default:
      return (
        <MobeaModal
          confirmText={t("shared.ok")}
          title={t("shared.oops")}
          onConfirm={clearTickets}
          type="error"
        >
          <p>{t("order_nmbs.calculate_price_error_text")}</p>
        </MobeaModal>
      );
  }
}
