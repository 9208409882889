import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

export function CheckMarkIcon({
  className = "",
  fill = "currentColor",
  stroke = "transparent",
  height = "24px",
  width = "24px",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon
        fill={fill}
        stroke={stroke}
        points="11.2426407 12.8284271 15.4852814 8.58578644 16.8994949 10 11.2426407 15.6568542 7 11.4142136 8.41421356 10"
      />
    </svg>
  );
}
