import { ReactChild, ReactElement } from "react";
import { AppColors, AppFonts } from "utils/colors";
type Props = {
  icon: ReactElement;
  name: ReactElement;
  value: ReactChild;
};

export function Item({ icon, name, value }: Props) {
  return (
    <>
      <div
        css={{
          fontSize: "0.875rem",
          backgroundColor: "white",
          display: "flex",
          padding: "12px 32px",
          alignItems: "center",
        }}
      >
        <div
          css={{
            marginRight: 12,
            backgroundColor: AppColors.BACKGROUND,
            color: AppColors.GRAY_200,
            borderRadius: "100vw",
            width: 32,
            height: 32,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            "& > *": {
              width: 16,
              height: 16,
            },
          }}
        >
          {icon}
        </div>

        <div
          css={{
            flexGrow: 1,
            color: AppColors.GRAY_300,
          }}
        >
          {name}
        </div>

        <div css={{ fontFamily: AppFonts.SECONDARY }}>{value}</div>
      </div>
      <div
        css={{
          backgroundColor: AppColors.BACKGROUND,
          height: 1,
          marginLeft: 80,
          ":last-child": {
            display: "none",
          },
        }}
      />
    </>
  );
}
