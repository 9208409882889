import { DefaultRootState } from "react-redux";
import {
  MapActions,
  OnboardingActions,
  OrderingActions,
  TutorialActions,
  UserActions,
} from "./actions";
import { AppUiActions } from "./actions/AppUiActions";
import {
  expensesJSONSerializer,
  TravelPassActions,
} from "./actions/TravelPassActions";
import {
  AppUiState,
  MapState,
  OnboardingState,
  OrderingState,
  persistedAppUiKeys,
  persistedMapKeys,
  persistedOnboardingKeys,
  persistedOrderingKeys,
  persistedTravelPassKeys,
  persistedTutorialKeys,
  persistedUserKeys,
  TravelPassState,
  TutorialState,
  UserState,
} from "./reducers/stateTypes";

export const AUTOSAVED_STATE: {
  stateKey: keyof DefaultRootState;
  fields: string[];
  serializers: Record<string, (v: any) => unknown>;
}[] = [
  {
    stateKey: "onboarding",
    fields: persistedOnboardingKeys,
    serializers: {},
  },
  {
    stateKey: "user",
    fields: persistedUserKeys,
    serializers: {},
  },
  {
    stateKey: "passes",
    fields: persistedTravelPassKeys,
    serializers: {
      expenses: expensesJSONSerializer,
    },
  },
  {
    stateKey: "ordering",
    fields: persistedOrderingKeys,
    serializers: {},
  },
  {
    stateKey: "map",
    fields: persistedMapKeys,
    serializers: {},
  },
  {
    stateKey: "tutorial",
    fields: persistedTutorialKeys,
    serializers: {},
  },
  {
    stateKey: "appUi",
    fields: persistedAppUiKeys,
    serializers: {},
  },
];

declare module "react-redux" {
  export interface DefaultRootState {
    onboarding: OnboardingState;
    user: UserState;
    passes: TravelPassState;
    ordering: OrderingState;
    map: MapState;
    tutorial: TutorialState;
    appUi: AppUiState;
  }
}

export type Action =
  | MapActions
  | OnboardingActions
  | OrderingActions
  | TravelPassActions
  | TutorialActions
  | UserActions
  | AppUiActions;
