import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { hasPendingOrder } from "services/travelPassService";
import { TravelPassProvider } from "utils";

export function usePendingOrder(provider: TravelPassProvider) {
  const history = useHistory();

  const [pending, setPending] = useState(true);

  const [pollingId, setPollingId] = useState<number | null>(null);

  useEffect(() => {
    let timeoutId: number;

    let canceled = false;

    let pending = true;

    async function fetchPendingOrders() {
      try {
        const orderJson = await hasPendingOrder(provider, history);

        if (!orderJson.error) {
          pending = !!orderJson.pending;

          if (!canceled) {
            setPending(pending);

            if (pending) {
              timeoutId = window.setTimeout(() => {
                // trigger reruning the hook
                setPollingId(timeoutId);
              }, 5000);
            }
          }
        } else {
          if (!canceled) {
            pending = false;
            setPending(pending);
          }

          console.error(
            "Failed to update pending order status",
            orderJson.error_description
          );
        }
      } catch (error) {
        if (!canceled) {
          pending = false;
          setPending(pending);
        }

        console.error("Failed to update pending order status", error);
      }
    }

    fetchPendingOrders();

    return () => {
      canceled = true;

      if (timeoutId) {
        window.clearTimeout(timeoutId);
      }

      if (pending) {
        console.debug("Canceled hook usePendingOrders");
      }
    };
  }, [pollingId, history, provider]);

  return [pending] as [boolean];
}
