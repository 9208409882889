import { trackAddedToHomeScreen } from "analytics/events";
import { Spinner } from "common/Spinner";
import { ReactElement, useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { Auth } from "services/authTokenStore";
import { appLaunchTrackedAction, setCustomerIdAction } from "state/actions";
import {
  CUSTOMER_TOKEN_NAME,
  getSearchParams,
  LoginResultEnum,
  pushLocation,
  Routes,
  URL_TOKEN_NAME,
} from "utils";
import { getEnvironment } from "utils/install";
import { getRedirectRoute } from "utils/routing";

export function OnboardingGuardMoveasy(): ReactElement {
  const dispatch = useDispatch();

  const history = useHistory();

  const { tutorialWatched, appLaunchTracked } = useSelector(
    (state) => state.onboarding
  );

  const customerId = useSelector((state) => state.user.customerId);

  const passwordSet = useSelector((state) => state.user.passwordSet);

  const [loggingIn, setLoggingIn] = useState<
    ["customer", string] | ["token", string]
  >();

  const { search } = useLocation();

  const redirectRoute = getRedirectRoute();

  useEffect(() => {
    let canceled = false;

    if (loggingIn?.[0] === "token") {
      const smsToken = loggingIn[1];

      Auth.requestTokenWithCode(smsToken).then((result) => {
        if (canceled) {
          return;
        }

        if (result === LoginResultEnum.SUCCESS) {
          const customerId = Auth.getCustomerId();

          if (customerId) {
            batch(() => {
              dispatch(setCustomerIdAction(customerId));

              // redirect to Welcome as user is logged in now
              pushLocation(history, Routes.SetPassword);
            });
          } else {
            console.error(
              "Error customer id not found after logging in!",
              Auth["token"]
            );
          }
        } else {
          console.error(
            "Log in failed for the token",
            smsToken,
            LoginResultEnum[result]
          );

          history.replace(Routes.InvalidLoginToken);
        }
      });
    } else if (loggingIn?.[0] === "customer") {
      const customerId = loggingIn[1];

      Auth.requestTokens(customerId).then((result) => {
        if (canceled) {
          return;
        }

        if (result === LoginResultEnum.SUCCESS) {
          batch(() => {
            setLoggingIn(undefined);

            dispatch(setCustomerIdAction(customerId));

            // redirect to Home screen as user came with link from browser
            pushLocation(history, Routes.Home);
          });
        } else {
          console.error(
            "Log in failed for the customer id",
            customerId,
            LoginResultEnum[result]
          );

          pushLocation(history, redirectRoute);
        }
      });
    }

    return () => {
      canceled = true;
    };
  }, [dispatch, history, loggingIn, redirectRoute]);

  let spin = false;

  const queryParams = getSearchParams(search);

  if (loggingIn) {
    spin = true;

    // sms token passed in URL
  } else if (queryParams.has(URL_TOKEN_NAME)) {
    // skip logging in if token is still valid
    if (Auth.hasToken()) {
      spin = false;
    } else {
      setLoggingIn(["token", queryParams.get(URL_TOKEN_NAME)]);

      spin = true;
    }
  }

  // customer Id passed in sms
  else if (queryParams.has(CUSTOMER_TOKEN_NAME)) {
    setLoggingIn(["customer", queryParams.get(CUSTOMER_TOKEN_NAME)]);

    spin = true;
  }

  // missing customerId - redirect to verification
  else if (!customerId) {
    return <Redirect to={redirectRoute} />;
  }

  if (spin) {
    return <Spinner />;
  }

  // first visit of app should be tracked by analytics
  // must be done only after we handled special behavior so it does not conflict
  // cause infinite reloading loop if done before!!!
  if (getEnvironment() === "app" && !appLaunchTracked) {
    trackAddedToHomeScreen();

    dispatch(appLaunchTrackedAction());
  }

  // redirect to log in if token is expired or not present
  // happens while testing
  if (!Auth.hasToken()) {
    return <Redirect to={getRedirectRoute()} />;
  }

  return !passwordSet ? (
    <Redirect to={Routes.SetPassword} />
  ) : !tutorialWatched ? (
    <Redirect to={Routes.Tutorial} />
  ) : (
    <Redirect to={Routes.Home} />
  );
}
