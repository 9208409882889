import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getTicketDetail } from "services/travelPassService";
import { cancelVertsRide } from "services/vertsService";
import { VertsTravelPass } from "state/actions";
import { normalizeVertsTravelPass } from "utils/normalizers/vertsNormalizer";

export enum VertsCancelError {
  None = "",
  Unknown = "unknown",
}

export function useVertsCancel() {
  const history = useHistory();

  const { providers } = useSelector((state) => state.passes);

  const [canceling, setCanceling] = useState(false);

  const [result, setResult] = useState<VertsTravelPass | null>(null);

  const [error, setError] = useState(VertsCancelError.None);

  const cancel = async (rideId: string) => {
    try {
      setCanceling(true);

      setError(VertsCancelError.None);

      const response = await cancelVertsRide(rideId, history);

      if (response.error) {
        setError(response.error_code as VertsCancelError);

        console.error(
          `Failed to cancel Verts drive: ${response.error_code} - ${response.error_description}`
        );
      } else {
        const ticketResponse = await getTicketDetail(rideId, history);

        if (ticketResponse.error) {
          setError(VertsCancelError.Unknown);

          console.error(
            `Failed to cancel Verts ticket: ${ticketResponse.error_code} - ${ticketResponse.error_description}`
          );
        } else {
          const normalizedTicket = normalizeVertsTravelPass(
            ticketResponse,
            providers
          );

          setResult(normalizedTicket);
        }
      }
    } catch (error) {
      console.error("Failed to cancel Verts ride", error);

      setError(VertsCancelError.Unknown);
    } finally {
      setCanceling(false);
    }
  };

  return [cancel, canceling, result, error] as const;
}
