import { UpIcon } from "icons/UpIcon";
import {
  CSSProperties,
  MouseEvent,
  ReactElement,
  ReactNode,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { CSSTransition } from "react-transition-group";
import "./MobeaDropdown.scss";

export interface MobeaDropdownProps {
  direction?: "up" | "down";
  options: string[];
  value: string;
  placeholder?: ReactNode;
  className?: string;
  optionHeight?: number;
  maxOptionsHeight?: number;
  disabled?: boolean;
  onChange?(value: string): void;
  onOpen?(): void;
  onClose?(): void;
  optionTransformer?(value: string): ReactNode;
  valueTransformer?(value: string): ReactNode;
  showArrow?: boolean;
  icon?: ReactNode;
}

export function MobeaDropdown({
  options,
  value,
  direction = "down",
  disabled = false,
  className = "",
  optionHeight = 38,
  maxOptionsHeight = 190,
  placeholder = "",
  onOpen,
  onClose,
  onChange,
  optionTransformer = (value) => value,
  valueTransformer = (value) => value,
  showArrow,
  icon,
}: MobeaDropdownProps): ReactElement {
  const [opened, setOpened] = useState(false);

  const [visibleItemsHeight, setVisibleItemsHeight] =
    useState(maxOptionsHeight);

  const selectRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (selectRef && selectRef.current) {
      try {
        const screenHeight = window.screen.availHeight;

        const boundingBox = selectRef.current.getBoundingClientRect();

        let maxHeight = screenHeight - boundingBox.bottom;

        if (direction === "up") {
          maxHeight = boundingBox.top;
        }

        const maxItems = Math.floor(maxHeight / optionHeight);

        setVisibleItemsHeight(maxItems * optionHeight);
      } catch (e) {
        console.warn("Cannot set max height of the dropdown", e);
      }
    }
  }, [direction, optionHeight]);

  const open = () => {
    onOpen && onOpen();

    !disabled && setOpened(true);
  };

  const close = () => {
    onClose && onClose();

    !disabled && setOpened(false);
  };

  const toggle = () => {
    opened ? close() : open();
  };

  const selectOption = (e: MouseEvent, value: string) => {
    e.preventDefault();

    e.stopPropagation();

    close();

    onChange?.(value);
  };

  const singleOptionHeightPx = `${optionHeight}px`;

  const optionsHeight = optionHeight * options.length;

  const optionsHeightPx = `${optionsHeight}px`;

  const optionsStyle: CSSProperties = {
    height: opened ? optionsHeightPx : 0,
    maxHeight: `${visibleItemsHeight}px`,
  };

  const scrollOptions = maxOptionsHeight < optionsHeight;

  return (
    <div
      className={`${className} mobea__dropdown mobea__direction__${direction} ${
        disabled ? "mobea__disabled" : ""
      }`}
      ref={selectRef}
      onClick={toggle}
    >
      {opened && (
        <div className="mobea__dropdown__overlay" onClick={close}></div>
      )}

      <div className="mobea__dropdown__select">
        {icon}

        <span className="mobea__dropdown__select__value">
          {value && valueTransformer(value)}
          {!value && (
            <span className="mobea__dropdown__select__placeholder">
              {placeholder}
            </span>
          )}
        </span>

        {showArrow && <UpIcon className="mobea__dropdown__select__arrow" />}
      </div>

      <CSSTransition in={opened} timeout={300} classNames="dropdown">
        <ul
          className={`mobea__dropdown__options ${
            scrollOptions ? "mobea__dropdown__scroll" : ""
          }`}
          style={optionsStyle}
        >
          {options.map((optionValue) => (
            <li
              key={optionValue}
              className={`mobea__dropdown__option ${
                optionValue === value ? "mobea__dropdown__option__current" : ""
              }`}
              value={optionValue}
              style={{ height: singleOptionHeightPx }}
              onClick={(e) => selectOption(e, optionValue)}
            >
              {optionTransformer(optionValue)}
            </li>
          ))}
        </ul>
      </CSSTransition>
    </div>
  );
}
