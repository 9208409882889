import { Action } from "state";
import { getInitialStateWithPersistance } from "utils";
import { AppUiState } from "./stateTypes";

const initialState: AppUiState = {
  keyboardOpened: false,
};

export function AppUiReducer(
  state: AppUiState = getInitialStateWithPersistance("appUi", initialState),
  action: Action
): AppUiState {
  switch (action.type) {
    case "KEYBOARD_TOGGLED":
      return {
        ...state,
        keyboardOpened: action.opened,
      };
    default:
      return state;
  }
}
