import { OrderFooter } from "common/order/OrderFooter";
import { Spinner } from "common/Spinner";
import { ProviderLogo } from "common/travelPasses";
import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { TravelPassProvider } from "utils";
import { AppColors } from "utils/colors";
import { NestedPageFull } from "../NestedPageFull";

interface SimpleOrderPageProps {
  provider: TravelPassProvider;
  title: ReactNode;
  ticketName: ReactNode;
  ticketDescription: ReactNode;
  formattedPrice: string;
  orderInProgress: boolean;
  orderButtonLabel: string;
  ready: boolean;
  headerInSecondaryColor?: boolean;
  logo?: ReactNode;
  minPasses?: number;
  maxPasses?: number;
  amount?: number;
  children?: ReactNode;
  onOrder: () => void;
  onAmountChange: (amount: number) => void;
}

export function SimpleOrderPage({
  provider,
  title,
  headerInSecondaryColor = false,
  logo,
  ready,
  ticketName,
  ticketDescription,
  formattedPrice,
  orderInProgress,
  orderButtonLabel,
  minPasses,
  maxPasses,
  amount,
  children,
  onOrder,
  onAmountChange,
}: SimpleOrderPageProps) {
  const travelProvider = useSelector((state) => state.passes.providers).find(
    (travelProvider) => travelProvider.provider == provider
  );

  return (
    <NestedPageFull
      pageClassName="mobea-velo-order"
      title={title}
      headerHeight="minimal"
      headerInSecondaryColor={headerInSecondaryColor}
      css={{
        ".m_page__content": {
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <section
        className="logo"
        css={{
          width: 64,
          textAlign: "center",
          margin: "32px auto 20px",
        }}
      >
        {logo ? logo : <ProviderLogo provider={provider} />}
      </section>

      {!ready ? (
        <Spinner />
      ) : (
        <section
          css={{
            flex: "1 1 auto",
            backgroundColor: AppColors.BACKGROUND,
            margin: "0 -24px -32px -32px",
            padding: "0 32px 32px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            css={{
              flex: "1 1 auto",
            }}
          >
            <h2
              css={{
                color: travelProvider?.textColor,
                fontSize: "2rem",
                fontWeight: 500,
                lineHeight: "2.5rem",
                textAlign: "center",
                marginBottom: 16,
              }}
            >
              {ticketName}
            </h2>

            <p
              css={{
                textAlign: "center",
                color: AppColors.GRAY_300,
                fontSize: "1rem",
                lineHeight: 1.5,
                margin: 0,
                strong: {
                  color: AppColors.GRAY_500,
                },
              }}
            >
              {ticketDescription}
            </p>
          </div>

          <OrderFooter
            formattedPrice={formattedPrice}
            disabled={orderInProgress}
            pending={orderInProgress}
            buttonLabel={orderButtonLabel}
            minAmount={minPasses}
            maxAmount={maxPasses}
            amount={amount}
            onOrder={onOrder}
            onAmountChange={onAmountChange}
          />
        </section>
      )}
      {children}
    </NestedPageFull>
  );
}
