import { MultiTicketDetail } from "common/travelPasses/MultiTicketDetail";
import { VeloTravelPass } from "common/travelPasses/velo/VeloTravelPass";
import { VeloTutorial } from "pages/velo/detail/VeloTutorial";
import { ReactElement } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import {
  TravelPass,
  VeloTravelPass as VeloTravelPassType,
} from "state/actions";
import { getSearchParams } from "utils";
import { BackButtonBehaviour } from "utils/routing";

type Props = {
  backButtonBehaviour?: BackButtonBehaviour;
};

export function VeloMultiTicketDetail({
  backButtonBehaviour = "goHome",
}: Props): ReactElement {
  const location = useLocation();

  const queryParams = getSearchParams(location.search);

  const showTutorial = Boolean(queryParams.get("showTutorial"));

  const locale = useSelector((state) => state.user.language);

  const toPage = (pass: TravelPass, refresh: () => void) => (
    <VeloTravelPass
      key={pass.id}
      {...(pass as VeloTravelPassType)}
      locale={locale}
      refresh={refresh}
    />
  );

  return (
    <MultiTicketDetail
      backButtonBehaviour={backButtonBehaviour}
      toPage={toPage}
      info={<VeloTutorial forceShow={showTutorial} />}
    />
  );
}
