import { PhonePrefix } from "./constants";

export function formatPhoneNumber(phonePrefix: string, phoneNumber: string) {
  let formatted = `${phonePrefix} ${phoneNumber.substring(0, 3)}`;

  for (let index = 3; index < phoneNumber.length; index += 2) {
    formatted += ` ${phoneNumber.substring(index, index + 2)}`;
  }

  return formatted;
}

export function formatPhoneNumberWithoutPrefix(value: string) {
  const formatted = Array.from(value).flatMap((number, index) => {
    // do not add space as last char & allows deleting!
    if (index === value.length - 1) {
      return [number];
    } else if ([2, 4, 6, 8, 10, 13].includes(index)) {
      return [number, " "];
    } else {
      return [number];
    }
  });

  return formatted.join("");
}

export function generatePlaceholder(length: number) {
  return "12345678901234".substring(0, length);
}

export function validateNumber(number: string, prefix: PhonePrefix) {
  return new RegExp(`[0-9]{${prefix.minLength},12}`).test(number);
}
