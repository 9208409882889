import { DeLijnLogo } from "icons/logos/DeLijnLogo";
import { CommonTileProps } from "pages/providers/tiles";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Routes, TravelPassProvider } from "utils";
import { useCheckEnoughBudget } from "../dialogs/useInsufficientBudgetDialog";
import { ProviderTile, PublicProviderTileProps } from "./ProviderTile";

export function DeLijnTile(
  props: PublicProviderTileProps & CommonTileProps
): ReactElement {
  const { t } = useTranslation();

  const checkDeposit = useCheckEnoughBudget(
    props.provider,
    props.minPrice || 0
  );

  return (
    <ProviderTile
      {...props}
      kind={t("data.bus,tram")}
      to={
        props.type === "expense"
          ? Routes.ExpenseAdd.replace(":provider", TravelPassProvider.delijn)
          : Routes.DeLijnOrder
      }
      beforeBuy={checkDeposit}
    >
      <DeLijnLogo width="32px" height="31px" />
    </ProviderTile>
  );
}
