import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { openBike } from "services/mobitService";

export function useMobitOpenBike(bikeCode: { code: string } | null) {
  const history = useHistory();

  const [result, setResult] = useState<{ ok: true } | null>(null);

  const [failed, setFailed] = useState(false);

  const [loading, setLoading] = useState(false);

  const [failureCode, setFailureCode] = useState("");

  useEffect(() => {
    let canceled = false;
    let isLoading = false;

    async function unlockBike(code: string) {
      try {
        setLoading(true);
        isLoading = true;

        setFailureCode("");

        setFailed(false);

        const response = await openBike(code, history);

        if (!response.error) {
          if (!canceled) {
            setResult({ ok: true });

            setLoading(false);
          }
        } else {
          if (!canceled) {
            setFailed(true);

            setLoading(false);

            setFailureCode(response.error_code);
          }

          console.error(
            "Failed to open Mobit bike: ",
            response.error_description
          );
        }
      } catch (error) {
        if (!canceled) {
          setFailed(true);

          setLoading(false);
        }
        console.error("Failed to open Mobit bike", error);
      } finally {
        isLoading = false;
      }
    }

    bikeCode !== null && unlockBike(bikeCode.code);

    return () => {
      canceled = true;

      isLoading && console.debug("Canceled hook useMobitOpenBike");
    };
  }, [bikeCode, history]);

  return [result, failed, loading, failureCode] as const;
}
