import { DefaultRootState } from "react-redux";
import { LOCAL_STORAGE_KEY } from "utils/constants";
import { AUTOSAVED_STATE } from "./index";
import { store } from "./store";

let pausedPersistance = false;

let lastStateSnapshot: Record<string, any> | null = null;

const defaultSerializer = (value) => value;

const autoSaveFieldsChanged = (state: DefaultRootState) => {
  const snapshot = lastStateSnapshot || {};

  const changed = AUTOSAVED_STATE.some((definition) => {
    const partialState = state[definition.stateKey];

    const partialSnapshot = snapshot[definition.stateKey];

    return definition.fields.some((field) => {
      const serializer = definition.serializers[field] || defaultSerializer;

      const snapshotValue = serializer(partialSnapshot[field]);

      const stateFieldValue = serializer(partialState[field]);

      return snapshotValue !== stateFieldValue;
    });
  });

  return changed;
};

const takeSnapshot = (state: DefaultRootState) =>
  AUTOSAVED_STATE.reduce((accumulator, definition) => {
    const partialState = state[definition.stateKey];

    accumulator[definition.stateKey] = definition.fields.reduce(
      (aggregator, field) => {
        const serializer = definition.serializers[field] || defaultSerializer;

        aggregator[field] = serializer(partialState[field]);

        return aggregator;
      },
      {}
    );

    return accumulator;
  }, {});

const saveSnapshot = (state: DefaultRootState) => {
  lastStateSnapshot = takeSnapshot(state);
};

const saveEditorData = () => {
  let success = true;

  try {
    window.localStorage.setItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify(lastStateSnapshot)
    );
  } catch (domException: any) {
    success = false;

    if (
      domException.name === "QuotaExceededError" ||
      domException.name === "NS_ERROR_DOM_QUOTA_REACHED"
    ) {
      // @TODO: how to recover from out of storage
    }
  }

  return success;
};

export function pausePersistance() {
  pausedPersistance = true;
}

export function resumePersistance() {
  pausedPersistance = false;
}

export function autoSaveEditor() {
  const state = store.getState();

  // take snapshot if not existing
  if (lastStateSnapshot === null || autoSaveFieldsChanged(state)) {
    saveSnapshot(state);
    !pausedPersistance && saveEditorData();
  }
}
