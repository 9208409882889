import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getVeloData } from "services/veloService";
import { setVeloDataAction } from "state/actions";

export function useVeloData(skip = false) {
  const history = useHistory();

  const dispatch = useDispatch();

  const { loaded } = useSelector((state) => state.ordering.veloData);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let canceled = false;

    const fetchVeloData = async () => {
      try {
        setLoading(true);

        const orderJson = await getVeloData(history);

        if (!orderJson.error) {
          const data = {
            minPasses: parseInt(orderJson.min_passes_bought),
            maxPasses: parseInt(orderJson.max_passes_bought),
            price: parseFloat(orderJson.day_pass_price),
            duration: parseInt(orderJson.free_riding_period_minutes),
            validity: parseInt(orderJson.validity_period_hours),
            loaded: true,
          };

          if (!canceled) {
            dispatch(setVeloDataAction(data));

            setLoading(false);
          }
        } else {
          if (!canceled) {
            setLoading(false);
          }

          console.error(
            "Failed to fetch Velo data",
            orderJson.error_description
          );
        }
      } catch (error) {
        if (!canceled) {
          setLoading(false);
        }

        console.error("Failed to fetch Velo data", error);
      }
    };

    // allow to ignore this call - e.g. ticket preview
    if (!loaded && !skip) {
      fetchVeloData();
    }

    return () => {
      canceled = true;

      console.debug("Canceled hook fetchVeloData");
    };
  }, [dispatch, history, loaded, skip]);

  return [loading] as [boolean];
}
