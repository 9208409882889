import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

export function EuroIcon({
  className = "",
  fill = "currentColor",
  height = "24px",
  width = "24px",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 -1 18 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 12.33A4.32 4.32 0 016.16 10H10l.67-1.33H5.72a4.42 4.42 0 010-1.34H10L10.67 6H6.16a4.33 4.33 0 016.66-1.29L14 3.53A5.97 5.97 0 004.35 6H2l-.67 1.33h2.71a5.5 5.5 0 000 1.34H2L1.33 10h3.02A6 6 0 0014 12.47l-1.19-1.18A4.27 4.27 0 0110 12.33z"
        fillRule="nonzero"
        fill={fill}
      />
    </svg>
  );
}
