import { RefObject, useEffect, useRef } from "react";
import { NullableLocationCoords } from "services/mapService";

const DEFAULT_ZOOM_LEVEL = 14;

export function useCenteredMap(
  center: NullableLocationCoords | null,
  mapClassRef: RefObject<H.Map>,
  zoomLevel = DEFAULT_ZOOM_LEVEL,
  followOnMap = true
) {
  const initializedRef = useRef(false);

  useEffect(() => {
    if (center && center.lat !== null && center.lng !== null) {
      if (initializedRef.current && !followOnMap) {
        return;
      }

      mapClassRef.current?.setCenter(
        {
          lat: center.lat,
          lng: center.lng,
        },
        true
      );

      mapClassRef.current?.setZoom(zoomLevel, true);

      initializedRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center]);

  return [];
}
