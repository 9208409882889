import { MobeaButton } from "common/forms";
import { Steps } from "common/steps/Steps";
import { ReactElement, ReactNode, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDrag } from "react-use-gesture";
import "./TutorialDialog.scss";

export interface TutorialProps {
  steps: {
    image: ReactNode;
    headline: ReactNode;
    info: ReactNode;
  }[];
  endButtonText?: string;
  permanentSkip?: boolean;
  onClose?(): void;
  onStep?(step: number): void;
}

export function Tutorial({
  steps,
  endButtonText,
  permanentSkip = false,
  onClose = () => undefined,
  onStep,
}: TutorialProps): ReactElement {
  const { t } = useTranslation();

  const [currentStep, setCurrentStep] = useState(0);

  const previousStep = () => {
    setCurrentStep(currentStep - 1);
    onStep?.(currentStep - 1);
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
    onStep?.(currentStep + 1);
  };

  const totalSteps = steps.length;

  const isFirstStep = currentStep === 0;

  const isLastStep = currentStep === steps.length - 1;

  const touchBinding = useDrag(
    ({ last, distance, axis, direction: [xDir] }) => {
      if (last && axis === "x" && distance > 50) {
        if (xDir > 0) {
          // from left - prev
          !isFirstStep && previousStep();
        } else {
          // from right - next
          !isLastStep && nextStep();
        }
      }
    }
  );

  const endText = endButtonText || t("shared.ok");

  const stepWidth = 100 / steps.length;

  const totalWidth = `${100 * steps.length}%`;

  const marginLeft = `${-currentStep * 100}%`;

  const StepsList = useMemo(
    () =>
      steps.map((step, index) => {
        const { info, image, headline } = step;

        return (
          <div
            className="mobea__tutorial-dialog__step"
            key={index}
            style={{ width: `${stepWidth}%` }}
          >
            <section className="mobea__tutorial-dialog__image">{image}</section>
            <Steps currentStep={index} stepCount={totalSteps} />
            <h2 className="mobea__tutorial-dialog__title"> {headline}</h2>
            <div className="mobea__tutorial-dialog__info">{info}</div>
          </div>
        );
      }),
    [stepWidth, steps, totalSteps]
  );

  return (
    <div className="mobea__tutorial-dialog" {...touchBinding()}>
      <div
        className="mobea__tutorial-dialog__steps"
        style={{ width: totalWidth, marginLeft }}
      >
        {StepsList}
      </div>

      <section className="mobea__tutorial-dialog__actions">
        <MobeaButton
          className="mobea__tutorial-dialog__actions__skip"
          type="tertiary"
          onClick={permanentSkip || isFirstStep ? onClose : previousStep}
        >
          {permanentSkip || isFirstStep ? t("tutorial.skip") : t("shared.back")}
        </MobeaButton>

        <MobeaButton type="tertiary" onClick={isLastStep ? onClose : nextStep}>
          {isLastStep ? endText : t("tutorial.next")}
        </MobeaButton>
      </section>
    </div>
  );
}
