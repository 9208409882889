import { useAppName } from "common/hooks/useAppName";
import { useTutorialSuspender } from "common/tutorial2/Tutorial";
import { AppLogoSmall } from "icons/AppLogo";
import { IosAddIcon } from "icons/IosAddIcon";
import { ReactElement, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { getConfig } from "utils/configure";
import { addInstallHooksCallback, mobea } from "utils/install";
import "./IosInstallOverlay.scss";

export interface IosInstallOverlayProps {
  onCancel(): void;
}

export function IosInstallOverlay({
  onCancel,
}: IosInstallOverlayProps): ReactElement | null {
  const { t } = useTranslation();

  const [opened, setOpened] = useState(true);

  const [insideApp, setInsideApp] = useState(mobea.isApp);

  const appName = useAppName();

  const { iconBase64 } = getConfig();

  // run just once
  useEffect(() => {
    addInstallHooksCallback((mobea) => {
      setOpened(!mobea.isApp);

      setInsideApp(mobea.isApp);
    });
  }, []);

  const closeInstallModal = () => {
    onCancel();

    setOpened(false);
  };

  const prevent = insideApp || !opened;

  useTutorialSuspender("ios-install", !prevent);

  return prevent /* do not show overlay inside PWA or once we close it */ ? null : (
    <div className="mobea__ios-install__overlay">
      <section className="mobea__ios-install">
        <header className="mobea__ios-install__header">
          <div className="mobea__ios-install__header__logo">
            {iconBase64 ? (
              <img src={iconBase64} alt="app icon" />
            ) : (
              <AppLogoSmall />
            )}
          </div>
          <h2 className="mobea__ios-install__header__text">
            {t("install.install_mobility_budget", { appName })}
          </h2>
          <button
            className="mobea__ios-install__close"
            onClick={closeInstallModal}
          >
            &nbsp;
          </button>
        </header>

        <div className="mobea__ios-install__content">
          <p>{t("install.install_to_home_screen_text")}</p>
          <p>
            {mobea.isSafari && (
              <Trans i18nKey="install.ios_how_to_install_text">
                Just tap on this icon below&nbsp;
                <IosAddIcon />, and then select “Add to Home Screen”
              </Trans>
            )}
            {!mobea.isSafari &&
              t("install.ios_others_how_to_install_text", {
                appName,
              })}
          </p>
        </div>
      </section>
    </div>
  );
}
