import { CheckboxGroup, MobeaButton } from "common/forms";
import { useTutorialSuspender } from "common/tutorial2/Tutorial";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setTermsOrPrivacyAccepted } from "services/customerService";
import { termsAndPrivacyAcceptedAction } from "state/actions";
import { TermsOrPrivacy } from "utils";
import { AppColors } from "utils/colors";
import { getPrivacyLink, getTermsAndConditionsLink } from "utils/intl";
import { CustomMobeaModal } from "..";
import { useContactUsModal } from "../ContactUs/ContactUsModal";

export function AcceptConditionsModal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const locale = useSelector((state) => state.user.language);
  const { privacyAccepted, termsAccepted } = useSelector(
    (state) => state.onboarding
  );

  const [termsChecked, setTermsChecked] = useState(false);
  const [privacyChecked, setPrivacyChecked] = useState(false);

  const { show: openContactUs } = useContactUsModal();

  const shouldOpen = !privacyAccepted || !termsAccepted;

  useTutorialSuspender("termsAndPrivacy", shouldOpen);

  const saveAndContinue = () => {
    Promise.all([
      setTermsOrPrivacyAccepted(TermsOrPrivacy.TERMS, history),
      setTermsOrPrivacyAccepted(TermsOrPrivacy.PRIVACY, history),
    ])
      .then(([termsResult, privacyResult]) => {
        if (termsResult.error || privacyResult.error) {
          console.warn(
            "Failed to push privacy and terms checked to the server",
            termsResult,
            privacyResult
          );
        }
      })
      .catch((error) => {
        console.warn(
          "Failed to push privacy and terms checked to the server",
          error
        );
      })
      .finally(() => {
        dispatch(termsAndPrivacyAcceptedAction(true));
        console.debug("Set privacy and terms as checked");
      });
  };

  return shouldOpen ? (
    <CustomMobeaModal
      title={t("accept_conditions.before_accessing")}
      type="secondary"
      withHeader
      customStyle={{
        ".mobea__modal__dialog": {
          paddingTop: 24,
          paddingBottom: 24,
          "&__header": {
            paddingBottom: 16,
            "&__headline": {
              lineHeight: 1.4545,
            },
          },
        },
      }}
    >
      <p
        css={{
          fontSize: "0.9375rem",
          lineHeight: "1.5rem",
          padding: 0,
          margin: "0 0 24px",
        }}
      >
        {t("accept_conditions.text")}
      </p>
      <footer
        css={{
          marginLeft: -32,
          marginRight: -32,
          marginBottom: -24,
          padding: "20px 32px 24px",
          backgroundColor: AppColors.BACKGROUND,
          borderTop: `1px solid ${AppColors.GRAY_100}`,
        }}
      >
        <div
          css={{
            marginBottom: 16,
          }}
        >
          <CheckboxGroup
            name="terms"
            label={
              <Trans i18nKey="accept_conditions.terms_label">
                <a
                  href={getTermsAndConditionsLink(locale)}
                  target="_blank"
                  rel="noreferrer"
                ></a>
              </Trans>
            }
            isChecked={termsChecked}
            onChange={setTermsChecked}
            css={{
              marginBottom: 12,
            }}
          />
          <CheckboxGroup
            name="privacy"
            label={
              <Trans i18nKey="accept_conditions.privacy_label">
                <a
                  href={getPrivacyLink(locale)}
                  target="_blank"
                  rel="noreferrer"
                ></a>
              </Trans>
            }
            isChecked={privacyChecked}
            onChange={setPrivacyChecked}
          />
        </div>
        <MobeaButton
          disabled={!termsChecked || !privacyChecked}
          onClick={saveAndContinue}
          css={{
            marginBottom: 8,
          }}
        >
          {t("shared.continue")}
        </MobeaButton>
        <MobeaButton type="secondary" onClick={openContactUs}>
          {t("contact_us.need_help")}
        </MobeaButton>
      </footer>
    </CustomMobeaModal>
  ) : null;
}
