import { ReactElement, ReactNode } from "react";
import "./MobitInfoItem.scss";

export interface MobitInfoItemProps {
  label: ReactNode;
  value: ReactNode;
  className?: string;
}

export function MobitInfoItem({
  label,
  value,
  className = "",
}: MobitInfoItemProps): ReactElement {
  return (
    <p
      className={`mobea__mobit-ticket__info__item mobea__mobit-ticket__info__item__${className}`}
    >
      <span className="mobea__mobit-ticket__info__item__label">{label}</span>
      <span className="mobea__mobit-ticket__info__item__value">{value}</span>
    </p>
  );
}
