import { MobeaButton } from "common/forms";
import { useBodyNoScroll } from "common/hooks";
import { ReactElement, ReactNode } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { ApplicationVariant, MODAL_ROOT_ELEMENT } from "utils";
import { applicationVariant } from "utils/configure";
import "./MobeaModal.scss";

export type MobeaModalType = "info" | "error" | "secondary";

export type MobeaModalProps = {
  title?: ReactNode;
  className?: string;
  type?: MobeaModalType;
  image?: ReactNode;
  onClose?(): void;
  usePortal?: boolean;
  children: ReactNode;
  visible?: boolean;
} & (
  | {
      confirmDisabled?: boolean;
      confirmText: ReactNode;
      onConfirm?(): void;
      confirmButton?: undefined;
    }
  | {
      confirmDisabled?: undefined;
      confirmText?: undefined;
      onConfirm?: undefined;
      confirmButton: ReactNode;
    }
  | {
      confirmDisabled?: undefined;
      confirmText?: undefined;
      onConfirm?: undefined;
      confirmButton?: undefined;
    }
) &
  (
    | {
        cancelButton?: ReactNode;
        cancelButtonType?: undefined;
        onCancel?: undefined;
        cancelText?: undefined;
      }
    | {
        cancelButton?: undefined;
        cancelButtonType?: "primary" | "secondary" | "tertiary";
        onCancel?(): void;
        cancelText?: ReactNode;
      }
  );

export function MobeaModal({
  title,
  image,
  children,
  confirmText,
  className = "",
  type = "info",
  confirmDisabled = false,
  confirmButton,
  onConfirm,
  onClose,
  cancelButton,
  cancelButtonType = "secondary",
  onCancel,
  cancelText,
  usePortal = false,
  visible = true,
}: MobeaModalProps): ReactElement {
  const { t } = useTranslation();

  useBodyNoScroll(visible);

  const renderModal = () => (
    <div
      className={`mobea__modal mobea__overlay mobea__expanded ${className}`}
      css={
        !visible
          ? {
              opacity: "0 !important",
              pointerEvents: "none",
              "& > .mobea__modal__dialog": {
                transform: "scale(0) !important",
              },
            }
          : undefined
      }
    >
      <section
        className={`mobea__modal__dialog mobea__${type} ${
          onClose ? "mobea__modal__dialog__closeable" : ""
        }`}
        css={{
          borderRadius:
            applicationVariant === ApplicationVariant.MOVEASY ? 4 : 0,
        }}
      >
        {title && (
          <header className="mobea__modal__dialog__header">
            <h2 className="mobea__modal__dialog__header__headline">{title}</h2>

            {onClose && (
              <button
                className="mobea__modal__dialog__header__close mobea__close-button"
                onClick={onClose}
              >
                &nbsp;
              </button>
            )}
          </header>
        )}

        {image && <div className="mobea__modal__dialog__image">{image}</div>}

        <div className="mobea__modal__dialog__content">{children}</div>

        <div className="mobea__modal__dialog__footer">
          {confirmButton ||
            (onConfirm && confirmText && (
              <MobeaButton onClick={onConfirm} disabled={confirmDisabled}>
                {confirmText}
              </MobeaButton>
            ))}
          {cancelButton ||
            (onCancel && (
              <MobeaButton type={cancelButtonType} onClick={onCancel}>
                {cancelText || t("shared.cancel")}
              </MobeaButton>
            ))}
        </div>
      </section>
    </div>
  );

  return usePortal && MODAL_ROOT_ELEMENT
    ? ReactDOM.createPortal(renderModal(), MODAL_ROOT_ELEMENT)
    : renderModal();
}
