import { RawTravelPass } from "services/travelPassService";
import {
  MobitTicketStatus,
  MobitTravelPass,
} from "state/actions/TravelPassActions";
import { addTimezoneToServerTime, parseFloatWithComma } from "utils";
import { TravelPassProvider, TravelPassType } from "../constants";

export function normalizeMobitTravelPass(
  ticketData: RawTravelPass
): MobitTravelPass {
  const orderTimestamp = new Date(ticketData.created).valueOf();

  const expiration = addTimezoneToServerTime(ticketData.expire_at).valueOf();

  const duration = Math.ceil((expiration - orderTimestamp) / (3600 * 1000));

  // server set values to null now
  const {
    ride_status,
    activation_datetime,
    bike_code = "",
    riding_id = "",
    fine = "0.00",
  } = ticketData.mobit_detail || {};

  const activation = addTimezoneToServerTime(
    activation_datetime || ticketData.created
  ).valueOf();

  return {
    id: ticketData.id,
    orderTimestamp,
    expiration,
    activation,
    duration,
    status: ride_status
      ? MobitTicketStatus[ride_status]
      : MobitTicketStatus.NOT_USED,
    bikeCode: bike_code || "",
    rideId: (riding_id || "").toString(),
    price: parseFloatWithComma(ticketData.amount) || 0,
    fine: parseFloatWithComma(fine),
    types: [TravelPassType.Bike],
    provider: TravelPassProvider.mobit,
    plan: ticketData.plan,
  };
}
