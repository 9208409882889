import { MobeaModal } from "common/modal";
import { FileAttachment } from "common/types";
import { FileIcon } from "icons/FileIcon";
import { UploadIcon } from "icons/UploadIcon";
import { ChangeEvent, ReactElement, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ATTACHMENT_SIZE_LIMIT } from "utils";
import "./FileUploader.scss";

export interface FileUploaderProps {
  filesToUpload: FileAttachment[];
  supportedFilesReqExp?: RegExp;
  filesTypeErrorKey: string;
  supportedFilesTextKey: string;
  onFilesToUploadChange: (filesToUpload: FileAttachment[]) => void;
  maxFileCount?: number;
}

export function FileUploader({
  filesToUpload,
  supportedFilesReqExp = /^image\/|^application\/pdf$/,
  filesTypeErrorKey,
  supportedFilesTextKey,

  onFilesToUploadChange,
  maxFileCount = 2,
}: FileUploaderProps): ReactElement {
  const { t } = useTranslation();

  const [invalidFileType, setInvalidFileType] = useState(false);

  const [invalidFileSizeError, setInvalidFileSize] = useState(false);

  const addFileToUpload = (fileMeta: FileAttachment) => {
    onFilesToUploadChange(filesToUpload.concat(fileMeta));
  };

  const removeFileToUpload = (fileName: string, index: number) => {
    onFilesToUploadChange(
      filesToUpload.filter((file, fileIndex) => {
        if (fileIndex === index) {
          if (file.name !== fileName) {
            console.warn(
              "Files does not look to match by name",
              fileName,
              file.name
            );
          }

          return false;
        }

        return true;
      })
    );
  };

  const onImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files || [];

    // do not allow multiupload so pick just first one
    const file = files[0];

    if (file) {
      if (file.size >= ATTACHMENT_SIZE_LIMIT) {
        setInvalidFileSize(true);
      } else if (supportedFilesReqExp.test(file.type)) {
        const reader = new window.FileReader();

        reader.onload = (e) => {
          addFileToUpload({
            name: file.name,
            path: e.target ? (e.target.result as string) : undefined,
            file,
          });
        };

        reader.readAsDataURL(file);
      } else {
        setInvalidFileType(true);
      }
    }
  };

  const closeFileTypeError = () => setInvalidFileType(false);

  const closeFileSizeError = () => setInvalidFileSize(false);

  return (
    <div className="mobea__uploader__previews">
      {filesToUpload.map((file, index) => (
        <div key={file.name} className="mobea__uploader__previews__preview">
          <div className="mobea__uploader__previews__preview__spacer">
            &nbsp;
          </div>

          <img
            src={file.path}
            alt={file.name}
            onError={(e) =>
              ((e.target as HTMLImageElement).className = "mobea__image__error")
            }
          />

          <FileIcon />

          <p className="mobea__uploader__previews__preview__name">
            <span>
              {file.name.substring(0, file.name.lastIndexOf(".")) || ""}
            </span>
            <span>{file.name.substring(file.name.lastIndexOf(".")) || ""}</span>
          </p>

          <div
            className="mobea__uploader__previews__preview__remove mobea__close-button"
            onClick={() => removeFileToUpload(file.name, index)}
          >
            &nbsp;
          </div>
        </div>
      ))}

      {filesToUpload.length > 0 && filesToUpload.length < maxFileCount && (
        <div className="mobea__uploader__previews__preview mobea__uploader__previews__preview__upload">
          <div className="mobea__uploader__previews__preview__spacer">
            &nbsp;
          </div>

          <div className="mobea__uploader__upload__icon">
            <UploadIcon />
          </div>

          <input
            type="file"
            className="mobea__uploader__upload__input"
            onChange={onImageUpload}
          />
        </div>
      )}

      {filesToUpload.length === 0 && (
        <div className="mobea__uploader__upload">
          <div className="mobea__uploader__upload__spacer" />

          <div className="mobea__uploader__upload__icon-text">
            <div className="mobea__uploader__upload__icon-text__icon">
              <UploadIcon />
            </div>
            <p className="mobea__uploader__upload__icon-text__text">
              {t("expense.upload_files")}
            </p>
          </div>

          <input
            type="file"
            className="mobea__uploader__upload__input"
            onChange={onImageUpload}
          />
        </div>
      )}

      <p className="mobea__uploader__previews__files-text">
        <Trans i18nKey={supportedFilesTextKey}>
          <span className="mobea__arial" />
        </Trans>
      </p>

      {invalidFileType && (
        <MobeaModal
          confirmText={t("shared.ok")}
          title={t("shared.oops")}
          onConfirm={closeFileTypeError}
          type="error"
        >
          <p>{t(filesTypeErrorKey)}</p>
        </MobeaModal>
      )}

      {invalidFileSizeError && (
        <MobeaModal
          confirmText={t("shared.ok")}
          title={t("shared.oops")}
          onConfirm={closeFileSizeError}
          type="error"
        >
          <p>{t("shared.large_file_text")}</p>
        </MobeaModal>
      )}
    </div>
  );
}
