import { ReactElement } from "react";
import { DefaultIconProps } from "../props";

export function ServicesIcon({
  fill = "currentColor",
  className = "",
  height = "20",
  width = "20",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        transform="translate(1 4)"
        d="M12.4 2.8V2h.7c.3 0 .7-.3.7-.7V.7c0-.4-.4-.7-.7-.7H8.9c-.3 0-.7.3-.7.7v.6c0 .4.4.7.7.7h.7v.8A9.4 9.4 0 0 0 1.4 12h19.2c0-4.7-3.6-8.6-8.2-9.2Zm9 10.5H.6c-.4 0-.7.3-.7.7v1.3c0 .4.3.7.7.7h20.6c.4 0 .7-.3.7-.7V14c0-.4-.3-.7-.7-.7Z"
        fill={fill}
      />
    </svg>
  );
}
