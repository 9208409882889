import i18n, { InitOptions } from "i18next";
import XHR from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import { getAutoSavedState } from "state/getAutoSavedState";
import { getAllSupportedLanguages } from "./intl";

const languages = Object.keys(getAllSupportedLanguages());

export const DEFAULT_INTL_NAMESPACE = "translation";

const i18nConfig: InitOptions = {
  supportedLngs: languages,
  fallbackLng: "en",
  debug: process.env.NODE_ENV !== "production",
  lowerCaseLng: true,
  ns: [DEFAULT_INTL_NAMESPACE],
  defaultNS: DEFAULT_INTL_NAMESPACE,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },

  backend: {
    loadPath: `${process.env.PUBLIC_URL}locales/{{lng}}/{{ns}}.json`,
  },
};

try {
  const persistedState = getAutoSavedState();

  if (persistedState && persistedState.user.language) {
    i18nConfig.lng = persistedState.user.language;
  }
} catch (e) {
  console.warn("Failed to retreive persisted state", e);
}

export const i18nInit = i18n
  // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(XHR)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(i18nConfig);

export default i18n;
