import { DefaultIconProps } from "icons/props";
import { ReactElement } from "react";
import { VerticalClassicFlagIcon } from "./VerticalClassicFlagIcon";

export function BelgiumIcon(props: DefaultIconProps): ReactElement {
  return (
    <VerticalClassicFlagIcon
      firstColor="#000"
      secondColor="#FFDA44"
      thirdColor="#D80027"
      {...props}
    />
  );
}
