import { StandardErrorDialog } from "common/errors/StandardErrorDialog";
import { MobeaModal } from "common/modal";
import { ReactElement } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ApiErrors } from "utils";

interface AddMoneyErrorDialogProps {
  error: ApiErrors;
  onTryAgain: () => void;
  onCancel: () => void;
}

export function AddMoneyErrorDialog({
  error,
  onTryAgain,
  onCancel,
}: AddMoneyErrorDialogProps): ReactElement {
  const { t } = useTranslation();

  if (error === ApiErrors.I_NETWORK) {
    return <StandardErrorDialog onConfirm={onTryAgain} />;
  } else {
    return (
      <MobeaModal
        type="error"
        title={t("shared.sorry_but")}
        confirmText={t("shared.try_again")}
        cancelText={t("shared.cancel")}
        cancelButtonType="secondary"
        onConfirm={onTryAgain}
        onCancel={onCancel}
      >
        <Trans i18nKey="add_money.get_link_error" />
      </MobeaModal>
    );
  }
}
