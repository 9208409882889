import { MobeaButton } from "common/forms";
import { MobeaModal } from "common/modal";
import { Spinner } from "common/Spinner";
import { MobitBikeLockStatus } from "pages/mobit/hooks";
import { ReactElement, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

export interface EndRideDialogProps {
  lockStatus: MobitBikeLockStatus;
  // in or out of parking zone
  version: "inside" | "outside";
  expiration: number;
  loading?: boolean;
  endInProgress?: boolean;
  onConfirm(): void;
  onClose(): void;
}

export function EndRideDialog({
  lockStatus,
  version,
  expiration,
  loading = false,
  endInProgress = false,
  onConfirm,
  onClose,
}: EndRideDialogProps): ReactElement {
  const { t } = useTranslation();

  const [feeAccepted, setFeeAccepted] = useState(false);

  const pending = lockStatus !== MobitBikeLockStatus.CLOSED;

  const remainingMinutes = Math.max(
    Math.ceil((expiration - Date.now()) / 1000 / 60),
    0
  );

  const remainingHours = Math.floor(remainingMinutes / 60);

  const acceptFee = () => setFeeAccepted(true);

  const canEndRide =
    version === "inside" || (version === "outside" && feeAccepted);

  return (
    <MobeaModal
      className="mobea-find-bike__pause-ride"
      title={t("mobit_ride.end_the_ride")}
      confirmButton={
        <>
          {canEndRide && (
            <MobeaButton
              disabled={pending}
              loading={endInProgress}
              onClick={onConfirm}
            >
              {t("shared.end")}
            </MobeaButton>
          )}
          {!canEndRide && (
            <>
              <MobeaButton onClick={onClose}>
                {t("mobit_ride.repark_the_bike")}
              </MobeaButton>
              <MobeaButton type="secondary" onClick={acceptFee}>
                {t("mobit_ride.accept_the_fee")}
              </MobeaButton>
            </>
          )}
        </>
      }
      onClose={canEndRide ? onClose : undefined}
    >
      {loading && <Spinner />}

      {!loading && (
        <>
          {canEndRide && (
            <>
              <p>
                <Trans i18nKey="mobit_ride.end_the_ride_dialog_text">
                  First close the lock, then click on <strong>End</strong>.
                </Trans>
              </p>
              <p>{t("mobit_ride.end_in_zone_text")}</p>
            </>
          )}

          {!canEndRide && (
            <p>
              <Trans
                i18nKey={
                  remainingHours > 0
                    ? "mobit_ride.end_outside_zone_text_hour_min"
                    : "mobit_ride.end_outside_zone_text_min"
                }
              >
                <strong className="mobea__arial">
                  {{ remainingHours }}{" "}
                  {{
                    remainingMinutes: remainingMinutes - remainingHours * 60,
                  }}
                </strong>
                {
                  " to repark the bike to the indicated zone.Otherwise, you will be given "
                }
                <strong>a fee</strong>
              </Trans>
            </p>
          )}
        </>
      )}
    </MobeaModal>
  );
}
