import "@here/maps-api-for-javascript/bin/mapsjs.bundle";
import { useLocationSearchSuggestions, useReverseGeocode } from "maps";
import { useLocationUpdates } from "maps/effects";
import { ReactElement, useRef, useState } from "react";
import {
  LocationSearchPlace,
  LocationSearchPlaceWithId,
} from "services/mapService";
import { LocationPickerDialog } from "./LocationPickerDialog";

const H = window.H;

type Props = {
  className?: string;
  label: string;
  defaultLocation?: LocationSearchPlace | null;
  previousLocation?: LocationSearchPlaceWithId | null;
  filterOutLocalities?: boolean;
  onSelect(place: LocationSearchPlaceWithId): void;
  onClose(): void;
};

export function LocationPicker({
  className,
  label,
  defaultLocation,
  previousLocation = null,
  filterOutLocalities = false,
  onSelect,
  onClose,
}: Props): ReactElement {
  const platformRef = useRef<H.service.Platform | null>(
    new H.service.Platform({
      apikey: process.env.HERE_API_KEY,
    })
  );

  const [userLocation, , locationError] = useLocationUpdates();

  const [searchText, setSearchText] = useState(defaultLocation?.label || "");

  const [suggestions, loadingSuggestions] = useLocationSearchSuggestions(
    userLocation,
    searchText,
    filterOutLocalities
  );

  const [myLocationAddress] = useReverseGeocode(platformRef, userLocation);

  return (
    <LocationPickerDialog
      label={label}
      className={className}
      searchText={searchText}
      userLocation={userLocation}
      myLocationAddress={myLocationAddress}
      locationError={locationError}
      previousLocation={previousLocation}
      suggestions={suggestions}
      loadingSuggestions={loadingSuggestions}
      onSelect={onSelect}
      onSearchTextChange={setSearchText}
      onClose={onClose}
    />
  );
}
