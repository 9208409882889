import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

export function NavBackIcon({
  fill = "currentColor",
  className = "",
  height = "24",
  width = "24",
  onClick,
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 0 24 24"
      width={width}
      onClick={onClick}
    >
      <path
        fill={fill}
        d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z"
      />
    </svg>
  );
}
