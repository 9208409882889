import { DatePicker } from "common/datepicker/DatePicker";
import { DisabledOverlay } from "common/DisabledOverlay";
import { DropDownGroup, InputGroup, MobeaButton } from "common/forms";
import { useUpdatedCustomer } from "common/hooks";
import { MobeaModal } from "common/modal";
import { NestedPageFull } from "common/page/NestedPageFull";
import { ProviderName } from "common/travelPasses";
import { FileAttachment } from "common/types";
import { FileUploader } from "common/uploads/FileUploader";
import { ReactElement, ReactText, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { updateCustomer } from "services/customerService";
import { Expense, expenseIbanDisplayedAction } from "state/actions";
import {
  parseFloatWithComma,
  providerComparator,
  pushLocation,
  Routes,
  TravelPassProvider,
} from "utils";
import { ExpenseProviderOption } from "../ExpenseProviderOption";
import "./AddExpensePage.scss";
import { AddExpenseError } from "./error/AddExpenseError";
import { ConfirmIbanOverlay } from "./iban/ConfirmIbanOverlay";
import { useAddExpense } from "./useAddExpense";

export function AddExpensePage(): ReactElement {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  useUpdatedCustomer();

  const { provider: providerParam } = useParams<{
    provider: TravelPassProvider;
  }>();

  const {
    customerId,
    language: locale,
    iban: customerIban,
    startDate,
    endDate,
    iban,
  } = useSelector((state) => state.user);

  const expenseIbanDisplayed = useSelector(
    (state) => state.onboarding.expenseIbanDisplayed
  );

  const [provider, setProvider] = useState(providerParam || "");

  const [providerOpened, setProviderOpened] = useState(false);

  const [date, setDate] = useState<number>();

  const [price, setPrice] = useState<string>();

  const [priceFocused, setPriceFocused] = useState(false);

  const [filesToUpload, setFilesToUpload] = useState<FileAttachment[]>([]);

  const [confirmBankNumber, setConfirmBankNumber] = useState(false);

  const [expenseToUpload, setExpenseToUpload] = useState<Expense | null>(null);

  const [uploadedExpense, uploading, uploadSuccess, uploadError] =
    useAddExpense(expenseToUpload);

  const [errorDialogOpened, setErrorDialogOpened] = useState(true);
  const allProviders = useSelector((state) => state.passes.providers);

  const expenseProviders = useMemo(
    () =>
      allProviders
        .filter((provider) => !provider.hidden)
        .map((provider) => ({
          name: t(`providers_names.${provider.provider}`),
          provider: provider.provider,
        }))
        .sort(providerComparator)
        .map((value) => value.provider),
    [allProviders, t]
  );

  const providerLabel = t("expense.transport_provider");

  const dateLabel = t("time.date");

  const priceLabel = t("shared.price");

  const onPriceFocused = () => setPriceFocused(true);

  const onPriceBlurred = () => setPriceFocused(false);

  const onPriceChange = (value: ReactText) => {
    setPrice(value.toString());
  };

  const onProviderChange = (value: string) =>
    setProvider(value as TravelPassProvider);

  const onProviderOpen = () => setProviderOpened(true);

  const onProviderClose = () => setProviderOpened(false);

  const doConfirmBankNumber = () => setConfirmBankNumber(true);

  const closeConfirmBankNumber = () => setConfirmBankNumber(false);

  const submitExpense = (iban: string = customerIban) => {
    const parsedPrice = parseFloatWithComma(price || "0") || 0;

    const expense: Expense = {
      id: Date.now().toString(),
      price: parsedPrice,
      acceptedPrice: parsedPrice,
      provider: provider as TravelPassProvider,
      status: "PENDING",
      createdDate: Date.now(),
      expensedDate: date || Date.now(),
      uploads: filesToUpload,
      iban,
    };

    setConfirmBankNumber(false);

    setErrorDialogOpened(true);

    setExpenseToUpload(expense);

    if (!expenseIbanDisplayed) {
      updateCustomer(
        customerId,
        {
          iban,
        },
        history
      );

      dispatch(expenseIbanDisplayedAction());
    }
  };

  const closeError = () => setErrorDialogOpened(false);

  const goHome = () =>
    pushLocation(
      history,
      Routes.ExpenseDetail.replace(
        ":id",
        uploadedExpense ? uploadedExpense.id : ""
      )
    );

  const allValid = !!provider && !!date && !!price && filesToUpload.length > 0;

  return (
    <>
      {!confirmBankNumber && (
        <NestedPageFull
          title={t("expense.add_new_expense")}
          pageClassName="mobea-add-expense"
          padding={{
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <DisabledOverlay active={uploading} />
          <section className="mobea-add-expense__form">
            <DropDownGroup
              className={`mobea-add-expense__form__providers ${
                providerOpened ? "mobea__active" : ""
              }`}
              label={provider ? providerLabel : ""}
              direction="down"
              options={expenseProviders}
              value={provider}
              placeholder={!provider ? providerLabel : ""}
              optionHeight={48}
              maxOptionsHeight={384}
              onChange={onProviderChange}
              onOpen={onProviderOpen}
              onClose={onProviderClose}
              optionTransformer={(provider) => (
                <ExpenseProviderOption
                  provider={provider as TravelPassProvider}
                />
              )}
              valueTransformer={(provider) => (
                <ProviderName provider={provider as TravelPassProvider} />
              )}
            />
            <DatePicker
              title={t("expense.expense_date")}
              dateInputLabel={dateLabel}
              className="mobea-add-expense__form__date"
              locale={locale}
              selectedDate={date}
              startDate={startDate}
              endDate={endDate}
              t={t}
              onChange={setDate}
            />
            <InputGroup
              className="mobea-add-expense__form__price"
              label={priceLabel}
              name="price"
              type="text"
              value={price || ""}
              placeholder={priceLabel}
              toggleLabelAndPlaceholder
              onChange={onPriceChange}
              validation={(value) =>
                /^[0-9]+[.,]{0,1}[0-9]*$/.test(value.toString())
              }
              inputAttributes={{
                inputMode: "decimal",
                style: {
                  paddingLeft: priceFocused || price ? "10px" : "0px",
                },
                autoComplete: "off",
              }}
              onFocus={onPriceFocused}
              onBlur={onPriceBlurred}
            >
              {(!!priceFocused || !!price) && (
                <span className="mobea-add-expense__form__price__euro">€</span>
              )}
            </InputGroup>
          </section>
          <section className="mobea-add-expense__uploads">
            <FileUploader
              filesToUpload={filesToUpload}
              filesTypeErrorKey="expense.invalid_file_type_text"
              supportedFilesTextKey="expense.supported_files_text"
              onFilesToUploadChange={setFilesToUpload}
            />
          </section>
          <section className="mobea-add-expense__button">
            <MobeaButton
              disabled={!allValid || uploading}
              onClick={
                expenseIbanDisplayed && iban
                  ? submitExpense
                  : doConfirmBankNumber
              }
              loading={uploading}
            >
              {uploading ? t("shared.in_progress") : t("shared.continue")}
            </MobeaButton>
          </section>
          {errorDialogOpened && uploadError && (
            <AddExpenseError errorCode={uploadError} closeError={closeError} />
          )}
          {uploadSuccess && (
            <MobeaModal
              confirmText={t("shared.ok")}
              title={t("expense.expense_sent")}
              onConfirm={goHome}
            >
              <p>{t("expense.expense_reviewed_text")}</p>
            </MobeaModal>
          )}
        </NestedPageFull>
      )}

      {confirmBankNumber && (
        <ConfirmIbanOverlay
          bankAccount={customerIban}
          t={t}
          onClose={closeConfirmBankNumber}
          onDone={submitExpense}
        />
      )}
    </>
  );
}
