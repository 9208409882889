import { MobeaModal } from "common/modal";
import { ReactElement } from "react";
import { Trans, useTranslation } from "react-i18next";

interface Props {
  onConfirm(): void;
}

export function ParkingZoneModal({ onConfirm }: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <MobeaModal
      className="mobea-parking-zone-modal"
      title={t("mobit_ride.parking_zones")}
      confirmText={t("shared.understand")}
      onConfirm={onConfirm}
    >
      <p>
        <Trans i18nKey="mobit_ride.pause_in_zone_text">
          Remember to park in the indicated zones.Otherwise, you will be charged
          extra!
        </Trans>
      </p>
    </MobeaModal>
  );
}
