import { useEffect, useState } from "react";

export function useViewportHeight() {
  const [height, setHeight] = useState<number | null>(null);

  useEffect(() => {
    if (!window.visualViewport) {
      return;
    }

    const viewportResized = ({ target: viewport }) => {
      setHeight(viewport.height);
    };

    window.visualViewport.addEventListener("resize", viewportResized);

    return () => {
      window.visualViewport.removeEventListener("resize", viewportResized);
    };
  }, []);

  return height;
}
