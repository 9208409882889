import { useTravelPasses } from "common/hooks";
import { BudgetState, useBudgetState } from "common/hooks/useBudgetState";
import { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { TravelPass } from "state/actions";
import { combineParentTravelPasses, WalletType } from "utils";
import {
  groupHistoryItems,
  HistoryItemsGroup,
  HistoryNoCosts,
  todayString,
  translateDate,
  yesterdayString,
} from "../costs";
import { HistoryPageSpinner } from "../HistoryPageSpinner";
import { HistoryPurchase } from "./HistoryPurchase";

type Props = { wallet: WalletType };

export function HistoryTicketsList({ wallet }: Props): ReactElement {
  const { t } = useTranslation();

  const [loadingPasses] = useTravelPasses("all");

  const { language: locale } = useSelector((state) => state.user);

  const [loadingBudget, budgetState] = useBudgetState();

  const purchases = useSelector((state) => state.passes.purchases);

  const travelPasses = useMemo(
    () =>
      combineParentTravelPasses(purchases)
        .filter((tp) => tp.plan === wallet)
        .reverse(),
    [purchases, wallet]
  );

  const loading = loadingPasses || loadingBudget;

  const today = todayString(locale);

  const yesterday = yesterdayString(locale);

  const toHistoryBlocks = (groups: HistoryItemsGroup<TravelPass>[]) =>
    groups.map((group) => {
      const isToday = group.date === today;

      return (
        <ul key={group.date} className="mobea-history__costs__block">
          <li
            key={group.date}
            className={`mobea-history__costs__date ${isToday ? "today" : ""}`}
          >
            {translateDate(group.date, today, yesterday, t)}
          </li>

          {group.items.map((item) => (
            <HistoryPurchase key={item.id} locale={locale} pass={item} />
          ))}
        </ul>
      );
    });

  const dt = (value: string) => value;

  return (
    <>
      <HistoryPageSpinner loading={loading} />
      <div
        css={{
          paddingLeft: 32,
        }}
      >
        {travelPasses.length === 0 ? (
          <HistoryNoCosts
            noCostsTranslateKey={
              budgetState === BudgetState.Expired
                ? dt("history.no_tickets_budget_expired")
                : dt("history.no_tickets_text")
            }
            addCostTranslateKey={dt("history.add_ticket_text")}
            active={budgetState === BudgetState.Active}
          />
        ) : (
          <>
            <h2 className="mobea-history__costs__title">
              {t("history.purchased")}
            </h2>

            <div className="mobea-history__costs">
              {toHistoryBlocks(
                groupHistoryItems(travelPasses, "orderTimestamp", locale)
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}
