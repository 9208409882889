import { cx } from "@emotion/css";
import { EuroIcon } from "icons/EuroIcon";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { getMolliePayments, RawPayment } from "services/mollieService";
import { formatCurrency, formatDateWithLocaleAsDefault, Routes } from "utils";
import { AppColors } from "utils/colors";
import {
  groupHistoryItems,
  HistoryNoCosts,
  todayString,
  translateDate,
  yesterdayString,
} from "../costs";
import { HistoryPageSpinner } from "../HistoryPageSpinner";
import { topupStatusProps } from "./topupStatusProps";

type Payment = Omit<RawPayment, "created" | "amount"> & {
  created: Date;
  amount: number;
};

export function HistoryTopUpsList(): ReactElement {
  const { t } = useTranslation();

  const { language: locale } = useSelector((state) => state.user);

  const [loading, setLoading] = useState(true);

  const today = todayString(locale);

  const yesterday = yesterdayString(locale);

  const history = useHistory();

  const [payments, setPayments] = useState<Payment[]>([]);

  useEffect(() => {
    getMolliePayments(history)
      .then((data) => {
        if (Array.isArray(data)) {
          setPayments(
            data.map((payment) => ({
              ...payment,
              created: new Date(payment.created),
              amount: Number(payment.amount) + Number(payment.fee),
            }))
          );
        } else {
          console.error(
            "Failed to fetch payments",
            data.error_code,
            data.error_description
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [history]);

  const dt = (value: string) => value;

  return (
    <div>
      <HistoryPageSpinner loading={loading} />

      <div
        css={{
          paddingLeft: 32,
        }}
      >
        {payments.length === 0 ? (
          <HistoryNoCosts
            noCostsTranslateKey={dt("history.no_topups")}
            addCostTranslateKey={dt("home.add_money")}
            active
            startLinkTo={Routes.AddMoney}
          />
        ) : (
          <>
            <h2 className="mobea-history__costs__title">
              {t("history.added")}
            </h2>

            <ul className="mobea-history__costs mobea-history__expense">
              {groupHistoryItems(payments, "created", locale).map((group) => {
                const isToday = group.date === today;

                return (
                  <ul
                    key={`ul__${group.date}`}
                    className="mobea-history__costs__block"
                  >
                    <li
                      key={group.date}
                      className={cx(
                        "mobea-history__costs__date",
                        isToday && "today"
                      )}
                    >
                      {translateDate(group.date, today, yesterday, t)}
                    </li>

                    {group.items.map((item) => {
                      const [color, Icon, iconSize] =
                        topupStatusProps[item.status] ?? [];

                      return (
                        <li
                          key={item.id}
                          className="mobea-history__costs__spending"
                          css={{
                            display: "block",
                            paddingTop: 0,
                            paddingBottom: 0,
                          }}
                        >
                          <Link
                            css={{
                              display: "flex",
                              textDecoration: "none",
                              paddingTop: 16,
                              paddingBottom: 16,
                            }}
                            to={Routes.TopUpDetail.replace(":id", item.id)}
                          >
                            <div
                              className="mobea__travel-pass__header__type"
                              css={{
                                backgroundColor: AppColors.PRIMARY,
                                flexShrink: 0,
                              }}
                            >
                              <EuroIcon />
                            </div>

                            <div className="mobea__travel-pass__header__provider">
                              <h3 className="mobea__travel-pass__header__provider__name">
                                {t("history.topup")}
                              </h3>

                              <p
                                className="mobea__travel-pass__header__provider__dates"
                                css={{ lineHeight: 2 }}
                              >
                                {formatDateWithLocaleAsDefault(
                                  item.created,
                                  locale
                                )}
                                 | {t("history.payment_" + item.status)}
                              </p>
                            </div>

                            <p
                              className="mobea-history__costs__spending__price"
                              css={{
                                color,
                                paddingTop: 0,
                                "& > *": { verticalAlign: "middle" },
                              }}
                            >
                              <span>
                                {(!["failed", "canceled", "expired"].includes(
                                  item.status
                                )
                                  ? "+\u00a0"
                                  : "") + formatCurrency(item.amount, locale)}
                              </span>
                              <Icon
                                height={iconSize}
                                width={iconSize}
                                css={{ marginLeft: "0.75em" }}
                              />
                            </p>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                );
              })}
            </ul>
          </>
        )}
      </div>
    </div>
  );
}
