import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

export function LocationCurrentIcon({
  fill = "currentColor",
  className = "",
  height = "16",
  width = "16",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.762.381v1.576a6.102 6.102 0 015.28 5.281h1.577v1.524h-1.576a6.102 6.102 0 01-5.281 5.28v1.577H7.238v-1.576a6.1 6.1 0 01-5.28-5.281H.38V7.238h1.576a6.102 6.102 0 015.281-5.28V.38h1.524zM8 3.429A4.577 4.577 0 003.429 8c0 2.52 2.05 4.571 4.571 4.571A4.577 4.577 0 0012.571 8c0-2.52-2.05-4.571-4.571-4.571zm0 2.285a2.286 2.286 0 110 4.572 2.286 2.286 0 010-4.572z"
        fill={fill}
      />
    </svg>
  );
}
