import { MobeaModal } from "common/modal";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { ApiErrors } from "utils/errors";

interface Props {
  errorCode: ApiErrors;
  onConfirm(): void;
}

export function MobitErrorDialogs({
  errorCode,
  onConfirm,
}: Props): ReactElement {
  const { t } = useTranslation();

  const getErrorTitle = () => {
    switch (errorCode) {
      case ApiErrors.MOBIT_WRONG_CODE:
        return t("mobit_unlock.wrong_code");

      default:
        return t("mobit_ride.bike_not_available");
    }
  };

  const getErrorText = () => {
    switch (errorCode) {
      case ApiErrors.MOBIT_RESERVED:
      case ApiErrors.MOBIT_RESERVED_ALT:
        return t("mobit_ride.bike_reserved_error_text");
      case ApiErrors.MOBIT_WRONG_CODE:
        return t("mobit_unlock.wrong_code_text");
      case ApiErrors.MOBIT_BIKE_BROKEN:
        return t("mobit_ride.bike_broken_error_text");
      case ApiErrors.MOBIT_BIKE_USED:
        return t("mobit_ride.bike_ridden_error_text");
      case ApiErrors.MOBIT_RIDING_OTHER:
        return t("mobit_ride.user_riding_other_bike_text");
      case ApiErrors.MOBIT_SPECIAL_PASS:
        return t("mobit_ride.bike_pass_error_text");
      default:
        return t("mobit_ride.bike_not_available_text");
    }
  };

  if (errorCode.startsWith("M")) {
    return (
      <MobeaModal
        type="error"
        confirmText={t("shared.ok")}
        title={getErrorTitle()}
        onConfirm={onConfirm}
      >
        {getErrorText()}
      </MobeaModal>
    );
  } else {
    // unspecific error out of Mobit range
    return (
      <MobeaModal
        type="error"
        confirmText={t("shared.ok")}
        title={t("shared.oops")}
        onConfirm={onConfirm}
      >
        {t("shared.network_error_text")}
      </MobeaModal>
    );
  }
}
