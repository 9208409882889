import { MobeaModal } from "common/modal";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ApiErrors, pushLocation, Routes } from "utils";

export interface AddExpenseErrorProps {
  errorCode: string;
  closeError(): void;
}

export function AddExpenseError({
  errorCode,
  closeError,
}: AddExpenseErrorProps): ReactElement {
  const history = useHistory();

  const { t } = useTranslation();

  const goHome = () => pushLocation(history, Routes.Home);

  switch (errorCode) {
    case ApiErrors.NO_ACTIVE_PLAN:
      return (
        <MobeaModal
          confirmText={t("shared.ok")}
          title={t("shared.oops")}
          onConfirm={goHome}
          type="error"
        >
          <p>{t("shared.no_active_plan_text")}</p>
        </MobeaModal>
      );
    case ApiErrors.LOW_BUDGET:
      return (
        <MobeaModal
          confirmText={t("shared.ok")}
          title={t("shared.oops")}
          onConfirm={goHome}
          type="error"
        >
          <p>{t("shared.insufficient_budget_expense")}</p>
        </MobeaModal>
      );
    case ApiErrors.I_NETWORK:
      return (
        <MobeaModal
          confirmText={t("shared.ok")}
          title={t("shared.oops")}
          onConfirm={closeError}
          type="error"
        >
          <p>{t("shared.network_error_text")}</p>
        </MobeaModal>
      );
    case ApiErrors.I_TOO_LARGE_PAYLOAD:
      return (
        <MobeaModal
          confirmText={t("shared.ok")}
          title={t("shared.oops")}
          onConfirm={closeError}
          type="error"
        >
          <p>{t("shared.large_file_text")}</p>
        </MobeaModal>
      );
    case ApiErrors.EXPENSE_DATA_MISSING:
    case ApiErrors.PROVIDER_NOT_EXIST:
      return (
        <MobeaModal
          confirmText={t("shared.ok")}
          title={t("shared.oops")}
          onConfirm={closeError}
          type="error"
        >
          <p>{t("expense.expense_error_text")}</p>
        </MobeaModal>
      );
    default:
      return (
        <MobeaModal
          confirmText={t("shared.ok")}
          title={t("shared.oops")}
          onConfirm={closeError}
          type="error"
        >
          <p>{t("shared.network_error_text")}</p>
        </MobeaModal>
      );
  }
}
