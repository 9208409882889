import { useCallback, useState } from "react";

export function useBooleanState(initialState = false) {
  const [value, setValue] = useState<boolean>(initialState);

  return [
    value,
    useCallback(() => setValue(true), []),
    useCallback(() => setValue(false), []),
    useCallback(() => setValue((v) => !v), []),
  ] as const;
}
