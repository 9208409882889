import { FileAttachment } from "common/types";
import { LocationCoords } from "services/mapService";
import {
  ProviderToTypeMappingType,
  TravelPassProvider,
  TravelPassType,
  WalletType,
} from "utils/constants";
import { VertsStatus } from "utils/VertsStatus";

export interface TravelPass {
  types: TravelPassType[];
  provider: TravelPassProvider;
  price: number;
  id: string;
  orderTimestamp: number; // order timestamp
  activation?: number;
  expiration: number;
  plan: WalletType;
  parentTicketId?: string;
}

export const setActiveTravelPassesAction = (passes: TravelPass[]) => ({
  type: "SET_ACTIVE_TRAVEL_PASSES" as const,
  passes,
});

export enum ProviderPrimaryAction {
  Order = "order",
  Expense = "expense",
}

export type ProviderType = "order" | "expense";

export interface TravelProvider {
  color: string;
  name: string;
  provider: TravelPassProvider;
  type: ProviderType;
  orderable: boolean;
  expensable: boolean;
  minPrice?: number;
  textColor: string;
  testOrder: boolean;
  types: TravelPassType[];
  hidden: boolean;
  availableOnMap: boolean;
}

export const setProvidersAction = (providers: TravelProvider[]) => ({
  type: "SET_PROVIDERS" as const,
  providers,
});

export const setProvidersTypesAction = (
  types: ProviderToTypeMappingType,
  mapTypes: TravelPassType[]
) => ({
  type: "SET_PROVIDERS_TYPES" as const,
  types,
  mapTypes,
});

export const setProvidersLoadingAction = (loading: boolean) => ({
  type: "SET_PROVIDERS_LOADING" as const,
  loading,
});

export interface DeLijnTravelPass extends TravelPass {
  amount: number;
  endDateTimeFormatted: string;
  duration: number;
  shortCheckSum: string;
  afzender: string;
  verificationCode: string;
  provider: TravelPassProvider.delijn;
}

export enum JourneyType {
  SINGLE = "SingleTrip",
  RETURN = "RoundTrip",
}

export enum TravelClass {
  FIRST = "FirstClass",
  SECOND = "SecondClass",
}

export enum PassengerType {
  ADULT = "Adult",
  CHILD = "Child",
}

export interface NmbsTravelPass extends TravelPass {
  barCode: string;
  departureStationName: string;
  destinationStationName: string;
  longCode: string;
  productId: number;
  productName: string;
  shortCode: string;
  ticketNumber: string;
  travelDate: string; // Date string
  travelEndDate: string | null;
  journeyType: JourneyType;
  provider: TravelPassProvider.nmbs;
  travelClass: TravelClass;
  returnTravelPass?: NmbsTravelPass;
  passengerName: string;
  passengerSurname: string;
}

export enum MobitTicketStatus {
  NOT_USED = "not_used",
  STARTED = "started",
  PAUSED = "paused",
  ENDED = "ended",
}

export interface MobitTravelPass extends TravelPass {
  duration: number;
  status: MobitTicketStatus;
  bikeCode?: string;
  rideId?: string;
  fine: number;
  provider: TravelPassProvider.mobit;
}

export const vertsStatusMapper = {
  CREATED: VertsStatus.Created,
  PLANNED: VertsStatus.Ordered,
  SEARCHING_DRIVER: VertsStatus.Searching,
  SEARCH_FAILED: VertsStatus.NoDriverFound,
  ON_WAY_TO_CUSTOMER: VertsStatus.Arriving,
  WAITING_CUSTOMER: VertsStatus.Waiting,
  ON_WAY_TO_DESTINATION: VertsStatus.InProgress,
  OFF_BOARD: VertsStatus.InProgress,
  AT_DESTINATION: VertsStatus.InProgress,
  COMPLETED: VertsStatus.Finished,
  CANCELED: VertsStatus.Cancelled,
};

export interface VertsTravelPass extends TravelPass {
  status: VertsStatus;
  origin: {
    address: string;
    location: LocationCoords;
  };
  destination: {
    address: string;
    location: LocationCoords;
  };
  createdTime: string; // ISO string
  etaTime?: string; //ISO string
  arrivalTime?: string; // ISO string
  displayStatus: VertsStatus;
  price: number;
  cancelFee: number;
  cancelledByOperator?: boolean;
  driver?: string;
  vehicle?: {
    model: string;
    color: string;
    plate: string;
    location?: LocationCoords;
  };
  provider: TravelPassProvider.verts;
  requestedAt: string; // ISO string
}

export enum VeloStatus {
  // t("velo_detail.status.ready")
  Ready = "ready",

  // t("velo_detail.status.active")
  Active = "active",

  // t("velo_detail.status.expired")
  Expired = "expired",

  // t("velo_detail.status.charged")
  Charged = "charged",
}

export interface VeloTravelPass extends TravelPass {
  status: VeloStatus;
  userId?: string;
  password?: string;
  price: number;
  fine: boolean;
}

export enum BlueBikeStatus {
  // t("blue-bike_detail.status.ready")
  Ready = "ready",

  // t("blue-bike_detail.status.active")
  Active = "active",

  // t("blue-bike_detail.status.expired")
  Expired = "expired",

  // t("blue-bike_detail.status.charged")
  Charged = "charged",
}

export interface BlueBikeTravelPass extends TravelPass {
  status: BlueBikeStatus;
  password?: string;
  wrongParkingFine: boolean;
}

export const addActiveTravelPassAction = (pass: TravelPass) => ({
  type: "ADD_ACTIVE_TRAVEL_PASS" as const,
  pass,
});

export const updateActiveTravelPassAction = (pass: TravelPass) => ({
  type: "UPDATE_ACTIVE_TRAVEL_PASS" as const,
  pass,
});

export const addPurchaseAction = (pass: TravelPass) => ({
  type: "ADD_PURCHASE" as const,
  pass,
});

export const setPurchasesAction = (passes: TravelPass[]) => ({
  type: "SET_PURCHASES" as const,
  passes,
});

export type ExpenseStatus =
  // t("expense.pending")
  | "PENDING"

  // t("expense.accepted")
  | "ACCEPTED"

  // t("expense.declined")
  | "DECLINED"

  // t("expense.partially_accepted")
  | "PARTIALLY ACCEPTED";

export interface Expense {
  provider: TravelPassProvider;
  price: number;
  acceptedPrice: number;
  id: string;
  expensedDate: number; // expensed date
  createdDate: number;
  status: ExpenseStatus;
  iban: string;
  acceptedDate?: number;
  refusalReason?: string;
  uploads: FileAttachment[];
}

// Do not store expense attachments in local storage as it will run out of space
// attachment links are fetched when online
export const expensesJSONSerializer = (expenses: Expense[]) =>
  expenses.map((expense) => ({
    ...expense,
    uploads: expense.uploads.map((upload) => ({
      name: upload.name,
      preview: "",
      path: "",
    })),
  }));

export const setExpensesAction = (expenses: Expense[]) => ({
  type: "SET_EXPENSES" as const,
  expenses,
});

export const addExpenseAction = (expense: Expense) => ({
  type: "ADD_EXPENSE" as const,
  expense,
});

export type TravelPassActions =
  | ReturnType<typeof setActiveTravelPassesAction>
  | ReturnType<typeof setProvidersAction>
  | ReturnType<typeof setProvidersTypesAction>
  | ReturnType<typeof setProvidersLoadingAction>
  | ReturnType<typeof addActiveTravelPassAction>
  | ReturnType<typeof updateActiveTravelPassAction>
  | ReturnType<typeof setPurchasesAction>
  | ReturnType<typeof addPurchaseAction>
  | ReturnType<typeof setExpensesAction>
  | ReturnType<typeof addExpenseAction>;
