import { DefaultIconProps } from "icons/props";
import { ReactElement } from "react";

export function MivbLogo({
  className = "",
  width,
  height,
}: Pick<DefaultIconProps, "className" | "width" | "height">): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 328 328"
      xmlns="http://www.w3.org/2000/svg"
    >
      <clipPath id="mivb-a">
        <path d="M254.7 328.4h-181A74 74 0 010 254.7v-181A74 74 0 0173.7 0h181a74 74 0 0173.7 73.7v181a74 74 0 01-73.7 73.7" />
      </clipPath>
      <path
        clipPath="url(#mivb-a)"
        d="M144.2 92.4a53 53 0 01-26.5-1.4l2.6-10.7a39.8 39.8 0 0022 1.7c9.5-2 14.2-7.6 12.6-15.1-1.2-5.8-5.6-8.3-13.8-8.9l-8.8-.7c-7.3-.3-12.9-2.4-16.3-5.9a20.5 20.5 0 01-5.9-10.4c-2.8-13.3 5.8-24.9 21.2-28.2A46 46 0 01157 15l-3.8 10.2c-8-2.7-13.1-3.3-19.2-2-7.4 1.6-11.6 6.7-10.3 12.8 1 4.9 4.2 6.6 11.9 7.4l9.7.7c11.8.7 20.9 6.1 23.4 17.7 2.6 12.6-5 26.5-24.5 30.6m-64.1 74.4L3.7 183.1 1 170.3 77.3 154zm6.4 30.2l-46.7 13.6c-4.5 1.5-10.2 2.7-12.7 3.3 2.7 0 7.4 0 12.3.5l51.6 3.7 2.3 10.7-43.5 22.5c-4.8 2.5-11 5.2-13.3 6.3 2.5-.5 9.4-1.4 14.5-2.2l46.6-6.8 2.7 12.7-77.9 9.3-3.6-16.9 40.7-20.9c6.2-3 10-4.6 12.1-5.5h-2c-2.6 0-6.7 0-11.2-.3l-45.8-2.5-3.6-17 75-22.8zM306.4-1.2a17 17 0 01-9.7 16.9c13.1.4 18.5 8.2 20.2 16 3.2 15-6 23.8-25.3 27.9l-21.9 4.7-14-65.6-18.3-.1L252.1 68 239 70.8 223.6-1.5h-9.4l.4 8.9-19.3 4.1 14 65.6-12.5 2.7-14-65.6-19.3 4.1-2.2-10.5 43.5-9.3L-.4-2l.1 36.5c4.6 1 8.5 4.2 11.4 9.5.5-13.4 8.2-18.9 15.7-20.5 15.1-3.2 24 5.9 28.1 25.2l4.7 22.1-60.3 13 .1 15 68 8.2 2.5 11.7-70.8 41.9-1.2 155.9 341.3-72.8 1.3-244.8zm-255.6 118l-.1-.4s-4.8.1-17-1.1l-34.5-3.7V145l37-21.1c9.1-5.1 14.6-7.1 14.6-7.1m235-92.3l-11.1 2.4 5.2 24.2 11.6-2.5c9.6-2 12.6-8.6 11.2-15.3-1.2-5.4-5.2-9.2-9.5-9.5a21 21 0 00-7.4.7M28.4 37.9c-5.3 1.1-9.2 5.3-9.5 9.5-.2 1.9.2 3.6.9 7.1l2.4 11.1 24-5.1-2.4-11.4c-2-9.6-8.3-12.7-15.4-11.2M291.7 9.5c2-3 1.9-6.3 1.5-8.4l-.8-2.4h-23.9l3.8 17.8 10.7-2.3c4.7-1 7-1.8 8.7-4.7M9.5 57.4c-.9-4.4-1.7-6.8-4.9-8.7A9.7 9.7 0 00-.6 47l.1 23.6L11.8 68z"
        fill="#006fb9"
      />
      <path
        clipPath="url(#mivb-a)"
        d="M-15.9 303.6l-.4 49.7 354.4-8.5 1.2-117z"
        fill="#e73339"
      />
    </svg>
  );
}
