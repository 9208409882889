import { TravelPassProvider } from "utils";

export type SpendingTrackingData = {
  action: TravelPassProvider;
  label: "success" | "failure";
  value: string;
};

export function trackTicketPurchase(data: SpendingTrackingData) {
  window.mobeaEvent({
    category: "buy ticket",
    ...data,
  });
}

export function trackAddedToHomeScreen() {
  window.mobeaEvent({
    category: "home screen",
    action: "add",
  });
}

export function trackExpenseAdded(data: SpendingTrackingData) {
  window.mobeaEvent({
    category: "add expense",
    ...data,
  });
}

export function trackPhoneVerification(result: "success" | "failure") {
  window.mobeaEvent({
    category: "phone verification",
    label: result,
  });
}
