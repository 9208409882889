import { MobeaModal } from "common/modal";
import { ReactElement } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ProviderPrimaryAction } from "state/actions";
import { formatDateWithLocaleAsDigits } from "utils";

export type NoBudgetModalProps = {
  messageType: ProviderPrimaryAction;
  start: Date;
  end: Date;
  locale: string;
  onClose(): void;
};

export function NoBudgetModal({
  messageType,
  start,
  end,
  locale,
  onClose,
}: NoBudgetModalProps): ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <MobeaModal
        title={t("warning.no_budget")}
        className="mobea__no-budget-modal"
        confirmText={t("shared.ok")}
        onConfirm={onClose}
      >
        {/*
              t("warning.no_budget_order")
              t("warning.no_budget_expense")
            */}
        <Trans i18nKey={`warning.no_budget_${messageType}`}>
          {"Expired message "}
          {{ start: formatDateWithLocaleAsDigits(start, locale) }}{" "}
          {{ end: formatDateWithLocaleAsDigits(end, locale) }}
        </Trans>
      </MobeaModal>
    </>
  );
}
