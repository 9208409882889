import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  getPlan,
  getPrivatePlan,
  PlanApiResponse,
  PrivatePlanApiResponse,
} from "services/customerService";
import {
  setBudgetAction,
  setNoPlanAction,
  setPrivateBudgetAction,
} from "state/actions/UserActions";
import { applicationVariant } from "utils/configure";
import { ApplicationVariant } from "utils/constants";

type BudgetContextType = readonly [
  loading: boolean,
  reload: () => void,
  setEnable: (enable: boolean) => void
];

// context is for caching and re-using getPlan promise
const BudgetContext = createContext<BudgetContextType | undefined>(undefined);

export function BudgetProvider({ children }: { children: ReactNode }) {
  const history = useHistory();

  const dispatch = useDispatch();

  const planId = useSelector((state) => state.user.currentPlanId);

  const { pathname } = useLocation();

  const [key, setKey] = useState(0);

  const [loading, setLoading] = useState(false);

  const [enable, setEnable] = useState(false);

  useEffect(() => {
    setEnable(false);
  }, [pathname, planId]);

  useEffect(() => {
    if (!enable) {
      return;
    }

    let canceled = false;

    setLoading(true);

    let loading = true;

    (async () => {
      try {
        const [json, jsonPrivate]: [
          PlanApiResponse | false,
          PrivatePlanApiResponse | false
        ] = await Promise.all<[any, any]>([
          !!planId ? getPlan(planId, history) : false,
          applicationVariant === ApplicationVariant.MOVEASY
            ? getPrivatePlan(history)
            : false,
        ]);

        if (canceled) {
          return;
        }

        if (!jsonPrivate) {
          // nothing
        } else if (!jsonPrivate.error) {
          const plan = jsonPrivate.current_plan ?? {};

          dispatch(
            setPrivateBudgetAction({
              amount: plan.remaining_amount ?? 0,
              startDate: plan.start_date
                ? new Date(plan.start_date).valueOf()
                : null,
            })
          );
        } else {
          console.error(
            "Failed to fetch private budget",
            jsonPrivate.error_description
          );
        }

        if (!json) {
          dispatch(setNoPlanAction());
        } else if (!json.error) {
          const plan = json.current_plan;

          if (plan) {
            dispatch(
              setBudgetAction({
                amount: plan.remaining_amount || 0,
                totalAmount: plan.allocated_amount || 0,
                startDate: new Date(plan.start_date).valueOf(),
                endDate: new Date(plan.end_date).valueOf(),
              })
            );
          } else {
            dispatch(setNoPlanAction());
          }

          // handle no plan error!
        } else if (json.error && json.error_code === "0303") {
          dispatch(setNoPlanAction());
        } else {
          console.error(
            "Failed to fetch remaining budget",
            json.error_description
          );
        }
      } catch (error) {
        console.error("Failed to fetch budget", error);
      } finally {
        setLoading(false);

        loading = false;
      }
    })();

    return () => {
      canceled = true;

      setLoading(false);

      if (loading) {
        console.debug("Canceled hook useBudget");
      }
    };
  }, [dispatch, history, planId, key, enable]);

  const reload = useCallback(() => {
    setKey((k) => k + 1);
  }, []);

  const value = useMemo(
    () => [loading, reload, setEnable] as const,
    [loading, reload]
  );

  return (
    <BudgetContext.Provider value={value}>{children}</BudgetContext.Provider>
  );
}

export function useBudget() {
  const ctx = useContext(BudgetContext);

  if (!ctx) {
    throw new Error("missing BudgetContext");
  }

  const [loading, reload, setEnabled] = ctx;

  useEffect(() => {
    setTimeout(() => {
      setEnabled(true);
    });
  }, [setEnabled]);

  return [loading, reload] as const;
}
