import { ReactNode } from "react";
import { AppColors } from "utils/colors";

interface FieldLabelProps {
  htmlFor?: string;
  children: ReactNode;
}

export function FieldLabel({ htmlFor, children }: FieldLabelProps) {
  return (
    <label
      htmlFor={htmlFor}
      css={{
        fontSize: "0.75rem",
        lineHeight: "1rem",
        color: AppColors.GRAY_300,
        fontWeight: "bold",
        marginBottom: 10,
        display: "block",
      }}
    >
      {children}
    </label>
  );
}
