import { ReactElement } from "react";
import { AppColors, getDerivedColor } from "utils/colors";
import { DefaultIconProps } from "../props";

const path =
  "M20 3.333A11.658 11.658 0 008.333 15C8.333 23.75 20 36.667 20 36.667S31.667 23.75 31.667 15C31.667 8.55 26.45 3.333 20 3.333zm0 15.834A4.168 4.168 0 0115.833 15c0-2.3 1.867-4.167 4.167-4.167S24.167 12.7 24.167 15 22.3 19.167 20 19.167z";

export const LocationCursorIconSvg = () =>
  `<svg class="mobea__location-cursor-icon" width="40" height="40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
        <path
            d="${path}"
            fill="${getDerivedColor(AppColors.PRIMARY)}"
        /> 
    </svg>`;

export function LocationCursorIcon({
  fill = getDerivedColor(AppColors.PRIMARY),
  className = "",
  height = "40",
  width = "40",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
    >
      <path d={path} fill={fill} />
    </svg>
  );
}
