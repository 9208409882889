import { useAppName } from "common/hooks/useAppName";
import { CustomMobeaModal } from "common/modal";
import { Tutorial } from "common/tutorial";
import { TicketIcon } from "icons/navigation";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { setHistoryTutorialShown } from "state/actions";
import { Routes } from "utils";
import { ReceiptIcon } from "./ReceiptIcon";

export function HistoryTutorial() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { state } = useLocation();

  const appName = useAppName();

  const historyTutorialShown = useSelector(
    (store) => store.tutorial.historyTutorialShown
  );

  const [backToTutors] = useState(
    typeof state === "object" && state?.["from"] === Routes.Tutorials
  );

  const closeTutorial = () => {
    dispatch(setHistoryTutorialShown(true));

    if (backToTutors) {
      history.goBack();
    }
  };

  return historyTutorialShown ? null : (
    <CustomMobeaModal
      className="mobea__tutorial-dialog map-tutorial"
      withHeader={false}
    >
      <Tutorial
        steps={[
          {
            headline: t("tutorial.history_1_title", { appName }),
            image: <TicketIcon width="96" className="map-tutorial__icon" />,
            info: t("tutorial.history_1_body", { appName }),
          },
          {
            headline: t("tutorial.history_2_title", { appName }),
            image: <ReceiptIcon className="map-tutorial__icon" />,
            info: t("tutorial.history_2_body", { appName }),
          },
        ]}
        onClose={closeTutorial}
      />
    </CustomMobeaModal>
  );
}
