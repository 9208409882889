import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Auth } from "services/authTokenStore";
import { getFullConfig } from "services/configService";
import { pushLocation } from "utils";
import {
  customizeApp,
  getPersistedClientConfig,
  normalizeAppConfig,
  persistClientConfig,
} from "utils/configure";
import { getRedirectRoute } from "utils/routing";

/**
 * Cover case that user logs in and config has to be updated with full version
 * As well cover case that config expired and to be re-fetched, but in full
 */

export function useClientConfig() {
  const history = useHistory();

  const [loading, setLoading] = useState(true);

  const configReady = useSelector((state) => state.user.clientConfigReady);

  const loggedIn = Auth.hasToken();

  useEffect(() => {
    let canceled = false;

    async function updateConfig() {
      setLoading(true);

      const config = getPersistedClientConfig();

      // identify if we have full config or minimal
      if (loggedIn && (!config || config.minimal)) {
        const token = await Auth.getToken();

        if (token === null) {
          setLoading(false);

          pushLocation(history, getRedirectRoute());

          return;
        }

        const json = await getFullConfig(history);

        const loadedConfig = customizeApp(normalizeAppConfig(json));

        !loadedConfig.minimal && persistClientConfig();

        !canceled && setLoading(false);
      } else {
        // do nothing if full config is available as it was already applied on app start
        !canceled && setLoading(false);
      }
    }

    // don't update config next time
    !configReady && updateConfig();

    return () => {
      canceled = true;
    };
  }, [loggedIn, history, configReady]);

  return [loading] as const;
}
