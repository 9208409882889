import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getMapData,
  LocationCoords,
  MapData,
  toMapData,
} from "services/mapService";
import { TravelPassProvider, TravelPassType } from "utils";

const SEARCH_RADIUS_LIST = [1000, 3000, 5000, 10000];

export function useMapData(
  location: LocationCoords | null,
  passTypes: TravelPassType[],
  selectedProviderType: TravelPassProvider | null,
  enabled = true,
  searchRadiusList = SEARCH_RADIUS_LIST
) {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(false);

  const [data, setData] = useState<MapData | null>(null);

  const [searchRadius, setSearchRadius] = useState(SEARCH_RADIUS_LIST[0]);

  const allProviders = useSelector((state) => state.passes.providers);

  const providersMap = useMemo(() => {
    const map = new Map<TravelPassProvider, true>();
    allProviders.forEach((provider) => {
      map.set(provider.provider, true);
    });

    return map;
  }, [allProviders]);

  useEffect(() => {
    let canceled = false;

    setData(null);

    setSearchRadius(searchRadiusList[0]);

    if (!enabled || !location) {
      return;
    }

    if (!passTypes?.length && !selectedProviderType) {
      return;
    }

    const fetchMapData = async () => {
      try {
        setLoading(true);

        setError(false);

        for (const searchRadius of searchRadiusList) {
          setSearchRadius(searchRadius);

          const response = await getMapData(
            location!,
            passTypes,
            selectedProviderType,
            searchRadius,
            history
          );

          if (canceled) {
            return;
          }

          if (response.error) {
            setLoading(false);

            setError(true);

            console.error(
              "Failed to fetch static map data",
              response.error_description
            );

            return;
          } else {
            const providerData = toMapData(response)
              // drop data for providers who are not allowed by AppClient
              // these are not available in providers metadata
              .filter((provider) => providersMap.has(provider.type))
              // drop broken Mobit testing data
              .filter((provider) =>
                provider.type === "mobit" ? provider.id : true
              );

            if (providerData.length > 0) {
              setData(providerData);

              setLoading(false);

              return;
            }
          }
        }

        setData([]);

        setLoading(false);
      } catch (error) {
        if (!canceled) {
          setLoading(false);

          setError(true);

          console.error("Failed to fetch static map data", error);
        }
      }
    };

    fetchMapData();

    return () => {
      canceled = true;
    };
  }, [
    location,
    passTypes,
    selectedProviderType,
    searchRadiusList,
    enabled,
    history,
    providersMap,
  ]);

  return [searchRadius, data, loading, error] as const;
}
