import styled from "@emotion/styled";
import { Carousel } from "common/carousel/Carousel";
import { BottomNavBar, TabNavItem, TabPaneNav } from "common/navigation";
import { BriefcaseIcon } from "icons/BriefcaseIcon";
import { BusinessWallet } from "pages/home/plan/BusinessWallet";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { ApplicationVariant, Routes, WalletType } from "utils";
import { AppColors } from "utils/colors";
import { applicationVariant } from "utils/configure";
import { HistoryExpensesList } from "./expenses/HistoryExpensesList";
import "./HistoryPage.scss";
import { HistoryTicketsList } from "./tickets/HistoryTicketsList";
import { HistoryTopUpsList } from "./topups/HistoryTopUpsList";
import { HistoryTutorial } from "./tutorial/HistoryTutorial";

const WalletHeader = styled.div({
  label: "wallet-header",
  display: "flex",
  alignItems: "center",
  padding: "1rem 0 0.5rem 0",
  "& > :first-child": {
    color: "var(--color-primary)",
    backgroundColor: "rgba(var(--rgb-primary), 0.16)",
    borderRadius: "100vw",
    display: "flex",
    width: "2.5rem",
    height: "2.5rem",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 0.5rem 0 1rem",
  },
  "& svg": {
    width: "1rem",
    height: "1rem",
  },
});

const Card = styled.div({
  label: "card",
  backgroundColor: "white",
  borderRadius: 4,
  height: "100%",
});

const Shadow = styled.div({
  flex: "0 0 8px",
  margin: "1rem -2rem 0 -2rem",
  background:
    // generated with the following code:
    // `linear-gradient(to bottom, ${[...Array(6).keys()].map(x => x / 5).map(x => `rgba(0,0,0,${(0.05 * x ** 2).toPrecision(2)}) ${x * 100}%`).join(', ')})`
    "linear-gradient(to bottom, rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0020) 20%, rgba(0,0,0,0.0080) 40%, rgba(0,0,0,0.018) 60%, rgba(0,0,0,0.032) 80%, rgba(0,0,0,0.050) 100%)",
});

export function HistoryPage(): ReactElement {
  const history = useHistory();

  const location = useLocation();

  const { t } = useTranslation();

  const sp = new URLSearchParams(location.search);

  const tab = sp.get("tab") || "tickets";

  const wallet = sp.get("wallet") === WalletType.PRIVATE ? 1 : 0;

  const activeTab = tab === "tickets" ? 0 : 1;

  const historyTabs: TabNavItem[] = [
    { key: "tickets", label: t("history.tickets") },
    { key: "expenses", label: t("history.expenses") },
    { key: "topups", label: t("history.topups") },
  ];

  const go = (tab?: number, position?: number) => {
    const sp = new URLSearchParams();

    sp.set(
      "tab",
      (tab ?? activeTab) === 0
        ? "tickets"
        : (position ?? wallet) === 0
        ? "expenses"
        : "topups"
    );

    sp.set(
      "wallet",
      (position ?? wallet) === 0 ? WalletType.BUSINESS : WalletType.PRIVATE
    );

    history.replace(Routes.History + "?" + sp.toString());
  };

  const changeTab = (index: number) => {
    go(index);
  };

  const setWallet = (wallet: number) => {
    go(0, wallet);
  };

  return (
    <main className="mobea-history app-moveasy">
      <header
        className="mobea-history__header"
        css={{
          ".app-password &": {
            marginBottom: 0,
          },
        }}
      >
        <h1>{t("history.my_history")}</h1>
      </header>

      <Carousel
        css={{
          margin: "0 -32px",
          "& > *:last-child": {
            marginTop: "1rem",
          },
          "& > *:first-child": {
            marginLeft: "2rem",
            marginRight: "2rem",
          },
        }}
        stepsPlacement="bottom"
        position={wallet}
        onChange={setWallet}
        pageOffset={0}
        pages={[
          <Card key={1}>
            <WalletHeader>
              <div>
                <BriefcaseIcon />
              </div>
              {t("home.business_wallet")}
            </WalletHeader>
            <BusinessWallet />
          </Card>,
          // <Card key={2}>
          //   <WalletHeader>
          //     <div>
          //       <UserIcon />
          //     </div>
          //     {t("home.private_wallet")}
          //   </WalletHeader>
          //   <PrivateWallet />
          // </Card>,
        ]}
      />

      <Shadow />

      <div
        css={{
          backgroundColor: "white",
          margin: "0 -2rem",
          padding: "1.5rem 2rem 0 2rem",
          flexGrow: 1,
        }}
      >
        <header
          className="mobea-history__header"
          css={
            applicationVariant === ApplicationVariant.MOVEASY && {
              "& .mobea__tab-pane-nav": {
                color: AppColors.GRAY_200,
                backgroundColor: AppColors.BACKGROUND,
              },
            }
          }
        >
          <TabPaneNav
            tabs={historyTabs.filter(
              (ht) =>
                ht.key === "tickets" ||
                (wallet === 0 && ht.key === "expenses") ||
                (wallet === 1 && ht.key === "topups")
            )}
            active={activeTab}
            onTabChange={changeTab}
          />
        </header>

        <section className="mobea-history__tab-container">
          {activeTab === 0 && (
            <HistoryTicketsList
              wallet={wallet === 0 ? WalletType.BUSINESS : WalletType.PRIVATE}
            />
          )}
          {wallet === 0 && activeTab === 1 && <HistoryExpensesList />}
          {wallet === 1 && activeTab === 1 && <HistoryTopUpsList />}
        </section>
      </div>

      <BottomNavBar />

      <HistoryTutorial />
    </main>
  );
}
