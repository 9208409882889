import { Action } from "state";
import { getInitialStateWithPersistance } from "utils/helpers";
import { OrderingState } from "./stateTypes";

const initialState: OrderingState = {
  deLijnSingleTicket: {
    amount: 1,
    duration: 60,
    price: 2,
    tariff: 180,
    ticketTypeId: 4007,
  },
  nmbsStations: [],
  nmbsStationsUpdateTimestamp: 0,
  mobitDayPass: {
    price: 0,
    duration: 24,
    dayLimit: 5,
  },
  vertsLastOrigin: null,
  vertsLastDestination: null,
  termsAccepted: {},
  veloData: {
    minPasses: 1,
    maxPasses: 5,
    duration: 30, // minutes
    price: 5,
    validity: 24, // hours
    loaded: false,
  },
  blueBikeData: {
    loaded: false,
    maxPasses: 3,
    minPasses: 1,
    price: 4.5,
    validity: 24, // hours
    returnTime: 15, // minutes
  },
};

export function OrderingReducer(
  state: OrderingState = getInitialStateWithPersistance(
    "ordering",
    initialState
  ),
  action: Action
): OrderingState {
  switch (action.type) {
    case "SET_DE_LIJN_SINGLE_TICKET_DATA":
      return {
        ...state,
        deLijnSingleTicket: { ...action.data },
      };

    case "SET_NMBS_STATIONS":
      return {
        ...state,
        nmbsStations: action.stations,
        nmbsStationsUpdateTimestamp: Date.now(),
      };

    case "SET_MOBIT_PRICING":
      return {
        ...state,
        mobitDayPass: { ...action.pricing },
      };

    case "SET_VERTS_LAST_LOCATIONS":
      return {
        ...state,
        vertsLastOrigin: action.origin,
        vertsLastDestination: action.destination,
      };

    case "SET_TERMS_ACCEPTED":
      return {
        ...state,
        termsAccepted: {
          ...state.termsAccepted,
          ...action.providers,
        },
      };

    case "SET_VELO_DATA":
      return {
        ...state,
        veloData: { ...action.data },
      };

    case "SET_BLUE_BIKE_DATA":
      return {
        ...state,
        blueBikeData: { ...action.data },
      };

    default:
      return state;
  }
}
