import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { animated, useSpring } from "react-spring";
import { useGesture } from "react-use-gesture";

const NUMBER_HEIGHT = 88;

type Props = {
  className?: string;
  selectedIndex: number;
  setSelectedIndex: (index: number) => void;
  parentHeight: number;
  min: number;
  max: number;
  step?: number;
};

export function ScrollableNumbers({
  className = "",
  selectedIndex,
  setSelectedIndex,
  parentHeight,
  min,
  max,
  step = 1,
}: Props): ReactElement {
  const containerRef = useRef<HTMLDivElement>(null);

  const topPosition = useRef(0);

  const [scrollIndex, setScrollIndex] = useState(0);

  const [style, setStyle] = useSpring(() => ({
    transform: "translateY(0px)",
  }));

  const maxIndex = Math.floor((max - min) / step);

  const totalHeight = (maxIndex + 1) * NUMBER_HEIGHT;

  const getPositionFromIndex = useCallback(
    (index: number) => {
      return (
        index * -NUMBER_HEIGHT +
        parentHeight / 2 -
        NUMBER_HEIGHT / 2 -
        totalHeight * scrollIndex
      );
    },
    [parentHeight, scrollIndex, totalHeight]
  );

  const getIndexFromPosition = (position: number) => {
    return Math.round(
      (-totalHeight * scrollIndex -
        position +
        parentHeight / 2 -
        NUMBER_HEIGHT / 2) /
        NUMBER_HEIGHT
    );
  };

  useGesture(
    {
      onDragStart: () => {
        topPosition.current = getPositionFromIndex(selectedIndex);
      },
      onDrag: ({ event, movement: [, y] }) => {
        if (y !== 0) {
          event?.preventDefault();

          event?.stopPropagation();

          setStyle({
            transform: `translateY(${topPosition.current + y}px)`,
          });
        }
      },
      // @ts-ignore: TS does not recognize the function correctly
      onDragEnd: ({ movement: [, y] }) => {
        const index = getIndexFromPosition(topPosition.current + y);
        if (index > maxIndex) {
          setScrollIndex(scrollIndex + 1);
        } else if (index < 0) {
          setScrollIndex(scrollIndex - 1);
        }

        setSelectedIndex(index);

        setStyle({
          transform: `translateY(${getPositionFromIndex(index)}px)`,
        });
      },
    },
    {
      domTarget: containerRef,
      eventOptions: { passive: false },
    }
  );

  useEffect(() => {
    setStyle({
      transform: `translateY(${getPositionFromIndex(selectedIndex)}px)`,
    });
  }, [selectedIndex, parentHeight, setStyle, getPositionFromIndex]);

  const renderNumbers = () => {
    const numbers: ReactNode[] = [];

    for (let number = min; number <= max; number += step) {
      numbers.push(
        <div
          key={number}
          className={`scrollable-numbers__number ${
            selectedIndex === number / step ? "active" : ""
          }`}
        >
          {number.toLocaleString(undefined, {
            minimumIntegerDigits: 2,
          })}
        </div>
      );
    }
    return numbers;
  };

  return (
    <animated.div
      ref={containerRef}
      className={`scrollable-numbers ${className}`}
      style={style}
    >
      <div
        className="scrollable-numbers__container"
        style={{
          transform: `translateY(${(scrollIndex - 1) * totalHeight}px)`,
        }}
      >
        {renderNumbers()}
      </div>
      <div
        className="scrollable-numbers__container"
        style={{
          transform: `translateY(${scrollIndex * totalHeight}px)`,
        }}
      >
        {renderNumbers()}
      </div>
      <div
        className="scrollable-numbers__container"
        style={{
          transform: `translateY(${(scrollIndex + 1) * totalHeight}px)`,
        }}
      >
        {renderNumbers()}
      </div>
    </animated.div>
  );
}
