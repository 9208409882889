import { History } from "history";
import {
  JourneyType,
  PassengerType,
  TravelClass,
} from "state/actions/TravelPassActions";
import {
  ADMIN_API_BASE,
  ApiErrorResponse,
  ApiErrors,
  CORE_API_BASE,
  WalletType,
} from "utils";
import { callSecuredApiEndpoint } from "./apiCall";

export type NmbsStationsListResponse =
  | {
      error: false;
      stations: {
        Id: number;
        Language: "NL" | "FR";
        Name: string;
      }[];
    }
  | ApiErrorResponse;

function getNmbsStationsListRequest(authorization: string) {
  return fetch(`${CORE_API_BASE}providers/nmbs/get-stations`, {
    method: "GET",
    headers: new window.Headers({
      Authorization: authorization,
    }),
  }).then((response) => response.json());
}

export function getNmbsStationsList(history: History) {
  return callSecuredApiEndpoint(getNmbsStationsListRequest, history).then(
    (json) => {
      if (json.error_code) {
        return {
          error: true,
          ...json,
        };
      }
      return {
        error: false,
        stations: json,
      };
    }
  ) as Promise<NmbsStationsListResponse>;
}

export interface NmbsGetProductsPayload {
  departure: {
    station: number; // station ID
    date: string; // ISO date string
  };
  destination: {
    station: number; // station ID
    date: string; // ISO date string
  };
  journeyType: JourneyType;
  travelClass: TravelClass;
  travelType: PassengerType;
}

export enum NmbsTicketType {
  // t("order_nmbs.ticket.40000145.name")
  // t("order_nmbs.ticket.40000145.description")
  // t("order_nmbs.ticket.40000145.info")
  Youth = 40000145,

  // t("order_nmbs.ticket.40000090.name")
  // t("order_nmbs.ticket.40000090.description")
  // t("order_nmbs.ticket.40000090.info")
  Standard = 40000090,

  // t("order_nmbs.ticket.40000131.name")
  // t("order_nmbs.ticket.40000131.description")
  // t("order_nmbs.ticket.40000131.info")
  Weekend = 40000131,

  // t("order_nmbs.ticket.40000150.name")
  // t("order_nmbs.ticket.40000150.description")
  // t("order_nmbs.ticket.40000150.info")
  Senior = 40000150,

  // t("order_nmbs.ticket.40000091.name")
  // t("order_nmbs.ticket.40000091.description")
  // t("order_nmbs.ticket.40000091.info")
  Child = 40000091,
}

export type NmbsTicket = {
  price: number;
  type: NmbsTicketType;
};

export function toNmbsTicket(product: NmbsProduct): NmbsTicket {
  return {
    price: product.Price,
    type: product.ProductId as NmbsTicketType,
  };
}

export type NmbsProduct = {
  Price: number;
  ProductId: number;
  Description: string;
};

export type NmbsGetProductsResponse =
  | {
      error: false;
      Product: NmbsProduct[];
    }
  | ApiErrorResponse;

function getNmbsProductsRequest(productRequest: NmbsGetProductsPayload) {
  return (authorization: string) =>
    fetch(`${CORE_API_BASE}providers/nmbs/get-products`, {
      method: "POST",
      headers: new window.Headers({
        Authorization: authorization,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(productRequest),
    }).then(async (response) => {
      try {
        return await response.json();
      } catch (e) {
        if (response.status == 200) {
          return {
            Product: [],
          };
        } else {
          throw e;
        }
      }
    });
}

export function getNmbsProducts(
  productRequest: NmbsGetProductsPayload,
  history: History
) {
  return callSecuredApiEndpoint(
    getNmbsProductsRequest(productRequest),
    history
  ).then(
    (json) => ({
      error: !!json.error_code,
      ...json,
    }),
    (error) => {
      console.error(error);

      return {
        error: true,
      } as ApiErrorResponse;
    }
  ) as Promise<NmbsGetProductsResponse>;
}

export type NmbsOrderPayload = {
  departure: {
    station: number; // station ID
    date: string; // ISO date string
  };
  destination: {
    station: number; // station ID
    date: string; // ISO date string
  };
  journeyType: JourneyType;
  price: number;
  firstName: string;
  lastName: string;
  dateOfBirth: string; //"1984-09-27"
  productId: number; // see NmbsTicketType
  travelClass: TravelClass;
  travelType: PassengerType;
  wallet: WalletType;
};

export type RawNmbsTicket = {
  Tickets: {
    Ticket: {
      Barcode: string;
      DepartureStationId: number;
      DepartureStationName: string;
      DestinationStationId: number;
      DestinationStationName: string;
      LongSmsCode: string;
      ProductId: number;
      ProductName: string;
      ShortSmsCode: string;
      TicketNumber: string;
      TravelDate: string; // Date string
    }[];
  };
  journeyType: "RoundTrip" | "SingleTrip";
  travelClass: "FirstClass" | "SecondClass";
  transaction_id: string;
  parent_transaction_id?: string;
  first_name?: string;
  last_name?: string;
  TransactionNumber: string;
};

export type NmbsOrderResponse = [
  | (RawNmbsTicket & {
      error: false;
    })
  | ApiErrorResponse
];

const orderNmbsTicketRequest =
  (orderRequest: NmbsOrderPayload) => (authorization: string) =>
    fetch(`${ADMIN_API_BASE}nmbs/orders`, {
      method: "POST",
      headers: new window.Headers({
        Authorization: authorization,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        data: {
          departure: orderRequest.departure,
          destination: orderRequest.destination,
          journeyType: orderRequest.journeyType,
          price: orderRequest.price,
          first_name: orderRequest.firstName,
          last_name: orderRequest.lastName,
          date_of_birth: orderRequest.dateOfBirth,
          productId: orderRequest.productId,
          travelClass: orderRequest.travelClass,
          plan_type: orderRequest.wallet,
        },
      }),
    }).then((response) => response.json());

export function orderNmbsTicket(
  orderRequest: NmbsOrderPayload,
  history: History
) {
  return callSecuredApiEndpoint(
    orderNmbsTicketRequest(orderRequest),
    history
  ).then(
    (json) => {
      if (json.error_code) {
        return [
          {
            error: true,
            ...json,
          } as ApiErrorResponse,
        ];
      } else {
        return json.map((ticketResponse) => ({
          error: !!ticketResponse.error_code,
          ...ticketResponse,
        }));
      }
    },
    (error) => {
      console.error(error);

      return [
        {
          error: true,
          error_code: ApiErrors.ORDER_ERROR,
          error_type: "Order failed",
          error_description: "Order failed due to error",
        } as ApiErrorResponse,
      ];
    }
  ) as Promise<NmbsOrderResponse>;
}
