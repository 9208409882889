import { ReactElement, ReactNode } from "react";

export interface MapButtonGroupProps {
  location: string;
  children: ReactNode;
}

export function MapButtonGroup({
  location,
  children,
}: MapButtonGroupProps): ReactElement {
  return (
    <div className={`mobea__map-actions mobea__map-actions__${location}`}>
      {children}
    </div>
  );
}
