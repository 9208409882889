import { MobeaButton } from "common/forms";
import { ReactElement } from "react";
import { Trans, useTranslation } from "react-i18next";

interface LinkSentProps {
  phoneNumber: string;
  tryAgain(): void;
}

export function LinkSent({
  phoneNumber,
  tryAgain,
}: LinkSentProps): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="mobea-forgotten-password__sent">
      <h2 className="mobea-forgotten-password__headline">
        {t("forgotten_password.link_sent")}
      </h2>
      <p className="mobea-forgotten-password__text">
        <Trans i18nKey="forgotten_password.link_sent_text">
          We’ve sent you a SMS with the recovery link to this phone number
          <br />
          <span className="mobea__arial phone-number">{{ phoneNumber }}</span>.
        </Trans>
      </p>
      <p className="mobea-forgotten-password__text last">
        {t("forgotten_password.did_not_get_it")}
      </p>
      <MobeaButton
        className="send-again-button"
        type="tertiary"
        onClick={tryAgain}
      >
        {t("forgotten_password.send_again")}
      </MobeaButton>
    </div>
  );
}
