import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

export function RefreshIcon({
  fill = "currentColor",
  className = "",
  height = "16",
  width = "16",
  onClick,
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 0 16 16"
      width={width}
      onClick={onClick}
    >
      <path
        d="M8 4v2l2.667-2.667L8 .667v2A5.332 5.332 0 002.667 8c0 1.047.306 2.02.826 2.84l.974-.973A3.914 3.914 0 014 8c0-2.207 1.793-4 4-4zm4.507 1.16l-.974.973C11.827 6.693 12 7.327 12 8c0 2.207-1.793 4-4 4v-2l-2.667 2.667L8 15.333v-2A5.332 5.332 0 0013.333 8c0-1.047-.306-2.02-.826-2.84z"
        fill={fill}
      />
    </svg>
  );
}
