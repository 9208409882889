import { LocationCursorIcon } from "icons/navigation";
import { RouteDirectionsButton } from "pages/map/RouteDirectionsButton";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { LocationSearchSuggestion } from "services/mapService";
import { formatDistance } from "utils";
import { AppColors } from "utils/colors";

type Props = {
  locale: string;
  location: LocationSearchSuggestion;
  showRoute?(): void;
};

export function SearchLocationDetails({
  locale,
  location,
  showRoute,
}: Props): ReactElement {
  const { t } = useTranslation();

  const addressChunks = location.label.split(", ");

  return (
    <div className="search-location-details">
      <div className="div search-location-details__address-distance">
        <div className="search-location-details__address-distance__title">
          {addressChunks[0]}
        </div>

        {addressChunks.length > 1 && (
          <div className="search-location-details__address-distance__subtitle">
            {addressChunks.slice(1).join(", ")}
          </div>
        )}

        {location.distance !== null && (
          <div className="search-location-details__address-distance__distance">
            <LocationCursorIcon
              width={20}
              height={20}
              fill={AppColors.GRAY_200}
            />
            {location.distance >= 1000
              ? t("map.distance_km", {
                  distance: formatDistance(location.distance / 1000, locale),
                })
              : t("map.distance", {
                  distance: Math.round(location.distance || 0),
                })}
          </div>
        )}
      </div>

      {showRoute && <RouteDirectionsButton onClick={showRoute} />}
    </div>
  );
}
