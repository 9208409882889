import { MobeaModal } from "common/modal";
import { SadIcon } from "icons/SadIcon";
import { ReactChildren } from "react";
import { useTranslation } from "react-i18next";
import "./StandardErrorDialog.scss";

type Props = {
  title?: string;
  confirmText?: string;
  children?: ReactChildren;
  onConfirm(): void;
};

export function StandardErrorDialog({
  title,
  confirmText,
  children,
  onConfirm,
}: Props) {
  const { t } = useTranslation();

  return (
    <MobeaModal
      confirmText={confirmText ?? t("shared.ok")}
      title={title ?? t("shared.something_went_wrong")}
      type="error"
      onConfirm={onConfirm}
      className="mobea__standard-error-dialog"
      image={<SadIcon width="37" height="37" />}
    >
      {children ?? <p>{t("shared.something_went_wrong_text")}</p>}
    </MobeaModal>
  );
}
