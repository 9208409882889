import { History } from "history";
import { BikeStationStatus } from "maps/bike/BikeStationTypes";
import { VeloStatus } from "state/actions";
import {
  ADMIN_API_BASE,
  ApiErrorResponse,
  ApiErrors,
  CORE_API_BASE,
} from "utils";
import { callSecuredApiEndpoint } from "./apiCall";

export interface RawVeloDayPass {
  transaction_id: string;
}

export interface VeloTicketMetadata {
  status: VeloStatus;
  account_id?: number | string;
  key_code?: number | string;
  activation_datetime?: number;
  fine: boolean;
}
export interface VeloStation {
  id: string;
  status: BikeStationStatus;
  availability: {
    bikes: number;
    slots: number;
  };
}

export type VeloDataResponse =
  | {
      error: false;
      min_passes_bought: string;
      max_passes_bought: string;
      validity_period_hours: string;
      free_riding_period_minutes: string;
      day_pass_price: string;
    }
  | ApiErrorResponse;

const getVeloDataRequest = (authorization: string) =>
  fetch(`${CORE_API_BASE}providers/velo-antwerpen/data`, {
    method: "GET",
    headers: new window.Headers({
      Authorization: authorization,
    }),
  }).then((response) => response.json());

export function getVeloData(history: History) {
  return callSecuredApiEndpoint(getVeloDataRequest, history).then((json) => ({
    ...json,
    error: !!json.error_code,
  })) as Promise<VeloDataResponse>;
}

export type VeloOrderResponse = [
  | (RawVeloDayPass & {
      error: false;
    })
  | ApiErrorResponse
];

function orderVeloDayPassRequest(
  wallet: "private" | "business",
  amount: number
) {
  return (authorization: string) =>
    fetch(`${ADMIN_API_BASE}velo-antwerpen/orders`, {
      method: "POST",
      headers: new window.Headers({
        "Content-Type": "application/json",
        Authorization: authorization,
      }),
      body: JSON.stringify({
        data: {
          amount,
          plan_type: wallet,
        },
      }),
    })
      .then((response) => {
        if (response.status === 423) {
          return [
            {
              error: true,
              error_code: ApiErrors.VELO_NO_PASSES_AVAILABLE,
              error_description: "No passes available",
              error_type: "no_passes",
            } as ApiErrorResponse,
          ];
        } else {
          return response.json();
        }
      })
      .then((json) => {
        // global error - e.g. not enough budget
        if (json.error_code) {
          return [
            {
              error: true,
              ...json,
            },
          ];
        }

        return json.map((ticketResponse) => ({
          ...ticketResponse,
          error: !!ticketResponse.error_code,
        }));
      });
}

export function orderVeloDayPass(
  wallet: "private" | "business",
  amount: number,
  history: History
) {
  return callSecuredApiEndpoint(
    orderVeloDayPassRequest(wallet, amount),
    history
  ) as Promise<VeloOrderResponse>;
}

export type VeloActivateResponse =
  | ({
      error: false;
    } & RawVeloDayPass)
  | ApiErrorResponse;

function activateVeloDayPassRequest(ticketId: string) {
  return (authorization: string) =>
    fetch(`${CORE_API_BASE}providers/velo-antwerpen/activate/${ticketId}`, {
      method: "PUT",
      headers: new window.Headers({
        "Content-Type": "application/json",
        Authorization: authorization,
      }),
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((json) => ({
        ...json,
        error: !!json.error_code,
      })) as Promise<VeloActivateResponse>;
}

export function activateVeloDayPass(ticketId: string, history: History) {
  return callSecuredApiEndpoint(activateVeloDayPassRequest(ticketId), history);
}

export type VeloStationResponse =
  | ({
      error: false;
    } & VeloStation)
  | ApiErrorResponse;

function getVeloStationRequest(stationId: string) {
  return (authorization: string) =>
    fetch(`${CORE_API_BASE}providers/velo-antwerpen/stations/${stationId}`, {
      method: "GET",
      headers: new window.Headers({
        Authorization: authorization,
      }),
    })
      .then((response) => response.json())
      .then((json) => ({
        ...json,
        error: !!json.error_code,
      })) as Promise<VeloStationResponse>;
}

export function getVeloStation(stationId: string, history: History) {
  return callSecuredApiEndpoint(getVeloStationRequest(stationId), history);
}
