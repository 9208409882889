import { MobeaButton } from "common/forms";
import { useContactUsModal } from "common/modal";
import { NestedPage } from "common/page/NestedPage";
import { usePhoneNumbersValidation } from "common/phoneNumberPicker";
import { PhoneNumberPicker } from "common/phoneNumberPicker/PhoneNumberPicker";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { sendPasswordResetLink } from "services/passwordService";
import { ApiErrors, removeSpaces } from "utils";
import { getConfig } from "utils/configure";
import "./ForgottenPasswordPage.scss";
import { LinkSent } from "./LinkSent";
import {
  SendForgottenPasswordErrorDialog,
  SendForgottenPasswordErrorType,
} from "./SendForgottenPasswordErrorDialog";

export function ForgottenPasswordPage(): ReactElement {
  const { t } = useTranslation();

  const { clientId } = getConfig();

  const [
    prefix,
    localNumberFormatted,
    valid,
    onPrefixChange,
    updatePhoneNumber,
    reset,
  ] = usePhoneNumbersValidation();

  const [pendingRequest, setPendingRequest] = useState(false);

  const [requestError, setRequestError] =
    useState<SendForgottenPasswordErrorType | null>(null);

  const [smsSentSuccess, setSmsSentSuccess] = useState(false);

  const tryAgain = () => {
    setRequestError(null);
    reset();
    setSmsSentSuccess(false);
  };

  const { show: showContactUs } = useContactUsModal({ onClose: tryAgain });

  const requestPassword = () => {
    setPendingRequest(true);

    sendPasswordResetLink(
      prefix.code,
      removeSpaces(localNumberFormatted),
      clientId
    )
      .then((response) => {
        setPendingRequest(false);

        if (response.success) {
          setSmsSentSuccess(true);
        } else if (response.error) {
          setRequestError(response.error_code as ApiErrors);
        }
      })
      .catch((error) => {
        console.error(error);

        setPendingRequest(false);

        setRequestError("external");
      });
  };

  const submitDisabled = !valid || pendingRequest;

  const phoneNumber = `${prefix.code} ${localNumberFormatted}`;

  return (
    <NestedPage
      pageClassName="mobea-forgotten-password"
      title={t("forgotten_password.password_recovery")}
      headerInSecondaryColor
    >
      {!smsSentSuccess && (
        <>
          <h2 className="mobea-forgotten-password__headline">
            {t("forgotten_password.no_worries")}
          </h2>

          <p className="mobea-forgotten-password__text">
            {t("forgotten_password.password_recovery_text")}
          </p>

          <label className="mobea_field_label">
            {t("shared.phone_number")}
          </label>

          <PhoneNumberPicker
            prefix={prefix}
            localNumberFormatted={localNumberFormatted}
            valid={valid}
            onPrefixChange={onPrefixChange}
            onPhoneNumberChange={updatePhoneNumber}
            onSubmit={requestPassword}
          />

          <MobeaButton
            disabled={submitDisabled}
            onClick={requestPassword}
            loading={pendingRequest}
          >
            {pendingRequest && t("verification.checking")}
            {!pendingRequest && t("shared.submit")}
          </MobeaButton>
        </>
      )}
      {smsSentSuccess && (
        <LinkSent phoneNumber={phoneNumber} tryAgain={tryAgain} />
      )}
      {requestError && (
        <SendForgottenPasswordErrorDialog
          errorType={requestError}
          tryAgain={tryAgain}
          contactUs={showContactUs}
        />
      )}
    </NestedPage>
  );
}
