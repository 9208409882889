import { DefaultIconProps } from "icons/props";
import { ReactElement } from "react";

export function EndIcon({
  className = "",
  fill = "currentColor",
  height = "33",
  width = "33",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="3 3 33 33"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={fill}
        d="M20.9 5.8V18h12.4V21H20.9v12.4H18V20.9H5.7V18h12.4V5.7H21z"
        transform="rotate(45 19.5 19.5)"
      />
    </svg>
  );
}
