import styled from "@emotion/styled";
import { DateOfBirthPicker } from "common/datepicker/dateOfBirth/DateOfBirthPicker";
import { InputGroup, MobeaButton } from "common/forms";
import { useSelectedWallet } from "common/hooks/useSelectedWallet";
import { NmbsTravelPassRoute } from "common/travelPasses/nmbs/NmbsTravelPassRoute";
import { EuroIcon } from "icons/EuroIcon";
import { LabelIcon } from "icons/LabelIcon";
import { WalletIcon } from "icons/WalletIcon";
import { ReactElement, ReactText, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NmbsTicket } from "services/nmbsService";
import { JourneyType, TravelClass } from "state/actions";
import { ApplicationVariant, formatCurrency } from "utils";
import { AppColors } from "utils/colors";
import { applicationVariant } from "utils/configure";
import "./AddPassengerDetails.scss";

const StyledLabel = styled.span({
  color: AppColors.GRAY_400,
  fontSize: 12,
  lineHeight: 1.33,
  marginBottom: 4,
});

const StyledValue = styled.p({
  margin: 0,
  padding: 0,
  fontSize: 14,
  lineHeight: 1.71,
  fontWeight: "bold",
  "@media (max-width: 360px)": {
    fontSize: 12,
  },
});

export interface PassengerDetails {
  name: string;
  surname: string;
  dateOfBirth: string;
}

export interface AddPassengerDetailsProps {
  departure: string;
  destination: string;
  journeyType: JourneyType;
  travelClass: TravelClass;
  locale: string;
  ticket: NmbsTicket;
  ordering: boolean;
  onConfirm(details: PassengerDetails): void;
  useCustomerData: boolean;
}

export function AddPassengerDetails({
  departure,
  destination,
  journeyType,
  travelClass,
  locale,
  ticket,
  ordering,
  onConfirm,
  useCustomerData,
}: AddPassengerDetailsProps): ReactElement {
  const { t } = useTranslation();

  const selectedWallet = useSelectedWallet();

  const {
    name: customerName,
    surname: customerSurname,
    dateOfBirth: customerDateOfBirth,
  } = useSelector((state) => state.user);

  const [name, setName] = useState(useCustomerData ? customerName : "");

  const [surname, setSurname] = useState(
    useCustomerData ? customerSurname : ""
  );

  const [dateOfBirth, setDateOfBirth] = useState(
    useCustomerData ? customerDateOfBirth : ""
  );

  const onNameChange = (value: ReactText) => {
    setName(value.toString());
  };

  const onSurnameChange = (value: ReactText) => {
    setSurname(value.toString());
  };

  const onDateOfBirthChange = (_, value: string) => {
    setDateOfBirth(value);
  };

  const onSubmit = () => {
    onConfirm({
      name,
      surname,
      dateOfBirth,
    });
  };

  const nameLabel = t("passenger.first_name");

  const surnameLabel = t("passenger.last_name");

  const dateOfBirthLabel = t("passenger.date_of_birth");

  const validName =
    name?.length > 1 &&
    surname?.length > 1 &&
    isISO_8859_1(name) &&
    isISO_8859_1(surname);

  const readyToOrder = validName && dateOfBirth;

  const nameErrorMsg = !isISO_8859_1(name.toString())
    ? t("passenger.invalid_character")
    : t("passenger.name_error");

  const surnameErrorMsg = !isISO_8859_1(surname.toString())
    ? t("passenger.invalid_character")
    : t("passenger.name_error");

  return (
    <section className="mobea-passenger-details">
      <section className="mobea-passenger-details__form">
        <InputGroup
          className="mobea-passenger-details__form__name"
          label={nameLabel}
          name="name"
          type="text"
          value={name}
          placeholder={nameLabel}
          toggleLabelAndPlaceholder
          validation={(value) =>
            value.toString().length > 1 && isISO_8859_1(value.toString())
          }
          initialValidation={(value) => isISO_8859_1(value.toString())}
          errorMessage={nameErrorMsg}
          onChange={onNameChange}
          inputAttributes={{
            autoComplete: "off",
          }}
        />
        <InputGroup
          className="mobea-passenger-details__form__surname"
          label={surnameLabel}
          name="surname"
          type="text"
          value={surname}
          placeholder={surnameLabel}
          toggleLabelAndPlaceholder
          validation={(value) =>
            value.toString().length > 1 && isISO_8859_1(value.toString())
          }
          initialValidation={(value) => isISO_8859_1(value.toString())}
          errorMessage={surnameErrorMsg}
          onChange={onSurnameChange}
          inputAttributes={{
            autoComplete: "off",
          }}
        />
        <DateOfBirthPicker
          title={dateOfBirthLabel}
          dateInputLabel={dateOfBirthLabel}
          className="mobea-passenger-details__form__dob"
          locale={locale}
          selectedDate={dateOfBirth}
          onChange={onDateOfBirthChange}
        />
      </section>

      <section className="mobea-passenger-details__empty-space" />

      <section className="mobea-passenger-details__summary">
        {applicationVariant === ApplicationVariant.MOVEASY && (
          <>
            <NmbsTravelPassRoute
              showLabels
              departure={departure}
              destination={destination}
              journeyType={journeyType}
              css={{
                minHeight: "auto",
                marginBottom: 8,
                ".mobea__nmbs-route__direction-label": {
                  display: "none",
                },
                ".mobea__nmbs-route__destination, .mobea__nmbs-route__departure":
                  {
                    textAlign: "left",
                    lineHeight: "24px",
                    fontWeight: "bold",
                    "> span": {
                      fontWeight: "normal",
                    },
                  },
              }}
            />
            <div
              css={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: 8,
              }}
            >
              <div>
                <StyledLabel>
                  <WalletIcon
                    width={16}
                    height={16}
                    css={{ verticalAlign: "text-top", marginRight: 4 }}
                  />
                  {t("wallet.wallet")}
                </StyledLabel>
                {selectedWallet && (
                  <StyledValue>
                    {t(`wallet.${selectedWallet}_short`)}
                  </StyledValue>
                )}
              </div>

              <div>
                <StyledLabel>
                  <LabelIcon
                    width={16}
                    height={16}
                    outlined
                    css={{ verticalAlign: "text-top", marginRight: 4 }}
                  />
                  {t(`order_nmbs.ticket.${ticket.type}.name`)}
                </StyledLabel>
                <StyledValue>
                  {t(`nmbs_detail.journey.${journeyType}`)}
                  <span>&nbsp;-&nbsp;</span>
                  {travelClass === TravelClass.FIRST
                    ? t("order_nmbs.first_class")
                    : t("order_nmbs.second_class")}
                </StyledValue>
              </div>

              <div>
                <StyledLabel>
                  <EuroIcon
                    width={16}
                    height={16}
                    css={{ verticalAlign: "text-top", marginRight: 4 }}
                  />
                  {t("shared.total_price")}
                </StyledLabel>
                <StyledValue>
                  {formatCurrency(ticket.price, locale)}
                </StyledValue>
              </div>
            </div>
          </>
        )}

        <MobeaButton
          disabled={!readyToOrder || ordering}
          loading={ordering}
          onClick={onSubmit}
        >
          {ordering ? t("shared.in_progress") : t("order_nmbs.buy_ticket")}
        </MobeaButton>
      </section>
    </section>
  );
}

function isISO_8859_1(str: string) {
  return !/[^\u0000-\u00ff]/g.test(str);
}
