import { StandardErrorDialog } from "common/errors/StandardErrorDialog";
import { InputGroup, MobeaButton } from "common/forms";
import { useUpdatedCustomer } from "common/hooks";
import { NestedPage } from "common/page/NestedPage";
import { Spinner } from "common/Spinner";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ApiErrors, formatIban, removeSpaces, validateIban } from "utils";
import { ProfileUpdateSuccessDialog } from "../profile/dialogs/ProfileUpdateSuccessDialog";
import { useUpdateProfile } from "../profile/hooks/useUpdateProfile";
import "./RefundBillingPage.scss";

export function RefundBillingPage(): ReactElement {
  const { t } = useTranslation();
  const history = useHistory();

  const loading = useUpdatedCustomer();

  const { customerId, iban: userIban } = useSelector((state) => state.user);

  const [iban, setIban] = useState("");

  const [result, updating, updateProfile, reset] = useUpdateProfile();

  const ibanValid = validateIban(iban).valid;

  const saveEnabled = iban != userIban && ibanValid;

  useEffect(() => {
    setIban(userIban);
  }, [userIban]);

  const doProfileUpdate = () => {
    updateProfile(customerId, {
      iban,
    });
  };

  return (
    <NestedPage
      pageClassName="mobea-refund-billing"
      title={t("settings.refund_billing")}
      headerInSecondaryColor
    >
      {loading && <Spinner />}
      {!loading && (
        <>
          <p className="mobea-refund-billing__text">
            {t("profile.refund_billing_text")}
          </p>
          <InputGroup
            className="mobea-refund-billing__iban"
            label={t("profile.iban")}
            name="iban"
            value={formatIban(removeSpaces(iban))}
            placeholder="BE00 0000 0000 0000"
            errorMessage={t("iban.invalid_text")}
            validation={(value) => validateIban(value.toString()).valid}
            onChange={(iban) => setIban(removeSpaces(iban.toString()))}
          />
          <section className="mobea-refund-billing__empty-space"></section>
          <section className="mobea-refund-billing__button">
            <MobeaButton
              disabled={!saveEnabled || updating}
              onClick={doProfileUpdate}
              loading={updating}
            >
              {updating ? t("shared.saving") : t("shared.save")}
            </MobeaButton>
          </section>
          {result === "success" && (
            <ProfileUpdateSuccessDialog onConfirm={() => history.goBack()} />
          )}
          {result && result in ApiErrors && (
            <StandardErrorDialog onConfirm={reset} />
          )}
        </>
      )}
    </NestedPage>
  );
}
