import { ReactElement } from "react";
import "./OptionLoader.scss";

export function OptionLoader({ running = true }): ReactElement {
  return (
    <div className={`mobea-option-loader ${running ? "running" : ""}`}>
      <div className="icon-placeholder" />
      <div className="name-info-placeholders">
        <div className="name-info-placeholders__name" />
        <div className="name-info-placeholders__info" />
      </div>
    </div>
  );
}
