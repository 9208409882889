import { DefaultIconProps } from "icons/props";
import { ReactElement } from "react";
import { HorizontalClassicFlagIcon } from "./HorizontalClassicFlag";

export function NetherlandsIcon(props: DefaultIconProps): ReactElement {
  return (
    <HorizontalClassicFlagIcon
      firstColor="#d80027"
      secondColor="#fff"
      thirdColor="#1c448c"
      {...props}
    />
  );
}
