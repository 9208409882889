import { RefObject, useEffect } from "react";
import { LocationCoords } from "services/mapService";
import { AppColors, getDerivedAppColorTransparent } from "utils/colors";

export function useRadiusCircle(
  mapClassRef: RefObject<H.Map>,
  radiusGroupRef: RefObject<H.map.Group | null>,
  searchLocation: LocationCoords | null,
  searchRadius: number
) {
  // set radius circle for search location
  useEffect(() => {
    const map = mapClassRef.current;

    const radiusGroup = radiusGroupRef.current;

    if (!radiusGroup || !searchLocation || !map) {
      return;
    }

    radiusGroup.removeAll();

    const radiusCircle = new H.map.Circle(searchLocation, searchRadius, {
      style: {
        lineWidth: 0,
        fillColor: getDerivedAppColorTransparent(AppColors.PRIMARY_EXTRA_LIGHT),
      },
    });

    radiusGroup.addObject(radiusCircle);

    if (
      !map
        .getViewModel()
        .getLookAtData()
        .bounds?.getBoundingBox()
        .containsRect(radiusCircle.getGeometry().getBoundingBox())
    ) {
      map.getViewModel().setLookAtData(
        {
          bounds: radiusCircle.getGeometry().getBoundingBox(),
        },
        true
      );
    }
  }, [mapClassRef, radiusGroupRef, searchLocation, searchRadius]);
}
