import { css } from "@emotion/react";
import { MobeaButton } from "common/forms";
import { useBooleanState, useReduxState } from "common/hooks";
import { SourceDestPicker } from "common/routePicker/SourceDestPicker";
import { OptionLoader } from "common/search";
import { CloseIcon } from "icons/CloseIcon";
import { NavBackIcon } from "icons/NavBackIcon";
import { inSameLocation } from "maps";
import { useRoutePlanData } from "pages/map/hooks/useRoutePlanData";
import { NoProvidersAvailable } from "pages/map/NoProvidersAvailable";
import { RoutePlannerError } from "pages/map/routePlanner/RoutePlannerError";
import { TripDetails } from "pages/map/routePlanner/trip/TripDetails";
import { ProviderFilterType } from "pages/providers/filter/ProviderFilterType";
import { ProvidersFilter } from "pages/providers/filter/ProvidersFilter";
import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  LocationSearchPlace,
  MyLocationSearchPlace,
  RoutePlanTrip,
  TravelTimeOption,
} from "services/mapService";
import {
  setRoutePlannerSearchAction,
  setSelectedRoutePlanTripAction,
  updateSelectedRoutePlanTripAction,
} from "state/actions";
import { AppColors } from "utils/colors";
import "./RoutePlanner.scss";
import { TravelTimePicker } from "./travelTime/TravelTimerPicker";
import { TripOptions } from "./trip/TripOptions";
import { TripSegments } from "./trip/TripSegments";

const SLIDER_HEAD_HEIGHT = 60;

export enum LocationPickerTarget {
  Source = "from",
  Destination = "to",
}

interface Props {
  initialSource?: LocationSearchPlace | MyLocationSearchPlace | null;
  initialDestination?: LocationSearchPlace | null;
  onClose(): void;
  onSourceChange?: (source: LocationSearchPlace | null) => void;
  onDestinationChange?: (destination: LocationSearchPlace | null) => void;
}

export function RoutePlanner({
  initialSource,
  initialDestination,
  onClose,
  onSourceChange,
  onDestinationChange,
}: Props): ReactElement {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const sourceInitializedRef = useRef(false);

  const destinationInitializedRef = useRef(false);

  const [selectedTrip, setSelectedTrip] = useReduxState(
    (state) => state.map.selectedRoutePlannerTrip,
    setSelectedRoutePlanTripAction
  );

  const [search, setSearch] = useReduxState(
    (state) => state.map.routePlannerSearchResult,
    setRoutePlannerSearchAction
  );

  const [source, setSource] = useState<MyLocationSearchPlace | null>(
    initialSource || null
  );

  const [destination, setDestination] = useState<MyLocationSearchPlace | null>(
    initialDestination || null
  );

  const [selectedTravelTimeOption, setSelectedTravelTimeOption] = useState(
    search?.travelTimeOption ?? TravelTimeOption.LeaveNow
  );

  const [selectedTravelTimeDate, setSelectedTravelTimeDate] = useState(
    search?.travelTimeDate ? new Date(search.travelTimeDate) : new Date()
  );

  const [loadRoutePlanData, data, loadingData, errorCode, resetSearch] =
    useRoutePlanData();

  const [errorDialogVisible, showErrorDialog, hideErrorDialog] =
    useBooleanState();

  const updateTravelTime = useCallback(() => {
    if (selectedTravelTimeOption === TravelTimeOption.LeaveNow) {
      setSelectedTravelTimeDate(new Date());
    } else if (selectedTravelTimeDate.valueOf() < new Date().valueOf()) {
      setSelectedTravelTimeDate(new Date());
    }
  }, [selectedTravelTimeDate, selectedTravelTimeOption]);

  // prevent past time as it would be invalid
  useEffect(() => {
    // do not update when we already have data so it is clear what time search was related to
    if (!!data) {
      return;
    }
    const updateNowInterval = setInterval(updateTravelTime, 2000);

    return () => {
      clearInterval(updateNowInterval);
    };
  }, [
    selectedTravelTimeOption,
    selectedTravelTimeDate,
    data,
    updateTravelTime,
  ]);

  useEffect(() => {
    if (sourceInitializedRef.current) {
      return;
    }

    const source = search?.source || initialSource || null;

    setSource(source);

    onSourceChange && onSourceChange(source);

    sourceInitializedRef.current = !!search || !initialSource?.loading;
  }, [initialSource, onSourceChange, search]);

  useEffect(() => {
    if (destinationInitializedRef.current) {
      return;
    }

    setDestination(search?.destination || initialDestination || null);

    destinationInitializedRef.current =
      !!search || !initialDestination?.loading;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDestination]);

  useEffect(() => {
    if (
      initialSource &&
      initialDestination &&
      !inSameLocation([initialSource, initialDestination])
    ) {
      loadRoutePlanData(
        initialSource,
        initialDestination,
        selectedTravelTimeOption,
        selectedTravelTimeDate,
        []
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (search) {
      onSourceChange && onSourceChange(search.source);

      onDestinationChange && onDestinationChange(search.destination);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (errorCode) {
      showErrorDialog();
    }
  }, [errorCode, showErrorDialog]);

  const sourceChanged = (location: LocationSearchPlace | null) => {
    setSource(location);

    onSourceChange && onSourceChange(location);

    resetSearch();
  };

  const destinationChanged = (location: LocationSearchPlace | null) => {
    setDestination(location);

    onDestinationChange && onDestinationChange(location);

    resetSearch();
  };

  const [providerTypes, setProviderTypes] = useState<ProviderFilterType[]>(
    search?.filters || []
  );

  const findRoute = () => {
    if (source && destination) {
      loadRoutePlanData(
        source,
        destination,
        selectedTravelTimeOption,
        selectedTravelTimeDate,
        providerTypes
      );
    }
  };

  const renderLoaders = () => {
    const loaders: ReactNode[] = [];

    for (let index = 0; index < 5; index++) {
      loaders.push(<OptionLoader key={index} running={loadingData} />);
    }

    return loaders;
  };

  const travelTimeChanged = (value: TravelTimeOption, dateTime: Date) => {
    setSelectedTravelTimeOption(value);

    setSelectedTravelTimeDate(dateTime);
  };

  const onBack = () => {
    if (selectedTrip) {
      setSelectedTrip(null);
    } else if (data) {
      setProviderTypes([]);
      resetSearch();
    } else {
      close();
    }
  };

  const close = () => {
    setSearch(null);
    onClose();
  };

  const getTitle = () => {
    return selectedTrip || data
      ? t("map.to_destination", { destination: destination?.label })
      : t("map.route_planner");
  };

  const [filterChanged, setFilterChanged] = useState(false);

  // update time to recent when we change providers filter
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateTravelTime, [providerTypes]);

  useEffect(() => {
    setFilterChanged(true);
  }, [providerTypes, selectedTravelTimeOption, selectedTravelTimeDate]);

  useEffect(() => {
    setFilterChanged(false);
  }, [loadingData]);

  const replaceMyLocationOnClick = (_, target: LocationPickerTarget | null) => {
    if (target === LocationPickerTarget.Source) {
      if (source?.address && source.address !== source.label) {
        setSource({
          ...source,
          label: source.address,
        });
      }
    } else if (target === LocationPickerTarget.Destination) {
      if (destination?.address && destination.address !== destination.label) {
        setDestination({
          ...destination,
          label: destination.address,
        });
      }
    }
  };

  const onTripChange = useCallback((updatedTrip: RoutePlanTrip) => {
    dispatch(updateSelectedRoutePlanTripAction(updatedTrip));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headerHeight = data ? 164 : 270;

  const searchDisabled =
    loadingData ||
    !source ||
    !destination ||
    inSameLocation([source, destination]) ||
    (!!data && !filterChanged);

  return (
    <>
      <div className="route-planner">
        <header
          css={{
            boxShadow: "0px 5px 3px 0px rgb(0,0,0,0.08)",
            margin: "0 -24PX",
            padding: "0 24px 16px",
            position: "relative",
            zIndex: 1,
          }}
        >
          <div
            css={{
              position: "relative",
              display: "grid",
              gridTemplateColumns: "auto 1fr auto",
              textAlign: selectedTrip || data ? "left" : "center",
              whiteSpace: "nowrap",
              gap: "0.5rem 1rem",
            }}
          >
            <NavBackIcon
              fill={AppColors.GRAY_300}
              onClick={onBack}
              css={{
                gridColumn: "1/2",
              }}
            />
            <div
              css={{
                gridColumn: "2/3",
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: "1.25rem",
                lineHeight: 1.2,
                fontWeight: 500,
              }}
            >
              {getTitle()}
            </div>
            {!selectedTrip && data && (
              <div
                css={{
                  gridColumn: "2/3",
                  width: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: "0.875rem",
                }}
              >
                {t("map.from")}&ensp;
                <span css={{ color: AppColors.PRIMARY }} onClick={onBack}>
                  {source?.label}
                </span>
              </div>
            )}
            {selectedTrip && (
              <CloseIcon
                fill={AppColors.GRAY_300}
                onClick={close}
                css={{
                  gridColumn: "3/4",
                }}
              />
            )}
          </div>
          {selectedTrip && (
            <div
              css={{
                paddingTop: 8,
                marginBottom: -12,
              }}
            >
              <TripSegments
                iconsOnly
                segments={selectedTrip.segments}
                css={{
                  paddingLeft: 40,
                  marginTop: 4,
                  paddingBottom: 8,
                }}
              />
            </div>
          )}
          {!selectedTrip && (
            <>
              {!data && (
                <SourceDestPicker
                  withId={false}
                  source={source}
                  destination={destination}
                  disabled={loadingData}
                  onSourceChange={sourceChanged}
                  onDestinationChange={destinationChanged}
                  onPickerToggle={replaceMyLocationOnClick}
                  css={{
                    marginTop: 12,
                  }}
                />
              )}
              <ProvidersFilter
                disabled={loadingData}
                multiple
                // allow all providers in route planner even if we have no map data for them
                availabilityCheck="none"
                hiddenOptions={[
                  ProviderFilterType.All,
                  ProviderFilterType.Kickscooters,
                  ProviderFilterType.Scooters,
                  ProviderFilterType.Services,
                ]}
                selected={providerTypes}
                onChange={setProviderTypes}
              />

              <div
                css={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "end",
                  margin: "12px 0 0",
                }}
              >
                <TravelTimePicker
                  disabled={loadingData}
                  selectedTravelTimeDate={selectedTravelTimeDate}
                  selectedTravelTimeOption={selectedTravelTimeOption}
                  onChange={travelTimeChanged}
                />

                <MobeaButton
                  className="route-planner__search-button"
                  onClick={findRoute}
                  disabled={searchDisabled}
                  css={css`
                    width: auto;
                    margin: 0;
                    padding: 4px 16px;
                    height: 32px;
                    font-size: 0.875rem;
                    line-height: 1.5rem;
                    min-height: initial;
                    border: 0 none;
                    font-weight: bold;
                  `}
                >
                  {loadingData
                    ? t("map.searching")
                    : data
                    ? t("map.apply_filter")
                    : t("map.search")}
                </MobeaButton>
              </div>
            </>
          )}
        </header>

        {selectedTrip && (
          <TripDetails trip={selectedTrip} onTripChange={onTripChange} />
        )}

        {!selectedTrip && (
          <div
            className="route-planner__scroll-container"
            style={{
              maxHeight: window.innerHeight - headerHeight - SLIDER_HEAD_HEIGHT,
            }}
          >
            <div
              className={`route-trips travel-time-option-${selectedTravelTimeOption}`}
            >
              {(!data || loadingData) && renderLoaders()}
              {!loadingData && data && (
                <TripOptions
                  bestTrip={data.bestTrip}
                  groups={data.groups}
                  onTripSelect={setSelectedTrip}
                />
              )}
              {!loadingData && data && data.tripsCount === 0 && (
                <NoProvidersAvailable />
              )}
              <div className="spacer"></div>
            </div>
          </div>
        )}
      </div>

      {errorDialogVisible && errorCode && (
        <RoutePlannerError errorCode={errorCode} close={hideErrorDialog} />
      )}
    </>
  );
}
