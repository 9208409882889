import { useBooleanState, usePendingOrder } from "common/hooks";
import { useSelectedWallet } from "common/hooks/useSelectedWallet";
import { MobeaModal } from "common/modal";
import { SimpleOrderPage } from "common/page/order/SimpleOrderPage";
import { CircleCheckIcon } from "icons/CircleCheckIcon";
import { BlueBikeLogo } from "icons/logos/BlueBike";
import { ProviderTermsAndConditions } from "pages/conditions/ProviderTermsAndConditions";
import { ReactElement, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  formatCurrency,
  pushLocation,
  Routes,
  SupportedLanguage,
  TravelPassProvider,
  WalletType,
} from "utils";
import { AppColors } from "utils/colors";
import { useBlueBikeData } from "../hooks/useBlueBikeData";
import { useBlueBikeOrder } from "../hooks/useBlueBikeOrder";
import { BlueBikeOrderError } from "./BlueBikeOrderError";

export function BlueBikeOrderPage(): ReactElement {
  const history = useHistory();

  const { t } = useTranslation();

  const locale = useSelector((state) => state.user.language);

  const { minPasses, maxPasses, price, validity, loaded } = useSelector(
    (state) => state.ordering.blueBikeData
  );

  const [orderPending] = usePendingOrder(TravelPassProvider["blue-bike"]);

  const [amount, setAmount] = useState(minPasses);

  // @TODO impl ordering!
  const [order, ordering, orderedPasses, errorCode] = useBlueBikeOrder(
    amount * price
  );

  const [errorDialogVisible, showErrorDialog, hideErrorDialog] =
    useBooleanState();

  const formattedPrice = formatCurrency(price * amount, locale);

  useBlueBikeData();

  useEffect(() => {
    if (errorCode) {
      showErrorDialog();
    }
  }, [errorCode, showErrorDialog]);

  // update initial value if fetch order data differs
  useEffect(() => {
    loaded && setAmount(minPasses);
  }, [loaded, minPasses]);

  const showTicketDetail = () => {
    pushLocation(
      history,
      Routes.BlueBikeTicketDetail.replace(
        ":id",
        encodeURIComponent(orderedPasses[0].id)
      ) + "?back=goHome&showTutorial=true"
    );
  };

  const showMultiTicketDetail = () => {
    pushLocation(
      history,
      `${Routes.BlueBikeMultiTicketDetail}?ids=${orderedPasses.map(
        (pass) => pass.id
      )}&showTutorial=true`
    );
  };

  const wallet = useSelectedWallet(WalletType.BUSINESS);

  const handleOrder = () => {
    order(wallet, amount);
  };

  const termsLinks: Record<SupportedLanguage, string> = {
    en: "https://www.blue-bike.be/en/terms-and-conditions",
    fr: "https://www.blue-bike.be/fr/termes-et-conditions",
    nl: "https://www.blue-bike.be/nl/algemene-voorwaarden",
  };

  return (
    <>
      <SimpleOrderPage
        provider={TravelPassProvider["blue-bike"]}
        title={t("blue-bike_order.order_ticket")}
        headerInSecondaryColor
        logo={<BlueBikeLogo width={54} height={54} />}
        ready={loaded}
        ticketName={t("blue-bike_order.ticket")}
        ticketDescription={
          <Trans i18nKey="blue-bike_order.description">
            <span className="mobea__arial mobea__bold">{{ validity }}</span>
          </Trans>
        }
        formattedPrice={formattedPrice}
        orderInProgress={orderPending || ordering}
        orderButtonLabel={
          orderPending
            ? t("shared.order_in_progress")
            : t("shared.order_ticket")
        }
        minPasses={minPasses}
        maxPasses={maxPasses}
        amount={amount}
        onOrder={handleOrder}
        onAmountChange={setAmount}
      >
        {orderedPasses.length > 0 && (
          <MobeaModal
            confirmText={t("shared.super")}
            title={
              orderedPasses.length > 1
                ? t("blue-bike_order.tickets_bought")
                : t("blue-bike_order.ticket_bought")
            }
            image={<CircleCheckIcon fill={AppColors.PRIMARY} />}
            onConfirm={
              orderedPasses.length > 1
                ? showMultiTicketDetail
                : showTicketDetail
            }
          >
            <p>
              <Trans
                i18nKey={
                  orderedPasses.length > 1
                    ? "velo_order.day_passes_bought_text"
                    : "velo_order.day_pass_bought_text"
                }
                values={{ validity }}
              >
                <span className="mobea__arial"></span>
              </Trans>
            </p>
          </MobeaModal>
        )}
      </SimpleOrderPage>

      {errorDialogVisible && errorCode && (
        <BlueBikeOrderError
          errorCode={errorCode}
          closeError={hideErrorDialog}
        />
      )}

      <ProviderTermsAndConditions
        title={t("blue-bike_order.order_ticket")}
        provider={TravelPassProvider["blue-bike"]}
        description={t("blue-bike_order.terms_description")}
        termsExternalLink={termsLinks[locale]}
        css={{
          ".mobea__provider-logo": {
            width: 54,
            height: 54,
          },
        }}
      />
    </>
  );
}
