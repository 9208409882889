import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getDeLijnOrderData } from "services/delijnService";
import {
  DeLijnSingleTicket,
  setDeLijnSingleTicketDataAction,
} from "state/actions";

export function useDeLijnOrderData() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let canceled = false;

    async function fetchDeLijnOrderData() {
      try {
        setLoading(true);
        const orderJson = await getDeLijnOrderData(history);

        if (!orderJson.error) {
          const desiredTicketType = (orderJson.ticketTypes || []).find(
            (ticket) => {
              // change format so Safari works ... :?
              const validFrom = ticket.validFrom.replace("+0000", "Z");
              const validityStart = new Date(validFrom);

              let isValid = validityStart.valueOf() < Date.now();

              if (ticket.validTo) {
                // change format so Safari works ... :?
                const validTo = ticket.validTo.replace("+0000", "Z");
                isValid = isValid && new Date(validTo).valueOf() > Date.now();
              }

              return ticket.sku === "MT60" && isValid;
            }
          );

          if (orderJson.ok && desiredTicketType) {
            const singleTicketData: DeLijnSingleTicket = {
              amount: 1,
              duration: desiredTicketType.validityMinutes,
              price: desiredTicketType.tariff / 100,
              tariff: desiredTicketType.tariff,
              ticketTypeId: desiredTicketType.ticketTypeId,
            };

            if (!canceled) {
              dispatch(setDeLijnSingleTicketDataAction(singleTicketData));
              setLoading(false);
            }
          } else {
            !canceled && setLoading(false);
            console.error(
              'Failed to fetch De Lijn order data - response was not ok or ticket type "MT60" is missing',
              orderJson
            );
          }
        } else {
          !canceled && setLoading(false);
          console.error(
            "Failed to fetch De Lijn order data",
            orderJson.error_description
          );
        }
      } catch (error) {
        !canceled && setLoading(false);
        console.error("Failed to fetch De Lijn order data", error);
      }
    }

    fetchDeLijnOrderData();

    return () => {
      canceled = true;
      console.debug("Canceled hook fetchDeLijnOrderData");
    };
  }, [dispatch, history]);

  return [loading] as const;
}
