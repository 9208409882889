import { LocationCursorIconSvg } from "icons/navigation/LocationCursorIcon";
import { useEffect, useMemo, useState } from "react";
import { LocationCoords } from "services/mapService";
import { useLocationSearchPlace } from "./useLocationSearchPlace";

const H = window.H;

const LocationCursorIcon = () =>
  new H.map.Icon(LocationCursorIconSvg(), {
    anchor: new H.math.Point(20, 40),
    crossOrigin: false,
    hitArea: new H.map.HitArea(H.map.HitArea.ShapeType.CIRCLE, [20, 20, 20]),
  });

export const useSearchLocation = (
  searchLocation: LocationCoords | null,
  setSearchLocation: (location: LocationCoords | null) => void,
  dragEnabled = true,
  pinVisible = true
) => {
  const [searchLocationGroup, setSearchLocationGroup] =
    useState<H.map.Group | null>(null);

  const [map, setMap] = useState<H.Map | null>(null);

  const [mapBehaviour, setMapBehaviour] = useState<H.mapevents.Behavior | null>(
    null
  );

  const [locationSearchPlace] = useLocationSearchPlace();

  const locationCursorIcon = useMemo(() => LocationCursorIcon(), []);

  // set search location based on location search
  useEffect(() => {
    if (locationSearchPlace) {
      setSearchLocation({
        lat: locationSearchPlace.lat,
        lng: locationSearchPlace.lng,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationSearchPlace]);

  // set search pin on search location
  useEffect(() => {
    if (!searchLocationGroup || !map) {
      return;
    }

    searchLocationGroup.removeAll();

    if (!searchLocation || !pinVisible) {
      return;
    }

    const locationMarker = new H.map.Marker(searchLocation, {
      icon: locationCursorIcon,
      // @ts-ignore TS does not recognize the property (according to https://developer.here.com/documentation/examples/maps-js/markers/draggable-marker it's there)
      volatility: true,
    }) as H.map.Marker & { offset: H.math.Point };

    searchLocationGroup.addObject(locationMarker);

    if (!dragEnabled) {
      return;
    }

    locationMarker.draggable = true;

    const dragStarted = ({ target, currentPointer }: any) => {
      if (target == locationMarker) {
        const targetPosition = map.geoToScreen(
          locationMarker.getGeometry() as H.geo.Point
        );
        locationMarker.offset = new H.math.Point(
          currentPointer.viewportX - targetPosition.x,
          currentPointer.viewportY - targetPosition.y
        );
        mapBehaviour?.disable();
      }
    };

    const dragged = ({ target, currentPointer }: any) => {
      if (target == locationMarker) {
        locationMarker.setGeometry(
          map.screenToGeo(
            currentPointer.viewportX - locationMarker.offset.x,
            currentPointer.viewportY - locationMarker.offset.y
          )
        );
      }
    };

    const dragEnded = ({ target }: any) => {
      if (target == locationMarker) {
        mapBehaviour?.enable();

        const markerLocation = locationMarker.getGeometry() as H.geo.Point;

        setSearchLocation({ ...markerLocation });
      }
    };

    map.addEventListener("dragstart", dragStarted);

    map.addEventListener("drag", dragged);

    map.addEventListener("dragend", dragEnded);

    return () => {
      map.removeEventListener("dragstart", dragStarted);

      map.removeEventListener("drag", dragged);

      map.removeEventListener("dragend", dragEnded);
    };
  }, [
    searchLocation,
    setSearchLocation,
    map,
    searchLocationGroup,
    mapBehaviour,
    dragEnabled,
    pinVisible,
    locationCursorIcon,
  ]);

  const setMapReferences = (
    map: H.Map,
    mapGroup: H.map.Group,
    mapBehaviour: H.mapevents.Behavior
  ) => {
    setMap(map);
    setSearchLocationGroup(mapGroup);
    setMapBehaviour(mapBehaviour);
  };

  return [setMapReferences] as const;
};
