import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ArrowReturnIcon } from "icons/ArrowReturnIcon";
import { ArrowRightIcon } from "icons/ArrowRightIcon";
import { useTranslation } from "react-i18next";
import { JourneyType } from "state/actions";
import { capitalizeString } from "utils";

const StyledLocationName = styled.p(css`
  position: relative;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
  max-width: calc(50% - 16px);
  text-transform: capitalize;
  flex: 1 1 auto;
`);

interface NmbsTravelPassRouteProps {
  className?: string;
  showLabels?: boolean;
  departure: string;
  destination: string;
  journeyType: JourneyType;
}
export function NmbsTravelPassRoute({
  className = "",
  showLabels = false,
  departure,
  destination,
  journeyType,
}: NmbsTravelPassRouteProps) {
  const { t } = useTranslation();

  const StyledLocationLabel = styled.span(css`
    position: absolute;
    top: -20px;
    display: ${showLabels ? "block" : "none"};
    text-align: left;
    font-size: 12px;
    line-height: 16px;
  `);

  return (
    <div
      className={className}
      css={css`
        display: flex;
        flex-direction: row;
        margin-bottom: 16px;
        min-height: 40px;
      `}
    >
      <StyledLocationName className="mobea__nmbs-route__departure">
        <StyledLocationLabel>{t("order_nmbs.from")}</StyledLocationLabel>
        {capitalizeString(departure)}
      </StyledLocationName>
      <div
        css={css`
          width: 32px;
          color: var(--color-primary);
          flex: 1 0 32px;
          position: relative;
          text-align: center;
          > svg {
            color: var(--color-primary);
            width: 24px;
            height: 24px;

            > path {
              transform: translateY(-2px);
            }
        `}
      >
        {journeyType === JourneyType.RETURN ? (
          <ArrowReturnIcon />
        ) : (
          <ArrowRightIcon />
        )}
        <span
          className="mobea__nmbs-route__direction-label"
          css={css`
            display: block;
            color: var(--color-gray-300);
            font-family: var(--font-family-secondary);
            font-size: 12px;
            line-height: 16px;
            margin-top: -5px;
            white-space: nowrap;           
            }
          `}
        >
          {/*
            t("nmbs_detail.journey.SingleTrip")
            t("nmbs_detail.journey.RoundTrip")
            */}
          {t(`nmbs_detail.journey.${journeyType}`)}
        </span>
      </div>
      <StyledLocationName
        className="mobea__nmbs-route__destination"
        css={css`
          text-align: right;
        `}
      >
        <StyledLocationLabel>{t("order_nmbs.to")}</StyledLocationLabel>
        {capitalizeString(destination)}
      </StyledLocationName>
    </div>
  );
}
