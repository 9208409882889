import { MobeaButton } from "common/forms";
import { LabelIcon } from "icons/LabelIcon";
import { MouseEvent, ReactElement } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { MobitTicketStatus } from "state/actions";
import { formatCurrency, Routes, TravelPassProvider } from "utils";
import { TravelPassWrapper } from "../TravelPassWrapper";
import { MobitInfoItem } from "./MobitInfoItem";
import "./MobitTravelPass.scss";
import { MobitTicketStartEnd } from "./time/MobitTicketStartEnd";

export interface MobitTravelPassProps {
  id: string;
  price: number;
  fine: number;
  status: MobitTicketStatus;
  duration: number;
  activation?: number;
  expiration?: number;
  locale: string;
  bikeCode?: string;
  rideId?: string;
  // do not allow any actions on ticket in history
  disabledActions?: boolean;
  isPreview?: boolean;
  onSelect?(): void;
}

export function MobitTravelPass({
  id,
  price = 0,
  fine = 0,
  status,
  activation,
  expiration,
  bikeCode,
  rideId,
  locale,
  isPreview = false,
  onSelect,
}: MobitTravelPassProps): ReactElement {
  const { t } = useTranslation();

  const history = useHistory();

  const hasFine = fine > 0;

  const totalPrice = price + fine;

  const formattedPrice = formatCurrency(totalPrice, locale);

  const formattedFine = formatCurrency(fine, locale);

  const getActionText = () => {
    switch (status) {
      case MobitTicketStatus.NOT_USED:
        return t("mobit_detail.start_the_ride");
      case MobitTicketStatus.STARTED:
        return t("mobit_detail.manage_the_ride");
      case MobitTicketStatus.PAUSED:
        return t("mobit_detail.continue_the_ride");
      default:
        return "";
    }
  };

  const showDetail = (e: MouseEvent) => {
    e.preventDefault();
    onSelect && onSelect();
  };

  const onAction = () => {
    switch (status) {
      case MobitTicketStatus.NOT_USED:
        history.push(Routes.MobitStartRide.replace(":id", id));
        break;
      case MobitTicketStatus.STARTED: {
        const path = Routes.MobitManageRide.replace(":id", id)
          .replace(":bikeCode", bikeCode || "")
          .replace(":rideId", rideId || "");
        history.push(path);
        break;
      }
      case MobitTicketStatus.PAUSED:
        history.push(
          Routes.MobitStartRide.replace(":id", id).replace(
            ":rideId",
            rideId || ""
          )
        );
        break;
    }
  };

  return (
    <TravelPassWrapper
      className={`mobea__mobit-ticket ${isPreview ? "preview" : ""}`}
      provider={TravelPassProvider.mobit}
      locale={locale}
      activation={activation}
      expiration={expiration}
      onClick={showDetail}
    >
      <div className="mobea__mobit-ticket__name__wrapper">
        <div className={`mobea__mobit-ticket__name__status ${status}`}>
          <div className="background"></div>
          {/*
              t("mobit_detail.status_not_used")
              t("mobit_detail.status_started")
              t("mobit_detail.status_paused")
              t("mobit_detail.status_ended")
            */}
          {t(`mobit_detail.status_${status}`)}
        </div>
        <h2 className="mobea__mobit-ticket__name">
          <span className="mobea-mobit-order__ticket__details__name__green">
            Day
          </span>
          Pass
        </h2>
      </div>

      <div className="mobea__mobit-ticket__image__wrapper">
        <img
          className="mobea__mobit-ticket__image"
          src="/static/images/mobit-bike.png"
          alt="bike image"
        />
      </div>

      <div className="mobea__mobit-ticket__bottom-section">
        <section className="mobea__mobit-ticket__info">
          <MobitTicketStartEnd
            validityStart={activation}
            validityEnd={expiration}
            t={t}
          />
          <hr className="mobea__mobit-ticket__info__hr" />
          <MobitInfoItem
            className={`price hide-in-preview ${hasFine ? "fined" : ""}`}
            label={
              <>
                <LabelIcon outlined />
                {t("shared.price")}
              </>
            }
            value={formattedPrice}
          />
          {hasFine ? (
            <p className="mobea__mobit-ticket__footer__fine-info">
              <Trans i18nKey="mobit_detail.fine_text">
                {"fined with "}
                <span className="mobea__arial">{{ fine: formattedFine }}</span>.
              </Trans>
            </p>
          ) : (
            <p className="mobea__mobit-ticket__footer__no-fine-info">&nbsp;</p>
          )}
        </section>

        <section
          className={`mobea__mobit-ticket__footer ${hasFine ? "fined" : ""}`}
        >
          {status !== MobitTicketStatus.ENDED && (
            <MobeaButton
              className="mobea__mobit-ticket__footer__button"
              onClick={onAction}
            >
              {getActionText()}
            </MobeaButton>
          )}
        </section>
      </div>
    </TravelPassWrapper>
  );
}
