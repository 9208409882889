import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

export function OfflineIcon({
  fill = "currentColor",
  className = "",
  height = "20",
  width = "24",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="131 -131 512 512"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={fill}
        d="M543.9,62.1c-14.2-73.1-78.6-129-156.5-129c-31.5,0-60.6,9.4-85.7,25.2l31.5,31.5
		c16.5-8.7,34.6-13.4,54.3-13.4c64.5,0,117.2,52.7,117.2,117.2v11h32.2c35.4,0,63.7,28.3,63.7,63.7c0,24.4-13.4,44.8-33,55.8
		l30.7,30.7c27.5-19.7,44.8-51.1,44.8-86.5C643,111.6,599,66,543.9,62.1z M195.5-39.4l58.2,58.2C186.1,22,131,77.8,131,146.2
		c0,70.8,57.4,128.2,128.2,128.2h250.1l42.5,42.5l27.5-26.7L223-66.9L195.5-39.4z M296.2,61.3L466.8,232H260c-47.2,0-85-37.8-85-85
		s37.8-84.9,84.9-84.9h36.2v-0.8H296.2z"
      />
    </svg>
  );
}
