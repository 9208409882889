import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

export function ArrowRightIcon({
  fill = "currentColor",
  className = "",
  height = "24",
  width = "24",
  onClick,
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 0 24 24"
      width={width}
      onClick={onClick}
    >
      <path
        fill={fill}
        d="M15 6l-1.406 1.406 3.562 3.61H3v1.968h14.156l-3.562 3.61L15 18l6-6z"
      />
    </svg>
  );
}
