import { MobeaButton } from "common/forms";
import { useNetwork } from "common/hooks";
import { forwardRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { pushLocation, Routes } from "utils";
import { BUDGET_COOLDOWN_PERIOD_DAYS } from "utils/constants";
import { formatDateWithLocaleAsDigits } from "utils/intl";
import { NetworkState } from "utils/network";
import { TravelPassesImage } from "../TravelPassesImage";
import "./BudgetPeriodOver.scss";

interface Props {
  id?: string;
}

export const BudgetPeriodOver = forwardRef<HTMLDivElement, Props>(({ id }) => {
  const { t } = useTranslation();

  const network = useNetwork();

  const history = useHistory();

  const {
    startDate,
    outOfBudgetPeriod,
    remainingDays,
    amount,
    language: locale,
  } = useSelector((state) => state.user);

  const remainingAmount = Math.max(0, amount);

  const budgetNotStartedYet = startDate ? Date.now() < startDate : false;

  // remaining days are negative if after budget period
  // cap value inside interval to prevent issue with too many days or negative value
  const days = Math.min(
    Math.max(BUDGET_COOLDOWN_PERIOD_DAYS + remainingDays, 0),
    BUDGET_COOLDOWN_PERIOD_DAYS
  );

  // disable expensing once we have no budget, after cooldown period or offline
  const expensingDisabled =
    remainingAmount <= 0 || days === 0 || network !== NetworkState.online;

  const startDateFormatted = startDate
    ? formatDateWithLocaleAsDigits(startDate, locale).replace(/-/g, "/")
    : "";

  const daysLeftText = `${days} ${t("time.day", { count: days })}`;

  return (
    <div id={id}>
      {outOfBudgetPeriod && remainingDays > -BUDGET_COOLDOWN_PERIOD_DAYS && (
        <section className="mobea-dashboard__expired">
          {budgetNotStartedYet && (
            <>
              <p className="mobea-dashboard__expired__text">
                <Trans i18nKey="home.budget_not_started_text">
                  <span className="mobea__bold">
                    {{ startDate: startDateFormatted }}
                  </span>
                </Trans>
              </p>
              <div className="mobea-dashboard__expired__image">
                <TravelPassesImage />
              </div>
            </>
          )}

          {!budgetNotStartedYet && (
            <>
              <p className="mobea-dashboard__expired__text">
                <Trans i18nKey="home.budget_expired_cooldown_text">
                  <span className="mobea__arial mobea__bold">
                    {{ days: daysLeftText }} days
                  </span>
                </Trans>
              </p>
              <MobeaButton
                className="mobea-dashboard__expired__add-expense"
                disabled={expensingDisabled}
                onClick={() => pushLocation(history, Routes.ExpenseAddEmpty)}
              >
                {t("expense.add_expense")}
              </MobeaButton>
            </>
          )}
        </section>
      )}

      {outOfBudgetPeriod && remainingDays <= -BUDGET_COOLDOWN_PERIOD_DAYS && (
        <section className="mobea-dashboard__expired">
          <p className="mobea-dashboard__expired__text">
            {t("home.bugdet_expired_final_text")}
          </p>
          <div className="mobea-dashboard__expired__icon">
            <TravelPassesImage />
          </div>
        </section>
      )}
    </div>
  );
});

BudgetPeriodOver.displayName = "BudgetPeriodOver";
