import { ReactElement } from "react";
import { AppColors } from "utils/colors";
import { DefaultIconProps } from "../props";

export function MyLocationIcon({
  fill = AppColors.GRAY_400,
  className = "",
  height = "24",
  width = "24",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path fill={fill} d="M21 3L3 10.53v.98l6.84 2.65L12.48 21h.98z" />
    </svg>
  );
}
