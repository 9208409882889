import { TFunction } from "i18next";
import { getI18n } from "react-i18next";
import { applicationVariant, getConfig } from "./configure";
import { ApplicationVariant, MOVEASY_TERMS_LINKS } from "./constants";
import { DEFAULT_INTL_NAMESPACE } from "./i18n";
import { Routes } from "./routes";

// nasty issue with webpack resolution
// const was exported before it was initiated so we have to method here
export function getAllSupportedLanguages() {
  return {
    nl: "Dutch",
    fr: "French",
    en: "English",
  };
}

export type SupportedLanguage = "nl" | "fr" | "en";

const fullLanguageCodesMap = {
  en: "en-GB",
  fr: "fr-FR",
  nl: "nl-NL",
};

export function getFullLanguageCodes(locale: string) {
  return fullLanguageCodesMap[locale];
}

export const SOFT_HYPHEN_ENCODED = "&#173;";

export function getLanguages() {
  return getConfig().languages.reduce((accumulator, lng) => {
    accumulator[lng] = getAllSupportedLanguages()[lng];

    return accumulator;
  }, {} as Record<SupportedLanguage, string>);
}

export const defaultLanguage: SupportedLanguage = "en";

const daysCache: Map<string, string[]> = new Map();

export function getDaysOfTheWeek(t: TFunction, locale: string) {
  const keys = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  let values: string[];

  if (daysCache.has(locale)) {
    values = daysCache.get(locale) as string[];
  } else {
    /*
      t("dates.sunday")
      t("dates.monday")
      t("dates.tuesday")
      t("dates.wednesday")
      t("dates.thursday")
      t("dates.friday")
      t("dates.saturday")
    */
    values = keys.map((key) => t(`dates.${key}`));

    daysCache.set(locale, values);
  }

  return values;
}

export function getFirstDayOfTheWeek(locale: string) {
  // force the week to start with Monday even for english...
  return locale.startsWith("en") ? 1 : 1;
}

export function getDaysOfTheWeekShort(t: TFunction, locale: string) {
  return getDaysOfTheWeek(t, locale).map((day) => day.substring(0, 3));
}

const monthsCache: Map<string, string[]> = new Map();

export function getMonths(t: TFunction, locale: string) {
  const keys = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];

  let values: string[];

  if (monthsCache.has(locale)) {
    values = monthsCache.get(locale) as string[];
  } else {
    /*
      t("months.january")
      t("months.february")
      t("months.march")
      t("months.april")
      t("months.may")
      t("months.june")
      t("months.july")
      t("months.august")
      t("months.september")
      t("months.october")
      t("months.november")
      t("months.december")
     */
    values = keys.map((key) => t(`months.${key}`));

    monthsCache.set(locale, values);
  }

  return values;
}

export function getMonthsShort(t: TFunction, locale: string) {
  return getMonths(t, locale).map((month) => month.substring(0, 3));
}

export function formatCurrency(
  number: number,
  locale: string,
  options: Intl.NumberFormatOptions = {}
) {
  return number.toLocaleString(locale, {
    currency: "EUR",
    currencyDisplay: "symbol",
    style: "currency",
    ...options,
  });
}

export function formatCurrencyCustom(
  number: number,
  locale: string,
  options: Intl.NumberFormatOptions
) {
  return number.toLocaleString(locale, { currency: "EUR", ...options });
}

export function formatDateWithLocale(
  date: string | number | Date,
  language: string,
  options?: Intl.DateTimeFormatOptions
) {
  // fixed default formatting as different browsers could have various default!
  const optionsWithFallback: Intl.DateTimeFormatOptions = options || {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  };

  // fix output timezone to Belgium otherwise different one will be used depending on browser impl
  optionsWithFallback.timeZone = "Europe/Brussels";

  // fix english locale into UK one which does not change date order
  const locale = language === "en" ? "en-GB" : language;

  return new Date(date).toLocaleDateString(locale, optionsWithFallback);
}

export function formatTimeWithLocale(
  date: string | number | Date,
  language: string,
  withSeconds = false
) {
  const locale = language === "en" ? "en-GB" : language;

  const timeString = new Date(date).toLocaleTimeString(locale);
  return withSeconds ? timeString : timeString.replace(/(:\d{2}| [AP]M)$/, "");
}

export function formatDateWithLocaleAsDigits(
  date: string | number | Date,
  language: string,
  slashes = true
) {
  const result = formatDateWithLocale(date, language, {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  });

  if (slashes) {
    return result.replace(/-/g, "/");
  }

  return result;
}

export function formatDateWithLocaleAsDefault(
  date: string | number | Date,
  language: string,
  slashes = true
) {
  const result = formatDateWithLocale(date, language, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  if (slashes) {
    return result.replace(/-/g, "/");
  }

  return result;
}

export function formatDateOfBirth(
  dateOfBirth: string | number | Date | undefined,
  language: string
) {
  if (!dateOfBirth) {
    return "";
  }

  const date =
    dateOfBirth instanceof Date
      ? new Date(dateOfBirth.getTime())
      : new Date(dateOfBirth);

  date.setHours(12);

  return formatDateWithLocaleAsDefault(date, language, true);
}

export function getTermsAndConditionsLink(language: string) {
  const customTerms = getConfig().customTermsConditions[language];

  if (customTerms) {
    return customTerms;
  } else if (applicationVariant === ApplicationVariant.MOVEASY) {
    return MOVEASY_TERMS_LINKS[language];
  } else {
    return Routes.TermsAndConditions;
  }
}

export function getFaqLink(language: string) {
  return getConfig().faqLink[language];
}

export function getPrivacyLink(language: string) {
  return getConfig().privacyLink[language];
}

export function getCookiesPolicyLink(language: string) {
  return getConfig().cookiesLink[language];
}

export function removeAccents(value: string) {
  return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function formatDistance(value: number, language: string) {
  return value.toLocaleString(language, { maximumFractionDigits: 1 });
}

export const getTranslationNoWarning = (key: string) => {
  const i18n = getI18n();

  return i18n.getResource(i18n.language, DEFAULT_INTL_NAMESPACE, key);
};
