import styled from "@emotion/styled";
import { ProgressBar } from "common/progress/ProgressBar";
import { Spinner } from "common/Spinner";
import { ExclamationMarkIcon } from "icons/ExclamationMarkIcon";
import { HomeIcon } from "icons/navigation";
import { ParkingIcon } from "icons/ParkingIcon";
import { BikeIcon } from "icons/transportTypes";
import { WalkIcon } from "icons/WalkIcon";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { AppColors, AppFonts } from "utils/colors";
import { BikeStation, BikeStationStatus } from "./BikeStationTypes";

type Props = {
  station: BikeStation | null;
  color: string;
  loading: boolean;
  address: string | null;
  loadingAddress: boolean;
  distance: number | null;
  loadingDistance: boolean;
};

const InfoSectionStyled = styled.div({
  display: "flex",
  alignItems: "center",
});

const ParkingLabelStyled = styled.div({
  color: AppColors.GRAY_300,
  fontFamily: AppFonts.SECONDARY,
  fontSize: "0.875rem",
  lineHeight: "1.25rem",
  margin: "0 4px",
});

const ParkingValueStyled = styled.div({
  fontFamily: AppFonts.SECONDARY,
  fontSize: "0.875rem",
  fontWeight: "bold",
});

const AddressLabelStyled = styled.div({
  color: AppColors.GRAY_200,
  fontFamily: AppFonts.SECONDARY,
  fontSize: "0.75rem",
  lineHeight: "1rem",
  marginLeft: 4,
  marginBottom: 4,
});

const AddressValueStyled = styled.div({
  color: AppColors.GRAY_400,
  fontFamily: AppFonts.NUMBERS,
  fontSize: "0.875rem",
  lineHeight: "1.25rem",
});

export function BikeStationInfo({
  station,
  color,
  loading,
  address,
  loadingAddress,
  distance,
  loadingDistance,
}: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <div
      css={{
        padding: "8px 24px",
        position: "relative",
        "> .mobea__spinner": {
          position: "relative",
          margin: "16px auto",
          display: "flex",
          justifyContent: "center",
          minHeight: 100,
        },
      }}
    >
      {loading && <Spinner />}

      {!loading && station && (
        <>
          {station.status === BikeStationStatus.Open && (
            <>
              <section
                css={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: 12,
                }}
              >
                <InfoSectionStyled>
                  <BikeIcon
                    fill={AppColors.GRAY_200}
                    width={16}
                    height={16}
                    css={{
                      marginRight: 4,
                    }}
                  />
                  <ParkingLabelStyled>{t("velo_map.bikes")}</ParkingLabelStyled>
                  <ParkingValueStyled>
                    {station.availability.bikes}
                  </ParkingValueStyled>
                </InfoSectionStyled>
                <InfoSectionStyled>
                  <ParkingValueStyled>
                    {station.availability.slots}
                  </ParkingValueStyled>
                  <ParkingLabelStyled>
                    {t("velo_map.parking_slots")}
                  </ParkingLabelStyled>
                  <ParkingIcon
                    fill={AppColors.GRAY_200}
                    width={16}
                    height={16}
                    css={{
                      marginLeft: 4,
                    }}
                  />
                </InfoSectionStyled>
              </section>
              <ProgressBar
                value={station.availability.bikes}
                total={station.availability.bikes + station.availability.slots}
                css={{
                  "> div": {
                    background: color,
                  },
                }}
              />
            </>
          )}

          {station.status === BikeStationStatus.Closed && (
            <section
              css={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                color: AppColors.ERROR,
              }}
            >
              <ExclamationMarkIcon />
              <div
                css={{
                  marginTop: 6,
                  marginBottom: 4,
                  fontFamily: AppFonts.SECONDARY,
                  fontSize: "0.875rem",
                  textAlign: "center",
                }}
              >
                {t("velo_map.station_not_available")}
              </div>
            </section>
          )}

          <div
            css={{
              height: 1,
              backgroundColor: AppColors.BACKGROUND,
              margin: "16px 0",
            }}
          />

          <section
            css={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <div
              css={{
                maxWidth: "50%",
              }}
            >
              <InfoSectionStyled>
                <HomeIcon
                  width={16}
                  height={16}
                  fill={AppColors.GRAY_200}
                  css={{
                    marginTop: -2,
                    ".mobea__navigation__active": {
                      fillOpacity: 0,
                    },
                  }}
                />
                <AddressLabelStyled>{t("velo_map.address")}</AddressLabelStyled>
              </InfoSectionStyled>
              <InfoSectionStyled>
                {loadingAddress && <Spinner size={16} />}
                {!loadingAddress && (
                  <AddressValueStyled>{address}</AddressValueStyled>
                )}
              </InfoSectionStyled>
            </div>
            <div
              css={{
                maxWidth: "50%",
              }}
            >
              <InfoSectionStyled>
                <WalkIcon
                  width={16}
                  height={16}
                  fill={AppColors.GRAY_200}
                  css={{
                    marginTop: -2,
                  }}
                />
                <AddressLabelStyled>
                  {t("velo_map.distance")}
                </AddressLabelStyled>
              </InfoSectionStyled>
              <InfoSectionStyled>
                {loadingDistance && <Spinner size={16} />}
                {!loadingDistance && (
                  <AddressValueStyled>{distance}m</AddressValueStyled>
                )}
              </InfoSectionStyled>
            </div>
          </section>
        </>
      )}
    </div>
  );
}
