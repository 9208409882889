import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { getTicketDetail } from "services/travelPassService";
import { MobitTicketStatus } from "state/actions";

export function useStatusUpdate(
  id: string | null,
  timeout = 5000,
  status?: MobitTicketStatus
) {
  const history = useHistory();

  const [pending, setPending] = useState(true);

  const [pollingId, setPollingId] = useState<number | null>(null);

  // const [initialStatus, setInitialStatus] = useState<
  //   MobitTicketStatus | undefined | null
  // >(status);
  const initialStatusRef = useRef<MobitTicketStatus | undefined | null>(status);

  useEffect(() => {
    let timeoutId: number;

    let canceled = false;
    let inProgress = false;

    async function fetchTicketStatus(id: string) {
      inProgress = true;

      try {
        const response = await getTicketDetail(id, history);

        const initialStatus = initialStatusRef.current;

        if (!response.error) {
          const { ride_status: rideStatus = null } =
            response.mobit_detail || {};

          const isPending =
            initialStatusRef === undefined || rideStatus === initialStatus;

          if (!canceled) {
            setPending(isPending);

            if (initialStatus === undefined) {
              initialStatusRef.current = rideStatus;
            }

            if (isPending) {
              timeoutId = window.setTimeout(() => {
                // trigger rerunning the hook
                setPollingId(timeoutId);
              }, timeout);
            } else {
              console.log("Status updated", initialStatus, rideStatus);
              inProgress = false;
            }
          }
        } else {
          !canceled && setPending(false);
          inProgress = false;

          console.error(
            "Failed to update mobit day pass status",
            response.error_description
          );
        }
      } catch (error) {
        !canceled && setPending(false);
        inProgress = false;

        console.error("Failed to update mobit day pass status", error);
      }
    }

    id && fetchTicketStatus(id);

    return () => {
      canceled = true;

      timeoutId && window.clearTimeout(timeoutId);

      inProgress && console.debug("Canceled hook useStatusUpdate");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pollingId, id, timeout]);

  return [pending] as const;
}
