import { NavBackIcon } from "icons/NavBackIcon";
import { ReactElement, ReactNode } from "react";
import { useHistory } from "react-router-dom";
import "./ConditionsPage.scss";

export interface ConditionsPageProps {
  title: string;
  children: ReactNode;
}

export function ConditionsPage({
  title,
  children,
}: ConditionsPageProps): ReactElement {
  const history = useHistory();

  const onBack = () => history.goBack();

  return (
    <main className="mobea-conditions modeba-privacy">
      <header className="mobea-conditions__header">
        <h1>
          <button className="mobea-conditions__header__back" onClick={onBack}>
            <NavBackIcon />
          </button>
          <span>{title}</span>
        </h1>
      </header>

      <article className="mobea-conditions__content">{children}</article>
    </main>
  );
}
