import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useBudget } from "./useBudget";

export enum BudgetState {
  NoBudget = "no-budget",
  NotActiveYet = "not-active-yet",
  Active = "active",
  Expired = "expired",
}

export function useBudgetState() {
  const { amount, outOfBudgetPeriod, startDate } = useSelector(
    (state) => state.user
  );

  const [loading] = useBudget();

  const noBudget = amount <= 0;

  const budgetState = useMemo(() => {
    if (noBudget || !startDate) {
      return BudgetState.NoBudget;
    }

    if (Date.now() < startDate) {
      return BudgetState.NotActiveYet;
    }

    return outOfBudgetPeriod ? BudgetState.Expired : BudgetState.Active;
  }, [noBudget, outOfBudgetPeriod, startDate]);

  return [loading, budgetState] as const;
}
