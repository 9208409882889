import { useAppName } from "common/hooks/useAppName";
import { NestedPage } from "common/page/NestedPage";
import { UpIcon } from "icons/UpIcon";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  setHistoryTutorialShown,
  setMapTutorialShown,
  startTutorial,
} from "state/actions";
import { Routes } from "utils";
import "./TutorialsPage.scss";

export function TutorialsPage(): ReactElement {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const appName = useAppName();

  return (
    <NestedPage pageClassName="mobea-tutorials" title={t("settings.tutorial")}>
      <Link
        to={{
          pathname: Routes.Tutorial,
          state: { from: Routes.Tutorials },
        }}
      >
        <span>{t("tutorial.intro", { appName })}</span>
        <UpIcon />
      </Link>
      <Link
        to={{
          pathname: Routes.Home,
          state: { from: Routes.Tutorials },
        }}
        onClick={() => {
          dispatch(startTutorial("main"));
        }}
      >
        <span>{t("tutorial.onboarding")}</span>
        <UpIcon />
      </Link>
      <Link
        to={{
          pathname: Routes.History,
          state: { from: Routes.Tutorials },
        }}
        onClick={() => {
          dispatch(setHistoryTutorialShown(false));
        }}
      >
        <span>{t("tutorial.history")}</span>
        <UpIcon />
      </Link>
      <Link
        to={{
          pathname: Routes.GetTravelPass,
          state: { from: Routes.Tutorials },
        }}
        onClick={() => {
          dispatch(startTutorial("providers"));
        }}
      >
        <span>{t("tutorial.providers")}</span>
        <UpIcon />
      </Link>
      <Link
        to={{
          pathname: Routes.Map,
          state: { from: Routes.Tutorials },
        }}
        onClick={() => {
          dispatch(setMapTutorialShown(false));
        }}
      >
        <span>{t("tutorial.map")}</span>
        <UpIcon />
      </Link>
    </NestedPage>
  );
}
