import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { DatePicker } from "common/datepicker/DatePicker";
import { LabelValue } from "common/forms";
import { useBooleanState } from "common/hooks";
import { TimePicker } from "common/timepicker/TimePicker";
import { CalendarIcon } from "icons/CalendarIcon";
import { ChevronIcon } from "icons/ChevronIcon";
import { ClockIcon } from "icons/ClockIcon";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { TravelTimeOption } from "services/mapService";
import { formatTimeWithLocale } from "utils";
import { AppColors, AppFonts } from "utils/colors";

const LabelValueIcon = styled.div({
  flex: "1 0",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: "white",
  padding: "4px 12px 2px",
  ".mobea__label-value__value": {
    lineHeight: "1.125rem",
    marginBottom: 3,
    color: AppColors.GRAY_500,
  },
  "&:not(:first-of-type)": {
    marginLeft: 4,
  },
  svg: {
    flexShrink: 0,
  },
});

const grow = keyframes`
  from {
    max-height: 0;
  }
  to {
    max-height: 300px;
  }
  `;

type TravelTimeProps = {
  value: TravelTimeOption;
  dateTime: Date;
  onChange?: (value: TravelTimeOption, dateTime: Date) => void;
  onClick?(): void;
  availableOptions?: TravelTimeOption[];
  disabled?: boolean;
};

export function TravelTime({
  value,
  dateTime,
  disabled = false,
  onChange,
  onClick,
  availableOptions,
}: TravelTimeProps): ReactElement {
  const { t } = useTranslation();

  const locale = useSelector((state) => state.user.language);

  const [optionsVisible, showOptions, hideOptions] = useBooleanState();

  const [timePickerVisible, showTimePicker, hideTimePicker] = useBooleanState();

  const openTimePicker = () => {
    if (!disabled) {
      showTimePicker();
    }
  };

  const toOption = (option: TravelTimeOption) => {
    const optionSelected = () => {
      hideOptions();
      onChange?.(option, ceilTo5Minutes(new Date()));
    };

    return (
      <div
        key={option}
        className="mobea__travel-time__option"
        onClick={optionSelected}
        css={{
          padding: "12px 24px",
          fontFamily: AppFonts.SECONDARY,
          backgroundColor: "white",
          fontSize: "1rem",
          lineHeight: 1.5,
        }}
      >
        {t(`map.travel_option.${option}`)}
      </div>
    );
  };

  const getDateTitle = () => {
    switch (value) {
      case TravelTimeOption.LeaveAt:
        return t("time.departure_date");
      case TravelTimeOption.ArriveAt:
        return t("time.arrival_date");
      default:
        return "";
    }
  };

  const getTimeTitle = () => {
    switch (value) {
      case TravelTimeOption.LeaveAt:
        return t("time.departure_time");
      case TravelTimeOption.ArriveAt:
        return t("time.arrival_time");
      default:
        return "";
    }
  };

  const dateChanged = (dateValue: number) => {
    const date = new Date(dateValue);

    onChange?.(
      value,
      new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        dateTime.getHours(),
        dateTime.getMinutes(),
        dateTime.getSeconds()
      )
    );
  };

  const timeChanged = (time: Date) => {
    onChange?.(
      value,
      new Date(
        dateTime.getFullYear(),
        dateTime.getMonth(),
        dateTime.getDate(),
        time.getHours(),
        time.getMinutes(),
        time.getSeconds()
      )
    );

    hideTimePicker();
  };

  const toggleOptions = () => {
    onClick?.();

    if (disabled) {
      return;
    }

    optionsVisible ? hideOptions() : showOptions();
  };

  return (
    <div
      className={`mobea__travel-time  ${disabled ? "disabled" : ""}`}
      css={
        optionsVisible
          ? {
              minHeight: 194,
            }
          : undefined
      }
    >
      <div
        className="mobea__travel-time__selection"
        onClick={toggleOptions}
        css={{
          position: "relative",
          padding: "12px 24px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: optionsVisible
            ? AppColors.WHITE
            : AppColors.BACKGROUND,
          borderBottom: optionsVisible
            ? `1px solid ${AppColors.BACKGROUND}`
            : "0 none",
          zIndex: 2,
          transition: "background-color 0.2s",
        }}
      >
        <span
          css={{
            fontFamily: AppFonts.SECONDARY,
            fontSize: "1rem",
            lineHeight: 1.5,
            color: optionsVisible ? AppColors.PRIMARY : "inherit",
          }}
        >
          {t(`map.travel_option.${value}`)}
        </span>
        <ChevronIcon
          orientation="down"
          fill={AppColors.GRAY_200}
          width={24}
          height={24}
        />
      </div>

      {optionsVisible && (
        <>
          <div
            className="mobea__travel-time__overlay"
            onClick={hideOptions}
            css={{
              position: "fixed",
              top: "-100vh",
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.48)",
              zIndex: 1,
              animation: "fadeIn 0.2s",
            }}
          />
          <div
            className="mobea__travel-time__options"
            css={{
              position: "absolute",
              zIndex: 2,
              width: "100%",
              overflow: "hidden",
              animation: `${grow} 0.2s`,
            }}
          >
            {(availableOptions ?? Object.values(TravelTimeOption))
              .filter((option) => option !== value)
              .map(toOption)}
          </div>
        </>
      )}

      {(value === TravelTimeOption.LeaveAt ||
        value === TravelTimeOption.ArriveAt) && (
        <div
          className="mobea__travel-time__date-time"
          css={{
            padding: 24,
            backgroundColor: AppColors.BACKGROUND,
            borderTop: `1px solid ${AppColors.GRAY_100}`,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <LabelValueIcon>
            <DatePicker
              className="mobea__travel-time__date-time__date"
              title={getDateTitle()}
              dateInputLabel={t("time.date")}
              startDate={Date.now()}
              selectedDate={dateTime.getTime()}
              locale={locale}
              t={t}
              disabled={disabled}
              onChange={dateChanged}
              usePortal
              css={{
                borderBottom: "none",
                height: "auto",
                "> input": {
                  height: "auto",
                  marginTop: 2,
                },
                label: {
                  fontSize: "0.75rem",
                },
              }}
            />
            <CalendarIcon width={24} height={24} fill={AppColors.GRAY_200} />
          </LabelValueIcon>
          <LabelValueIcon onClick={openTimePicker}>
            <LabelValue
              label={t("time.time")}
              value={formatTimeWithLocale(dateTime, locale)}
            />
            <ClockIcon width={24} height={24} fill={AppColors.GRAY_200} />
          </LabelValueIcon>
        </div>
      )}

      {timePickerVisible && (
        <TimePicker
          title={getTimeTitle()}
          value={dateTime}
          onChange={timeChanged}
          onClose={hideTimePicker}
          startDate={new Date()}
        />
      )}
    </div>
  );
}

// 14:35:34 -> 14:40:00; 14:36:12 -> 14:40:00
function ceilTo5Minutes(date: Date) {
  if (date.getMilliseconds() || date.getSeconds() || date.getMinutes() % 5) {
    const d = new Date(date);
    d.setMinutes(Math.floor(d.getMinutes() / 5) * 5 + 5);
    d.setSeconds(0);
    d.setMilliseconds(0);

    return d;
  }

  return date;
}
