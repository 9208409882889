import { MobeaButton } from "common/forms";
import { LocationCursorIcon } from "icons/navigation";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { AppColors } from "utils/colors";
import { BellIcon, BikeLockIcon, WrenchIcon } from "../icons";

export interface MobitRideControlsProps {
  address?: string | null;
  addressLoading?: boolean;
  bikeCode?: string;
  ringBike(): void;
  startRide?(): void;
  resumeRide?(): void;
  pauseRide?(): void;
  endRide?(): void;
  reportBike(): void;
}

export function MobitRideControls({
  address,
  addressLoading,
  bikeCode,
  ringBike,
  startRide,
  resumeRide,
  pauseRide,
  endRide,
  reportBike,
}: MobitRideControlsProps): ReactElement {
  const { t } = useTranslation();

  return (
    <section className="mobea-find-bike__controls">
      {(address || addressLoading) && (
        <div className="mobea-find-bike__controls__location-ring">
          <div className="mobea-find-bike__controls__location-ring__location">
            <LocationCursorIcon
              width="24"
              height="24"
              fill={AppColors.GRAY_200}
            />
            {addressLoading && <span>{t("shared.loading")}</span>}
            {!addressLoading && address && <span>{address}</span>}
          </div>
          <div
            className="mobea-find-bike__controls__location-ring__ring"
            onClick={ringBike}
          >
            <BellIcon width="20" height="20" fill={AppColors.PRIMARY} />
            <span>{t("mobit_ride.ring_bell")}</span>
          </div>
        </div>
      )}

      {bikeCode && (
        <div className="mobea-find-bike__controls__code-report">
          <div className="mobea-find-bike__controls__code-report__code">
            <BikeLockIcon width="20" height="20" />
            <span>{bikeCode}</span>
          </div>
          <div
            className="mobea-find-bike__controls__code-report__report"
            onClick={reportBike}
          >
            <WrenchIcon width="16" height="16" fill={AppColors.PRIMARY} />
            <span>{t("mobit_ride.report_defect")}</span>
          </div>
        </div>
      )}

      <div className="mobea-find-bike__controls__actions">
        {startRide && (
          <MobeaButton onClick={startRide}>
            {t("mobit_ride.start_ride")}
          </MobeaButton>
        )}
        {endRide && (
          <MobeaButton type="secondary" onClick={endRide}>
            {t("mobit_ride.end_the_ride")}
          </MobeaButton>
        )}
        {pauseRide && (
          <MobeaButton onClick={pauseRide}>
            {t("mobit_ride.pause_the_ride")}
          </MobeaButton>
        )}
        {resumeRide && (
          <MobeaButton onClick={resumeRide}>
            {t("mobit_ride.resume_ride")}
          </MobeaButton>
        )}
      </div>
    </section>
  );
}
