import { Action } from "state";
import { defaultLanguage, getInitialStateWithPersistance } from "utils";
import i18n from "utils/i18n";
import { setLoggingUser } from "utils/logging";
import { UserState, WalletsStatus } from "./stateTypes";

const initialState: UserState = {
  customerId: "",
  currentPlanId: null,
  language: defaultLanguage,
  phonePrefix: "",
  phoneNumber: "",
  name: "",
  surname: "",
  email: "",
  iban: "",
  dateOfBirth: "",
  amount: 0,
  totalAmount: 0,
  startDate: null,
  endDate: null,
  tester: false,
  planLoaded: false,
  outOfBudgetPeriod: true,
  remainingDays: 0,
  noBudgetWarningClosed: false,
  outOfBudgetPeriodWarningClosed: false,
  clientConfigReady: false,
  passwordSet: false,
  privateAmount: 0,
  privateStartDate: null,
  walletWarnings: WalletsStatus.emptyPrivate,
};

const rehydrateState = () => {
  const state = getInitialStateWithPersistance("user", initialState);

  // push re-hydrated language to the translations manager
  i18n.changeLanguage(state.language);

  if (state.customerId) {
    setLoggingUser(state.customerId);
  }

  return state;
};

const calculateBudgetPeriod = (
  startDate: number | null,
  endDate: number | null,
  state: UserState
) => {
  let outOfBudgetPeriod = true;

  if (startDate !== null && endDate !== null) {
    const now = Date.now();

    outOfBudgetPeriod = startDate > now || endDate < now;
  }

  // we deduct, that if no plan is returned, last known information is correct...
  const expectedEndDate = endDate || state.endDate;

  const remaining = expectedEndDate
    ? (expectedEndDate - Date.now()) / (24 * 3600 * 1000)
    : 0;
  // round down to full days and fix negative rounding
  const remainingDays =
    remaining > 0 ? Math.floor(remaining) : Math.ceil(remaining);

  return {
    expectedEndDate,
    outOfBudgetPeriod,
    remainingDays,
  };
};

export function UserReducer(
  state: UserState = rehydrateState(),
  action: Action
): UserState {
  switch (action.type) {
    case "SET_CUSTOMER_ID": {
      setLoggingUser(action.customerId);

      return {
        ...state,
        customerId: action.customerId,
      };
    }

    case "SET_LANGUAGE": {
      return {
        ...state,
        language: action.language,
      };
    }

    case "SET_CUSTOMER_BATCH": {
      return {
        ...state,
        currentPlanId: action.planId,
        language: action.language,
        phonePrefix: action.phonePrefix,
        phoneNumber: action.phoneNumber,
        name: action.name,
        surname: action.surname,
        email: action.email,
        iban: action.iban,
        tester: action.tester,
        dateOfBirth: action.dateOfBirth,
        passwordSet: action.passwordSet,
      };
    }

    case "PASSWORD_SET":
      return {
        ...state,
        passwordSet: true,
      };

    case "UPDATE_CUSTOMER_PROFILE":
      return {
        ...state,
        name: action.name ?? state.name,
        surname: action.surname ?? state.surname,
        email: action.email || state.email,
        iban: action.iban ?? state.iban,
        dateOfBirth: action.dateOfBirth ?? state.dateOfBirth,
      };

    case "SET_PRIVATE_BUDGET":
      return {
        ...state,
        privateAmount: action.payload.amount,
        privateStartDate: action.payload.startDate,
      };

    case "SET_BUDGET": {
      const { startDate, endDate, amount, totalAmount } = action.plan;

      const { expectedEndDate, outOfBudgetPeriod, remainingDays } =
        calculateBudgetPeriod(startDate, endDate, state);

      return {
        ...state,
        amount,
        totalAmount,
        planLoaded: true,
        startDate: startDate || state.startDate,
        endDate: expectedEndDate,
        outOfBudgetPeriod,
        remainingDays,
      };
    }

    case "NO_PLAN": {
      const { remainingDays } = calculateBudgetPeriod(
        state.startDate,
        state.endDate,
        state
      );

      return {
        ...state,
        planLoaded: true,
        outOfBudgetPeriod: true,
        remainingDays,
      };
    }

    case "CLOSE_NO_BUDGET_WARNING":
      return {
        ...state,
        noBudgetWarningClosed: true,
      };

    case "CLOSE_OUT_OF_PERIOD_WARNING":
      return {
        ...state,
        outOfBudgetPeriodWarningClosed: true,
      };

    case "SET_WALLETS_STATUS":
      return {
        ...state,
        walletWarnings: action.payload,
      };

    default:
      return state;
  }
}
