import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { LocationCoords } from "services/mapService";
import { getMobitEfences, MobitEfence } from "services/mobitService";

export type LocationBoundingBox = {
  topLeft: LocationCoords;
  bottomRight: LocationCoords;
};

export function useNearbyEfences(coordinates: LocationBoundingBox | null) {
  const history = useHistory();

  const [results, setResults] = useState<MobitEfence[] | null>(null);

  const [loading, setLoading] = useState(false);

  const [key, setKey] = useState(Date.now());

  useEffect(() => {
    let canceled = false;
    let isLoading = false;

    async function fetchEfences(
      topLeft: LocationCoords,
      bottomRight: LocationCoords
    ) {
      try {
        setLoading(true);
        isLoading = true;

        const json = await getMobitEfences(topLeft, bottomRight, history);

        if (!json.error) {
          const efences: MobitEfence[] = json.data.map((fence) => ({
            image: fence.image,
            loc: {
              type: fence.loc.type,
              points: fence.loc.points.map((point) => ({
                lat: point.y,
                lng: point.x,
              })),
            },
            title: fence.title,
            typeName: fence.typeName,
            zOrder: fence.zOrder,
          }));

          if (!canceled) {
            setLoading(false);

            setResults(efences);
          }
        } else {
          !canceled && setLoading(false);

          console.error(
            "Failed to fetch e-fences",
            json.error_code,
            json.error_description
          );
        }
      } catch (error) {
        !canceled && setLoading(false);

        console.error("Failed to fetch e-fences", error);
      } finally {
        isLoading = false;
      }
    }

    coordinates && fetchEfences(coordinates.topLeft, coordinates.bottomRight);

    return () => {
      canceled = true;

      isLoading && console.debug("Canceled hook useEfences");
    };
  }, [key, coordinates, history]);

  const reload = () => setKey(Date.now());

  return [results, loading, reload] as const;
}
