import { trackExpenseAdded } from "analytics/events";
import { useEffect, useState } from "react";
import { batch, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { createExpense } from "services/historyService";
import { addExpenseAction, Expense } from "state/actions/TravelPassActions";
import { ApiErrors } from "utils";

export function useAddExpense(expensePayload: Expense | null) {
  const dispatch = useDispatch();

  const history = useHistory();

  const [uploadedExpense, setUploadedExpense] = useState<Expense | null>(null);

  const [uploading, setUploading] = useState(false);

  const [success, setSuccess] = useState<boolean>(false);

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let canceled = false;
    let isUploading = false;

    async function uploadExpense(expense: Expense) {
      setUploadedExpense(null);

      setUploading(true);
      isUploading = true;

      const expenseTrackingEvent = {
        action: expense.provider,
        label: "success" as "success" | "failure",
        value: expense.price.toString(),
      };

      try {
        const result = await createExpense(expense, history);

        if (!result.error) {
          const updatedExpense = {
            ...expense,
            id: result.id.toString(),
          };

          dispatch(addExpenseAction(updatedExpense));

          trackExpenseAdded(expenseTrackingEvent);

          if (!canceled) {
            setUploadedExpense(updatedExpense);

            setSuccess(true);

            setError(null);
          }
        } else {
          setSuccess(false);

          setError(result.error_code);

          expenseTrackingEvent.label = "failure";

          trackExpenseAdded(expenseTrackingEvent);

          console.error(
            "Failed to upload expense",
            result.error_code,
            result.error_description
          );
        }

        setUploading(false);
      } catch (error) {
        batch(() => {
          if (!canceled) {
            setSuccess(false);

            setError(ApiErrors.I_NETWORK);

            setUploading(false);
          }

          expenseTrackingEvent.label = "failure";

          trackExpenseAdded(expenseTrackingEvent);

          console.error("Failed to upload expense", error);
        });
      } finally {
        isUploading = false;
      }
    }

    expensePayload !== null && uploadExpense(expensePayload);

    return () => {
      canceled = true;

      isUploading && console.debug("Canceled hook useAddExpense");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expensePayload]);

  return [uploadedExpense, uploading, success, error] as [
    Expense | null,
    boolean,
    boolean,
    string | null
  ];
}
