import { SpendingTrackingData, trackTicketPurchase } from "analytics/events";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { orderBlueBikeTicket } from "services/blueBikeService";
import { getTicketDetail } from "services/travelPassService";
import {
  addActiveTravelPassAction,
  addPurchaseAction,
  BlueBikeTravelPass,
} from "state/actions";
import { ApiErrors, TravelPassProvider } from "utils";
import { normalizeBlueBikeTravelPass } from "utils/normalizers/blueBikeNormalizer";

export function useBlueBikeOrder(price: number) {
  const history = useHistory();

  const dispatch = useDispatch();

  const [ordering, setOrdering] = useState(false);

  const [errorCode, setErrorCode] = useState<string | null>(null);

  const [result, setResult] = useState<BlueBikeTravelPass[]>([]);

  const orderBlueBike = async (
    wallet: "private" | "business",
    amount: number
  ) => {
    setOrdering(true);

    setResult([]);

    setErrorCode(null);

    const trackingPayload: SpendingTrackingData = {
      action: TravelPassProvider["blue-bike"],
      label: "success" as "success" | "failure",
      value: price.toString(),
    };

    try {
      const ticketErrors: (string | null)[] = [];

      const tickets: BlueBikeTravelPass[] = [];

      const response = await orderBlueBikeTicket(wallet, amount, history);

      for (const ticket of response) {
        if (ticket.error) {
          ticketErrors.push(ticket.error_code);

          continue;
        }

        trackTicketPurchase(trackingPayload);

        const ticketDetail = await getTicketDetail(
          ticket.transaction_id,
          history
        );

        if (!ticketDetail.error) {
          const normalizedTicket = normalizeBlueBikeTravelPass(ticketDetail);

          tickets.push(normalizedTicket);

          ticketErrors.push(null);

          // dispatch even if navigated out
          dispatch(addActiveTravelPassAction(normalizedTicket));

          // add to history for case app goes offline so history is consistent
          dispatch(addPurchaseAction(normalizedTicket));
        } else {
          ticketErrors.push(ticketDetail.error_code);

          console.error(
            `Failed to find orderer Blue bike day pass: ${ticketDetail.error_code} - ${ticketDetail.error_description}`
          );
        }
      }

      setOrdering(false);

      setResult(tickets);

      const errorCode = ticketErrors.filter((error) => !!error)[0];

      setErrorCode(errorCode);

      if (errorCode) {
        trackingPayload.label = "failure";

        trackTicketPurchase(trackingPayload);
      }
    } catch (error) {
      trackingPayload.label = "failure";

      trackTicketPurchase(trackingPayload);

      setErrorCode(ApiErrors.I_NETWORK);

      console.error("Failed to order Blue bike tickets", error);
    } finally {
      setOrdering(false);
    }
  };

  return [orderBlueBike, ordering, result, errorCode] as const;
}
