import { useNetwork } from "common/hooks/useNetwork";
import { RefObject, useEffect, useState } from "react";
import {
  LocationAddress,
  LocationCoords,
  LocationSearchResultType,
} from "services/mapService";
import { NetworkState } from "utils/network";

export interface ReverseGeocodeResult {
  items: {
    id: string;
    address: LocationAddress;
    distance: number;
    title: string;
    position: LocationCoords;
    resultType: LocationSearchResultType;
  }[];
}

export type MyLocationAddress = {
  id: string;
  label: string;
  unknown?: boolean;
};

export function useReverseGeocode(
  platformRef: RefObject<H.service.Platform | null>,
  location: LocationCoords | null,
  enabled = true
) {
  const network = useNetwork();

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState<string | null>(null);

  const [address, setAddress] = useState<MyLocationAddress | null>(null);

  useEffect(() => {
    const platform = platformRef.current;

    if (!location || !platform || !enabled) {
      setAddress(null);

      setError(null);

      setLoading(false);

      return;
    }

    let canceled = false;

    const reverseGeocode = () => {
      // @ts-ignore
      const service = platform.getSearchService();

      setLoading(true);

      setError(null);

      service.reverseGeocode(
        {
          at: `${location.lat},${location.lng}`,
        },
        (result: ReverseGeocodeResult) => {
          if (canceled) {
            return;
          }

          if (result.items.length) {
            const item = result.items[0];

            setAddress({
              id: item.id,
              label: item.address.label,
            });
          } else {
            setAddress({ id: "", label: "Unknown", unknown: true });
          }

          setLoading(false);
        },
        (error) => {
          setError(error.message);

          setLoading(false);
        }
      );
    };

    network === NetworkState.online && reverseGeocode();

    return () => {
      canceled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.lat, location?.lng, enabled]);

  return [address, loading, error] as const;
}
