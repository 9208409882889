import { useEffect, useRef } from "react";

/**
 * Triggers refresh when app returns from background to foreground
 *
 * Note: This was specifically made to solve freeze issue on iOS (https://bugs.webkit.org/show_bug.cgi?id=211018)
 */
export function useRefreshOnReShow() {
  const lastRefresh = useRef(Date.now());

  useEffect(() => {
    const handle = window.setInterval(() => {
      if (Date.now() - lastRefresh.current > 1000) {
        window.location.reload();
      }

      lastRefresh.current = Date.now();
    }, 500);

    lastRefresh.current = Date.now();

    return () => {
      window.clearInterval(handle);
    };
  }, []);
}
