import { MobeaButton } from "common/forms";
import { CircleCheckIcon } from "icons/CircleCheckIcon";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { AppColors } from "utils/colors";

export function AddMoneySuccess(): ReactElement {
  const history = useHistory();
  const { t } = useTranslation();

  const goBack = () => history.goBack();

  return (
    <div
      css={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        flex: "1 0 auto",
      }}
    >
      <h2
        css={{
          fontSize: "2rem",
          lineHeight: 1.25,
          marginBottom: "1.5rem",
          fontWeight: 400,
          marginTop: "2.25rem",
        }}
      >
        {t("add_money.payment_completed")}
      </h2>
      <p
        css={{
          fontSize: "1rem",
          lineHeight: 1.5,
          marginTop: 0,
          marginBottom: "3.5rem",
          color: AppColors.GRAY_400,
        }}
      >
        {t("shared.thank_you")}
      </p>
      <CircleCheckIcon
        fill="#009E2B"
        width={68}
        height={68}
        css={{
          margin: "0 auto",
        }}
      />
      <div
        css={{
          flex: "1 1 auto",
        }}
      ></div>
      <MobeaButton onClick={goBack}>{t("shared.ok")}</MobeaButton>
    </div>
  );
}
