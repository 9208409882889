import { DefaultIconProps } from "icons/props";
import { VerticalClassicFlagIcon } from "./VerticalClassicFlagIcon";

const PortugesIcon = (props: DefaultIconProps) => (
  <VerticalClassicFlagIcon
    firstColor="#006501"
    secondColor="#FF0000"
    thirdColor="#FF0000"
    {...props}
  />
);

export default PortugesIcon;
