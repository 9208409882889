import { MobeaButton } from "common/forms";
import { MobeaModal } from "common/modal";
import { ProviderLogo, ProviderName } from "common/travelPasses";
import { getProviderNameNoSoftHyphen } from "common/travelPasses/ProviderName";
import { useTutorialHighlighter } from "common/tutorial2/Tutorial";
import { CloseIcon } from "icons/CloseIcon";
import { ReactElement } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ProviderPrimaryAction } from "state/actions";
import {
  openNativeApp,
  providerToActionsMapping,
  TravelPassProvider,
} from "utils";
import { AppColors } from "utils/colors";
import "./ProviderActionsDialog.scss";

type Props = {
  provider: TravelPassProvider;
  color: string;
  visible: boolean;
  primaryActionType: ProviderPrimaryAction;
  onClose(): void;
  onBuy(): void;
  onRefund(): void;
};

export function ProviderActionsDialog({
  provider,
  color,
  primaryActionType,
  onClose,
  onBuy,
  onRefund,
  visible,
}: Props): ReactElement {
  const { t } = useTranslation();

  const actions = providerToActionsMapping[provider];

  useTutorialHighlighter("providers", 4, "tutorial-dialog-primary", {
    wait: 250,
    offsetY: 10,
    maskMargin: [10, 10, 10, 10],
  });

  useTutorialHighlighter("providers", 5, "tutorial-dialog-secondary", {
    offsetY: 10,
  });

  const getPrimaryActionName = () => {
    // prioritize server configuration over local
    if (primaryActionType === ProviderPrimaryAction.Order) {
      if (actions.primaryAction) {
        // t("provider.action.buy_day_pass")
        return t(`provider.action.${actions.primaryAction}`);
      } else {
        return t("provider.action.buy_ticket");
      }

      // fallback to local config for expensed provider
    } else if (actions.app) {
      return t("provider.action.refund");
    } else if (actions.primaryAction) {
      // t("provider.action.buy_day_pass")
      return t(`provider.action.${actions.primaryAction}`);
    } else {
      return t("provider.action.refund");
    }
  };

  const getSecondaryActionName = () => {
    if (primaryActionType === ProviderPrimaryAction.Order) {
      return t("provider.action.refund");
    } else if (actions.app) {
      return actions.app.website
        ? t("provider.action.open_website")
        : t("provider.action.open_app");
    } else {
      return t("provider.action.refund");
    }
  };

  const getProviderInfo = () => {
    /*
      t("provider.info.ticket")
      t("provider.info.ticket_app")
      t("provider.info.pass")
      t("provider.info.pass_app")
      t("provider.info.taxi")
      t("provider.info.taxi_app")
      t("provider.info.car_scooter_app")
      t("provider.info.bike_app")
      t("provider.info.kickscooter_app")
      t("provider.info.car_app")
      t("provider.info.scooter_app")
      t("provider.info.mivb")
      t("provider.info.mivb_app")
      t("provider.info.uber")
      t("provider.info.uber_app") 
      t("provider.info.velo")
      t("provider.info.velo_app")
      t("provider.info.blue-bike")
      t("provider.info.blue-bike_app")
      t("provider.info.workspace_app")
    */
    return primaryActionType === ProviderPrimaryAction.Order
      ? `provider.info.${actions.info}`
      : `provider.info.${actions.info}_app`;
  };

  const onPrimaryAction = () => {
    if (primaryActionType === ProviderPrimaryAction.Order) {
      onBuy();
    } else if (actions.app) {
      onRefund();
    } else {
      onBuy();
    }
  };

  const onSecondaryAction = () => {
    if (primaryActionType === ProviderPrimaryAction.Order) {
      onRefund();
    } else if (actions.app) {
      openNativeApp(actions.app);
    } else {
      onRefund();
    }
  };

  return (
    <MobeaModal
      title=""
      visible={visible}
      className="mobea-provider-actions-dialog"
    >
      <div
        className="mobea-provider-actions-dialog__stripe"
        style={{ backgroundColor: color }}
      />

      <CloseIcon fill={AppColors.GRAY_300} onClick={onClose} />

      <ProviderLogo provider={provider} />

      <div className="mobea-provider-actions-dialog__name">
        <ProviderName provider={provider} />
      </div>

      <p className="mobea-provider-actions-dialog__info">
        <Trans i18nKey={getProviderInfo()}>
          {{ name: getProviderNameNoSoftHyphen(provider) }}
        </Trans>
      </p>

      <MobeaButton id="tutorial-dialog-primary" onClick={onPrimaryAction}>
        {getPrimaryActionName()}
      </MobeaButton>

      <MobeaButton
        id="tutorial-dialog-secondary"
        type="secondary"
        onClick={onSecondaryAction}
      >
        {getSecondaryActionName()}
      </MobeaButton>
    </MobeaModal>
  );
}
