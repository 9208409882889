import {
  ADMIN_API_BASE,
  LOCAL_STORAGE_AUTH_KEY,
  LoginResultEnum,
} from "utils/constants";
import { ApiErrorResponse } from "utils/errors";
import { logIn, register } from "./customerService";

type TokenMetadata = {
  token_type: "refresh";
  exp: number; // unit timestamp
  jti: string;
  user_id: string;
};

type SessionStorageData = {
  token: string | null;
  tokenExpiration: number;
  refreshToken: string | null;
};

type LoginSuccessResponse = {
  access: string;
  refresh: string;
  error: false;
};

export type LoginResponse =
  | LoginSuccessResponse
  | {
      error: true;
      detail: string;
      code: string;
      messages: {
        token_class: string;
        token_type: string;
        message: string;
      }[];
    };

class AuthTokenStore {
  private metadata: TokenMetadata | null = null;

  private token: string | null = null;

  private tokenExpiration = 0;

  private refreshToken: string | null = null;

  private get tokenIsExpired() {
    return this.tokenExpiration * 1000 < Date.now();
  }

  private onGoingRefresh: Promise<LoginResultEnum> | null = null;

  private onGoingRequest: Promise<LoginResultEnum> | null = null;

  constructor() {
    try {
      // load session from session storage
      const data: string | null = window.localStorage.getItem(
        LOCAL_STORAGE_AUTH_KEY
      );

      if (data) {
        const decoded = window.atob(data);

        const parsedData: SessionStorageData = JSON.parse(decoded);

        this.token = parsedData.token || null;

        this.tokenExpiration = parsedData.tokenExpiration || 0;

        this.refreshToken = parsedData.refreshToken || null;

        this.metadata = this.extractMetadata();
      }
    } catch (e) {
      console.error("Something wrong with session storage", e);
    }
  }

  private static parseMetadata(token: string) {
    // sections - 0. token data 1. metadata 2. hash
    const metadataSection = token.split(".")[1];

    let metadata: TokenMetadata | null = null;

    try {
      metadata = JSON.parse(window.atob(metadataSection));
    } catch (e) {
      console.error("Error handling token metadata", e);
    }

    return metadata;
  }

  private extractMetadata() {
    // skip parsing when no token is provided
    if (this.token === null) {
      return null;
    }

    return AuthTokenStore.parseMetadata(this.token);
  }

  /**
   * Update session storage to keep it synced
   */
  private updateSessionStorageData() {
    const data: SessionStorageData = {
      token: this.token,
      tokenExpiration: this.tokenExpiration,
      refreshToken: this.refreshToken,
    };

    const json = JSON.stringify(data);

    const base64 = window.btoa(json);

    window.localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, base64);
  }

  /**
   * Update tokens in store
   */
  private setTokens(token: string, expiration: number, refreshToken: string) {
    console.log("Token set to Auth storage", token);

    this.token = token;

    this.tokenExpiration = expiration;

    this.refreshToken = refreshToken;

    this.metadata = this.extractMetadata();

    this.updateSessionStorageData();
  }

  public async Register(
    phonePrefix: string,
    phoneNumber: string,
    password: string,
    passwordConfirm: string,
    dob: string
  ) {
    const body = await register(
      phonePrefix,
      phoneNumber,
      password,
      passwordConfirm,
      dob
    );

    if (!body.error_code) {
      if (!body.access || !body.expire || !body.refresh) {
        throw new Error("missing tokens");
      }

      this.setTokens(body.access, body.expire, body.refresh);
    }

    return body;
  }

  public async logIn(
    phonePrefix: string,
    phoneNumber: string,
    password: string
  ) {
    const body = await logIn(phonePrefix, phoneNumber, password);

    if (!body.error_code) {
      if (!body.access || !body.expire || !body.refresh) {
        throw new Error("missing tokens");
      }

      this.setTokens(body.access, body.expire, body.refresh);
    }

    return body;
  }

  private static getExpirationFromToken(token: string) {
    let expiration = Date.now() + 900 * 1000;

    const metadata = this.parseMetadata(token);

    if (metadata) {
      expiration = metadata.exp * 1000;
    } else {
      console.error(
        "Can't identify token expiration. Used default 15 minutes."
      );
    }

    return expiration;
  }

  /**
   * Use refresh token to get recent token
   */
  public updateToken = () => {
    // issue new refresh request only once
    if (this.onGoingRefresh === null) {
      // skip call if no refresh token is available
      if (this.refreshToken === null) {
        return Promise.resolve(LoginResultEnum.INVALID);
      }

      this.onGoingRefresh = fetch(`${ADMIN_API_BASE}token/refresh/`, {
        method: "POST",
        headers: new window.Headers({
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({ refresh: this.refreshToken }),
      })
        .then((response) => response.json())
        // force cast error property to boolean so we can use it to distinct error cases
        .then((json) => ({
          ...json,
          error: !!json.error,
        }))
        .then((json: LoginResponse) => {
          console.log("Refresh", json);

          if (json.error) {
            console.warn("Update token error response", json.detail);
            this.clearTokens();

            this.onGoingRefresh = null;

            return LoginResultEnum.ERROR;
          }

          this.setTokens(
            json.access,
            AuthTokenStore.getExpirationFromToken(json.access),
            json.refresh || this.refreshToken || "" // refresh missing in response...
          );

          this.onGoingRefresh = null;

          return LoginResultEnum.SUCCESS;
        })
        .catch((e) => {
          console.error("Update token error", e);

          this.clearTokens();

          this.onGoingRefresh = null;

          return LoginResultEnum.ERROR;
        });
    }

    return this.onGoingRefresh;
  };

  /**
   * Identifies logged in user
   */
  public hasToken = () => {
    return !!this.token && !this.tokenIsExpired;
  };

  /**
   * Returns recent token. If its expired it gets revalidated
   */
  public getToken = (): Promise<string | null> => {
    // we already have active session
    if (this.token) {
      if (this.tokenIsExpired) {
        return this.updateToken().then(() => this.token);
      } else {
        return Promise.resolve(this.token);
      }

      // wait for request end
    } else if (this.onGoingRequest !== null) {
      return this.onGoingRequest.then(this.getToken);

      // wait for request end
    } else if (this.onGoingRefresh !== null) {
      return this.onGoingRefresh.then(this.getToken);
    }
    return Promise.resolve(null);
  };

  /**
   * Request token from auth endpoint using username and password
   */
  public requestTokens = (customerUuid: string) => {
    if (this.onGoingRequest === null) {
      this.onGoingRequest = fetch(ADMIN_API_BASE + "token/", {
        method: "POST",
        headers: new window.Headers({
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({ customer_id: customerUuid }),
      })
        .then((response) => response.json())
        .then((json) => ({
          ...json,
          error: !!json.error || !!json.error_code,
        }))
        .then((json: LoginResponse) => {
          console.log("Log in", json);

          if (json.error) {
            this.onGoingRequest = null;

            if (json.code) {
              return LoginResultEnum.INVALID;
            }

            return LoginResultEnum.ERROR;
          }

          this.setTokens(
            json.access,
            AuthTokenStore.getExpirationFromToken(json.access),
            json.refresh
          );

          this.onGoingRequest = null;

          return LoginResultEnum.SUCCESS;
        })
        .catch((e) => {
          console.error("Log in error", e);

          this.onGoingRequest = null;

          return LoginResultEnum.ERROR;
        });
    }

    return this.onGoingRequest;
  };

  public resetPassword = (
    password: string,
    token: string
  ): Promise<{ error: false; customerId: string | null } | ApiErrorResponse> =>
    fetch(`${ADMIN_API_BASE}reset-password`, {
      method: "POST",
      headers: new window.Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        password,
        token,
      }),
    })
      .then((response) => response.json())
      .then((json) => ({
        ...json,
        error: !!json.error_code,
      }))
      .then((json) => {
        // log user in on success
        if (!json.error) {
          console.log("Reset and log in");

          this.setTokens(
            json.access,
            AuthTokenStore.getExpirationFromToken(json.access),
            json.refresh
          );

          return {
            error: false,
            customerId: this.getCustomerId(),
          };
        }

        // errors to be handled in component
        // as standard API errors might happen
        return json;
      });

  /**
   * Request token from auth endpoint using secret token from sms
   */
  public requestTokenWithCode = async (code: string) => {
    try {
      const response = await fetch(ADMIN_API_BASE + `customer-token/${code}`, {
        method: "GET",
      });

      const json = await response.json();
      json.error = !!json.error || !!json.error_code;

      console.log("Log in", json);

      if (json.error) {
        return json.code ? LoginResultEnum.INVALID : LoginResultEnum.ERROR;
      }

      this.setTokens(
        json.access,
        AuthTokenStore.getExpirationFromToken(json.access),
        json.refresh
      );

      return LoginResultEnum.SUCCESS;
    } catch (e) {
      console.error("Log in error", e);

      return LoginResultEnum.ERROR;
    }
  };

  public getCustomerId = () => {
    return this.metadata ? this.metadata.user_id || null : null;
  };

  /**
   * Clear tokens from local instance and session storage
   */
  public clearTokens = () => {
    window.localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);

    this.token = null;

    this.tokenExpiration = 0;

    this.refreshToken = null;

    this.metadata = null;
  };
}

export const Auth = new AuthTokenStore();
