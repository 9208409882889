import { BlueBikeTravelPass } from "common/travelPasses/blueBike/BlueBikeTravelPass";
import { MultiTicketDetail } from "common/travelPasses/MultiTicketDetail";
import { ReactElement } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import {
  BlueBikeTravelPass as BlueBikeTravelPassType,
  TravelPass,
} from "state/actions";
import { getSearchParams } from "utils";
import { BackButtonBehaviour } from "utils/routing";
import { BlueBikeTutorial } from "./BlueBikeTutorial";

type Props = {
  backButtonBehaviour?: BackButtonBehaviour;
};

export function BlueBikeMultiTicketDetail({
  backButtonBehaviour = "goHome",
}: Props): ReactElement {
  const location = useLocation();

  const queryParams = getSearchParams(location.search);

  const showTutorial = Boolean(queryParams.get("showTutorial"));

  const locale = useSelector((state) => state.user.language);

  const toPage = (pass: TravelPass, refresh: () => void) => (
    <BlueBikeTravelPass
      key={pass.id}
      {...(pass as BlueBikeTravelPassType)}
      locale={locale}
      refresh={refresh}
    />
  );

  return (
    <MultiTicketDetail
      backButtonBehaviour={backButtonBehaviour}
      toPage={toPage}
      info={<BlueBikeTutorial forceShow={showTutorial} />}
    />
  );
}
