import { BlueBikeMetadata } from "services/blueBikeService";
import { RawTravelPass } from "services/travelPassService";
import {
  BlueBikeStatus,
  BlueBikeTravelPass,
} from "state/actions/TravelPassActions";
import { addTimezoneToServerTime, parseFloatWithComma } from "utils";
import { TravelPassProvider, TravelPassType } from "../constants";

export function normalizeBlueBikeTravelPass(
  rawTravelPass: RawTravelPass
): BlueBikeTravelPass {
  const orderTimestamp = new Date(rawTravelPass.created).valueOf();

  const expiration = addTimezoneToServerTime(rawTravelPass.expire_at).valueOf();

  const {
    activation_datetime,
    code,
    fine = false,
    different_station_fine = false,
  } = (rawTravelPass.response as BlueBikeMetadata) || {};

  const activation = activation_datetime
    ? new Date(activation_datetime).valueOf()
    : undefined;

  const getStatus = (): BlueBikeStatus => {
    if (fine) {
      return BlueBikeStatus.Charged;
    } else if (different_station_fine) {
      return BlueBikeStatus.Charged;
    } else if (expiration < Date.now()) {
      return BlueBikeStatus.Expired;
    } else if (code) {
      return BlueBikeStatus.Active;
    }
    return BlueBikeStatus.Ready;
  };

  return {
    id: rawTravelPass.id,
    status: getStatus(),
    password: code,
    orderTimestamp,
    activation,
    expiration,
    price: parseFloatWithComma(rawTravelPass.amount) || 0,
    wrongParkingFine: different_station_fine,
    types: [TravelPassType.Bike],
    provider: TravelPassProvider["blue-bike"],
    plan: rawTravelPass.plan,
  };
}
