import { ReactElement } from "react";
import { getI18n, useTranslation } from "react-i18next";
import { TravelPassProvider } from "utils/constants";
import { DEFAULT_INTL_NAMESPACE } from "utils/i18n";
import { SOFT_HYPHEN_ENCODED } from "utils/intl";

interface Props {
  provider: TravelPassProvider;
}

export const getProviderNameNoSoftHyphen = (provider: TravelPassProvider) => {
  // avoid standard t function as it does escape values and we need raw name with soft hyphen!
  const i18n = getI18n();

  return i18n
    .getResource(
      i18n.language,
      DEFAULT_INTL_NAMESPACE,
      `providers_names.${provider}`
    )
    .replace(SOFT_HYPHEN_ENCODED, "");
};

// use instead of string in case when we need word breaking
export function ProviderName({ provider }: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: t(`providers_names.${provider}`),
      }}
    />
  );
}
