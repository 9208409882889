import { OrderError, OrderErrorProps } from "common/errors/OrderError";
import { MobeaModal } from "common/modal";
import { ReactElement } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ApiErrors } from "utils";

type Props = {
  buyTicket(): void;
  viewTicket(): void;
  origin: string;
  destination: string;
  closeOrder(): void;
} & OrderErrorProps;

export function NmbsOrderError({
  buyTicket,
  viewTicket,
  origin,
  destination,
  closeOrder,
  errorCode,
  closeError,
}: Props): ReactElement {
  const { t } = useTranslation();

  switch (errorCode) {
    case ApiErrors.NMBS_SOURCE_TO_DESTINATION_TICKET_ERROR:
      return (
        <MobeaModal
          type="error"
          title={t("order_nmbs.one_ticket_error_title")}
          confirmText={t("shared.buy_ticket")}
          onConfirm={buyTicket}
          cancelText={t("shared.view_the_ticket")}
          onCancel={viewTicket}
        >
          <p>
            <Trans i18nKey="order_nmbs.one_ticket_error_text">
              {{ source: destination, destination: origin }}
            </Trans>
          </p>
        </MobeaModal>
      );
    case ApiErrors.NMBS_DESTINATION_TO_SOURCE_TICKET_ERROR:
      return (
        <MobeaModal
          type="error"
          title={t("order_nmbs.one_ticket_error_title")}
          confirmText={t("shared.buy_ticket")}
          onConfirm={buyTicket}
          cancelText={t("shared.view_the_ticket")}
          onCancel={viewTicket}
        >
          <p>
            <Trans i18nKey="order_nmbs.one_ticket_error_text">
              {{ source: origin, destination: destination }}
            </Trans>
          </p>
        </MobeaModal>
      );
    case ApiErrors.NMBS_RETURN_TICKET_ERROR:
      return (
        <MobeaModal
          type="error"
          title={t("order_nmbs.return_ticket_error_title")}
          confirmText={t("shared.try_again")}
          onConfirm={buyTicket}
          cancelText={t("shared.leave_the_form")}
          onCancel={closeOrder}
        >
          <p>
            <Trans i18nKey="order_nmbs.return_ticket_error_text">
              {{ source: origin, destination }}
            </Trans>
          </p>
        </MobeaModal>
      );
    default:
      return <OrderError errorCode={errorCode} closeError={closeError} />;
  }
}
