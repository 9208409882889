import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

type Props = DefaultIconProps & {
  outlined?: boolean;
};

export function PointFingerIcon({
  fill = "currentColor",
  className = "",
  height = "84",
  width = "80",
  outlined = false,
}: Props): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 80 ${outlined ? "87" : "84"}`}
    >
      <g transform="translate(0 4)" fill="none">
        <circle stroke={fill} strokeWidth="4" cx="32" cy="14" r="16" />
        <path
          d="M76.667 41.643v25.024C76.667 74 70.667 80 63.333 80H39c-3.6 0-7-1.433-9.5-3.967l-26.167-26.6s4.2-4.1 4.334-4.166A3.95 3.95 0 0110.3 44.3c.733 0 1.4.2 2 .533.133.034 14.367 8.2 14.367 8.2v-39.7c0-2.766 2.233-5 5-5 2.766 0 5 2.234 5 5v23.334l35.487-.001a4.993 4.993 0 014.513 4.977z"
          stroke={fill}
          strokeWidth={outlined ? 7 : 0}
          fill={outlined ? "white" : fill}
        />
      </g>
    </svg>
  );
}
