import { DefaultIconProps } from "icons/props";
import { ReactElement } from "react";
import { VerticalClassicFlagIcon } from "./VerticalClassicFlagIcon";

export function FranceIcon(props: DefaultIconProps): ReactElement {
  return (
    <VerticalClassicFlagIcon
      firstColor="#2641ac"
      secondColor="#fff"
      thirdColor="#f43c51"
      {...props}
    />
  );
}
