import { TutorialDialog, TutorialLine } from "common/tutorial/";
import { ReactElement } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { TravelPassProvider, VELO_SUPPORT_PHONE_NUMBER } from "utils";

type Props = {
  forceShow?: boolean;
};

export function VeloTutorial({ forceShow = false }: Props): ReactElement {
  const { t } = useTranslation();

  const { duration } = useSelector((state) => state.ordering.veloData);

  return (
    <TutorialDialog
      provider={TravelPassProvider["velo-antwerpen"]}
      className="velo-tutorial"
      steps={[
        {
          headline: t("velo_detail.borrowing_bike"),
          image: (
            <img src="/static/images/velo-station.png" alt="velo station" />
          ),
          info: (
            <>
              <TutorialLine infoText={t("velo_detail.borrowing_info_codes")} />
              <TutorialLine infoText={t("velo_detail.borrowing_info_lock")} />
              <TutorialLine infoText={t("velo_detail.borrowing_info_take")} />
              <TutorialLine
                infoText={
                  <Trans i18nKey="velo_detail.borrowing_info_duration">
                    <span className="mobea__arial">{{ duration }}</span>
                    <a
                      href="https://www.velo-antwerpen.be/en/rates"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      extra charges
                    </a>
                  </Trans>
                }
              />
              <TutorialLine
                infoText={
                  <Trans i18nKey="velo_detail.info_contact">
                    <a
                      className="mobea__arial"
                      href={`tel:${VELO_SUPPORT_PHONE_NUMBER}`}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {VELO_SUPPORT_PHONE_NUMBER}
                    </a>
                  </Trans>
                }
              />
            </>
          ),
        },
        {
          headline: t("velo_detail.returning_bike"),
          image: (
            <img
              src="/static/images/velo-station-return.png"
              alt="velo station"
            />
          ),
          info: (
            <>
              <TutorialLine infoText={t("velo_detail.return_info_place")} />
              <TutorialLine infoText={t("velo_detail.return_info_wait")} />
              <TutorialLine infoText={t("velo_detail.return_info_codes")} />
              <TutorialLine
                infoText={t("velo_detail.return_info_confirmation")}
              />
              <TutorialLine
                infoText={
                  <Trans i18nKey="velo_detail.info_contact">
                    <a
                      className="mobea__arial"
                      href={`tel:${VELO_SUPPORT_PHONE_NUMBER}`}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {VELO_SUPPORT_PHONE_NUMBER}
                    </a>
                  </Trans>
                }
              />
            </>
          ),
        },
      ]}
      forceShow={forceShow}
    />
  );
}
