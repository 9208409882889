import { ReactElement } from "react";
import "./CloseIcon.scss";
import { DefaultIconProps } from "./props";

export function CloseIcon({
  fill = "currentColor",
  width = 20,
  height = 20,
  className = "",
  onClick,
}: DefaultIconProps): ReactElement {
  return (
    <div
      className={`mobea__close-icon ${className}`}
      style={{
        display: "inline-block",
        height,
        width,
        color: fill,
      }}
      onClick={onClick}
    />
  );
}
