import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

export function FaqIcon({
  fill = "currentColor",
  className = "",
  height = "20",
  width = "20",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8 1.7H4.2c-1 0-1.7.7-1.7 1.6V15c0 1 .7 1.7 1.7 1.7h3.3l2.5 2.5 2.5-2.5h3.3c1 0 1.7-.8 1.7-1.7V3.3c0-.9-.8-1.6-1.7-1.6zm0 13.3h-4l-.5.5-1.3 1.3-1.3-1.3-.5-.5h-4V3.3h11.6V15zm-6.6-2.5h1.6v1.7H9.2v-1.7zm.8-6.7c1 0 1.7.8 1.7 1.7 0 1.7-2.5 1.5-2.5 4.2h1.6c0-2 2.5-2.1 2.5-4.2a3.3 3.3 0 10-6.6 0h1.6c0-1 .8-1.7 1.7-1.7z"
        fill={fill}
      />
    </svg>
  );
}
