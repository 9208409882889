import { ReactElement } from "react";
import { AppColors } from "utils/colors";
import { DefaultIconProps } from "./props";

export function AppLogoSmall({
  className = "",
  height = "30px",
  width = "30px",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 950 950"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path
          d="M902.5 379.99H855V284.99c0-52.394-42.607-95.002-95-95.002h-18.145l-81.89-163.735C648.945 4.164 622.773-5.764 599.83 3.404L133.332 189.987H95c-52.392 0-95 42.608-95 95.002v570.01C0 907.391 42.608 950 95 950h665c52.393 0 95-42.608 95-95.002v-95.001h47.5c26.22 0 47.5-21.28 47.5-47.501V427.49c0-26.22-21.28-47.5-47.5-47.5zM614.402 50.56l71.266 140.15-407.143.887L614.402 50.56zM763.5 898.553H91.447c-22.091 0-40-17.909-40-40V282.157c0-22.092 17.909-40 40-40h672.194c22.092 0 40 17.908 40 40v97.636h-186.02a47.434 47.434 0 00-33.597 13.974L441.467 536.355c-18.58 18.537-18.58 48.576 0 67.207l142.557 142.589c8.886 8.84 21.004 13.879 33.596 13.879h185.926l-.033 98.523c-.022 22.086-17.928 39.986-40.014 40zM898.553 708.73H619.316l-138.55-138.819 138.552-138.819h279.235V708.73zm-264.528-77.582c-3.888-18.695 10.048-40.02 24.717-54.26 14.668-14.241 28.237-30.38 28.237-30.38 23.763-31.038 6.821-43.819 6.821-43.819-13.862-12.56-47.454-9.055-47.454-9.055a183.458 183.458 0 00-51.34 10.443c-7.335 9.932-22.003 52.07-14.67 92.31 7.335 40.239 33.592 71.35 77.525 75.074a81.508 81.508 0 0045.547-6.28s-61.315 4.527-69.383-34.033z"
          fill={AppColors.PRIMARY}
        />
        <path
          d="M743.686 637.141c7.257-9.908 21.77-52.33 14.514-92.697-6.241-40.66-33.237-71.706-76.705-75.449-19.449-2.128-37.3 1.248-45.065 6.312h-.436a89.683 89.683 0 0150.798 9.248c22.206 13.137 21.118 35.89 14.006 50.862a145.87 145.87 0 01-24.818 32.587 241.517 241.517 0 00-30.261 36.036c-7.257 13.064-7.257 27.376-1.307 32.954 19.812 18.936 77.721 8.147 99.201 0"
          fill={AppColors.SECONDARY}
        />
      </g>
    </svg>
  );
}
