import { InputGroup } from "common/forms";
import { LocationPicker } from "common/search";
import { RouteIcon } from "icons/RouteIcon";
import { ToggleDirectionIcon } from "icons/ToggleDirectionIcon";
import { ReactElement, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  LocationSearchPlace,
  LocationSearchPlaceWithId,
} from "services/mapService";
import { AppColors } from "utils/colors";
import "./SourceDestPicker.scss";

export enum LocationPickerTarget {
  Source = "from",
  Destination = "to",
}

type SourceDestPickerBaseProps = {
  disabled?: boolean;
  sameLocationError?: ReactNode;
  filterOutLocalities?: boolean;
  sourceLabel?: string;
  destinationLabel?: string;
  loadingSource?: boolean;
  loadingDestination?: boolean;
  sourceNumberError?: boolean;
  onPickerToggle?: (
    visible: boolean,
    target: LocationPickerTarget | null
  ) => void;
};

type SourceDestPickerWithIdProps = SourceDestPickerBaseProps & {
  withId: true;
  destination: LocationSearchPlaceWithId | null;
  source: LocationSearchPlaceWithId | null;
  onSourceChange: (location: LocationSearchPlaceWithId | null) => void;
  onDestinationChange: (location: LocationSearchPlaceWithId | null) => void;
};

type SourceDestPickerProps = SourceDestPickerBaseProps & {
  withId: false;
  destination: LocationSearchPlace | null;
  source: LocationSearchPlace | null;
  onSourceChange: (location: LocationSearchPlace | null) => void;
  onDestinationChange: (location: LocationSearchPlace | null) => void;
};

export function SourceDestPicker({
  source,
  sourceLabel,
  destination,
  destinationLabel,
  disabled = false,
  sameLocationError = null,
  loadingSource = false,
  loadingDestination = false,
  filterOutLocalities = false,
  sourceNumberError = false,
  onSourceChange,
  onDestinationChange,
  onPickerToggle,
}: SourceDestPickerProps | SourceDestPickerWithIdProps): ReactElement {
  const { t } = useTranslation();

  const [pickerTarget, setPickerTarget] = useState<LocationPickerTarget | null>(
    null
  );

  const showHidePicker = (target: LocationPickerTarget | null) => {
    setPickerTarget(target);
    onPickerToggle && onPickerToggle(!!target, target);
  };

  const setPickedLocation = (location: LocationSearchPlaceWithId) => {
    if (pickerTarget === LocationPickerTarget.Source) {
      onSourceChange(location);
    } else if (pickerTarget === LocationPickerTarget.Destination) {
      onDestinationChange(location);
    }

    showHidePicker(null);
  };

  const toggleDirection = () => {
    if (disabled) {
      return;
    }

    // well TS get confused here and does not narrow down type
    onSourceChange(destination as LocationSearchPlaceWithId);
    onDestinationChange(source as LocationSearchPlaceWithId);
  };

  const fromText = sourceLabel || t("map.from");
  const toText = destinationLabel || t("map.to");

  const isSameSourceAndDestination =
    sameLocationError &&
    source &&
    destination &&
    !loadingSource &&
    source.label === destination.label;

  return (
    <div className="mobea__source-destination">
      <RouteIcon className="mobea__source-destination__route-icon" />
      <div className="mobea__source-destination__fields">
        <InputGroup
          label={fromText}
          name="from"
          className="mobea__source-destination__fields__input"
          placeholder={fromText}
          toggleLabelAndPlaceholder
          value={source?.label || ""}
          disabled={disabled}
          loading={loadingSource}
          inputAttributes={{
            autoComplete: "off",
            readOnly: true,
            onClick: () => showHidePicker(LocationPickerTarget.Source),
          }}
          errorMessage={
            sourceNumberError ? t("verts_order.missing_street_number") : null
          }
          css={{
            marginBottom: sourceNumberError ? 10 : 0,
          }}
        />
        <InputGroup
          label={toText}
          name="to"
          className="mobea__source-destination__fields__input"
          placeholder={toText}
          toggleLabelAndPlaceholder
          value={destination?.label || ""}
          disabled={disabled}
          loading={loadingDestination}
          inputAttributes={{
            autoComplete: "off",
            readOnly: true,
            onClick: () => showHidePicker(LocationPickerTarget.Destination),
          }}
          errorMessage={
            isSameSourceAndDestination ? sameLocationError : undefined
          }
        />
      </div>
      <ToggleDirectionIcon
        className="mobea-verts-order__route__toggle-direction"
        fill={disabled ? AppColors.GRAY_200 : "currentColor"}
        onClick={toggleDirection}
      />
      {pickerTarget && (
        <LocationPicker
          className="route-planner-location-picker"
          label={t(`map.${pickerTarget}`)}
          defaultLocation={
            pickerTarget === LocationPickerTarget.Source ? source : destination
          }
          previousLocation={null}
          filterOutLocalities={filterOutLocalities}
          onSelect={setPickedLocation}
          onClose={() => showHidePicker(null)}
        />
      )}
    </div>
  );
}
