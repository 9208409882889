import { DefaultIconProps } from "icons/props";
import { ReactElement } from "react";

export function ContactUsImage({
  fill = "currentColor",
  className = "",
  height = "80",
  width = "64",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 64 80"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M53.343 22.038c.269 1.767-.179 5.214-.627 7.688-.268 1.326-.447 2.298-.716 3.182.292.073.594.103.896.088 2.819 0 5.104-2.255 5.104-5.037v-5.921a5.004 5.004 0 00-1.495-3.563A5.14 5.14 0 0052.895 17c.27 1.59.27 3.005.359 3.977-.023.356.007.713.09 1.06z"
        fill={fill}
      />
      <path
        d="M63.746 56.348a15.629 15.629 0 00-4.384-9.125c-2.763-2.98-7.814-6.332-16.677-7.729.657-.8 1.262-1.64 1.81-2.514 1.54.074 3.083-.083 4.575-.466.476-.186 1.334-.558 2.287-3.724.316-1.103.57-2.222.763-3.352a36.4 36.4 0 00.667-8.102c0-.279-.096-.651-.096-1.21-.095-1.024-.095-2.514-.38-4.19C51.356 9.604 47.544.106 31.724.013 25.15-.174 20.004 1.689 16.287 5.507a20.551 20.551 0 00-5.337 10.522c-2.61.437-4.53 2.628-4.574 5.214v6.24c0 2.93 2.432 5.307 5.432 5.307 2.035-.032 3.894-1.136 4.86-2.887a22.957 22.957 0 004.67 9.498C1.23 42.661.277 56.162.277 56.348c-.096 1.956-.572 11.826 0 13.875l.095.186C5.804 79.721 29.343 80 32.012 80c2.668 0 26.207-.28 31.544-9.591l.095-.186c.667-1.956.19-11.92.095-13.875zM20.861 11.28c3.24-3.445 7.91-4.19 10.483-4.284h1.334c2.478.094 7.243.839 10.388 4.284 2.478 2.7 3.621 6.518 3.336 11.453a35.703 35.703 0 01-2.764 11.36c-1.906 0-4.289 0-6.862-.093a5.262 5.262 0 00-3.621-1.21c-2.383 0-4.288 1.117-4.288 2.607 0 1.49 1.905 2.607 4.288 2.607 1.3.031 2.575-.362 3.621-1.117 1.716.093 3.622.093 5.432.093a12.763 12.763 0 01-1.715 2.235l-.572.559c-.381.372-.857.744-1.239 1.117a12.875 12.875 0 01-6.67 2.607 12.875 12.875 0 01-6.672-2.607c-.476-.373-.857-.745-1.334-1.117l-.572-.56c-5.05-5.772-5.813-15.084-5.813-16.48-.286-4.936.763-8.754 3.24-11.454zm-7.052 4.935a18.214 18.214 0 014.48-8.846c3.144-3.166 7.623-4.749 13.341-4.656 14.105.186 17.154 8.101 17.916 13.968-.559.3-1.046.712-1.429 1.21a14.933 14.933 0 00-3.526-7.822C40.97 6.065 35.633 5.32 32.869 5.134h-1.525c-2.859.093-8.1.931-11.722 4.842a13.962 13.962 0 00-3.526 7.636 11.515 11.515 0 00-2.287-1.397zm48.126 53.356c-2 3.445-7.624 5.96-16.105 7.356a97.227 97.227 0 01-13.819 1.21 89.062 89.062 0 01-13.627-1.117c-8.577-1.396-14.2-4.004-16.201-7.356-.382-1.49-.19-8.753.095-13.13 0-.186.762-12.105 19.06-15.084v8.753l11.055-4.097 10.387 4.097v-8.94c18.202 2.98 19.06 14.527 19.06 15.178.286 4.377.477 11.64.095 13.13z"
        fill={fill}
      />
    </svg>
  );
}
