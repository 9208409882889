import { useCallback, useEffect, useRef, useState } from "react";

export function useLoading(subj: string) {
  const [loading, setLoadingInt] = useState(false);

  const loadingRef = useRef(false);

  const canceledRef = useRef(false);

  useEffect(() => {
    return () => {
      canceledRef.current = true;

      if (loadingRef.current) {
        console.debug(`Canceled loading ${subj}`);
      }
    };
  }, [subj]);

  const setLoading = useCallback((loading: boolean) => {
    if (!canceledRef.current) {
      setLoadingInt(loading);
    }

    loadingRef.current = loading;
  }, []);

  return [loading, setLoading, canceledRef] as const;
}
