import * as Sentry from "@sentry/react";
import { DateOfBirthPicker } from "common/datepicker/dateOfBirth/DateOfBirthPicker";
import { FieldLabel, MobeaButton } from "common/forms";
import { useBooleanState, useCustomerData } from "common/hooks";
import { useModal } from "common/hooks/useModal";
import { useContactUsModal } from "common/modal";
import { FullScreenPage } from "common/page/FullScreenPage";
import { PasswordInput } from "common/passwordInput/PasswordInput";
import { usePhoneNumbersValidation } from "common/phoneNumberPicker";
import { PhoneNumberPicker } from "common/phoneNumberPicker/PhoneNumberPicker";
import { LogoWithLangHeader } from "pages/setPassword/LogoWithLangHeader";
import { useSomethingWentWrongModal } from "pages/setPassword/useSomethingWentWrongModal";
import { FormEvent, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Auth } from "services/authTokenStore";
import { setCustomerIdAction } from "state/actions";
import { ApiErrors, removeSpaces, Routes } from "utils";
import { AppColors } from "utils/colors";
import i18n from "../../utils/i18n";
import "./RegisterPage.scss";
import { RegisterTitle } from "./RegisterTitle";
import { useCustomerExistsModal } from "./useCustomerExistsModal";

export function RegisterPage(): ReactElement {
  const { t } = useTranslation();

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");

  const [loggingIn, setLoggingIn] = useState(false);
  const [wrongPw, setWrongPw, resetWrongPw] = useBooleanState(false);

  const history = useHistory();

  const [
    prefix,
    localNumberFormatted,
    valid,
    onPrefixChange,
    updatePhoneNumber,
  ] = usePhoneNumbersValidation();

  const onDateOfBirthChange = (_, value: string) => {
    setDateOfBirth(value);
  };

  const dispatch = useDispatch();

  const { show: showContactUsModal } = useContactUsModal();

  const notActiveModal = useModal({
    cancelText: t("log_in.need_help"),
    cancelButtonType: "secondary",
    confirmText: t("shared.ok"),
    title: t("shared.sorry_but"),
    type: "error",
    onCancel: showContactUsModal,
    children: <p>{t("verification.error_client_inactive_text")}</p>,
  });

  const wrongPhoneOrPwErrorModal = useModal({
    confirmText: t("shared.ok"),
    cancelText: t("log_in.need_help"),
    cancelButtonType: "secondary",
    title: t("shared.sorry_but"),
    type: "error",
    onCancel: showContactUsModal,
    children: <p>{t("register.wrong_phone_or_password")}</p>,
  });

  const setPasswordErrorModal = useSomethingWentWrongModal();
  const customerExistsModal = useCustomerExistsModal();

  const [loggedIn, setLoggedIn] = useBooleanState();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    setLoggingIn(true);

    Auth.Register(
      prefix.code,
      removeSpaces(localNumberFormatted),
      password,
      passwordConfirm,
      dateOfBirth
    )
      .then((body) => {
        if (
          body.error_code === ApiErrors.NO_ACTIVE_PLAN ||
          body.error_code === ApiErrors.CLIENT_NOT_ACTIVE
        ) {
          notActiveModal.show();
        } else if (body.error_code === ApiErrors.CUSTOMER_PW_NOT_SET) {
          missingPasswordModal.show();
        } else if (
          body.error_code === ApiErrors.WRONG_PASSWORD ||
          body.error_code === ApiErrors.PASSWORD_VALIDATION_FAILED
        ) {
          wrongPhoneOrPwErrorModal.show();
        } else if (body.error_code === ApiErrors.CUSTOMER_ALREADY_EXISTS) {
          customerExistsModal.show();
        } else if (body.error_code === ApiErrors.PASSWORD_NOT_MATCH) {
          setWrongPw();
        } else if (body.error_code) {
          Sentry.captureMessage(
            "Error register user with error " + JSON.stringify(body)
          );
          setPasswordErrorModal.show();
        } else {
          const customerId = Auth.getCustomerId();

          if (!customerId) {
            throw new Error("missing customerId");
          }

          dispatch(setCustomerIdAction(customerId));

          setLoggedIn();
        }
      })
      .catch((e) => {
        console.error(e);

        setPasswordErrorModal.show();
      })
      .finally(() => {
        setLoggingIn(false);
      });
  };

  const loading = useCustomerData();

  // redirect only after we have the user, otherwise pending tutorial won't start
  useEffect(() => {
    if (!loading && loggedIn) {
      history.replace(Routes.Default);
    }
  }, [history, loading, loggedIn]);

  const missingPasswordModal = useModal({
    className: "missing-pw-modal",
    title: t("log_in.missing_password_title"),
    type: "error",
    cancelButtonType: "secondary",
    confirmText: t("log_in.set_password"),
    cancelText: t("log_in.need_help"),
    onCancel() {
      showContactUsModal();
    },
    onConfirm() {
      history.push(Routes.ForgottenPassword);
    },
    children: t("log_in.missing_password_body"),
  });

  return (
    <FullScreenPage
      pageClassName="m_login"
      padding={{
        left: 32,
        right: 32,
      }}
    >
      <LogoWithLangHeader />

      <form
        onSubmit={handleSubmit}
        css={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
      >
        <div
          css={{
            flexGrow: 1,
            justifyContent: "flex-end",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <RegisterTitle
            title={t("register.title")}
            text={t("register.text")}
          />

          <FieldLabel>{t("shared.phone_number")}</FieldLabel>

          <PhoneNumberPicker
            prefix={prefix}
            localNumberFormatted={localNumberFormatted}
            valid={valid}
            onPrefixChange={onPrefixChange}
            onPhoneNumberChange={updatePhoneNumber}
          />

          <div
            css={{
              height: 32,
            }}
          ></div>

          <FieldLabel>{t("register.password_placeholder")}</FieldLabel>

          <PasswordInput
            variant="outlined"
            value={password}
            onChange={(value) => setPassword(value)}
            error={wrongPw ? t("register.error_password_not_match") : undefined}
            noCheck
          />

          <FieldLabel>{t("register.password_confirm_placeholder")}</FieldLabel>

          <PasswordInput
            variant="outlined"
            value={passwordConfirm}
            onChange={(value) => setPasswordConfirm(value)}
            noCheck
          />

          <FieldLabel>{t("passenger.date_of_birth")}</FieldLabel>

          <DateOfBirthPicker
            title={t("passenger.date_of_birth")}
            dateInputLabel={""}
            className="register__form__dob"
            locale={i18n.language}
            selectedDate={dateOfBirth}
            onChange={onDateOfBirthChange}
          />
        </div>

        <div
          css={{
            flexGrow: 1,
            justifyContent: "flex-end",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <MobeaButton
            disabled={!valid || !password || !passwordConfirm || !dateOfBirth}
            loading={loggingIn}
          >
            {t("log_in.register")}
          </MobeaButton>

          <Link
            css={{
              marginTop: "1.5rem",
              display: "block",
              fontSize: "0.875rem",
              textDecoration: "none",
              textAlign: "center",
              color: AppColors.PRIMARY,
              fontWeight: "bold",
            }}
            to={Routes.Login}
          >
            {t("log_in.already_have_account")}
          </Link>
        </div>
      </form>
    </FullScreenPage>
  );
}
