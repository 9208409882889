import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

export function FileIcon({
  fill = "currentColor",
  className = "",
  height = "42",
  width = "34",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="10 6 34 42"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32 7H15.8c-2.2 0-4 1.9-4 4.2v33.6c0 2.3 1.8 4.2 4 4.2h24.5c2.3 0 4.1-1.9 4.1-4.2V19.6L32.1 7zM15.8 44.8V11.2H30v10.5h10.3v23.1H15.8z"
        fill={fill}
        fillRule="nonzero"
      />
    </svg>
  );
}
