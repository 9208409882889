import { InputGroup } from "common/forms";
import { MobeaModal } from "common/modal";
import { TFunction } from "i18next";
import { MouseEvent, ReactElement, useEffect, useState } from "react";
import DayPicker, {
  DateUtils,
  DayModifiers,
  RangeModifier,
} from "react-day-picker";
import {
  formatDateWithLocaleAsDigits,
  getDaysOfTheWeekShort,
  getFirstDayOfTheWeek,
  getMonths,
} from "utils";
import "./DatePicker.scss";
import "./DateRangePicker.scss";

export type DateRange = {
  from: Date;
  to: Date;
};

export interface DateRangePickerProps {
  title: string;
  dateFromLabel: string;
  dateToLabel: string;
  className?: string;
  startDate: number | null;
  endDate: number | null;
  from: number | undefined;
  to: number | undefined;
  locale: string;
  singleDayRange?: boolean;
  t: TFunction;
  onChange: (range: DateRange) => void;
}

export function DateRangePicker({
  title,
  dateFromLabel,
  dateToLabel,
  className = "",
  locale,
  startDate,
  endDate,
  from,
  to,
  singleDayRange = false,
  t,
  onChange,
}: DateRangePickerProps): ReactElement {
  const daysOfTheWeek = getDaysOfTheWeekShort(t, locale);

  const months = getMonths(t, locale);

  const [dateModalShown, setDateModalShown] = useState(false);

  const [dateRange, setDateRange] = useState<RangeModifier>({
    from: undefined,
    to: undefined,
  });

  useEffect(() => {
    setDateRange({
      from: from && new Date(from),
      to: to && new Date(to),
    } as any);
  }, [from, to]);

  const openDate = (e: MouseEvent) => {
    e.preventDefault();
    setDateModalShown(true);
  };

  const closeDate = () => setDateModalShown(false);

  const handleDayClick = (value: Date, modifiers: DayModifiers) => {
    const range = DateUtils.addDayToRange(value, dateRange);

    if (!modifiers.disabled) {
      if (singleDayRange) {
        setDateRange({
          from: value,
          to: value,
        });
      } else {
        setDateRange(range);
      }
    }
  };

  const onCancel = () => {
    setDateRange({
      from: undefined,
      to: undefined,
    } as any);
    closeDate();
  };

  const onConfirm = () => {
    closeDate();

    const { from, to } = dateRange;

    if (from && to) {
      onChange({ from, to });
    }
  };

  // handle case that we have no budget end date to prevent error
  const budgetInterval = {
    start: new Date(startDate || Date.now() - 24 * 3600 * 1000 * 100),
    end: new Date(endDate || Date.now() + 24 * 3600 * 1000 * 100),
  };

  const dateFromFormatted = dateRange.from
    ? formatDateWithLocaleAsDigits(dateRange.from, locale)
    : "";

  const dateToFormatted = dateRange.to
    ? formatDateWithLocaleAsDigits(dateRange.to, locale)
    : "";

  let monthsToShow = 1;

  if (budgetInterval.end.getFullYear() == budgetInterval.start.getFullYear()) {
    monthsToShow =
      1 + budgetInterval.end.getMonth() - budgetInterval.start.getMonth();
  } else {
    monthsToShow =
      1 +
      budgetInterval.end.getMonth() +
      (11 - budgetInterval.start.getMonth());
  }

  return (
    <div className={`mobea-date-range-picker ${className}`}>
      <InputGroup
        className={`${className} mobea__range__start`}
        label={dateFromLabel}
        type="text"
        name="date_start"
        value={dateFromFormatted}
        placeholder={dateFromLabel}
        toggleLabelAndPlaceholder
        inputAttributes={{
          onClick: openDate,
          autoComplete: "off",
          readOnly: true,
        }}
      />

      <InputGroup
        className={`${className} mobea__range__end`}
        label={dateToLabel}
        type="text"
        name="date_end"
        value={dateToFormatted}
        placeholder={dateToLabel}
        toggleLabelAndPlaceholder
        inputAttributes={{
          onClick: openDate,
          autoComplete: "off",
          readOnly: true,
        }}
      />

      {dateModalShown && (
        <MobeaModal
          className="mobea__datepicker-modal"
          onConfirm={onConfirm}
          title={title}
          confirmText={t("shared.select_this_date")}
          confirmDisabled={!dateRange.from || !dateRange.to}
          onClose={onCancel}
        >
          <DayPicker
            className="mobea__range-picker"
            numberOfMonths={monthsToShow}
            firstDayOfWeek={getFirstDayOfTheWeek(locale)}
            selectedDays={{ ...dateRange }}
            modifiers={{
              start: dateRange.from ?? undefined,
              end: dateRange.to ?? undefined,
            }}
            onDayClick={handleDayClick}
            disabledDays={{
              before: budgetInterval.start,
              after: budgetInterval.end,
            }}
            fromMonth={budgetInterval.start}
            toMonth={budgetInterval.end}
            locale={locale}
            weekdaysShort={daysOfTheWeek}
            months={months}
          />
        </MobeaModal>
      )}
    </div>
  );
}
