import { History } from "history";
import { ADMIN_API_BASE, ApiErrorResponse, CORE_API_BASE } from "utils";
import { callSecuredApiEndpoint } from "./apiCall";

export type BlueBikeDataResponse =
  | {
      error: false;
      validity_period_hours: string;
      day_pass_price: string;
      min_price: string;
      min_passes_bought: string;
      max_passes_bought: string;
      return_period_minutes: string;
    }
  | ApiErrorResponse;

const getBlueBikeDataRequest = (authorization: string) =>
  fetch(`${CORE_API_BASE}providers/blue-bike/data`, {
    method: "GET",
    headers: new window.Headers({
      Authorization: authorization,
    }),
  }).then((response) => response.json());

export function getBlueBikeData(history: History) {
  return callSecuredApiEndpoint(getBlueBikeDataRequest, history).then(
    (json) => ({
      ...json,
      error: !!json.error_code,
    })
  ) as Promise<BlueBikeDataResponse>;
}

// @TODO add real response!
export interface RawBlueBikeTicket {
  transaction_id: string;
}

export interface BlueBikeMetadata {
  code?: string;
  activation_datetime?: number; // epoche tme
  fine: boolean;
  different_station_fine: boolean;
}

export type BlueBikeOrderResponse = [
  | (RawBlueBikeTicket & {
      error: false;
    })
  | ApiErrorResponse
];

function orderBlueBikeTicketRequest(
  wallet: "private" | "business",
  amount: number
) {
  return (authorization: string) =>
    fetch(`${ADMIN_API_BASE}blue-bike/orders`, {
      method: "POST",
      headers: new window.Headers({
        "Content-Type": "application/json",
        Authorization: authorization,
      }),
      body: JSON.stringify({
        data: {
          amount,
          plan_type: wallet,
        },
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        // global error - e.g. not enough budget
        if (json.error_code) {
          return [
            {
              error: true,
              ...json,
            },
          ];
        }

        return json.map((ticketResponse) => ({
          ...ticketResponse,
          error: !!ticketResponse.error_code,
        }));
      });
}

export function orderBlueBikeTicket(
  wallet: "private" | "business",
  amount: number,
  history: History
) {
  return callSecuredApiEndpoint(
    orderBlueBikeTicketRequest(wallet, amount),
    history
  ) as Promise<BlueBikeOrderResponse>;
}

export type BlueBikeActivateResponse =
  | ({
      error: false;
    } & RawBlueBikeTicket)
  | ApiErrorResponse;

function activateBlueBikeTicketRequest(ticketId: string) {
  return (authorization: string) =>
    fetch(`${CORE_API_BASE}providers/blue-bike/activate/${ticketId}`, {
      method: "PUT",
      headers: new window.Headers({
        "Content-Type": "application/json",
        Authorization: authorization,
      }),
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((json) => ({
        ...json,
        error: !!json.error_code,
      })) as Promise<BlueBikeActivateResponse>;
}

export function activateBlueBikeTicket(ticketId: string, history: History) {
  return callSecuredApiEndpoint(
    activateBlueBikeTicketRequest(ticketId),
    history
  );
}

export type BlueBikeStation = {
  id: number;
  type: number; // enum ?
  name: string;
  latitude: number;
  longitude: number;
  bikes_available: number;
  bikes_in_use: number;
  last_seen: string; // Datetime
};

type BlueBikeStationResponse =
  | ({
      error: false;
    } & BlueBikeStation)
  | ApiErrorResponse;

function getBlueBikeStationRequest(stationId: string) {
  return (authorization: string) =>
    fetch(`${CORE_API_BASE}maps/providers/blue-bike/location/${stationId}`, {
      method: "GET",
      headers: new window.Headers({
        Authorization: authorization,
      }),
    })
      .then((response) => response.json())
      .then((json) => ({
        ...json,
        error: !!json.error_code,
      })) as Promise<BlueBikeStationResponse>;
}

export function getBlueBikeStation(stationId: string, history: History) {
  return callSecuredApiEndpoint(getBlueBikeStationRequest(stationId), history);
}
