import { useBooleanState, usePreviousValue } from "common/hooks";
import { useRefundConfirmWalletModal } from "common/hooks/useRefundConfirmWalletModal";
import { NoBudgetModal } from "common/modal";
import { ProviderName } from "common/travelPasses";
import { ProviderActionsDialog } from "pages/providers/dialogs/ProviderActionsDialog/ProviderActionsDialog";
import { MouseEvent, ReactElement, ReactNode, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ProviderPrimaryAction, ProviderType } from "state/actions";
import {
  ApplicationVariant,
  pushLocation,
  Routes,
  TravelPassProvider,
  TravelPassType,
  WalletType,
} from "utils";
import { applicationVariant } from "utils/configure";
import { useWalletChooser } from "../useWalletChooser";
import "./ProviderTile.scss";

export interface PublicProviderTileProps {
  provider: TravelPassProvider;
  minPrice?: number;
  color: string;
  type: ProviderType;
  types: TravelPassType[];
  children?: ReactNode;
}

export interface ProviderTileProps {
  to?: string;
  kind: string;
  onClick?(): void;
  beforeBuy?(wallet: WalletType): boolean;
  children?: ReactNode;
}

export function ProviderTile({
  provider,
  color,
  kind,
  type,
  children,
  to,
  onClick,
  beforeBuy,
}: PublicProviderTileProps & ProviderTileProps): ReactElement {
  const history = useHistory();

  const [isBudgetModalVisible, showBudgetModal, hideBudgetModal] =
    useBooleanState();

  const { startDate, endDate, language } = useSelector((state) => state.user);

  const [actionDialogVisible, showActionDialog, hideActionDialog] =
    useBooleanState();

  const budgetNotStartedYet = startDate ? Date.now() < startDate : false;

  const primaryActionType =
    type === "order"
      ? ProviderPrimaryAction.Order
      : ProviderPrimaryAction.Expense;

  // used for tutorial...
  const showAction = useSelector((store) =>
    store.tutorial.name === "providers"
      ? (store.tutorial.step ?? 0) > 3
        ? 2
        : 1
      : 0
  );

  const providersTutorialActive = useSelector(
    (store) => store.tutorial.name === "providers"
  );

  const prevProvidersTutorialActive = usePreviousValue(providersTutorialActive);

  useEffect(() => {
    if (!providersTutorialActive && prevProvidersTutorialActive) {
      hideActionDialog();
    }
  }, [providersTutorialActive, prevProvidersTutorialActive, hideActionDialog]);

  useEffect(() => {
    if (showAction && provider === TravelPassProvider.delijn) {
      if (showAction === 2) {
        showActionDialog();
      } else {
        hideActionDialog();
      }
    }
  }, [hideActionDialog, provider, showAction, showActionDialog]);

  const tileClicked = (e: MouseEvent) => {
    e.preventDefault();

    if (onClick) {
      onClick();
    } else if (budgetNotStartedYet) {
      showBudgetModal();
    } else {
      showActionDialog();
    }
  };

  const walletChooser = useWalletChooser((wallet) => {
    if ((!beforeBuy || beforeBuy(wallet)) && to) {
      pushLocation(history, to, { wallet });
    }
  });

  const onBuy = () => {
    if (applicationVariant === ApplicationVariant.MOVEASY) {
      hideActionDialog();

      walletChooser.show();
      return;
    }

    if (beforeBuy && !beforeBuy(WalletType.BUSINESS)) {
      hideActionDialog();
    } else if (to) {
      pushLocation(history, to);
    }
  };

  const confirmModal = useRefundConfirmWalletModal(provider);

  const handleRefundAction = () => {
    hideActionDialog();

    if (applicationVariant === ApplicationVariant.MOVEASY) {
      confirmModal.show();
    } else {
      pushLocation(history, Routes.ExpenseAdd.replace(":provider", provider));
    }
  };

  const [renderDialog, setRenderDialog, resetRenderDialog] =
    useBooleanState(false);

  useEffect(() => {
    let tid: number | undefined;

    if (actionDialogVisible) {
      setRenderDialog();
    } else {
      tid = window.setTimeout(() => {
        resetRenderDialog();
      }, 200); // animation delay
    }

    return () => {
      if (tid !== undefined) {
        window.clearTimeout(tid);
      }
    };
  }, [actionDialogVisible, resetRenderDialog, setRenderDialog]);

  return (
    <>
      <div
        className={`mobea__provider-tile mobea__provider-tile__${provider}`}
        onClick={tileClicked}
      >
        <hr className="mobea__provider-tile__stripe" style={{ color }}></hr>
        <div className="mobea__provider-tile__logo">{children}</div>
        <div className="mobea__provider-tile__text">
          <h4 className="mobea__provider-tile__text__name">
            <ProviderName provider={provider} />
          </h4>
          <p className="mobea__provider-tile__text__description">{kind}</p>
        </div>
      </div>

      {startDate && endDate && isBudgetModalVisible && (
        <NoBudgetModal
          messageType={primaryActionType}
          start={new Date(startDate)}
          end={new Date(endDate)}
          locale={language}
          onClose={hideBudgetModal}
        />
      )}

      {renderDialog && (
        <ProviderActionsDialog
          visible={actionDialogVisible}
          provider={provider}
          color={color}
          primaryActionType={primaryActionType}
          onClose={hideActionDialog}
          onBuy={onBuy}
          onRefund={handleRefundAction}
        />
      )}
    </>
  );
}
