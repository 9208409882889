import { useTranslation } from "react-i18next";
import {
  RoutePlanCategory,
  RoutePlanGroup,
  RoutePlanTrip,
} from "services/mapService";
import { AppColors } from "utils/colors";
import { TripOption } from "./TripOption";

interface TripOptionsProps {
  bestTrip: RoutePlanTrip | undefined;
  groups: RoutePlanGroup[];
  onTripSelect: (trip: RoutePlanTrip) => void;
}

export function TripOptions({
  bestTrip,
  groups,
  onTripSelect,
}: TripOptionsProps) {
  const { t } = useTranslation();

  const toTripOption = (trip: RoutePlanTrip) => (
    <TripOption key={trip.id} {...trip} onClick={() => onTripSelect(trip)} />
  );

  const groupsWithBest: RoutePlanGroup[] = [
    {
      category: "best" as RoutePlanCategory,
      trips: bestTrip ? [bestTrip] : [],
    },
  ].concat(groups);

  return (
    <div className="route-planner-trip-options">
      {groupsWithBest.map(({ category, trips }) => {
        if (trips.length === 0) {
          return null;
        }

        return (
          <div key={category} className="route-planner-trip-category">
            <h4
              css={{
                margin: "16px 0 0",
                padding: 0,
                fontSize: 12,
                lineHeight: "16px",
                textTransform: "uppercase",
                color: AppColors.GRAY_300,
              }}
            >
              {t(`map.trip_category.${category}`)}
            </h4>
            {trips.map(toTripOption)}
          </div>
        );
      })}
    </div>
  );
}
