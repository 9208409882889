import { History } from "history";
import {
  LocationSearchPlace,
  LocationSearchPlaceWithId,
} from "services/mapService";
import { WalletType } from "utils";

export type LocationChangeState = {
  source?: LocationSearchPlace | LocationSearchPlaceWithId | null;
  destination?: LocationSearchPlace | LocationSearchPlaceWithId | null;
  wallet?: WalletType;
  from: string;
};

export function pushLocation(
  history: History,
  route: string,
  extraState?: Partial<Omit<LocationChangeState, "from">>
) {
  if (process.env.MOBEA_ENV === "development") {
    console.debug("Redirect", route, {
      from: history.location.pathname,
      ...extraState,
    });
  }
  history.push(route, { from: history.location.pathname, ...extraState });
}
