export function BlueBikeImage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 160 104"
    >
      <path
        d="M136.6 42.2l.4-1.5a32.6 32.6 0 00-21.2 1.3l-9-30 4.2-3.4c.3-.3 2.1-2 2.2-4 0-1-.3-1.8-1-2.4-2-2.1-5-1.2-5.7-1l-8 1.7-4.6 1 .6 2.6 12.9-2.8c.2 0 2-.4 3 .4l.1.4c0 .6-.7 1.5-1.2 2l-5.6 4.6 4.7 15.6-22.5 37.7c-6 4.6-12.6 3.8-15.3 3.2L59.1 26c2.7-3.4 10.7-4 10.7-4S70 20 68.1 19c-1.8-1-4.3-.6-8.3-.2-3.2.3-6-.8-7.6-.9-1.6 0-3.8.3-5 2-.7 1.1-.4 2.4-.1 3.2.3.7 1 2.6 5.4 3.6 1.9.4 3.2.4 4.1.4l2.7 9.8-3.8 4.5-13.4-6.8c.3 0 .6-.3.8-.7l2.5-8.5c.1-.3 0-.9-.3-1.2l-7.4-7.9a2 2 0 00-1.2-.6L3 13.7c-.3 0-.6.3-.5.6L5.2 32c0 .4.4.7.8.7l11.3.6a4 4 0 00-3 1.7c-.8 1.2-.8 2.8 0 4.4l1.8 4A33.2 33.2 0 000 63.3l1.5.3a31.5 31.5 0 0029.9 40c13.2 0 24.5-8.3 29-20l11 1.8c.8 0 1.7 0 2.6-.2l2.2 6.2-2 .3c-.5 0-.7.4-.7.8l.2 1.2c0 .4.4.7.8.6l6.8-1c.4 0 .7-.4.6-.8l-.2-1.2c0-.4-.4-.6-.8-.6l-2.4.4-2.4-6.7a8.3 8.3 0 004.4-7 9.2 9.2 0 004.6-3.2c2.6-3.4 21.4-32.2 25.9-39l2.3 7.9a32.9 32.9 0 00-17 35.8l1.5-.3a31.2 31.2 0 0061.6-6.3c0-14.4-9.6-26.6-22.8-30.2zM31.4 99.7a27.3 27.3 0 01-13-51.4l8.9 18.5a6.3 6.3 0 002 11.8L56.4 83a27.3 27.3 0 01-25.1 16.7zm0-54.7c1.2 0 2.3 0 3.5.2.2 3.8 1.1 9.4 3.8 16.3l-4.1 5-4-.3H30L21 47A27 27 0 0131.3 45zm6.5 21.7l3-3.5c3 1 9.8 3 17.2 3.5l.2 1.3-20.4-1.3zM60.2 40l8 28.6-4.4-.3c-1-8-4.8-15-10.4-20.3l6.8-8zm-43.5-1.8c-.3-.8-.4-1.4-.1-1.8.2-.3.7-.5 1.4-.5h.2l23.2 1.3 12.3 6.2-2.3 2.9a32.4 32.4 0 00-32.8-4.2l-1.9-4zm62.5 34.5a8.3 8.3 0 00-2-2.1c1.8-.2 3.8-.7 5.8-1.5l-.1.2s-1.7 2.2-3.7 3.4zm49 27A27.3 27.3 0 01115 48.4l2.8 9v.1c3 9.4 9.4 15.5 9.6 15.7l1.9-2s-6.1-5.8-9-14.5l-2.8-9.5a27.3 27.3 0 1110.9 52.5z"
        fill="#00A4E4"
      />
      <path
        d="M116.5 35h1c.3 0 .5-.3.5-.6v-3.8c0-.4-.2-.6-.5-.6h-1c-.3 0-.5.2-.5.6v3.8c0 .3.2.6.5.6"
        fill="#2292C4"
      />
    </svg>
  );
}
