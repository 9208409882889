import { ReactElement } from "react";
import { DefaultIconProps } from "../props";

export function HailIcon({
  fill = "currentColor",
  className = "",
  height = "20",
  width = "20",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="3 3 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.15 6.784a1.44 1.44 0 0 0 1.44-1.433 1.44 1.44 0 0 0-2.88 0 1.44 1.44 0 0 0 1.44 1.433ZM4.47 19.325 6.486 9.221l-1.296.501v2.437H3.75V8.79l3.744-1.577c.108 0 .198-.018.288-.036.09-.018.18-.036.288-.036.504 0 1.512.105 1.8.535l1.8-2.631 1.08.751-2.16 3.383-1.245.542-.195.645-.432 2.15 1.512 1.433v5.375H8.79v-4.3l-1.512-1.433-1.296 5.733H4.47Zm13.743-1.778H12v-5.48h6.88l-1-3.08h-4.986L13.94 6.59h2.273v1.37h1.667c.44 0 .813.287.947.691l1.386 4.102v5.478c0 .377-.3.685-.666.685h-.667a.678.678 0 0 1-.667-.685v-.684Zm-.333-2.054c-.553 0-1-.459-1-1.027 0-.569.447-1.027 1-1.027.553 0 1 .458 1 1.027 0 .568-.447 1.027-1 1.027Z"
        fill={fill}
      />
    </svg>
  );
}
