import { LocationCoords } from "services/mapService";

// eslint-disable-next-line no-undef
export type LocationWatchCallback = (
  error: GeolocationPositionError | undefined,
  coords?: LocationCoords
) => void;

/**
 * Singleton class handling location updates watching in application
 * with lazy initialization of watch to show permission dialog on demand
 * and reduce system load
 */
class Location {
  private lastKnownLocation: LocationCoords | null = null;
  // eslint-disable-next-line no-undef
  private lastKnownLocationError: GeolocationPositionError | null = null;

  private locationCallbacks = new Map<string, LocationWatchCallback>();

  private watchId: number | undefined;

  private runCallbacks = (coords: LocationCoords) => {
    this.lastKnownLocation = coords;
    console.debug("Location update", coords);

    for (const cb of this.locationCallbacks.values()) {
      cb(undefined, { ...coords });
    }
  };

  // eslint-disable-next-line no-undef
  private runErrorCallbacks = (e: GeolocationPositionError) => {
    console.warn(e);

    this.lastKnownLocationError = e;

    this.locationCallbacks.forEach((cb) => {
      cb(e);
    });
  };

  private addWatcher = () => {
    if (this.watchId !== undefined) {
      console.warn("There is already registered location watcher");
    }

    this.watchId = window.navigator.geolocation.watchPosition(
      (e) =>
        this.runCallbacks({
          lat: e.coords.latitude,
          lng: e.coords.longitude,
        }),
      this.runErrorCallbacks
    );
  };

  private clearWatcher = () => {
    if (this.watchId) {
      window.navigator.geolocation.clearWatch(this.watchId);
      this.watchId = undefined;
    }
  };

  private generateId = () => {
    let id = Math.random().toString();

    while (this.locationCallbacks.has(id)) {
      id = Math.random().toString();
    }
    return id;
  };

  requestWatch(cb: LocationWatchCallback) {
    const id = this.generateId();

    this.locationCallbacks.set(id, cb);

    if (!this.watchId) {
      if (window.navigator.geolocation) {
        this.addWatcher();
      } else {
        return null;
      }
    } else {
      // run immediately with the recent data if existing
      this.lastKnownLocation && cb(undefined, this.lastKnownLocation);
      this.lastKnownLocationError && cb(this.lastKnownLocationError);
    }

    return id;
  }

  clearWatch(id: string) {
    this.locationCallbacks.delete(id);

    if (this.locationCallbacks.size === 0) {
      this.clearWatcher();
      console.debug("Cleared location watch");
    }
  }
}

export default new Location();
