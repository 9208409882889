import { TravelPassNameDate } from "common/travelPasses/components/TravelPassNameDate";
import { TravelType } from "common/travelPasses/components/TravelType";
import { ReactElement } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { JourneyType, NmbsTravelPass, TravelPass } from "state/actions";
import {
  formatCurrency,
  pushLocation,
  Routes,
  TravelPassProvider,
  TravelPassType,
} from "utils";
import "./HistoryPurchase.scss";

export interface HistoryPurchaseProps {
  locale: string;
  pass: TravelPass;
}

export function HistoryPurchase({
  locale,
  pass,
}: HistoryPurchaseProps): ReactElement {
  const { id, types, provider, price, activation, expiration } = pass;

  const history = useHistory();

  const allProviders = useSelector((state) => state.passes.providers);

  const color = allProviders.find(
    (travelProvider) => travelProvider.provider === provider
  )?.color;

  const getTotalPrice = () => {
    switch (provider) {
      case TravelPassProvider.nmbs: {
        const { returnTravelPass } = pass as NmbsTravelPass;

        return returnTravelPass ? price + returnTravelPass.price : price;
      }
      default:
        return price;
    }
  };

  const formattedPrice = formatCurrency(getTotalPrice(), locale);
  const type = types ? types[0] : TravelPassType.Unknown;

  const getExpiration = () => {
    switch (provider) {
      case TravelPassProvider.nmbs: {
        const { returnTravelPass, travelEndDate } = pass as NmbsTravelPass;

        if (returnTravelPass) {
          return new Date(
            (returnTravelPass as NmbsTravelPass).travelDate
          ).getTime();
        } else {
          return (
            (travelEndDate && new Date(travelEndDate).getTime()) || expiration
          );
        }
      }
      case TravelPassProvider.verts:
        return activation;
      default:
        return expiration;
    }
  };

  const goToDetail = () => {
    let detailRouteBase = "";

    switch (provider) {
      case TravelPassProvider.delijn:
        detailRouteBase = Routes.DeLijnTicketCostDetail;

        break;
      case TravelPassProvider.nmbs: {
        const { returnTravelPass } = pass as NmbsTravelPass;

        if (returnTravelPass) {
          const ids = [id, returnTravelPass.id];

          if (
            expiration < Date.now() &&
            returnTravelPass.expiration > Date.now()
          ) {
            // show active as first
            ids.reverse();
          }

          pushLocation(
            history,
            `${Routes.NmbsMultiTicketDetail}?ids=${ids.join(",")}&back=goBack`
          );

          return;
        } else {
          detailRouteBase = Routes.NmbsTicketCostDetail;
        }
        break;
      }
      case TravelPassProvider.mobit:
        detailRouteBase = Routes.MobitTicketCostDetail;

        break;
      case TravelPassProvider.verts:
        detailRouteBase = `${Routes.VertsTicketDetail}?back=goBack`;

        break;
      case TravelPassProvider["velo-antwerpen"]:
        detailRouteBase = `${Routes.VeloTicketDetail}?back=goBack`;
        break;

      case TravelPassProvider["blue-bike"]:
        detailRouteBase = `${Routes.BlueBikeTicketDetail}?back=goBack`;
        break;
      default:
        console.warn("Missing provider detail path for", provider);
        return;
    }

    pushLocation(
      history,
      detailRouteBase.replace(":id", encodeURIComponent(id))
    );
  };

  const isReturnJourney = () => {
    switch (provider) {
      case TravelPassProvider.nmbs: {
        const { journeyType, returnTravelPass } = pass as NmbsTravelPass;

        return journeyType === JourneyType.RETURN || !!returnTravelPass;
      }
      default:
        return false;
    }
  };

  return (
    <li className="mobea-history__costs__spending" onClick={goToDetail}>
      <TravelType transportType={type} color={color} />
      <TravelPassNameDate
        provider={provider}
        locale={locale}
        activation={activation}
        expiration={getExpiration()}
        isReturnJourney={isReturnJourney()}
      />
      <p className="mobea-history__costs__spending__price">
        -&nbsp;{formattedPrice}
      </p>
    </li>
  );
}
