import { WalletsStatus } from "state/reducers/stateTypes";

export const setCustomerIdAction = (customerId: string) => ({
  type: "SET_CUSTOMER_ID" as const,
  customerId,
});

// Do not allow to change lang directly - has to be done through i18n changeLanguage method
const setLanguageAction = (language: any) => ({
  type: "SET_LANGUAGE" as const,
  language,
});

export interface Customer {
  language: string;
  phonePrefix: string;
  phoneNumber: string;
  name: string;
  surname: string;
  email: string;
  iban: string;
  tester: boolean;
  dateOfBirth: string;
  startDate: number | null;
  endDate: number | null;
  planId: string | null;
  passwordSet: boolean;
}

export const setCustomerAction = (
  customer: Omit<Customer, "startDate" | "endDate">
) => ({
  type: "SET_CUSTOMER_BATCH" as const,
  ...customer,
});

export const setCustomerProfileDataAction = (
  profile: Partial<
    Pick<Customer, "name" | "surname" | "email" | "iban" | "dateOfBirth">
  >
) => ({
  type: "UPDATE_CUSTOMER_PROFILE" as const,
  ...profile,
});

export interface Budget {
  amount: number;
  totalAmount: number;
  startDate: number;
  endDate: number;
}

export interface PrivateBudget {
  amount: number;
  startDate: number | null;
}

export const setBudgetAction = (plan: Budget) => ({
  type: "SET_BUDGET" as const,
  plan,
});

export const setPrivateBudgetAction = (payload: PrivateBudget) => ({
  type: "SET_PRIVATE_BUDGET" as const,
  payload,
});

export interface PlanDuration {
  startDate: number;
  endDate: number;
}

export const setNoPlanAction = () => ({
  type: "NO_PLAN" as const,
});

export const closeNoBudgetWarningAction = () => ({
  type: "CLOSE_NO_BUDGET_WARNING" as const,
});

export const closeOutOfBudgetPeriodWarning = () => ({
  type: "CLOSE_OUT_OF_PERIOD_WARNING" as const,
});

export const passwordSet = () => ({
  type: "PASSWORD_SET" as const,
});

export const setWalletWarningsAction = (payload: WalletsStatus) => ({
  type: "SET_WALLETS_STATUS" as const,
  payload,
});

export type UserActions =
  | ReturnType<typeof setCustomerIdAction>
  | ReturnType<typeof setLanguageAction>
  | ReturnType<typeof setCustomerAction>
  | ReturnType<typeof setCustomerProfileDataAction>
  | ReturnType<typeof setBudgetAction>
  | ReturnType<typeof setPrivateBudgetAction>
  | ReturnType<typeof setNoPlanAction>
  | ReturnType<typeof closeNoBudgetWarningAction>
  | ReturnType<typeof closeOutOfBudgetPeriodWarning>
  | ReturnType<typeof passwordSet>
  | ReturnType<typeof setWalletWarningsAction>;
