import { ADMIN_API_BASE } from "utils";
import { Auth } from "./authTokenStore";
import { fetchTimeoutIncident } from "./fetchTimeout";
import { RequestPhoneVerificationResponse } from "./verificationService";

export function sendPasswordResetLink(
  prefix: string,
  number: string,
  clientId: number
) {
  return fetchTimeoutIncident<RequestPhoneVerificationResponse>(
    `${ADMIN_API_BASE}send-password-reset-link`,
    {
      method: "POST",
      headers: new window.Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        phone_prefix: prefix,
        phone_number: number,
        client: clientId,
      }),
    }
  );
}

export const resetPassword = Auth.resetPassword;

export function checkTokenValidity(token: string) {
  return fetchTimeoutIncident<RequestPhoneVerificationResponse>(
    `${ADMIN_API_BASE}check-token/${token}`,
    {
      method: "GET",
    }
  );
}
