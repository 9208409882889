import "@here/maps-api-for-javascript/bin/mapsjs-ui.css";
import "@here/maps-api-for-javascript/bin/mapsjs.bundle";
import { useReverseGeocode, useRouting } from "maps";
import { BikeMapPage } from "maps/bike/BikeMapPage";
import { BikeStationInfo } from "maps/bike/BikeStationInfo";
import { useVeloStation } from "pages/velo/hooks/useVeloStation";
import { ReactElement, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { LocationCoords, ProviderData } from "services/mapService";
import { pushLocation, Routes, TravelPassProvider } from "utils";
import { AppColors } from "utils/colors";
import { VeloTutorial } from "../detail/VeloTutorial";
import "./VeloMap.scss";

const SEARCH_RADIUS_LIST = [1000];

const H = window.H;

export function VeloMap(): ReactElement {
  const history = useHistory();

  const { id = "" } = useParams<{ id: string }>();

  const platformRef = useMemo(
    () => ({
      current: new H.service.Platform({
        apikey: process.env.HERE_API_KEY,
      }),
    }),
    []
  );

  const [selectedStation, setSelectedStation] = useState<ProviderData | null>(
    null
  );

  const [searchLocation, setSearchLocation] = useState<LocationCoords | null>(
    null
  );

  const [station, loadingStation, stationError] = useVeloStation(
    selectedStation?.data?.stationId || null
  );

  const [setRoutingMapGroup, distance, loadingDistance] = useRouting({
    platformRef,
    origin: searchLocation,
    destination: selectedStation,
    color: AppColors.VELO,
  });

  const [stationAddress, loadingStationAddress] = useReverseGeocode(
    platformRef,
    selectedStation
  );

  const goToTicket = () =>
    pushLocation(history, Routes.VeloTicketDetail.replace(":id", id));

  return (
    <BikeMapPage
      provider={TravelPassProvider["velo-antwerpen"]}
      searchLocation={searchLocation}
      selectedStation={selectedStation}
      searchRadiusList={SEARCH_RADIUS_LIST}
      stationError={stationError}
      tutorial={<VeloTutorial />}
      setRoutingMapGroup={setRoutingMapGroup}
      goToTicket={goToTicket}
      onSearchLocationChange={setSearchLocation}
      onSelectedStationChange={setSelectedStation}
    >
      <BikeStationInfo
        station={station}
        color={AppColors.VELO}
        loading={loadingStation}
        address={stationAddress?.label ?? null}
        loadingAddress={loadingStationAddress}
        distance={distance}
        loadingDistance={loadingDistance}
      />
    </BikeMapPage>
  );
}
