import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

export function RouteIcon({
  fill = "white",
  stroke = "currentColor",
  className = "",
  height = 71,
  width = 12,
}: DefaultIconProps & { height?: number }): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={`-2 0 12 ${height}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle fill={stroke} cx="4" cy="4" r="4" />
      <circle
        stroke={stroke}
        fill={fill}
        strokeWidth="2"
        cx="4"
        cy={height - 6}
        r="5"
      />
      <path
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3,4"
        d={`M4 12.1v${height - 26}.8`}
      />
    </svg>
  );
}
