import { History } from "history";
import { Expense } from "state/actions";
import {
  ADMIN_API_BASE,
  ApiErrorResponse,
  ApiErrors,
  padWithZero,
  TravelPassProvider,
} from "utils";
import { callSecuredApiEndpoint } from "./apiCall";
import { fetchTimeoutIncident } from "./fetchTimeout";

export interface RawExpense {
  provider: TravelPassProvider;
  amount: string;
  accepted_amount: string;
  id: number;
  created: string; // ISO Date time string
  expense_date: string; // ISO Date string
  iban: string;
  status: "PENDING" | "ACCEPTED" | "DECLINED";
  decline_reason?: string;
  last_updated: string; // ISO Date string
}

export type ExpenseHistoryResponse =
  | {
      error: false;
      expenses: RawExpense[];
    }
  | ApiErrorResponse;

const getExpensesHistoryRequest = (authorization: string) =>
  fetchTimeoutIncident<ExpenseHistoryResponse>(
    `${ADMIN_API_BASE}expenses`,
    {
      method: "GET",
      headers: new window.Headers({
        Authorization: authorization,
      }),
    },
    (json) => {
      if (json.error_code) {
        return {
          error: true,
          ...json,
        };
      }

      return {
        error: false,
        expenses: json,
      };
    }
  );

export function getExpensesHistory(history: History) {
  return callSecuredApiEndpoint(getExpensesHistoryRequest, history);
}

export type IssueExpenseResponse =
  | {
      error: false;
      success: boolean;
    }
  | ApiErrorResponse;

const createExpenseRequest =
  (expensePayload: Expense) => (authorization: string) => {
    const date = new Date(expensePayload.expensedDate);

    const expenseDate = `${date.getFullYear()}-${padWithZero(
      date.getMonth() + 1
    )}-${padWithZero(date.getDate())}`;

    const formData = new window.FormData();

    formData.append("amount", expensePayload.price.toString());

    formData.append("expense_date", expenseDate);

    formData.append("provider", expensePayload.provider);

    formData.append("iban", expensePayload.iban);

    const file1 = expensePayload.uploads[0];

    file1 && file1.file && formData.append("file1", file1.file, file1.name);

    const file2 = expensePayload.uploads[1];

    file2 && file2.file && formData.append("file2", file2.file, file2.name);

    return fetch(`${ADMIN_API_BASE}expenses`, {
      method: "POST",
      headers: new window.Headers({
        Authorization: authorization,
      }),
      body: formData,
    }).then((response) => {
      if (response.status === 413) {
        return {
          error: true,
          error_code: ApiErrors.I_TOO_LARGE_PAYLOAD,
          error_type: "too_large",
          error_description: "Attachment too large",
        } as ApiErrorResponse;
      } else {
        return response.json();
      }
    });
  };

export type CreateExpenseResponse =
  | {
      error: false;
      id: number;
    }
  | ApiErrorResponse;

export function createExpense(expensePayload: Expense, history: History) {
  return callSecuredApiEndpoint(
    createExpenseRequest(expensePayload),
    history
  ).then((json) => ({
    ...json,
    error: !!json.error_code,
  })) as Promise<CreateExpenseResponse>;
}

export type RawExpenseDetail = RawExpense & {
  attachments: {
    name: string;
    original: string; // URL
    preview: string; // URL
  }[];
};

export type ExpenseDetailResponse =
  | ({ error: false } & RawExpenseDetail)
  | ApiErrorResponse;

const getExpenseDetailRequest = (id: string) => (authorization: string) =>
  fetchTimeoutIncident<any>(`${ADMIN_API_BASE}expenses/${id}`, {
    method: "GET",
    headers: new window.Headers({
      Authorization: authorization,
    }),
  });

export function getExpenseDetail(id: string, history: History) {
  return callSecuredApiEndpoint(getExpenseDetailRequest(id), history).then(
    (json) => ({
      error: !!json.error_code,
      ...json,
    })
  ) as Promise<ExpenseDetailResponse>;
}
