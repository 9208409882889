import { css } from "@emotion/react";
import { useViewportHeight } from "common/hooks";
import { RefObject } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppColors } from "utils/colors";
import { PageHeader, PageHeaderPublicProps } from "./header/PageHeader";

export const SearchLocationFieldContainerStyles = css`
  .search-location-field-container {
    position: relative;
    border: 1px solid var(--color-gray-100);
    border-radius: 2px;
    background: white;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12);
    margin: 32px 24px;

    .mobea__input-group {
      float: none;
      position: relative;
      border-bottom: none;
      padding: 4px 8px;
      height: auto;
    }
  }
`;

export type MapViewPageProps = {
  pageClassName?: string;
  className?: string;
  mapRef: RefObject<HTMLDivElement>;
  children?: React.ReactNode;
  noHeader?: boolean;
  mapActionsOffset?: number;
  mapActionsOffsetKeyboard?: number;
  onNavBack?: () => void;
} & PageHeaderPublicProps;
export function MapViewPage({
  pageClassName = "",
  className = "",
  noHeader = false,
  children,
  mapRef,
  mapActionsOffset = 120,
  mapActionsOffsetKeyboard = -160,
  onNavBack,
  ...headerProps
}: MapViewPageProps) {
  const history = useHistory();

  // component uses viewport height as its minHeight (if not specified otherwise)
  // e.g. overcome issue with keyboard making the component visible height smaller on Android
  const height = useViewportHeight();

  const keyboardOpened = useSelector((state) => state.appUi.keyboardOpened);

  const onBack = (e: React.MouseEvent) => {
    e.preventDefault();

    onNavBack ? onNavBack() : history.goBack();
  };

  return (
    <main
      className={`${className} ${pageClassName}`}
      css={{
        overflow: "hidden",
        width: "100%",
        minHeight: !height ? "100%" : height || 0,
        position: "absolute",
        top: 0,
        left: 0,
        display: "flex",
        flexDirection: "column",
        ".map-actions, .mobea__map-actions": {
          position: "absolute",
          right: keyboardOpened ? -160 : 16,
          bottom: mapActionsOffset + 60,
          transition: "right 0.3s, bottom 0.3s",
          ".map-action, .mobea__map-action": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 56,
            width: 56,
            borderRadius: 28,
            backgroundColor: "#fff",
            boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
            cursor: "pointer",
            svg: {
              width: 24,
              height: 24,
              fill: AppColors.GRAY_400,
            },
            "&:not(:first-of-type)": {
              marginTop: 16,
            },
          },
        },
      }}
    >
      {!noHeader && <PageHeader {...headerProps} onBack={onBack} />}
      <section
        className={`${pageClassName}__content`}
        css={{
          flex: "1 1 auto",
          width: "100%",
          position: "relative",
        }}
      >
        <div
          ref={mapRef}
          className="map-container"
          css={{
            width: "100%",
            top: 0,
            left: 0,
            bottom: 0,
            position: "absolute",
            ".H_imprint": {
              bottom: `${
                keyboardOpened ? mapActionsOffsetKeyboard : mapActionsOffset
              }px !important`,
            },
            ".H_logo": {
              transform: `translate(-10px, 0)`,
              opacity: 0.6,
              transition: "transform 0.3s",
              transitionDelay: "0s",
            },
            ".H_copyright": {
              display: "flex",
              flexDirection: "column-reverse",
              alignItems: "center",
              transform: `translate(-10px, -20px)`,
              fontSize: "0.625rem",
              opacity: 0.7,
              padding: "2px 0 !important",
              transition: "transform 0.3s",
              transitionDelay: "0s",
              a: {
                marginTop: "4px !important",
              },
            },
          }}
        />
        {children}
      </section>
    </main>
  );
}
