import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

export function StudyIcon({
  fill = "currentColor",
  className = "",
  height = "20",
  width = "20",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 2.5l-9.2 5 3.4 1.8v5l5.8 3.2 5.8-3.2v-5l1.7-.9v5.8h1.7V7.5l-9.2-5zm5.7 5L10 10.6 4.3 7.5 10 4.4l5.7 3.1zm-1.5 5.8L10 15.6l-4.2-2.3v-3l4.2 2.2 4.2-2.3v3.1z"
        fill={fill}
      />
    </svg>
  );
}
