import { Action } from "state";
import { ProviderToTypeMappingType } from "utils/constants";
import { getInitialStateWithPersistance, sortByDate } from "utils/helpers";
import { TravelPassState } from "./stateTypes";

const initialState: TravelPassState = {
  active: [],
  providers: [],
  providersToTypes: {} as ProviderToTypeMappingType,
  providersTypes: [],
  mapProvidersTypes: [],
  providersUpdateTimestamp: 0,
  providersLoading: false,
  purchases: [],
  expenses: [],
};

export function TravelPassReducer(
  state: TravelPassState = getInitialStateWithPersistance(
    "passes",
    initialState
  ),
  action: Action
): TravelPassState {
  switch (action.type) {
    case "SET_ACTIVE_TRAVEL_PASSES":
      return {
        ...state,
        active: action.passes.sort(sortByDate("orderTimestamp")),
      };

    case "SET_PROVIDERS":
      return {
        ...state,
        providers: action.providers,
        providersUpdateTimestamp: Date.now(),
      };

    case "SET_PROVIDERS_TYPES":
      return {
        ...state,
        providersToTypes: action.types,
        // deduplicate values
        providersTypes: Array.from(
          new Set(Object.values(action.types).flat(1))
        ),
        mapProvidersTypes: Array.from(new Set(action.mapTypes)),
      };

    case "ADD_ACTIVE_TRAVEL_PASS":
      return {
        ...state,
        active: state.active
          .concat(action.pass)
          .sort(sortByDate("orderTimestamp")),
      };

    case "UPDATE_ACTIVE_TRAVEL_PASS":
      return {
        ...state,
        active: state.active.map((pass) =>
          pass.id === action.pass.id ? action.pass : pass
        ),
        // update as well pass in history
        purchases: state.purchases.map((pass) =>
          pass.id === action.pass.id ? action.pass : pass
        ),
      };

    case "ADD_PURCHASE":
      return {
        ...state,
        purchases: state.purchases
          .concat(action.pass)
          .sort(sortByDate("orderTimestamp")),
      };

    case "SET_PURCHASES":
      return {
        ...state,
        purchases: action.passes.sort(sortByDate("orderTimestamp")),
      };

    case "SET_EXPENSES":
      return {
        ...state,
        expenses: action.expenses.sort(sortByDate("createdDate")),
      };

    case "ADD_EXPENSE":
      return {
        ...state,
        expenses: state.expenses
          .concat(action.expense)
          .sort(sortByDate("expensedDate")),
      };

    default:
      return state;
  }
}
