import { TravelPassType } from "utils";
import { ProviderFilterType } from "./ProviderFilterType";
import { ProvidersFilterOptions } from "./ProvidersFilterOptions";

export function toTravelPassTypes(
  filter: ProviderFilterType
): TravelPassType[] {
  return (
    ProvidersFilterOptions.find((option) => option.type == filter)?.types || []
  );
}

export function toProviderFilterName(
  filter: ProviderFilterType
): string | undefined {
  return ProvidersFilterOptions.find((option) => option.type == filter)?.name;
}

export function findProviderFilterIndex(type: TravelPassType) {
  return ProvidersFilterOptions.findIndex((option) =>
    option.types.includes(type)
  );
}

export function findProviderFilterType(type: TravelPassType) {
  return ProvidersFilterOptions.find((option) => option.types.includes(type))
    ?.type;
}
