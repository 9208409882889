import { DefaultIconProps } from "icons/props";
import { ReactElement } from "react";

export interface HorizontalClassicFlagIconPros extends DefaultIconProps {
  firstColor: string;
  secondColor: string;
  thirdColor: string;
}

export function VerticalClassicFlagIcon({
  firstColor,
  secondColor,
  thirdColor,
  className = "country-phone-prefix__icon",
  width = "20",
  height = "20",
}: HorizontalClassicFlagIconPros): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 10c0 4.3 2.714 7.965 6.522 9.378V.622A10.003 10.003 0 000 10z"
        fill={firstColor}
      />
      <path
        d="M13.478.622A9.979 9.979 0 0010 0C8.777 0 7.605.22 6.522.622L5.652 10l.87 9.378A9.978 9.978 0 0010 20c1.223 0 2.395-.22 3.478-.622l.87-9.378-.87-9.378z"
        fill={secondColor}
      />
      <path
        d="M20 10c0-4.3-2.714-7.965-6.522-9.378v18.756A10.004 10.004 0 0020 10z"
        fill={thirdColor}
      />
    </svg>
  );
}
