import { useBooleanState, usePendingOrder } from "common/hooks";
import { useSelectedWallet } from "common/hooks/useSelectedWallet";
import { MobeaModal } from "common/modal";
import { SimpleOrderPage } from "common/page/order/SimpleOrderPage";
import { CircleCheckIcon } from "icons/CircleCheckIcon";
import { VeloAntwerpenLogo } from "icons/logos/VeloAntwerpsLogo";
import { ProviderTermsAndConditions } from "pages/conditions/ProviderTermsAndConditions";
import { VeloOrderError } from "pages/velo/VeloOrderError";
import { ReactElement, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  formatCurrency,
  pushLocation,
  Routes,
  SupportedLanguage,
  TravelPassProvider,
  WalletType,
} from "utils";
import { AppColors } from "utils/colors";
import { useVeloData } from "./hooks/useVeloData";
import { useVeloOrder } from "./hooks/useVeloOrder";

export function VeloOrderPage(): ReactElement {
  const history = useHistory();

  const { t } = useTranslation();

  const locale = useSelector((state) => state.user.language);

  const { minPasses, maxPasses, price, duration, validity, loaded } =
    useSelector((state) => state.ordering.veloData);

  const [orderPending] = usePendingOrder(TravelPassProvider["velo-antwerpen"]);

  const [amount, setAmount] = useState(minPasses);

  const [order, ordering, orderedPasses, errorCode] = useVeloOrder(
    amount * price
  );

  const [errorDialogVisible, showErrorDialog, hideErrorDialog] =
    useBooleanState();

  const formattedPrice = formatCurrency(price * amount, locale);

  useVeloData();

  useEffect(() => {
    if (errorCode) {
      showErrorDialog();
    }
  }, [errorCode, showErrorDialog]);

  // update initial value if fetch order data differs
  useEffect(() => {
    loaded && setAmount(minPasses);
  }, [loaded, minPasses]);

  const showTicketDetail = (id: string) => {
    pushLocation(
      history,
      Routes.VeloTicketDetail.replace(":id", encodeURIComponent(id)) +
        "?back=goHome&showTutorial=true"
    );
  };

  const showMultiTicketDetail = () => {
    pushLocation(
      history,
      `${Routes.VeloMultiTicketDetail}?ids=${orderedPasses.map(
        (pass) => pass.id
      )}&showTutorial=true`
    );
  };

  const closeErrorAndResetAmount = () => {
    setAmount(minPasses);
    hideErrorDialog();
  };

  const wallet = useSelectedWallet(WalletType.BUSINESS);

  const handleOrder = () => {
    order(wallet, amount);
  };

  const termsLinks: Record<SupportedLanguage, string> = {
    en: "https://www.velo-antwerpen.be/en/general-conditions",
    fr: "https://www.velo-antwerpen.be/fr/conditions-generales",
    nl: "https://www.velo-antwerpen.be/nl/algemene-voorwaarden",
  };

  return (
    <>
      <SimpleOrderPage
        provider={TravelPassProvider["velo-antwerpen"]}
        title={t("velo_order.order_velo_pass")}
        logo={<VeloAntwerpenLogo width="100%" />}
        ready={loaded}
        ticketName={t("velo_order.day_pass")}
        ticketDescription={
          <Trans i18nKey="velo_order.order_velo_pass_text">
            <span className="mobea__arial">{{ validity }}</span>{" "}
            <span className="mobea__arial">{{ duration }}</span>
          </Trans>
        }
        formattedPrice={formattedPrice}
        orderInProgress={orderPending || ordering}
        orderButtonLabel={
          orderPending
            ? t("shared.order_in_progress")
            : t("velo_order.order_day_pass")
        }
        minPasses={minPasses}
        maxPasses={maxPasses}
        amount={amount}
        onOrder={handleOrder}
        onAmountChange={setAmount}
      >
        {orderedPasses.length > 0 && (
          <MobeaModal
            confirmText={t("shared.super")}
            title={
              orderedPasses.length > 1
                ? t("velo_order.day_passes_bought")
                : t("velo_order.day_pass_bought")
            }
            image={<CircleCheckIcon fill={AppColors.PRIMARY} />}
            onConfirm={
              orderedPasses.length > 1
                ? showMultiTicketDetail
                : () => showTicketDetail(orderedPasses[0].id)
            }
          >
            <p>
              <Trans
                i18nKey={
                  orderedPasses.length > 1
                    ? "velo_order.day_passes_bought_text"
                    : "velo_order.day_pass_bought_text"
                }
                values={{ validity }}
              >
                <span className="mobea__arial">{{ duration }}</span>
              </Trans>
            </p>
          </MobeaModal>
        )}

        {errorDialogVisible && errorCode && (
          <VeloOrderError
            amount={amount}
            errorCode={errorCode}
            closeError={closeErrorAndResetAmount}
          />
        )}
      </SimpleOrderPage>

      <ProviderTermsAndConditions
        className="velo-order-terms-conditions"
        title={t("velo_order.order_velo_pass")}
        provider={TravelPassProvider["velo-antwerpen"]}
        description={t("velo_order.terms_description")}
        termsExternalLink={termsLinks[locale]}
      />
    </>
  );
}
