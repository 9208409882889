import { DefaultIconProps } from "icons/props";
import { ReactElement } from "react";

export function LimeLogo({
  className = "",
  width,
  height,
}: Pick<DefaultIconProps, "className" | "width" | "height">): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 107 39"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M64.746 18.923H61.41v9.836h3.336v-9.836zM52.712 20.542c0-.165.007-9.184.015-10.81H49.39v10.863a8.212 8.212 0 002.406 5.773c.165.172.345.337.533.487 1.522 1.274 3.373 1.92 5.503 1.92h1.919v-3.33h-2.144a4.913 4.913 0 01-4.896-4.903zM87.71 14.17c-1.139-1.192-2.586-1.672-4.16-1.672-1.62 0-3.066.525-4.176 1.672-.825.847-1.305 1.934-1.305 1.934s-.48-1.087-1.304-1.934c-1.117-1.155-2.557-1.672-4.176-1.672-1.575 0-3.014.48-4.161 1.672-1.23 1.29-1.844 3.141-1.844 5.24 0 3.622 0 8.202-.008 9.32h3.314c.007-1.275.045-6.988.045-9.32 0-2.886 1.574-3.913 3.126-3.913.345 0 .69.06 1.02.172.667.195 1.394.848 1.717 1.372.307.503.51 1.087.51 2.272v9.417h3.508v-9.417c0-1.185.203-1.77.51-2.272.323-.524 1.05-1.177 1.717-1.372.33-.112.675-.172 1.02-.172 1.552 0 3.126 1.027 3.126 3.913 0 2.325.037 8.045.045 9.32h3.314c0-1.118-.008-5.69-.008-9.32.015-2.099-.592-3.95-1.83-5.24zM107 20.483c0-2.19-.817-3.922-2.241-5.458-1.44-1.56-3.606-2.527-5.848-2.527-2.377 0-4.424.915-6.058 2.639-1.47 1.544-2.054 3.389-2.054 5.66 0 2.28.9 4.424 2.519 5.878 1.702 1.522 3.464 2.31 6.013 2.31 2.174 0 4.446-.84 5.967-1.95l-2.159-2.16c-1.064.743-2.451 1.208-3.726 1.208-1.837 0-3.913-1.17-4.76-2.721l12.31-2.167c.015-.21.037-.503.037-.712zm-12.7-.24c.038-2.43 1.395-4.709 4.64-4.709 2.865 0 4.214 1.905 4.522 3.42L94.3 20.564v-.322zM63.082 16.944c-2.002 0-2.504-1.155-2.504-2.235 0-1.08.592-2.234 2.504-2.234s2.504 1.155 2.504 2.234c0 1.08-.502 2.235-2.504 2.235z"
        fill="#0D0"
      />
      <mask
        id="a"
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="35"
        height="35"
      >
        <path
          d="M19.35 2.197c9.462 0 17.162 7.692 17.162 17.153 0 9.462-7.7 17.161-17.161 17.161-9.462 0-17.154-7.7-17.154-17.16 0-9.462 7.692-17.154 17.154-17.154z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#a)" fill="#0D0">
        <path d="M16.848 19.35a2.504 2.504 0 115.009 0 2.504 2.504 0 01-5.01 0zM23.594 15.64l6.005-3.472a2.097 2.097 0 00.45-3.284 14.914 14.914 0 00-6.98-4.033c-1.334-.338-2.631.652-2.631 2.032v6.942c.007 1.612 1.754 2.624 3.156 1.814zM18.257 13.825V6.89c0-1.372-1.297-2.37-2.631-2.032a14.987 14.987 0 00-6.98 4.034c-.96.974-.735 2.594.45 3.284l6.005 3.47c1.41.803 3.156-.21 3.156-1.821zM24.688 21.172l6.013 3.471a2.098 2.098 0 003.066-1.244 14.982 14.982 0 000-8.082c-.367-1.32-1.882-1.927-3.066-1.245l-6.013 3.471c-1.402.803-1.402 2.82 0 3.63zM15.115 23.061l-6.006 3.471a2.097 2.097 0 00-.45 3.284 14.913 14.913 0 006.98 4.034c1.335.337 2.632-.653 2.632-2.032v-6.942c-.015-1.612-1.762-2.617-3.156-1.815zM14.02 17.536l-6.012-3.471a2.098 2.098 0 00-3.066 1.244 14.983 14.983 0 000 8.082c.367 1.32 1.881 1.927 3.066 1.245l6.013-3.471c1.394-.803 1.394-2.82 0-3.629zM20.447 24.883v6.943c0 1.372 1.297 2.369 2.632 2.031a14.987 14.987 0 006.98-4.033c.96-.975.734-2.594-.45-3.284l-6.005-3.471c-1.41-.81-3.157.195-3.157 1.814z" />
      </g>
      <path
        d="M19.35 38.7C8.667 38.7 0 30.042 0 19.35 0 8.667 8.667 0 19.35 0 30.041 0 38.708 8.667 38.7 19.35c0 10.684-8.659 19.35-19.35 19.35zm17.162-19.35c0-9.461-7.7-17.153-17.16-17.153-9.462 0-17.155 7.692-17.155 17.153 0 9.462 7.693 17.161 17.154 17.161 9.462 0 17.161-7.7 17.161-17.16z"
        fill="#0D0"
      />
    </svg>
  );
}
