export enum Routes {
  "Default" = "/",
  "RequestPhoneVerification" = "/request-phone-verification",
  "ConfirmPhoneVerification" = "/confirm-phone-verification",
  "Welcome" = "/welcome",
  "TermsAndConditions" = "/terms-and-conditions",
  "Tutorial" = "/tutorial",
  "Register" = "/register",
  "Home" = "/home",
  "Map" = "/map",
  "History" = "/history",
  "HistoryExpensesDetail" = "/history/expenses/detail/:id",
  "Settings" = "/settings",
  "Profile" = "/settings/profile",
  "Cookies" = "/settings/cookies",
  "Tutorials" = "/settings/tutorials",
  "RefundBilling" = "/settings/refund-billing",
  "MyWallets" = "/settings/my-wallets",
  "AddMoney" = "/settings/add-money",
  "GetTravelPass" = "/get-travel-pass",
  "ExpenseAddEmpty" = "/expense/add",
  "ExpenseAdd" = "/expense/add/:provider?",
  "ExpenseDetail" = "/expense/detail/:id",
  "DeLijnOrder" = "/de-lijn/order",
  "DeLijnTicketDetail" = "/de-lijn/detail/:id",
  "DeLijnTicketCostDetail" = "/de-lijn/cost/detail/:id",
  "NmbsOrder" = "/nmbs/order",
  "NmbsTicketDetail" = "/nmbs/detail/:id",
  "NmbsMultiTicketDetail" = "/nmbs/multi-detail",
  "NmbsTicketCostDetail" = "/nmbs/cost/detail/:id",
  "MobitOrder" = "/mobit/order",
  "MobitTicketDetail" = "/mobit/detail/:id",
  "MobitTicketCostDetail" = "/mobit/cost/detail/:id",
  "MobitStartRide" = "/mobit/ride/start/:id",
  "MobitManageRide" = "/mobit/ride/manage/:id/:bikeCode/:rideId",
  "VertsOrder" = "/verts/order",
  "VertsTicketDetail" = "/verts/detail/:id",
  "VertsMap" = "/verts/map/:id",
  "VeloOrder" = "/velo/order",
  "VeloTicketDetail" = "/velo/detail/:id",
  "VeloMultiTicketDetail" = "/velo/multi-detail",
  "VeloMap" = "/velo/map/:id",
  "BlueBikeOrder" = "/blue-bike/order",
  "BlueBikeTicketDetail" = "/blue-bike/detail/:id",
  "BlueBikeMultiTicketDetail" = "/blue-bike/multi-detail",
  "BlueBikeMap" = "/blue-bike/map/:id",
  "Reload" = "/reload", // used to show loader while reloading page
  "ChangePassword" = "/change-password",
  "InvalidLoginToken" = "/invalid-login-token",
  "SetPassword" = "/set-password",
  "SetPasswordSuccess" = "/set-password-success",
  "ForgottenPassword" = "/forgotten-password",
  "ResetPassword" = "/reset-password/:token",
  "PreLogin" = "/hello",
  "Login" = "/login",
  "Redirect" = "/redirect", // special Mollie redirect url. Not to be used in the app
  "TopUpDetail" = "/top-up-detail/:id",
  "SetPasswordLinkSend" = "/set-password-link-send",
}

// list of the routes, that are public so should not redirect
// to authentication if there is invalid token - user is just receiving new one
export const PUBLIC_AUTH_ROUTES: Routes[] = [
  // Moveasy case
  Routes.SetPassword,
  Routes.ResetPassword.replace(":token", "") as Routes.ResetPassword,
  Routes.PreLogin,
  Routes.Login,
  // EA case
  Routes.RequestPhoneVerification,
  Routes.ConfirmPhoneVerification,
];
