import { ProviderLogo } from "common/travelPasses";
import { CommonTileProps } from "pages/providers/tiles";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { getTranslationNoWarning, Routes } from "utils";
import { ProviderTile, PublicProviderTileProps } from "./ProviderTile";

export function ExpenseTile(
  props: PublicProviderTileProps & CommonTileProps
): ReactElement {
  const { t } = useTranslation();

  const getProviderTypeTranslated = () => {
    const translation = getTranslationNoWarning(`data.${props.types}`);

    if (translation) {
      return translation;
    }

    const transports = props.types
      .map((type) => t(`data.${type}`, { defaultValue: null }))
      .filter((value) => !!value)
      .map((value, index) => (index === 0 ? value : value.toLowerCase()))
      .join(" / ");

    if (transports.length === 0) {
      console.warn("Unknown transport types without translation", props.types);
    }

    return transports;
  };

  const link = Routes.ExpenseAdd.replace(":provider", props.provider);

  return (
    <ProviderTile {...props} kind={getProviderTypeTranslated()} to={link}>
      <ProviderLogo provider={props.provider} />
    </ProviderTile>
  );
}
