import * as Sentry from "@sentry/react";
import { getConfig } from "utils/configure";

export function initLogging() {
  if (process.env.MOBEA_ENV === "development") {
    return;
  }

  const { sentryLink, slug = "EA" } = getConfig();

  if (sentryLink) {
    console.debug("Initializing Sentry logging...");

    Sentry.setTag("client", slug);

    Sentry.init({
      dsn: sentryLink,
      tracesSampleRate: 1.0,
      environment: process.env.MOBEA_ENV,
    });
  }
}

export function setLoggingUser(id: string) {
  return Sentry.setUser({ id });
}
