import { History } from "history";
import {
  ADMIN_API_BASE,
  ApiErrorResponse,
  ApiErrors,
  TermsOrPrivacy,
  TravelPassProvider,
} from "utils";
import { getConfig } from "utils/configure";
import { callSecuredApiEndpoint } from "./apiCall";
import { fetchTimeoutIncident } from "./fetchTimeout";

export type RawCustomer = {
  id: string;
  name: string;
  surname: string;
  email: string;
  date_of_birth?: string; // YYYY-MM-DD
  phone_number?: string;
  phone_prefix?: string;
  iban?: string | null;
  reference_number: number;
  language?: string;
  is_tester: boolean;
  latest_plan_end?: string; // YYYY-MM-DD
  plan_id?: string;
  anonymized: boolean;
  first_login?: boolean;
  tac: Record<TravelPassProvider | TermsOrPrivacy, string> | null; // Date time
};

export type CustomerResponse =
  | ({
      error: false;
    } & RawCustomer)
  | ApiErrorResponse;

function getCustomerRequest(id: string) {
  return (authorization: string) =>
    fetchTimeoutIncident<CustomerResponse>(`${ADMIN_API_BASE}customers/${id}`, {
      method: "GET",
      headers: new window.Headers({
        Authorization: authorization,
      }),
    }).catch(
      () =>
        ({
          error: true,
          error_code: ApiErrors.I_NO_CONTENT,
          error_type: "no_content",
          error_description: "No content",
        } as ApiErrorResponse)
    );
}

// pass null value if no customer id is received (user not logged in) so we receive correct 401 status and error
export function getCustomer(id: string, history: History) {
  return callSecuredApiEndpoint(getCustomerRequest(id || "null"), history);
}

export type RawPlanData = {
  customer: string;
  allocated_amount: number;
  remaining_amount: number;
  start_date: string;
  end_date: string;
};

export type RawPrivatePlanData = {
  customer: string;
  remaining_amount: number;
  start_date: string;
};

export type PlanApiResponse =
  | {
      error: false;
      current_plan: RawPlanData;
    }
  | ApiErrorResponse;

export type PrivatePlanApiResponse =
  | {
      error: false;
      current_plan: RawPrivatePlanData | Record<string, never>;
    }
  | ApiErrorResponse;

function getPlanRequest(id: string) {
  return (authorization: string) =>
    fetchTimeoutIncident<PlanApiResponse>(`${ADMIN_API_BASE}plan/${id}`, {
      method: "GET",
      headers: new window.Headers({
        Authorization: authorization,
      }),
    });
}

function getPrivatePlanRequest() {
  return (authorization: string) =>
    fetchTimeoutIncident<PrivatePlanApiResponse>(
      `${ADMIN_API_BASE}private-plan`,
      {
        method: "GET",
        headers: new window.Headers({
          Authorization: authorization,
        }),
      }
    );
}

export function getPlan(id: string, history: History) {
  return callSecuredApiEndpoint(getPlanRequest(id), history);
}

export function getPrivatePlan(history: History) {
  return callSecuredApiEndpoint(getPrivatePlanRequest(), history);
}

export type CustomerUpdatePayload = {
  email?: string;
  date_of_birth?: string;
  iban?: string;
  language?: string;
};

function updateCustomerRequest(id: string, updates: CustomerUpdatePayload) {
  return (authorization: string) =>
    fetchTimeoutIncident<CustomerResponse>(`${ADMIN_API_BASE}customers/${id}`, {
      method: "PATCH",
      headers: new window.Headers({
        Authorization: authorization,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        ...updates,
        client: getConfig().clientId,
      }),
    });
}

export function updateCustomer(
  id: string,
  updates: CustomerUpdatePayload,
  history: History
) {
  return callSecuredApiEndpoint(updateCustomerRequest(id, updates), history);
}

export function setCustomerPassword(
  id: string,
  history: History,
  password: string,
  old_password?: string
) {
  return callSecuredApiEndpoint(
    (authorization: string) =>
      fetchTimeoutIncident<any>(`${ADMIN_API_BASE}customers/${id}`, {
        method: "PATCH",
        headers: new window.Headers({
          Authorization: authorization,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          password,
          old_password,
        }),
      }),
    history
  );
}

export function logIn(
  phone_prefix: string,
  phone_number: string,
  password: string
) {
  return fetchTimeoutIncident<any>(`${ADMIN_API_BASE}login`, {
    method: "POST",
    headers: new window.Headers({
      "Content-Type": "application/json",
    }),
    body: JSON.stringify({
      phone_prefix,
      phone_number,
      password,
      client: getConfig().clientId,
    }),
  });
}

export function register(
  phone_prefix: string,
  phone_number: string,
  password: string,
  password_confirm: string,
  date_of_birth: string
) {
  return fetchTimeoutIncident<any>(`${ADMIN_API_BASE}register`, {
    method: "POST",
    headers: new window.Headers({
      "Content-Type": "application/json",
    }),
    body: JSON.stringify({
      phone_prefix,
      phone_number,
      password,
      password_confirm,
      date_of_birth,
      client: getConfig().clientId,
    }),
  });
}

export type TermsOrPrivacyAcceptedResponse =
  | {
      error: false;
      accepted: boolean;
    }
  | ApiErrorResponse;

export function setTermsOrPrivacyAcceptedRequest(item: TermsOrPrivacy) {
  return (authorization: string) =>
    fetchTimeoutIncident<TermsOrPrivacyAcceptedResponse>(
      `${ADMIN_API_BASE}${item}/terms`,
      {
        method: "POST",
        headers: new window.Headers({
          Authorization: authorization,
        }),
        body: JSON.stringify({
          accepted: true,
        }),
      }
    );
}

export function setTermsOrPrivacyAccepted(
  item: TermsOrPrivacy,
  history: History
) {
  return callSecuredApiEndpoint(
    setTermsOrPrivacyAcceptedRequest(item),
    history
  );
}
