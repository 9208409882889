import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

type Props = DefaultIconProps & {
  outlined?: boolean;
};

export function LabelIcon({
  className = "",
  fill = "currentColor",
  height = "20",
  width = "20",
  outlined = false,
}: Props): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      {outlined ? (
        <>
          <path
            d="M17.5 9.32L10 1.82a1.66 1.66 0 00-1.17-.49H3c-.92 0-1.67.75-1.67 1.67v5.83c0 .46.19.88.5 1.19l7.5 7.5a1.66 1.66 0 002.35-.01l5.83-5.83c.3-.3.49-.72.49-1.18 0-.46-.2-.88-.5-1.18zm-7 7.02L3 8.84V3h5.83l7.5 7.5z"
            fill={fill}
          />
          <circle cx="5.08" cy="5.08" r="1.25" fill={fill} />
        </>
      ) : (
        <path
          d="M17.842 9.65l-7.5-7.5c-.3-.3-.717-.483-1.175-.483H3.333c-.916 0-1.666.75-1.666 1.666v5.834c0 .458.183.875.491 1.183l7.5 7.5c.3.3.717.483 1.175.483a1.63 1.63 0 001.175-.491l5.834-5.834c.308-.3.491-.716.491-1.175 0-.458-.191-.883-.491-1.183zM4.583 5.833c-.691 0-1.25-.558-1.25-1.25 0-.691.559-1.25 1.25-1.25.692 0 1.25.559 1.25 1.25 0 .692-.558 1.25-1.25 1.25z"
          fill={fill}
        />
      )}
    </svg>
  );
}
