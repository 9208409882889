import styled from "@emotion/styled";
import "@here/maps-api-for-javascript/bin/mapsjs.bundle";
import { InputGroup } from "common/forms";
import { useBooleanState } from "common/hooks";
import { CustomMobeaModal } from "common/modal";
import { Spinner } from "common/Spinner";
import { CloseIcon } from "icons/CloseIcon";
import { LocationCurrentIcon } from "icons/LocationCurrentIcon";
import { NavBackIcon } from "icons/NavBackIcon";
import { LocationCursorIcon } from "icons/navigation";
import { PreviousIcon } from "icons/PreviousIcon";
import { LocationError, MyLocationAddress, splitToHighlights } from "maps";
import { LocationAccessDeniedDialog } from "maps/LocationAccessDeniedDialog";
import { ReactElement, ReactNode, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  LocationCoords,
  LocationSearchPlaceWithId,
  LocationSearchSuggestion,
} from "services/mapService";
import { AppColors, AppFonts } from "utils/colors";

export const SuggestionStyled = styled.div({
  display: "flex",
  alignItems: "center",
  paddingTop: 12,
  paddingBottom: 12,
  "> svg": {
    color: AppColors.GRAY_100,
    width: 40,
    flex: "0 0 auto",
  },
  "> span": {
    marginLeft: 10,
    marginTop: 2,
    color: AppColors.GRAY_500,
    fontFamily: AppFonts.SECONDARY,
    fontSize: "0.9375rem",
    lineHeight: 1.4667,
    textAlign: "left",
  },
});

type Props = {
  className?: string;
  label: string;
  searchText: string;
  userLocation?: LocationCoords | null;
  myLocationAddress?: MyLocationAddress | null;
  locationError?: LocationError;
  allowMyLocation?: boolean;
  previousLocation?: LocationSearchPlaceWithId | null;
  suggestions?: LocationSearchSuggestion[];
  loadingSuggestions?: boolean;
  children?: ReactNode;
  onSelect: (place: LocationSearchPlaceWithId) => void;
  onSearchTextChange: (searchText: string) => void;
  onClose(): void;
};

export function LocationPickerDialog({
  className,
  label,
  searchText,
  userLocation,
  myLocationAddress,
  locationError,
  allowMyLocation = true,
  previousLocation,
  loadingSuggestions = false,
  suggestions = [],
  children,
  onClose,
  onSelect,
  onSearchTextChange,
}: Props): ReactElement {
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);

  const [
    locationErrorDialogVisible,
    showLocationErrorDialog,
    hideLocationErrorDialog,
  ] = useBooleanState();

  const close = () => {
    onClose();
  };

  const onClear = () => {
    onSearchTextChange("");

    inputRef.current?.focus();
  };

  const useMyLocation = () => {
    if (myLocationAddress && userLocation) {
      onSelect({
        id: myLocationAddress.id,
        label: myLocationAddress.label,
        ...userLocation,
      });
    } else if (locationError === LocationError.PermissionDenied) {
      showLocationErrorDialog();
    }
  };

  const usePreviousLocation = () => {
    if (previousLocation) {
      onSelect(previousLocation);
    }
  };

  const toLocationSuggestion = (suggestion: LocationSearchSuggestion) => (
    <SuggestionStyled
      key={suggestion.locationId}
      onClick={() => onSelect({ ...suggestion, id: suggestion.locationId })}
    >
      <LocationCursorIcon fill="currentColor" width={26} height={26} />
      <span>
        {splitToHighlights(suggestion.label, searchText, suggestion.highlights)}
      </span>
    </SuggestionStyled>
  );

  return (
    <>
      <CustomMobeaModal
        withHeader={false}
        className={className}
        usePortal
        onCancel={close}
        customStyle={{
          ".mobea__modal__dialog": {
            bottom: "auto",
            padding: 24,
          },
        }}
      >
        <InputGroup
          label={label}
          name="location"
          placeholder={label}
          toggleLabelAndPlaceholder
          value={searchText}
          className="mobea-location-picker__location__input"
          inputAttributes={{
            autoComplete: "off",
            autoFocus: true,
            ref: inputRef,
          }}
          onChange={(value) => onSearchTextChange(value.toString())}
          css={{
            display: "flex",
            float: "none",
            position: "relative",
            width: "100%",
            "> input": {
              paddingLeft: 32,
              paddingRight: 24,
            },
            "> label": {
              left: 32,
            },
          }}
        >
          <NavBackIcon
            height={20}
            width={20}
            onClick={close}
            css={{
              position: "absolute",
              left: 0,
              top: 24,
              color: AppColors.PRIMARY,
            }}
          />

          {searchText && (
            <CloseIcon
              fill={AppColors.PRIMARY}
              width={16}
              height={16}
              onClick={onClear}
              css={{
                position: "absolute",
                top: 22,
                right: 4,
                color: AppColors.PRIMARY,
              }}
            />
          )}
        </InputGroup>

        <div
          css={{
            marginTop: 4,
          }}
        >
          {allowMyLocation && (
            <SuggestionStyled
              onClick={useMyLocation}
              css={{
                position: "relative",
                "&:after": {
                  content: "''",
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: "100%",
                  height: 1,
                  backgroundColor: AppColors.BACKGROUND,
                },
              }}
            >
              <LocationCurrentIcon height={22} width={22} />
              <span>{t("shared.use_my_location")}</span>
            </SuggestionStyled>
          )}

          {previousLocation && (
            <SuggestionStyled onClick={usePreviousLocation}>
              <PreviousIcon height={22} width={22} />
              <span>{previousLocation.label}</span>
            </SuggestionStyled>
          )}

          {loadingSuggestions && (
            <div
              css={{
                paddingTop: 16,
                paddingBottom: 16,
                display: "flex",
                alignItems: "center",
                "> .mobea__spinner": {
                  position: "relative",
                  margin: 0,
                },
              }}
            >
              <Spinner size={16} />
              <span
                css={{
                  marginLeft: 16,
                  color: AppColors.GRAY_500,
                  fontFamily: AppFonts.SECONDARY,
                  fontSize: "1rem",
                  lineHeight: 1.5,
                }}
              >
                {t("shared.loading")}
              </span>
            </div>
          )}

          {!loadingSuggestions &&
            suggestions.length > 0 &&
            suggestions.map(toLocationSuggestion)}
          {children}
        </div>
      </CustomMobeaModal>
      {locationErrorDialogVisible && (
        <LocationAccessDeniedDialog hide={hideLocationErrorDialog} />
      )}
    </>
  );
}
