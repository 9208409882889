import { TFunction } from "i18next";
import { NavBackIcon } from "icons/NavBackIcon";
import { ReactElement } from "react";
import { padWithZero } from "utils";
import { MobitInfoItem } from "../MobitInfoItem";
import "./MobitTicketStartEnd.scss";

export interface MobitTicketStartEndProps {
  validityStart?: number;
  validityEnd?: number;
  t: TFunction;
}

export function MobitTicketStartEnd({
  validityStart = 0,
  validityEnd = 0,
  t,
}: MobitTicketStartEndProps): ReactElement {
  const formatDateTime = (date: Date) =>
    `${padWithZero(date.getHours())}:${padWithZero(
      date.getMinutes()
    )} ${padWithZero(date.getDate())}/${padWithZero(
      date.getMonth() + 1
    )}/${date.getFullYear()}`;

  const startFormatted = formatDateTime(new Date(validityStart));

  const endFormatted = formatDateTime(new Date(validityEnd));

  return (
    <section className="mobea__mobit-ticket__start-end">
      <MobitInfoItem
        className="from"
        label={`${t("time.from")}`}
        value={startFormatted}
      />

      <div className="mobea__mobit-ticket__start-end__separator">
        <NavBackIcon />
      </div>

      <MobitInfoItem
        className="to"
        label={`${t("time.to")}`}
        value={endFormatted}
      />
    </section>
  );
}
