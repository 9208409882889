import { BudgetState, useBudgetState } from "common/hooks/useBudgetState";
import { useCheckEnoughBudget } from "pages/providers/dialogs/useInsufficientBudgetDialog";
import { useWalletChooser } from "pages/providers/useWalletChooser";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { LocationSearchPlace, ProviderData } from "services/mapService";
import {
  ApplicationVariant,
  MIVB_OUTLET_URL,
  openNativeApp,
  providerToActionsMapping,
  pushLocation,
  Routes,
  TravelPassProvider,
  WalletType,
} from "utils";
import { applicationVariant } from "utils/configure";

export interface PrimaryActionOptions {
  wallet?: WalletType;
  origin?: LocationSearchPlace;
  destination?: LocationSearchPlace;
  nmbsSourceCode?: string;
  nmbsDestinationCode?: string;
  departure?: number;
}

export function useProviderActions(
  provider: ProviderData | null,
  validUntil?: number
) {
  const { t } = useTranslation();

  const history = useHistory();

  const privateAmount = useSelector((state) => state.user.privateAmount);

  const allProviders = useSelector((state) => state.passes.providers);

  const activePasses = useSelector((state) => state.passes.active);

  const travelProvider = allProviders.find(
    (travelProvider) => travelProvider.provider == provider?.type
  );

  const actions =
    travelProvider && providerToActionsMapping[travelProvider.provider];

  const checkDeposit = useCheckEnoughBudget(
    travelProvider?.provider || ("" as TravelPassProvider),
    travelProvider?.minPrice || 0
  );

  const activePass = activePasses.find(
    (pass) =>
      pass.provider === travelProvider?.provider &&
      (!validUntil || pass.expiration > validUntil)
  );

  const [route, setRoute] = useState("");

  const [, businessBudgetState] = useBudgetState();

  const hasBudget =
    (applicationVariant === ApplicationVariant.MOVEASY && privateAmount > 0) ||
    businessBudgetState === BudgetState.Active;

  const getPrimaryActionName = () => {
    if (!travelProvider) {
      return null;
    }

    switch (travelProvider.provider) {
      case TravelPassProvider.nmbs:
        if (travelProvider.type === "order") {
          return t("provider.action.buy_ticket");
        } else {
          return t("provider.action.refund");
        }

      case TravelPassProvider.delijn: {
        if (activePass) {
          return t("provider.action.open_ticket");
        } else {
          return t("provider.action.buy_ticket");
        }
      }
      case TravelPassProvider.verts:
        return t("provider.action.order_taxi");

      case TravelPassProvider.uber:
        return t("provider.action.order_uber");

      case TravelPassProvider.mobit:
        if (travelProvider.type === "order") {
          if (activePass) {
            return t("provider.action.open_pass");
          } else {
            return t("provider.action.order_pass");
          }
        } else {
          return t("provider.action.open_app");
        }

      case TravelPassProvider["velo-antwerpen"]:
        if (activePass) {
          return t("provider.action.open_pass");
        } else if (travelProvider.type === "order") {
          return t("provider.action.buy_pass");
        } else {
          return t("provider.action.open_app");
        }

      case TravelPassProvider.mivb:
        return t("provider.action.find_outlet");

      case TravelPassProvider["blue-bike"]:
        if (activePass) {
          return t("provider.action.open_pass");
        } else if (travelProvider.type === "order") {
          return t("provider.action.buy_pass");
        } else {
          return t("provider.action.open_app");
        }

      default:
        return t("provider.action.open_app");
    }
  };

  const getPrimaryActionEnabled = () => {
    if (!travelProvider) {
      return hasBudget;
    }

    switch (travelProvider.provider) {
      case TravelPassProvider.delijn:
      case TravelPassProvider.mobit:
      case TravelPassProvider["velo-antwerpen"]:
      case TravelPassProvider["blue-bike"]:
        return !!activePass || hasBudget;
      case TravelPassProvider.nmbs:
        return hasBudget;
      // always allow to search for station of MIVB
      case TravelPassProvider.mivb:
        return true;

      default:
        if (applicationVariant === ApplicationVariant.MOVEASY) {
          return true;
        }
        return hasBudget;
    }
  };

  const getSecondaryActionEnabled = () => {
    if (!travelProvider) {
      return hasBudget;
    }

    switch (travelProvider.provider) {
      // secondary has only MIVB and it is default case
      // add here other special cases
      default:
        return true;
    }
  };

  const sourceRef = useRef<LocationSearchPlace | null>(null);
  const destinationRef = useRef<LocationSearchPlace | null>(null);

  const walletChooser = useWalletChooser((wallet) => {
    pushLocation(history, route, {
      wallet,
      source: sourceRef.current,
      destination: destinationRef.current,
    });
  });

  const handleRoute = (route: string) => {
    if (applicationVariant === ApplicationVariant.MOVEASY) {
      setRoute(route);

      walletChooser.show();
    } else {
      pushLocation(history, route, {
        source: sourceRef.current,
        destination: destinationRef.current,
      });
    }
  };

  const onPrimaryAction = (options: PrimaryActionOptions = {}) => {
    if (
      applicationVariant === ApplicationVariant.EA &&
      !checkDeposit(options.wallet ?? WalletType.BUSINESS)
    ) {
      return;
    }

    switch (travelProvider?.provider) {
      case TravelPassProvider.nmbs:
        // handleRoute(
        //   provider
        //     ? `${Routes.NmbsOrder}?origin=${encodeURIComponent(
        //         options.nmbsSourceCode || provider.name
        //       )}&destination=${encodeURIComponent(
        //         (options.nmbsDestinationCode || provider.destination) ?? ""
        //       )}&departure=${options.departure ?? ""}`
        //     : Routes.NmbsOrder
        // );
        pushLocation(
          history,
          Routes.ExpenseAdd.replace(":provider", travelProvider?.provider)
        );
        break;
      case TravelPassProvider.delijn:
        if (activePass) {
          pushLocation(
            history,
            `${Routes.DeLijnTicketCostDetail.replace(":id", activePass.id)}`
          );
        } else {
          handleRoute(Routes.DeLijnOrder);
        }
        break;
      case TravelPassProvider.mobit:
        if (travelProvider?.type === "order") {
          if (activePass) {
            pushLocation(
              history,
              `${Routes.MobitTicketDetail.replace(
                ":id",
                activePass.id
              )}?back=goBack`
            );
          } else {
            handleRoute(Routes.MobitOrder);
          }
        } else if (actions?.app) {
          openNativeApp(actions.app);
        }
        break;
      case TravelPassProvider.verts: {
        if (travelProvider?.type == "order") {
          const { departure, destination, origin } = options;
          // support old behaviour by using provider object
          const originDerived = origin || provider;

          const params: string[] = [];

          originDerived &&
            params.push(`origin=${originDerived.lat},${originDerived.lng}`);

          destination &&
            params.push(`destination=${destination.lat},${destination.lng}`);
          departure && params.push(`departure=${options.departure}`);

          if (params.length > 0) {
            handleRoute(`${Routes.VertsOrder}?${params.join("&")}`);
          } else {
            handleRoute(Routes.VertsOrder);
          }
        } else if (actions?.app) {
          openNativeApp(actions.app);
        }
        break;
      }
      case TravelPassProvider["velo-antwerpen"]:
        if (activePass) {
          pushLocation(
            history,
            Routes.VeloTicketDetail.replace(":id", activePass.id) +
              "?back=goBack"
          );
        } else if (travelProvider?.type === "order") {
          handleRoute(Routes.VeloOrder);
        } else if (actions?.app) {
          openNativeApp(actions.app);
        }
        break;
      case TravelPassProvider["blue-bike"]:
        if (activePass) {
          pushLocation(
            history,
            Routes.BlueBikeTicketDetail.replace(":id", activePass.id) +
              "?back=goBack"
          );
        } else if (travelProvider?.type === "order") {
          handleRoute(Routes.BlueBikeOrder);
        } else if (actions?.app) {
          openNativeApp(actions.app);
        }
        break;
      case TravelPassProvider.mivb:
        window.open(MIVB_OUTLET_URL);
        break;
      default:
        if (actions?.app) {
          openNativeApp(actions.app);
        }
        break;
    }
  };

  const getSecondaryActionName = () => {
    if (!travelProvider) {
      return null;
    }

    switch (travelProvider.provider) {
      case TravelPassProvider.mivb:
        return t("provider.action.open_app");
      default:
        return null;
    }
  };

  const onSecondaryAction = () => {
    switch (travelProvider?.provider) {
      case TravelPassProvider.mivb:
        if (actions?.app) {
          openNativeApp(actions.app);
        }
        break;
      default:
        return;
    }
  };

  return [
    getPrimaryActionName(),
    onPrimaryAction,
    getSecondaryActionName(),
    onSecondaryAction,
    getPrimaryActionEnabled(),
    getSecondaryActionEnabled(),
  ] as const;
}
