import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

export function ToggleDirectionIcon({
  fill = "currentColor",
  className = "",
  height = "24",
  width = "24",
  onClick,
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M10 14V7h3L9 3 5 7h3v7h2zm5 7l4-4h-3v-7h-2v7h-3l4 4z"
        fill={fill}
      />
    </svg>
  );
}
