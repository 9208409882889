import { ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { installNotificationCancelAction } from "state/actions";
import { mobea } from "utils/install";
import { AndroidInstallOverlay, IosInstallOverlay } from ".";

export function InstallReminder(): ReactElement {
  const dispatch = useDispatch();

  const installNotificationCanceled = useSelector(
    (state) => state.onboarding.installNotificationCanceled
  );

  const onCancel = () => dispatch(installNotificationCancelAction());

  /* Do not show after canceling */
  return (
    <>
      {!installNotificationCanceled && mobea.isAndroid && (
        <AndroidInstallOverlay onCancel={onCancel} />
      )}

      {!installNotificationCanceled && mobea.isIos && (
        <IosInstallOverlay onCancel={onCancel} />
      )}
    </>
  );
}
