import { RouteIcon } from "icons/RouteIcon";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { VertsPackage } from "services/vertsService";
import { formatDateWithLocaleAsDefault, formatTimeWithLocale } from "utils";
import "./VertsOrderSummary.scss";

interface VertsOrderSummaryProps {
  selectedPackage: VertsPackage;
  source: string;
  destination: string;
  requestedAt?: Date;
}

export function VertsOrderSummary({
  selectedPackage,
  source,
  destination,
  requestedAt,
}: VertsOrderSummaryProps): ReactElement {
  const { t } = useTranslation();

  const date = requestedAt ?? new Date();

  const { language: locale } = useSelector((state) => state.user);

  const formattedDate = formatDateWithLocaleAsDefault(date, locale);

  const formattedTime = formatTimeWithLocale(date, locale);

  const durationMins = Math.round(selectedPackage.duration / 60);

  const isToday =
    formattedDate === formatDateWithLocaleAsDefault(new Date(), locale);

  const isTomorrow =
    formattedDate ===
    formatDateWithLocaleAsDefault(
      new Date(Date.now() + 24 * 60 * 60 * 1000),
      locale
    );

  return (
    <div className="mobea__verts-order-summary">
      <img
        className="mobea__verts-order-summary__image"
        src="/static/images/car.png"
        alt="car"
      />

      <div className="mobea__verts-order-summary__time">
        <h2 className="mobea__verts-order-summary__time__depart">
          {t("verts_order.leaving")}{" "}
          {/* @TODO implement once we have option to choose later date & time */}
          {isToday ? (
            <>
              {t("history.today")}, {formattedTime}
            </>
          ) : isTomorrow ? (
            <>
              {t("history.tomorrow")}, {formattedTime}
            </>
          ) : (
            <>
              {formattedTime}, {formattedDate}
            </>
          )}
        </h2>

        <span className="mobea__verts-order-summary__time__length">
          {durationMins !== 0
            ? t("verts_order.summary_duration", { duration: durationMins })
            : ""}
        </span>
      </div>

      <div className="mobea__verts-order-summary__route">
        <RouteIcon height={40} />
        <p className="mobea__verts-order-summary__route__address">{source}</p>
        <p className="mobea__verts-order-summary__route__address">
          {destination}
        </p>
      </div>
    </div>
  );
}
