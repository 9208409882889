import { useContactUsModal } from "common/modal";
import { TFunction } from "i18next";
import { HistoryExpenseStatus } from "pages/history/expenses/HistoryExpenseStatus";
import { ReactElement } from "react";
import { Trans } from "react-i18next";
import { ExpenseStatus } from "state/actions";
import {
  decapitalizeString,
  formatCurrency,
  formatDateWithLocale,
} from "utils";

export interface ExpenseDetailStatusProps {
  status: ExpenseStatus;
  locale: string;
  acceptedPrice: number;
  acceptedDate: number;
  refusalReason: string;
  t: TFunction;
}

export function ExpenseDetailStatus({
  status,
  locale,
  acceptedPrice,
  acceptedDate,
  t,
  refusalReason,
}: ExpenseDetailStatusProps): ReactElement {
  const { show: showContactUs } = useContactUsModal();

  const translationKey = status.toLowerCase().replace(" ", "_");

  const formattedAcceptedDate = formatDateWithLocale(
    new Date(acceptedDate),
    locale,
    {
      day: "numeric",
      month: "short",
      year: "numeric",
    }
  );

  const formattedAcceptedPrice = formatCurrency(acceptedPrice || 0, locale);

  return (
    <section className="mobea-expense-detail__status">
      <p className="mobea-expense-detail__status__headline">
        <HistoryExpenseStatus status={status} />
        <span>{t(`expense.${translationKey}`)}</span>
      </p>

      <p className="mobea-expense-detail__status__text">
        {status === "PENDING" && (
          <Trans i18nKey={`expense.${translationKey}_text`}>
            <span className="mobea__arial" />
          </Trans>
        )}

        {status === "PARTIALLY ACCEPTED" && (
          <Trans i18nKey="expense.accepted_partial_text">
            <span className="mobea__arial" />
            {{ formattedAcceptedDate, formattedAcceptedPrice }}
          </Trans>
        )}

        {status === "ACCEPTED" && (
          // t("expense.pending_text")
          // t("expense.accepted_text")
          // t("expense.declined_text")
          // t("expense.invalid_file_type_text")
          // t("expense.supported_files_text")
          <Trans i18nKey={`expense.${translationKey}_text`}>
            <span className="mobea__arial">{{ formattedAcceptedDate }}</span>
          </Trans>
        )}

        {status === "DECLINED" && (
          <>
            <span>{t(`expense.${translationKey}_text`)}</span>&nbsp;
            {refusalReason && (
              <span>
                {t("expense.refusal_reason")}
                {": "}
                {decapitalizeString(
                  // t("expense_refusal_reasons.insufficient")
                  // t("expense_refusal_reasons.within")
                  // t("expense_refusal_reasons.dateamount")
                  // t("expense_refusal_reasons.file")
                  // t("expense_refusal_reasons.notacceptable")
                  // t("expense_refusal_reasons.systemerror")
                  t(`expense_refusal_reasons.${refusalReason}`, {
                    defaultValue: refusalReason,
                  })
                )}
                .
              </span>
            )}
            {!refusalReason && (
              <span>
                {t("expense.more_information")}{" "}
                <span
                  className="mobea-expense-detail__status__text__contact_us"
                  onClick={showContactUs}
                >
                  {t("expense.contact_us")}
                </span>
              </span>
            )}
          </>
        )}
      </p>
    </section>
  );
}
