import { useNetwork } from "common/hooks";
import { useTutorialHighlighter } from "common/tutorial2/Tutorial";
import { Location } from "history";
import { ClockIcon } from "icons/ClockIcon";
import { HomeIcon, MapIcon, SettingsIcon } from "icons/navigation";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Routes } from "utils";
import { NetworkState } from "utils/network";
import { BottomBarBackground } from "./BottomBarBackground";
import "./BottomNavBar.scss";

export interface BottomNavBarProps {
  hidden?: boolean;
}

export function BottomNavBar({
  hidden = false,
}: BottomNavBarProps): ReactElement {
  const { t } = useTranslation();

  const location = useLocation();

  const { amount, startDate, outOfBudgetPeriod, privateAmount } = useSelector(
    (state) => state.user
  );

  const keyboardOpened = useSelector((state) => state.appUi.keyboardOpened);

  const network = useNetwork();

  const budgetNotStartedYet = startDate ? Date.now() < startDate : false;

  const getTravelPassDisabled =
    (privateAmount <= 0 &&
      (amount <= 0 || (outOfBudgetPeriod && !budgetNotStartedYet))) ||
    network !== NetworkState.online;

  const isHistoryActive = (_, location: Location) =>
    location.pathname.startsWith("/history");

  useTutorialHighlighter("main", 1, "nav-home", {
    offsetY: 14,
    fixed: true,
  });

  useTutorialHighlighter("main", 3, "nav-map", {
    offsetY: 14,
    fixed: true,
  });

  useTutorialHighlighter("main", 4, "nav-purchase", {
    offsetY: 16,
    fixed: true,
  });

  useTutorialHighlighter("main", 5, "nav-history", {
    offsetY: 14,
    fixed: true,
  });

  useTutorialHighlighter("main", 6, "nav-settings", {
    offsetY: 14,
    fixed: true,
  });

  return (
    <nav
      className={`mobea__bottom-nav ${
        hidden || keyboardOpened ? "hidden" : ""
      }`}
    >
      <section className="mobea__bottom-nav__content">
        <div id="nav-home" className="mobea__bottom-nav__simple-item">
          <NavLink to={Routes.Home}>
            <HomeIcon />
            <span className="mobea__bottom-nav__simple-item__label">
              {t("menu.home")}
            </span>
          </NavLink>
        </div>
        <div id="nav-map" className="mobea__bottom-nav__simple-item">
          <NavLink to={Routes.Map}>
            <MapIcon />
            <span className="mobea__bottom-nav__simple-item__label">
              {t("menu.map")}
            </span>
          </NavLink>
        </div>
        <div className="mobea__bottom-nav__simple-item mobea__bottom-nav__simple-item__main">
          <div id="nav-purchase" className="mobea__bottom-nav__main-item">
            <Link
              to={Routes.GetTravelPass}
              className={getTravelPassDisabled ? "mobea__disabled" : ""}
            >
              +
            </Link>
          </div>
        </div>

        <div id="nav-history" className="mobea__bottom-nav__simple-item">
          <NavLink to={Routes.History} isActive={isHistoryActive}>
            <ClockIcon outlined={!isHistoryActive(null, location)} />
            <span className="mobea__bottom-nav__simple-item__label">
              {t("menu.history")}
            </span>
          </NavLink>
        </div>
        <div id="nav-settings" className="mobea__bottom-nav__simple-item">
          <NavLink to={Routes.Settings}>
            <SettingsIcon />
            <span className="mobea__bottom-nav__simple-item__label">
              {t("menu.settings")}
            </span>
          </NavLink>
        </div>
      </section>
      <div className="mobea__bottom-nav__bg">
        <BottomBarBackground />
      </div>
    </nav>
  );
}
