import { ReactElement } from "react";
import { DefaultIconProps } from "../props";

export function BusCarIcon({
  fill = "currentColor",
  className = "",
  height = "20",
  width = "20",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 4H5a3 3 0 00-3 3v8a3 3 0 003 3l-1 1v1h1l2-2h2v-5H4V6h9v2h2V7a3 3 0 00-3-3zM5 14c.5 0 1 .4 1 1s-.5 1-1 1a1 1 0 01-1-1c0-.6.5-1 1-1zm15.6-4.3a1 1 0 00-1-.7h-7.2a1 1 0 00-1 .7l-1.4 4v5.6c0 .4.3.7.7.7h.6c.4 0 .7-.4.7-.8V18h8v1.2c0 .4.3.8.7.8h.6c.4 0 .7-.3.7-.7v-5.5l-1.4-4.1zm-8.2.3h7.2l1 3h-9.2l1-3zm-.4 6a1 1 0 01-1-1c0-.6.4-1 1-1s1 .4 1 1-.4 1-1 1zm8 0a1 1 0 01-1-1c0-.6.4-1 1-1s1 .4 1 1-.4 1-1 1z"
        fill={fill}
      />
    </svg>
  );
}
