import QrScanner from "qr-scanner";
import { useCallback, useEffect, useRef, useState } from "react";

QrScanner.WORKER_PATH = "/static/js/qr-scanner-worker.min.js";

export function useQrReader(
  videoId: string,
  handleResult: (result: string) => void,
  autoStart = true
) {
  const qrScanner = useRef<QrScanner | null>(null);

  const [cameraAvailable, setCameraAvailable] = useState(true);

  const [flashAvailable, setFlashAvailable] = useState(true);

  const cameraNotAvailable = () => setCameraAvailable(false);

  const cameraIsAvailable = () => setCameraAvailable(true);

  const toggleLight = () =>
    qrScanner.current
      ?.toggleFlash()
      .then(console.log, () => setFlashAvailable(false));

  const start = useCallback(() => {
    if (qrScanner.current) {
      qrScanner.current.start().then(cameraIsAvailable, cameraNotAvailable);
    } else {
      // fallback for the case stream does not start for some reason not covered by library
      cameraNotAvailable();
    }
  }, []);

  const pause = () => {
    qrScanner.current?.pause();
  };

  useEffect(() => {
    const videoEl = document.getElementById(videoId) as HTMLVideoElement;

    if (videoEl && videoEl.parentElement) {
      const width = videoEl.parentElement.offsetWidth;

      const height = videoEl.parentElement.offsetHeight;

      qrScanner.current = new QrScanner(
        videoEl,
        handleResult,
        () => undefined,
        undefined,
        "environment"
      );

      let scaleFactor = 1;

      // override so it does not mess with our logic
      // @ts-ignore
      qrScanner.current._setVideoMirror = (facingMode: string) => {
        // in user facing mode mirror the video to make it easier for the user to position the QR code
        scaleFactor = facingMode === "user" ? -1 : 1;
      };

      // video is centered using css to cover entire viewport
      videoEl.onplaying = (e) => {
        // zoom out to keep wrapper covered by video - as background-size: cover in css does...
        const targetZoom = Math.max(
          width / videoEl.videoWidth,
          height / videoEl.videoHeight
        );

        const targetZoomString = targetZoom.toFixed(6);

        const targetZoomWithScaleFactorString = (
          targetZoom * scaleFactor
        ).toFixed(6);

        // zoom out and center video
        (
          e.target as HTMLVideoElement
        ).style.transform = `translate(-50%, -50%) scale(${targetZoomWithScaleFactorString}, ${targetZoomString})`;

        // detect if we have flash
        qrScanner.current
          ?.hasFlash()
          .then((result) => {
            setFlashAvailable(result);

            !autoStart && qrScanner.current?.stop();
          })
          .catch((error) => {
            console.warn("Flash error", error);

            setFlashAvailable(false);

            !autoStart && qrScanner.current?.stop();
          });
      };

      start();
    }

    return () => {
      if (qrScanner.current) {
        qrScanner.current.destroy();

        qrScanner.current = null;
      }
    };
  }, [autoStart, handleResult, start, videoId]);

  return [
    qrScanner.current,
    cameraAvailable,
    flashAvailable,
    start,
    pause,
    toggleLight,
  ] as const;
}
