import { ActionButton } from "common/forms";
import { RouteDirectionsIcon } from "icons/RouteDirectionsIcon";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { AppColors } from "utils/colors";

type Props = {
  onClick(): void;
};

export function RouteDirectionsButton({ onClick }: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <ActionButton
      className="route-directions-button"
      type="secondary"
      shape="ellipse"
      onClick={onClick}
      css={{
        display: "flex",
        columnGap: 4,
        height: 42,
        padding: "12px 16px",
        fontSize: 12,
        lineHeight: 1.333,
        backgroundColor: AppColors.PRIMARY,
        color: AppColors.WHITE,
        border: "1px solid currentColor",
        boxShadow: "none",
      }}
    >
      <span>{t("map.route")}</span>
      <RouteDirectionsIcon />
    </ActionButton>
  );
}
