import { cx } from "@emotion/css";
import { MobeaButton } from "common/forms";
import { useBooleanState } from "common/hooks";
import { useSelectedWallet } from "common/hooks/useSelectedWallet";
import { MobeaModal } from "common/modal";
import { ReactElement, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import "./OrderFooter.scss";

export interface OrderFooterProps {
  className?: string;
  amount?: number;
  formattedPrice: string;
  disabled?: boolean;
  pending?: boolean;
  buttonLabel?: string;
  onOrder?(): void;
  confirmText?: ReactNode;
  onAmountChange?(amount: number): void;
  minAmount?: number;
  maxAmount?: number;
  children?: ReactNode;
}

export function OrderFooter({
  className = "",
  amount = 1,
  formattedPrice,
  disabled = false,
  buttonLabel = "",
  pending = false,
  children,
  confirmText,
  onOrder = () => undefined,
  onAmountChange,
  minAmount = 1,
  maxAmount = Number.MAX_VALUE,
}: OrderFooterProps): ReactElement {
  const { t } = useTranslation();

  const selectedWallet = useSelectedWallet();

  const [confirmDialogVisible, showConfirmDialog, hideConfirmDialog] =
    useBooleanState();

  const normalizeAmount = (amount: number) =>
    Math.min(maxAmount, Math.max(minAmount, amount));

  const amountChanged = (event) => {
    if (!onAmountChange) {
      return;
    }

    const updatedAmount = Number.parseInt(event.target.value);

    if (Number.isNaN(updatedAmount)) {
      setTimeout(() => event.target.select(0, event.target.value.length), 100);
    } else {
      onAmountChange(Math.min(maxAmount, Math.max(1, updatedAmount)));
    }
  };

  return (
    <footer
      className={cx(
        "mobea__order__ticket__footer",
        className,
        selectedWallet && "wallets"
      )}
    >
      <div className="mobea__order__ticket__footer__info">
        {onAmountChange && (
          <div className="mobea__order__ticket__footer__info__amount editor-view">
            <span className="mobea__order__ticket__footer__info__label">
              {t("shared.amount")}
            </span>

            <div className="mobea__order__ticket__footer__info__amount__editor">
              <div
                className={`mobea__order__ticket__footer__info__amount__editor__amount-button minus ${
                  amount <= minAmount ? "disabled" : ""
                }`}
                onClick={() => onAmountChange(normalizeAmount(amount - 1))}
              />

              <input
                value={amount}
                inputMode="numeric"
                onChange={amountChanged}
              />

              <div
                className={`mobea__order__ticket__footer__info__amount__editor__amount-button plus ${
                  amount >= maxAmount ? "disabled" : ""
                }`}
                onClick={() => onAmountChange(normalizeAmount(amount + 1))}
              />
            </div>
          </div>
        )}

        {!onAmountChange && (
          <div className="mobea__order__ticket__footer__info__amount">
            <span className="mobea__order__ticket__footer__info__label">
              {t("shared.amount")}:
            </span>
            <span className="mobea__order__ticket__footer__info__value">
              {amount}
            </span>
          </div>
        )}

        {selectedWallet && (
          <div className="mobea__order__ticket__footer__info__wallet">
            <span className="mobea__order__ticket__footer__info__label">
              {t("wallet.wallet")}:
            </span>
            <span className="mobea__order__ticket__footer__info__value">
              {t(`wallet.${selectedWallet}`)}
            </span>
          </div>
        )}

        <div
          className={`mobea__order__ticket__footer__info__price ${
            onAmountChange ? "full" : ""
          }`}
        >
          <span className="mobea__order__ticket__footer__info__label">
            {t("shared.total_price")}
            {onAmountChange ? "" : ":"}
          </span>

          <span className="mobea__order__ticket__footer__info__value">
            {formattedPrice}
          </span>
        </div>
      </div>
      {children && children}
      {!children && (
        <MobeaButton
          onClick={confirmText ? showConfirmDialog : onOrder}
          disabled={disabled}
          loading={pending}
          className="mobea__order__ticket__footer__button"
        >
          {buttonLabel}
        </MobeaButton>
      )}
      {confirmDialogVisible && (
        <MobeaModal
          confirmText={t("shared.buy_now")}
          title={t("shared.confirm_purchase")}
          onConfirm={() => {
            hideConfirmDialog();
            onOrder();
          }}
          onClose={hideConfirmDialog}
        >
          {confirmText}
        </MobeaModal>
      )}
    </footer>
  );
}
