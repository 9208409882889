import styled from "@emotion/styled";
import { getTotalDistance } from "pages/map/functions";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RoutePlanTrip } from "services/mapService";
import { formatDistance, formatTimeWithLocale, splitHoursMinutes } from "utils";
import { AppColors, AppFonts } from "utils/colors";
import { TripSegments } from "./TripSegments";

const StyledTripOptionInfo = styled.div({
  fontFamily: AppFonts.NUMBERS,
  fontWeight: 500,
  fontSize: "0.75rem",
  lineHeight: "20px",
});

const StyledSeparator = styled.div({
  width: 1,
  height: 16,
  backgroundColor: AppColors.GRAY_100,
  position: "absolute",
  top: 2,
  left: 7,
});

type Props = RoutePlanTrip & {
  onClick(): void;
};

export function TripOption({
  depart,
  arrive,
  carbonCost,
  segments,
  onClick,
}: Props): ReactElement {
  const { t } = useTranslation();

  const locale = useSelector((state) => state.user.language);

  const totalDistance = getTotalDistance(segments);

  const [durationHours, durationMinutes] = splitHoursMinutes(
    (arrive - depart) / (60 * 1000)
  );

  const durationArgs = {
    minutes: durationMinutes,
    hours: durationHours,
  };

  return (
    <div
      className="route-planner-trip-option"
      onClick={onClick}
      css={{
        padding: "16px 0 8px",
      }}
    >
      <TripSegments
        className="route-planner-trip-option__segments"
        segments={segments}
        size={24}
        css={{
          marginBottom: 8,
          rowGap: 4,
        }}
      />
      <div
        css={{
          display: "flex",
          justifyItems: "flex-start",
        }}
      >
        <StyledTripOptionInfo>
          {formatTimeWithLocale(depart, locale)}
          {" - "}
          {formatTimeWithLocale(arrive, locale)}
        </StyledTripOptionInfo>

        {typeof carbonCost === "number" && (
          <StyledTripOptionInfo
            css={{
              paddingLeft: 16,
              position: "relative",
            }}
          >
            <StyledSeparator />
            <span>
              {carbonCost.toLocaleString(locale, { maximumFractionDigits: 2 })}{" "}
            </span>
            <span>{t("map.co2_kg")}</span>
          </StyledTripOptionInfo>
        )}

        {totalDistance && (
          <StyledTripOptionInfo
            css={{
              paddingLeft: 16,
              position: "relative",
            }}
          >
            <StyledSeparator />
            {totalDistance < 1000
              ? t("map.distance", { distance: totalDistance })
              : t("map.distance_km", {
                  distance: formatDistance(totalDistance / 1000, locale),
                })}
          </StyledTripOptionInfo>
        )}

        <div
          className="route-planner-trip-option__duration"
          css={{
            flexGrow: 1,
            fontFamily: AppFonts.NUMBERS,
            color: AppColors.GRAY_400,
            fontSize: "0.875rem",
            fontWeight: "bold",
            lineHeight: "20px",
            textAlign: "right",
          }}
        >
          {durationHours
            ? t("map.duration_h_min", durationArgs)
            : t("map.duration_min", durationArgs)}
        </div>
      </div>
    </div>
  );
}
