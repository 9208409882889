import { ReactElement } from "react";
import { AppColors } from "utils/colors";
import { DefaultIconProps } from "./props";

export function CameraIcon({
  fill = AppColors.GRAY_100,
  className = "",
  height = "24",
  width = "24",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 88 88"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle fill={fill} fillRule="nonzero" cx="44" cy="44" r="11.7" />
      <path
        d="M33 7.3l-6.7 7.4H14.7c-4 0-7.4 3.3-7.4 7.3v44c0 4 3.3 7.3 7.4 7.3h58.6c4 0 7.4-3.3 7.4-7.3V22c0-4-3.3-7.3-7.4-7.3H61.7L55 7.3H33zm11 55a18.3 18.3 0 110-36.6 18.3 18.3 0 010 36.6z"
        fill={fill}
        fillRule="nonzero"
      />
    </svg>
  );
}
