import { Component, createRef, FocusEvent } from "react";
import "./CodeInput.scss";

/**
 * Code forked and typed from
 * https://github.com/suweya/react-verification-code-input
 * version 1.2.5
 *
 * Reason - added on Enter handler so we can submit code as required
 */

const KEY_CODE = {
  enter: 13,
  backspace: 8,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
};

export interface ReactCodeInputProps {
  values: string[];
  type: "text" | "number" | string;
  fields: number;
  title: string;
  fieldWidth: number;
  fieldHeight: number;
  autoFocus: boolean;
  className: string;
  disabled: boolean;
  required: boolean;
  placeholder: string[];
  inputMode?:
    | "none"
    | "text"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal"
    | "search";
  onChange: (value: string) => void;
  onComplete: (value: string) => void;
  onEnter?: (value: string) => void;
  onFocus?: (e: FocusEvent) => void;
  onBlur?: (e: FocusEvent) => void;
}

export class ReactCodeInput extends Component<ReactCodeInputProps> {
  static defaultProps = {
    type: "number",
    fields: 6,
    fieldWidth: 58,
    fieldHeight: 54,
    autoFocus: true,
    disabled: false,
    required: false,
    placeholder: [],
  };

  state: {
    values: string[];
    autoFocusIndex: number;
  };

  constructor(props: ReactCodeInputProps) {
    super(props);

    const { fields, values } = props;

    let vals;

    let autoFocusIndex = 0;

    if (values && values.length) {
      vals = [];

      for (let i = 0; i < fields; i++) {
        vals.push(values[i] || "");
      }

      autoFocusIndex = values.length >= fields ? 0 : values.length;
    } else {
      vals = Array(fields).fill("");
    }

    this.state = { values: vals, autoFocusIndex };

    this.iRefs = [];

    for (let i = 0; i < fields; i++) {
      this.iRefs.push(createRef());
    }

    this.id = +new Date();
  }

  iRefs: any[];

  id: any;

  /**
   * Clear all field value & focus first field
   */
  __clearvalues__ = () => {
    const { fields } = this.props;

    this.setState({ values: Array(fields).fill("") });

    this.iRefs[0].current.focus();
  };

  triggerChange = (values = this.state.values) => {
    const { onChange, onComplete, fields } = this.props;

    const val = values.join("");

    onChange && onChange(val);

    if (onComplete && val.length >= fields) {
      onComplete(val);
    }
  };

  onChange = (e) => {
    const index = parseInt(e.target.dataset.id);

    if (this.props.type === "number") {
      e.target.value = e.target.value.replace(/[^\d]/gi, "");
    }

    if (
      e.target.value === "" ||
      (this.props.type === "number" && !e.target.validity.valid)
    ) {
      return;
    }

    const { fields } = this.props;

    let next;

    const value = e.target.value;

    let { values } = this.state;

    values = Object.assign([], values);

    if (value.length > 1) {
      let nextIndex = value.length + index - 1;

      if (nextIndex >= fields) {
        nextIndex = fields - 1;
      }

      next = this.iRefs[nextIndex];

      const split = value.split("");

      split.forEach((item: string, i: number) => {
        const cursor = index + i;

        if (cursor < fields) {
          values[cursor] = item;
        }
      });

      this.setState({ values });
    } else {
      next = this.iRefs[index + 1];

      values[index] = value;

      this.setState({ values });
    }

    if (next) {
      next.current.focus();

      next.current.select();
    }

    this.triggerChange(values);
  };

  onKeyDown = (e) => {
    const index = parseInt(e.target.dataset.id);

    const prevIndex = index - 1;

    const nextIndex = index + 1;

    const prev = this.iRefs[prevIndex];

    const next = this.iRefs[nextIndex];

    switch (e.keyCode) {
      case KEY_CODE.backspace:
        e.preventDefault();

        // eslint-disable-next-line no-case-declarations
        const vals = [...this.state.values];

        if (this.state.values[index]) {
          vals[index] = "";

          this.setState({ values: vals });

          this.triggerChange(vals);
        } else if (prev) {
          vals[prevIndex] = "";

          prev.current.focus();

          this.setState({ values: vals });

          this.triggerChange(vals);
        }

        break;
      case KEY_CODE.left:
        e.preventDefault();

        if (prev) {
          prev.current.focus();
        }

        break;
      case KEY_CODE.right:
        e.preventDefault();

        if (next) {
          next.current.focus();
        }

        break;
      case KEY_CODE.up:
      case KEY_CODE.down:
        e.preventDefault();

        break;
      case KEY_CODE.enter:
        e.preventDefault();

        this.props.onEnter && this.props.onEnter(this.state.values.join(""));

        break;
      default:
        break;
    }
  };

  onFocus = (e) => {
    const { onFocus } = this.props;

    e.target.select(e);

    onFocus && onFocus(e);
  };

  onBlur = (e) => {
    const { onBlur } = this.props;

    onBlur && onBlur(e);
  };

  render() {
    const { values, autoFocusIndex } = this.state;

    const {
      title,
      fieldHeight,
      fieldWidth,
      fields,
      autoFocus,
      className,
      type,
    } = this.props;

    const INPUT_STYLE = {
      width: fieldWidth,
      height: fieldHeight,
    };

    const ROOT_STYLE = {
      width: fields * fieldWidth,
    };

    return (
      <div
        className={`${"react-code-input-container"} ${className}`}
        style={ROOT_STYLE}
      >
        {title && <p className="title">{title}</p>}

        <div className="react-code-input">
          {values.map((value, index) => (
            <input
              type={type === "number" ? "tel" : type}
              pattern={type === "number" ? "[0-9]*" : undefined}
              autoFocus={autoFocus && index === autoFocusIndex}
              style={INPUT_STYLE}
              key={`${this.id}-${index}`}
              data-id={index}
              value={value}
              ref={this.iRefs[index]}
              onChange={this.onChange}
              onKeyDown={this.onKeyDown}
              inputMode={this.props.inputMode}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              disabled={this.props.disabled}
              required={this.props.required}
              placeholder={this.props.placeholder[index]}
            />
          ))}
        </div>
      </div>
    );
  }
}
