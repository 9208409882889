import { Spinner } from "common/Spinner";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { AppColors } from "utils/colors";

export function AddMoneyProcessingPayment(): ReactElement {
  const { t } = useTranslation();

  return (
    <div
      css={{
        textAlign: "center",
      }}
    >
      <h2
        css={{
          fontSize: "2rem",
          lineHeight: 1.25,
          marginBottom: "2rem",
          fontWeight: 400,
          marginTop: "2.25rem",
        }}
      >
        {t("add_money.processing")}
      </h2>
      <p
        css={{
          fontSize: "1rem",
          lineHeight: 1.5,
          marginBottom: "2rem",
          color: AppColors.GRAY_400,
        }}
      >
        {t("add_money.processing_text")}
      </p>
      <Spinner />
    </div>
  );
}
