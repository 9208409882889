import { InputGroup, MobeaButton } from "common/forms";
import { InfoIcon } from "icons/InfoIcon";
import { ReactElement, ReactText } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { EURO, formatCurrency, MAX_PRIVATE_AMOUNT, Routes } from "utils";
import { AppColors, AppFonts } from "utils/colors";
import { AddMoneyInfoLine } from "./AddMoneyInfoLine";

export type ErrorMessage = "invalid" | "min" | "max" | "over" | null;

export interface AddMoneySetAmountProps {
  value: string;
  amount: number;
  min: number;
  max: number;
  privateAmount: number;
  buttonDisabled: boolean;
  buttonLoading: boolean;
  errorMsg: ErrorMessage;
  locale: string;
  validate: (value: ReactText) => boolean;
  goToPayments: () => void;
  onValueChange: (text: ReactText) => void;
  onFocus: () => void;
  onBlur: () => void;
}

export function AddMoneySetAmount({
  value,
  amount,
  min,
  max,
  privateAmount,
  buttonDisabled,
  buttonLoading,
  errorMsg,
  locale,
  validate,
  goToPayments,
  onValueChange,
  onBlur,
  onFocus,
}: AddMoneySetAmountProps): ReactElement {
  const { t } = useTranslation();

  const formattedTotal = formatCurrency(amount, locale);
  const formattedMin = formatCurrency(min, locale, {
    minimumFractionDigits: 0,
  });
  const formattedMax = formatCurrency(max, locale, {
    minimumFractionDigits: 0,
  });
  const formattedRemaining = formatCurrency(
    MAX_PRIVATE_AMOUNT - privateAmount,
    locale,
    {
      minimumFractionDigits: 0,
    }
  );
  const formattedLimit = formatCurrency(MAX_PRIVATE_AMOUNT, locale, {
    minimumFractionDigits: 0,
  });

  const minMax = {
    maxTotal: formattedLimit, // max amount
    min: formattedMin,
    max: formattedMax,
    remaining: formattedRemaining,
  };

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        flex: "1 0 auto",
      }}
    >
      <p
        css={{
          color: AppColors.GRAY_400,
          fontSize: "1rem",
          lineHeight: 1.5,
          textAlign: "center",
        }}
      >
        {t("add_money.text")}
      </p>
      <InputGroup
        label={t("shared.amount")}
        name="amount"
        value={value}
        placeholder={`${EURO}0,00`}
        validation={validate}
        inputAttributes={{
          inputMode: "decimal",
          autoComplete: "off",
        }}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onValueChange}
        css={{
          marginBottom: "3rem",
          height: "4.6rem",
          paddingTop: "1.25rem",
          "&.mobea__errored": {
            whiteSpace: "initial",
            ">input": {
              color: AppColors.ERROR,
            },
            ">label": {
              color: AppColors.GRAY_200,
            },
          },
          ">input": {
            marginBottom: "0.75rem",
            fontSize: "2.5rem",
            letterSpacing: "1.13px",
            lineHeight: 1,
            height: "3rem",
            paddingBottom: 0,
            paddingTop: 0,
            caretColor: AppColors.PRIMARY,
            color: AppColors.PRIMARY,
          },
          ">label[for='amount']": {
            color: AppColors.GRAY_200,
          },
        }}
      >
        <span
          css={{
            color: errorMsg ? AppColors.ERROR : AppColors.GRAY_300,
            fontSize: "0.8125rem",
            lineHeight: "1rem",
            fontFamily: AppFonts.SECONDARY,
            opacity: amount && !errorMsg ? 0 : 1,
            transition: "opacity 0.2",
          }}
        >
          <InfoIcon
            width={16}
            css={{
              verticalAlign: "bottom",
              marginRight: "0.25rem",
            }}
          />
          {errorMsg
            ? t(`add_money.error_${errorMsg}`, minMax)
            : t("add_money.charge_info", minMax)}
        </span>
      </InputGroup>

      <div
        css={{
          flex: "1 0 auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          opacity: amount && !errorMsg ? 1 : 0,
          transition: "opacity 0.2",
        }}
      >
        <AddMoneyInfoLine
          label={t("add_money.total_amount")}
          value={formattedTotal}
          dark
        />
      </div>
      <MobeaButton
        onClick={goToPayments}
        disabled={buttonDisabled}
        loading={buttonLoading}
      >
        {t("add_money.go_to_payments")}
      </MobeaButton>
      <p
        css={{
          color: AppColors.GRAY_300,
          fontSize: "0.8125rem",
          lineHeight: "1rem",
          margin: "8px 0 0",
        }}
      >
        <Trans i18nKey="add_money.acknowledgement">
          <Link to={Routes.TermsAndConditions}></Link>
        </Trans>
      </p>
    </div>
  );
}
