import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

export function RefundIcon({
  fill = "currentColor",
  className = "",
  height = "24",
  width = "24",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.37 18.25c1.04 0 1.88-.84 1.88-1.87V2.63c0-1.04-.84-1.88-1.88-1.88H2.63C1.59.75.75 1.59.75 2.63v13.75c0 1.03.84 1.87 1.88 1.87h18.74zm-.23-1.87H2.86a.24.24 0 01-.23-.24V2.86c0-.13.1-.23.23-.23h18.28c.13 0 .23.1.23.23v13.28c0 .13-.1.24-.23.24zm-2.58-8.75c.52 0 .94-.42.94-.94V4.8a.94.94 0 00-.94-.93h-3.12a.94.94 0 00-.94.93V6.7c0 .52.42.94.94.94h3.12zM10.91 12c.25 0 .46-.21.46-.47V9.97a.47.47 0 00-.46-.47H8.7a.47.47 0 00-.46.47v1.56c0 .26.21.47.47.47h2.19zm3.75 0c.25 0 .46-.21.46-.47V9.97a.47.47 0 00-.46-.47h-2.2a.47.47 0 00-.46.47v1.56c0 .26.21.47.47.47h2.19zm-7.5 0c.25 0 .46-.21.46-.47V9.97a.47.47 0 00-.46-.47h-2.2a.47.47 0 00-.46.47v1.56c0 .26.21.47.47.47h2.19zm11.87 0c.26 0 .47-.21.47-.47V9.97a.47.47 0 00-.47-.47h-2.81a.47.47 0 00-.47.47v1.56c0 .26.21.47.47.47h2.81zM7.78 14.5c.26 0 .47-.21.47-.47v-.31a.47.47 0 00-.47-.47H4.97a.47.47 0 00-.47.47v.31c0 .26.21.47.47.47h2.81zm7.5 0c.26 0 .47-.21.47-.47v-.31a.47.47 0 00-.47-.47H9.97a.47.47 0 00-.47.47v.31c0 .26.21.47.47.47h5.31z"
        fill={fill}
        fillRule="nonzero"
      />
    </svg>
  );
}
