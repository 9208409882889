import { CircleCheckIcon } from "icons/CircleCheckIcon";
import { CloseIcon } from "icons/CloseIcon";
import { DefaultIconProps } from "icons/props";
import { RefreshIcon } from "icons/RefreshIcon";
import { FC } from "react";
import { MolliePaymentStatus } from "services/mollieService";
import { AppColors } from "utils/colors";

export const topupStatusProps: Record<
  MolliePaymentStatus,
  [color: string, icon: FC<DefaultIconProps>, iconSize: number]
> = {
  created: [AppColors.GRAY_200, RefreshIcon, 24],
  open: [AppColors.GRAY_200, RefreshIcon, 24],
  paid: ["var(--color-status-green)", CircleCheckIcon, 24],
  failed: [AppColors.ERROR, CloseIcon, 18],
  canceled: [AppColors.ERROR, CloseIcon, 18],
  expired: [AppColors.ERROR, CloseIcon, 18],
  pending: [AppColors.GRAY_200, RefreshIcon, 24],
  authorized: ["var(--color-status-green)", CircleCheckIcon, 24],
};
