import { FileAttachment } from "common/types";
import { History } from "history";
import { LocationCoords } from "services/mapService";
import { MobitTicketStatus } from "state/actions";
import {
  ADMIN_API_BASE,
  ApiErrorResponse,
  CORE_API_BASE,
  WalletType,
} from "utils";
import { callSecuredApiEndpoint } from "./apiCall";

/**
 * Mobit always send code in response but that clashes with our Auth
 * which sends code if user is logged out so we have to normalize response
 */
const normalizeMobitErrorCode = ({ code, ...rest }): any => ({
  ...rest,
  mobit_code: code,
});

const spreadObjectData = (json) => ({ ...json.data });
const spreadArrayData = (json) => [...json.data];

const toNormalizedMobitResponse =
  (normalizer: typeof spreadArrayData | typeof spreadObjectData) => (json) => {
    // mobit success
    if (json.mobit_code) {
      if (json.mobit_code === "0000") {
        return {
          error: false,
          data: normalizer(json),
        };
      } else {
        return {
          error: true,
          error_code: json.mobit_code.replace("0", "M"),
          error_type: "mobit_error",
          error_description: "",
        } as ApiErrorResponse;
      }
    }

    return {
      error: !!json.error_code,
      ...json,
    };
  };

const toNormalizedMobitArrayResponse =
  toNormalizedMobitResponse(spreadArrayData);

const toNormalizedMobitObjectResponse =
  toNormalizedMobitResponse(spreadObjectData);

export type MobitOrderDataResponse =
  | {
      error: false;
      id: number;
      name: string;
      image: null;
      duration: number;
      rideLimit: number;
      dayLimit: number;
      updateTime: number; // timestamp
      manager: number;
      price: number;
      activationMethod: number; // enum?
      description: null;
      useTime: null;
      endTime: null;
    }
  | ApiErrorResponse;

function getMobitOrderDataRequest(authorization: string) {
  return fetch(`${CORE_API_BASE}providers/mobit/daypass-pricing`, {
    method: "GET",
    headers: new window.Headers({
      Authorization: authorization,
    }),
  }).then((response) => response.json());
}

export function getMobitOrderData(history: History) {
  return callSecuredApiEndpoint(getMobitOrderDataRequest, history).then(
    (json) => ({
      ...json,
      error: !!json.error_code,
    })
  ) as Promise<MobitOrderDataResponse>;
}

export type MobitTicketMetadata = {
  activation_datetime: string | null; // Date
  bike_code: string | null;
  id: number;
  ride_status: MobitTicketStatus;
  riding_id: number | null;
  fine: string;
};

export type MobitDayPassOrderResponse =
  | {
      error: false;
      transaction_id: string;
    }
  | ApiErrorResponse;

function orderMobitDayPassRequest(wallet: WalletType) {
  return (authorization: string) =>
    fetch(`${ADMIN_API_BASE}mobit/orders`, {
      method: "POST",
      headers: new window.Headers({
        "Content-Type": "application/json",
        Authorization: authorization,
      }),
      body: JSON.stringify({ data: { plan_type: wallet } }),
    }).then((response) => response.json());
}

export function orderMobitDayPass(history: History, wallet: WalletType) {
  return callSecuredApiEndpoint(orderMobitDayPassRequest(wallet), history).then(
    (json) => ({
      ...json,
      error: !!json.error_code,
    })
  ) as Promise<MobitDayPassOrderResponse>;
}

export type MobitNearbyBikesResponse =
  | {
      error: false;
      data: {
        frontCode: string;
        backCode: string;
        location: {
          x: number;
          y: number;
        };
      }[];
    }
  | ApiErrorResponse;

export type MobitNearbyBike = {
  frontCode: string;
  backCode: string;
} & LocationCoords;

function getMobitNearbyBikesRequest(coords: LocationCoords) {
  return (authorization: string) =>
    fetch(
      `${CORE_API_BASE}providers/mobit/nearby-bikes?longitude=${coords.lng.toFixed(
        5
      )}&latitude=${coords.lat.toFixed(5)}`,
      {
        method: "GET",
        headers: new window.Headers({
          Authorization: authorization,
        }),
      }
    )
      .then((response) => response.json())
      // fix Mobit error code name as it breaks our Auth handling in edge cases
      .then(normalizeMobitErrorCode);
}

export function getMobitNearbyBikes(
  coordinates: LocationCoords,
  history: History
) {
  return callSecuredApiEndpoint(
    getMobitNearbyBikesRequest(coordinates),
    history
  ).then(toNormalizedMobitArrayResponse) as Promise<MobitNearbyBikesResponse>;
}

type MobitEfencePointRaw = {
  type: "Point";
  coordinates: [number, number];
  x: number;
  y: number;
};
export type MobitEfencesResponse =
  | {
      error: false;
      data: {
        borderColor: string;
        borderSize: number;
        color: string;
        image: string; // url
        loc: {
          type: "Polygon";
          coordinates: {
            type: "LineString";
            coordinates: MobitEfencePointRaw[];
          }[];
          points: MobitEfencePointRaw[];
        };
        title: string;
        typeName: string;
        zOrder: number;
      }[];
    }
  | ApiErrorResponse;

export type MobitEfencePoint = {
  lat: number;
  lng: number;
};

export type MobitEfence = {
  image: string; // url
  loc: {
    type: "Polygon";
    points: MobitEfencePoint[];
  };
  title: string;
  typeName: string;
  zOrder: number;
};

const getMobitEfencesRequest =
  (topLeft: LocationCoords, bottomRight: LocationCoords) =>
  (authorization: string) =>
    fetch(
      `${CORE_API_BASE}providers/mobit/nearby-fance?top-longitude=${topLeft.lng.toFixed(
        5
      )}&top-latitude=${topLeft.lat.toFixed(
        5
      )}&bottom-longitude=${bottomRight.lng.toFixed(
        5
      )}&bottom-latitude=${bottomRight.lat.toFixed(5)}`,
      {
        method: "GET",
        headers: new window.Headers({
          Authorization: authorization,
        }),
      }
    )
      .then((response) => response.json())
      .then(normalizeMobitErrorCode);

export function getMobitEfences(
  topLeft: LocationCoords,
  bottomRight: LocationCoords,
  history: History
) {
  return callSecuredApiEndpoint(
    getMobitEfencesRequest(topLeft, bottomRight),
    history
  ).then(toNormalizedMobitArrayResponse) as Promise<MobitEfencesResponse>;
}

export type MobitBikeAvailabilityResponse =
  | {
      error: false;
      data: {
        frontCode: string;
        backCode: string;
        location: {
          x: number;
          y: number;
        };
        lockStatus: number; // 0 lock opened 1 lock closed
      };
    }
  | ApiErrorResponse;

const checkIsMobitBikeAvailableRequest =
  (bikeCode: string) => (authorization: string) =>
    fetch(
      `${CORE_API_BASE}providers/mobit/${bikeCode}/check-bike-availability`,
      {
        method: "GET",
        headers: new window.Headers({
          Authorization: authorization,
        }),
      }
    )
      .then((response) => response.json())
      .then(normalizeMobitErrorCode);

export function checkIsMobitBikeAvailable(bikeCode: string, history: History) {
  return callSecuredApiEndpoint(
    checkIsMobitBikeAvailableRequest(bikeCode),
    history
  ).then(
    toNormalizedMobitObjectResponse
  ) as Promise<MobitBikeAvailabilityResponse>;
}

export type OpenBikeResponse =
  | {
      error: false;
    }
  | ApiErrorResponse;

function openBikeRequest(bikeCode: string) {
  return (authorization: string) =>
    fetch(`${CORE_API_BASE}providers/mobit/${bikeCode}/open-bike`, {
      method: "POST",
      headers: new window.Headers({
        Authorization: authorization,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then(normalizeMobitErrorCode);
}

export function openBike(bikeCode: string, history: History) {
  return callSecuredApiEndpoint(openBikeRequest(bikeCode), history).then(
    toNormalizedMobitObjectResponse
  ) as Promise<OpenBikeResponse>;
}

export type ReportBikeData = {
  problem: string;
  attachments: FileAttachment[];
};

export type ReportBikeResponse =
  | {
      error: false;
    }
  | ApiErrorResponse;

const reportBikeRequest =
  (bikeCode: string, reportData: ReportBikeData) => (authorization: string) => {
    const formData = new window.FormData();

    formData.append("desc", reportData.problem);
    if (reportData.attachments[0]?.file) {
      formData.append(
        "file",
        reportData.attachments[0].file,
        reportData.attachments[0].name
      );
    }

    return fetch(`${CORE_API_BASE}providers/mobit/${bikeCode}/report-defect`, {
      method: "POST",
      headers: new window.Headers({
        Authorization: authorization,
      }),
      body: formData,
    })
      .then((response) => response.json())
      .then(normalizeMobitErrorCode);
  };

export function reportBike(
  bikeCode: string,
  reportData: ReportBikeData,
  history: History
) {
  return callSecuredApiEndpoint(
    reportBikeRequest(bikeCode, reportData),
    history
  ).then(toNormalizedMobitObjectResponse) as Promise<ReportBikeResponse>;
}

export type RingBikeResponse =
  | {
      error: false;
    }
  | ApiErrorResponse;

function findBikeRequest(bikeCode: string) {
  return (authorization: string) =>
    fetch(`${CORE_API_BASE}providers/mobit/${bikeCode}/find-bike`, {
      method: "GET",
      headers: new window.Headers({
        Authorization: authorization,
      }),
    })
      .then((response) => response.json())
      .then(normalizeMobitErrorCode);
}

export function findBike(bikeCode: string, history: History) {
  return callSecuredApiEndpoint(findBikeRequest(bikeCode), history).then(
    toNormalizedMobitObjectResponse
  ) as Promise<RingBikeResponse>;
}

export type PauseRideResponse =
  | {
      error: false;
    }
  | ApiErrorResponse;

const pauseRideRequest = (rideId: string) => (authorization: string) =>
  fetch(`${CORE_API_BASE}providers/mobit/ride/${rideId}/pause`, {
    method: "POST",
    headers: new window.Headers({
      "Content-Type": "application/json",
      Authorization: authorization,
    }),
    body: JSON.stringify({}),
  })
    .then((response) => response.json())
    .then(normalizeMobitErrorCode);

export function pauseRide(rideId: string, history: History) {
  return callSecuredApiEndpoint(pauseRideRequest(rideId), history).then(
    toNormalizedMobitObjectResponse
  ) as Promise<PauseRideResponse>;
}

export type EndRideResponse =
  | {
      error: false;
    }
  | ApiErrorResponse;

const endRideRequest = (ridingId: string) => (authorization: string) =>
  fetch(`${CORE_API_BASE}providers/mobit/ride/${ridingId}/end`, {
    method: "POST",
    headers: new window.Headers({
      "Content-Type": "application/json",
      Authorization: authorization,
    }),
    body: JSON.stringify({}),
  })
    .then((response) => response.json())
    .then(normalizeMobitErrorCode);

export function endRide(ridingId: string, history: History) {
  return callSecuredApiEndpoint(endRideRequest(ridingId), history).then(
    toNormalizedMobitObjectResponse
  ) as Promise<EndRideResponse>;
}
