import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

type Props = DefaultIconProps & {
  outlined?: boolean;
};

export function ProfileIcon({
  fill = "currentColor",
  className = "",
  height = "16",
  width = "16",
  outlined = false,
}: Props): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={outlined ? "0 0 16 16" : "2 2 16 16"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      {!outlined && (
        <path
          d="M10,10 C11.84,10 13.33,8.51 13.33,6.66 C13.33,4.825 11.84,3.33 10,3.33 C8.16,3.33 6.66,4.825 6.66,6.66 C6.66,8.51 8.16,10 10,10 Z M10,11.66 C7.775,11.66 3.33,12.78 3.33,15 L3.33,16.66 L16.66,16.66 L16.66,15 C16.66,12.78 12.225,11.66 10,11.66 Z"
          fill={fill}
        />
      )}
      {outlined && (
        <path
          d="M8 4c.733 0 1.333.6 1.333 1.333 0 .734-.6 1.334-1.333 1.334s-1.333-.6-1.333-1.334C6.667 4.6 7.267 4 8 4m0 6c1.8 0 3.867.86 4 1.333V12H4v-.66C4.133 10.86 6.2 10 8 10m0-7.333A2.666 2.666 0 108 8a2.666 2.666 0 100-5.333zm0 6c-1.78 0-5.333.893-5.333 2.666v2h10.666v-2C13.333 9.56 9.78 8.667 8 8.667z"
          fill={fill}
        />
      )}
    </svg>
  );
}
