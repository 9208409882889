import { DefaultIconProps } from "icons/props";
import { ReactElement } from "react";

export function NmbsLogo({
  className = "",
  width,
  height,
}: Pick<DefaultIconProps, "className" | "width" | "height">): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 504 329.2"
    >
      <path
        fill="#0071B7"
        d="M252,311.9c-115,0-208.7-66.2-208.7-147.3S137,17.3,252,17.3s208.7,66.2,208.7,147.3S367,311.9,252,311.9 M252,0C112.6,0,0,73.2,0,164.6s112.6,164.6,252,164.6s252-74,252-164.6S391.4,0,252,0"
      />
      <path
        fill="#0071B7"
        d="M263,251.2h-27.6c-8.7,0-13.4-3.9-13.4-11v-63c0-3.9,1.6-5.5,5.5-5.5H263c22.3,0,40.5,17.9,40.9,40.2c0.3,21.5-16.9,39.1-38.4,39.4C264.7,251.3,263.9,251.3,263,251.2 M222.1,90.6c0-7.1,4.7-11,13.4-11h18.1c18.7-0.8,34.6,13.7,35.4,32.4v1.5c-0.3,19.3-16.1,34.8-35.4,34.6h-26c-3.9,0-5.5-1.6-5.5-5.5V90.6z M333.1,160.7c-5.5-2.4-5.5-3.2,0-6.3c14.1-8.7,22.5-24.3,22-40.9c0-30.7-40.9-61.4-106.3-61.4c-37.8-0.2-74.5,12-104.7,34.6c-5.5,4.7-4.7,7.1-3.1,8.7l9.5,11c3.1,3.2,4.7,2.4,6.3,0.8c7.1-5.5,7.9-2.4,7.9,3.9v108.7c0,6.3-0.8,9.5-7.9,3.9c-1.6-1.6-3.1-2.4-6.3,0.8l-10.2,11.8c-1.6,2.4-3.1,4.7,3.2,8.7c31.6,21.6,68.8,33.4,107.1,33.9c73.2,0,118.9-30.7,118.9-71.7 C370.1,179.6,347.3,166.2,333.1,160.7"
      />
    </svg>
  );
}
