import { DefaultIconProps } from "icons/props";
import { ReactElement } from "react";
import { HorizontalClassicFlagIcon } from "./HorizontalClassicFlag";

export function LuxembourgIcon(props: DefaultIconProps): ReactElement {
  return (
    <HorizontalClassicFlagIcon
      firstColor="#ed2839"
      secondColor="#fff"
      thirdColor="#01a1df"
      {...props}
    />
  );
}
