import { getRoutePlannerTravelTypeIcon } from "pages/map/functions";
import { ReactElement } from "react";
import { useSelector } from "react-redux";
import { RoutePlanSegmentInfo } from "services/mapService";
import { AppColors } from "utils/colors";

type Props = {
  segmentInfo: RoutePlanSegmentInfo;
  outlined?: boolean;
  size: number;
};

export function TripProviderIconWrapper({
  segmentInfo,
  outlined = false,
  size,
}: Props): ReactElement {
  const { providers: allProviders, providersToTypes: providersTypes } =
    useSelector((state) => state.passes);

  const color = segmentInfo.providers[0]
    ? allProviders.find(
        (provider) => segmentInfo.providers[0].type === provider.provider
      )?.color
    : undefined;

  return (
    <div
      className="route-planner-trip-segment-icon"
      css={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          color || (outlined ? "transparent" : AppColors.GRAY_200),
        width: size,
        height: size,
        borderRadius: "50%",
        color: outlined && !color ? AppColors.GRAY_300 : AppColors.WHITE,
        border: `1px solid ${color || AppColors.GRAY_200}`,
      }}
    >
      {getRoutePlannerTravelTypeIcon(segmentInfo, providersTypes)}
    </div>
  );
}
