import { CloseIcon } from "icons/CloseIcon";
import { NavBackIcon } from "icons/NavBackIcon";
import React from "react";
import { AppColors } from "utils/colors";

export type PageHeaderPublicProps = {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  headerInSecondaryColor?: boolean;
  backButtonVisible?: boolean;
  headerHeight?: "default" | "compact" | "minimal";
  customCloseButton?: React.ReactNode;
  onClose?: () => void;
};

type PageHeaderProps = {
  onBack: (e: React.MouseEvent) => void;
};

export function PageHeader({
  title,
  subtitle,
  headerInSecondaryColor = false,
  headerHeight = "default",
  backButtonVisible = true,
  customCloseButton,
  onBack,
  onClose,
}: PageHeaderProps & PageHeaderPublicProps) {
  return (
    <header
      className="m_page__header"
      css={{
        width: "100%",
        minHeight: 28,
        position: "relative",
        display: "flex",
        color: headerInSecondaryColor ? AppColors.SECONDARY : AppColors.PRIMARY,
        marginBottom:
          headerHeight === "default" ? 32 : headerHeight === "compact" ? 16 : 0,
        "@media screen and (max-height: 620px)": {
          marginBottom: headerHeight === "compact" ? 16 : 8,
        },
      }}
    >
      {backButtonVisible && (
        <button
          className={`m_page__header__back ${
            headerInSecondaryColor ? "secondary-color" : ""
          }`}
          onClick={onBack}
          css={{
            color: "inherit",
            background: "none",
            border: "0 none",
            display: "inline-block",
            padding: "0px 2px 4px 0px",
            outline: "0 none",
            flex: "0 0 auto",
            position: "relative",
            left: -6,
            height: 28,
          }}
        >
          <NavBackIcon />
        </button>
      )}
      <h1
        css={{
          fontSize: "1.375rem",
          fontWeight: 500,
          lineHeight: 1.2727,
          textAlign: "center",
          margin: 0,
          flex: "1 1 auto",
          position: "relative",
          "@media screen and (max-height: 620px)": {
            fontSize: "1.25rem",
            lineHeight: 1.2,
          },
        }}
      >
        <span className="m_page__header__title">{title}</span>

        {subtitle && (
          <span
            className="m_page__header__subtitle"
            css={{
              display: "block",
              fontSize: "0.75rem",
              lineHeight: 1.6667,
              textAlign: "center",
            }}
          >
            {subtitle}
          </span>
        )}
      </h1>
      {customCloseButton !== undefined ? (
        customCloseButton
      ) : onClose ? (
        <CloseIcon onClick={onClose} css={{ flex: "0 0 auto" }} />
      ) : (
        <div css={{ width: 20, height: 20, visibility: "hidden" }}></div>
      )}
    </header>
  );
}
