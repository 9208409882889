import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateCustomer } from "services/customerService";
import { setCustomerProfileDataAction } from "state/actions";
import { ApiErrors } from "utils";

interface ProfileUpdateData {
  email?: string;
  iban?: string;
}

export function useUpdateProfile() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [updating, setUpdating] = useState(false);
  const [result, setResult] = useState<"success" | ApiErrors | null>(null);

  const reset = () => setResult(null);

  const updateProfile = async (
    customerId: string,
    { email, iban }: ProfileUpdateData
  ) => {
    setUpdating(true);

    try {
      const response = await updateCustomer(
        customerId,
        {
          email,
          iban,
        },
        history
      );

      if (!response.error) {
        setResult("success");

        dispatch(
          setCustomerProfileDataAction({
            email,
            iban,
          })
        );
      } else {
        setResult(response.error_code as ApiErrors);
        console.warn("Customer update failed", response.error_code);
      }
    } catch (e) {
      setResult(ApiErrors.I_NETWORK);
      console.warn("Customer update failed", e);
    } finally {
      setUpdating(false);
    }
  };

  return [result, updating, updateProfile, reset] as const;
}
