import { ArrowRightIcon } from "icons/ArrowRightIcon";
import { ReactElement } from "react";
import { AppColors } from "utils/colors";
import "./RouteSection.scss";

type Props = {
  origin: string;
  destination: string;
};

export function RouteSection({ origin, destination }: Props): ReactElement {
  return (
    <section className="route-section">
      <div className="route-section__origin">{origin}</div>
      <ArrowRightIcon
        className="route-section__arrow"
        fill={AppColors.PRIMARY}
      />
      <div className="route-section__destination">{destination}</div>
    </section>
  );
}
