import { ReactElement } from "react";
import { DefaultIconProps } from "../props";

export function HomeIcon({
  fill = "currentColor",
  className = "",
  height = "24",
  width = "24",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="mobea__navigation__inactive"
        d="M12 5.7l5 4.5V18h-2v-6H9v6H7v-7.8l5-4.5M12 3L2 12h3v8h6v-6h2v6h6v-8h3L12 3z"
        fill={fill}
        fillRule="nonzero"
      />
      <polygon
        className="mobea__navigation__active"
        fill={fill}
        points="10 20 10 14 14 14 14 20 19 20 19 12 22 12 12 3 2 12 5 12 5 20"
      />
    </svg>
  );
}
