import { MobeaDropdown } from "common/forms";
import { useClientLogo } from "common/hooks/useClientLogo";
import { GlobeIcon } from "icons/GlobeIcon";
import { ReactElement, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { updateCustomer } from "services/customerService";
import { getLanguages, Routes } from "utils";
import i18n from "utils/i18n";
import "./LogoWithLangHeader.scss";

type Props = {
  saveLanguage?: "onChange" | "always";
};

export function LogoWithLangHeader({ saveLanguage }: Props): ReactElement {
  const clientLogo = useClientLogo();

  const { language, customerId } = useSelector((state) => state.user);

  const handleLanguageChange = (value: string) => {
    if (value !== language) {
      i18n.changeLanguage(value);
    }

    if (saveLanguage === "onChange") {
      updateCustomer(
        customerId,
        {
          language: value,
        },
        history
      );
    }
  };

  const history = useHistory();

  useEffect(() => {
    if (saveLanguage === "always") {
      updateCustomer(
        customerId,
        {
          language,
        },
        history
      );
    }
  }, [customerId, history, language, saveLanguage]);

  return (
    <header className="mobea_logo_lang_header">
      <Link to={Routes.Login}>{clientLogo}</Link>

      <MobeaDropdown
        className="mobea__settings__language-picker"
        direction="down"
        value={language}
        options={Object.keys(getLanguages()).filter((lang) => language != lang)}
        onChange={handleLanguageChange}
        optionHeight={48}
        icon={<GlobeIcon className="mobea__dropdown__select__prefix" />}
        showArrow
      />
    </header>
  );
}
