import { useCurrentTicket } from "common/hooks/useCurrentTicket";
import { NestedPage } from "common/page/NestedPage";
import { Spinner } from "common/Spinner";
import { MobitTravelPass } from "common/travelPasses";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  MobitTicketStatus,
  MobitTravelPass as MobitTravelPassType,
} from "state/actions";
import {
  getSearchParams,
  MOBIT_DEFAULT_DURATION,
  pushLocation,
  Routes,
} from "utils";
import { BackButtonBehaviour } from "utils/routing";
import "./MobitTicketDetail.scss";
import { MobitTutorial } from "./MobitTutorial";

export interface MobitTicketDetailProps {
  backButtonBehaviour?: BackButtonBehaviour;
  disabledActions?: boolean;
}

export function MobitTicketDetail({
  backButtonBehaviour = "goHome",
}: MobitTicketDetailProps): ReactElement {
  const history = useHistory();

  const location = useLocation();

  const { id = "" } = useParams<{ id: string }>();

  const { t } = useTranslation();

  const queryParams = getSearchParams(location.search);
  const showTutorial = Boolean(queryParams.get("showTutorial"));

  const language = useSelector((state) => state.user.language);

  const [currentPass, loadingFailed, loadingDetail] =
    useCurrentTicket<MobitTravelPassType>(
      id,
      () => true //reload ticket when opened - it might have expired
    );

  const goBack = () =>
    backButtonBehaviour === "goHome"
      ? pushLocation(history, Routes.Home)
      : history.goBack();

  const {
    duration = MOBIT_DEFAULT_DURATION,
    status = MobitTicketStatus.NOT_USED,
    expiration = Infinity,
  } = currentPass ?? {};

  const enhancedStatus =
    expiration < Date.now() ? MobitTicketStatus.ENDED : status;

  return (
    <NestedPage
      pageClassName="mobea-ticket-mobit"
      title={
        <>
          {t("ticked_detail.my_ticket")}
          <MobitTutorial duration={duration} forceShow={showTutorial} />
        </>
      }
      background="default"
      padding={{
        left: 16,
        right: 16,
        bottom: 16,
      }}
      onNavBack={goBack}
    >
      {loadingDetail && <Spinner />}

      {!loadingDetail && currentPass && (
        <MobitTravelPass
          {...currentPass}
          status={enhancedStatus}
          locale={language}
        />
      )}

      {!loadingDetail && loadingFailed && !currentPass && (
        <h2>Sorry cannot find travel pass.</h2>
      )}
    </NestedPage>
  );
}
