import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTicketDetail } from ".";

export function useCurrentTicket<T>(
  id: string,
  // identify case when we have only partial ticket data
  ticketIntegrityCheck?: (currentPass: T) => boolean
) {
  const check = ticketIntegrityCheck ? ticketIntegrityCheck : () => false;

  const [idToLoad, setIdToLoad] = useState<string | null>(null);

  const [loadedTravelPass, loadingFailed, loadingDetail, , refresh] =
    useTicketDetail<T>(idToLoad);

  const decodedId = decodeURIComponent(id);

  const { active, purchases } = useSelector((state) => state.passes);

  const ticket = useMemo(() => {
    let currentPass = active
      .concat(purchases)
      .find((pass) => pass.id === decodedId) as T | undefined;

    // wait for result while loading or if it fails
    if (loadingDetail || loadingFailed) {
      // update local data with fetched ones
    } else if (!currentPass && loadedTravelPass) {
      currentPass = loadedTravelPass;

      // load if not already set to load
    } else if (idToLoad === null) {
      // identify pass not cached or case when we have only partial data
      (!currentPass || check(currentPass)) && setIdToLoad(decodedId);
    }

    return currentPass;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    active,
    decodedId,
    idToLoad,
    loadedTravelPass,
    loadingDetail,
    loadingFailed,
    purchases,
  ]);

  return [ticket, loadingFailed, loadingDetail, refresh] as const;
}
