import { NmbsLogo } from "icons/logos/NmbsLogo";
import { CommonTileProps } from "pages/providers/tiles";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Routes, TravelPassProvider } from "utils";
import { useCheckEnoughBudget } from "../dialogs/useInsufficientBudgetDialog";
import { ProviderTile, PublicProviderTileProps } from "./ProviderTile";

export function NmbsTile(
  props: PublicProviderTileProps & CommonTileProps
): ReactElement {
  const { t } = useTranslation();

  const checkDeposit = useCheckEnoughBudget(
    props.provider,
    props.minPrice || 0
  );

  return (
    <ProviderTile
      {...props}
      to={Routes.ExpenseAdd.replace(":provider", TravelPassProvider.nmbs)}
      kind={t("data.train")}
      beforeBuy={checkDeposit}
    >
      <NmbsLogo width="32px" height="23px" />
    </ProviderTile>
  );
}
