import { DefaultIconProps } from "icons/props";
import { ReactElement } from "react";

export function PauseIcon({
  className = "",
  fill = "currentColor",
  height = "33",
  width = "29",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 30 33"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="10" height="33" x="0" y="0" fill={fill} />
      <rect width="10" height="33" x="18" y="0" fill={fill} />
    </svg>
  );
}
