import { ReactElement } from "react";
import "./CarPlate.scss";

const VEHICLE_PLATE_COUNTRY = "B";

type Props = {
  country?: string;
  plate: string;
};

export function CarPlate({
  country = VEHICLE_PLATE_COUNTRY,
  plate,
}: Props): ReactElement {
  return (
    <div className="car-plate">
      <div className="car-plate__country">{country}</div>
      <div className="car-plate__text">{plate}</div>
    </div>
  );
}
