import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { checkIsMobitBikeAvailable } from "services/mobitService";
import { MobitBikeLockStatus } from "./useCurrentBikeInfo";

export function useBikeLockUpdate(
  id: string | null,
  timeout = 5000,
  status?: MobitBikeLockStatus
) {
  const history = useHistory();

  const [pending, setPending] = useState(true);

  const [pollingId, setPollingId] = useState<number | null>(null);

  const [initialStatus, setInitialStatus] = useState<
    MobitBikeLockStatus | undefined
  >(status);

  useEffect(() => {
    let timeoutId: number;

    let canceled = false;

    async function fetchBikeInfo(id: string) {
      try {
        const response = await checkIsMobitBikeAvailable(id, history);

        if (!response.error) {
          const { lockStatus } = response.data;

          const isPending =
            initialStatus === undefined || lockStatus === initialStatus;

          if (!canceled) {
            setPending(isPending);

            initialStatus === undefined && setInitialStatus(lockStatus);

            if (isPending) {
              timeoutId = window.setTimeout(() => {
                // trigger rerunning the hook
                setPollingId(timeoutId);
              }, timeout);
            } else {
              console.log(
                "Bike lock status updated",
                initialStatus,
                lockStatus
              );
            }
          }
        } else {
          !canceled && setPending(false);

          console.error(
            "Failed to update bike lock status",
            response.error_description
          );
        }
      } catch (error) {
        !canceled && setPending(false);

        console.error("Failed to update bike lock status", error);
      }
    }

    id && fetchBikeInfo(id);

    return () => {
      canceled = true;

      timeoutId && window.clearTimeout(timeoutId);

      pending && console.debug("Canceled hook useBikeLockUpdate");
    };
  }, [pollingId, timeout, pending, id, initialStatus, history]);

  return [pending] as const;
}
