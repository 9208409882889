import { SpendingTrackingData, trackTicketPurchase } from "analytics/events";
import { useLoading } from "common/hooks/useLoading";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { orderMobitDayPass } from "services/mobitService";
import {
  addActiveTravelPassAction,
  addPurchaseAction,
  MobitTicketStatus,
  MobitTravelPass,
} from "state/actions";
import { TravelPassProvider, TravelPassType, WalletType } from "utils";

export interface MobitOrderPayload {
  price: number;
  duration: number;
  expiration: number;
  wallet: WalletType;
}

export function useMobitOrder() {
  const dispatch = useDispatch();

  const history = useHistory();

  const [result, setResult] = useState<any | null>(null);

  const [failed, setFailed] = useState(false);

  const [failureCode, setFailureCode] = useState("");

  const [loading, setLoading, canceledRef] = useLoading("mobitOrder");

  const order = useCallback(
    (id: number | null, orderPayload: MobitOrderPayload) => {
      async function orderMobit() {
        const trackingPayload: SpendingTrackingData = {
          action: TravelPassProvider.mobit,
          label: "success",
          value: orderPayload.price.toString(),
        };

        try {
          setLoading(true);

          const orderJson = await orderMobitDayPass(
            history,
            orderPayload.wallet
          );

          if (!orderJson.error) {
            const ticketOverview: MobitTravelPass = {
              id: orderJson.transaction_id,
              price: orderPayload.price,
              plan: orderPayload.wallet,
              expiration: orderPayload.expiration,
              orderTimestamp: Date.now(),
              duration: orderPayload.duration,
              status: MobitTicketStatus.NOT_USED,
              provider: TravelPassProvider.mobit,
              fine: 0,
              types: [TravelPassType.Bike],
            };

            trackTicketPurchase(trackingPayload);

            // dispatch even if navigated out
            dispatch(addActiveTravelPassAction(ticketOverview));

            // handle case when app goes offline after order so history is consistent
            dispatch(addPurchaseAction(ticketOverview));

            if (!canceledRef.current) {
              setResult(ticketOverview);
            }
          } else {
            trackingPayload.label = "failure";

            trackTicketPurchase(trackingPayload);

            if (!canceledRef.current) {
              setFailed(true);

              setFailureCode(orderJson.error_code);
            }
            console.error(
              "Failed to order Mobit ticket: ",
              orderJson.error_description
            );
          }
        } catch (error) {
          trackingPayload.label = "failure";

          trackTicketPurchase(trackingPayload);

          if (!canceledRef.current) {
            setFailed(true);
          }

          console.error("Failed to order Mobit ticket", error);
        } finally {
          setLoading(false);
        }
      }

      if (id !== null) {
        orderMobit();
      }
    },
    [canceledRef, dispatch, history, setLoading]
  );

  return [order, result, failed, loading, failureCode] as const;
}
