import styled from "@emotion/styled";
import { ReactNode } from "react";
import { AppColors, AppFonts } from "utils/colors";

const DescriptionStyled = styled.div({
  color: AppColors.GRAY_300,
  fontFamily: AppFonts.NUMBERS,
  fontSize: 14,
  lineHeight: "20px",
});

interface Props {
  icon: ReactNode;
  title: ReactNode;
  time: ReactNode;
  description: ReactNode;
  servicedBy?: ReactNode;
  children?: ReactNode;
}

export function TripSegmentTemplate({
  icon,
  title,
  time,
  description,
  servicedBy,
  children,
}: Props) {
  return (
    <div
      css={{
        display: "flex",
        alignItems: "flex-start",
        padding: "16px 0",
        borderBottom: `1px solid ${AppColors.BACKGROUND}`,
      }}
    >
      {icon}
      <div
        css={{
          flex: "1 0",
          marginTop: 4,
          marginLeft: 12,
        }}
      >
        <div
          className="title-time"
          css={{
            display: "flex",
            alignItems: "flex-start",
            fontFamily: AppFonts.NUMBERS,
            fontSize: 14,
            fontWeight: "bold",
            lineHeight: "20px",
          }}
        >
          <div
            className="title-time__title"
            css={{
              flex: "1 0",
              lineHeight: "18px",
            }}
          >
            {title}
          </div>
          <div
            className="title-time__time"
            css={{
              flexShrink: 0,
              marginLeft: 8,
            }}
          >
            {time}
          </div>
        </div>

        <DescriptionStyled>{description}</DescriptionStyled>

        {servicedBy && (
          <DescriptionStyled className="route-planner-trip-segment-detail__title-info__description">
            {servicedBy}
          </DescriptionStyled>
        )}

        {children}
      </div>
    </div>
  );
}
