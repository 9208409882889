import { useNetwork } from "common/hooks";
import { NoNetworkIcon } from "icons/NoNetworkIcon";
import { OfflineIcon } from "icons/OfflineIcon";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { NetworkState } from "utils/network";
import "./OfflineBanner.scss";

export function OfflineBanner(): ReactElement {
  const { t } = useTranslation();

  const network = useNetwork(true);

  return (
    <div
      className={`mobea__offline-banner ${
        network !== NetworkState.online ? "active" : ""
      }`}
    >
      {network === NetworkState.offline && (
        <div className="offline">
          <OfflineIcon className="mobea__offline-banner__icon" />

          <span className="mobea__offline-banner__text">
            {t("warning.you_are_offline")}
          </span>
        </div>
      )}

      {network === NetworkState.incident && (
        <div className="incident">
          <NoNetworkIcon className="mobea__offline-banner__icon" />

          <span className="mobea__offline-banner__text">
            {t("warning.cannot_connect_server")}
          </span>
        </div>
      )}
    </div>
  );
}
