import { ReactElement, ReactNode } from "react";
import "./LabelValue.scss";

export type LabelValueProps = {
  className?: string;
  icon?: ReactNode;
  label: string;
  value: ReactNode;
  onClick?(): void;
};

export function LabelValue({
  className = "",
  icon = null,
  label,
  value,
  onClick,
}: LabelValueProps): ReactElement {
  return (
    <div className={`mobea__label-value ${className}`} onClick={onClick}>
      <div className="mobea__label-value__icon-label">
        {icon && <span className="mobea__label-value__icon">{icon}</span>}
        <span className="mobea__label-value__label">{label}</span>
      </div>

      <div className="mobea__label-value__value">{value}</div>
    </div>
  );
}
