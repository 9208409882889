import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

export function InfoIcon({
  fill = "currentColor",
  className = "",
  height = "16",
  width = "16",
  onClick,
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M8 14.7c1.8 0 3.4-.7 4.7-2 1.3-1.3 2-2.9 2-4.7 0-1.8-.7-3.4-2-4.7a6.4 6.4 0 00-4.7-2c-1.8 0-3.4.7-4.7 2a6.4 6.4 0 00-2 4.7c0 1.8.7 3.4 2 4.7 1.3 1.3 2.9 2 4.7 2zm0-1.4c-1.5 0-2.7-.5-3.8-1.5-1-1-1.5-2.3-1.5-3.8s.5-2.7 1.5-3.8c1-1 2.3-1.5 3.8-1.5s2.7.5 3.8 1.5c1 1 1.5 2.3 1.5 3.8s-.5 2.7-1.5 3.8c-1 1-2.3 1.5-3.8 1.5zM8.7 6V4.7H7.3V6h1.4zm0 5.3v-4H7.3v4h1.4z"
        fill={fill}
        fillRule="nonzero"
      />
    </svg>
  );
}
