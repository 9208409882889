import { LocationPickerDialog, SuggestionStyled } from "common/search";
import { LocationCursorIcon } from "icons/navigation";
import { splitToHighlights } from "maps/mapUtils";
import { ReactElement, useState } from "react";
import { batch } from "react-redux";
import { NmbsStation } from "state/actions";
import { capitalizeString } from "utils";
import { filterStations, NmbsStationPayload, StationSuggestion } from "./utils";

export interface StationPickerProps {
  currentStation?: NmbsStationPayload;
  label: string;
  stations: NmbsStation[];
  noResultsText: string;
  startSearchingText: string;
  excluded?: NmbsStationPayload; // station to exclude from list as is already used for other end
  onSelect: (station: NmbsStationPayload) => void;
  onClose(): void;
}

export function StationPicker({
  currentStation,
  label,
  stations,
  noResultsText,
  startSearchingText,
  excluded = { id: NaN, label: "" },
  onSelect,
  onClose,
}: StationPickerProps): ReactElement {
  const [filteredResults, setFilteredResults] = useState<StationSuggestion[]>(
    () => {
      return currentStation
        ? filterStations(currentStation.label, stations, excluded)
        : [];
    }
  );

  const [searchText, setSearchText] = useState(
    currentStation ? currentStation.label : ""
  );

  const updateSearchText = (value: string) => {
    const filteredStations =
      value === "" ? [] : filterStations(value, stations, excluded);

    batch(() => {
      setSearchText(value);
      setFilteredResults(filteredStations);
    });
  };

  const toStationSuggestion = (suggestion: StationSuggestion) => (
    <SuggestionStyled
      key={`${suggestion.id}-${suggestion.label}`}
      className=""
      onClick={() =>
        onSelect({
          label: capitalizeString(suggestion.label),
          id: suggestion.id,
        })
      }
      css={{
        textTransform: "capitalize",
      }}
    >
      <LocationCursorIcon fill="currentColor" width={26} height={26} />
      <span>
        {splitToHighlights(suggestion.label, searchText, suggestion.highlights)}
      </span>
    </SuggestionStyled>
  );

  return (
    <LocationPickerDialog
      label={label}
      className=""
      searchText={searchText}
      allowMyLocation={false}
      onSearchTextChange={updateSearchText}
      onSelect={() => undefined}
      onClose={onClose}
      css={{
        input: {
          textTransform: "capitalize",
        },
      }}
    >
      <div
        css={{
          fontSize: "0.9375rem",
        }}
      >
        {filteredResults.length > 0 &&
          filteredResults.slice(0, 5).map(toStationSuggestion)}
        {searchText.length > 0 && filteredResults.length === 0 && (
          <p
            css={{
              textAlign: "left",
            }}
          >
            {noResultsText}
          </p>
        )}
        {searchText.length === 0 && (
          <p
            css={{
              textAlign: "left",
            }}
          >
            {startSearchingText}
          </p>
        )}
      </div>
    </LocationPickerDialog>
  );
}
