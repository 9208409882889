import { CalendarIcon } from "icons/CalendarIcon";
import { WalletIcon } from "icons/WalletIcon";
import { forwardRef, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { AppColors, AppFonts } from "utils/colors";

export interface WalletProps {
  totalBudget: string;

  validPeriod: string;
  children: ReactNode;
  theme: "verbose" | "brief";
  className?: string;
}

export const Wallet = forwardRef<HTMLElement, WalletProps>(
  ({ totalBudget, validPeriod, children, theme, className }, ref) => {
    const { t } = useTranslation();

    const color = theme === "verbose" ? AppColors.PRIMARY : "default";

    return (
      <section
        ref={ref}
        css={{
          display: "grid",
          gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
          gridTemplateRows: "repeat(2, minmax(0, 1fr))",
          gap: "1.5rem",
          margin: "0.5rem",
          paddingBottom: "1rem",
        }}
        className={className}
      >
        <div
          css={{
            gridRow: "span 2 / span 2",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            justifySelf: "end",
          }}
        >
          {children}
        </div>

        <div
          css={{
            alignSelf: "end",
            fontWeight: 700,
            color,
          }}
        >
          {theme === "verbose" && <WalletIcon css={{ opacity: 0.7 }} />}

          <div
            css={{
              fontSize: "1rem",
              lineHeight: 1.5,
              fontFamily: AppFonts.NUMBERS,
            }}
          >
            {totalBudget}
          </div>

          <div
            css={{
              fontSize: "0.75rem",
              lineHeight: 1.5,
              opacity: 0.7,
            }}
          >
            {t("home.total_budget")}
          </div>
        </div>

        <div css={{ alignSelf: "start", fontWeight: 700, color }}>
          {theme === "verbose" && <CalendarIcon css={{ opacity: 0.7 }} />}

          <div
            css={{
              fontSize: "1rem",
              lineHeight: 1.5,
              fontFamily: AppFonts.NUMBERS,
            }}
          >{`${validPeriod}`}</div>

          <div
            css={{
              fontSize: "0.75rem",
              lineHeight: 1.5,
              opacity: 0.7,
            }}
          >
            {t("home.valid_period")}
          </div>
        </div>
      </section>
    );
  }
);

Wallet.displayName = "Wallet";
