import { TravelPassProvider } from "utils/constants";

export const welcomeDoneAction = () => ({
  type: "WELCOME_DONE" as const,
});

export const tutorialFinishAction = () => ({
  type: "TUTORIAL_FINISHED" as const,
});

export const installNotificationCancelAction = () => ({
  type: "INSTALL_NOTIFICATION_CANCEL" as const,
});

export const verificationRequestedAction = () => ({
  type: "REQUESTED_VERIFICATION" as const,
});

export const verificationFinishedAction = () => ({
  type: "FINISHED_VERIFICATION" as const,
});

export const appLaunchTrackedAction = () => ({
  type: "APP_LAUNCH_TRACKED" as const,
});

export const cookiesAcceptAction = () => ({
  type: "COOKIES_ACCEPTED" as const,
});

export const analyticsChangeAction = (accepted: boolean) => ({
  type: "ANALYTICS_CHANGED" as const,
  accepted,
});

export const advertisingChangeAction = (accepted: boolean) => ({
  type: "ADVERTISING_CHANGED" as const,
  accepted,
});

export const mobitParkingZonesAcknowledgeAction = () => ({
  type: "MOBIT_PARKING_ZONES_ACKNOWLEDGED" as const,
});

export const expenseIbanDisplayedAction = () => ({
  type: "EXPENSE_IBAN_DISPLAYED" as const,
});

export const providerTutorialWatchedAction = (
  provider: TravelPassProvider
) => ({
  type: "PROVIDER_TUTORIAL_WATCHED" as const,
  provider,
});

export const termsAndPrivacyAcceptedAction = (accepted: boolean) => ({
  type: "TERMS_PRIVACY_ACCEPTED" as const,
  accepted,
});

export type OnboardingActions =
  | ReturnType<typeof welcomeDoneAction>
  | ReturnType<typeof tutorialFinishAction>
  | ReturnType<typeof installNotificationCancelAction>
  | ReturnType<typeof verificationRequestedAction>
  | ReturnType<typeof verificationFinishedAction>
  | ReturnType<typeof appLaunchTrackedAction>
  | ReturnType<typeof cookiesAcceptAction>
  | ReturnType<typeof analyticsChangeAction>
  | ReturnType<typeof advertisingChangeAction>
  | ReturnType<typeof mobitParkingZonesAcknowledgeAction>
  | ReturnType<typeof expenseIbanDisplayedAction>
  | ReturnType<typeof providerTutorialWatchedAction>
  | ReturnType<typeof termsAndPrivacyAcceptedAction>;
