import { DefaultRootState, useDispatch, useSelector } from "react-redux";

export function useReduxState<TSelected = unknown>(
  selector: (state: DefaultRootState) => TSelected,
  action: (updatedValue: TSelected) => void
) {
  const dispatch = useDispatch();

  const value = useSelector(selector);

  const setValue = (updatedValue: TSelected) => dispatch(action(updatedValue));

  return [value, setValue] as const;
}
