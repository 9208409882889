import { Action } from "state";
import { getInitialStateWithPersistance } from "utils/helpers";
import { MapState } from "./stateTypes";

const initialState: MapState = {
  searchLocation: null,
  previousSearchLocation: null,
  mapDisplayed: {
    budgetActive: false,
    budgetNotActiveYet: false,
    budgetExpired: false,
  },
  routePlannerVisible: false,
  routePlannerSearchResult: null,
  selectedRoutePlannerTrip: null,
};

export function MapReducer(
  state: MapState = getInitialStateWithPersistance("map", initialState),
  action: Action
): MapState {
  switch (action.type) {
    case "SET_MAP_PREVIOUS_SEARCH_LOCATION":
      return {
        ...state,
        previousSearchLocation: action.location,
      };

    case "MAP_MESSAGES_DISPLAYS":
      return {
        ...state,
        mapDisplayed: action.displays,
      };

    case "SET_ROUTE_PLANNER_VISIBLE":
      return {
        ...state,
        routePlannerVisible: action.visible,
      };

    case "SET_ROUTE_PLANNER_SEARCH":
      return {
        ...state,
        routePlannerSearchResult: action.search,
      };

    case "SET_SELECTED_ROUTE_PLAN_TRIP":
      return {
        ...state,
        selectedRoutePlannerTrip: action.trip,
      };

    case "UPDATE_SELECTED_ROUTE_PLAN_TRIP": {
      const search = state.routePlannerSearchResult;

      if (search && search.data) {
        const best = search.data.bestTrip;

        // patch instead of update to prevent un-necessary re-render as these data
        // are used only once we open trip detail
        search.data.bestTrip = best?.id === action.trip.id ? action.trip : best;
        search.data.groups = search.data.groups.map((group) => ({
          category: group.category,
          trips: group.trips.map((trip) => {
            if (trip.id === action.trip.id) {
              return action.trip;
            }
            return trip;
          }),
        }));
      }

      return {
        ...state,
        selectedRoutePlannerTrip: action.trip,
        routePlannerSearchResult: search,
      };
    }
    default:
      return state;
  }
}
