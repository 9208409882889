import { DefaultIconProps } from "icons/props";
import { ReactElement } from "react";

export function BirdLogo({
  className = "",
  width,
  height,
}: Pick<DefaultIconProps, "className" | "width" | "height">): ReactElement {
  return (
    <img
      width={width}
      height={height}
      className={className}
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAF4AAAAYCAMAAABEDbotAAAAilBMVEUAAAAzMzM3Nzc1NTUzMzMzMzMzMzM0NDQ0NDQ0NDQ0NDQzMzM6Ojo8PDwzMzMzMzMzMzM0NDQzMzMzMzM1NTU3Nzc0NDQ0NDQzMzM2NjY6OjozMzMzMzMzMzM0NDQ0NDQ1NTU0NDQ1NTU0NDRAQEBJSUkzMzMzMzMzMzM0NDQ0NDQ1NTU0NDQzMzMFYW0BAAAALXRSTlMAfSpmqvT5RB5f3XgOBOHWhDO0oyIZwZFQJRTozsjFWU1JOi8KB7mvm4pxaph9HbT+AAABsUlEQVRIx7WV2WKCMBBFgxIUZRFkkUVExb38/++VG1qBhsG89DwxueQQJ0HYf+PN3xxOS3sYYtTqql/Mo3ehLOZiZvUCre7D44D1wNCsq3rwW8lIS/FKRwPwUtEDPxzXA/7sBXwFHC4So1TRA9ci9CAK34HOWkLNwKwp/WLZcC7XGQp9oPeXYLaYXw2E+8tQD0wElqSXq0pHleBStiR7hFs5qPBgT0HPLKeptHE9s8XDT1JgYSdsFT3bYoGEnlUuUil4oodMSb9uqiulF1020kFgeTEGd2r6O7l6UHF0pw0MDtB2YVfS2/j5G1LP8mbElE9stFTTx6jOtN7Hzsv62ph/1NvBbo/iymh91Iw82yBaCI7mZoVZhwk9B3WLE07oYdpJwR3TbKU/hTxgtD4QvZOCi3ivVPToIa1/iGXKgYvu0Pp4DXDT15T+ZIidHV/98dPJOeOuktZ7GbYplYM7HOmEvntls5TQBw88HRv75+Ro4uQUCue+aK5vQ727AbGe1wKN+BA4oYI+4N3ujln4gQj8ROmtNeFICD3fhiNBlvsPzFDRsxu+SLZkMZxCN62f4W/IxYnsA2Tu2AAAAABJRU5ErkJggg=="
    />
  );
}
