import { History } from "history";
import { VeloTicketMetadata } from "services/veloService";
import {
  ADMIN_API_BASE,
  ApiErrorResponse,
  CORE_API_BASE,
  TravelPassProvider,
  WalletType,
} from "utils";
import { callSecuredApiEndpoint } from "./apiCall";
import { BlueBikeMetadata } from "./blueBikeService";
import { RawDeLijnSingleTicket } from "./delijnService";
import { fetchTimeout, fetchTimeoutIncident } from "./fetchTimeout";
import { MobitTicketMetadata } from "./mobitService";
import { RawNmbsTicket } from "./nmbsService";
import { RawProviderData } from "./providerService";
import { RawVertsTicket, VertsTicketMetadata } from "./vertsService";

export interface RawTravelPass {
  amount: string;
  created: string; // date time string
  customer: string;
  expire_at: string; // date time string
  id: string; // uuid
  provider: RawProviderData;
  response:
    | RawDeLijnSingleTicket
    | RawNmbsTicket
    | RawVertsTicket
    | VeloTicketMetadata
    | BlueBikeMetadata;
  mobit_detail?: MobitTicketMetadata;
  taxi_vert_detail?: VertsTicketMetadata;
  velo_detail?: VeloTicketMetadata;
  status?: "FINALISED" | "CANCELED";
  plan: WalletType;
}

export type TravelPassServiceResponse =
  | {
      error: false;
      active: RawTravelPass[];
    }
  | ApiErrorResponse;

const getTravelPassesRequest =
  (filter: "active" | "all") => (authorization: string) =>
    fetchTimeout(
      filter === "active"
        ? `${CORE_API_BASE}active-orders`
        : `${CORE_API_BASE}orders`,
      {
        method: "GET",
        headers: new window.Headers({
          Authorization: authorization,
        }),
      }
    ).then((response) => response.json());

export function getTravelPasses(filter: "active" | "all", history: History) {
  return callSecuredApiEndpoint(getTravelPassesRequest(filter), history).then(
    (json) => {
      if (json.error_code) {
        return {
          error: true,
          ...json,
        };
      }
      return {
        error: false,
        active: json,
      };
    }
  ) as Promise<TravelPassServiceResponse>;
}

export type PendingOrderResponse =
  | {
      error: false;
      pending: boolean;
    }
  | ApiErrorResponse;

const hasPendingOrderRequest =
  (provider: TravelPassProvider) => (authorization: string) =>
    fetchTimeout(`${ADMIN_API_BASE}${provider}/pending-transaction`, {
      method: "GET",
      headers: new window.Headers({
        Authorization: authorization,
      }),
      // polled every 5 seconds
      timeout: 4500,
    }).then((response) => response.json()) as Promise<PendingOrderResponse>;

export function hasPendingOrder(
  provider: TravelPassProvider,
  history: History
) {
  return callSecuredApiEndpoint(hasPendingOrderRequest(provider), history);
}

export type GetDetailResponse =
  | ({ error: false } & RawTravelPass)
  | ApiErrorResponse;

function getTicketDetailRequest(id: string) {
  return (authorization: string) =>
    fetchTimeoutIncident<GetDetailResponse>(`${CORE_API_BASE}orders/${id}`, {
      method: "GET",
      headers: new window.Headers({
        Authorization: authorization,
      }),
    });
}

export function getTicketDetail(id: string, history: History) {
  return callSecuredApiEndpoint(getTicketDetailRequest(id), history);
}
