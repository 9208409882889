import { MobeaModal } from "common/modal";
import { LocationCursorIcon } from "icons/navigation";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { AppColors } from "utils/colors";

type Props = {
  hide(): void;
  showClickPinMessage?: boolean;
};

export function LocationAccessDeniedDialog({
  hide,
  showClickPinMessage = false,
}: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <MobeaModal
      title={t("map.enable_your_location")}
      confirmText={t("shared.ok")}
      onConfirm={hide}
      usePortal
    >
      <LocationCursorIcon
        fill={AppColors.GRAY_100}
        width="80px"
        height="80px"
      />

      <p>
        {t("map.enable_your_location_message")}{" "}
        {showClickPinMessage && t("map.click_pin_to_enable")}
      </p>
    </MobeaModal>
  );
}
