import { NestedPageProps } from "./NestedPage";
import { NestedPageFull } from "./NestedPageFull";

export function NestedPageOverlay(props: NestedPageProps) {
  return (
    <NestedPageFull
      {...props}
      css={{
        position: "fixed",
        zIndex: 1000,
        animation: "fadeIn 0.2s",
      }}
    />
  );
}
