import { MobeaButton } from "common/forms";
import { useRefundConfirmWalletModal } from "common/hooks/useRefundConfirmWalletModal";
import { useTranslation } from "react-i18next";
import { TravelPassProvider } from "utils";
import { AppColors } from "utils/colors";

export function OtherProvider() {
  const { t } = useTranslation();

  const { show: showConfirmDialog } = useRefundConfirmWalletModal(
    TravelPassProvider.other
  );

  return (
    <section
      css={{
        marginTop: 24,
        borderTop: `2px solid ${AppColors.GRAY_100}`,
        padding: "16px 24px 0",
        textAlign: "center",
      }}
    >
      <h2
        css={{
          margin: 0,
          marginBottom: 4,
          fontWeight: "bold",
          fontSize: "1rem",
          lineHeight: 1.5,
        }}
      >
        {t("providers.not_on_list")}
      </h2>
      <p
        css={{
          margin: 0,
          fontSize: "0.8125rem",
          lineHeight: "1rem",
          marginBottom: 16,
          color: AppColors.GRAY_300,
        }}
      >
        {t("providers.not_on_list_text")}
      </p>
      <MobeaButton onClick={showConfirmDialog}>
        {t("providers.ask_refund_here")}
      </MobeaButton>
    </section>
  );
}
