import { cx } from "@emotion/css";
import { ReactElement, ReactNode } from "react";

export interface SettingsItemProps {
  icon: ReactNode;
  label: ReactNode;
  className?: string;
  children: ReactNode;
}

export function SettingsItem({
  icon,
  label,
  children,
  className,
}: SettingsItemProps): ReactElement {
  return (
    <div className={cx("mobea__settings__section__item", className)}>
      <div className="mobea__settings__section__item__icon">
        <div className="mobea__settings__section__item__icon__bg"></div>
        {icon}
      </div>
      <h3 className="mobea__settings__section__item__text">{label}</h3>
      <div className="mobea__settings__section__item__action">{children}</div>
    </div>
  );
}
