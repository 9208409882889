import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

export function SadIcon({
  fill = "currentColor",
  className = "",
  height = "24",
  width = "24",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 80 80"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={fill}>
        <circle cx="12" cy="12" r="12" />
        <circle cx="68" cy="12" r="12" />
        <path d="M40 46.9A42.54 42.54 0 000 75h13.71A30 30 0 0140 60a30 30 0 0126.3 15.53H80A42.54 42.54 0 0040 46.9z" />
      </g>
    </svg>
  );
}
