import { useModal } from "common/hooks/useModal";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  BUDGET_COOLDOWN_PERIOD_DAYS,
  pushLocation,
  Routes,
  TravelPassProvider,
} from "utils";

export function useRefundConfirmWalletModal(provider?: TravelPassProvider) {
  const { t } = useTranslation();

  const history = useHistory();

  const { amount, outOfBudgetPeriod, remainingDays } = useSelector(
    (state) => state.user
  );

  const canRefund =
    amount > 0 &&
    (!outOfBudgetPeriod || -remainingDays < BUDGET_COOLDOWN_PERIOD_DAYS);

  const confirmModal = useModal({
    type: canRefund ? "secondary" : "error",
    title: canRefund ? t("shared.refund") : t("shared.sorry_but"),
    confirmText: canRefund ? t("shared.continue") : t("wallet.open"),
    cancelButtonType: "secondary",
    onConfirm() {
      pushLocation(
        history,
        !canRefund
          ? Routes.MyWallets
          : provider
          ? Routes.ExpenseAdd.replace(":provider", provider)
          : Routes.ExpenseAddEmpty
      );
    },
    onCancel() {
      // nothing
    },
    children: canRefund ? (
      <Trans i18nKey="warning.refund_wallet" />
    ) : (
      t("wallet.cant_refund")
    ),
  });

  return confirmModal;
}
