import { ReactElement } from "react";
import "./VertsTaxi.scss";

type Props = {
  color: string;
  model: string;
};

export function VertsTaxi({ color, model }: Props): ReactElement {
  return (
    <div className="verts-taxi">
      <div className="verts-taxi__color" style={{ backgroundColor: color }} />
      <div className="verts-taxi__model">{model}</div>
    </div>
  );
}
