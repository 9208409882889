import { MobeaButton } from "common/forms";
import { useBooleanState } from "common/hooks";
import { useSelectedWallet } from "common/hooks/useSelectedWallet";
import { MobeaModal } from "common/modal";
import { BottomSlider, BottomSliderRef } from "common/navigation";
import { SourceDestPicker } from "common/routePicker/SourceDestPicker";
import { Spinner } from "common/Spinner";
import { Steps } from "common/steps/Steps";
import { ClockIcon } from "icons/ClockIcon";
import { inSameLocation, ReverseGeocodeResult } from "maps";
import { TravelTime } from "pages/map/TravelTime";
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  getLocationSearchPlace,
  LocationSearchPlace,
  LocationSearchPlaceFull,
  LocationSearchPlaceWithId,
  toLocationAddress,
  TravelTimeOption,
} from "services/mapService";
import { VertsPackage } from "services/vertsService";
import { setVertsLastLocationsAction } from "state/actions";
import {
  ApiErrors,
  ApplicationVariant,
  formatCurrencyCustom,
  getSearchParams,
  LocationChangeState,
  pushLocation,
  Routes,
  TravelPassProvider,
  WalletType,
} from "utils";
import { AppColors } from "utils/colors";
import { applicationVariant } from "utils/configure";
import { useVertsGetPackages } from "../hooks/useVertsGetPackages";
import { useVertsOrder } from "../hooks/useVertsOrder";
import { VertsPackageTile } from "../package/VertsPackageTile";
import { VertsOrderSummary } from "../summary/VertsOrderSummary";
import "./VertsOrderBottomSlider.scss";

const locationWithAddress = (
  location: LocationSearchPlaceWithId | LocationSearchPlaceFull
): location is LocationSearchPlaceFull =>
  "address" in location && typeof location.address === "object";

interface VertsOrderBottomSliderProps {
  onSourceChange?: (source: LocationSearchPlace | null) => void;
  onDestinationChange?: (destination: LocationSearchPlace | null) => void;
}

const VERTS_ORDER_STEPS_COUNT = 3;
const HEADER_HEIGHT = 100;
const DEFAULT_SLIDER_HEIGHT = 430;

const dt = (value: string) => value;

export function VertsOrderBottomSlider({
  onSourceChange,
  onDestinationChange,
}: VertsOrderBottomSliderProps): ReactElement {
  const { t } = useTranslation();

  const history = useHistory();

  const dispatch = useDispatch();

  const queryParams = getSearchParams(location.search);

  const originParam = queryParams.get("origin");

  const destinationParam = queryParams.get("destination");

  const { state } = useLocation<LocationChangeState>();
  const departureParam = queryParams.get("departure");

  const selectedWallet = useSelectedWallet();

  const { language } = useSelector((state) => state.user);

  const canChooseDepartureTime =
    applicationVariant === ApplicationVariant.MOVEASY;

  const allProviders = useSelector((state) => state.passes.providers);

  const bottomSliderRef = useRef<BottomSliderRef>(null);

  const [currentStep, setCurrentStep] = useState(0);

  const [pickerOpened, setPickerOpened] = useState(false);

  const [dateTimeInfoVisible, showDateTimeInfo, hideDateTimeInfo] =
    useBooleanState();

  const [closeConfirmDialogVisible, setCloseConfirmDialogVisible] =
    useState(false);

  const [source, setSource] = useState<LocationSearchPlaceFull | null>(null);

  const [destination, setDestination] =
    useState<LocationSearchPlaceWithId | null>(null);

  const [loadVertsPackages, packages, loadingPackages, packagesError] =
    useVertsGetPackages();

  const [selectedPackage, setSelectedPackage] = useState<VertsPackage | null>(
    null
  );

  const [loadingSource, setLoadingSource] = useState(false);

  const platformRef = useRef<H.service.Platform | null>(
    new H.service.Platform({
      apikey: process.env.HERE_API_KEY,
    })
  );

  const [
    orderConfirmDialogVisible,
    showOrderConfirmDialog,
    hideOrderConfirmDialog,
  ] = useBooleanState();

  const [orderVertsDrive, orderResult, ordering, orderError] = useVertsOrder();

  const [errorDialogVisible, showErrorDialog, hideErrorDialog] =
    useBooleanState();

  const minPrice =
    allProviders.find(
      (provider) => provider.provider === TravelPassProvider.verts
    )?.minPrice || 0;

  const findTaxiDisabled = !selectedPackage || ordering;

  const reverseGeocode = async (
    lat: number,
    lng: number
  ): Promise<LocationSearchPlaceFull | null> => {
    //@ts-ignore
    const service = platformRef.current.getSearchService();

    return new Promise((resolve, reject) => {
      service.reverseGeocode(
        {
          at: `${lat},${lng}`,
        },
        (result: ReverseGeocodeResult) => {
          if (result.items.length) {
            const item = result.items[0];

            resolve({
              id: item.id,
              label: item.address.label,
              address: toLocationAddress(item),
              ...item.position,
            });
          } else {
            resolve(null);
          }
        },
        (error) => reject(error)
      );
    });
  };

  const getLocationAddress = useCallback(async (id: string) => {
    const response = await getLocationSearchPlace(id, history);

    if (response.error) {
      return null;
    }

    return toLocationAddress(response.data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sourceChanged = useCallback(
    async (
      location: LocationSearchPlaceWithId | LocationSearchPlaceFull | null
    ) => {
      if (location) {
        if (locationWithAddress(location)) {
          setSource(location);
        } else {
          setLoadingSource(true);

          const address = await getLocationAddress(location.id);

          if (address) {
            const locationWithAddress = {
              ...location,
              // provide full address instead of title
              label: address.label,
              address,
            };
            setSource(locationWithAddress);
          } else {
            // @TODO error when not possible to get address
          }
          setLoadingSource(false);
        }
      } else {
        setSource(location);
      }

      onSourceChange?.(location);
    },
    [getLocationAddress, onSourceChange]
  );

  useEffect(() => {
    if (state.source) {
      sourceChanged({
        ...(state.source as LocationSearchPlaceWithId),
      });
    } else {
      (async () => {
        if (originParam) {
          const [lat, lng] = originParam.split(",");
          setLoadingSource(true);

          const location = await reverseGeocode(lat, lng);

          setLoadingSource(false);

          if (location) {
            // keep original location
            // as one from rev geocode can be offset a bit
            location.lat = lat;
            location.lng = lng;

            setSource(location);
            onSourceChange?.(location);
          }
        }
      })();
    }
  }, [onSourceChange, originParam, sourceChanged, state.source]);

  const destinationChanged = useCallback(
    (location: LocationSearchPlaceWithId | null) => {
      setDestination(location);
      onDestinationChange && onDestinationChange(location);
    },
    [onDestinationChange]
  );

  useEffect(() => {
    if (state.destination) {
      destinationChanged({
        ...(state.destination as LocationSearchPlaceWithId),
      });
    } else {
      (async () => {
        if (destinationParam) {
          const [lat, lng] = destinationParam.split(",");

          const location = await reverseGeocode(lat, lng);

          if (location) {
            setDestination(location);
            onDestinationChange?.(location);
          }
        }
      })();
    }
  }, [
    destinationChanged,
    destinationParam,
    onDestinationChange,
    state.destination,
  ]);

  // hide info message after few seconds
  useEffect(() => {
    if (!dateTimeInfoVisible) {
      return;
    }

    const timeoutHandle = window.setTimeout(() => {
      hideDateTimeInfo();
    }, 5000);

    return () => {
      window.clearTimeout(timeoutHandle);
    };
  }, [dateTimeInfoVisible, hideDateTimeInfo]);

  useEffect(() => {
    if (packagesError || orderError) {
      showErrorDialog();
    }
  }, [packagesError, orderError, showErrorDialog]);

  useEffect(() => {
    if (orderResult) {
      pushLocation(
        history,
        Routes.VertsTicketDetail.replace(
          ":id",
          encodeURIComponent(orderResult.id)
        ) + "?back=goHome"
      );
    }
  }, [history, orderResult]);

  const onPickerToggle = (opened: boolean) => setPickerOpened(opened);

  const calculatePrice = () => {
    if (source && destination) {
      loadVertsPackages(source, destination, selectedTravelTimeDate);
      setCurrentStep(1);
    }
  };

  const changeDestination = () => {
    setCurrentStep(0);
    hideErrorDialog();
  };

  const showSummary = () => {
    setCurrentStep(2);
  };

  const goToPreviousStep = () => {
    if (currentStep === 1) {
      changeDestination();

      setSelectedPackage(null);

      return;
    }

    setCurrentStep(1);

    setSelectedPackage(null);
  };

  const orderTaxi = () => {
    hideOrderConfirmDialog();

    if (!source || !destination || !selectedPackage) {
      return;
    }

    orderVertsDrive({
      price: selectedPackage.minRate,
      source,
      destination,
      packageId: selectedPackage.id,
      estimationId: selectedPackage.estimationId,
      wallet: selectedWallet ?? WalletType.BUSINESS,
      requestedAt: selectedTravelTimeDate,
    });

    dispatch(setVertsLastLocationsAction(source, destination));
  };

  const showTicketsPage = () => {
    pushLocation(history, Routes.GetTravelPass);
  };

  const title = useMemo(() => {
    switch (currentStep) {
      case 0:
        return t("verts_order.where_to_go");
      case 1:
        return t("verts_order.vehicle_options");
      case 2:
        return t("verts_order.order_taxi_verts");
      default:
        return t("verts_order.where_to_go");
    }
  }, [currentStep, t]);

  const formatRate = (rate: number) =>
    formatCurrencyCustom(rate, language, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

  const minRate =
    selectedPackage && typeof selectedPackage.minRate === "number"
      ? formatRate(selectedPackage.minRate)
      : "";

  const maxRate =
    selectedPackage && typeof selectedPackage.maxRate === "number"
      ? formatRate(selectedPackage.maxRate)
      : "";

  const [selectedTravelTimeDate, setSelectedTravelTimeDate] = useState<
    Date | undefined
  >(
    canChooseDepartureTime && departureParam
      ? new Date(parseInt(departureParam))
      : undefined
  );

  const [selectedTravelTimeOption, setSelectedTravelTimeOption] = useState(
    canChooseDepartureTime && departureParam
      ? TravelTimeOption.LeaveAt
      : TravelTimeOption.LeaveNow
  );

  const travelTimeChanged = (value: TravelTimeOption, dateTime: Date) => {
    setSelectedTravelTimeOption(value);

    setSelectedTravelTimeDate(
      value === TravelTimeOption.LeaveNow ? undefined : dateTime
    );
  };

  return (
    <>
      <BottomSlider
        ref={bottomSliderRef}
        className="mobea__verts-order-slider"
        title={title}
        hidden={false}
        defaultOpen
        onBack={currentStep > 0 ? goToPreviousStep : undefined}
        onClose={() => setCloseConfirmDialogVisible(true)}
        visibleHeight={70}
      >
        <div
          className="mobea__verts-order-slider__scroll-container"
          style={{
            maxHeight: window.innerHeight - HEADER_HEIGHT,
            minHeight: pickerOpened
              ? window.innerHeight - HEADER_HEIGHT
              : DEFAULT_SLIDER_HEIGHT,
          }}
        >
          <Steps
            currentStep={currentStep}
            stepCount={VERTS_ORDER_STEPS_COUNT}
          />
          {currentStep === 0 && (
            <>
              <div className="route-selection">
                <SourceDestPicker
                  withId
                  source={source}
                  destination={destination}
                  sourceLabel={t("verts_order.origin")}
                  destinationLabel={t("verts_order.destination")}
                  sameLocationError={t("verts_order.same_destination_error")}
                  filterOutLocalities
                  sourceNumberError={
                    source
                      ? typeof source.address.houseNumber !== "string"
                      : false
                  }
                  loadingSource={loadingSource}
                  onSourceChange={sourceChanged}
                  onDestinationChange={destinationChanged}
                  onPickerToggle={onPickerToggle}
                />

                <TravelTime
                  availableOptions={[
                    TravelTimeOption.LeaveAt,
                    TravelTimeOption.LeaveNow,
                  ]}
                  value={selectedTravelTimeOption}
                  dateTime={selectedTravelTimeDate ?? new Date()}
                  disabled={!canChooseDepartureTime}
                  onChange={
                    canChooseDepartureTime ? travelTimeChanged : undefined
                  }
                  onClick={
                    canChooseDepartureTime ? undefined : showDateTimeInfo
                  }
                />
              </div>
              <div
                className={`mobea__verts-order-slider__bottom-section step-${currentStep}`}
              >
                <div className="mobea__verts-order-slider__bottom-section__message-area">
                  {dateTimeInfoVisible && (
                    <div className="mobea__verts-order-slider__bottom-section__message-area__info">
                      <ClockIcon width={24} height={24} fill={AppColors.BLUE} />
                      {t("verts_order.set_date_info")}
                    </div>
                  )}
                </div>
              </div>

              <div className="mobea__verts-order-slider__button">
                <MobeaButton
                  className="route-planner__search-button"
                  onClick={calculatePrice}
                  disabled={
                    !source ||
                    !destination ||
                    inSameLocation([source, destination])
                  }
                >
                  {t("verts_order.calculate_price")}
                </MobeaButton>
              </div>
            </>
          )}

          {currentStep === 1 && (
            <>
              <div
                className={`mobea__verts-order-slider__bottom-section step-${currentStep}`}
              >
                <div className="mobea__verts-order-slider__bottom-section__vehicle-options">
                  {loadingPackages && (
                    <div className="mobea__verts-order-slider__bottom-section__vehicle-options__spinner">
                      <Spinner />
                    </div>
                  )}

                  {!loadingPackages && !packagesError && (
                    <>
                      <div className="mobea__verts-order-slider__bottom-section__vehicle-options__packages">
                        {packages.map((vertsPackage) => (
                          <VertsPackageTile
                            key={vertsPackage.id}
                            language={language}
                            data={vertsPackage}
                            selected={selectedPackage?.id === vertsPackage.id}
                            onSelect={() => setSelectedPackage(vertsPackage)}
                          />
                        ))}
                      </div>

                      <div className="mobea__verts-order-slider__bottom-section__vehicle-options__estimation-info">
                        *{t("verts_order.estimation_info")}
                        {selectedPackage && selectedPackage.minRate < minPrice && (
                          <>
                            <br />
                            <Trans i18nKey="verts_order.estimation_info_min_price">
                              {{ price: minPrice }}
                            </Trans>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="mobea__verts-order-slider__button">
                <MobeaButton
                  onClick={showSummary}
                  disabled={selectedPackage === null}
                  loading={ordering}
                >
                  {t("shared.continue")}
                </MobeaButton>
              </div>
            </>
          )}

          {currentStep === 2 && selectedPackage && (
            <>
              <div
                className={`mobea__verts-order-slider__bottom-section step-${currentStep}`}
              >
                <VertsOrderSummary
                  selectedPackage={selectedPackage}
                  source={source?.label || ""}
                  destination={destination?.label || ""}
                  requestedAt={selectedTravelTimeDate}
                />
              </div>

              <div className="mobea__verts-order-slider__button">
                {selectedWallet && (
                  <div className="mobea__verts-order-slider__button__price">
                    <span className="mobea__verts-order-slider__button__price__label">
                      {t("wallet.wallet")}
                    </span>
                    <span className="mobea__verts-order-slider__button__price__value">
                      {t(`wallet.${selectedWallet}`)}
                    </span>
                  </div>
                )}

                <div className="mobea__verts-order-slider__button__price">
                  <span className="mobea__verts-order-slider__button__price__label">
                    {t("verts_order.estimated_price")}
                  </span>
                  <span className="mobea__verts-order-slider__button__price__value">
                    € {minRate !== maxRate ? `${minRate} - ` : ""}
                    {maxRate}
                  </span>
                </div>

                <MobeaButton
                  onClick={showOrderConfirmDialog}
                  disabled={findTaxiDisabled}
                  loading={ordering}
                >
                  {ordering
                    ? t("shared.in_progress")
                    : t("verts_order.find_taxi")}
                </MobeaButton>
              </div>
            </>
          )}
        </div>
      </BottomSlider>

      {closeConfirmDialogVisible && (
        <MobeaModal
          title={t("shared.leave_confirm")}
          confirmText={t("shared.no")}
          cancelText={t("shared.yes_leave")}
          onCancel={() => {
            setCloseConfirmDialogVisible(false);
            history.goBack();
          }}
          onConfirm={() => setCloseConfirmDialogVisible(false)}
        >
          <p>{t("verts_order.leave_confirm")}</p>
        </MobeaModal>
      )}

      {orderConfirmDialogVisible && (
        <MobeaModal
          title={t("verts_order.order_confirm_title")}
          confirmText={t("verts_order.order_taxi")}
          cancelText={t("shared.no")}
          onCancel={hideOrderConfirmDialog}
          onConfirm={orderTaxi}
        >
          <Trans
            i18nKey={
              selectedPackage && selectedPackage.minRate < minPrice
                ? dt("verts_order.order_confirm_text_min_price")
                : dt("verts_order.order_confirm_text")
            }
          >
            <span className="mobea__arial" />
            {{ price: minPrice }}
          </Trans>
        </MobeaModal>
      )}

      {errorDialogVisible && (
        <>
          {packagesError === ApiErrors.VERTS_JOURNEY_NOT_POSSIBLE && (
            <MobeaModal
              type="error"
              title={t("verts_order.journey_not_possible_title")}
              confirmText={t("shared.understand")}
              onConfirm={changeDestination}
            >
              <p>{t("verts_order.journey_not_possible_text")}</p>
            </MobeaModal>
          )}

          {packagesError !== ApiErrors.VERTS_JOURNEY_NOT_POSSIBLE && (
            <MobeaModal
              type="error"
              title={
                orderError
                  ? t("verts_order.order_cannot_be_placed")
                  : t("shared.oops")
              }
              cancelText={t("verts_order.switch_providers")}
              onCancel={orderError ? showTicketsPage : undefined}
              cancelButtonType="secondary"
              confirmText={
                orderError
                  ? t("verts_order.change_the_destination")
                  : t("shared.ok")
              }
              onConfirm={changeDestination}
            >
              <p>
                {orderError === ApiErrors.LOW_BUDGET &&
                  t("verts_order.error_insufficient_budget")}
                {(packagesError || orderError !== ApiErrors.LOW_BUDGET) &&
                  t("verts_order.error")}
              </p>
            </MobeaModal>
          )}
        </>
      )}
    </>
  );
}
