import { TravelTypeIcon } from "common/travelPasses/components/TravelTypeIcon";
import { HailIcon } from "icons/transportTypes/HailIcon";
import { WaitIcon } from "icons/transportTypes/WaitIcon";
import { WalkIcon } from "icons/WalkIcon";
import {
  LocationCoords,
  RoutePlanSegmentInfo,
  RoutePlanTripSegment,
  SegmentType,
} from "services/mapService";
import { ProviderToTypeMappingType, TravelPassType } from "utils/constants";

export function getRoutePlannerTravelTypeIcon(
  segment: RoutePlanSegmentInfo | null,
  providerToTypeMapping: ProviderToTypeMappingType
) {
  if (segment) {
    switch (segment.type) {
      case SegmentType.Bicycle:
      case SegmentType.BicycleShare:
      case SegmentType.BicycleElectricShare:
        return (
          <TravelTypeIcon type={TravelPassType.Bike} height={16} width={16} />
        );
      case SegmentType.Bus:
        return (
          <TravelTypeIcon type={TravelPassType.Bus} height={16} width={16} />
        );
      case SegmentType.Car:
      case SegmentType.CarShare:
        return (
          <TravelTypeIcon type={TravelPassType.Car} height={16} width={16} />
        );
      case SegmentType.Taxi:
        return (
          <TravelTypeIcon type={TravelPassType.Taxi} height={16} width={16} />
        );
      case SegmentType.Train:
        return (
          <TravelTypeIcon type={TravelPassType.Train} height={16} width={16} />
        );
      case SegmentType.Tram:
        return (
          <TravelTypeIcon type={TravelPassType.Tram} height={16} width={16} />
        );
      case SegmentType.Subway:
        return (
          <TravelTypeIcon type={TravelPassType.Metro} height={16} width={16} />
        );
      case SegmentType.TaxiWait:
        return <HailIcon width={16} height={16} />;
      case SegmentType.Transfer:
        return <WaitIcon width={16} height={16} />;
      case SegmentType.Walk:
        return <WalkIcon fill="currentColor" height={16} width={16} />;
      default:
        const provider = segment.providers[0];

        if (provider) {
          const transportType = providerToTypeMapping[provider.type];

          if (!transportType) {
            console.warn(`Provider not found: ${provider}`);

            return null;
          }
          return (
            <TravelTypeIcon type={transportType[0]} width={16} height={16} />
          );
        }

        console.warn(`Unknown iconType: ${segment.type}`);
        return null;
    }
  } else {
    return null;
  }
}

export function getTotalDistance(
  segments: RoutePlanTripSegment[]
): number | undefined {
  return segments.reduce(
    (total, segment) =>
      segment.distance === undefined || total === undefined
        ? undefined
        : total + segment.distance,
    0 as number | undefined
  );
}

// https://developers.google.com/maps/documentation/utilities/polylinealgorithm
// source: https://gist.github.com/ismaels/6636986
export function decodeWaypoints(encodedWaypoints: string): LocationCoords[] {
  const points = [] as LocationCoords[];

  let index = 0;

  let lat = 0;

  let lng = 0;

  while (index < encodedWaypoints.length) {
    let b: number;

    let shift = 0;

    let result = 0;

    do {
      b = encodedWaypoints.charAt(index++).charCodeAt(0) - 63;

      result |= (b & 0x1f) << shift;

      shift += 5;
    } while (b >= 0x20);

    const dlat = (result & 1) != 0 ? ~(result >> 1) : result >> 1;

    lat += dlat;

    shift = 0;

    result = 0;

    do {
      b = encodedWaypoints.charAt(index++).charCodeAt(0) - 63;

      result |= (b & 0x1f) << shift;

      shift += 5;
    } while (b >= 0x20);

    const dlng = (result & 1) != 0 ? ~(result >> 1) : result >> 1;

    lng += dlng;

    points.push({
      lat: lat / 1e5,
      lng: lng / 1e5,
    });
  }

  return points;
}
