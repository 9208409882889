import { MobeaButton } from "common/forms";
import { useNetwork } from "common/hooks";
import { NoNetworkIcon } from "icons/NoNetworkIcon";
import { OfflineIcon } from "icons/OfflineIcon";
import { ReactElement, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { NetworkState } from "utils/network";
import "./MapOfflineOverlay.scss";

interface MapOfflineOverlayProps {
  title?: ReactNode;
  shadow?: boolean;
}

export function MapOfflineOverlay({
  title,
  shadow = false,
}: MapOfflineOverlayProps): ReactElement {
  const { t } = useTranslation();

  const network = useNetwork();

  const opened = network !== NetworkState.online;

  return (
    <div
      className={`mobea__map-offline ${opened ? "opened" : ""} ${
        shadow ? "shadow" : ""
      } ${NetworkState[network]}`}
    >
      {title && (
        <header className="mobea__map-offline__header">
          <h1>{title}</h1>
        </header>
      )}

      <section className="mobea__map-offline__content">
        <div className="mobea__map-offline__content__icon">
          {network === NetworkState.offline && <OfflineIcon />}
          {network === NetworkState.incident && <NoNetworkIcon />}
        </div>

        <h2 className="mobea__map-offline__content__headline">
          {t("warning.no_connection")}
        </h2>

        <p className="mobea__map-offline__content__text">
          {network === NetworkState.offline && t("warning.map_offline_text")}

          {network === NetworkState.incident &&
            t("warning.map_api_incident_text")}
        </p>

        <MobeaButton
          type="tertiary"
          onClick={() => {
            // @ts-ignore
            window.location.reload(false);
          }}
        >
          {t("warning.tap_to_retry")}
        </MobeaButton>
      </section>
    </div>
  );
}
