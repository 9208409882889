import { useRefundConfirmWalletModal } from "common/hooks/useRefundConfirmWalletModal";
import { TravelPassesImage } from "pages/home/TravelPassesImage";
import { MouseEvent, ReactElement } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ApplicationVariant, Routes } from "utils";
import { AppColors } from "utils/colors";
import { applicationVariant } from "utils/configure";

export interface HistoryNoCostsProps {
  noCostsTranslateKey: string;
  addCostTranslateKey?: string;
  startButtonTranslateKey?: string;
  startLinkTo?: string;
  daysLeft?: number;
  active: boolean;
}

export function HistoryNoCosts({
  noCostsTranslateKey,
  addCostTranslateKey,
  daysLeft = 0,
  startButtonTranslateKey = "history.start",
  startLinkTo = Routes.GetTravelPass,
  active,
}: HistoryNoCostsProps): ReactElement {
  const { t } = useTranslation();

  const confirmModal = useRefundConfirmWalletModal();

  const handleStartClick =
    applicationVariant === ApplicationVariant.MOVEASY &&
    startLinkTo === Routes.ExpenseAddEmpty
      ? (e: MouseEvent<HTMLAnchorElement>) => {
          e.preventDefault();

          confirmModal.show();
        }
      : undefined;

  return (
    <section
      css={{
        textAlign: "center",
        marginLeft: -32,
        marginTop: 32,
        paddingBottom: 30,
        ".auth-password &": {
          paddingBottom: 100, // derived from bottom bar height
        },
        ".auth-token &": {
          marginTop: "3rem",
        },
      }}
    >
      <div
        css={{
          width: 64,
          margin: "0 auto",
        }}
      >
        <TravelPassesImage />
      </div>

      <p
        css={{
          padding: "0 16px",
          margin: "32px 0 24px",
          fontSize: 14,
          lineHeight: "24px",
          color: AppColors.GRAY_500,
        }}
      >
        <span>
          <Trans i18nKey={noCostsTranslateKey} count={daysLeft}>
            <span className="mobea__arial">{{ days: daysLeft }}</span>
          </Trans>
        </span>

        {active && addCostTranslateKey && (
          <>
            <br />
            <Trans i18nKey={addCostTranslateKey}>
              Tap on the “Plus” or the button below to add
              <br />a new expense for refund or buy a ticket.
            </Trans>
          </>
        )}
      </p>

      {active && (
        <Link
          onClick={handleStartClick}
          css={{
            textDecoration: "none",
            fontWeight: 600,
            fontSize: 20,
            lineHeight: "24px",
            display: "inline-block",
            ":first-letter": {
              textTransform: "uppercase",
            },
          }}
          to={startLinkTo}
        >
          {t(startButtonTranslateKey)}
        </Link>
      )}
    </section>
  );
}
