import { Spinner } from "common/Spinner";
import {
  ChangeEvent,
  forwardRef,
  ReactNode,
  ReactText,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import "./InputGroup.scss";

export interface InputGroupProps {
  name: string;
  value: string | number;
  label: ReactNode;
  placeholder?: string;
  type?: string;
  className?: string;
  toggleLabelAndPlaceholder?: boolean;
  children?: ReactNode;
  inputAttributes?: Record<string, any>;
  errorMessage?: ReactNode;
  loading?: boolean;
  loadingText?: string;
  validation?: (value: ReactText) => boolean;
  // used to validate initial value as there might be different rules
  initialValidation?: (value: ReactText) => boolean;
  onChange?: (value: ReactText) => void;
  onFocus?(): void;
  onBlur?(): void;
  disabled?: boolean;
}

export interface InputGroupRef {
  runValidation: (value: ReactText) => void;

  focus(): void;
}

export const InputGroup = forwardRef<InputGroupRef, InputGroupProps>(
  (
    {
      name,
      value,
      label,
      placeholder = "",
      type = "text",
      className = "",
      toggleLabelAndPlaceholder = false,
      loading = false,
      loadingText,
      inputAttributes = {},
      children,
      errorMessage,
      validation,
      initialValidation,
      onChange,
      onFocus,
      onBlur,
      disabled = false,
    },
    ref
  ) => {
    const { t } = useTranslation();

    const [focused, setFocused] = useState(false);

    const [errored, setErrored] = useState(
      initialValidation ? !initialValidation(value) : false
    );

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (!validation) {
        setErrored(!!errorMessage);
      }
    }, [errorMessage, validation]);

    const runValidation = (value: ReactText) => {
      if (validation) {
        setErrored(!validation(value));
      } else if (errorMessage) {
        setErrored(true);
      }
    };

    // allow to override when custom handlers are used - focus state has to passed through active prop
    const onFocusCb = () => {
      setFocused(true);

      setErrored(false); // clear error on focus

      onFocus && onFocus();
    };

    const onBlurCb = () => {
      setFocused(false);

      runValidation(value);

      onBlur && onBlur();
    };

    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;

      onChange && onChange(value);

      runValidation(value);
    };

    const focus = () => {
      inputRef.current?.focus();
    };

    useImperativeHandle(ref, () => ({
      runValidation,
      focus,
    }));

    let derivedLabel = label;

    let derivedPlaceholder = placeholder;

    // show label only when there is value - otherwise placeholder is used
    // this expect that placeholder is provided
    if (toggleLabelAndPlaceholder) {
      derivedLabel = focused || value ? label : "";

      derivedPlaceholder = focused ? "" : placeholder;
    }

    const loadingValue = loadingText ? loadingText : t("shared.loading");

    return (
      <div
        className={`mobea__input-group ${className} ${
          focused ? "mobea__focused" : ""
        } ${disabled ? "mobea__disabled" : ""} ${
          errored ? "mobea__errored" : ""
        }`}
        css={{
          "> .mobea__spinner": {
            marginRight: 0,
            marginBottom: 8,
          },
        }}
      >
        <input
          ref={inputRef}
          type={type}
          name={name}
          id={name}
          value={loading ? loadingValue : value}
          placeholder={derivedPlaceholder}
          disabled={disabled}
          css={{
            pointerEvents: loading ? "none" : "auto",
          }}
          onFocus={onFocusCb}
          onBlur={onBlurCb}
          {...inputAttributes}
          onChange={onInputChange}
        />

        <label htmlFor={name}>{derivedLabel}</label>
        {loading && <Spinner size={24} />}

        {children}

        {errored && (
          <span className="mobea__input-group__error">{errorMessage}</span>
        )}
      </div>
    );
  }
);

InputGroup.displayName = "InputGroup";
