import { useCurrentTicket } from "common/hooks/useCurrentTicket";
import { NestedPage } from "common/page/NestedPage";
import { Spinner } from "common/Spinner";
import { BlueBikeTravelPass } from "common/travelPasses/blueBike/BlueBikeTravelPass";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { BlueBikeTravelPass as BlueBikeTravelPassType } from "state/actions";
import { getSearchParams, pushLocation, Routes } from "utils";
import { BackButtonBehaviour } from "utils/routing";
import { BlueBikeTutorial } from "./BlueBikeTutorial";

type Props = {
  backButtonBehaviour?: BackButtonBehaviour;
};

export function BlueBikeTicketDetail({
  backButtonBehaviour = "goHome",
}: Props): ReactElement {
  const history = useHistory();

  const location = useLocation();

  const { id = "" } = useParams<{ id: string }>();

  const { t } = useTranslation();

  const queryParams = getSearchParams(location.search);
  const showTutorial = Boolean(queryParams.get("showTutorial"));

  const locale = useSelector((state) => state.user.language);

  const [currentPass, loadingFailed, loadingDetail, refresh] =
    useCurrentTicket<BlueBikeTravelPassType>(id, () => true);

  const goBack = () => {
    if (backButtonBehaviour === "goHome") {
      pushLocation(history, Routes.Home);
    } else {
      history.goBack();
    }
  };

  return (
    <NestedPage
      pageClassName="blue-bike-ticket-detail"
      title={t("ticked_detail.my_ticket")}
      customCloseButton={<BlueBikeTutorial forceShow={showTutorial} />}
      padding={{
        left: 16,
        right: 16,
        bottom: 16,
      }}
      background="default"
      headerInSecondaryColor
      onNavBack={goBack}
    >
      {loadingDetail && !currentPass && <Spinner />}
      {currentPass && (
        <BlueBikeTravelPass
          locale={locale}
          refresh={refresh}
          {...currentPass}
        />
      )}
      {!loadingDetail && loadingFailed && !currentPass && (
        <h2>Sorry cannot find travel pass.</h2>
      )}
    </NestedPage>
  );
}
