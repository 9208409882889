import { css } from "@emotion/css";
import { useBudget } from "common/hooks";
import { useModal } from "common/hooks/useModal";
import { useEffect, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { setWalletWarningsAction } from "state/actions";
import { WalletsStatus as WalletsWarnings } from "state/reducers/stateTypes";
import {
  ApplicationVariant,
  BUDGET_COOLDOWN_PERIOD_DAYS,
  MAX_PRIVATE_AMOUNT_BEFORE_TOP_UP,
  pushLocation,
  Routes,
} from "utils";
import { AppColors } from "utils/colors";
import { applicationVariant } from "utils/configure";

export function useWalletWarnings(route: Routes) {
  const dispatch = useDispatch();

  const [loading] = useBudget();

  const {
    amount: businessAmount,
    outOfBudgetPeriod,
    remainingDays,
    walletWarnings,
    privateAmount,
    startDate,
    privateStartDate,
  } = useSelector((state) => state.user);

  const { t } = useTranslation();

  const history = useHistory();

  const emptyBothWalletsWarning = useModal(
    useMemo(
      () => ({
        type: "error",
        title: t("wallet.empty_both_warning_title"),
        confirmText: t("home.add_money"),
        confirmDisabled: privateAmount > MAX_PRIVATE_AMOUNT_BEFORE_TOP_UP,
        cancelText: t("shared.ok"),
        cancelButtonType: "secondary",
        children: <Trans i18nKey="wallet.empty_both_warning_body" />,
        onConfirm() {
          pushLocation(history, Routes.AddMoney);
        },
      }),
      [history, privateAmount, t]
    )
  );

  const emptyBusinessWalletWarning = useModal(
    useMemo(() => {
      if (privateAmount > MAX_PRIVATE_AMOUNT_BEFORE_TOP_UP) {
        return {
          type: "error",
          title: t("wallet.empty_business_warning_title"),
          cancelText: t("shared.ok"),
          cancelButtonType: "primary",
          children: (
            <Trans i18nKey="wallet.empty_business_warning_body_full_private" />
          ),
        };
      }
      return {
        type: "error",
        title: t("wallet.empty_business_warning_title"),
        confirmText: t("home.add_money"),
        cancelText: t("shared.ok"),
        cancelButtonType: "secondary",
        onConfirm() {
          pushLocation(history, Routes.AddMoney);
        },
        children: <Trans i18nKey="wallet.empty_business_warning_body" />,
      };
    }, [history, privateAmount, t])
  );

  const emptyPrivateWalletWarning = useModal(
    useMemo(
      () => ({
        type: "error",
        title: t("wallet.empty_private_warning_title"),
        cancelText: t("shared.ok"),
        cancelButtonType: "secondary",
        confirmText: t("home.add_money"),
        confirmDisabled: privateAmount > MAX_PRIVATE_AMOUNT_BEFORE_TOP_UP,
        onConfirm() {
          pushLocation(history, Routes.AddMoney);
        },
        children: <Trans i18nKey="wallet.empty_private_warning_body" />,
      }),
      [history, privateAmount, t]
    )
  );

  const days = Math.min(
    Math.max(BUDGET_COOLDOWN_PERIOD_DAYS + remainingDays, 0),
    BUDGET_COOLDOWN_PERIOD_DAYS
  );

  const daysLeftText = `${days} ${t("time.day", { count: days })}`;

  const cooldownWalletWarning = useModal(
    useMemo(() => {
      if (privateAmount > MAX_PRIVATE_AMOUNT_BEFORE_TOP_UP) {
        return {
          type: "error",
          title: t("wallet.cooldown_warning_title"),
          cancelText: t("shared.ok"),
          confirmText: t("wallet.add_expense"),
          cancelButtonType: "tertiary",
          cancelHides: true,
          onConfirm() {
            pushLocation(history, Routes.ExpenseAddEmpty);
          },
          className: css({
            "& .mobea__close-button": {
              color: AppColors.GRAY_500,
            },
          }),
          children: (
            <Trans
              i18nKey="cooldown_warning_body_full_private"
              values={{ days: daysLeftText }}
            />
          ),
        };
      }

      return {
        type: "error",
        title: t("wallet.cooldown_warning_title"),
        cancelText: t("wallet.add_expense"),
        confirmText: t("home.add_money"),
        cancelButtonType: "secondary",
        onConfirm() {
          pushLocation(history, Routes.AddMoney);
        },
        onCancel() {
          pushLocation(history, Routes.ExpenseAddEmpty);
        },
        showCloseButton: true,
        className: css({
          "& .mobea__close-button": {
            color: AppColors.GRAY_500,
          },
        }),
        children: (
          <Trans
            i18nKey="wallet.cooldown_warning_body"
            values={{ days: daysLeftText }}
          />
        ),
      };
    }, [daysLeftText, history, privateAmount, t])
  );

  const hasBusiness = businessAmount > 0;

  const hasPrivate = privateAmount > 0;

  const location = useLocation();

  const cookiesAndTutorialDone = useSelector(
    (state) =>
      state.tutorial.mainTutorialShown &&
      state.onboarding.cookiesAccepted &&
      state.onboarding.tutorialWatched
  );

  const skip =
    !cookiesAndTutorialDone ||
    route !== location.pathname ||
    loading ||
    applicationVariant !== ApplicationVariant.MOVEASY ||
    !startDate ||
    !privateStartDate;

  // OK Period is valid and businessBalance = 0 : emptyBusinessWalletWarning
  // Period is expired, within 7 days since the expiration, balance > 0 : cooldownWalletWarning
  // Period is expired, within 7 days since the expiration, balance = 0 : emptyBusinessWalletWarning
  // OK privateBalance = 0: emptyPrivateWalletWarning
  // OK No funds in both budgets: emptyBothWalletsWarning

  useEffect(() => {
    if (skip) {
      return;
    }

    const cooldown =
      outOfBudgetPeriod && -remainingDays < BUDGET_COOLDOWN_PERIOD_DAYS;

    let next = walletWarnings;

    if (hasBusiness && hasPrivate && !outOfBudgetPeriod) {
      next = 0;
    } else if (
      !hasBusiness &&
      !hasPrivate &&
      !(walletWarnings & WalletsWarnings.emptyBoth)
    ) {
      emptyBothWalletsWarning.show();

      next |=
        WalletsWarnings.emptyBoth |
        WalletsWarnings.emptyPrivate |
        WalletsWarnings.emptyBusiness;
    } else if (
      !hasPrivate &&
      !(walletWarnings & WalletsWarnings.emptyPrivate)
    ) {
      emptyPrivateWalletWarning.show();

      next |= WalletsWarnings.emptyPrivate;
    } else if (
      cooldown &&
      hasBusiness &&
      !(walletWarnings & WalletsWarnings.cooldown)
    ) {
      cooldownWalletWarning.show();

      next |= WalletsWarnings.cooldown;
    } else if (
      ((!outOfBudgetPeriod && !hasBusiness) || (cooldown && !hasBusiness)) &&
      !(walletWarnings & WalletsWarnings.emptyBusiness)
    ) {
      emptyBusinessWalletWarning.show();

      next |= WalletsWarnings.emptyBusiness;
    }

    if (hasPrivate) {
      next &= ~(WalletsWarnings.emptyBoth | WalletsWarnings.emptyPrivate);
    }

    if (hasBusiness) {
      next &= ~(WalletsWarnings.emptyBoth | WalletsWarnings.emptyBusiness);
    }

    if (!cooldown) {
      next &= ~WalletsWarnings.cooldown;
    }

    if (next !== walletWarnings) {
      dispatch(setWalletWarningsAction(next));
    }
  }, [
    cooldownWalletWarning,
    dispatch,
    emptyBothWalletsWarning,
    emptyBusinessWalletWarning,
    emptyPrivateWalletWarning,
    hasBusiness,
    hasPrivate,
    outOfBudgetPeriod,
    remainingDays,
    skip,
    walletWarnings,
  ]);
}
