import { DefaultIconProps } from "icons/props";
import { VerticalClassicFlagIcon } from "./VerticalClassicFlagIcon";

const RomaniaIcon = (props: DefaultIconProps) => (
  <VerticalClassicFlagIcon
    firstColor="#012169"
    secondColor="#FFCD00"
    thirdColor="#C8102E"
    {...props}
  />
);

export default RomaniaIcon;
