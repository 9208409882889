import Color from "color";
import { RefObject, useEffect, useState } from "react";
import { LocationCoords } from "services/mapService";
import { AppColors, getDerivedColor } from "utils/colors";

const H = window.H;

const ROUTING_SERVICE_VERSION = 8;

type UseRoutingParams = {
  platformRef: RefObject<H.service.Platform | null>;
  origin: LocationCoords | null;
  destination: LocationCoords | null;
  enabled?: boolean;
  transportMode?: string;
  color?: string;
};

export function useRouting({
  platformRef,
  origin,
  destination,
  enabled = true,
  transportMode = "pedestrian",
  color,
}: UseRoutingParams) {
  const [mapGroup, setMapGroup] = useState<H.map.Group | null>(null);

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState<string | null>(null);

  const [routeLines, setRouteLines] = useState<H.geo.LineString[]>([]);

  const [distance, setDistance] = useState<number | null>(null);

  useEffect(() => {
    const platform = platformRef.current;

    let canceled = false;

    setRouteLines([]);

    setDistance(null);

    setError(null);

    if (!platform || !origin || !destination || !enabled) {
      return;
    }

    const calculateRoute = () => {
      // @ts-ignore
      const service = platform.getRoutingService(null, ROUTING_SERVICE_VERSION);

      setLoading(true);

      service.calculateRoute(
        {
          routingMode: "fast",
          transportMode,
          origin: `${origin.lat},${origin.lng}`,
          destination: `${destination.lat},${destination.lng}`,
          return: "polyline,summary",
        },
        (result) => {
          if (canceled) {
            return;
          }

          if (result.routes.length) {
            const route = result.routes[0];

            setRouteLines(
              route.sections.map((section) =>
                (H.geo.LineString as any).fromFlexiblePolyline(section.polyline)
              )
            );

            setDistance(
              route.sections.reduce(
                (distance: number, section) =>
                  distance + section.summary.length,
                0
              )
            );
          }

          setLoading(false);
        },
        (error) => {
          setError(error.message);

          setLoading(false);
        }
      );
    };

    calculateRoute();

    return () => {
      canceled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    origin?.lat,
    origin?.lng,
    destination?.lat,
    destination?.lng,
    transportMode,
    enabled,
  ]);

  useEffect(() => {
    if (!mapGroup) {
      return;
    }

    mapGroup.removeAll();

    if (routeLines.length > 0) {
      mapGroup.addObjects(
        color
          ? routeLines.flatMap((routeLine) => [
              mapGroup.addObject(
                new H.map.Polyline(routeLine, {
                  style: {
                    strokeColor: Color(getDerivedColor(color))
                      .darken(0.3)
                      .string(),
                    lineWidth: 6,
                  },
                })
              ),

              mapGroup.addObject(
                new H.map.Polyline(routeLine, {
                  style: {
                    strokeColor: getDerivedColor(color),
                    lineWidth: 4,
                  },
                })
              ),
            ])
          : routeLines.map(
              (routeLine) =>
                new H.map.Polyline(routeLine, {
                  style: {
                    strokeColor: getDerivedColor(AppColors.PRIMARY),
                    lineWidth: 2,
                  },
                })
            )
      );
    }
  }, [color, mapGroup, routeLines]);

  return [setMapGroup, distance, loading, error] as const;
}
