import { ReactElement, ReactNode } from "react";

export interface SettingsSectionProps {
  headline: ReactNode;
  children?: ReactNode;
}

export function SettingsSection({
  headline,
  children,
}: SettingsSectionProps): ReactElement {
  return (
    <section className="mobea__settings__section">
      <h3 className="mobea__settings__section__headline">{headline}</h3>
      <div className="mobea__settings__section__items">{children}</div>
    </section>
  );
}
