import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

type Props = DefaultIconProps & {
  outlined?: boolean;
};

export function ClockIcon({
  fill = "currentColor",
  className = "",
  height = "24",
  width = "24",
  outlined = true,
}: Props): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
        fill={fill}
      />
      {!outlined && <circle cx="12" cy="12" r="8" fill={fill} />}
      <path
        fill={outlined ? fill : "white"}
        d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z"
      />
    </svg>
  );
}
