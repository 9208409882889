import { ReactElement } from "react";
import { DefaultIconProps } from "../props";

export function TicketIcon({
  fill = "currentColor",
  className = "",
  height = "24",
  width = "24",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="nonzero"
    >
      <path
        className="mobea__navigation__inactive"
        d="M22 10V6a2 2 0 00-2-2H4a2 2 0 00-2 2v4a2 2 0 010 4v4c0 1.1.9 2 2 2h16a2 2 0 002-2v-4a2 2 0 01-2-2c0-1.1.9-2 2-2zm-2-1.5a4 4 0 00-2 3.5 4 4 0 002 3.5V18H4v-2.5a4 4 0 000-7V6h16v2.5zM11 15h2v2h-2v-2zm0-4h2v2h-2v-2zm0-4h2v2h-2V7z"
        fill={fill}
      />
      <path
        className="mobea__navigation__active"
        d="M22 10V6a2 2 0 00-2-2H4a2 2 0 00-2 2v4a2 2 0 010 4v4c0 1.1.9 2 2 2h16a2 2 0 002-2v-4a2 2 0 01-2-2c0-1.1.9-2 2-2zm-9 7.5h-2v-2h2v2zm0-4.5h-2v-2h2v2zm0-4.5h-2v-2h2v2z"
        fill={fill}
      />
    </svg>
  );
}
