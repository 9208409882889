import Bowser from "bowser";
import { MobeaButton } from "common/forms";
import { useContactUsModal } from "common/modal";
import { SadHeadIcon } from "icons/SadHeadIcon";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppColors } from "utils/colors";

export interface ErrorInfo {
  error: Error;
  componentStack: string;
  eventId: string;
}

type Props = {
  errorInfo: ErrorInfo;
  onReset(): void;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function ErrorPage({ errorInfo, onReset }: Props): ReactElement {
  const { t } = useTranslation();

  const [browserIsOld, setBrowserIsOld] = useState(false);

  const { show: showContactUs } = useContactUsModal();

  // show custom message to major browsers if used in old version
  // primary use case is Safari which cannot be updated without system update
  // so you are locked to old version on old phones...
  useEffect(() => {
    const parser = Bowser.getParser(navigator.userAgent);

    const isOldMajorBrowser = parser.satisfies({
      safari: "<12",
      chrome: "<66",
      firefox: "<57",
    });

    setBrowserIsOld(!!isOldMajorBrowser);
  }, []);

  const refresh = () => {
    onReset();

    window.location.reload();
  };

  return (
    <div
      className="mobea__error-page"
      css={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        padding: "2rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <SadHeadIcon
        fill={AppColors.GRAY_100}
        css={{
          marginBottom: "3rem",
        }}
      />
      <div
        css={{
          color: AppColors.ERROR,
          textAlign: "center",
          lineHeight: "2rem",
          fontSize: "1.3125rem",
          marginBottom: "0.5rem",
          fontWeight: 500,
        }}
      >
        {t("error.title")}
      </div>
      <div
        css={{
          fontSize: "0.9375rem",
          lineHeight: 1.5,
          marginBottom: "2rem",
        }}
      >
        {browserIsOld ? t("error.old_browser") : t("error.info")}
      </div>
      <div
        css={{
          width: "100%",
          "> button:first-of-type": {
            marginBottom: "0.5rem",
          },
        }}
      >
        {!browserIsOld && (
          <MobeaButton onClick={refresh} type="primary">
            {t("error.refresh")}
          </MobeaButton>
        )}
        <MobeaButton
          onClick={showContactUs}
          type={browserIsOld ? "primary" : "secondary"}
        >
          {t("settings.contact_us")}
        </MobeaButton>
      </div>
    </div>
  );
}
