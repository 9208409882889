import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { LocationCoords, ProviderData } from "services/mapService";
import { getMobitNearbyBikes, MobitNearbyBike } from "services/mobitService";
import { TravelPassProvider } from "utils";

export type MobitNearbyBikeProvider = MobitNearbyBike & ProviderData;

export function useNearbyBikes(coordinates: LocationCoords | null) {
  const history = useHistory();

  const [results, setResults] = useState<MobitNearbyBikeProvider[]>([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let canceled = false;
    let isLoading = false;

    async function fetchNearbyBikes(coords: LocationCoords) {
      try {
        setLoading(true);
        isLoading = true;

        const json = await getMobitNearbyBikes(coords, history);

        if (!json.error) {
          const bikeLocations: MobitNearbyBikeProvider[] = json.data
            .filter((bike) => bike.backCode)
            .map((bike) => ({
              frontCode: bike.frontCode,
              backCode: bike.backCode,
              id: bike.backCode,
              type: TravelPassProvider.mobit,
              name: TravelPassProvider.mobit,
              lng: bike.location.x,
              lat: bike.location.y,
            }))
            // keep consistent order as Mobit returns bikes in random order...
            .sort((a, b) => a.backCode.localeCompare(b.backCode));

          if (!canceled) {
            setLoading(false);

            setResults(bikeLocations);
          }
        } else {
          !canceled && setLoading(false);

          console.error(
            "Failed to fetch nearby bikes",
            json.error_code,
            json.error_description
          );
        }
      } catch (error) {
        !canceled && setLoading(false);

        console.error("Failed to fetch nearby bikes", error);
      } finally {
        isLoading = false;
      }
    }

    coordinates && fetchNearbyBikes(coordinates);

    return () => {
      canceled = true;

      isLoading && console.debug("Canceled hook useNearbyBikes");
    };
  }, [coordinates, history]);

  return [results, loading] as const;
}
