import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

export function ExclamationMarkIcon({
  fill = "currentColor",
  className = "",
  height = "32",
  width = "32",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 72 72"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36 6c8.29 0 15.363 2.934 21.218 8.803C63.073 20.67 66 27.737 66 36s-2.927 15.329-8.782 21.197C51.363 63.066 44.29 66 36 66s-15.386-2.958-21.288-8.873C8.904 51.305 6 44.263 6 36c0-8.263 2.927-15.329 8.782-21.197C20.637 8.934 27.71 6 36 6zm0 6c-6.616 0-12.27 2.34-16.962 7.018S12 29.357 12 36c0 6.643 2.346 12.304 7.038 16.982C23.73 57.661 29.384 60 36 60s12.27-2.34 16.962-7.018S60 42.643 60 36c0-6.643-2.346-12.304-7.038-16.982C48.27 14.339 42.616 12 36 12zm3 33v6h-6v-6h6zm0-24v18h-6V21h6z"
        fill={fill}
      />
    </svg>
  );
}
