import { MyLocationIcon } from "icons/navigation/MyLocationIcon";
import { ReactElement } from "react";

export interface MapLocationButtonProps {
  onClick(): void;
}

export function LocateUserButton({
  onClick,
}: MapLocationButtonProps): ReactElement {
  return (
    <div
      className="mobea__map-action mobea__map-action__location"
      onClick={onClick}
    >
      <MyLocationIcon />
    </div>
  );
}
