import {
  DeLijnTile,
  MobitTile,
  NmbsTile,
  VeloTile,
  VertsTile,
} from "pages/providers/tiles";
import { ExpenseTile } from "pages/providers/tiles/ExpenseTile";
import React from "react";
import { TravelProvider } from "state/actions";
import { TravelPassProvider } from "utils";
import { BlueBikeTile } from "./BlueBikeTile";

function getProviderConstructor(provider: TravelPassProvider) {
  switch (provider) {
    case TravelPassProvider.delijn:
      return DeLijnTile;
    case TravelPassProvider.nmbs:
      return NmbsTile;
    case TravelPassProvider.nmbs:
      return NmbsTile;
    case TravelPassProvider.mobit:
      return MobitTile;
    case TravelPassProvider.verts:
      return VertsTile;
    case TravelPassProvider["velo-antwerpen"]:
      return VeloTile;
    case TravelPassProvider["blue-bike"]:
      return BlueBikeTile;
    default:
      return ExpenseTile;
  }
}

export function toProviderTile(
  travelProvider: TravelProvider,
  onClick?: () => void
) {
  const provider = travelProvider.provider;

  return React.createElement(getProviderConstructor(provider), {
    key: `${travelProvider.type}-${provider}`,
    ...travelProvider,
    onClick,
  });
}
