import { useTranslations } from "common/hooks";
import { useAppName } from "common/hooks/useAppName";
import { Spinner } from "common/Spinner";
import { getProviderNameNoSoftHyphen } from "common/travelPasses/ProviderName";
import { ReactElement, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { TravelProvider } from "state/actions";
import { ApplicationVariant, getPrivacyLink } from "utils";
import { applicationVariant, getConfig } from "utils/configure";
import { DEFAULT_INTL_NAMESPACE } from "utils/i18n";
import { ConditionsPage } from "./ConditionsPage";

export function TermsAndConditionsPage(): ReactElement {
  const { t } = useTranslation("terms");

  const language = useSelector((state) => state.user.language);

  const providers = useSelector((state) => state.passes.providers);

  const loading = useTranslations("terms");

  const { supportEmail, supportPhone, clientName, clientNameShort } =
    getConfig();

  const appName = useAppName();

  const interpolationValues = { appName, clientName, clientNameShort };

  const translateName = (provider: TravelProvider) =>
    getProviderNameNoSoftHyphen(provider.provider);

  const mergeNames = (
    accumulator: string,
    value: string,
    index: number,
    array: string[]
  ) => {
    const isLast = index === array.length - 1;

    const separator = isLast
      ? ` ${t("shared.and", { ns: DEFAULT_INTL_NAMESPACE })} `
      : ", ";

    return accumulator + (index > 0 ? separator : "") + value;
  };

  const orderable = useMemo(
    () =>
      providers
        .filter((provider) => provider.orderable && !provider.testOrder)
        .map(translateName)
        .reduce(mergeNames, ""),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [providers]
  );

  const expensable = useMemo(
    () =>
      providers
        .filter((provider) => provider.expensable && !provider.orderable)
        .map(translateName)
        .reduce(mergeNames, ""),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [providers]
  );

  const translateSection = (
    name: string,
    size: number,
    extraValues: Record<string, string> = {}
  ) => {
    const mergedValues = { ...interpolationValues, ...extraValues };

    return (
      <section className="mobea-conditions__content__section">
        <h2>{t(`${name}.headline`, mergedValues)}</h2>
        {new Array(size).fill("").map((_, index) => (
          <p key={index}>
            <Trans
              i18nKey={`${name}.${index}`}
              ns="terms"
              values={mergedValues}
            />
          </p>
        ))}
      </section>
    );
  };

  return (
    <ConditionsPage title={t("translation:welcome.terms_conditions")}>
      {loading && <Spinner />}

      {!loading && (
        <>
          {translateSection("definition", 5)}
          {translateSection("acceptance", 2)}
          {translateSection("usage", 3)}
          {translateSection("plan", 3)}
          {translateSection("app", 4)}
          {translateSection("ordering", 6, { orderable })}

          <section className="mobea-conditions__content__section">
            <h2>{t("expense.headline")}</h2>
            {[0, 1, 2, 3].map((key) => (
              <p key={key}>
                <Trans
                  i18nKey={`expense.${key}`}
                  ns="terms"
                  values={{
                    ...interpolationValues,
                    expensable,
                  }}
                />
              </p>
            ))}

            <ul>
              {[0, 1, 2, 3, 4].map((key) => (
                <li key={key}>
                  <Trans
                    i18nKey={`expense.list.${key}`}
                    ns="terms"
                    values={interpolationValues}
                  />
                </li>
              ))}
            </ul>

            {[4, 5, 6, 7].map((key) => (
              <p key={key}>
                <Trans
                  i18nKey={`expense.${key}`}
                  ns="terms"
                  values={interpolationValues}
                />
              </p>
            ))}
          </section>

          {translateSection("no_money", 2)}
          {translateSection("misuse", 1)}
          {translateSection("end", 2)}

          <section className="mobea-conditions__content__section">
            <h2>{t("personal_info.headline")}</h2>
            <p>
              <Trans
                i18nKey="personal_info.0"
                ns="terms"
                values={interpolationValues}
              >
                <a
                  href={getPrivacyLink(language)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
                <span
                  className={
                    applicationVariant === ApplicationVariant.MOVEASY
                      ? "mobea__no-display"
                      : ""
                  }
                ></span>
              </Trans>
            </p>
          </section>

          {translateSection("notification", 1)}

          <section className="mobea-conditions__content__section">
            <h2>{t("problems.headline")}</h2>
            <p>
              <Trans
                i18nKey="problems.0"
                ns="terms"
                values={{
                  ...interpolationValues,
                  email: supportEmail,
                  phone: supportPhone,
                }}
              >
                <a href={`mailto:${supportEmail}`}></a>
                and phone number
                <a href={`tel:${supportPhone}`}></a>.
              </Trans>
            </p>
          </section>

          {translateSection("availability", 4)}
          {translateSection("law", 1)}
        </>
      )}
    </ConditionsPage>
  );
}
