import { useEffect } from "react";

export function useBodyNoScroll(enabled = true) {
  useEffect(() => {
    if (enabled) {
      document.body.classList.add("noScroll");

      return () => document.body.classList.remove("noScroll");
    }
  }, [enabled]);
}
