import { getLocationWithFallback } from "maps/mapUtils";
import { useEffect, useState } from "react";
import { NullableLocationCoords } from "services/mapService";

export function useLocation() {
  const [location, setLocation] = useState<NullableLocationCoords | null>(null);

  useEffect(() => {
    let canceled = false;

    getLocationWithFallback().then((coords) => {
      !canceled && setLocation(coords);
    });

    return () => {
      canceled = true;
    };
  }, []);

  return location;
}
