import { DefaultIconProps } from "icons/props";
import { ReactElement } from "react";

export function CozywheelsLogo({
  className = "",
  width,
  height,
}: Pick<DefaultIconProps, "className" | "width" | "height">): ReactElement {
  return (
    <img
      className={className}
      width={width}
      height={height}
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAAAyCAMAAAAuj2TTAAAAQlBMVEUAAAAgj58NgIYnvtQAa2cAaGUlvc8AaGMAZmQkus8AZmUjus8AZ2Mju84AZ2Qkuc4AZ2IAZmQkuc4AZmIjuc4AZmW//eJAAAAAFHRSTlMAECY7Q1tgcYKGmKCtwsLX2u3x9THno1UAAAQJSURBVHja7ZjhlpsgEIXvEAkmMQSlvP+rdhXiHUMTPdvtMT0n358iUZwPhikuPnz454gxpu4R/F8chl8TwxGF49gzci0u4zUZUBrl+XIv9kUHOUgJjFz+KHLMjQNGbvlCsC+/FgA5SDJQRMnlxg0jvG8vag8BJLfIhSK0vTK3TrlpsCuXBw/GTKR08nrWPXIqsCOM4gTAMMIxRIEM85KIGDEyzPcyt6i0L8yeArcxNflTVaqA01ssyD0cwuR/DB284NWBU7EjjJQMczlinulfjsvddZW3WJDXIlww3jpUWcn798SwDi3LWH3xRPktau8UHk1uB6pd9X/xOrH0JBC8xmbwfVYG4LTeLtdx61JtOB2vpXlArk2Ek7Ct9qYMvo2sDMBJZ8wMWYdd9SzksK8IT3zEMGbVhaES0XaXvUVqkxuqqA9zxSIPj+ItRHSUR+YMP0iei5hi/yYiwOFyG4bb5aDkLl/LMlxPUm44LcFCWN5G5JvwQ2S7SGNtgyXG1n2Q8UZTi+hnzA8f1I5APxExMTZLK0ORc5roMNOElAkWpO3TROwEtYj4xIc20kdCenk4G5doxu4crFdvp0ifChEFn0gvd7mkcJWISxrBFoIDISZOg/NTxqYJyzRSAblZhNyXLC0QepDzg0hTDbRO60E0SRVfvQn4SgMgzp2J6Jh9pccBiFmKVOOsYyJeiZT6rKcWQGDyMLpUEVTI0d+NGrVK3kc9PWzpPA3YQpJRJimioQhrL3PLMOb5lU6JWJk3KkO2ajvpFtDWIjBqmYzHFvoGQDJQSFnKVNXee9A2FVo0VTo0qq1XjPHNwcti1FokOGuwjXMHwDtkQijvu4sceIDRuVX+ZbPXwXNjUKQ1I7NIyA0zUYZ4tkd6i3UkArD93aMRTNhYRFh7dU1EyrDpXojUsFNDkZxvJArWsHovSQK6FC0AF79Iuvbq3OrSTOC7/06EK8IByLqIA4AmE8xyuyQMXBDmVsG3Ooi/FzkvRdBE/eMmkTZ0E2ns8LT3rL13nK7tbLerIh0If359aBTX+ZAym0Q6Axs6QRAgIXTBPf6Jgvx5WrEqcl9oZ+eUZlKbWIskJxjpNoq0k4j30iQJAusxnfqennt7LjaLcP9K5Jwy0TUuzEap0Fl7jmUEipT4+67ttm6SsQJ6k2BgQjBIcnbeTWujay9xzCxG0z4VYTfhoVPhH0SqSrCGhBGP2CDFkTH3Yb8aPWsv4RsW7eciDI9waRVuIeIr+W0kAK7DiPXlmrWXMIBWHVHjaxGYRFiBQtJEpVxrWmzEJecjMqG3eaG5IJq23t7tKxFecX9X9TVW3yONUukNtmMbsOph4pjBj9D40PfBN1jQhv6rt331SGjx4cOHDx8+/AC/AefB1evpkUznAAAAAElFTkSuQmCC"
    />
  );
}
