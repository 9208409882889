import { useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Auth } from "services/authTokenStore";
import { getCustomer } from "services/customerService";
import {
  providerTermsAcceptedAction,
  termsAndPrivacyAcceptedAction,
} from "state/actions";
import { setCustomerAction } from "state/actions/UserActions";
import {
  ApplicationVariant,
  defaultLanguage,
  pushLocation,
  Routes,
  TermsOrPrivacy,
  TravelPassProvider,
} from "utils";
import { applicationVariant } from "utils/configure";
import i18n from "utils/i18n";
import { getRedirectRoute } from "utils/routing";

export function useCustomerData(forceReload = false) {
  const { customerId, phoneNumber, endDate, amount } = useSelector(
    (state) => state.user
  );

  const history = useHistory();

  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const loggedIn = Auth.hasToken();

  const reload =
    (forceReload ||
      phoneNumber === "" ||
      endDate === null ||
      endDate < Date.now() ||
      amount <= 0) &&
    loggedIn;

  useEffect(() => {
    let canceled = false;

    let loading = false;

    setLoading(false);

    const redirRoute = getRedirectRoute();

    async function fetchUserData() {
      if (customerId === "") {
        // allow user to open confirm page without redirecting him back
        if (
          location.pathname !== Routes.ConfirmPhoneVerification &&
          location.pathname !== Routes.Default
        ) {
          console.debug("Redirecting to verification.");

          pushLocation(history, redirRoute);
        } else {
          console.debug("No customer. Aborting user data");
        }

        return;
      }

      try {
        setLoading(true);

        loading = true;

        const customerJson = await getCustomer(customerId, history);

        if (!customerJson.error) {
          if (customerJson.anonymized) {
            Auth.clearTokens();

            pushLocation(history, redirRoute);

            return;
          }

          if (
            applicationVariant === ApplicationVariant.MOVEASY &&
            !customerJson.first_login &&
            location.pathname !== Routes.SetPassword
          ) {
            pushLocation(history, Routes.SetPassword);
          }

          const planId = customerJson.plan_id || null;

          const normalizedCustomer = {
            language: customerJson.language || defaultLanguage,
            name: customerJson.name || "",
            surname: customerJson.surname || "",
            email: customerJson.email || "",
            phonePrefix: customerJson.phone_prefix || "",
            phoneNumber: customerJson.phone_number || "",
            dateOfBirth: customerJson.date_of_birth || "",
            iban: customerJson.iban || "",
            tester: customerJson.is_tester || false,
            planId: planId || null,
            // states if first login already happened - if password was set
            // just weird wording :)
            passwordSet: !!customerJson.first_login,
          };

          // change language only if it really does change!
          if (i18n.language !== normalizedCustomer.language) {
            i18n.changeLanguage(normalizedCustomer.language);
          }

          const tac =
            customerJson.tac ||
            ({} as Record<TravelPassProvider | TermsOrPrivacy, string>);
          const termsAndConditionsAccepted =
            !!tac.moveasy_privacy && !!tac.moveasy_terms;
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { moveasy_privacy, moveasy_terms, ...providerTerms } = tac;

          // convert from date string to booleans
          const convertedProviderTerms = Object.entries(providerTerms).reduce(
            (accumulator, [key, value]) => {
              accumulator[key] = !!value;
              return accumulator;
            },
            {}
          );

          batch(() => {
            if (!canceled) {
              dispatch(setCustomerAction(normalizedCustomer));
              dispatch(
                termsAndPrivacyAcceptedAction(termsAndConditionsAccepted)
              );
              dispatch(providerTermsAcceptedAction(convertedProviderTerms));
            }
          });
        } else {
          console.error(
            "Failed to fetch user data",
            customerJson.error && customerJson.error_description
          );
        }
      } catch (error) {
        console.error("Failed to fetch user data", error);
      } finally {
        if (!canceled) {
          setLoading(false);

          loading = false;
        }
      }
    }

    // run only once as we are fetching static data
    if (reload) {
      fetchUserData();
    }

    return () => {
      canceled = true;

      if (loading) {
        console.debug("Canceled hook useUserData");
      }
    };
  }, [customerId, dispatch, history, reload, location.pathname]);

  return loading;
}
