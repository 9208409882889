import { History } from "history";
import {
  LocationSearchPlace,
  LocationSearchPlaceFull,
} from "services/mapService";
import { RawTravelPass } from "services/travelPassService";
import {
  ADMIN_API_BASE,
  ApiErrorResponse,
  ApiErrors,
  CORE_API_BASE,
} from "utils";
import { VertsStatus } from "utils/VertsStatus";
import { callSecuredApiEndpoint } from "./apiCall";

export type RawVertsTicket = {
  arrivalAt: string; // date ISO string
  createdAt: string; // date ISO string
  requestedAt: string; // date ISO string
  current_target: {
    eta:
      | string
      | {
          time: string; // date ISO string
        };
  } | null;
  source: {
    address: string;
    coordinates: {
      lat: number;
      lng: number;
    };
  };
  destination: {
    address: string;
    coordinates: {
      lat: number;
      lng: number;
    };
  };
  min_rate?: number;
  schedule_type: "immediate" | "planned";
};

export interface VertsTicketMetadata {
  order_status: VertsStatus;
  cancelled_by_operator?: boolean;
  driver_info?: {
    info: {
      first_name: string;
      last_name: string;
    };
  };
  vehicle_info?: {
    info: {
      brand: string;
      model: string;
      plaque: string;
      color: string;
    };
    current_location?: {
      coordinates: {
        lat: number;
        lng: number;
      };
    };
  };
}

const toDataResponseObject =
  (dataTransform = (data) => data) =>
  (json) => {
    if (json.error_code) {
      return {
        error: true,
        ...json,
      };
    } else {
      return {
        error: false,
        data: dataTransform(json),
      };
    }
  };

interface RawVertsPackage {
  estimation_id: string;
  commercial_package_id: string;
  name: string;
  min_rate: number;
  max_rate: number;
  distance: number;
  duration: number;
}

export interface VertsPackage {
  id: string;
  estimationId: string;
  name: string;
  minRate: number;
  maxRate: number;
  capacity?: number;
  distance: number; // in meters
  duration: number; // in seconds
}

const PackageCapacities = {
  cp_id_vip: 4,
};

const toVertsPackage = (vertsPackage: RawVertsPackage): VertsPackage => ({
  id: vertsPackage.commercial_package_id,
  estimationId: vertsPackage.estimation_id,
  name: vertsPackage.name,
  minRate: vertsPackage.min_rate,
  maxRate: vertsPackage.max_rate,
  capacity: PackageCapacities[vertsPackage.commercial_package_id],
  distance: vertsPackage.distance,
  duration: vertsPackage.duration,
});

type VertsPackagesResponse =
  | {
      error: false;
      data: VertsPackage[];
    }
  | ApiErrorResponse;

const getVertsPackagesRequest =
  (
    origin: LocationSearchPlaceFull,
    destination: LocationSearchPlace,
    requestedAt = new Date()
  ) =>
  async (authorization: string) =>
    fetch(`${CORE_API_BASE}providers/verts/estimate`, {
      method: "POST",
      headers: new window.Headers({
        Authorization: authorization,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        load_type: "user",
        requestedAt: requestedAt.toISOString(),
        source: {
          coordinates: {
            lat: origin.lat,
            lng: origin.lng,
          },
          street_number: origin.address.houseNumber || "?",
          street: origin.address.streetName,
          postal_code: origin.address.postalCode,
        },
        destination: {
          coordinates: {
            lat: destination.lat,
            lng: destination.lng,
          },
        },
      }),
    }).then((response) => {
      if (response.status === 400) {
        return {
          error: true,
          error_code: ApiErrors.VERTS_JOURNEY_NOT_POSSIBLE,
          error_type: "journey_not_possible",
          error_description: "Journey not possible",
        } as ApiErrorResponse;
      } else {
        return response.json();
      }
    });

export function getVertsPackages(
  origin: LocationSearchPlaceFull,
  destination: LocationSearchPlace,
  requestedAt: Date | undefined,
  history: History
) {
  return callSecuredApiEndpoint(
    getVertsPackagesRequest(origin, destination, requestedAt),
    history
  ).then(
    toDataResponseObject((data) => data.map(toVertsPackage))
  ) as Promise<VertsPackagesResponse>;
}

export interface VertsOrderPayload {
  packageId: string;
  estimationId: string;
  price: number;
  source: LocationSearchPlaceFull;
  destination: LocationSearchPlace;
  wallet: "private" | "business";
  requestedAt?: Date;
}

type VertsOrderResponse =
  | {
      error: false;
      data: {
        transaction_id: string;
      };
    }
  | ApiErrorResponse;

const orderVertsRideRequest =
  (payload: VertsOrderPayload) => (authorization: string) =>
    fetch(`${ADMIN_API_BASE}verts/orders`, {
      method: "POST",
      headers: new window.Headers({
        Authorization: authorization,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        data: {
          load_type: "user",
          commercial_package_id: payload.packageId,
          estimation_id: payload.estimationId,
          requestedAt: (payload.requestedAt ?? new Date()).toISOString(),
          price: payload.price,
          source: {
            address: payload.source.label,
            coordinates: {
              lat: payload.source.lat,
              lng: payload.source.lng,
            },
            // fallback value from TV for the case there is no house number available
            street_number: payload.source.address.houseNumber || "?",
            street: payload.source.address.streetName,
            postal_code: payload.source.address.postalCode,
          },
          destination: {
            address: payload.destination.label,
            coordinates: {
              lat: payload.destination.lat,
              lng: payload.destination.lng,
            },
          },
          plan_type: payload.wallet,
        },
      }),
    }).then((response) => response.json());

export function orderVertsRide(payload: VertsOrderPayload, history: History) {
  return callSecuredApiEndpoint(orderVertsRideRequest(payload), history).then(
    toDataResponseObject()
  ) as Promise<VertsOrderResponse>;
}

type VertsCancelResponse =
  | {
      error: false;
      data: RawTravelPass;
    }
  | ApiErrorResponse;

function cancelVertsRideRequest(id: string) {
  return (authorization: string) =>
    fetch(`${CORE_API_BASE}providers/verts/order/${id}`, {
      method: "DELETE",
      headers: new window.Headers({
        Authorization: authorization,
      }),
    }).then((response) => response.json());
}

export function cancelVertsRide(rideId: string, history: History) {
  return callSecuredApiEndpoint(cancelVertsRideRequest(rideId), history).then(
    toDataResponseObject()
  ) as Promise<VertsCancelResponse>;
}
