import { MobeaButton } from "common/forms";
import { useQrReader } from "common/hooks/useQrReader";
import { MobeaModal } from "common/modal";
import { NestedPageOverlay } from "common/page/NestedPageOverlay";
import { Spinner } from "common/Spinner";
import { CameraIcon } from "icons/CameraIcon";
import { CircleCheckIcon } from "icons/CircleCheckIcon";
import { InfoIcon } from "icons/InfoIcon";
import { QrCodeIcon } from "icons/QrCodeIcon";
import { TorchIcon } from "icons/TorchIcon";
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Routes } from "utils/routes";
import { MobitBikeLockStatus, useBikeLockUpdate } from "../hooks";
import { useMobitOpenBike } from "../hooks/useMobitOpenBike";
import { UnlockBikeDialogs } from "../unlock/UnlockBikeDialogs";
import "./ScanCodePage.scss";

enum ScanProgress {
  IN_PROGRESS = "inProgress",
  SUCCESS = "success",
  ERROR = "error",
}

interface ScanCodePageProps {
  goToEnterCode(): void;
  goToTicket(): void;
  goBack(): void;
}

export function ScanCodePage({
  goToEnterCode,
  goBack,
  goToTicket,
}: ScanCodePageProps): ReactElement {
  const { t } = useTranslation();

  const history = useHistory();

  const [scanResult, setScanResult] = useState<ScanProgress>(
    ScanProgress.IN_PROGRESS
  );

  const [bikeToOpen, setBikeToOpen] = useState<{ code: string } | null>(null);

  // prevent multiple objects for same code
  const bikeCodeToOpen = useMemo(() => {
    if (bikeToOpen === null) {
      return null;
    } else {
      return { code: bikeToOpen.code };
    }
  }, [bikeToOpen]);

  const [openResult, openFailed, , openFailureCode] =
    useMobitOpenBike(bikeCodeToOpen);

  const [pendingUpdate] = useBikeLockUpdate(
    openResult?.ok && bikeCodeToOpen ? bikeCodeToOpen.code : null,
    3000,
    MobitBikeLockStatus.CLOSED
  );

  const [enableCameraOpened, setEnableCameraOpened] = useState(false);

  const [errorDialogNotClosed, setErrorDialogNotClosed] = useState(true);

  const ongoingOpen = useRef(false);

  const updateResult = useCallback((text: string) => {
    if (ongoingOpen.current) {
      return;
    }

    if (text) {
      // code is url with bike code as last query param so we have to extract is
      // e.g. http://gps.mobit.eu/?sn=123456789
      const codes = text.match(/[0-9]+$/) || [""];

      if (codes[0]) {
        ongoingOpen.current = true;

        setScanResult(ScanProgress.SUCCESS);

        setBikeToOpen({ code: codes[0] });

        return;
      }
    }

    setScanResult(ScanProgress.ERROR);
  }, []);

  const [, cameraAvailable, flashAvailable, , , toggleLight] = useQrReader(
    "mobea-scan-preview",
    updateResult
  );

  // redirect to ticket (map) after success
  useEffect(() => {
    if (openResult && openResult.ok && !pendingUpdate) {
      goToTicket();
    }
  }, [goToTicket, openResult, pendingUpdate]);

  // @TODO maybe change icon according to flash light state?
  const openEnableCamera = () => setEnableCameraOpened(true);

  const closeEnableCamera = () => setEnableCameraOpened(false);

  const closeError = () => {
    setBikeToOpen(null);

    setScanResult(ScanProgress.IN_PROGRESS);

    setErrorDialogNotClosed(false);

    ongoingOpen.current = false;
  };

  const buyDayPass = () => history.push(Routes.MobitOrder);

  return (
    <NestedPageOverlay
      pageClassName="mobea-mobit-scan"
      title={t("mobit_unlock.activate_your_bike")}
      headerHeight="compact"
      padding={{
        top: 24,
      }}
      onNavBack={goBack}
    >
      <section className="mobea-mobit-scan__text">
        {cameraAvailable && (
          <>
            {scanResult === ScanProgress.IN_PROGRESS && (
              <>
                <QrCodeIcon className="mobea-mobit-scan__text__icon" />
                <p className="mobea-mobit-scan__text__desc">
                  {t("mobit_scan.find_the_code_text")}
                </p>
              </>
            )}
            {scanResult === ScanProgress.SUCCESS && (
              <>
                <CircleCheckIcon className="mobea-mobit-scan__text__icon__success" />
                <p className="mobea-mobit-scan__text__desc success">
                  {t("mobit_scan.scan_success_text")}
                </p>
              </>
            )}
            {scanResult === ScanProgress.ERROR && (
              <>
                <InfoIcon
                  className="mobea-mobit-scan__text__icon__error"
                  width="24px"
                  height="24px"
                />
                <p className="mobea-mobit-scan__text__desc error">
                  {t("mobit_scan.scan_error_text")}
                </p>
              </>
            )}
          </>
        )}
        {!cameraAvailable && (
          <>
            <QrCodeIcon className="mobea-mobit-scan__text__icon" />
            <p className="mobea-mobit-scan__text__desc">
              {t("mobit_scan.no_camera_text")}
            </p>
          </>
        )}
      </section>

      <section
        className={`mobea-mobit-scan__preview ${
          cameraAvailable ? "white" : ""
        }`}
      >
        <video
          id="mobea-scan-preview"
          className={cameraAvailable ? "" : "hidden"}
        />
        {cameraAvailable && (
          <div className={`mobea-mobit-scan__preview__overlay ${scanResult}`}>
            {scanResult === ScanProgress.SUCCESS && <Spinner size={120} />}
          </div>
        )}
        {!cameraAvailable && (
          <MobeaButton
            className="mobea-mobit-scan__preview__enable-button"
            onClick={openEnableCamera}
          >
            {t("mobit_scan.enable_camera_access")}
          </MobeaButton>
        )}
        <div className="mobea-mobit-scan__preview__controls">
          <button
            className="mobea-mobit-scan__preview__controls__button"
            disabled={!flashAvailable}
            onClick={toggleLight}
          >
            <TorchIcon />
          </button>
          <button
            className="mobea-mobit-scan__preview__controls__button"
            disabled={ongoingOpen.current}
            onClick={goToEnterCode}
          >
            ABC1
          </button>
        </div>
      </section>

      <UnlockBikeDialogs
        bikeOpened={false} // do not show success dialog
        errorDialogNotClosed={errorDialogNotClosed}
        openFailed={openFailed}
        openFailureCode={openFailureCode}
        buyDayPass={buyDayPass}
        goToTicket={goToTicket}
        closeError={closeError}
      />

      {enableCameraOpened && (
        <MobeaModal
          confirmText={t("shared.ok")}
          title={t("mobit_scan.enable_access_to_camera")}
          image={<CameraIcon />}
          onConfirm={closeEnableCamera}
        >
          {t("mobit_scan.enable_access_to_camera_text")}
        </MobeaModal>
      )}
    </NestedPageOverlay>
  );
}
