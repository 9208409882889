import { LabelValue } from "common/forms";
import { NestedPageFull } from "common/page/NestedPageFull";
import { Spinner } from "common/Spinner";
import { ProviderName } from "common/travelPasses";
import { FileIcon } from "icons/FileIcon";
import { useExpenseDetail } from "pages/expense/detail/useExpenseDetail";
import { MouseEvent, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Expense } from "state/actions";
import {
  formatCurrency,
  formatDateWithLocale,
  pushLocation,
  Routes,
} from "utils";
import { BackButtonBehaviour } from "utils/routing";
import "./ExpenseDetailPage.scss";
import { ExpenseDetailStatus } from "./ExpenseDetailStatus";

export interface ExpenseDetailPageProps {
  backButtonBehaviour?: BackButtonBehaviour;
}

export function ExpenseDetailPage({
  backButtonBehaviour = "goHome",
}: ExpenseDetailPageProps): ReactElement {
  const history = useHistory();

  const { id = "" } = useParams<{ id: string }>();

  const { t } = useTranslation();

  const [fullscreenImage, setFullscreenImage] = useState("");

  const [fullScreenImageLoading, setFullscreenImageLoading] = useState(false);

  const [idToLoad, setIdToLoad] = useState<string | null>(null);

  const [loadedExpenseDetail, loadingFailed, loadingDetail] =
    useExpenseDetail(idToLoad);

  const locale = useSelector((state) => state.user.language);

  const expenses = useSelector((state) => state.passes.expenses);

  const decodedId = decodeURIComponent(id);

  let currentExpense = expenses.find((expense) => expense.id === decodedId) as
    | Expense
    | undefined;

  // wait for result while loading or if it fails
  if (loadingDetail || loadingFailed) {
    // update local data with fetched ones
  } else if (!currentExpense && loadedExpenseDetail) {
    currentExpense = loadedExpenseDetail;

    // update expense with loaded one if we have partial data (major use case)
  } else if (
    currentExpense &&
    currentExpense.uploads.length === 0 &&
    loadedExpenseDetail
  ) {
    currentExpense = loadedExpenseDetail;
  } else if (idToLoad === null) {
    // identify not cached item
    (!currentExpense || currentExpense.uploads.length === 0) &&
      setIdToLoad(decodedId);
  }

  const dateFormatted = currentExpense
    ? formatDateWithLocale(new Date(currentExpense.expensedDate), locale, {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
    : "";
  const priceFormatted = currentExpense
    ? formatCurrency(currentExpense.price, locale)
    : "€0";

  const goBack = () => {
    if (backButtonBehaviour === "goHome") {
      pushLocation(history, Routes.Home);
    } else {
      history.goBack();
    }
  };

  const openFullscreen = (e: MouseEvent, url?: string) => {
    const img: Element | null = (e.currentTarget as HTMLAnchorElement)
      .firstElementChild;

    // show full preview if not errored - no preview
    if (img && !img.classList.contains("mobea__image__error") && url) {
      e.preventDefault();

      setFullscreenImage(url);

      setFullscreenImageLoading(true);
    }
  };

  const closeFullscreen = () => {
    setFullscreenImage("");
  };

  return (
    <NestedPageFull
      pageClassName="mobea-expense-detail"
      title={t("expense.expense")}
      padding={{
        bottom: 0,
        right: 0,
        left: 0,
      }}
      onNavBack={goBack}
    >
      {currentExpense && (
        <>
          <section className="mobea-expense-detail__form">
            <div className="mobea-expense-detail__form__row">
              <LabelValue
                label={t("expense.transport_provider")}
                value={<ProviderName provider={currentExpense.provider} />}
              />
              <LabelValue label={t("time.date")} value={dateFormatted} />
              <LabelValue label={t("shared.price")} value={priceFormatted} />
            </div>
          </section>
          <ExpenseDetailStatus
            locale={locale}
            status={currentExpense.status}
            acceptedPrice={currentExpense.acceptedPrice}
            acceptedDate={currentExpense.acceptedDate || Date.now()}
            refusalReason={currentExpense.refusalReason || ""}
            t={t}
          />
          <section className="mobea-expense-detail__uploads">
            <span className="mobea-expense-detail__uploads__label">
              {t("expense.attachments")}
            </span>
            <div className="mobea-expense-detail__uploads__previews">
              {loadingDetail && <Spinner />}
              {!loadingDetail &&
                currentExpense.uploads.map((file) => (
                  <div
                    key={file.name}
                    className="mobea-expense-detail__uploads__previews__preview"
                    onClick={(e) => openFullscreen(e, file.path)}
                  >
                    <div className="mobea-expense-detail__uploads__previews__preview__spacer">
                      &nbsp;
                    </div>
                    <img
                      src={file.preview || file.path}
                      alt={file.name}
                      onError={(e) =>
                        ((e.target as HTMLImageElement).className =
                          "mobea__image__error")
                      }
                    />

                    <FileIcon />
                    <p className="mobea-expense-detail__uploads__previews__preview__name">
                      <span>
                        {file.name.substring(0, file.name.lastIndexOf(".")) ||
                          ""}
                      </span>
                      <span>
                        {file.name.substring(file.name.lastIndexOf(".")) || ""}
                      </span>
                    </p>
                  </div>
                ))}
            </div>
          </section>
        </>
      )}

      {fullscreenImage && (
        <div className="mobea-expense-detail__fullscreen-preview">
          <button
            className="mobea-expense-detail__fullscreen-preview__close mobea__close-button"
            onClick={closeFullscreen}
          >
            &nbsp;
          </button>
          {fullScreenImageLoading && <Spinner />}
          <img
            src={fullscreenImage}
            onLoad={() => setFullscreenImageLoading(false)}
          />
        </div>
      )}

      {!currentExpense && <h2>Sorry cannot find expense</h2>}
    </NestedPageFull>
  );
}
