import { DefaultIconProps } from "icons/props";
import { ReactElement } from "react";

export function WrenchIcon({
  className = "",
  fill = "currentColor",
  height = "29",
  width = "21",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 21 29"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.1 10.5c0-4.1-2.6-7.7-6.4-9.1L12 .8V9A1.4 1.4 0 019 9V.8l-1.8.6a9.6 9.6 0 00-1 17.7v9.2h2.8V20H12v8.2h2.7V19a9.6 9.6 0 005.5-8.6zm-9.6 6.8A6.9 6.9 0 016.4 5v4a4.1 4.1 0 008.2 0V5a6.9 6.9 0 01-4.1 12.3z"
        fill={fill}
      />
    </svg>
  );
}
