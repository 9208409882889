import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

export function PhoneTicketIcon({
  className = "",
  fill = "currentColor",
  height = "24px",
  width = "24px",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1 23c.6 0 1-.2 1.4-.6.3-.4.5-.9.5-1.4V3c0-.5-.2-1-.5-1.4-.4-.4-.8-.6-1.4-.6H7c-.6 0-1 .2-1.4.6A2 2 0 005 3v18c0 .5.2 1 .5 1.4.4.4.8.6 1.4.6H17zm-.1-4H7V4h10v15z"
        fill={fill}
      />
      <path
        d="M15.2 14c.2 0 .4 0 .6-.2l.2-.6v-1.4l-.6-.3a.7.7 0 01-.2-.5c0-.2 0-.4.2-.5l.6-.3V8.8c0-.3 0-.4-.2-.6a.8.8 0 00-.6-.2H8.8c-.2 0-.4 0-.6.2l-.2.6v1.4l.6.3.2.5c0 .2 0 .4-.2.5l-.6.3v1.4c0 .3 0 .4.2.6l.6.2h6.4zm-2-1l-1.2-.8-1.2.8.3-1.5-1.1-1h1.5L12 9l.5 1.4 1.5.1-1.1 1 .3 1.5z"
        fill={fill}
      />
    </svg>
  );
}
