import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  checkIsMobitBikeAvailable,
  MobitNearbyBike,
} from "services/mobitService";

export enum MobitBikeLockStatus {
  OPEN,
  CLOSED,
}

export interface CurrentBikeInfo extends MobitNearbyBike {
  lockStatus: MobitBikeLockStatus;
}

const CURRENT_BIKE_DATA_UPDATE_INTERVAL = 7000;

export function useCurrentBikeInfo(
  bikeCode: string | null,
  continuesUpdates = false
) {
  const history = useHistory();

  const [result, setResult] = useState<CurrentBikeInfo | null>(null);

  const [pollingId, setPollingId] = useState<number | null>(null);

  useEffect(() => {
    let timeoutId: number;

    let canceled = false;

    async function checkBikeInfo(code: string) {
      try {
        const json = await checkIsMobitBikeAvailable(code, history);

        if (!json.error) {
          if (!canceled) {
            setResult({
              lockStatus: json.data.lockStatus,
              lng: json.data.location.x,
              lat: json.data.location.y,
              frontCode: json.data.frontCode,
              backCode: json.data.backCode,
            });

            if (continuesUpdates) {
              timeoutId = window.setTimeout(() => {
                // trigger re-running the hook
                setPollingId(timeoutId);
              }, CURRENT_BIKE_DATA_UPDATE_INTERVAL);
            }
          }
        } else {
          console.error(
            "Failed to update current bike info",
            json.error_description
          );
        }
      } catch (error) {
        console.error("Failed to update current bike info", error);
      }
    }

    if (bikeCode) {
      checkBikeInfo(bikeCode);
    } else {
      setResult(null);
    }

    return () => {
      canceled = true;

      timeoutId && window.clearTimeout(timeoutId);
    };
  }, [bikeCode, pollingId, continuesUpdates, history]);

  const cancel = () => {
    pollingId && window.clearTimeout(pollingId);
  };

  return [result, cancel] as const;
}
