import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getNmbsStationsList } from "services/nmbsService";
import { NmbsStation, setNmbsStationsAction } from "state/actions";

export function useNmbsStationsList() {
  const history = useHistory();

  const dispatch = useDispatch();

  const { nmbsStationsUpdateTimestamp: lastLoadTimestamp, nmbsStations } =
    useSelector((state) => state.ordering);

  const [loading, setLoading] = useState(false);
  const [retryAttempt, setRetryAttempt] = useState(0);

  const retry = () => setRetryAttempt((key) => ++key);

  useEffect(() => {
    let canceled = false;

    async function fetchNmbsStations() {
      try {
        setLoading(true);

        const stationsListJson = await getNmbsStationsList(history);

        if (!stationsListJson.error) {
          const normalizedStations: NmbsStation[] = stationsListJson.stations
            .map((station) => ({
              id: station.Id,
              label: (station.Name || "").toLowerCase(),
              language: station.Language.toLowerCase() ?? "",
            }))
            .filter(
              (station) =>
                typeof station.label === "string" && station.label.length > 0
            );

          if (!canceled) {
            setLoading(false);

            dispatch(setNmbsStationsAction(normalizedStations));
          }
        } else {
          !canceled && setLoading(false);

          console.error(
            "Failed to fetch NMBS stations list",
            stationsListJson.error_description
          );

          // retry on fail 2x as NMBS has issues sometimes
          retryAttempt < 2 && retry();
        }
      } catch (error) {
        !canceled && setLoading(false);

        console.error("Failed to fetch NMBS stations list", error);
        retryAttempt < 2 && retry();
      }
    }

    // fetch stations only when missing
    if (!lastLoadTimestamp || nmbsStations.length === 0) {
      fetchNmbsStations();
    }

    return () => {
      canceled = true;

      console.debug("Canceled hook useNmbsStationsList");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history, retryAttempt]);

  return [loading] as [boolean];
}
