import { InputGroup, InputGroupRef } from "common/forms";
import { CloseIcon } from "icons/CloseIcon";
import { SearchIcon } from "icons/SearchIcon";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { AppColors } from "utils/colors";
import "./SearchField.scss";

export interface SearchFieldRef {
  focus(): void;
}

export type SearchFieldProps = {
  className?: string;
  value: string;
  onChange(value: string): void;
  onClose?(): void;
};

export const SearchField = forwardRef<SearchFieldRef, SearchFieldProps>(
  ({ className = "", value, onChange, onClose }, ref) => {
    const inputGroupRef = useRef<InputGroupRef>(null);

    useImperativeHandle(ref, () => ({
      focus() {
        inputGroupRef.current?.focus();
      },
    }));

    return (
      <div className={`mobea-search-field ${className}`}>
        <InputGroup
          ref={inputGroupRef}
          className="mobea-search-field__input"
          name="search-field"
          label=""
          value={value}
          onChange={(value) => onChange(value.toString())}
          inputAttributes={{
            autoComplete: "off",
          }}
        >
          <SearchIcon className="mobea-search-field__search-icon" />

          <CloseIcon
            className="mobea-search-field__close-icon"
            fill={AppColors.GRAY_300}
            height={18}
            width={18}
            onClick={onClose}
          />
        </InputGroup>
      </div>
    );
  }
);

SearchField.displayName = "SearchField";
