import { ReactElement } from "react";
import { DefaultIconProps } from "../props";

export function MapIcon({
  fill = "currentColor",
  className = "",
  height = "24",
  width = "24",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="mobea__navigation__inactive"
        fill={fill}
        d="m 20.104,2.558 -0.16,0.03 -5.34,2.0699999 -6,-2.0999999 -5.64,1.8999999 c -0.21,0.07 -0.36,0.25 -0.36,0.48 V 20.058 c 0,0.28 0.22,0.5 0.5,0.5 l 0.16,-0.03 5.34,-2.07 6,2.1 5.64,-1.9 c 0.21,-0.07 0.36,-0.25 0.36,-0.48 V 3.058 c 0,-0.28 -0.22,-0.5 -0.5,-0.5 z m -10.5,2.4699999 4,1.3999994 V 18.088 l -4,-1.4 z M 4.6039992,6.0179993 7.604,5.0079999 V 16.708 l -3.0000008,1.16 z M 18.604,17.098 l -3,1.01 V 6.4179993 l 3,-1.1600001 z"
      />
      <path
        className="mobea__navigation__active"
        fill={fill}
        d="M15,21 L20.625,19.078125 C20.875,19.015625 21,18.859375 21,18.609375 L21,18.609375 L21,3.515625 C21,3.171875 20.828125,3 20.484375,3 L20.484375,3 L20.34375,3.046875 L15,5.109375 L9,3 L3.375,4.921875 C3.125,4.984375 3,5.140625 3,5.390625 L3,5.390625 L3,20.484375 C3,20.828125 3.171875,21 3.515625,21 L3.515625,21 L3.65625,20.953125 L9,18.890625 L15,21 Z M15,18.984375 L9,16.875 L9,5.015625 L15,7.125 L15,18.984375 Z"
        fillRule="nonzero"
      />
    </svg>
  );
}
