import { EaBrandLogo } from "icons/logos/EaBrandLogo";
import { useEffect, useMemo, useState } from "react";
import { getConfig } from "utils/configure";

export function useClientLogo() {
  const [aspectRatio, setAspectRatio] = useState<
    "square" | "wide" | "tall" | "taller" | "wider" | "widest"
  >("square");

  const [css, setCss] = useState<any>({});

  const clientLogo = getConfig().clientLogo;

  const getImageSrc = () =>
    clientLogo.startsWith("http")
      ? clientLogo
      : `data:image/svg+xml;utf8,${encodeURIComponent(clientLogo)}`;

  useEffect(() => {
    if (!clientLogo) {
      return;
    }

    const image = new Image();
    image.src = getImageSrc();
    image.onload = (e) => {
      const target = e.target as HTMLImageElement;
      const aspectRatio = target.naturalWidth / target.naturalHeight;

      if (aspectRatio > 2.7) {
        setCss({
          maxWidth: "min(70%, 250px)",
        });
        setAspectRatio("widest");
      } else if (aspectRatio > 2) {
        setCss({
          maxWidth: "min(60%, 220px)",
        });
        setAspectRatio("wider");
      } else if (aspectRatio > 1.33) {
        setCss({
          maxWidth: "min(50%, 180px)",
        });
        setAspectRatio("wide");
      } else if (aspectRatio < 0.5) {
        setCss({
          maxHeight: "20vh",
        });
        setAspectRatio("taller");
      } else if (aspectRatio < 0.67) {
        setCss({
          maxHeight: "15vh",
        });
        setAspectRatio("tall");
      } else {
        setCss({
          maxWidth: "40%",
        });
        setAspectRatio("square");
      }
    };
    // deps never change and should run exactly once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(() => {
    if (!clientLogo) {
      return <EaBrandLogo />;
    } else {
      return (
        <img src={getImageSrc()} alt="logo" className={aspectRatio} css={css} />
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aspectRatio, css]);
}
