import { UserLocationIcon } from "maps/icons";
import Location from "maps/location";
import { useEffect, useMemo, useRef, useState } from "react";
import { LocationCoords } from "services/mapService";

const H = window.H;

// create current location icon without hit area so clicks go through
const CurrentLocationIcon = () =>
  new H.map.Icon(UserLocationIcon(), {
    anchor: new H.math.Point(15, 15),
    crossOrigin: false,
    hitArea: new H.map.HitArea(H.map.HitArea.ShapeType.NONE),
  });

export enum LocationError {
  None,
  PermissionDenied,
  PositionUnavailable,
  Timeout,
}

export function useLocationUpdates() {
  const [userLocation, setUserLocation] = useState<LocationCoords | null>(null);
  const [locationError, setLocationError] = useState<LocationError>(
    LocationError.None
  );

  const userLocationGroupRef: { current: H.map.Group | null } = useRef(
    null
  ) as any;

  // cache icon so we do not create new one on every render
  const currentLocationIcon = useMemo(CurrentLocationIcon, []);

  const updateMarker = (coords: LocationCoords) => {
    const userLocationGroup = userLocationGroupRef.current;

    if (!userLocationGroup || !coords.lat || !coords.lng) {
      return;
    }

    const marker = new H.map.Marker(
      {
        ...coords,
        alt: 0,
      },
      {
        icon: currentLocationIcon,
        zIndex: 10,
      }
    );

    userLocationGroup.removeAll();
    userLocationGroup.addObject(marker);
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const userLocationWatchId = Location.requestWatch(
      (
        error: GeolocationPositionError | undefined,
        coords?: LocationCoords
      ) => {
        if (error) {
          setLocationError(error.code as LocationError);
          // make center unknown so we get error message
        } else if (coords) {
          updateMarker(coords);
          setUserLocation(coords);
        }
      }
    );

    return () => {
      console.debug("Canceled useLocationUpdates hook");
      userLocationWatchId && Location.clearWatch(userLocationWatchId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setUserLocationGroup = (group: H.map.Group) => {
    userLocationGroupRef.current = group;
    updateMarker({
      lat: userLocation?.lat || 0,
      lng: userLocation?.lng || 0,
    });
  };

  return [userLocation, setUserLocationGroup, locationError] as const;
}
