import { Spinner } from "common/Spinner";
import { AppColors } from "utils/colors";

interface Props {
  loading: boolean;
}
export function HistoryPageSpinner({ loading }: Props) {
  return (
    <div
      css={{
        position: "absolute",
        top: 32,
        left: 0,
        right: 0,
        zIndex: 10,
        opacity: loading ? 1 : 0,
        transition: "opacity 200ms",
        pointerEvents: loading ? "auto" : "none",
        height: "100%",
        background: AppColors.WHITE,
        paddingTop: 40,
        "> .mobea__spinner": {
          marginTop: 0,
        },
      }}
    >
      <Spinner />
    </div>
  );
}
