import { Action } from "state";
import { getConfig } from "utils/configure";
import { LOCAL_STORAGE_TRACKING_KEY } from "utils/constants";
import { getInitialStateWithPersistance } from "utils/helpers";
import { OnboardingState } from "./stateTypes";

const initialState: OnboardingState = {
  welcomeDone: false, // functionality is removed for now
  tutorialWatched: false,
  installNotificationCanceled: false,
  verificationRequested: false,
  appLaunchTracked: false,
  cookiesAccepted: false,
  trackingAccepted: false,
  advertisingAccepted: false,
  mobitParkingZonesAcknowledged: false,
  expenseIbanDisplayed: false,
  providersTutorialWatched: {},
  termsAccepted: false,
  privacyAccepted: false,
};

export function OnboardingReducer(
  state: OnboardingState = getInitialStateWithPersistance(
    "onboarding",
    initialState
  ),
  action: Action
): OnboardingState {
  switch (action.type) {
    case "WELCOME_DONE":
      return {
        ...state,
        welcomeDone: true,
      };

    case "TUTORIAL_FINISHED":
      return {
        ...state,
        tutorialWatched: true,
      };

    case "INSTALL_NOTIFICATION_CANCEL": {
      return {
        ...state,
        installNotificationCanceled: true,
      };
    }

    case "REQUESTED_VERIFICATION": {
      return {
        ...state,
        verificationRequested: true,
      };
    }

    case "FINISHED_VERIFICATION": {
      return {
        ...state,
        verificationRequested: false,
      };
    }

    case "APP_LAUNCH_TRACKED":
      return {
        ...state,
        appLaunchTracked: true,
      };

    case "COOKIES_ACCEPTED":
      return {
        ...state,
        cookiesAccepted: true,
      };

    case "ANALYTICS_CHANGED": {
      window.localStorage.setItem(
        LOCAL_STORAGE_TRACKING_KEY,
        action.accepted.toString()
      );
      action.accepted
        ? window.mobeaAddTracking(getConfig().googleAnalyticsId)
        : window.mobeaClearTracking();

      return {
        ...state,
        trackingAccepted: action.accepted,
      };
    }

    case "ADVERTISING_CHANGED":
      return {
        ...state,
        advertisingAccepted: action.accepted,
      };

    case "MOBIT_PARKING_ZONES_ACKNOWLEDGED":
      return {
        ...state,
        mobitParkingZonesAcknowledged: true,
      };

    case "EXPENSE_IBAN_DISPLAYED":
      return {
        ...state,
        expenseIbanDisplayed: true,
      };

    case "PROVIDER_TUTORIAL_WATCHED":
      return {
        ...state,
        providersTutorialWatched: {
          ...state.providersTutorialWatched,
          [action.provider]: true,
        },
      };

    case "TERMS_PRIVACY_ACCEPTED": {
      return {
        ...state,
        privacyAccepted: action.accepted,
        termsAccepted: action.accepted,
      };
    }

    default:
      return state;
  }
}
