import { LabelValue, MobeaButton } from "common/forms";
import { useBooleanState } from "common/hooks";
import { MobeaModal } from "common/modal";
import { TicketStartEnd } from "common/travelPasses/components/time/TicketStartEnd";
import { useVeloActivate } from "common/travelPasses/velo/hooks/useVeloActivate";
import { KeyIcon } from "icons/KeyIcon";
import { LabelIcon } from "icons/LabelIcon";
import { PhoneIcon } from "icons/PhoneIcon";
import { ProfileIcon } from "icons/ProfileIcon";
import { useVeloData } from "pages/velo/hooks/useVeloData";
import { MouseEvent, ReactElement, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  VeloStatus,
  VeloTravelPass as VeloTravelPassType,
} from "state/actions/TravelPassActions";
import {
  formatCurrency,
  padWithZero,
  pushLocation,
  Routes,
  VELO_SUPPORT_PHONE_NUMBER,
} from "utils";
import { AppColors } from "utils/colors";
import { TravelPassInfoLine } from "../components/TravelPassInfoLine";
import { TravelPassWrapper } from "../TravelPassWrapper";
import "./VeloTravelPass.scss";

type Props = VeloTravelPassType & {
  isPreview?: boolean;
  locale: string;
  onSelect?(): void;
  refresh(): void;
};

export function VeloTravelPass({
  isPreview = false,
  id,
  provider,
  status,
  activation,
  expiration,
  userId,
  password,
  price,
  locale,
  onSelect,
  refresh,
}: Props): ReactElement {
  const history = useHistory();

  const { t } = useTranslation();

  const { duration, validity } = useSelector(
    (state) => state.ordering.veloData
  );

  const [
    activateConfirmDialogVisible,
    showActivateConfirmDialog,
    hideActivateConfirmDialog,
  ] = useBooleanState();

  const [contactDialogVisible, showContactDialog, hideContactDialog] =
    useBooleanState();

  const [activate, activating, activationSuccess] = useVeloActivate();

  useVeloData(isPreview);

  useEffect(() => {
    if (activationSuccess) {
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activationSuccess]);

  const showDetail = (e: MouseEvent) => {
    if (onSelect) {
      e.preventDefault();

      onSelect();
    }
  };

  const callVelo = () => {
    window.open(`tel:${VELO_SUPPORT_PHONE_NUMBER}`);
  };

  const getContactText = () => {
    switch (status) {
      case VeloStatus.Active:
        return t("velo_detail.contact_text_active");
      case VeloStatus.Charged:
        return t("velo_detail.contact_text_charged");
      default:
        return t("velo_detail.contact_text");
    }
  };

  const findStation = () => {
    pushLocation(history, Routes.VeloMap.replace(":id", id));
  };

  return (
    <TravelPassWrapper
      className={`mobea__velo-ticket ${isPreview ? "preview" : ""}`}
      provider={provider}
      activation={activation}
      expiration={expiration}
      locale={locale}
      onClick={showDetail}
    >
      <section className="status-section">
        <div className={`status-section__status ${status}`}>
          <div className="background"></div>
          {t(`velo_detail.status.${status}`)}
        </div>

        <div className="status-section__day-pass">
          {t("velo_order.day_pass")}
        </div>
      </section>

      <section className="image-section">
        <img
          className="image-section__bike-image"
          src="/static/images/velo-bike.png"
          alt="bike"
        />
      </section>

      {status === VeloStatus.Ready && (
        <section className="info-section">
          <TravelPassInfoLine>
            <Trans i18nKey="velo_detail.info_validity">{{ validity }}</Trans>
          </TravelPassInfoLine>

          <TravelPassInfoLine>
            <Trans i18nKey="velo_detail.info_duration">{{ duration }}</Trans>
          </TravelPassInfoLine>

          <TravelPassInfoLine>
            <Trans i18nKey="velo_detail.info_charges">
              {{ duration }}{" "}
              <a
                href="https://www.velo-antwerpen.be/en/rates"
                target="_blank"
                rel="noreferrer"
              >
                See the prices
              </a>
            </Trans>
          </TravelPassInfoLine>
          <TravelPassInfoLine>
            {t("velo_detail.info_end_ride")}
          </TravelPassInfoLine>
        </section>
      )}

      {status !== VeloStatus.Ready && (
        <section className="details-section">
          {status !== VeloStatus.Charged && (
            <>
              <TicketStartEnd
                validityStart={activation}
                validityEnd={expiration}
              />
              <div className="details-section__separator" />
            </>
          )}

          <div className="details-section__info">
            <LabelValue
              icon={<ProfileIcon outlined={true} />}
              label={t("velo_detail.user_id")}
              value={userId}
            />
            <LabelValue
              icon={<KeyIcon />}
              label={t("velo_detail.password")}
              value={padWithZero(password ?? 0, 4)}
            />
            <LabelValue
              className={status === VeloStatus.Charged ? "charged" : ""}
              icon={<LabelIcon width={16} height={16} outlined />}
              label={t("shared.price")}
              value={formatCurrency(price, locale)}
            />
          </div>

          {status === VeloStatus.Charged && (
            <div className="details-section__charge-info">
              <Trans i18nKey="velo_detail.charge_info">
                <span className="mobea__arial">{{ duration }}</span>
                <a
                  href="https://www.velo-antwerpen.be/en/rates"
                  target="_blank"
                  rel="noreferrer"
                >
                  See the prices
                </a>
              </Trans>
            </div>
          )}
        </section>
      )}

      {isPreview && status === VeloStatus.Ready && (
        <section className="preview-info-section">
          <LabelValue
            label={t("time.from")}
            value={t("velo_detail.valid_after_activation")}
          />
        </section>
      )}

      {!isPreview && (
        <section className="actions-section">
          {status === VeloStatus.Ready && (
            <MobeaButton
              type="secondary"
              onClick={showActivateConfirmDialog}
              loading={activating}
              disabled={activating}
            >
              {activating ? t("shared.in_progress") : t("velo_detail.activate")}
            </MobeaButton>
          )}

          {status === VeloStatus.Active && (
            <MobeaButton type="secondary" onClick={findStation}>
              {t("velo_detail.find_station")}
            </MobeaButton>
          )}

          <MobeaButton
            className={`phone-button ${
              status === VeloStatus.Expired || status === VeloStatus.Charged
                ? "wide"
                : ""
            }`}
            type="secondary"
            onClick={showContactDialog}
          >
            <PhoneIcon outlined className="phone-icon" />
            {(status === VeloStatus.Expired || status === VeloStatus.Charged) &&
              t("velo_detail.contact_title")}
          </MobeaButton>
        </section>
      )}

      {activateConfirmDialogVisible && (
        <MobeaModal
          title={t("velo_detail.activate_confirm_title")}
          confirmText={t("velo_detail.activate_confirm")}
          onConfirm={() => {
            hideActivateConfirmDialog();
            activate(id);
          }}
          cancelText={t("shared.cancel")}
          onCancel={hideActivateConfirmDialog}
          usePortal={true}
        >
          <p>
            <Trans i18nKey="velo_detail.activate_confirm_text">
              <span className="mobea__arial">{{ validity }}</span>
            </Trans>
          </p>
        </MobeaModal>
      )}

      {contactDialogVisible && (
        <MobeaModal
          title={t("velo_detail.contact_title")}
          confirmText={t("velo_detail.contact_title")}
          onConfirm={callVelo}
          onClose={hideContactDialog}
          usePortal={true}
        >
          <PhoneIcon
            outlined
            fill={AppColors.GRAY_100}
            width="88"
            height="88"
          />

          <p>{getContactText()}</p>
        </MobeaModal>
      )}
    </TravelPassWrapper>
  );
}
