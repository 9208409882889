import { ChevronIcon } from "icons/ChevronIcon";
import { ReactElement } from "react";
import { useSelector } from "react-redux";
import { RoutePlanTripSegment } from "services/mapService";
import { AppColors, AppFonts } from "utils/colors";
import { getRoutePlannerTravelTypeIcon } from "../../functions";
import { TripProviderIconWrapper } from "./TripProviderIconWrapper";

type Props = {
  className?: string;
  iconsOnly?: boolean;
  size?: number;
  segments: RoutePlanTripSegment[];
};

export function TripSegments({
  className = "",
  iconsOnly = false,
  size = 32,
  segments,
}: Props): ReactElement {
  const providersTypesMapping = useSelector(
    (state) => state.passes.providersToTypes
  );

  return (
    <div
      className={`route-planner-transports ${className}`}
      css={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      {segments.map((segment, index, array) => (
        <div
          key={segment.id}
          className="route-planner-transport"
          css={{
            display: "flex",
            alignItems: "center",
            fontFamily: AppFonts.NUMBERS,
            fontSize: 12,
            lineHeight: "16px",
            color: AppColors.GRAY_400,
          }}
        >
          {iconsOnly ? (
            getRoutePlannerTravelTypeIcon(segment, providersTypesMapping)
          ) : (
            <TripProviderIconWrapper
              segmentInfo={{
                type: segment.type,
                providers: segment.providers,
              }}
              size={size}
              outlined={false}
            />
          )}

          {segment.serviceNumber && (
            <div
              className="route-planner-transports__number"
              css={{
                color: AppColors.GRAY_400,
                fontFamily: AppFonts.NUMBERS,
                fontSize: 12,
                lineHeight: "16px",
                marginLeft: 4,
              }}
            >
              {segment.serviceNumber}
            </div>
          )}

          {index < array.length - 1 && (
            <ChevronIcon
              width={16}
              height={16}
              className="chevron-icon"
              orientation="right"
              css={{
                color: AppColors.GRAY_200,
                marginRight: 4,
              }}
            />
          )}
        </div>
      ))}
    </div>
  );
}
