import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

export function IosAddIcon({
  className = "",
  fill = "#007AFF",
  height = "20px",
  width = "15px",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 15 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 6v.7H.8v12.6h13.4V6.7H9.5V6H15v14H0V6h5.5zm2-6L11 3.4l-.6.6L8 1.6V13H7V1.6L4.6 4 4 3.4 7.5 0z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
}
