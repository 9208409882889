import { ReactElement, ReactNode } from "react";

type Props = {
  infoText: ReactNode;
};

export function TutorialLine({ infoText }: Props): ReactElement {
  return (
    <div className="mobea__tutorial-dialog__info__info-line">
      <span className="mobea__tutorial-dialog__info__info-line__bullet">•</span>

      <div className="mobea__tutorial-dialog__info__info-line__text">
        {infoText}
      </div>
    </div>
  );
}
