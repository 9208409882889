import {
  LocationBoundingBox,
  useNearbyEfences,
} from "pages/mobit/hooks/useNearbyEfences";
import { useEffect, useRef } from "react";
import { MobitEfenceStyle } from "../icons";

const H = window.H;

export function useEfences(boundingBox: LocationBoundingBox | null) {
  const efencesGroupRef = useRef<H.map.Group | null>(null);

  const [efences] = useNearbyEfences(boundingBox);

  useEffect(() => {
    if (efences) {
      const shapes = efences.map(
        (efence) =>
          new H.map.Polygon(
            H.geo.LineString.fromLatLngArray(
              efence.loc.points.flatMap(({ lat, lng }) => [lat, lng])
            ),
            {
              zIndex: efence.zOrder,
              style: MobitEfenceStyle,
              data: { ...efence },
            }
          )
      );

      // clean previous items
      efencesGroupRef.current?.removeAll();

      efencesGroupRef.current?.addObjects(shapes);
    }
  }, [efences]);

  return [efencesGroupRef] as const;
}
