import { useBudget, useNetwork } from "common/hooks";
import { useModal } from "common/hooks/useModal";
import { CheckMarkIcon } from "icons/CheckMarkIcon";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { formatCurrency, WalletType } from "utils";
import { AppColors } from "utils/colors";
import { NetworkState } from "utils/network";

export function useWalletChooser(onConfirm: (wallet: WalletType) => void) {
  const { t } = useTranslation();

  const {
    amount: businessAmount,
    outOfBudgetPeriod,
    language,
    privateAmount,
  } = useSelector((state) => state.user);

  const [loading] = useBudget();

  const network = useNetwork();

  const formattedBusinessAmount = formatCurrency(
    Math.max(businessAmount, 0),
    language
  );

  const formattedPrivateAmount = formatCurrency(
    Math.max(privateAmount, 0),
    language
  );

  const businessWalletDisabled =
    loading ||
    businessAmount <= 0 ||
    outOfBudgetPeriod ||
    network !== NetworkState.online;

  const privateWalletDisabled =
    loading || privateAmount <= 0 || network !== NetworkState.online;

  const [wallet, setWallet] = useState<WalletType>();

  useEffect(() => {
    if (!wallet) {
      setWallet(
        !businessWalletDisabled
          ? WalletType.BUSINESS
          : !privateWalletDisabled
          ? WalletType.PRIVATE
          : undefined
      );
    }
  }, [businessWalletDisabled, privateWalletDisabled, wallet]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setWallet(e.currentTarget.value as WalletType);
  };

  const walletChooser = useModal({
    type: "secondary",
    title: t("wallet.select_title"),
    confirmText: t("shared.continue"),
    onConfirm: () => {
      if (wallet) {
        onConfirm(wallet);
      }
    },
    onCancel: () => undefined,
    confirmDisabled: businessWalletDisabled && privateWalletDisabled,
    children: (
      <>
        <p>{t("wallet.select_prompt")}</p>

        <form
          css={{
            display: "flex",
            flexDirection: "column",
            "& label": {
              borderRadius: 2,
              marginTop: "1rem",
              lineHeight: 1.5,
              transition: "all 0.2s",
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              justifyItems: "start",

              "& svg": {
                gridRow: "1/3",
                border: "none",
                margin: "1rem",
                borderRadius: "100vw",
                transition: "all 0.2s",
              },

              "& span:first-of-type": {
                gridRow: 1,
                alignSelf: "end",
                fontWeight: 700,
              },

              "& span:last-of-type": {
                gridRow: 2,
                alignSelf: "start",
                color: AppColors.GRAY_200,
                fontSize: "0.75rem",
              },

              '&[data-checked="false"]': {
                border: "1px solid " + AppColors.GRAY_100,

                "& svg": {
                  color: "rgba(0, 0, 0, 0)",
                  boxShadow: "inset 0 0 0 1.5px " + AppColors.GRAY_100,
                },
              },

              '&[data-checked="true"]': {
                border: "1px solid " + AppColors.PRIMARY,
                backgroundColor: "rgba(var(--rgb-primary), 0.08)",

                "& svg": {
                  color: AppColors.TEXT_INVERTED,
                  boxShadow: "inset 0 0 0 1.5px " + AppColors.PRIMARY,
                  backgroundColor: AppColors.PRIMARY,
                },
              },

              '&[data-disabled="true"]': {
                color: AppColors.GRAY_300,
                border: "1px solid " + AppColors.GRAY_100,
                backgroundColor: AppColors.BACKGROUND,

                "& svg": {
                  color: "rgba(0,0,0,0)",
                  boxShadow: "inset 0 0 0 1.5px " + AppColors.GRAY_100,
                },
              },
            },

            "& input": {
              opacity: 0,
              position: "absolute",
              pointerEvents: "none",
            },
          }}
        >
          <label
            data-checked={wallet === WalletType.BUSINESS}
            data-disabled={businessWalletDisabled}
          >
            <input
              type="radio"
              name="wallet"
              value={WalletType.BUSINESS}
              onChange={handleChange}
              checked={wallet === WalletType.BUSINESS}
              disabled={businessWalletDisabled}
            />

            <CheckMarkIcon />

            <span>{t("wallet.business")}</span>

            <span>{formattedBusinessAmount}</span>
          </label>

          {/* <label
            data-checked={wallet === WalletType.PRIVATE}
            data-disabled={privateWalletDisabled}
          >
            <input
              type="radio"
              name="wallet"
              value={WalletType.PRIVATE}
              onChange={handleChange}
              checked={wallet === WalletType.PRIVATE}
              disabled={privateWalletDisabled}
            />

            <CheckMarkIcon />

            <span>{t("wallet.private")}</span>

            <span>{formattedPrivateAmount}</span>
          </label> */}
        </form>
      </>
    ),
  });

  return walletChooser;
}
