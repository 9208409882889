import { fetchTimeout } from "services/fetchTimeout";
import { ADMIN_API_BASE } from "./constants";

export enum NetworkState {
  online,
  offline,
  incident, // API incident - similar to offline
}

let networkState = window.navigator.onLine
  ? NetworkState.online
  : NetworkState.offline;

export function getNetworkState() {
  return networkState;
}

// provide direct access to state for low level use
export function getRawNetworkState() {
  return window.navigator.onLine ? NetworkState.online : NetworkState.offline;
}

export type NetworkChangeListener = (state: NetworkState) => void;

let networkChangeListenersStack: NetworkChangeListener[] = [];

export function addNetworkChangeListener(listener: NetworkChangeListener) {
  if (networkChangeListenersStack.includes(listener)) {
    console.warn("Listener is already added to the stack", listener);
  } else {
    networkChangeListenersStack.push(listener);
  }
}

export function removeNetworkChangeListener(listener: NetworkChangeListener) {
  networkChangeListenersStack = networkChangeListenersStack.filter(
    (item) => item !== listener
  );
}

function executeStack() {
  networkChangeListenersStack.forEach((listener) => listener(networkState));
}

function triggerOffline() {
  networkState = NetworkState.offline;
  executeStack();
}

function triggerOnline() {
  networkState = NetworkState.online;
  executeStack();
}

export function triggerApiIncident() {
  networkState = NetworkState.incident;
  executeStack();
}

class ApiHealthChecker {
  private static API_HEALTH_CHECK_INTERVAL = 15000;

  private static doCheck = async () => {
    try {
      const response = await fetchTimeout(ADMIN_API_BASE + "health", {
        // 5000 was not enough sometimes and caused false positive cases
        timeout: 7000,
      });

      if (response.status === 200) {
        triggerOnline();
      } else {
        triggerApiIncident();
      }
    } catch (error) {
      triggerApiIncident();
    }
  };

  static interval: number;

  static start = () => {
    ApiHealthChecker.doCheck();

    ApiHealthChecker.interval = window.setInterval(
      ApiHealthChecker.doCheck,
      ApiHealthChecker.API_HEALTH_CHECK_INTERVAL
    );
  };
}

export const startApiHealthChecking = ApiHealthChecker.start;

window.addEventListener("online", triggerOnline);

window.addEventListener("offline", triggerOffline);
