import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

export function GlobeIcon({
  fill = "currentColor",
  className = "",
  height = "16",
  width = "16",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 16c2.2 0 4.1-.8 5.7-2.3A7.7 7.7 0 0016 8c0-2.2-.8-4-2.3-5.7A7.7 7.7 0 008 0C5.8 0 3.9.8 2.3 2.4A7.7 7.7 0 000 8c0 2.2.8 4 2.3 5.7A7.7 7.7 0 008 16zm4.4-4a1.6 1.6 0 00-1.5-1.1h-.8V8.6c0-.2 0-.4-.2-.6a.7.7 0 00-.6-.2H4.7V6.2h1.5c.2 0 .4 0 .6-.2l.2-.6V4h1.6c.4 0 .7-.2 1-.5.3-.3.5-.7.5-1V2a6 6 0 012.8 2.3c.7 1 1.1 2.2 1.1 3.5a6 6 0 01-1.6 4.2zm-5 2.7c-1.6-.2-2.9-1-3.9-2.2A6.7 6.7 0 012 8.1l.1-1.4 3.7 4v.7c0 .5.1.9.4 1.2.3.3.7.5 1.1.5v1.6z"
        fill={fill}
      />
    </svg>
  );
}
