import { ReactElement } from "react";
import "./DropdownGroup.scss";
import { MobeaDropdown, MobeaDropdownProps } from "./MobeaDropdown";

export interface DropdownGroupProps extends MobeaDropdownProps {
  label: string;
}

export function DropdownGroup({
  label,
  className,
  ...dropDownProps
}: DropdownGroupProps): ReactElement {
  return (
    <div className={`mobea__dropdown-group ${className}`}>
      <label>{label}</label>
      <MobeaDropdown {...dropDownProps} />
    </div>
  );
}
