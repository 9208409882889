import styled from "@emotion/styled";
import { useBooleanState } from "common/hooks";
import { TravelTypeIcon } from "common/travelPasses/components/TravelTypeIcon";
import { LocationCurrentIcon } from "icons/LocationCurrentIcon";
import { LocationCursorIcon } from "icons/navigation";
import { PreviousIcon } from "icons/PreviousIcon";
import { LocationError } from "maps/effects";
import { MyLocationAddress } from "maps/effects/useReverseGeocode";
import { LocationAccessDeniedDialog } from "maps/LocationAccessDeniedDialog";
import { splitToHighlights } from "maps/mapUtils";
import { ReactElement, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LocationCoords, LocationSearchSuggestion } from "services/mapService";
import { TravelProvider } from "state/actions";
import { formatDistance, LOCATION_SUGGESTIONS_LIMIT } from "utils";
import { AppColors, AppFonts } from "utils/colors";
import { OptionLoader } from "../optionsLoader/OptionLoader";

type Props = {
  searchText: string;
  previousLocation?: LocationSearchSuggestion | null;
  locations: LocationSearchSuggestion[];
  loadingLocations: boolean;
  providers: TravelProvider[];
  loadingProviders: boolean;
  allowMyLocation: boolean;
  userLocation?: LocationCoords | null;
  myLocationAddress?: MyLocationAddress | null;
  locationError?: LocationError;
  locationSelected: (
    LocationSearchSuggestion: LocationSearchSuggestion
  ) => void;
  providerSelected: (TravelProvider) => void;
};

const StyledSectionTitle = styled.div({
  marginTop: 20,
  fontSize: 12,
  fontWeight: "bold",
  lineHeight: "24px",
});

const StyledOptionItem = styled.div({
  display: "flex",
  paddingTop: 8,
  alignItems: "flex-start",
});

const StyledOptionItemIcon = styled.div({
  flexShrink: 0,
  width: 32,
  height: 32,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 16,
});

const StyledOptionItemWrapper = styled.div({
  flex: "1 0",
  marginLeft: 16,
  paddingBottom: 12,
  borderBottom: `1px solid ${AppColors.BACKGROUND}`,
});

const StyledOptionItemName = styled.div({
  color: AppColors.GRAY_400,
  fontSize: 14,
  fontWeight: 500,
  lineHeight: "20px",
  ".highlighted": {
    color: AppColors.PRIMARY,
    fontWeight: "bold",
    textDecoration: "underline",
  },
});

const StyledOptionItemInfo = styled.div({
  color: AppColors.GRAY_200,
  fontFamily: AppFonts.NUMBERS,
  fontSize: 12,
  lineHeight: "16px",
});

export function LocationProviderOptions({
  searchText,
  previousLocation,
  locations,
  loadingLocations,
  providers,
  loadingProviders,
  allowMyLocation,
  locationError,
  myLocationAddress,
  userLocation,
  locationSelected,
  providerSelected,
}: Props): ReactElement {
  const { t } = useTranslation();

  const locale = useSelector((state) => state.user.language);

  const [
    locationErrorDialogVisible,
    showLocationErrorDialog,
    hideLocationErrorDialog,
  ] = useBooleanState();

  const useMyLocation = (e) => {
    e.currentTarget.focus();

    if (myLocationAddress && userLocation) {
      locationSelected({
        locationId: myLocationAddress.id,
        label: myLocationAddress.label,
        distance: null,
        highlights: [],
        ...userLocation,
      });
    } else if (locationError === LocationError.PermissionDenied) {
      showLocationErrorDialog();
    }
  };

  const toLocationOption = (
    suggestion: LocationSearchSuggestion,
    icon = (
      <LocationCursorIcon fill={AppColors.GRAY_200} width={16} height={16} />
    )
  ) => (
    <StyledOptionItem
      key={suggestion.locationId}
      onMouseDown={(e) => e.preventDefault()}
      onClick={(e) => {
        e.currentTarget.focus();

        locationSelected(suggestion);
      }}
      tabIndex={0}
    >
      <StyledOptionItemIcon css={{ backgroundColor: AppColors.BACKGROUND }}>
        {icon}
      </StyledOptionItemIcon>
      <StyledOptionItemWrapper>
        <StyledOptionItemName>
          {searchText
            ? splitToHighlights(suggestion.label, searchText)
            : suggestion.label}
        </StyledOptionItemName>

        {suggestion.distance !== null && (
          <StyledOptionItemInfo
            css={{
              paddingTop: 0,
            }}
          >
            {t("map.distance_km", {
              distance: formatDistance(suggestion.distance / 1000, locale),
            })}
          </StyledOptionItemInfo>
        )}
      </StyledOptionItemWrapper>
    </StyledOptionItem>
  );

  const toProviderOption = (provider: TravelProvider) => {
    const providerName = t(`providers_names.${provider.provider}`);

    return (
      <StyledOptionItem
        key={provider.provider}
        onMouseDown={(e) => e.preventDefault()}
        onClick={(e) => {
          e.currentTarget.focus();

          providerSelected(provider);
        }}
        tabIndex={1}
      >
        <StyledOptionItemIcon css={{ backgroundColor: provider.color }}>
          <TravelTypeIcon
            type={provider.types[0]}
            fill="white"
            width={16}
            height={16}
          />
        </StyledOptionItemIcon>

        <StyledOptionItemWrapper>
          <StyledOptionItemName>
            {splitToHighlights(providerName, searchText)}
          </StyledOptionItemName>
          <StyledOptionItemInfo>
            {t(`data.${provider.types[0]}`)}
          </StyledOptionItemInfo>
        </StyledOptionItemWrapper>
      </StyledOptionItem>
    );
  };

  const renderLoaders = () => {
    const loaders: ReactNode[] = [];

    for (let index = 0; index < LOCATION_SUGGESTIONS_LIMIT; index++) {
      loaders.push(<OptionLoader key={index} />);
    }

    return loaders;
  };

  const hasOptions = locations.length > 0;

  const hasContent =
    allowMyLocation || previousLocation || hasOptions || loadingLocations;

  return (
    <div
      className="location-provider-options"
      css={{
        padding: hasContent ? `8px 16px ${hasOptions ? "16px" : 0}` : 0,
        overflow: hasContent ? "visible" : "hidden",
      }}
    >
      {allowMyLocation && (
        <StyledOptionItem
          // prevent default click or we loose focus and click is not propagated
          onMouseDown={(e) => e.preventDefault()}
          onClick={useMyLocation}
          tabIndex={0}
        >
          <StyledOptionItemIcon>
            <LocationCurrentIcon
              fill={AppColors.GRAY_200}
              css={{ marginTop: -12 }}
            />
          </StyledOptionItemIcon>
          <StyledOptionItemWrapper>
            <StyledOptionItemName>
              {t("shared.use_my_location")}
            </StyledOptionItemName>
          </StyledOptionItemWrapper>
        </StyledOptionItem>
      )}
      {previousLocation &&
        toLocationOption(
          previousLocation,
          <PreviousIcon fill={AppColors.GRAY_200} width={16} height={16} />
        )}

      {searchText && (
        <>
          <StyledSectionTitle>{t("map.locations")}</StyledSectionTitle>
          {loadingLocations && renderLoaders()}

          {!loadingLocations &&
            locations.length > 0 &&
            locations.map((location) => toLocationOption(location))}

          {!loadingLocations && locations.length === 0 && (
            <div
              css={{
                color: AppColors.GRAY_200,
                fontFamily: AppFonts.SECONDARY,
                fontSize: 12,
                lineHeight: "16px",
                marginTop: 12,
              }}
            >
              {t("map.no_locations_found")}
            </div>
          )}

          {(loadingProviders || providers.length > 0) && (
            <StyledSectionTitle>{t("map.providers")}</StyledSectionTitle>
          )}

          {loadingProviders && renderLoaders()}

          {!loadingProviders && providers.map(toProviderOption)}
        </>
      )}
      {locationErrorDialogVisible && (
        <LocationAccessDeniedDialog hide={hideLocationErrorDialog} />
      )}
    </div>
  );
}
