import { Spinner } from "common/Spinner";
import { ReactElement } from "react";

export interface AppLoaderProps {
  customBackgroundColor?: string;
}

export function AppLoader({
  customBackgroundColor,
}: AppLoaderProps): ReactElement {
  return (
    <main
      className="mobea-welcome loader"
      style={customBackgroundColor ? { background: customBackgroundColor } : {}}
    >
      <Spinner />
    </main>
  );
}
