import { History } from "history";
import { ADMIN_API_BASE, ApiErrorResponse, CORE_API_BASE } from "utils";
import { callSecuredApiEndpoint } from "./apiCall";

const languageToLocale = {
  en: "en_US",
  fr: "fr_BE",
  nl: "nl_BE",
};

export type MolliePaymentStatus =
  // t("payment_open")
  | "open" // added for backward compatibility
  // t("payment_created")
  | "created"
  // t("payment_paid")
  | "paid"
  // t("payment_failed")
  | "failed"
  // t("payment_canceled")
  | "canceled"
  // t("payment_expired")
  | "expired"
  // t("payment_pending")
  | "pending"
  // t("payment_authorized")
  | "authorized";

export type GetMolliePaymentResponse =
  | {
      error: false;
      amount: string;
      fee: string;
      created: string; // date string
      customer: string; // uuid
      id: string; // uuid
      status: MolliePaymentStatus;
      payment_response: {
        _links: {
          checkout: {
            href: string; // link
            type: string; // mime type
          };
        };
      };
    }
  | ApiErrorResponse;

export type RawPayment = {
  id: string;
  provider: any;
  amount: string;
  fee: string;
  created: string;
  response: unknown;
  status: MolliePaymentStatus;
  customer: string;
  expire_at: string;
  plan: "private" | "business";
  mobit_detail: unknown;
  taxi_vert_detail: unknown;
};

export type GetMolliePaymentsResponse =
  | (RawPayment[] & { error: false })
  | ApiErrorResponse;

function createMolliePaymentRequest(amount: number, language: string) {
  return (authorization: string) =>
    fetch(`${ADMIN_API_BASE}mollie/payment`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: authorization,
      }),
      body: JSON.stringify({
        amount: {
          currency: "EUR",
          value: amount.toLocaleString("en", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        },
        locale: languageToLocale[language],
      }),
    })
      .then((response) => response.json())
      .then((json) => ({
        ...json,
        error: !!json.error_code,
      })) as Promise<GetMolliePaymentResponse>;
}

export function createMolliePayment(
  amount: number,
  locale: string,
  history: History
) {
  return callSecuredApiEndpoint(
    createMolliePaymentRequest(amount, locale),
    history
  );
}

function getMolliePaymentsRequest() {
  return (authorization: string) =>
    fetch(`${CORE_API_BASE}mollie/payments`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: authorization,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        json.error = !!json.error_code;

        return json;
      }) as Promise<GetMolliePaymentsResponse>;
}

export function getMolliePayments(history: History) {
  return callSecuredApiEndpoint(getMolliePaymentsRequest(), history);
}

function getMolliePaymentStatusRequest(id: string) {
  return (authorization: string) =>
    fetch(`${CORE_API_BASE}mollie/payments/${id}`, {
      method: "GET",
      headers: new window.Headers({
        Authorization: authorization,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        json.error = !!json.error_code;

        return json;
      }) as Promise<GetMolliePaymentResponse>;
}

export function getMolliePaymentStatus(id: string, history: History) {
  return callSecuredApiEndpoint(getMolliePaymentStatusRequest(id), history);
}

export type GetMollieFeeResponse =
  | {
      error: false;
      amount: number;
      fee: number;
    }
  | ApiErrorResponse;

function getMollieFeeRequest(amount: number) {
  return (authorization: string) =>
    fetch(`${CORE_API_BASE}mollie/fee`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: authorization,
      }),
      body: JSON.stringify({
        amount,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        json.error = !!json.error_code;

        return json;
      }) as Promise<GetMollieFeeResponse>;
}

export function getMollieFee(amount: number, history: History) {
  return callSecuredApiEndpoint(getMollieFeeRequest(amount), history);
}
