import { MobeaButton } from "common/forms";
import { useContactUsModal } from "common/modal/";
import { NestedPageFull } from "common/page/NestedPageFull";
import { Spinner } from "common/Spinner";
import { CalendarIcon } from "icons/CalendarIcon";
import { EuroIcon } from "icons/EuroIcon";
import { QuestionMarkIcon } from "icons/QuestionMarkIcon";
import { topupStatusProps } from "pages/history/topups/topupStatusProps";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import {
  GetMolliePaymentResponse,
  getMolliePaymentStatus,
} from "services/mollieService";
import { formatCurrency, formatDateWithLocaleAsDefault } from "utils";
import { AppColors, AppFonts } from "utils/colors";
import { Item } from "./Item";
import { Section } from "./Section";

export function TopUpDetailPage(): ReactElement {
  const { t } = useTranslation();

  const history = useHistory();

  const params = useParams<{ id: string }>();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<GetMolliePaymentResponse>();

  const { language: locale } = useSelector((state) => state.user);

  useEffect(() => {
    setLoading(true);

    getMolliePaymentStatus(params.id, history)
      .then((data) => {
        setData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [history, params.id]);

  const [color, Icon, iconSize] =
    data && !data.error ? topupStatusProps[data.status] : [];

  const contactUsModal = useContactUsModal();

  return (
    <NestedPageFull
      pageClassName=""
      background="default"
      title={t("topup_detail.title")}
      headerInSecondaryColor
      onNavBack={() => {
        history.goBack();
      }}
    >
      {loading && <Spinner />}

      {data && !data.error && (
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            height: "100%",
          }}
        >
          <div
            css={{
              color: AppColors.GRAY_400,
              fontSize: "0.875rem",
              lineHeight: "1.5rem",
              textAlign: "center",
              fontFamily: AppFonts.NUMBERS,
            }}
          >
            {data.id}
          </div>

          <Section name={t("topup_detail.summary")}>
            {/* <Item
              icon={<WalletIcon />}
              name={t("topup_detail.wallet")}
              value={t("topup_detail.private_wallet") as string}
            /> */}

            <Item
              icon={<CalendarIcon />}
              name={t("topup_detail.date")}
              value={formatDateWithLocaleAsDefault(data.created, locale)}
            />

            <Item
              icon={<QuestionMarkIcon />}
              name={t("topup_detail.status")}
              value={
                <div
                  style={{ color }}
                  css={{ "& > *": { verticalAlign: "middle" } }}
                >
                  {t("history.payment_" + data.status)} 
                  {Icon && <Icon width={iconSize} height={iconSize} />}
                </div>
              }
            />
          </Section>

          <Section name={t("topup_detail.price")}>
            <Item
              icon={<EuroIcon />}
              name={t("topup_detail.amount")}
              value={formatCurrency(Number(data.amount), locale)}
            />

            <Item
              icon={<EuroIcon />}
              name={
                <div css={{ "& > *": { verticalAlign: "middle" } }}>
                  {t("topup_detail.transaction_fee")} 
                </div>
              }
              value={formatCurrency(Number(data.fee), locale)}
            />

            <Item
              icon={<EuroIcon />}
              name={t("topup_detail.total_price")}
              value={formatCurrency(
                Number(data.amount) + Number(data.fee),
                locale
              )}
            />
          </Section>

          <div css={{ flexGrow: 1 }} />

          <div>
            <MobeaButton
              css={{ marginTop: 32 }}
              onClick={() => contactUsModal.show()}
            >
              {t("contact_us.need_help")}
            </MobeaButton>
          </div>
        </div>
      )}
    </NestedPageFull>
  );
}
