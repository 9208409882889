import { useBooleanState } from "common/hooks";
import { UpIcon } from "icons/UpIcon";
import "mobiscroll/css/mobiscroll.react.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { TravelTimeOption } from "services/mapService";
import { formatDateWithLocale } from "utils";
import { AppColors, AppFonts } from "utils/colors";
import { TravelTimeWheelPicker } from "./TravelTimeWheelPicker";

interface TravelTimePickerProps {
  disabled?: boolean;
  selectedTravelTimeDate: Date;
  selectedTravelTimeOption: TravelTimeOption;
  onChange: (value: TravelTimeOption, dateTime: Date) => void;
}

export function TravelTimePicker({
  disabled = false,
  selectedTravelTimeDate,
  selectedTravelTimeOption,
  onChange,
}: TravelTimePickerProps) {
  const { t } = useTranslation();

  const locale = useSelector((state) => state.user.language);

  const [dialogOpened, openDialog, closeDialog] = useBooleanState(false);

  const showPicker = () => {
    if (!disabled) {
      openDialog();
    }
  };

  const onConfirm = (travelModeIndex: number, datetime: Date) => {
    onChange(Object.values(TravelTimeOption)[travelModeIndex], datetime);
    closeDialog();
  };

  const formattedDatetime = formatDateWithLocale(
    selectedTravelTimeDate,
    locale,
    {
      weekday: "short",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hourCycle: "h24",
    }
  );

  return (
    <>
      <div
        className="mobea__travel-datetime-selector"
        onClick={showPicker}
        css={{
          fontSize: "0.875rem",
          lineHeight: "16px",
          color: AppColors.GRAY_300,
        }}
      >
        <div
          className="selector"
          css={{
            marginBottom: 2,
          }}
        >
          <span
            css={{
              verticalAlign: "middle",
            }}
          >
            {t(`map.travel_option.${selectedTravelTimeOption}`)}
          </span>
          <UpIcon
            width={16}
            height={6}
            css={{
              transform: "rotate(180deg)",
              margin: "0 4px",
            }}
          />
        </div>
        <p
          className="text"
          css={{
            margin: 0,
            padding: 0,
            color: AppColors.GRAY_400,
            fontFamily: AppFonts.NUMBERS,
          }}
        >
          {formattedDatetime}
        </p>
      </div>
      {dialogOpened && (
        <TravelTimeWheelPicker
          initialDatetime={selectedTravelTimeDate}
          initialTravelModeIndex={Object.values(TravelTimeOption).findIndex(
            (mode) => mode === selectedTravelTimeOption
          )}
          onCancel={closeDialog}
          onSelect={onConfirm}
        />
      )}
    </>
  );
}
