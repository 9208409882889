import { cx } from "@emotion/css";
import { AppLoader } from "common/AppLoader";
import { PageBanner } from "common/banner/PageBanner";
import {
  useBudget,
  useCustomerData,
  useScrollTo,
  useTravelPasses,
} from "common/hooks";
import { useClientConfig } from "common/hooks/useClientConfig";
import { useClientLogo } from "common/hooks/useClientLogo";
import { BottomNavBar } from "common/navigation";
import { Spinner } from "common/Spinner";
import { useTutorialHighlighter } from "common/tutorial2/Tutorial";
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { startTutorial, TravelPass, VertsTravelPass } from "state/actions";
import {
  ACTIVE_PASSES_REFRESH_INTERVAL,
  ApplicationVariant,
  filterActiveTravelPasses,
  Routes,
  TravelPassProvider,
  VERTS_PASS_REFRESH_INTERVAL,
  VERTS_REFRESHABLE_STATUSES,
} from "utils";
import { AppColors } from "utils/colors";
import { applicationVariant } from "utils/configure";
import { ActiveTicketsList } from "./activeTickets/ActiveTicketsList";
import "./HomePage.scss";
import { InstallReminder } from "./installOverlays/InstallReminder";
import { BudgetPeriodOver } from "./over/BudgetPeriodOver";
import { PlanOverview } from "./plan/PlanOverview";
import { useWalletWarnings } from "./useWalletWarnings";

export function HomePage(): ReactElement {
  const loadingUserData = useCustomerData();

  const [updatingClientConfig] = useClientConfig();
  const clientLogo = useClientLogo();
  const { t } = useTranslation();

  const {
    outOfBudgetPeriod,
    language: locale,
    privateAmount,
  } = useSelector((state) => state.user);

  const [updatingBudget] = useBudget();

  const [loadingPasses, reloadPasses] = useTravelPasses("active");

  const allActivePasses = useSelector((state) => state.passes.active);

  const activePasses = useMemo(
    () => filterActiveTravelPasses(allActivePasses),
    [allActivePasses]
  );

  const [selectedPassID, setSelectedPassID] = useState<string | null>(null);

  const { scrollToTop } = useScrollTo();

  useEffect(() => {
    const selectedPass = selectedPassID
      ? activePasses.find((pass) => pass.id === selectedPassID)
      : null;

    const getRefreshInterval = () => {
      switch (selectedPass?.provider) {
        case TravelPassProvider.verts:
          return VERTS_REFRESHABLE_STATUSES.includes(
            (selectedPass as VertsTravelPass).status
          )
            ? VERTS_PASS_REFRESH_INTERVAL
            : ACTIVE_PASSES_REFRESH_INTERVAL;
        default:
          return ACTIVE_PASSES_REFRESH_INTERVAL;
      }
    };

    const handle = window.setInterval(() => {
      reloadPasses();
    }, getRefreshInterval());

    return () => {
      window.clearInterval(handle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPassID]);

  useEffect(() => {
    if (selectedPassID) {
      setSelectedPassID(
        activePasses.find((pass) => pass.id === selectedPassID)?.id || null
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePasses]);

  const selectPass = useCallback((pass: TravelPass | null) => {
    setSelectedPassID(pass?.id || null);

    if (pass) {
      scrollToTop();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useTutorialHighlighter("main", 2, "tutorial-plan", {
    position: "bottom",
    offsetY: 28,
  });

  const dispatch = useDispatch();

  const mainTutorialShown = useSelector(
    (state) => state.tutorial.mainTutorialShown
  );

  const location = useLocation();

  useEffect(() => {
    if (!mainTutorialShown || location["from"] === Routes.Tutorials) {
      dispatch(startTutorial("main"));
    }
  }, [dispatch, location, mainTutorialShown]);

  const [expandedHeight, setExpandedHeight] = useState(0);

  useWalletWarnings(Routes.Home);

  return (
    <main
      className={cx(
        "mobea-dashboard",
        !!selectedPassID && "ticket-detail-visible"
      )}
    >
      {updatingClientConfig && (
        <AppLoader customBackgroundColor={AppColors.BACKGROUND} />
      )}

      {loadingUserData && <Spinner />}

      {!loadingUserData && !updatingClientConfig && (
        <>
          <div css={{ margin: "2rem 0" }} className="mobea-dashboard__banner">
            <div
              className="mobea-dashboard__banner__logo"
              css={{ margin: "1rem auto", maxWidth: "300px" }}
            >
              {clientLogo}
            </div>
            <PageBanner>{t("dashboard.title")}</PageBanner>
          </div>
          <div
            css={{
              transition: "opacity 500ms",
              opacity: selectedPassID ? 0 : 1,
            }}
          >
            <PlanOverview
              id="tutorial-plan"
              onExpandedHeightChange={setExpandedHeight}
            />
          </div>

          {
            // do not show while loading plan but do not hide while reloading budget
            // TODO update layout for multiple wallets
            applicationVariant === ApplicationVariant.EA && !updatingBudget && (
              <BudgetPeriodOver id="tutorial-plan" />
            )
          }

          {(applicationVariant === ApplicationVariant.MOVEASY ||
            !outOfBudgetPeriod ||
            privateAmount > 0) && (
            <ActiveTicketsList
              loadingPasses={loadingPasses}
              activePasses={activePasses}
              selectedPassID={selectedPassID}
              onPassSelect={selectPass}
              locale={locale}
              reloadPasses={reloadPasses}
              offset={expandedHeight}
            />
          )}

          <BottomNavBar hidden={!!selectedPassID} />

          <InstallReminder />
        </>
      )}
    </main>
  );
}
