import { ReactElement } from "react";
import { DefaultIconProps } from "../props";

export function SettingsIcon({
  fill = "currentColor",
  className = "",
  height = "24",
  width = "24",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="mobea__navigation__inactive"
        d="M19.4 13a7.8 7.8 0 000-2l2.1-1.6c.2-.2.3-.5.2-.7l-2-3.4A.5.5 0 0019 5l-2.5 1-1.7-1-.4-2.6c0-.2-.3-.4-.5-.4h-4c-.3 0-.5.2-.5.4l-.4 2.7c-.6.2-1.1.6-1.7 1L5 5h-.1c-.2 0-.4 0-.5.2l-2 3.4c0 .3 0 .5.2.7l2 1.6a8 8 0 000 2l-2 1.6c-.2.2-.3.5-.2.7l2 3.4a.5.5 0 00.7.3l2.4-1 1.7 1 .4 2.6c0 .2.3.4.5.4h4c.3 0 .5-.2.5-.4l.4-2.7c.6-.2 1.1-.6 1.7-1l2.4 1h.2c.2 0 .4 0 .5-.2l2-3.4c0-.3 0-.5-.2-.7l-2-1.6zm-2-1.7a5.3 5.3 0 010 1.4V14l.8.7 1 .8-.6 1.2-1.3-.5-1-.4-1 .7-1.2.7-1 .4-.2 1.1-.2 1.4h-1.4l-.2-1.4-.2-1-1-.5-1.2-.7-1-.7-1 .4-1.3.5-.7-1.2 1.1-.8.9-.7-.2-1.2a8 8 0 010-1.4l.2-1.2-.9-.7-1-.8.6-1.2 1.3.5 1 .4 1-.7L9.8 7l1-.4.2-1.2.2-1.3h1.4l.2 1.3.1 1.2 1.1.4 1.2.7 1 .7 1-.4 1.3-.5.7 1.2-1.1.8-.9.7.1 1.2zM12 8a4 4 0 100 8 4 4 0 000-8zm0 6a2 2 0 01-2-2c0-1.1.9-2 2-2a2 2 0 012 2 2 2 0 01-2 2z"
        fill={fill}
        fillRule="nonzero"
      />
      <path
        className="mobea__navigation__active"
        d="M19.1 13a7 7 0 000-2l2-1.5c.2-.2.3-.4.2-.6l-2-3.4a.5.5 0 00-.5-.2l-2.4 1a7 7 0 00-1.6-1l-.4-2.5c0-.2-.2-.4-.5-.4h-3.8c-.3 0-.4.2-.5.4l-.3 2.5-1.7 1-2.4-1c-.2 0-.4 0-.5.3l-2 3.3c0 .2 0 .4.2.6l2 1.6a5.9 5.9 0 000 1.8l-2 1.6c-.2.2-.3.4-.2.6l2 3.3c0 .3.3.3.5.3l2.4-1 1.6 1 .4 2.5c0 .2.2.4.5.4h3.8c.3 0 .5-.2.5-.4l.3-2.6c.6-.2 1.2-.5 1.7-.9l2.4 1c.2 0 .4 0 .6-.3l1.9-3.3c0-.2 0-.4-.2-.6l-2-1.6zM12 15.5c-2 0-3.6-1.6-3.6-3.6S10 8.4 12 8.4s3.6 1.6 3.6 3.6-1.6 3.6-3.6 3.6z"
        fill={fill}
        fillRule="nonzero"
      />
    </svg>
  );
}
