import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getExpenseDetail } from "services/historyService";
import { Expense } from "state/actions";
import { normalizeExpense } from "utils/normalizers";

export function useExpenseDetail(detailId: string | null) {
  const history = useHistory();

  const [result, setResult] = useState<Expense | null>(null);

  const [failed, setFailed] = useState(false);

  const [loading, setLoading] = useState(false);

  const [failureCode, setFailureCode] = useState("-1");

  useEffect(() => {
    let canceled = false;
    let isLoading = false;

    async function fetchExpenseDetail(id: string) {
      try {
        setLoading(true);
        isLoading = true;

        const responseJson = await getExpenseDetail(id, history);

        if (!responseJson.error) {
          const normalizedExpense: Expense = normalizeExpense(responseJson);

          if (!canceled) {
            setResult(normalizedExpense);

            setLoading(false);
          }
        } else {
          if (!canceled) {
            setFailed(true);

            setLoading(false);

            setFailureCode(responseJson.error_code);
          }
          console.error(
            "Failed to fetch expense detail",
            responseJson.error_description
          );
        }
      } catch (error) {
        if (!canceled) {
          setFailed(true);

          setLoading(false);
        }
        console.error("Failed to fetch expense detail", error);
      } finally {
        isLoading = false;
      }
    }

    detailId !== null && fetchExpenseDetail(detailId);

    return () => {
      canceled = true;

      isLoading && console.debug("Canceled hook useExpenseDetail");
    };
  }, [detailId, history]);

  return [result, failed, loading, failureCode] as [
    Expense | null,
    boolean,
    boolean,
    string
  ];
}
