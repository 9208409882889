import { RawTravelPass } from "services/travelPassService";
import { VeloTicketMetadata } from "services/veloService";
import { VeloStatus, VeloTravelPass } from "state/actions/TravelPassActions";
import { addTimezoneToServerTime, parseFloatWithComma } from "utils";
import { TravelPassProvider, TravelPassType } from "../constants";

export function normalizeVeloTravelPass(
  rawTravelPass: RawTravelPass
): VeloTravelPass {
  const orderTimestamp = new Date(rawTravelPass.created).valueOf();

  const expiration = addTimezoneToServerTime(rawTravelPass.expire_at).valueOf();

  const {
    account_id,
    activation_datetime,
    key_code,
    fine = false,
  } = (rawTravelPass.response as VeloTicketMetadata) || {};

  const activation = activation_datetime
    ? new Date(activation_datetime).valueOf()
    : undefined;

  const getStatus = (): VeloStatus => {
    if (fine) {
      return VeloStatus.Charged;
    } else if (expiration < Date.now()) {
      return VeloStatus.Expired;
    } else if (account_id) {
      return VeloStatus.Active;
    } else {
      return VeloStatus.Ready;
    }
  };

  return {
    id: rawTravelPass.id,
    status: getStatus(),
    userId: account_id?.toString(),
    password: key_code?.toString(),
    orderTimestamp,
    activation,
    expiration,
    fine,
    price: parseFloatWithComma(rawTravelPass.amount) || 0,
    types: [TravelPassType.Bike],
    provider: TravelPassProvider["velo-antwerpen"],
    plan: rawTravelPass.plan,
  };
}
