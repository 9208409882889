import { useNetwork } from "common/hooks";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  getNearbyTransportData,
  LocationCoords,
  NearbyTransportData,
  toNearbyTransportData,
} from "services/mapService";
import { NetworkState } from "utils/network";

export function useNearbyTransportData(location: LocationCoords | null) {
  const history = useHistory();

  const network = useNetwork();

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(false);

  const [data, setData] = useState<NearbyTransportData | null>(null);

  useEffect(() => {
    let canceled = false;

    if (!location) {
      return;
    }

    const fetchStaticMapData = async () => {
      try {
        setLoading(true);

        setError(false);

        const response = await getNearbyTransportData(location!, history);

        if (canceled) {
          return;
        }

        if (response.error) {
          setLoading(false);

          setError(true);

          console.error(
            "Failed to fetch static map data",
            response.error_description
          );
        } else {
          setData(toNearbyTransportData(response));

          setLoading(false);
        }
      } catch (error) {
        if (!canceled) {
          setLoading(false);

          setError(true);

          console.error("Failed to fetch static map data", error);
        }
      }
    };

    network === NetworkState.online && fetchStaticMapData();

    return () => {
      canceled = true;
    };
  }, [history, location, network]);

  return [data, loading, error] as const;
}
