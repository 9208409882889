import { ProviderFilterType } from "pages/providers/filter/ProviderFilterType";
import {
  LocationCoords,
  LocationSearchPlace,
  LocationSearchSuggestion,
  RoutePlanData,
  RoutePlanTrip,
  TravelTimeOption,
} from "services/mapService";
import {
  BlueBikeData,
  DeLijnSingleTicket,
  MobitDayPassData,
  NmbsStation,
  VeloData,
} from "state/actions/OrderingActions";
import {
  Expense,
  TravelPass,
  TravelProvider,
} from "state/actions/TravelPassActions";
import {
  ProviderToTypeMappingType,
  TravelPassProvider,
  TravelPassType,
} from "utils/constants";

export const persistedUserKeys: (keyof UserState)[] = [
  "customerId",
  "currentPlanId",
  "language",
  "phonePrefix",
  "phoneNumber",
  "name",
  "surname",
  "startDate",
  "endDate",
  "totalAmount",
  "amount",
  "privateAmount",
  "privateStartDate",
  "iban",
  "dateOfBirth",
  "outOfBudgetPeriod",
  "tester",
  "passwordSet",
  "walletWarnings",
  "remainingDays",
];

export enum WalletsStatus {
  emptyBusiness = 1 << 0,
  cooldown = 1 << 1,
  emptyPrivate = 1 << 2,
  emptyBoth = 1 << 3,
}

export interface UserState {
  customerId: string;
  currentPlanId: string | null;
  phonePrefix: string;
  phoneNumber: string;
  name: string;
  surname: string;
  email: string;
  language: string;
  amount: number;
  totalAmount: number;
  startDate: number | null;
  endDate: number | null;
  iban: string;
  dateOfBirth: string;
  tester: boolean;
  planLoaded: boolean;
  outOfBudgetPeriod: boolean;
  remainingDays: number;
  noBudgetWarningClosed: boolean;
  outOfBudgetPeriodWarningClosed: boolean;
  clientConfigReady: boolean;
  passwordSet: boolean;
  privateAmount: number;
  privateStartDate: number | null;
  walletWarnings: WalletsStatus;
}

export const persistedOnboardingKeys: (keyof OnboardingState)[] = [
  "welcomeDone",
  "tutorialWatched",
  "installNotificationCanceled",
  "verificationRequested",
  "appLaunchTracked",
  "cookiesAccepted",
  "trackingAccepted",
  "advertisingAccepted",
  "mobitParkingZonesAcknowledged",
  "expenseIbanDisplayed",
  "providersTutorialWatched",
  "termsAccepted",
  "privacyAccepted",
];

export interface OnboardingState {
  welcomeDone: boolean;
  tutorialWatched: boolean;
  installNotificationCanceled: boolean;
  verificationRequested: boolean;
  appLaunchTracked: boolean;
  cookiesAccepted: boolean;
  trackingAccepted: boolean;
  advertisingAccepted: boolean;
  mobitParkingZonesAcknowledged: boolean;
  expenseIbanDisplayed: boolean;
  providersTutorialWatched: Partial<Record<TravelPassProvider, boolean>>;
  termsAccepted: boolean;
  privacyAccepted: boolean;
}

export const persistedTravelPassKeys: (keyof TravelPassState)[] = [
  "providers",
  "providersToTypes",
  "providersTypes",
  "mapProvidersTypes",
  "providersUpdateTimestamp",
  "active",
  "purchases",
  "expenses",
];

export interface TravelPassState {
  active: TravelPass[];
  providers: TravelProvider[];
  providersToTypes: ProviderToTypeMappingType;
  providersTypes: TravelPassType[];
  mapProvidersTypes: TravelPassType[];
  providersUpdateTimestamp: number;
  providersLoading: boolean;
  purchases: TravelPass[];
  expenses: Expense[];
}

export const persistedOrderingKeys: (keyof OrderingState)[] = [
  "deLijnSingleTicket",
  "nmbsStations",
  "nmbsStationsUpdateTimestamp",
  "mobitDayPass",
  "vertsLastOrigin",
  "vertsLastDestination",
  "termsAccepted",
];

export interface OrderingState {
  deLijnSingleTicket: DeLijnSingleTicket;
  nmbsStations: NmbsStation[];
  nmbsStationsUpdateTimestamp: number;
  mobitDayPass: MobitDayPassData;
  vertsLastOrigin: LocationSearchPlace | null;
  vertsLastDestination: LocationSearchPlace | null;
  termsAccepted: Partial<Record<TravelPassProvider, boolean>>;
  veloData: VeloData;
  blueBikeData: BlueBikeData;
}

export const persistedMapKeys: (keyof MapState)[] = [
  "mapDisplayed",
  "previousSearchLocation",
  "routePlannerVisible",
  "routePlannerSearchResult",
  "selectedRoutePlannerTrip",
];

export interface MapMessagesDisplays {
  budgetNotActiveYet: boolean;
  budgetActive: boolean;
  budgetExpired: boolean;
}

export interface RoutePlannerSearch {
  source: LocationSearchPlace;
  destination: LocationSearchPlace;
  travelTimeOption: TravelTimeOption;
  travelTimeDate: number;
  data: RoutePlanData | null;
  filters: ProviderFilterType[];
}

export interface MapState {
  searchLocation: LocationCoords | LocationSearchSuggestion | null;
  previousSearchLocation: LocationSearchSuggestion | null;
  mapDisplayed: MapMessagesDisplays;
  routePlannerVisible: boolean;
  routePlannerSearchResult: RoutePlannerSearch | null;
  selectedRoutePlannerTrip: RoutePlanTrip | null;
}

export const persistedTutorialKeys: (keyof TutorialState)[] = [
  "historyTutorialShown",
  "providersTutorialShown",
  "mainTutorialShown",
  "mapTutorialShown",
];

export interface TutorialState {
  name: "main" | "providers" | "history" | undefined;
  step: number | undefined;
  historyTutorialShown: boolean;
  providersTutorialShown: boolean;
  mainTutorialShown: boolean;
  mapTutorialShown: boolean;
}

export const persistedAppUiKeys: (keyof AppUiState)[] = [];

export interface AppUiState {
  keyboardOpened: boolean;
}

export interface ProvidersState {
  filter: ProviderFilterType;
}
