import { BrusselsCoords } from "maps";
import { useLayoutEffect, useRef } from "react";
import { getFullLanguageCodes } from "utils/intl";

export type MapInitPayload = { map: H.Map };

export const MAX_ZOOM = 17;

const H = window.H;

export function useMapInitializer(
  locale: string,
  initialZoom = 8,
  initialCenter = BrusselsCoords
) {
  const canceledRef = useRef(false);

  const platformRef = useRef<H.service.Platform | null>(null);

  const mapRef = useRef<HTMLDivElement>(null);

  const mapClassRef = useRef<H.Map | null>(null);

  const behaviorRef = useRef<H.mapevents.Behavior | null>(null);

  let initResolve: (value: MapInitPayload) => void;

  const onInitPromise = new Promise<MapInitPayload>((resolve) => {
    initResolve = resolve;
  });

  useLayoutEffect(() => {
    let resizeHandler: () => void;

    if (!canceledRef.current && mapRef.current) {
      console.debug("Map init");

      platformRef.current = new H.service.Platform({
        apikey: process.env.HERE_API_KEY,
      });

      const defaultLayers = platformRef.current.createDefaultLayers({
        lg: locale,
        lg2: "en",
      });

      // cap maximum zoom level
      defaultLayers.vector.normal.map.setMax(19);
      defaultLayers.vector.normal.map.setMin(5);

      defaultLayers.raster.terrain.map.setMin(4);

      const mapOptions: H.Map.Options = {
        pixelRatio: 1,
        center: initialCenter,
        zoom: initialZoom,
        engineType: H.Map.EngineType.P2D,
        imprint: {
          font: `11px var(--font-family)`,
          locale: getFullLanguageCodes(locale),
        } as H.map.Imprint.Options,
      };

      const map = (mapClassRef.current = new H.Map(
        mapRef.current,
        defaultLayers.raster.terrain.map,
        mapOptions
      ));

      let viewportHeight = map.getViewPort().height;

      resizeHandler = () => {
        map.getViewPort().resize();
        viewportHeight = map.getViewPort().height;
      };

      window.addEventListener("resize", resizeHandler);

      // make map interactive - add event handling
      behaviorRef.current = new H.mapevents.Behavior(
        new H.mapevents.MapEvents(map)
      );

      behaviorRef.current.disable((H.mapevents.Behavior as any).Feature.TILT);

      // prevent moving map out of screen
      map.getViewModel().addEventListener(
        "sync",
        (e) => {
          const top = map.screenToGeo(0, 0);
          const bottom = map.screenToGeo(0, viewportHeight);
          const overTop = top.lat >= 90;
          const bellowBottom = bottom.lat <= -90;

          const center = map.getCenter();

          if (!overTop && !bellowBottom) {
          } else {
            e.preventDefault();
            const bounceBackSpeed = 6 / map.getZoom();

            map.setCenter(
              {
                lat:
                  center.lat - (overTop ? bounceBackSpeed : -bounceBackSpeed),
                lng: center.lng,
              },
              true
            );
          }

          return false;
        },
        true
      );

      initResolve({
        map,
      });
    }

    return () => {
      canceledRef.current = true;
      mapClassRef.current?.dispose();
      behaviorRef.current?.dispose();

      resizeHandler && window.removeEventListener("resize", resizeHandler);

      console.debug("Map disposed");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef]);

  return [
    mapRef,
    mapClassRef,
    onInitPromise,
    behaviorRef,
    platformRef,
  ] as const;
}
