import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

type Props = {
  orientation: "up" | "down" | "left" | "right";
} & DefaultIconProps;

export function ChevronIcon({
  fill = "currentColor",
  className = "",
  height = "24",
  width = "24",
  onClick,
  orientation,
}: Props): ReactElement {
  const getTransform = () => {
    switch (orientation) {
      case "right":
        return "rotate(-90deg)";
      case "down":
        return "rotate(0deg)";
      case "left":
        return "rotate(90deg)";
      case "up":
        return "rotate(180deg)";
      default:
        return "rotate(0deg)";
    }
  };

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox="0 0 24 24"
      onClick={onClick}
      css={{
        transform: getTransform(),
      }}
    >
      <path fill={fill} d="M12 15l5-4.86L15.828 9 12 12.722 8.172 9 7 10.14z" />
    </svg>
  );
}
