import { Spinner } from "common/Spinner";
import { NoProvidersAvailable } from "pages/map/NoProvidersAvailable";
import { toProviderTile } from "pages/providers/tiles";
import { ReactElement, useMemo } from "react";
import { useSelector } from "react-redux";
import { TravelProvider } from "state/actions";
import {
  ApplicationVariant,
  providerOrderList,
  TravelPassProvider,
} from "utils";
import { applicationVariant } from "utils/configure";

type Props = {
  loading: boolean;
  title?: string;
  providers: TravelPassProvider[];
  providerSelected: (provider: TravelPassProvider) => void;
};

export function NearbyProviders({
  loading,
  title,
  providers,
  providerSelected,
}: Props): ReactElement {
  const allProviders = useSelector((state) => state.passes.providers);

  const visibleProviders = useMemo(() => {
    return providerOrderList(
      [...providers].map((provider) =>
        allProviders.find((fullProvider) => fullProvider.provider === provider)
      ) as TravelProvider[]
    )
      .filter((provider) => !!provider)
      .filter((provider) =>
        applicationVariant === ApplicationVariant.EA
          ? true
          : provider?.provider !== TravelPassProvider.uber
      )
      .map((provider) => provider as TravelProvider);
  }, [providers, allProviders]);

  const toTile = (provider: TravelProvider) => {
    const onClick = () => {
      providerSelected(provider.provider);
    };

    return (
      <div key={provider.provider} className="tile-wrapper">
        {toProviderTile(provider, onClick)}
      </div>
    );
  };

  return (
    <div className="nearby-providers">
      {title && <div className="nearby-providers__title">{title}</div>}

      {loading && <Spinner />}

      {!loading && visibleProviders.length > 0 && (
        <div className="nearby-providers__scroll-container">
          <div className="tiles-container">{visibleProviders.map(toTile)}</div>
        </div>
      )}

      {!loading && visibleProviders.length === 0 && <NoProvidersAvailable />}
    </div>
  );
}
