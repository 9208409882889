import "@here/maps-api-for-javascript/bin/mapsjs-ui.css";
import "@here/maps-api-for-javascript/bin/mapsjs.bundle";
import { LabelValue, MobeaButton } from "common/forms";
import { useBooleanState, useNetwork, useTicketDetail } from "common/hooks";
import { MobeaModal } from "common/modal";
import { BottomSlider, BottomSliderRef } from "common/navigation";
import { MapOfflineOverlay } from "common/network/MapOfflineOverlay";
import { MapViewPage } from "common/page/MapViewPage";
import { CarPlate } from "common/travelPasses/verts/CarPlate";
import { DateTimePrice } from "common/travelPasses/verts/DateTimePrice";
import { RouteSection } from "common/travelPasses/verts/RouteSection";
import { VertsTaxi } from "common/travelPasses/verts/VertsTaxi";
import { MyLocationIcon } from "icons/navigation";
import { LocationCursorIconSvg } from "icons/navigation/LocationCursorIcon";
import {
  LocationError,
  MapInitPayload,
  useCenteredMap,
  useLocationUpdates,
  useMapInitializer,
  useRouting,
} from "maps";
import {
  PickupIconDom,
  TaxiPickupIconDom,
  TaxiWaitingIconDom,
} from "maps/icons";
import { LocationAccessDeniedDialog } from "maps/LocationAccessDeniedDialog";
import { useVertsCancel } from "pages/verts/hooks/useVertsCancel";
import { ReactElement, useEffect, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import {
  VertsTravelPass,
  VertsTravelPass as VertsTravelPassType,
} from "state/actions";
import { Routes, VERTS_PASS_REFRESH_INTERVAL } from "utils";
import { NetworkState } from "utils/network";
import { VertsStatus } from "utils/VertsStatus";
import "./VertsMap.scss";

const INITIAL_MAP_ZOOM = 14;

const MAP_STATUSES = [
  VertsStatus.Arriving,
  VertsStatus.Waiting,
  VertsStatus.InProgress,
];

const CANCEL_VISIBLE_STATUSES = [VertsStatus.Arriving];

const BOTTOM_SLIDER_VISIBLE_STATUSES = [
  VertsStatus.Waiting,
  VertsStatus.InProgress,
];

const H = window.H;

type Props = {
  backButtonBehavior?: "goBack" | "goTicket";
};

const getTargetLocation = (currentPass?: VertsTravelPassType | null) => {
  switch (currentPass?.status) {
    case VertsStatus.Waiting:
      return currentPass?.vehicle?.location || currentPass?.origin.location;
  }

  return currentPass?.origin.location;
};

const getMapCenterLocation = (currentPass?: VertsTravelPassType | null) => {
  if (currentPass?.status === VertsStatus.InProgress) {
    return currentPass?.destination.location;
  }

  return getTargetLocation(currentPass);
};

export function VertsMap({
  backButtonBehavior = "goTicket",
}: Props): ReactElement {
  const { t } = useTranslation();

  const history = useHistory();

  const network = useNetwork();

  const { id = "" } = useParams<{ id: string }>();

  const decodedId = decodeURIComponent(id);

  const [updatedPass, loadingFailed, loadingDetail, , reload] =
    useTicketDetail<VertsTravelPassType>(decodedId !== "" ? decodedId : null);

  // collect cached versions of the ticket for case we are offline
  // to search for latest cached version of the ticket
  const latestPassDetail = useSelector((state) => [
    ...state.passes.active,
    ...state.passes.purchases,
  ]).find((pass) => pass.id === decodedId) as VertsTravelPass | undefined;

  const currentPass = updatedPass ?? latestPassDetail;

  const [cancel] = useVertsCancel();

  const user = useSelector((state) => state.user);

  const [mapRef, mapClassRef, onInit, , platformRef] = useMapInitializer(
    user.language,
    INITIAL_MAP_ZOOM
  );

  const taxiLocationGroupRef = useRef<H.map.Group | null>(null);

  const [userLocation, setUserLocationGroup, locationError] =
    useLocationUpdates();

  const [
    cancelConfirmDialogVisible,
    showCancelConfirmDialog,
    hideCancelConfirmDialog,
  ] = useBooleanState();

  const [errorDialogVisible, showErrorDialog, hideErrorDialog] =
    useBooleanState();

  const [
    locationErrorDialogVisible,
    showLocationErrorDialog,
    hideLocationErrorDialog,
  ] = useBooleanState();

  const [setRoutingMapGroup] = useRouting({
    platformRef,
    origin: userLocation,
    destination: getTargetLocation(currentPass) || null,
    enabled: currentPass?.status !== VertsStatus.InProgress,
    color: "var(--color-status-green)",
  });

  const bottomSliderRef = useRef<BottomSliderRef>(null);

  const bottomSliderVisible =
    currentPass && BOTTOM_SLIDER_VISIBLE_STATUSES.includes(currentPass.status);

  // refresh ticket in intervals to see the latest status
  useEffect(() => {
    if (!currentPass) {
      return;
    }

    const intervalHandle = window.setInterval(
      reload,
      VERTS_PASS_REFRESH_INTERVAL
    );

    return () => {
      window.clearInterval(intervalHandle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPass]);

  useEffect(() => {
    if (currentPass && !MAP_STATUSES.includes(currentPass.status)) {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPass?.status]);

  // customize map
  useEffect(() => {
    const customizeMap = ({ map }: MapInitPayload) => {
      const userLocationGroup = new H.map.Group({ zIndex: 1 });

      setUserLocationGroup(userLocationGroup);

      map.addObject(userLocationGroup);

      taxiLocationGroupRef.current = new H.map.Group({ zIndex: 2 });

      map.addObject(taxiLocationGroupRef.current!);

      const routingGroup = new H.map.Group({ zIndex: 3 });

      setRoutingMapGroup(routingGroup);

      map.addObject(routingGroup);
    };

    onInit.then(customizeMap);

    return () => {
      taxiLocationGroupRef.current?.dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useCenteredMap(
    userLocation,
    mapClassRef,
    mapClassRef.current?.getZoom() || INITIAL_MAP_ZOOM,
    false
  );

  // show error dialog on error
  useEffect(() => {
    if (loadingFailed && !loadingDetail) {
      network === NetworkState.online && showErrorDialog();
    }
  }, [showErrorDialog, loadingFailed, loadingDetail, network]);

  // update origin/destination location
  useEffect(() => {
    const taxiLocationGroup = taxiLocationGroupRef.current;

    taxiLocationGroup?.removeAll();

    if (!currentPass || !taxiLocationGroup) {
      return;
    }

    const getIcon = () => {
      switch (currentPass.status) {
        case VertsStatus.Waiting:
          return TaxiWaitingIconDom;
        case VertsStatus.InProgress:
          return LocationCursorIconSvg();
        case VertsStatus.Arriving:
          return PickupIconDom(currentPass.origin.address);
        default:
          return TaxiPickupIconDom(currentPass.origin.address);
      }
    };

    const getPosition = () => {
      switch (currentPass?.status) {
        case VertsStatus.Waiting:
          return currentPass.vehicle?.location || currentPass.origin.location;
        case VertsStatus.InProgress:
          return currentPass.destination.location;
      }

      return currentPass.origin.location;
    };
    const locationMarker = new H.map.DomMarker(getPosition(), {
      icon: new H.map.DomIcon(getIcon(), {}),
    });

    taxiLocationGroup.addObject(locationMarker);
  }, [currentPass]);

  // derive center location from ride when offline
  useEffect(() => {
    const coords = getMapCenterLocation(currentPass);

    coords && mapClassRef.current?.setCenter(coords, true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPass?.id, locationError]);

  const useMyLocation = () => {
    if (userLocation) {
      mapClassRef.current?.setCenter(userLocation, true);

      mapClassRef.current?.setZoom(INITIAL_MAP_ZOOM, true);
    } else if (locationError === LocationError.PermissionDenied) {
      showLocationErrorDialog();
    }
  };

  const cancelRide = () => {
    if (currentPass) {
      cancel(currentPass.id);
    }

    hideCancelConfirmDialog();

    history.goBack();
  };

  const getTitle = () => {
    switch (currentPass?.status) {
      case VertsStatus.Waiting:
        return t("verts_map.find_driver");
      case VertsStatus.InProgress:
        return t("verts_map.follow_ride");
      default:
        return t("verts_map.pick_up_location");
    }
  };

  const getSubtitle = () => {
    switch (currentPass?.status) {
      case VertsStatus.Waiting:
        return t("verts_map.goto_driver");
      case VertsStatus.InProgress:
        return "";
      default:
        return t("verts_map.goto_pick_up_location");
    }
  };

  const getBottomSliderTitle = () => {
    switch (currentPass?.status) {
      case VertsStatus.InProgress:
        return t("verts_map.route_info");
      default:
        return t("verts_map.driver_info");
    }
  };

  const onBack = () => {
    if (backButtonBehavior === "goBack") {
      history.goBack();
    } else {
      history.replace(Routes.VertsTicketDetail.replace(":id", id));
    }
  };

  return (
    <MapViewPage
      mapRef={mapRef}
      title={
        <>
          <div>{getTitle()}</div>
          <div className="subtitle">{getSubtitle()}</div>
        </>
      }
      pageClassName="verts-map"
      className={!bottomSliderVisible ? "full" : ""}
      onNavBack={onBack}
      mapActionsOffset={70}
      css={{
        padding: 0,
      }}
    >
      <div className="map-actions">
        <div className="map-action" onClick={useMyLocation}>
          <MyLocationIcon />
        </div>
      </div>

      <BottomSlider
        ref={bottomSliderRef}
        title={getBottomSliderTitle()}
        hidden={!bottomSliderVisible}
        visibleHeight={70}
      >
        {currentPass?.vehicle && currentPass?.status === VertsStatus.Waiting && (
          <div className="driver-info">
            <LabelValue
              label={t("verts_detail.driver")}
              value={currentPass?.driver}
            />
            <div className="car-plate-section">
              <LabelValue
                className="car-plate-section__car"
                label={t("verts_detail.car")}
                value={
                  <VertsTaxi
                    color={currentPass.vehicle.color}
                    model={currentPass.vehicle.model}
                  />
                }
              />
              <CarPlate plate={currentPass.vehicle.plate} />
            </div>
          </div>
        )}

        {currentPass?.status === VertsStatus.InProgress && (
          <div className="route-info">
            <RouteSection
              origin={currentPass.origin.address}
              destination={currentPass.destination.address}
            />
            <div className="route-info__separator" />
            <DateTimePrice
              status={currentPass.status}
              dateTime={currentPass.etaTime || ""}
              price={currentPass.price}
            />
          </div>
        )}
      </BottomSlider>

      {currentPass && CANCEL_VISIBLE_STATUSES.includes(currentPass.status) && (
        <div className="bottom-actions">
          <MobeaButton onClick={showCancelConfirmDialog}>
            {t("verts_map.cancel_ride")}
          </MobeaButton>
        </div>
      )}

      {cancelConfirmDialogVisible && (
        <MobeaModal
          title={t("verts_detail.cancel_confirm_title")}
          confirmText={t("shared.no")}
          onConfirm={hideCancelConfirmDialog}
          cancelText={t("shared.yes_cancel")}
          onCancel={cancelRide}
        >
          <p>
            <Trans i18nKey="verts_detail.cancel_confirm_text">
              <span className="mobea__arial">
                {{ fine: currentPass?.cancelFee || 0 }}
              </span>
            </Trans>
          </p>
        </MobeaModal>
      )}

      {errorDialogVisible && (
        <MobeaModal
          title={t("shared.oops")}
          onConfirm={hideErrorDialog}
          type="error"
          confirmText={t("shared.ok")}
        >
          <p>{t("map.error")}</p>
        </MobeaModal>
      )}

      {locationErrorDialogVisible && (
        <LocationAccessDeniedDialog hide={hideLocationErrorDialog} />
      )}

      <MapOfflineOverlay shadow />
    </MapViewPage>
  );
}
