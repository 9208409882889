import { ReactElement } from "react";
import { ExpenseStatus } from "state/actions";
import { AppColors } from "utils/colors";
import "./HistoryExpenseStatus.scss";

export interface HistoryExpenseStatusProps {
  status: ExpenseStatus;
}

export function HistoryExpenseStatus({
  status,
}: HistoryExpenseStatusProps): ReactElement | null {
  switch (status) {
    case "PARTIALLY ACCEPTED":
    case "ACCEPTED":
      return (
        <span className="mobea__status mobea__status__confirmed">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
              d="M16.6 7.6L10 14.2l-3.6-3.6L5 12l5 5 8-8-1.4-1.4zM12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 110-16 8 8 0 010 16z"
              fill={AppColors.PRIMARY}
              fillRule="nonzero"
            />
          </svg>
        </span>
      );
    case "DECLINED":
      return (
        <span className="mobea__status mobea__status__denied">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
            <path
              d="M22 7.8L15.5 14l6.3 6.4-1.4 1.4-6.4-6.3-6.3 6.3-1.4-1.4 6.3-6.4-6.3-6.3 1.4-1.4 6.3 6.3 6.4-6.3L22 7.8z"
              fill={AppColors.ERROR}
              fillRule="nonzero"
            />
          </svg>
        </span>
      );
    case "PENDING":
      return (
        <span className="mobea__status mobea__status__pending">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
              d="M12 6v3l4-4-4-4v3a8 8 0 00-6.8 12.3l1.5-1.5A6 6 0 0112 6zm6.8 1.7l-1.5 1.5A6 6 0 0112 18v-3l-4 4 4 4v-3a8 8 0 006.8-12.3z"
              fill={AppColors.GRAY_200}
              fillRule="nonzero"
            />
          </svg>
        </span>
      );
    default:
      console.warn("Uknonw status - not implemented icon for it!.", status);
      return null;
  }
}
