import { useEffect, useState } from "react";
import i18n from "utils/i18n";

export function useTranslations(namespace: string) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let canceled = false;
    let isLoading = false;

    async function loadTranslations() {
      try {
        setLoading(true);
        isLoading = true;

        await i18n.loadNamespaces(namespace);

        !canceled && setLoading(false);
      } catch (error) {
        !canceled && setLoading(false);

        console.error("Failed to load translation namespace", error);
      } finally {
        isLoading = false;
      }
    }

    loadTranslations();

    return () => {
      canceled = true;

      isLoading && console.debug("Canceled hook useTranslations");
    };
  }, [namespace]);

  return loading;
}
