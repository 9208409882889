import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

export function ArrowReturnIcon({
  fill = "currentColor",
  className = "",
  height = "24",
  width = "24",
  onClick,
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 0 24 24"
      width={width}
      onClick={onClick}
    >
      <path
        d="M2 16l4 4v-3h15v-2H6v-3zM22 8l-4-4v3H3v2h15v3z"
        fill={fill}
        fillRule="nonzero"
      />
    </svg>
  );
}
