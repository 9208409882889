import { ReactElement, ReactNode } from "react";
import ReactDOM from "react-dom";
import { ApplicationVariant, MODAL_ROOT_ELEMENT } from "utils";
import { applicationVariant } from "utils/configure";
import { MobeaModalType } from "./MobeaModal";
import "./MobeaModal.scss";

export type CustomMobeaModalProps = {
  className?: string;
  type?: MobeaModalType;
  children: ReactNode;
  title?: ReactNode;
  usePortal?: boolean;
  customStyle?: any;
  onCancel?: () => void;
} & (
  | {
      withHeader: false;
    }
  | {
      withHeader: true;
      title: ReactNode;
    }
);

export function CustomMobeaModal({
  title,
  children,
  type = "info",
  className = "",
  withHeader,
  usePortal = false,
  // styling to apply as it breaks with usage of portal
  customStyle = {},
  onCancel,
}: CustomMobeaModalProps): ReactElement {
  const renderModal = () => (
    <div
      className="mobea__modal"
      css={{
        ...customStyle,
      }}
    >
      <section
        className={`mobea__modal__dialog mobea__${type}`}
        css={{
          position: "fixed",
          borderRadius:
            applicationVariant === ApplicationVariant.MOVEASY ? 4 : 0,
        }}
      >
        {withHeader && (
          <header className="mobea__modal__dialog__header">
            <h2 className="mobea__modal__dialog__header__headline">{title}</h2>
          </header>
        )}
        <div className="mobea__modal__dialog__content">{children}</div>
      </section>
      <div
        className={`mobea__overlay mobea__expanded ${className}`}
        onClick={onCancel}
      ></div>
    </div>
  );

  return usePortal && MODAL_ROOT_ELEMENT
    ? ReactDOM.createPortal(renderModal(), MODAL_ROOT_ELEMENT)
    : renderModal();
}
