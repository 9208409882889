import { DefaultIconProps } from "icons/props";
import { ReactElement } from "react";

export function DeLijnLogo({
  className = "",
  width,
  height,
}: Pick<DefaultIconProps, "className" | "width" | "height">): ReactElement {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 87 84"
    >
      <path
        fill="#575E61"
        d="M42.228 27.994h-7.207l-2.486 13.999h7.207l1.099-6.225 2.414 6.225h7.573l2.484-13.995h-7.571l-1.119 6.277zM8.393 38.552l1.866-10.554H2.472L0 42.001h12.159l.61-3.449zM22.555 28.004h-7.912L12.9 37.796h7.912zM23.088 37.492c-.139.801-.451 1.06-1.049 1.06l-1.362-.005-.609 3.454h5.547c2.988 0 4.671-1.579 5.305-5.163l1.567-8.834-7.721.001-1.678 9.487z"
      />
      <g fill="#FD0">
        <path d="M86.8 0H29.726l-3.512 19.826 57.069-.001zM14.855 84h57.071l7.027-39.67H21.874z" />
      </g>
      <path
        fill="#575E61"
        d="M22.056 24.209h.637l.121-.687h-.639l.097-.478h1.145l.147-.878h-2.857l-.621 3.491h2.863l.163-.919-1.143-.001zM17.196 24.721h-.248l.288-1.662h.248s.273-.017.471.042c.172.049.385.327.305.775-.1.566-.389.767-.661.823-.131.031-.403.022-.403.022zm1.473.888c.578-.124 1.171-.521 1.387-1.729.172-.951-.265-1.454-.618-1.597-.283-.114-.752-.117-1.015-.117l-2.753-.001-.614 3.492h2.759c.211 0 .569.01.854-.048z"
      />
      <g fill="#009">
        <path d="M53.716 25.663l.62-3.496H43.695l-.618 3.496zM42.6 25.663l.617-3.496h-17.42l-.617 3.496zM60.527 25.663l.621-3.496h-6.311l-.62 3.496zM64.812 25.663l.622-3.496h-3.719l-.621 3.496zM67.762 25.663l.616-3.496H66.09l-.613 3.496zM69.925 25.663l.621-3.496h-1.42l-.614 3.496zM71.609 25.663l.621-3.496h-.812l-.618 3.496zM73.286 25.663l.622-3.496h-.629l-.62 3.496zM74.752 25.663l.622-3.496h-.394l-.613 3.496zM76.391 25.663l.619-3.496h-.304l-.619 3.496zM78.115 25.663l.621-3.496h-.278l-.617 3.496zM80.021 25.663l.62-3.496h-.215l-.619 3.496zM82.255 25.663l.615-3.496h-.168l-.614 3.496z" />
      </g>
    </svg>
  );
}
