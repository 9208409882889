import { AppColors, getDerivedColor } from "utils/colors";

export const PickupIconDom = (address: string, color = AppColors.PRIMARY) => `
<div class="mobea__taxi-pickup-icon">
    <div class="container">
        <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
            <path
                d="M20 3.3A11.7 11.7 0 0 0 8.3 15C8.3 23.8 20 36.7 20 36.7s11.7-13 11.7-21.7c0-6.4-5.3-11.7-11.7-11.7zm0 15.9a4.2 4.2 0 0 1-4.2-4.2 4.2 4.2 0 1 1 4.2 4.2z"
                fill="${color}"
            />
        </svg>
        <div class="address">${address}</div>
    </div>
</div>
`;

export const TaxiPickupIconDom = (address: string) => `
<div class="mobea__taxi-pickup-icon">
    <div class="container">
        <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19 6c-.3-.6-.8-1-1.5-1H15V3H9v2H6.5c-.7 0-1.2.4-1.4 1L3 12v8c0 .6.5 1 1 1h1c.5 0 1-.4 1-1v-1h12v1c0 .6.4 1 1 1h1c.6 0 1-.4 1-1v-8l-2-6zM6.4 16a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm11 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3zM5 11l1.5-4.5h11L19 11H5z"
                fill="${AppColors.PRIMARY}"
            />
        </svg>
        <div class="address">${address}</div>
    </div>
</div>
`;

export const TaxiWaitingIconDom = `
<div class="mobea__taxi-waiting-icon">
    <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19 6c-.3-.6-.8-1-1.5-1H15V3H9v2H6.5c-.7 0-1.2.4-1.4 1L3 12v8c0 .6.5 1 1 1h1c.5 0 1-.4 1-1v-1h12v1c0 .6.4 1 1 1h1c.6 0 1-.4 1-1v-8l-2-6zM6.4 16a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm11 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3zM5 11l1.5-4.5h11L19 11H5z"
            fill="white"
        />
    </svg>
</div>
`;

export const UserLocationIcon = (
  fillColor = getDerivedColor(AppColors.PRIMARY),
  strokeColor = "#fff"
) => `<svg width="30" height="30" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 30 30">
<defs>
  <filter x="-112.5%" y="-112.5%" width="325%" height="325%" filterUnits="objectBoundingBox" id="loc-a">
    <feMorphology radius="1.5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"/>
    <feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
    <feGaussianBlur stdDeviation="4" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
    <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" in="shadowBlurOuter1"/>
  </filter>
  <rect id="loc-b" x="0" y="0" width="12" height="12" rx="6"/>
</defs>
    <g transform="translate(9 9)" fill="none" fill-rule="evenodd">
    <use fill="#000" filter="url(#loc-a)" xlink:href="#loc-b"/>
    <use stroke="${strokeColor}" stroke-width="3" fill="${fillColor}" xlink:href="#loc-b"/>
    </g>
</svg>`;
