import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

export function WalletIcon({
  fill = "currentColor",
  className = "",
  height = "24",
  width = "24",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 10h-1V8a2 2 0 00-2-2h-.38l-1.73-3.45a1 1 0 00-1.26-.48L4.8 6H4a2 2 0 00-2 2v12c0 1.1.9 2 2 2h14a2 2 0 002-2v-2h1a1 1 0 001-1v-6a1 1 0 00-1-1zm-6.48-5.73L15.38 6H10.2l4.33-1.73zM18 8v2h-3a1 1 0 00-.7.3l-3 3a1 1 0 000 1.4l3 3a1 1 0 00.7.3h3v2H4V8h14zm-2 4a2 2 0 110 4 2 2 0 010-4z"
        fill={fill}
      />
    </svg>
  );
}
