import { MobeaButton } from "common/forms";
import { useBooleanState } from "common/hooks";
import { useAppName } from "common/hooks/useAppName";
import { MobeaModal } from "common/modal";
import { CloseIcon } from "icons/CloseIcon";
import {
  createContext,
  CSSProperties,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { endTutorial, nextTutorialStep, prevTutorialStep } from "state/actions";
import { mobea, Routes } from "utils";
import { AppColors, AppFonts } from "utils/colors";
import "./Tutorial.scss";

export type HighlighterParams = {
  position?: "top" | "bottom";
  offsetX?: number;
  offsetY?: number;
  maskMargin?: [top?: number, right?: number, bottom?: number, right?: number];
  wait?: number;
  fixed?: boolean;
};

type ElementDef = {
  id: string;
  params?: HighlighterParams;
};

const TutorialContext = createContext<
  | {
      setSuspendedKeys: (setter: (prev: Set<string>) => Set<string>) => void;

      // setElementDefs: (
      //   setter: (prev: Record<string, ElementDef>) => Record<string, ElementDef>
      // ) => void;
      setElementDef: (key: string, def: ElementDef) => void;
    }
  | undefined
>(undefined);

const steps = {
  main: 6,
  providers: 5,
};

type Props = { children: ReactNode };

export function TutorialProvider({ children }: Props) {
  const fullHeight = document.documentElement.scrollHeight;

  const step = useSelector((state) => state.tutorial.step);

  const name = useSelector((state) => state.tutorial.name);

  const elementDefs = useRef<Record<string, ElementDef>>({});

  const [maskStyle, setMaskStyle] = useState<CSSProperties>({});

  const [bubbleStyle, setBubbleStyle] = useState<CSSProperties>({});

  const [arrowStyle, setArrowStyle] = useState<CSSProperties>({});

  const [found, setFound] = useState(false);

  const dispatch = useDispatch();

  const bubbleRef = useRef<HTMLDivElement | null>(null);

  const [resizeCount, setResizeCount] = useState(0);

  const handleResize = useCallback(() => {
    setResizeCount((c) => c + 1);
  }, []);

  const effectiveStep = step;

  useEffect(() => {
    const ed = elementDefs.current[name + "-" + step];

    window.addEventListener("resize", handleResize);

    const tid =
      mobea.isIos && ed?.params?.fixed
        ? setInterval(() => {
            handleResize();
          }, 200)
        : undefined;

    return () => {
      window.removeEventListener("resize", handleResize);

      if (tid !== undefined) {
        clearInterval(tid);
      }
    };
  }, [handleResize, name, step]);

  useEffect(() => {
    const ed = elementDefs.current[`${name}-${step}`];
    const element = ed && document.getElementById(ed.id);

    if (!element) {
      setFound(false);
      return;
    }

    const { params = {} } = ed;

    const highlight = () => {
      const {
        maskMargin,
        position = "top",
        offsetX = 0,
        offsetY = 0,
        fixed,
      } = params;

      const rect = element.getBoundingClientRect();

      let { top, bottom, left, width, height } = rect;

      top -= maskMargin?.[0] ?? 0;

      width += (maskMargin?.[1] ?? 0) + (maskMargin?.[3] ?? 0);

      height += (maskMargin?.[0] ?? 0) + (maskMargin?.[2] ?? 0);

      bottom += maskMargin?.[2] ?? 0;

      left -= maskMargin?.[3] ?? 0;

      setFound(true);

      setTimeout(() => {
        if (bubbleRef.current) {
          const r = bubbleRef.current.getBoundingClientRect();

          if (r.top < 0 || r.bottom > window.innerHeight) {
            bubbleRef.current.scrollIntoView({
              behavior: "smooth",
              block: "end",
            });
          }
        }
      }, 200);

      const bottomPosition = position === "bottom";

      if (!fixed) {
        top += document.documentElement.scrollTop;
        bottom += document.documentElement.scrollTop;
      }

      setMaskStyle({
        top,
        left,
        width,
        height,
        position: fixed ? "fixed" : undefined,
      });

      setBubbleStyle({
        position: fixed ? "fixed" : undefined,

        ...(bottomPosition
          ? {
              top: bottom + 10 + offsetY,
            }
          : {
              bottom:
                (fixed ? window.innerHeight : fullHeight) - top + 10 + offsetY,
            }),
      });

      setArrowStyle({
        left: Math.min(
          Math.max(left + width / 2 - 5 + offsetX, 35),
          window.innerWidth - 45
        ),
        ...(bottomPosition
          ? {
              borderBottomColor: "#fff",
              borderTop: 0,
              top: bottom + offsetY,
            }
          : {
              borderTopColor: "#fff",
              borderBottom: 0,
              bottom:
                (fixed ? window.innerHeight : fullHeight) - top + 10 + offsetY,
              position: fixed ? "fixed" : undefined,
            }),
      });
    };

    let t: number | undefined;

    if (params?.wait) {
      t = window.setTimeout(highlight, params?.wait);
    } else {
      highlight();
    }

    return () => {
      if (t !== undefined) {
        window.clearTimeout(t);
      }
    };
  }, [step, fullHeight, resizeCount, name]);

  const { t } = useTranslation();

  const [confirm, setConfirm, resetConfirm] = useBooleanState();

  const location = useLocation();

  const history = useHistory();

  const appName = useAppName();

  useEffect(() => {
    history.listen((_, action) => {
      if (action === "POP") {
        dispatch(endTutorial());
      }
    });
  }, [dispatch, history]);

  const end = () => {
    dispatch(endTutorial());

    const { state } = location;

    if (
      state &&
      typeof state === "object" &&
      state["from"] === Routes.Tutorials
    ) {
      history.goBack();
    }
  };

  const handleNext = () => {
    if (name && effectiveStep === steps[name]) {
      end();
    } else {
      dispatch(nextTutorialStep());
    }
  };

  const handleCancel = () => {
    end();
    resetConfirm();
  };

  const [suspendKeys, setSuspendedKeys] = useState(new Set<string>());

  const ctx = useMemo(
    () => ({
      setElementDef: (key: string, def: ElementDef) => {
        elementDefs.current[key] = def;

        // flush element added to get new render cycle
        // otherwise render might run and exit before adding element
        setImmediate(() => setResizeCount((c) => c + 1));
      },
      setSuspendedKeys,
    }),
    []
  );

  return (
    <TutorialContext.Provider value={ctx}>
      {children}

      {suspendKeys.size === 0 && name && effectiveStep && found && (
        <div
          css={{
            position: "absolute",
            zIndex: 1000,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <div
            css={{
              position: "absolute",
              boxShadow: "0 0 0 9999px rgba(0, 0, 0, 0.24)",
              transition: "all 0.2s linear",
            }}
            className={`tutorial__${name}_${step}`}
            style={maskStyle}
          />
          <div
            css={{
              position: "absolute",
              background: "white",
              left: 24,
              right: 24,
              padding: 24,
              borderRadius: 10,
              boxShadow: "0 0 14px rgba(126, 126, 126, 0.5)",
              transition: "all 0.2s linear",
              display: "grid",
              lineHeight: 1.5,
              gridTemplateAreas:
                '"step close" "title title" "body body" "prev next"',
              zIndex: 10,
            }}
            style={bubbleStyle}
            ref={bubbleRef}
          >
            <div
              css={{
                gridArea: "step",
                color: AppColors.GRAY_300,
                fontSize: "0.875rem",
                alignSelf: "center",
                fontFamily: AppFonts.NUMBERS,
              }}
            >
              {effectiveStep}/{(name && steps[name]) ?? "?"}
            </div>

            <div
              css={{
                gridArea: "close",
                justifySelf: "end",
                alignSelf: "center",
                transform: "scale(0.75)",
              }}
            >
              <CloseIcon fill={AppColors.GRAY_300} onClick={setConfirm} />
            </div>

            <div
              css={{
                gridArea: "title",
                margin: "12px 0",
                fontSize: 20,
                lineHeight: "24px",
                fontWeight: "bold",
              }}
            >
              {t(`tutorial.${name}_${effectiveStep}_title`, { appName })}
            </div>

            <div
              css={{ gridArea: "body" }}
              dangerouslySetInnerHTML={{
                __html: t(`tutorial.${name}_${effectiveStep}_body`, {
                  appName,
                }),
              }}
            />

            {effectiveStep > 1 && (
              <div
                css={{
                  gridArea: "prev",
                  "& button": {
                    width: "auto",
                    padding: 0,
                    marginBottom: -18,
                  },
                }}
              >
                <MobeaButton
                  type="tertiary"
                  className="mobea__tutorial-dialog__actions__skip"
                  onClick={() => {
                    dispatch(prevTutorialStep());
                  }}
                >
                  {t("tutorial.previous")}
                </MobeaButton>
              </div>
            )}

            <div
              css={{
                gridArea: "next",
                justifySelf: "end",
                width: "auto",
                "& button": {
                  padding: 0,
                  marginBottom: -18,
                },
              }}
            >
              <MobeaButton type="tertiary" onClick={handleNext}>
                {effectiveStep < steps[name]
                  ? t("tutorial.next")
                  : t("tutorial.finish")}
              </MobeaButton>
            </div>
          </div>

          <div
            css={{
              label: "arrow",
              position: "absolute",
              width: 0,
              height: 0,
              border: "15px solid transparent",
              marginLeft: -10,
              marginBottom: -10,
              transition: "all 0.2s linear",
              zIndex: 1000,
            }}
            style={arrowStyle}
          />
        </div>
      )}

      {confirm && (
        <MobeaModal
          title={t("tutorial.confirm_close_title")}
          confirmText={t("shared.no")}
          onConfirm={resetConfirm}
          cancelText={t("shared.yes_leave")}
          onCancel={handleCancel}
        >
          {t("tutorial.confirm_close_body")}
        </MobeaModal>
      )}
    </TutorialContext.Provider>
  );
}

export function useTutorialHighlighter(
  name: keyof typeof steps,
  step: number,
  elementId: string,
  params?: HighlighterParams
) {
  const ctx = useContext(TutorialContext);

  if (!ctx) {
    throw new Error("missing TutorialContext");
  }

  const { setElementDef } = ctx;

  setElementDef(`${name}-${step}`, { id: elementId, params });
}

export function useTutorialSuspender(key: string, suspend: boolean) {
  const ctx = useContext(TutorialContext);

  if (!ctx) {
    throw new Error("missing TutorialContext");
  }

  const { setSuspendedKeys } = ctx;

  useEffect(() => {
    const suspendTutorial = (suspend: boolean) => {
      setSuspendedKeys((suspendKeys: Set<string>) => {
        const next = new Set(suspendKeys);

        if (suspend) {
          next.add(key);
        } else {
          next.delete(key);
        }

        return next;
      });
    };

    suspendTutorial(suspend);

    return () => {
      suspendTutorial(false);
    };
  }, [setSuspendedKeys, key, suspend]);
}
