// organize-imports-ignore
// reduce the bundle size by not including the unused parts of the application!
                        
                                                              
          
                                                      
import { getRoutesMoveasyAll, RoutesMoveasyPublic } from "routes/RoutesMoveasy";
          
import { usePageViewsTracking } from "analytics/usePageViewsTracking";
import { AppLoader } from "common/AppLoader";
import { useCustomerData, useProviders } from "common/hooks";
import { BudgetProvider } from "common/hooks/useBudget";
import { ModalProvider } from "common/hooks/useModal";
import { useScrollToTop } from "common/hooks/useScrollToTop";
import { OfflineBanner } from "common/network/OfflineBanner";
import { TutorialProvider } from "common/tutorial2/Tutorial";
import { UpdateChecker } from "common/UpdateChecker";
import { ReactElement, Suspense, useEffect } from "react";
import { hot } from "react-hot-loader";
import { useLocation } from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";
import { Auth } from "services/authTokenStore";
import { getSearchParams } from "utils";
import { startApiHealthChecking } from "utils/network";
import { getConfig } from "utils/configure";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import { getRoutesSharedAll, RoutesSharedPublic } from "routes/SharedRoutes";
import { useDispatch } from "react-redux";
import { keyboardToggleAction } from "state/actions/AppUiActions";

function App(): ReactElement {
  const location = useLocation();
  const dispatch = useDispatch();

  const queryParams = getSearchParams(location.search);

  useCustomerData(true);

  useProviders();

  const { fontName } = getConfig();

  // fire google analytics page view on every navigation
  usePageViewsTracking();

  // provide current path available as class name for global components style hacks if required
  useEffect(() => {
    window.document.body.dataset["currentPath"] = location.pathname
      .substring(1)
      .replace(/\//g, "__");

    // disable keyboard state on navigation - e.g. when user hides keyboard and goes back without leaving focus of input
    dispatch(keyboardToggleAction(false));
  }, [location.pathname, dispatch]);

  // based on client configuration define which font we use
  // so we can adjust for font specifics
  // runs exactly once after config is processed
  useEffect(() => {
    fontName &&
    window.document.body.classList.add(`font-${fontName.toLowerCase()}`);
  }, [fontName]);

  // start checking the API health on first render
  useEffect(startApiHealthChecking, []);

  useScrollToTop();

  const loggedIn = Auth.hasToken();

  let routes: EmotionJSX.Element[] = [];

  if (loggedIn) {
                                                          
    routes = routes.concat(getRoutesMoveasyAll(queryParams));
              

                            
                                        
              

    routes = routes.concat(getRoutesSharedAll(queryParams));
  } else {
                                                          
    routes = routes.concat(RoutesMoveasyPublic);
              

                            
                                           
              

    routes = routes.concat(RoutesSharedPublic);
  }

  return (
    <Suspense fallback={<AppLoader />}>
      <OfflineBanner />

      <UpdateChecker />

      <ModalProvider>
        <BudgetProvider>
          <TutorialProvider>
            <AnimatedSwitch
              atEnter={{ opacity: 0 }}
              atLeave={{ opacity: 0 }}
              atActive={{ opacity: 1 }}
              css={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                zIndex: 0,
                "& > div": {
                  height: "100%"
                }
              }}
            >
              {routes}
            </AnimatedSwitch>
          </TutorialProvider>
        </BudgetProvider>
      </ModalProvider>
    </Suspense>
  );
}

export default hot(module)(App);
