import { Carousel } from "common/carousel/Carousel";
import { useTravelPasses } from "common/hooks";
import { NestedPage } from "common/page/NestedPage";
import { Spinner } from "common/Spinner";
import { ReactElement, ReactNode } from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { TravelPass } from "state/actions";
import { getSearchParams, pushLocation, Routes } from "utils";
import { BackButtonBehaviour } from "utils/routing";
import "./MultiTicketDetail.scss";

const QUERY_PARAM_IDS = "ids";

type Props = {
  backButtonBehaviour?: BackButtonBehaviour;
  toPage: (pass: TravelPass, refresh: () => void) => ReactNode;
  info?: ReactNode;
};

export function MultiTicketDetail({
  backButtonBehaviour = "goHome",
  toPage,
  info,
}: Props): ReactElement {
  const history = useHistory();

  const { search } = useLocation();

  const queryParams = getSearchParams(search);

  const [loadingPasses, refresh] = useTravelPasses("all");

  const allPasses = useSelector((state) => state.passes.purchases);

  const passes = (queryParams.get(QUERY_PARAM_IDS) || "")
    .split(",")
    .map((id) => allPasses.find((pass) => pass.id === id))
    .filter((pass) => !!pass) as TravelPass[];

  const goBack = () => {
    if (backButtonBehaviour === "goHome") {
      pushLocation(history, Routes.Home);
    } else {
      history.goBack();
    }
  };

  return (
    <NestedPage
      pageClassName="mobea__multi-ticket-detail"
      title={
        <>
          <Trans i18nKey="shared.my_tickets">
            <span className="mobea__arial">{{ tickets: passes.length }}</span>
          </Trans>
          {info}
        </>
      }
      background="default"
      onNavBack={goBack}
    >
      {loadingPasses && <Spinner />}

      {!loadingPasses && (
        <Carousel
          className="mobea__multi-ticket-detail__tickets"
          pages={passes.map((pass) => toPage(pass, refresh))}
        />
      )}
    </NestedPage>
  );
}
