import { OrderError, OrderErrorProps } from "common/errors/OrderError";
import { MobeaModal } from "common/modal";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { ApiErrors } from "utils";

type Props = OrderErrorProps & { amount: number };

export function VeloOrderError({
  amount,
  errorCode,
  closeError,
}: Props): ReactElement {
  const history = useHistory();

  const { t } = useTranslation();

  const goBack = () => {
    history.goBack();
  };

  switch (errorCode) {
    case ApiErrors.VELO_NO_PASSES_AVAILABLE:
      return (
        <MobeaModal
          type="error"
          title={t("velo_order.no_passes_title")}
          confirmText={t("shared.understand")}
          onConfirm={amount > 1 ? closeError : goBack}
        >
          <p>
            {amount > 1
              ? t("velo_order.no_passes_multi_text")
              : t("velo_order.no_passes_text")}
          </p>
        </MobeaModal>
      );
    case ApiErrors.LOW_BUDGET:
      return (
        <MobeaModal
          type="error"
          title={t("shared.oops")}
          confirmText={t("shared.ok")}
          onConfirm={closeError}
        >
          <p>{t("velo_order.order_error_no_budget")}</p>
        </MobeaModal>
      );
    default:
      return <OrderError errorCode={errorCode} closeError={closeError} />;
  }
}
