import { useCurrentTicket } from "common/hooks/useCurrentTicket";
import { MobeaModal } from "common/modal";
import { NestedPage } from "common/page/NestedPage";
import { Spinner } from "common/Spinner";
import { DeLijnTravelPass } from "common/travelPasses/delijn/DeLijnTravelPass";
import { InfoIcon } from "icons/InfoIcon";
import { PhoneTicketIcon } from "icons/PhoneTicketIcon";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { DeLijnTravelPass as DeLijnTravelPassType } from "state/actions/TravelPassActions";
import { pushLocation, Routes } from "utils";
import { AppColors } from "utils/colors";
import { BackButtonBehaviour } from "utils/routing";
import "./DeLijnTicketDetail.scss";

export interface DeLijnTicketDetailProps {
  backButtonBehaviour?: BackButtonBehaviour;
}

export function DeLijnTicketDetail({
  backButtonBehaviour = "goHome",
}: DeLijnTicketDetailProps): ReactElement {
  const history = useHistory();

  const { id = "" } = useParams<{ id: string }>();

  const { t } = useTranslation();
  const locale = useSelector((state) => state.user.language);

  const [infoOpened, setInfoOpened] = useState(false);

  const [currentPass, loadingFailed, loadingDetail] =
    useCurrentTicket<DeLijnTravelPassType>(id, (pass) => !pass.afzender);

  const showInfo = () => setInfoOpened(true);

  const hideInfo = () => setInfoOpened(false);

  const goBack = () => {
    if (backButtonBehaviour === "goHome") {
      pushLocation(history, Routes.Home);
    } else {
      history.goBack();
    }
  };

  return (
    <NestedPage
      pageClassName="mobea-ticket-delijn"
      title={
        <>
          {t("ticked_detail.my_ticket")}
          <div className="mobea-ticket-delijn__info" onClick={showInfo}>
            <InfoIcon />
          </div>
        </>
      }
      background="default"
      padding={{
        left: 16,
        right: 16,
        bottom: 16,
      }}
      onNavBack={goBack}
    >
      {loadingDetail && <Spinner />}

      {!loadingDetail && currentPass && (
        <DeLijnTravelPass {...currentPass} locale={locale} />
      )}

      {!loadingDetail && loadingFailed && !currentPass && (
        <h2>Sorry cannot find travel pass.</h2>
      )}

      {infoOpened && (
        <MobeaModal
          title={t("ticked_detail.my_ticket")}
          confirmText={t("shared.got_it")}
          image={<PhoneTicketIcon fill={AppColors.GRAY_100} />}
          onConfirm={hideInfo}
        >
          <p>{t("ticked_detail.my_ticket_info_text")}</p>
        </MobeaModal>
      )}
    </NestedPage>
  );
}
