import { ReactNode } from "react";
import { AppFonts } from "utils/colors";

export function TravelPassInfoLine({ children }: { children: ReactNode }) {
  return (
    <div
      className="info-line"
      css={{
        display: "flex",
        alignItems: "flex-start",
        fontFamily: AppFonts.SECONDARY,
        fontSize: "0.875rem",
        lineHeight: "1.5rem",

        ":not(:first-of-type)": {
          marginTop: 4,
        },
      }}
    >
      <span
        className="info-line__bullet"
        css={{
          margin: "4px 20px 0 12px",
        }}
      >
        •
      </span>
      <div className="info-line__text">{children}</div>
    </div>
  );
}
