import { LocationSearchSuggestion, RoutePlanTrip } from "services/mapService";
import {
  MapMessagesDisplays,
  RoutePlannerSearch,
} from "state/reducers/stateTypes";

export function setMapPreviousSearchLocationAction(
  location: LocationSearchSuggestion | null
) {
  return {
    type: "SET_MAP_PREVIOUS_SEARCH_LOCATION" as const,
    location,
  };
}

export function setMapMessagesDisplaysAction(displays: MapMessagesDisplays) {
  return {
    type: "MAP_MESSAGES_DISPLAYS" as const,
    displays,
  };
}

export function setRoutePlannerVisibleAction(visible: boolean) {
  return {
    type: "SET_ROUTE_PLANNER_VISIBLE" as const,
    visible,
  };
}

export function setRoutePlannerSearchAction(search: RoutePlannerSearch | null) {
  return {
    type: "SET_ROUTE_PLANNER_SEARCH" as const,
    search,
  };
}

export function setSelectedRoutePlanTripAction(trip: RoutePlanTrip | null) {
  return {
    type: "SET_SELECTED_ROUTE_PLAN_TRIP" as const,
    trip,
  };
}

export function updateSelectedRoutePlanTripAction(trip: RoutePlanTrip) {
  return {
    type: "UPDATE_SELECTED_ROUTE_PLAN_TRIP" as const,
    trip,
  };
}

export type MapActions =
  | ReturnType<typeof setMapPreviousSearchLocationAction>
  | ReturnType<typeof setMapMessagesDisplaysAction>
  | ReturnType<typeof setRoutePlannerVisibleAction>
  | ReturnType<typeof setRoutePlannerSearchAction>
  | ReturnType<typeof setSelectedRoutePlanTripAction>
  | ReturnType<typeof updateSelectedRoutePlanTripAction>;
