import styled from "@emotion/styled";
import { Carousel } from "common/carousel/Carousel";
import { MobeaButton } from "common/forms";
import {
  useBudget,
  useNetwork,
  useUpdatedCustomer,
  useViewportHeight,
} from "common/hooks";
import { Spinner } from "common/Spinner";
import { BriefcaseIcon } from "icons/BriefcaseIcon";
import { CalendarIcon } from "icons/CalendarIcon";
import { NavBackIcon } from "icons/NavBackIcon";
import { WalletIcon } from "icons/WalletIcon";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  BUDGET_COOLDOWN_PERIOD_DAYS,
  formatCurrency,
  formatDateWithLocaleAsDigits,
  MAX_PRIVATE_AMOUNT,
  MAX_PRIVATE_AMOUNT_BEFORE_TOP_UP,
  pushLocation,
  Routes,
} from "utils";
import { AppColors, AppFonts } from "utils/colors";
import { NetworkState } from "utils/network";

type CardProps = {
  disabled?: boolean;
};

const Card = styled.div(({ disabled }: CardProps) => ({
  label: "card",
  borderRadius: "0.25rem",
  backgroundColor: disabled ? "rgba(212,212,212,0.5)" : AppColors.PRIMARY,
  padding: "1.5rem",
  transition: "opacity 200ms",
  ".inactive > &": {
    opacity: 0.5,
  },
  marginTop: "0.5rem", // because of the shadow
  boxShadow: "0 0 0.5rem rgba(var(--rgb-gray-200), 0.3)",
  display: "flex",
  flexDirection: "column",
  border: disabled ? "1px solid rgba(var(--rgb-gray-200), 0.5)" : "none",
  height: "100%",
  "& > *:nth-child(1)": {
    border: "1px solid " + (disabled ? AppColors.GRAY_200 : "white"),
    borderRadius: "3px",
    padding: "1rem",
    color: disabled ? AppColors.GRAY_500 : "white",
    fontWeight: "bold",
    fontSize: "1.125rem",
    textAlign: "center",
    marginBottom: "1.5rem",
  },
  "& > *:nth-child(2)": {
    color: disabled ? AppColors.GRAY_300 : "white",
    textAlign: "center",
    fontSize: "0.75rem",
    lineHeight: "1.25rem",
    "& > svg": {
      verticalAlign: "top",
      height: "1.25rem",
      marginRight: "1.5ch",
    },
  },
}));

const DetailContainer = styled.div({
  background: "white",
  flexGrow: 1,
  position: "relative",
});

const Detail = styled.div((props: { active: boolean }) => ({
  label: "detail",
  position: "absolute",
  top: "1rem",
  bottom: "2rem",
  left: "2rem",
  right: "2rem",
  opacity: props.active ? 1 : 0,
  pointerEvents: props.active ? undefined : "none",
  transition: "opacity 200ms",
  "& svg": {
    color: AppColors.PRIMARY,
    flexShrink: 0,
  },
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "& > *:nth-child(1)": {
    fontWeight: "bold",
    fontSize: "2.5rem",
  },
  "&::before": {
    content: '""',
    flexBasis: "1rem",
  },
}));

const Shadow = styled.div({
  flex: "0 0 8px",
  marginTop: "1rem",
  background:
    // generated with the following code:
    // `linear-gradient(to bottom, ${[...Array(6).keys()].map(x => x / 5).map(x => `rgba(0,0,0,${(0.05 * x ** 2).toPrecision(2)}) ${x * 100}%`).join(', ')})`
    "linear-gradient(to bottom, rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.0020) 20%, rgba(0,0,0,0.0080) 40%, rgba(0,0,0,0.018) 60%, rgba(0,0,0,0.032) 80%, rgba(0,0,0,0.050) 100%)",
});

const Info = styled.div({
  label: "info",
  display: "flex",
  "& > *:first-child": {
    marginRight: "1rem",
  },
  "& .value": {
    fontSize: "1rem",
    fontWeight: 700,
    color: AppColors.GRAY_500,
  },
  "& .desc": {
    fontSize: "0.75rem",
    lineHeight: "1.5rem",
    fontWeight: 700,
    opacity: 0.72,
    color: AppColors.GRAY_500,
  },
});

const InfoText = styled.span({
  textAlign: "center",
  color: AppColors.GRAY_400,
  fontSize: "0.875rem",
  lineHeight: 1.71,
  marginBottom: "1rem",
  flexGrow: 1,
});

export function MyWalletsPage() {
  const { t } = useTranslation();

  const location = useLocation();

  const history = useHistory();

  const sp = new URLSearchParams(location.search);

  const position = sp.get("wallet") === "private" ? 1 : 0;

  const setPosition = (position: number) => {
    history.replace(
      Routes.MyWallets + "?wallet=" + (position === 0 ? "business" : "private")
    );
  };

  const loading = useUpdatedCustomer();

  const {
    totalAmount,
    startDate,
    endDate,
    amount,
    language: locale,
    privateAmount,
    outOfBudgetPeriod,
    remainingDays,
  } = useSelector((state) => state.user);

  const remainingAmount =
    outOfBudgetPeriod && remainingDays <= -BUDGET_COOLDOWN_PERIOD_DAYS
      ? 0
      : Math.max(0, amount);

  const startDateFormatted = startDate
    ? formatDateWithLocaleAsDigits(startDate, locale)
    : "";

  const endDateFormatted = endDate
    ? formatDateWithLocaleAsDigits(endDate, locale)
    : "";

  const formattedRemainingAmount = formatCurrency(remainingAmount, locale);

  const formattedPrivateAmount = formatCurrency(
    Math.max(0, privateAmount),
    locale
  );

  const formattedTotalAmount = formatCurrency(totalAmount, locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  const formattedPrivateMax = formatCurrency(MAX_PRIVATE_AMOUNT, locale, {
    minimumFractionDigits: 0,
  });

  const [updatingBudget] = useBudget();

  const height = useViewportHeight();

  const network = useNetwork();

  // remaining days are negative if after budget period
  // cap value inside interval to prevent issue with too many days or negative value
  const days = Math.min(
    Math.max(BUDGET_COOLDOWN_PERIOD_DAYS + remainingDays, 0),
    BUDGET_COOLDOWN_PERIOD_DAYS
  );

  // disable expensing once we have no budget, after cooldown period or offline
  const expensingDisabled =
    remainingAmount <= 0 || days === 0 || network !== NetworkState.online;

  const daysLeftText = `${days} ${t("time.day", { count: days })}`;

  return (
    <main
      css={{
        background: AppColors.BACKGROUND,
        minHeight: height ? height + "px" : "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <header
        css={{ display: "flex", padding: "1rem", paddingBottom: "0.5rem" }}
      >
        <button
          css={{
            background: "none",
            border: 0,
            outline: "0 none",
          }}
          type="button"
          onClick={() => history.goBack()}
        >
          <NavBackIcon />
        </button>
        <h1
          css={{
            flexGrow: 1,
            textAlign: "center",
            fontSize: "1.25rem",
            lineHeight: "1.5rem",
            fontWeight: 500,
            paddingRight: "40px", // fixes mis-alignment because of back arrow
          }}
        >
          {t("settings.my_wallets")}
        </h1>
      </header>

      {loading ? (
        <Spinner />
      ) : (
        <>
          <Carousel
            css={{
              "& > *:last-child": {
                marginTop: "1rem",
              },
              "& > *:first-child": {
                marginLeft: "2rem",
                marginRight: "2rem",
              },
            }}
            stepsPlacement="bottom"
            pages={[
              <Card
                key={1}
                disabled={
                  amount <= 0 || remainingDays <= -BUDGET_COOLDOWN_PERIOD_DAYS
                }
              >
                <div>{t("my_wallets.business_wallet")}</div>

                <div>
                  <BriefcaseIcon />
                  {t("my_wallets.business_wallet_desc")}
                </div>
              </Card>,
              // <Card key={2} disabled={privateAmount <= 0}>
              //   <div>{t("my_wallets.private_wallet")}</div>

              //   <div>
              //     <UserIcon />
              //     {t("my_wallets.private_wallet_desc")}
              //   </div>
              // </Card>,
            ]}
            position={position}
            onChange={setPosition}
            pageOffset={0}
          />

          <Shadow />

          <DetailContainer>
            <Detail
              active={position === 0}
              // ugly hacks
              css={{
                "& p.mobea-dashboard__expired__text": {
                  flexGrow: 1,
                  marginTop: 0,
                },
                "& button.mobea-dashboard__expired__add-expense": {
                  margin: "0 0 1rem",
                },
              }}
            >
              <div
                css={{
                  color:
                    amount <= 0 || outOfBudgetPeriod
                      ? AppColors.GRAY_200
                      : AppColors.PRIMARY,
                  fontFamily: AppFonts.NUMBERS,
                }}
              >
                {formattedRemainingAmount}
              </div>

              <div
                css={{
                  display: "flex",
                  gap: "1rem 2rem",
                  padding: "2rem 0",
                  flexWrap: "wrap",
                }}
              >
                <Info>
                  <WalletIcon />
                  <div>
                    <div
                      className="value"
                      css={{ fontFamily: AppFonts.NUMBERS }}
                    >
                      {formattedTotalAmount}
                    </div>
                    <div className="desc">{t("home.total_budget")}</div>
                  </div>
                </Info>

                <Info>
                  <CalendarIcon />
                  <div>
                    <div
                      className="value"
                      css={{ fontFamily: AppFonts.NUMBERS }}
                    >
                      {startDateFormatted} - {endDateFormatted}
                    </div>
                    <div className="desc">{t("home.valid_period")}</div>
                  </div>
                </Info>
              </div>

              {updatingBudget ? null : outOfBudgetPeriod ? (
                remainingDays > -BUDGET_COOLDOWN_PERIOD_DAYS ? (
                  <InfoText>
                    <Trans
                      i18nKey="wallet.budget_expired_cooldown_text"
                      values={{ days: daysLeftText }}
                    >
                      <strong className="mobea__arial"></strong>
                    </Trans>
                  </InfoText>
                ) : (
                  <InfoText>
                    <Trans i18nKey="home.bugdet_expired_final_text_mobea" />
                  </InfoText>
                )
              ) : amount <= 0 ? (
                <InfoText>{t("wallet.no_budget")}</InfoText>
              ) : null}

              <div
                css={{
                  flexGrow: 1,
                }}
              />
              {!updatingBudget && remainingDays > -BUDGET_COOLDOWN_PERIOD_DAYS && (
                <MobeaButton
                  disabled={expensingDisabled}
                  onClick={() => pushLocation(history, Routes.ExpenseAddEmpty)}
                >
                  {t("expense.add_expense")}
                </MobeaButton>
              )}
              <p
                css={{
                  color: AppColors.GRAY_300,
                  fontSize: "0.75rem",
                  lineHeight: "1rem",
                  margin: "8px 0 0",
                  visibility: "hidden",
                }}
              >
                {t("my_wallets.private_disclaimer", {
                  maxAmount: formattedPrivateMax,
                })}
              </p>
            </Detail>

            <Detail active={position === 1}>
              <div
                css={{
                  color:
                    privateAmount <= 0 ? AppColors.GRAY_200 : AppColors.PRIMARY,
                  flexGrow: 1,
                  fontFamily: AppFonts.NUMBERS,
                }}
              >
                {formattedPrivateAmount}
              </div>

              <MobeaButton
                disabled={privateAmount > MAX_PRIVATE_AMOUNT_BEFORE_TOP_UP}
                onClick={() => pushLocation(history, Routes.AddMoney)}
              >
                {t("home.add_money")}*
              </MobeaButton>
              <p
                css={{
                  color: AppColors.GRAY_300,
                  fontSize: "0.75rem",
                  lineHeight: "1rem",
                  margin: "8px 0 0",
                }}
              >
                {t("my_wallets.private_disclaimer", {
                  maxAmount: formattedPrivateMax,
                })}
              </p>
            </Detail>
          </DetailContainer>
        </>
      )}
    </main>
  );
}
