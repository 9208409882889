import { useReduxState } from "common/hooks";
import { ProviderFilterType } from "pages/providers/filter/ProviderFilterType";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  getRoutePlanData,
  LocationSearchPlace,
  toPlanData,
  TravelTimeOption,
} from "services/mapService";
import { setRoutePlannerSearchAction } from "state/actions";
import { ApiErrors } from "utils";

export function useRoutePlanData() {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [errorCode, setErrorCode] = useState<string | null>(null);

  const [search, setSearch] = useReduxState(
    (state) => state.map.routePlannerSearchResult,
    setRoutePlannerSearchAction
  );

  const reset = () => {
    setLoading(false);
    setErrorCode(null);
    setSearch(null);
  };

  const loadRoutePlannerData = async (
    source: LocationSearchPlace,
    destination: LocationSearchPlace,
    travelTimeOption: TravelTimeOption,
    travelTimeDate: Date,
    transportTypes: ProviderFilterType[]
  ) => {
    try {
      setLoading(true);

      setErrorCode(null);

      const response = await getRoutePlanData(
        source,
        destination,
        travelTimeOption,
        travelTimeDate,
        transportTypes,
        history
      );

      if (response.error) {
        setLoading(false);

        setErrorCode(response.error_code);

        console.error(
          "Failed to fetch route plan data",
          response.error_description
        );
      } else {
        const data = toPlanData(response);

        // replace source and destination with the one from route planner
        // as they might change due to usage of coordinates and reverse geocoding in SkedGo and our end
        data?.groups?.forEach((group) => {
          group.trips.map((trip) => {
            if (trip.segments.length > 0) {
              if (source.lat && source.lng) {
                trip.segments[0].from = { ...source, address: source.label };
              }
              if (destination.lat && destination.lng) {
                trip.segments[trip.segments.length - 1].to = {
                  ...destination,
                  address: destination.label,
                };
              }
            }
          });
        });

        setSearch({
          source,
          destination,
          data,
          travelTimeOption,
          travelTimeDate: travelTimeDate.valueOf(),
          filters: transportTypes,
        });

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);

      setErrorCode(ApiErrors.I_NETWORK);

      console.error("Failed to fetch route plan data", error);
    }
  };

  return [
    loadRoutePlannerData,
    search?.data || null,
    loading,
    errorCode,
    reset,
  ] as const;
}
