import { useBooleanState } from "common/hooks";
import { MapOfflineOverlay } from "common/network/MapOfflineOverlay";
import { MapViewPage } from "common/page/MapViewPage";
import { MyLocationIcon } from "icons/navigation";
import {
  LocationError,
  MapInitPayload,
  useCenteredMap,
  useLocationUpdates,
  useMapInitializer,
  useRouting,
} from "maps";
import { useRouteDestination } from "maps/effects/useRouteDestination";
import { useRoutePlannerSource } from "maps/effects/useRouteSource";
import { LocationAccessDeniedDialog } from "maps/LocationAccessDeniedDialog";
import { ProviderTermsAndConditions } from "pages/conditions/ProviderTermsAndConditions";
import { INITIAL_MAP_ZOOM } from "pages/mobit/map/constants";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LocationSearchPlace } from "services/mapService";
import { TravelPassProvider } from "utils/constants";
import { VertsOrderBottomSlider } from "./VertsOrderBottomSlider";
import "./VertsOrderPage.scss";

export function VertsOrderPage(): ReactElement {
  const { t } = useTranslation();

  const { language } = useSelector((state) => state.user);

  const [mapRef, mapClassRef, onInit, , platformRef] = useMapInitializer(
    language,
    INITIAL_MAP_ZOOM
  );

  const [userLocation, setUserLocationGroup, locationError] =
    useLocationUpdates();

  const [
    locationErrorDialogVisible,
    showLocationErrorDialog,
    hideLocationErrorDialog,
  ] = useBooleanState();

  const [routeSource, setRouteSource] = useState<LocationSearchPlace | null>(
    null
  );

  const [routeDestination, setRouteDestination] =
    useState<LocationSearchPlace | null>(null);

  const [setRouteSourceGroupRef] = useRoutePlannerSource(
    routeSource,
    userLocation
  );

  const [setRouteDestinationGroupRef] = useRouteDestination(routeDestination);

  const [setRoutingMapGroup] = useRouting({
    platformRef: platformRef,
    origin: routeSource,
    destination: routeDestination,
    enabled: true,
    transportMode: "car",
    color: "var(--color-status-green)",
  });

  useEffect(() => {
    const customizeMap = ({ map }: MapInitPayload) => {
      const userLocationGroup = new H.map.Group({ zIndex: 1 });

      setUserLocationGroup(userLocationGroup);

      map.addObject(userLocationGroup);

      const routingGroup = new H.map.Group({ zIndex: 3 });

      setRoutingMapGroup(routingGroup);

      map.addObject(routingGroup);

      const routeSourceDestinationGroup = new H.map.Group({ zIndex: 4 });

      setRouteSourceGroupRef(routeSourceDestinationGroup);

      setRouteDestinationGroupRef(routeSourceDestinationGroup);

      map.addObject(routeSourceDestinationGroup);
    };

    onInit.then(customizeMap);

    return () => {
      // routingGroup.current?.dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useCenteredMap(
    userLocation,
    mapClassRef,
    mapClassRef.current?.getZoom() || INITIAL_MAP_ZOOM,
    false
  );

  const useMyLocation = () => {
    if (userLocation) {
      mapClassRef.current?.setCenter(userLocation, true);

      mapClassRef.current?.setZoom(INITIAL_MAP_ZOOM, true);
    } else if (locationError === LocationError.PermissionDenied) {
      showLocationErrorDialog();
    }
  };

  return (
    <>
      <MapViewPage
        mapRef={mapRef}
        pageClassName="verts-order-map"
        noHeader
        mapActionsOffset={70}
        mapActionsOffsetKeyboard={70}
        css={{
          padding: 0,
        }}
      >
        <div className="map-actions">
          <div className="map-action" onClick={useMyLocation}>
            <MyLocationIcon />
          </div>
        </div>
        <VertsOrderBottomSlider
          onSourceChange={setRouteSource}
          onDestinationChange={setRouteDestination}
        />
        {locationErrorDialogVisible && (
          <LocationAccessDeniedDialog hide={hideLocationErrorDialog} />
        )}
        <MapOfflineOverlay shadow />
      </MapViewPage>

      <ProviderTermsAndConditions
        title={t("verts_order.title")}
        provider={TravelPassProvider.verts}
        description={t("verts_order.terms_description")}
        termsExternalLink="https://taxisverts.be/en/terms-and-conditions"
      />
    </>
  );
}
