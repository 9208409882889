import { DefaultIconProps } from "icons/props";
import { ReactElement } from "react";

export interface HorizontalClassicFlagIconPros extends DefaultIconProps {
  firstColor: string;
  secondColor: string;
  thirdColor: string;
}

export function HorizontalClassicFlagIcon({
  firstColor,
  secondColor,
  thirdColor,
  className = "country-phone-prefix__icon",
  width = "20",
  height = "20",
}: HorizontalClassicFlagIconPros): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="rotate(90,10,10)">
        <path
          fill={firstColor}
          d="m 0,10 c 0,4.3 2.714,7.965 6.522,9.378 V 0.622 A 10.003,10.003 0 0 0 0,10 Z"
        />
        <path
          fill={secondColor}
          d="M 13.478,0.622 A 9.979,9.979 0 0 0 10,0 C 8.777,0 7.605,0.22 6.522,0.622 L 5.652,10 6.522,19.378 A 9.978,9.978 0 0 0 10,20 c 1.223,0 2.395,-0.22 3.478,-0.622 L 14.348,10 Z"
        />
        <path
          fill={thirdColor}
          d="M 20,10 C 20,5.7 17.286,2.035 13.478,0.622 V 19.378 A 10.004,10.004 0 0 0 20,10 Z"
        />
      </g>
    </svg>
  );
}
