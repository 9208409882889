import { OrderError, OrderErrorProps } from "common/errors/OrderError";
import { MobeaModal } from "common/modal";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { ApiErrors } from "utils";

export function BlueBikeOrderError({
  errorCode,
  closeError,
}: OrderErrorProps): ReactElement {
  const { t } = useTranslation();

  switch (errorCode) {
    case ApiErrors.LOW_BUDGET:
      return (
        <MobeaModal
          type="error"
          title={t("shared.oops")}
          confirmText={t("shared.ok")}
          onConfirm={closeError}
        >
          <p>{t("velo_order.order_error_no_budget")}</p>
        </MobeaModal>
      );
    default:
      return <OrderError errorCode={errorCode} closeError={closeError} />;
  }
}
