import { InputGroup, MobeaButton } from "common/forms";
import { NestedPageOverlay } from "common/page/NestedPageOverlay";
import { TFunction } from "i18next";
import { ReactElement, ReactText, useState } from "react";
import { Trans } from "react-i18next";
import { formatIban, removeSpaces, validateIban } from "utils";
import "./ConfirmIbanOverlay.scss";

export interface ConfirmIbanOverlayProps {
  bankAccount?: string;
  t: TFunction;
  onDone: (iban: string) => void;
  onClose(): void;
}

export function ConfirmIbanOverlay({
  bankAccount = "",
  t,
  onDone,
  onClose,
}: ConfirmIbanOverlayProps): ReactElement {
  const [ibanFormatted, setIbanFormatted] = useState(formatIban(bankAccount));

  const validationResult = validateIban(ibanFormatted);

  const ibanValid = !ibanFormatted || validationResult.valid;

  // allow to proceed only when IBAN is entered and valid
  const readyToExpense = ibanFormatted && ibanValid;

  const onChange = (value: ReactText) => {
    const withoutSpaces = removeSpaces(value.toString());
    // format again
    setIbanFormatted(formatIban(withoutSpaces));
  };

  const onSubmit = () => onDone(removeSpaces(ibanFormatted));

  return (
    <NestedPageOverlay
      title={t("iban.confirm_iban")}
      pageClassName="mobea-confirm-iban"
      padding={{
        left: 0,
        right: 0,
        bottom: 0,
      }}
      onNavBack={onClose}
    >
      <section className="mobea-confirm-iban__form">
        <p className="mobea-confirm-iban__form__text">
          <Trans i18nKey="iban.confirm_iban_text">
            Enter the IBAN number of the bank account to which you want to
            receive reimbursement.
          </Trans>
        </p>

        <InputGroup
          className="mobea-confirm-iban__form__iban"
          label="IBAN"
          name="iban"
          type="text"
          value={ibanFormatted}
          placeholder="BE00 0000 0000 0000"
          errorMessage={t("iban.invalid_text")}
          validation={(value) => validateIban(value.toString()).valid}
          onChange={onChange}
          inputAttributes={{
            inputMode: ibanFormatted.length >= 2 ? "numeric" : "text",
            autoComplete: "off",
          }}
        />
      </section>

      <section className="mobea-confirm-iban__empty-space"></section>

      <section className="mobea-confirm-iban__button">
        <MobeaButton disabled={!readyToExpense} onClick={onSubmit}>
          {t("expense.add_expense")}
        </MobeaButton>
      </section>
    </NestedPageOverlay>
  );
}
