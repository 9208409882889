export interface MobeaError {
  type: string;
  message?: string;
  details?: any;
}

export type ApiErrorResponse = {
  error: true;
  error_code: string;
  error_type: string;
  error_description: string;
};

export enum ApiErrors {
  //  /resend-sms
  CUSTOMER_DONT_EXIST = "0101",
  // /resend-sms
  CUSTOMER_NOT_SYNCED = "0102",
  // /resend-sms
  CLIENT_NOT_ACTIVE = "0107",
  CUSTOMER_PW_NOT_SET = "0110",
  // /customer-token/...
  CUSTOMER_TOKEN_EXPIRED = "0201",
  // /customer-token/...
  CUSTOMER_TOKEN_USED = "0202",

  // /reset-password
  CUSTOMER_TOKEN_WRONG = "0206",

  //registration
  PASSWORD_NOT_MATCH = "0207",
  CUSTOMER_ALREADY_EXISTS = "0208",
  // /expenses
  // /<provider>/orders
  // /send-sms-ea
  WRONG_PASSWORD = "0205",
  NO_ACTIVE_PLAN = "0301",
  // /<provider>/orders
  PENDING_TRANSACTION = "0302",
  // /plan
  PLAN_MISSING = "0303",
  // /<provider>/orders
  WRONG_DATA_INTEGRATION = "0304",
  // /<provider>/orders
  LOW_BUDGET = "0305",
  // /resend-sms
  PHONE_MISSING = "0401",
  // /resend-sms
  SMS_FAILED = "0402",
  ORDER_ERROR = "0501",
  MOBIT_NEARBY_ERROR = "0502",
  MOBIT_PRICING_ERROR = "0504",
  MOBIT_AVAILABILITY_ERROR = "0505",
  MOBIT_QUERY_RIDE_ERROR = "0506",
  // /mobit/orders
  MOBIT_DAY_PASS_ALREADY_PURCHASED_ERROR = "0507",
  MOBIT_NEARBY_FENCE_ERROR = "0508",
  // /mobit/${bikeCode}/open-bike
  MOBIT_EXPIRED_DAY_PASS_ERROR = "0515",
  // /nmbs/order
  NMBS_NO_TICKET = "NMBS_NO_TICKET",
  NMBS_SOURCE_TO_DESTINATION_TICKET_ERROR = "NMBS_SOURCE_TO_DESTINATION_ERROR",
  NMBS_DESTINATION_TO_SOURCE_TICKET_ERROR = "NMBS_DESTINATION_TO_SOURCE_ERROR",
  NMBS_RETURN_TICKET_ERROR = "NMBS_RETURN_TICKET_ERROR",
  // /velo/order
  VELO_NO_PASSES_AVAILABLE = "VELO_NO_PASSES_AVAILABLE_ERROR",
  // /expenses
  EXPENSE_DATA_MISSING = "0701",
  // /available-providers
  NO_AVAILABLE_PROVIDERS = "0801",
  // /expenses
  PROVIDER_NOT_EXIST = "0802",
  DELIJN_PRICING_ERROR = "0803",
  DELIJN_ARGUMENTS_ERROR = "0804",
  DELIJN_REFUND_ERROR = "0805",

  // /map/plan
  ROUTE_PLANNER_OUTSIDE_OF_AREA_ERROR = "1001",
  ROUTE_PLANNER_SOURCE_OUTSIDE_OF_AREA_ERROR = "1002",
  ROUTE_PLANNER_DESTINATION_OUTSIDE_OF_AREA_ERROR = "1003",
  ROUTE_PLANNER_SOURCE_DESTINATION_EQUAL = "1101",

  // internal errors
  I_NETWORK = "I000",
  I_LOGGED_OUT = "I001",
  I_NO_CONTENT = "I002",
  I_API_ISSUES = "I003",
  I_OFFLINE = "IO00",
  I_TOO_LARGE_PAYLOAD = "IE01",
  I_NMBS_NO_PRODUCT = "IN01",
  MOBIT_RESERVED = "M009",
  MOBIT_WRONG_CODE = "M014",
  MOBIT_BIKE_BROKEN = "M021",
  MOBIT_RIDING_OTHER = "M022",
  MOBIT_BIKE_USED = "M024",
  MOBIT_RESERVED_ALT = "M025",
  MOBIT_SPECIAL_PASS = "M035",
  MOBIT_LOCK_OPENED = "M100",
  VERTS_JOURNEY_NOT_POSSIBLE = "V001",
  PASSWORD_VALIDATION_FAILED = "0204",

  PASSWORD_DOESNT_MATCH = "0203",
}
