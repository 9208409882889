import { useLocation } from "react-router";
import { WalletType } from "utils";

export function useSelectedWallet<T = undefined>(dflt?: T): WalletType | T {
  const { state } = useLocation();

  return state &&
    typeof state === "object" &&
    [WalletType.BUSINESS, WalletType.PRIVATE].includes(state?.["wallet"])
    ? state["wallet"]
    : dflt;
}
