import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getBlueBikeData } from "services/blueBikeService";
import { BlueBikeData, setBlueBikeDataAction } from "state/actions";

export function useBlueBikeData(skip = false) {
  const history = useHistory();

  const dispatch = useDispatch();

  const { loaded } = useSelector((state) => state.ordering.blueBikeData);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let canceled = false;
    let isLoading = false;

    const fetchBlueBikeData = async () => {
      try {
        setLoading(true);
        isLoading = true;

        const orderJson = await getBlueBikeData(history);

        if (!orderJson.error) {
          const data: BlueBikeData = {
            minPasses: parseInt(orderJson.min_passes_bought),
            maxPasses: parseInt(orderJson.max_passes_bought),
            price: parseFloat(orderJson.day_pass_price),
            validity: parseInt(orderJson.validity_period_hours),
            returnTime: parseInt(orderJson.return_period_minutes ?? "15"),
            loaded: true,
          };

          if (!canceled) {
            dispatch(setBlueBikeDataAction(data));

            setLoading(false);
          }
        } else {
          if (!canceled) {
            setLoading(false);
          }

          console.error(
            "Failed to fetch Velo data",
            orderJson.error_description
          );
        }
      } catch (error) {
        if (!canceled) {
          setLoading(false);
        }

        console.error("Failed to fetch Velo data", error);
      } finally {
        isLoading = false;
      }
    };

    // allow to ignore this call - e.g. ticket preview
    if (!loaded && !skip) {
      fetchBlueBikeData();
    }

    return () => {
      canceled = true;

      isLoading && console.debug("Canceled hook useBlueBikeData");
    };
  }, [dispatch, history, loaded, skip]);

  return [loading] as [boolean];
}
