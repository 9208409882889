import { useBooleanState } from "common/hooks";
import { ChevronIcon } from "icons/ChevronIcon";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RoutePlanSegmentStop } from "services/mapService";
import { formatTimeWithLocale } from "utils";
import "./TripSegmentStops.scss";

type Props = {
  startTime: number;
  stops: RoutePlanSegmentStop[];
  className?: string;
};

export function TripSegmentStops({
  startTime,
  stops,
  className,
}: Props): ReactElement {
  const { t } = useTranslation();

  const language = useSelector((state) => state.user.language);

  const [stopsVisible, showStops, hideStops] = useBooleanState();

  const firstStop = stops[0];

  const lastStop = stops[stops.length - 1];

  const toStop = (
    stop: RoutePlanSegmentStop,
    relativeTime: number,
    className = ""
  ) => (
    <div
      key={stop.code}
      className={`route-planner-trip-segment-stops__stop ${className}`}
    >
      <div className="name-time">
        <div className="name-time__name">{stop.name}</div>
        <div className="name-time__time">
          {formatTimeWithLocale(startTime + relativeTime * 1000, language)}
        </div>
      </div>
    </div>
  );

  const stopsArg = {
    stopCount: stops.length - 2,
  };

  return (
    <div className={`route-planner-trip-segment-stops ${className}`}>
      {toStop(firstStop, firstStop.relativeDeparture, "first")}

      {stops.length > 2 && (
        <div
          className={`route-planner-trip-segment-stops__other-stops ${
            stopsVisible ? "open" : ""
          }`}
        >
          <div
            className="route-planner-trip-segment-stops__show-hide"
            onClick={stopsVisible ? hideStops : showStops}
          >
            {stopsVisible
              ? t("map.hide_stops", stopsArg)
              : t("map.show_stops", stopsArg)}
            <ChevronIcon
              width={16}
              height={16}
              orientation={stopsVisible ? "up" : "down"}
            />
          </div>

          {stopsVisible &&
            stops
              .slice(1, stops.length - 1)
              .map((stop) => toStop(stop, stop.relativeDeparture))}
        </div>
      )}

      {toStop(lastStop, lastStop.relativeArrival, "last")}
    </div>
  );
}
