import { ChangeEvent, useState } from "react";
import {
  formatPhoneNumberWithoutPrefix,
  PhonePrefix,
  removeSpaces,
  validateNumber,
} from "utils";
import { getAllowedPhoneNumbers } from "utils/configure";

export const usePhoneNumbersValidation = () => {
  const allowedPhoneNumbers = getAllowedPhoneNumbers();

  const [valid, setValid] = useState(false);

  const [prefix, setPrefix] = useState<PhonePrefix>(allowedPhoneNumbers[0]);

  const [localNumberFormatted, setLocalNumberFormatted] = useState("");

  const onPhoneNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = removeSpaces(e.target.value);

    const formattedNumber = formatPhoneNumberWithoutPrefix(value);

    setLocalNumberFormatted(formattedNumber);

    setValid(validateNumber(value, prefix));
  };

  const onPrefixChange = (code: string) => {
    const prefix = allowedPhoneNumbers.find((prefix) => prefix.code === code);

    prefix && setPrefix(prefix);
  };

  const reset = () => {
    setPrefix(allowedPhoneNumbers[0]);
    setLocalNumberFormatted("");
    setValid(false);
  };

  return [
    prefix,
    localNumberFormatted,
    valid,
    onPrefixChange,
    onPhoneNumberChange,
    reset,
  ] as const;
};
