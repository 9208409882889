import { MobeaButton } from "common/forms";
import { useClientLogo } from "common/hooks/useClientLogo";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Routes } from "utils";
import "./PreLoginPage.scss";
export function PreLoginPage() {
  const { t } = useTranslation();

  const history = useHistory();

  const handleLoginClick = () => {
    history.push(Routes.Login);
  };

  const logo = useClientLogo();

  return (
    <main className="mobea-pre-login">
      <section>
        <div className="logo-wrapper">
          {logo}
          <p>{t("log_in.slogan")}</p>
        </div>
        <MobeaButton onClick={handleLoginClick}>
          {t("log_in.log_in")}
        </MobeaButton>
      </section>
    </main>
  );
}
