import { ResizeObserver as ResizeObserverPolyfill } from "@juggle/resize-observer";
import ReactDOM from "react-dom";
import "react-hot-loader";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import {
  AppConfig,
  customizeApp,
  normalizeAppConfig,
  persistClientConfig,
} from "utils/configure";
import { addInstalLifecycleHooks } from "utils/install";
import { ErrorBoundary } from "utils/logging";
import App from "./App";
import { store } from "./state/store";
import "./style.scss";
import "./utils/i18n";
import * as serviceWorker from "./utils/serviceWorker";

// polyfill the global object so library uses it
if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserverPolyfill;
}

// add listeners for app lifecycle events
addInstalLifecycleHooks();

const root = document.getElementById("root");

let shouldPersistAppConfig = false;

window.mobeaConfigFetch
  .then((response) => {
    // fetch response
    if ("json" in response) {
      shouldPersistAppConfig = true;

      return (response as Response).json().then(normalizeAppConfig);
    }

    return response as unknown as AppConfig;
  })
  .then(customizeApp)
  .then((config) => {
    // do not update config if we do not load new one or it's initial micro config for log in screens
    shouldPersistAppConfig && !config.minimal && persistClientConfig();
  })
  .then(() => {
    ReactDOM.render(
      <Provider store={store}>
        <Router>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </Router>
      </Provider>,
      root
    );
  });

// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: (registration: ServiceWorkerRegistration) => {
    registration.installing?.postMessage({ type: "SKIP_WAITING" });

    window.dispatchEvent(new Event("updateready"));
  },
});
