import { CloseIcon } from "icons/CloseIcon";
import { NavBackIcon } from "icons/NavBackIcon";
import {
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { animated, useSpring } from "react-spring";
import { useGesture } from "react-use-gesture";
import "./BottomSlider.scss";

const DEFAULT_VISIBLE_HEIGHT = 145;

const CHANGE_THRESHOLD = 100;

const RUBBER_BAND_SIZE = 20;

export interface BottomSliderRef {
  open(): void;
  close(): void;
}

export type BottomSliderProps = {
  className?: string;
  title?: ReactNode;
  hidden?: boolean;
  defaultOpen?: boolean;
  keepOpen?: boolean;
  children: ReactNode;
  onBack?(): void;
  onClose?(): void;
  onChange?: (open: boolean) => void;
  visibleHeight?: number;
};

export const BottomSlider = forwardRef<BottomSliderRef, BottomSliderProps>(
  (
    {
      className = "",
      title,
      hidden = false,
      defaultOpen = false,
      keepOpen = false,
      children,
      onBack,
      onClose,
      onChange,
      visibleHeight = DEFAULT_VISIBLE_HEIGHT,
    },
    ref
  ) => {
    const [sliderOpened, setSliderOpened] = useState(defaultOpen);

    const sliderRef = useRef<HTMLDivElement>(null);

    const dragAreaRef = useRef<HTMLDivElement>(null);

    const sliderPosition = useRef(0);

    const [sliderStyle, setSliderStyle] = useSpring(() => ({
      transform: "translateY(1000px)",
    }));

    useGesture(
      {
        onClick: () => {
          setSliderStyle({
            transform: `translateY(${
              sliderOpened ? sliderRef.current!.clientHeight - visibleHeight : 0
            }px)`,
          });

          setSliderOpened((o) => !o);
        },
        onDragStart: () => {
          sliderPosition.current = sliderOpened
            ? 0
            : sliderRef.current!.clientHeight - visibleHeight;
        },
        onDrag: ({ event, movement: [, y] }) => {
          if (y !== 0) {
            event?.preventDefault();

            setSliderStyle({
              transform: `translateY(${Math.max(
                -RUBBER_BAND_SIZE,
                Math.min(
                  sliderPosition.current + y,
                  sliderRef.current!.clientHeight -
                    visibleHeight +
                    RUBBER_BAND_SIZE
                )
              )}px)`,
            });
          }
        },
        // @ts-ignore: TS does not recognize the function correctly
        onDragEnd: ({ movement: [, y] }) => {
          if (sliderOpened && y > CHANGE_THRESHOLD) {
            setSliderOpened(false);
          } else if (!sliderOpened && y < -CHANGE_THRESHOLD) {
            setSliderOpened(true);
          } else {
            setSliderStyle({
              transform: `translateY(${sliderPosition.current}px)`,
            });
          }
        },
      },
      {
        enabled: !keepOpen,
        domTarget: dragAreaRef,
        eventOptions: { passive: false },
      }
    );

    useEffect(() => {
      if (hidden) {
        setSliderStyle({
          transform: `translateY(${sliderRef.current!.clientHeight}px)`,
        });
      } else {
        setSliderStyle({
          transform: `translateY(${
            sliderOpened || keepOpen
              ? 0
              : sliderRef.current!.clientHeight - visibleHeight
          }px)`,
        });
      }
      onChange && onChange(!hidden && (sliderOpened || keepOpen));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sliderOpened, hidden, keepOpen, children]);

    useImperativeHandle(ref, () => ({
      open: () => setSliderOpened(true),
      close: () => setSliderOpened(false),
    }));

    return (
      <animated.div
        ref={sliderRef}
        className={`mobea-bottom-slider ${className}`}
        style={sliderStyle}
      >
        <div
          ref={dragAreaRef}
          className={`mobea-bottom-slider__drag-area ${
            title ? "title-visible" : ""
          }`}
        >
          <div
            className={`mobea-bottom-slider__touch-marker ${
              keepOpen ? "hidden" : ""
            }`}
          >
            {sliderOpened ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                fill="currentColor"
                className="bi bi-chevron-compact-down"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67z"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                fill="currentColor"
                className="bi bi-chevron-compact-up"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M7.776 5.553a.5.5 0 0 1 .448 0l6 3a.5.5 0 1 1-.448.894L8 6.56 2.224 9.447a.5.5 0 1 1-.448-.894l6-3z"
                />
              </svg>
            )}
          </div>
          {title && <div className="mobea-bottom-slider__title">{title}</div>}
        </div>

        {onBack && (
          <NavBackIcon
            className="mobea-bottom-slider__back-icon"
            onClick={onBack}
          />
        )}

        {onClose && (
          <CloseIcon
            className="mobea-bottom-slider__close-icon"
            onClick={onClose}
          />
        )}

        <div
          className={`mobea-bottom-slider__content ${
            title ? "title-visible" : ""
          }`}
        >
          {children}
        </div>
      </animated.div>
    );
  }
);

BottomSlider.displayName = "BottomSlider";
