import { ReactElement } from "react";
import { DefaultIconProps } from "../props";

export function TaxiIcon({
  fill = "currentColor",
  className = "",
  height = "20",
  width = "20",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 6c-.3-.6-.8-1-1.5-1H15V3H9v2H6.5c-.7 0-1.2.4-1.4 1L3 12v8c0 .6.5 1 1 1h1c.5 0 1-.4 1-1v-1h12v1c0 .6.4 1 1 1h1c.6 0 1-.4 1-1v-8l-2-6zM6.4 16a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm11 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3zM5 11l1.5-4.5h11L19 11H5z"
        fill={fill}
      />
    </svg>
  );
}
