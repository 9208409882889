import { LabelValue } from "common/forms";
import { NavBackIcon } from "icons/NavBackIcon";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { padWithZero } from "utils";
import "./TicketStartEnd.scss";

export interface MobitTicketStartEndProps {
  validityStart?: number;
  validityEnd?: number;
  noWrapper?: boolean;
}

export function TicketStartEnd({
  validityStart = 0,
  validityEnd = 0,
  noWrapper = false,
}: MobitTicketStartEndProps): ReactElement {
  const formatDateTime = (date: Date) =>
    `${padWithZero(date.getHours())}:${padWithZero(
      date.getMinutes()
    )} ${padWithZero(date.getDate())}/${padWithZero(
      date.getMonth() + 1
    )}/${date.getFullYear()}`;

  const startFormatted = formatDateTime(new Date(validityStart));

  const endFormatted = formatDateTime(new Date(validityEnd));

  const { t } = useTranslation();

  const toContent = () => (
    <>
      <LabelValue
        className="from"
        label={t("time.from")}
        value={startFormatted}
      />

      <div className="mobea__ticket-start-end__separator">
        <NavBackIcon />
      </div>

      <LabelValue className="to" label={t("time.to")} value={endFormatted} />
    </>
  );

  if (noWrapper) {
    return toContent();
  }

  return <section className="mobea__ticket-start-end">{toContent()}</section>;
}
