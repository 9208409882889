import { ReactElement } from "react";
import { DefaultIconProps } from "../props";

export function BikeIcon({
  fill = "currentColor",
  className = "",
  height = "20",
  width = "20",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2 10l-1.7-4.7A2 2 0 0014.6 4H12v2h2.6l1.5 4h-4.9L11 9H12V7H7v2h1.8l1.8 5h-.7a5 5 0 00-4.7-4A5 5 0 000 15a5 5 0 005 5 5 5 0 004.9-4h4.2a5 5 0 004.7 4 5 5 0 005.2-5 5 5 0 00-5-5h-.8zM7.8 16A3 3 0 015 18a3 3 0 01-3-3 3 3 0 013-3 3 3 0 012.8 2H5v2h2.8zm6.3-2h-1.4l-.7-2h3c-.4.6-.8 1.3-.9 2zm4.9 4a3 3 0 01-3-3 3 3 0 011-2.3l1 2.7 1.9-.7-1-2.7h.1a3 3 0 013 3 3 3 0 01-3 3z"
        fillRule="nonzero"
        fill={fill}
      />
    </svg>
  );
}
