export enum VertsStatus {
  // t("verts_detail.status.created")
  // t("verts_detail.info.created")
  Created = "created",

  // t("verts_detail.status.ordered")
  Ordered = "ordered",

  // t("verts_detail.info.searching")
  // t("verts_detail.status.searching")
  Searching = "searching",

  // t("verts_detail.info.no_driver_found")
  // t("verts_detail.status.no_driver_found")
  NoDriverFound = "no_driver_found",

  // t("verts_detail.info.driver_found")
  // t("verts_detail.status.driver_found")
  DriverFound = "driver_found",

  // t("verts_detail.status.arriving")
  Arriving = "arriving",

  // t("verts_detail.status.waiting")
  Waiting = "waiting",

  // t("verts_detail.status.in_progress")
  InProgress = "in_progress",

  // t("verts_detail.status.finished")
  Finished = "finished",

  // t("verts_detail.status.cancelled")
  Cancelled = "cancelled",

  // t("verts_detail.info.error")
  // t("verts_detail.status.error")
  Error = "error",
}
