import { usePendingOrder } from "common/hooks";
import { useSelectedWallet } from "common/hooks/useSelectedWallet";
import { MobeaModal } from "common/modal";
import { OrderFooter } from "common/order/OrderFooter";
import { NestedPageFull } from "common/page/NestedPageFull";
import { CircleCheckIcon } from "icons/CircleCheckIcon";
import { MobitLogo } from "icons/logos/MobitLogo";
import { ProviderTermsAndConditions } from "pages/conditions/ProviderTermsAndConditions";
import { ReactElement, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  formatCurrency,
  pushLocation,
  Routes,
  TravelPassProvider,
  WalletType,
} from "utils";
import { useMobitOrder } from "./hooks/useMobitOrder";
import { useMobitOrderData } from "./hooks/useMobitOrderData";
import { MobitOrderError } from "./MobitOrderError";
import "./MobitOrderPage.scss";

export function MobitOrderPage(): ReactElement {
  const history = useHistory();

  const { t } = useTranslation();

  const { duration, price, dayLimit } = useSelector(
    (state) => state.ordering.mobitDayPass
  );

  const locale = useSelector((state) => state.user.language);

  const [loading] = useMobitOrderData();

  const [orderPending] = usePendingOrder(TravelPassProvider.mobit);

  const wallet = useSelectedWallet(WalletType.BUSINESS);

  const [makeOrder, orderedPass, hasError, ordering, errorCode] =
    useMobitOrder();

  const [errorDialogOpened, setErrorDialogOpened] = useState(true);

  const formattedPrice = formatCurrency(price || 0, locale);

  const order = () => {
    makeOrder(Date.now(), {
      price,
      duration,
      expiration: Date.now() + dayLimit * 24 * 3600 * 1000,
      wallet,
    });

    // reset to default so error can be shown again
    setErrorDialogOpened(true);
  };

  const closeError = () => setErrorDialogOpened(false);

  const showTicketDetail = (id: string) => {
    pushLocation(
      history,
      Routes.MobitTicketDetail.replace(":id", encodeURIComponent(id)) +
        "?showTutorial=true"
    );
  };

  return (
    <>
      <NestedPageFull
        pageClassName="mobea-mobit-order"
        title={t("mobit_order.order_mobit_pass")}
        headerHeight="minimal"
      >
        <section className="mobea-mobit-order__logo">
          <MobitLogo />
        </section>

        <section className="mobea-mobit-order__ticket">
          <div className="mobea-mobit-order__ticket__details">
            <h2 className="mobea-mobit-order__ticket__details__name">
              <span className="mobea-mobit-order__ticket__details__name__green">
                Day
              </span>
              Pass
            </h2>
            <p className="mobea-mobit-order__ticket__details__info">
              <Trans i18nKey="mobit_order.order_mobit_pass__text">
                {
                  "The pass becomes active from the moment you order it and can be used for the next "
                }
                <span className="mobea__bold mobea__arial">
                  {{ duration }} <span className="font__reset">hours</span>
                </span>
                . Enjoy your trip!
              </Trans>
            </p>
          </div>
          <OrderFooter
            formattedPrice={formattedPrice}
            disabled={loading || orderPending || ordering}
            pending={loading || orderPending || ordering}
            buttonLabel={
              orderPending
                ? t("shared.order_in_progress")
                : t("mobit_order.order_day_pass")
            }
            confirmText={
              <p>
                <Trans i18nKey="mobit_order.confirm_purchase">
                  {"Your ticket will be active for "}
                  <span className="mobea__arial">{{ duration }}</span>
                  {" hours from the moment it is bought and it costs "}
                  <span className="mobea__arial">
                    {{ price: formattedPrice }}
                  </span>
                  .
                </Trans>
              </p>
            }
            onOrder={order}
          />
        </section>

        {orderedPass && orderedPass.id && (
          <MobeaModal
            confirmText={t("shared.super")}
            title={t("mobit_order.day_pass_bought")}
            image={<CircleCheckIcon />}
            onConfirm={() => showTicketDetail(orderedPass.id)}
          >
            <p>
              <Trans i18nKey="mobit_order.day_pass_bought_text">
                {"The Pass is active and can be used for the next "}
                <span className="mobea__bold mobea__arial">
                  {{ duration }} <span className="font__reset">hours</span>
                </span>
                . Enjoy your trip!
              </Trans>
            </p>
          </MobeaModal>
        )}

        {errorDialogOpened && hasError && (
          <MobitOrderError errorCode={errorCode} closeError={closeError} />
        )}
      </NestedPageFull>

      <ProviderTermsAndConditions
        title={t("mobit_order.order_mobit_pass")}
        provider={TravelPassProvider.mobit}
        description={t("mobit_order.terms_description")}
        termsExternalLink="https://www.mobit.eu/terms-and-conditions.html"
      />
    </>
  );
}
