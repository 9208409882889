import { MobeaButton } from "common/forms";
import { useProviderActions } from "pages/map/hooks/useProviderActions";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RoutePlanTripSegment } from "services/mapService";
import { formatTimeWithLocale, TravelPassProvider, WalletType } from "utils";
import { TripProviderIconWrapper } from "./TripProviderIconWrapper";
import { TripSegmentStops } from "./TripSegmentStops";
import { TripSegmentTemplate } from "./TripSegmentTemplate";

type Props = RoutePlanTripSegment & {
  nextSegment: RoutePlanTripSegment;
};

export function TripSegmentDetail({
  startTime,
  endTime,
  from,
  to,
  serviceNumber,
  serviceDirection,
  type,
  providers,
  stops,
  nextSegment,
}: Props): ReactElement {
  const { t } = useTranslation();

  const language = useSelector((state) => state.user.language);

  // do not show 0 minutes
  // @TODO would be better to have some text e.g. less than a minute
  const durationMinutes = Math.ceil((endTime - startTime) / (60 * 1000)) || 1;

  const [
    primaryActionName,
    onPrimaryAction,
    secondaryActionName,
    onSecondaryAction,
    actionsEnabled,
  ] = useProviderActions(providers[0], endTime);

  const getActionName = () => {
    switch (providers[0]?.type) {
      case TravelPassProvider.mivb:
        return secondaryActionName;
      default:
        return primaryActionName;
    }
  };

  const onAction = () => {
    switch (providers[0]?.type) {
      case TravelPassProvider.mivb:
        return onSecondaryAction();
      case TravelPassProvider.verts:
        return onPrimaryAction({
          origin: from && {
            ...from,
            label: from.address,
          },
          destination: to && {
            ...to,
            label: to.address,
          },
          departure: startTime,
          wallet: WalletType.BUSINESS,
        });
      case TravelPassProvider.nmbs:
        return onPrimaryAction({
          departure: startTime,
          nmbsSourceCode: stops[0]?.code,
          nmbsDestinationCode: stops[stops.length - 1]?.code,
        });
      default:
        return onPrimaryAction();
    }
  };

  const providerSlug = providers[0]?.type ?? nextSegment?.providers[0]?.type;

  const getTranslateOptions = () => ({
    destination: to?.address,
    duration: durationMinutes,
    serviceNumber,
    directions: serviceDirection || to?.address,
    provider: providerSlug ? t(`providers_names.${providerSlug}`) : "",
  });

  return (
    <TripSegmentTemplate
      icon={
        <TripProviderIconWrapper
          segmentInfo={{
            type: type,
            providers,
          }}
          outlined
          size={32}
          css={{
            flexShrink: 0,
          }}
        />
      }
      title={t(`map.trip_title.${type}`, getTranslateOptions())}
      time={formatTimeWithLocale(startTime, language)}
      description={t(`map.trip_description.${type}`, getTranslateOptions())}
      servicedBy={
        providers.length > 0 ? (
          <span>
            {t("map.service_by")} {t(`providers_names.${providers[0].type}`)}
          </span>
        ) : undefined
      }
    >
      {getActionName() && (
        <div
          className="route-planner-trip-segment-detail__title-info__action"
          css={{
            marginTop: 12,
          }}
        >
          <MobeaButton
            type="secondary"
            onClick={onAction}
            disabled={!actionsEnabled}
            css={{
              marginTop: 0,
              width: "auto",
              paddingLeft: 16,
              paddingRight: 16,
              fontSize: 14,
              lineHeight: "14px",
              minHeight: 32,
              borderWidth: 1,
            }}
          >
            {getActionName()}
          </MobeaButton>
        </div>
      )}

      {stops.length > 1 && (
        <TripSegmentStops
          startTime={startTime}
          stops={stops}
          css={{ marginTop: 12 }}
        />
      )}
    </TripSegmentTemplate>
  );
}
