import { StandardErrorDialog } from "common/errors/StandardErrorDialog";
import { MobeaButton } from "common/forms";
import { useUpdatedCustomer } from "common/hooks";
import { NestedPageFull } from "common/page/NestedPageFull";
import { Spinner } from "common/Spinner";
import { ProfileIcon } from "icons/ProfileIcon";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ApiErrors, formatToServiceDate, Routes, validateEmail } from "utils";
import { ProfileUpdateSuccessDialog } from "./dialogs/ProfileUpdateSuccessDialog";
import { ProfileForm } from "./form/ProfileForm";
import { useUpdateProfile } from "./hooks/useUpdateProfile";
import "./ProfileRegisteredPage.scss";

export function ProfileRegisteredPage(): ReactElement {
  const { t } = useTranslation();
  const history = useHistory();

  const loading = useUpdatedCustomer();

  const {
    customerId,
    name,
    surname,
    phonePrefix,
    phoneNumber,
    dateOfBirth,
    iban: userIban,
    email: userEmail,
    language,
  } = useSelector((state) => state.user);

  const [email, setEmail] = useState("");

  const [result, updating, updateProfile, reset] = useUpdateProfile();

  const emailValid = validateEmail(email.toString());

  const formattedDateOfBirth = dateOfBirth
    ? formatToServiceDate(new Date(dateOfBirth))
    : "";

  const changed = formattedDateOfBirth !== dateOfBirth || email != userEmail;

  const saveEnabled = emailValid && changed;

  const goToSettings = () => history.push(Routes.Settings);

  useEffect(() => {
    setEmail(userEmail);
  }, [userIban, userEmail]);

  const doProfileUpdate = () => {
    updateProfile(customerId, {
      email,
    });
  };

  return (
    <NestedPageFull
      pageClassName="mobea-profile-registered"
      title={t("settings.profile")}
      headerInSecondaryColor
    >
      {loading && <Spinner />}
      {!loading && (
        <>
          <section className="mobea-profile-registered__headline">
            <h2 className="mobea-profile-registered__headline__name">
              {name}&nbsp;{surname}
            </h2>
            <div className="mobea-profile-registered__headline__icon">
              <div className="mobea-profile-registered__headline__icon__bg"></div>
              <ProfileIcon />
            </div>
          </section>

          <ProfileForm
            dateOfBirth={dateOfBirth}
            email={email}
            language={language}
            nameAndSurname=""
            phoneNumber={phoneNumber}
            phonePrefix={phonePrefix}
            onEmailChange={setEmail}
          />

          <section className="mobea-profile-registered__empty-space"></section>

          <section className="mobea-profile-registered__button">
            <MobeaButton
              disabled={!saveEnabled || updating}
              onClick={doProfileUpdate}
              loading={updating}
            >
              {updating ? t("shared.saving") : t("shared.save")}
            </MobeaButton>
          </section>

          {result === "success" && (
            <ProfileUpdateSuccessDialog onConfirm={goToSettings} />
          )}

          {result && result in ApiErrors && (
            <StandardErrorDialog onConfirm={reset} />
          )}
        </>
      )}
    </NestedPageFull>
  );
}
