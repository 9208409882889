import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

export function BriefcaseIcon({
  fill = "currentColor",
  className = "",
  height = "24",
  width = "24",
  onClick,
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox="0 0 20 19"
      onClick={onClick}
    >
      <path
        d="M20 9.5V6.38c0-1-.88-1.88-1.88-1.88H15V2.63c0-1-.88-1.88-1.88-1.88H6.87C5.87.75 5 1.63 5 2.63V4.5H1.87C.87 4.5 0 5.38 0 6.38V9.5h20zm-7.5-5h-5V3.25h5V4.5zm5.62 13.75c1 0 1.88-.88 1.88-1.87v-5.63h-7.5v1.88c0 .34-.28.62-.63.62H8.12a.62.62 0 01-.62-.62v-1.88H0v5.63c0 1 .88 1.87 1.87 1.87h16.25z"
        fill={fill}
        fillRule="nonzero"
      />
    </svg>
  );
}
