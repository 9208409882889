import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getTicketDetail } from "services/travelPassService";
import { orderVertsRide, VertsOrderPayload } from "services/vertsService";
import {
  addActiveTravelPassAction,
  addPurchaseAction,
  VertsTravelPass,
} from "state/actions";
import { ApiErrors } from "utils";
import { normalizeVertsTravelPass } from "utils/normalizers/vertsNormalizer";

export function useVertsOrder() {
  const history = useHistory();

  const dispatch = useDispatch();

  const { providers } = useSelector((state) => state.passes);

  const [result, setResult] = useState<VertsTravelPass | null>(null);

  const [ordering, setOrdering] = useState(false);

  const [error, setError] = useState<ApiErrors | null>(null);

  const order = async (orderRequest: VertsOrderPayload) => {
    try {
      setOrdering(true);

      setError(null);

      const response = await orderVertsRide(orderRequest, history);

      if (response.error) {
        setError(response.error_code as ApiErrors);

        console.error(
          `Failed to order Verts: ${response.error_code} - ${response.error_description}`
        );
      } else {
        const ticketResponse = await getTicketDetail(
          response.data.transaction_id,
          history
        );

        if (ticketResponse.error) {
          setError(ticketResponse.error_code as ApiErrors);

          console.error(
            `Failed to find orderer Verts ticket: ${ticketResponse.error_code} - ${ticketResponse.error_description}`
          );
        } else {
          const normalizedTicket = normalizeVertsTravelPass(
            ticketResponse,
            providers
          );

          setResult(normalizedTicket);

          // dispatch even if navigated out
          dispatch(addActiveTravelPassAction(normalizedTicket));

          // add to history for case app goes offline so history is consistent
          dispatch(addPurchaseAction(normalizedTicket));
        }
      }
    } catch (error) {
      console.error("Failed to order Verts taxi", error);

      setError(ApiErrors.ORDER_ERROR);
    } finally {
      setOrdering(false);
    }
  };

  return [order, result, ordering, error] as const;
}
