import Color from "color";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  LocationCoords,
  RoutePlanTrip,
  RoutePlanTripSegment,
  SegmentType,
} from "services/mapService";
import { AppColors, getDerivedColor } from "utils/colors";
import "./useTripRoute.scss";

export function useTripRoute(trip: RoutePlanTrip | null) {
  const allProviders = useSelector((state) => state.passes.providers);

  const [mapGroup, setMapGroup] = useState<H.map.Group | null>(null);

  useEffect(() => {
    if (!mapGroup) {
      return;
    }

    mapGroup.removeAll();

    if (!trip) {
      return;
    }

    const getStartEndMarker = (
      location: LocationCoords,
      color: string | undefined
    ) => {
      if (
        typeof location.lat !== "number" ||
        typeof location.lng !== "number"
      ) {
        // prevent error in case location is missing. Ignore such a stop as it isn't drawable
        return;
      }
      return new H.map.DomMarker(location, {
        icon: new H.map.DomIcon(
          `<div class="trip-route-start-end-marker" style="background: ${color}"/>`
        ),
      });
    };

    const getStopMarker = (location: LocationCoords) =>
      new H.map.DomMarker(location, {
        icon: new H.map.DomIcon('<div class="trip-route-stop-marker"/>'),
      });

    const getSegmentColor = (
      segment: RoutePlanTripSegment
    ): string | undefined => {
      const provider =
        segment.providers[0] &&
        allProviders.find(
          (allProvider) => allProvider.provider === segment.providers[0].type
        );

      switch (segment.type) {
        case SegmentType.Walk:
          return AppColors.GRAY_200;
        case SegmentType.Car:
          return AppColors.GRAY_100;
        case SegmentType.Bicycle:
        case SegmentType.BicycleShare:
        case SegmentType.BicycleElectricShare:
          return provider?.color || AppColors.GRAY_100;
        default: {
          return provider?.color;
        }
      }
    };

    const getSegmentLineDash = (
      segment: RoutePlanTripSegment
    ): number[] | undefined => {
      switch (segment.type) {
        case SegmentType.Walk:
          return [15, 30];
        default:
          return [];
      }
    };

    trip.segments.forEach((segment, index) => {
      // skip drawing waiting segment
      if (
        [SegmentType.Transfer, SegmentType.TaxiWait, undefined].includes(
          segment.type
        )
      ) {
        return;
      }
      const color = getSegmentColor(segment);

      const lineDash = getSegmentLineDash(segment);

      segment.stops.forEach((stop) => {
        mapGroup.addObject(getStopMarker(stop));
      });

      if (segment.from) {
        const marker = getStartEndMarker(segment.from, color);
        marker && mapGroup.addObject(marker);
      }

      let lines: H.geo.LineString[] = [];

      if (segment.bikeRoute) {
        lines = segment.bikeRoute.map((route) =>
          (H.geo.LineString as any).fromFlexiblePolyline(route)
        );
      } else {
        lines.push(
          H.geo.LineString.fromLatLngArray(
            segment.waypoints.flatMap((waypoint) => [
              waypoint.lat,
              waypoint.lng,
            ])
          )
        );
      }

      try {
        lines.forEach((line) => {
          if (line.getPointCount() === 0) {
            // invalid line which cause error
            return;
          }

          if (color) {
            mapGroup.addObject(
              new H.map.Polyline(line, {
                style: {
                  strokeColor: Color(getDerivedColor(color))
                    .darken(0.3)
                    .string(),
                  lineWidth: 6,
                  lineDash,
                },
              })
            );
          }

          mapGroup.addObject(
            new H.map.Polyline(line, {
              zIndex: segment.type === SegmentType.Walk ? 2 : 1,
              style: {
                strokeColor: getDerivedColor(color || AppColors.PRIMARY),
                lineWidth: 4,
                lineDash,
              },
            })
          );
        });
      } catch (error) {
        // InvalidArgumentError expected here for incorrect routes
        // we need to handle this case so the app does not break
        console.error(error, segment.waypoints, lines);
      }

      const lastSegment = index === trip.segments.length - 1;

      if (lastSegment && segment.to) {
        const marker = getStartEndMarker(segment.to, color);
        marker && mapGroup.addObject(marker);
      }
    });
  }, [allProviders, mapGroup, trip]);

  return [setMapGroup] as const;
}
