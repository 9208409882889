import {
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { AppColors } from "utils/colors";

export type AccordionProps = {
  items: { header: (expanded: boolean) => ReactElement; body: ReactElement }[];
  onExpandedHeightChange(height: number): void;
};

export function Accordion({ items, onExpandedHeightChange }: AccordionProps) {
  const [expanded, setExpanded] = useState<number[]>([]);

  const [divs, setDivs] = useState(new Map<number, HTMLDivElement | null>());

  const setDivEle = useCallback((i: number, divEle: HTMLDivElement | null) => {
    setDivs((divs) => divs.set(i, divEle));
  }, []);

  useLayoutEffect(() => {
    function handleResize() {
      for (const div of divs.values()) {
        if (div?.firstChild instanceof HTMLElement) {
          div.style.maxHeight = div.firstChild.clientHeight + "px";
        }
      }
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [divs]);

  useEffect(() => {
    let sum = 0;

    for (const index of expanded) {
      const div = divs.get(index);

      sum +=
        div?.firstChild instanceof HTMLElement
          ? div.firstChild.clientHeight
          : 0;
    }

    onExpandedHeightChange(sum);
  }, [divs, expanded, onExpandedHeightChange]);

  function handleHeaderClick(i: number) {
    setExpanded((expanded) => {
      const idx = expanded.indexOf(i);

      return idx === -1
        ? [...expanded, i]
        : expanded.filter((item) => item !== i);
    });
  }

  return (
    <>
      {items.map((item, i) => (
        <Fragment key={i}>
          {i > 0 && (
            <div
              css={{
                height: 1,
                background: AppColors.BACKGROUND,
                marginLeft: "5.75rem",
              }}
            />
          )}
          <button
            css={{
              background: "none",
              border: 0,
              display: "flex",
              alignItems: "center",
              padding: "1rem",
              paddingLeft: "2rem",
              width: "100%",
              textAlign: "left",
              "&:focus": { outline: "none" },
              // gap: "1rem", // not working in Safari (AKA new IE)
              "& > *": {
                margin: "0 0.5rem",
              },
              "& > *:first-child": {
                marginLeft: 0,
              },
              "& > *:last-child": {
                marginRight: 0,
              },
            }}
            onClick={() => handleHeaderClick(i)}
          >
            {item.header(expanded.includes(i))}
          </button>
          <div
            css={{
              transition: "max-height 500ms",
              overflow: "hidden",
              maxHeight: expanded.includes(i) ? "" : "0 !important",
            }}
            ref={(e) => setDivEle(i, e)}
          >
            <div css={{ padding: "1rem", paddingTop: 0 }}>{item.body}</div>
          </div>
        </Fragment>
      ))}
    </>
  );
}
