import { ReactElement } from "react";
import { AppColors } from "utils/colors";

export function TravelPassesImage(): ReactElement {
  return (
    <svg
      width="64"
      height="60"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g fill="none" fillRule="evenodd" opacity=".319">
        <path
          d="M44 0H4C1.8 0 0 1.8 0 4v9.001c0 .227.22.477.662.75a5 5 0 01-.005 8.51c-.438.27-.657.517-.657.741V32c0 2.2 1.8 4 4 4h40c2.2 0 4-1.8 4-4v-9c0-.182-.22-.396-.658-.642a5 5 0 01.01-8.733c.432-.24.648-.449.648-.625V4c0-2.2-1.8-4-4-4z"
          fill={AppColors.PRIMARY}
          fillRule="nonzero"
        />
        <path
          d="M18 14c-1.103 0-2-.897-2-2 0-.487.182-.928.472-1.275l.588 1.617a1 1 0 001.88-.684l-.59-1.623A2 2 0 0120 12c0 1.103-.897 2-2 2zM7.858 11a3.993 3.993 0 00-1.421-2.149l.691-1.611L9.277 11H7.858zM4 14c-1.103 0-2-.897-2-2 0-1.021.771-1.855 1.759-1.976l-.678 1.582A.997.997 0 004 13h1.722c-.347.595-.985 1-1.722 1zm14-6c-.129 0-.251.026-.376.038L14.7 0H12a1 1 0 000 2h1.3l1.436 3.95-3.583 4.303L8.723 6H9a1 1 0 000-2H5a1 1 0 000 2h.483l-.882 2.061C4.403 8.031 4.206 8 4 8c-2.206 0-4 1.794-4 4s1.794 4 4 4c1.858 0 3.411-1.28 3.858-3H11a.974.974 0 00.374-.082c.023-.009.045-.017.068-.028a1 1 0 00.302-.231c.007-.009.018-.011.025-.019l3.758-4.515.211.581A3.996 3.996 0 0014 12c0 2.206 1.794 4 4 4s4-1.794 4-4-1.794-4-4-4z"
          fill="#fff"
          transform="translate(12 10)"
        />
        <path
          d="M60 24H20c-2.2 0-4 1.8-4 4v9.001c0 .227.22.477.662.75a5 5 0 01-.005 8.51c-.438.27-.657.517-.657.741V56c0 2.2 1.8 4 4 4h40c2.2 0 4-1.8 4-4v-9c0-.182-.22-.396-.658-.642a5 5 0 01.01-8.733c.432-.24.648-.449.648-.625v-9c0-2.2-1.8-4-4-4z"
          fill={AppColors.SECONDARY}
        />
        <path
          d="M46.52 31H33.47c-1.2 0-2.174.984-2.174 2.2v17.6c0 1.214.974 2.2 2.175 2.2 1.2 0 2.175-.986 2.175-2.2h8.7c0 1.214.974 2.2 2.175 2.2 1.2 0 2.175-.986 2.175-2.2V33.2c0-1.216-.975-2.2-2.175-2.2zm-13.085 4.343h13.028v7.727H33.435v-7.727zm1.74 13.224c-.911 0-1.65-.733-1.65-1.636 0-.903.739-1.636 1.65-1.636.911 0 1.65.733 1.65 1.636 0 .903-.739 1.636-1.65 1.636zm9.776-3.344c.911 0 1.65.733 1.65 1.636 0 .904-.739 1.637-1.65 1.637-.911 0-1.65-.733-1.65-1.637 0-.903.739-1.636 1.65-1.636zM52 36.502v4.455h-2.142v-4.455H52zm-21.858-.077v4.455H28v-4.455h2.142z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
