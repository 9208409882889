import { CustomMobeaModal } from "common/modal";
import { Tutorial } from "common/tutorial";
import { MapIcon } from "icons/navigation";
import { PointFingerIcon } from "icons/PointFingerIcon";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import "./MapTutorial.scss";

type Props = {
  close(): void;
};

export function MapTutorial({ close }: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <CustomMobeaModal
      className="mobea__tutorial-dialog map-tutorial"
      withHeader={false}
    >
      <Tutorial
        steps={[
          {
            headline: t("map.welcome"),
            image: (
              <MapIcon className="map-tutorial__icon" width="80" height="80" />
            ),
            info: t("map.welcome_message"),
          },
          {
            headline: t("map.navigate"),
            image: (
              <PointFingerIcon
                className="map-tutorial__icon"
                width="80"
                height="80"
                outlined={true}
              />
            ),
            info: t("map.info"),
          },
        ]}
        onClose={close}
      />
    </CustomMobeaModal>
  );
}
