import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "utils";

declare global {
  interface Window {
    mobeaAddTracking: (trackingId?: string) => void;
    mobeaClearTracking(): void;
    mobeaPageview: (config: { page_title?: string; page_path: string }) => void;
    mobeaEvent: (event: {
      category: string;
      action?: string;
      label?: string;
      value?: string;
    }) => void;
  }
}

export function usePageViewsTracking() {
  const { listen } = useHistory();

  useEffect(() => {
    const unlisten = listen((location) => {
      const from =
        typeof location.state === "object" &&
        typeof location.state?.["from"] === "string"
          ? location.state["from"]
          : "";

      if (location.pathname !== from && location.pathname !== Routes.Reload) {
        window.mobeaPageview({ page_path: location.pathname });
      }
    });

    return unlisten;
  }, [listen]);
}
