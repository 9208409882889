import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  getLocationSearchPlace,
  LocationSearchPlace,
  toLocationSearchPlace,
} from "services/mapService";

export function useLocationSearchPlace() {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(false);

  const [place, setPlace] = useState<LocationSearchPlace | null>(null);

  const currentSearchLocationIDRef = useRef("");

  const loadPlace = async (locationID: string) => {
    currentSearchLocationIDRef.current = locationID;

    try {
      setLoading(true);

      setError(false);

      const response = await getLocationSearchPlace(locationID, history);

      if (currentSearchLocationIDRef.current != locationID) {
        return;
      }

      if (response.error) {
        setLoading(false);
        setError(true);
        console.error(
          "Failed to fetch search place",
          response.error_description
        );
      } else {
        setPlace(toLocationSearchPlace(response));
        setLoading(false);
      }
    } catch (error) {
      if (currentSearchLocationIDRef.current == locationID) {
        setLoading(false);
        setError(true);
        console.error("Failed to fetch search place", error);
      }
    }
  };

  const clearPlace = () => {
    setPlace(null);
  };

  return [place, loading, error, loadPlace, clearPlace] as const;
}
