import { ClockIcon } from "icons/ClockIcon";
import { LabelIcon } from "icons/LabelIcon";
import { ReactElement } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  formatCurrency,
  formatDateWithLocaleAsDefault,
  formatTimeWithLocale,
} from "utils";
import { VertsStatus } from "utils/VertsStatus";
import "./DateTimePrice.scss";

type Props = {
  status: VertsStatus;
  dateTime: string;
  price?: number;
  hasFine?: boolean;
};

export function DateTimePrice({
  status,
  dateTime,
  price,
  hasFine = false,
}: Props): ReactElement {
  const { t } = useTranslation();

  const { language } = useSelector((state) => state.user);

  const getPriceText = () => {
    switch (status) {
      case VertsStatus.Finished:
        return t("verts_detail.price");
      case VertsStatus.Cancelled:
        return t("verts_detail.fee");
      default:
        return t("verts_detail.min_price");
    }
  };

  const getTimeText = () => {
    switch (status) {
      case VertsStatus.Finished:
      case VertsStatus.Cancelled:
        return t("verts_detail.time_of_arrival");
      case VertsStatus.Created:
        return t("verts_detail.pick_up_time");
      default:
        return t("verts_detail.time_of_order");
    }
  };

  return (
    <section className="date-time-price">
      {status !== VertsStatus.InProgress && status !== VertsStatus.Cancelled && (
        <div className="date-time-price__section">
          <div className="date-time-price__label-icon">
            <ClockIcon />
            <label>{getTimeText()}</label>
          </div>
          <div className="date-time-price__value">
            {status === VertsStatus.Finished ? (
              formatTimeWithLocale(new Date(dateTime), language)
            ) : (
              <Trans
                i18nKey="verts_detail.requested_at"
                values={{
                  date: formatDateWithLocaleAsDefault(
                    new Date(dateTime),
                    language
                  ),
                  time: formatTimeWithLocale(new Date(dateTime), language),
                }}
              />
            )}
          </div>
        </div>
      )}

      <div className={`date-time-price__section ${hasFine ? "fined" : ""}`}>
        <div className="date-time-price__label-icon">
          <LabelIcon outlined />
          <label>{getPriceText()}</label>
        </div>
        <div className={`date-time-price__value ${status}`}>
          {typeof price === "number" && formatCurrency(price, language)}
        </div>
      </div>
    </section>
  );
}
