import { InputGroup } from "common/forms";
import { MobeaModal } from "common/modal";
import { TFunction } from "i18next";
import { MouseEvent, ReactElement, useState } from "react";
import DayPicker, { BeforeAfterModifier } from "react-day-picker";
import { createPortal } from "react-dom";
import {
  formatDateWithLocaleAsDigits,
  getDaysOfTheWeekShort,
  getFirstDayOfTheWeek,
  getMonths,
  MODAL_ROOT_ELEMENT,
} from "utils";
import "./DatePicker.scss";

export interface DatePickerProps {
  title: string;
  dateInputLabel: string;
  className?: string;
  selectedDate?: number;
  startDate?: number | null;
  endDate?: number | null;
  locale: string;
  disabled?: boolean;
  t: TFunction;
  onChange(date: number): void;
  usePortal?: boolean;
}

export function DatePicker({
  title,
  dateInputLabel,
  className = "",
  locale,
  startDate,
  endDate,
  selectedDate,
  disabled = false,
  t,
  onChange,
  usePortal = false,
}: DatePickerProps): ReactElement {
  const daysOfTheWeek = getDaysOfTheWeekShort(t, locale);

  const months = getMonths(t, locale);

  const [date, setDate] = useState<number | undefined>(selectedDate);

  const [dateModalShown, setDateModalShown] = useState(false);

  const openDate = (e: MouseEvent) => {
    e.preventDefault();

    if (!disabled) {
      setDateModalShown(true);
    }
  };

  const closeDate = () => setDateModalShown(false);

  const onDateChange = (value: Date, modifiers: DayPicker.DayModifiers) =>
    !modifiers.disabled && setDate(value.valueOf());

  const onCancel = () => {
    // reset date when canceled
    setDate(selectedDate);

    closeDate();
  };

  const onConfirm = () => {
    closeDate();

    date && onChange(date);
  };

  const dateFormatted = selectedDate
    ? formatDateWithLocaleAsDigits(selectedDate, locale)
    : "";

  function renderModal() {
    return (
      <MobeaModal
        className="mobea__datepicker-modal"
        onConfirm={onConfirm}
        title={title}
        confirmText={t("shared.select_this_date")}
        confirmDisabled={!date}
        onClose={onCancel}
      >
        <DayPicker
          numberOfMonths={1}
          firstDayOfWeek={getFirstDayOfTheWeek(locale)}
          selectedDays={date ? new Date(date) : undefined}
          disabledDays={
            {
              before: startDate ? new Date(startDate) : undefined,
              after: endDate ? new Date(endDate) : undefined,
            } as BeforeAfterModifier
          }
          fromMonth={startDate ? new Date(startDate) : undefined}
          toMonth={endDate ? new Date(endDate) : undefined}
          locale={locale}
          months={months}
          weekdaysShort={daysOfTheWeek}
          onDayClick={onDateChange}
        />
      </MobeaModal>
    );
  }

  return (
    <div
      onMouseDown={(e) => {
        if (disabled) {
          e.preventDefault();
        }
      }}
    >
      <InputGroup
        className={`mobea__datepicker ${className}`}
        label={dateInputLabel}
        type="text"
        name="date"
        value={dateFormatted}
        placeholder={dateInputLabel}
        toggleLabelAndPlaceholder
        inputAttributes={{
          onClick: openDate,
          autoComplete: "off",
          readOnly: true,
        }}
      />

      {dateModalShown && (
        <>
          {!usePortal && renderModal()}
          {usePortal && createPortal(renderModal(), MODAL_ROOT_ELEMENT)}
        </>
      )}
    </div>
  );
}
