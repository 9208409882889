import { MobeaButton } from "common/forms";
import { FullScreenPage } from "common/page/FullScreenPage";
import { SadHeadIcon } from "icons/SadHeadIcon";
import { LoginTitle } from "pages/login/LoginTitle";
import { LogoWithLangHeader } from "pages/setPassword/LogoWithLangHeader";
import React, { ReactElement } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { pushLocation, Routes } from "utils";
import { getConfig } from "utils/configure";
import "./InvalidLoginTokenPage.scss";

export function InvalidLoginTokenPage(): ReactElement {
  const { t } = useTranslation();
  const history = useHistory();

  const { supportPhone } = getConfig();

  const goToLogin = (e: React.MouseEvent) => {
    e.preventDefault();

    pushLocation(history, Routes.Login);
  };

  return (
    <FullScreenPage
      pageClassName="mobea-invalid-login-token"
      padding={{
        left: 32,
        right: 32,
      }}
      css={{
        "> section": {
          justifyContent: "space-between",
        },
      }}
    >
      <LogoWithLangHeader />

      <div>
        <LoginTitle
          title={t("invalid_login_token.title")}
          text={t("invalid_login_token.text")}
        />

        <SadHeadIcon
          width={80}
          height={80}
          className="icon"
          css={{
            marginTop: 16,
          }}
        />
      </div>

      <div>
        <MobeaButton
          onClick={() => {
            window.open(`tel:${supportPhone}`);
          }}
        >
          {t("contact_us.call_us")}
        </MobeaButton>
        <p
          css={{
            margin: "24px 0 0",
            fontSize: "1rem",
            lineHeight: 1.5,
            textAlign: "center",
          }}
        >
          <Trans i18nKey="invalid_login_token.already_have">
            <a
              href="/login"
              onClick={goToLogin}
              css={{ fontWeight: "bold" }}
            ></a>
          </Trans>
        </p>
      </div>
    </FullScreenPage>
  );
}
