import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

type Props = DefaultIconProps & {
  outlined?: boolean;
};

export function RouteDirectionsIcon({
  fill = "currentColor",
  className = "",
  height = "16",
  width = "16",
  onClick,
  outlined = true,
}: Props): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={outlined ? "0 0 16 16" : "0 0 24 24"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      {!outlined && (
        <path
          d="M21.71 11.29l-9-9a.996.996 0 00-1.41 0l-9 9a.996.996 0 000 1.41l9 9c.39.39 1.02.39 1.41 0l9-9a.996.996 0 000-1.41zM14 14.5V12h-4v3H8v-4c0-.55.45-1 1-1h5V7.5l3.5 3.5-3.5 3.5z"
          fill={fill}
        />
      )}
      {outlined && (
        <path
          d="M14.953 7.06L8.947 1.053c-.5-.5-1.38-.506-1.887 0l-6 6c-.52.52-.52 1.36 0 1.88l6 6c.26.26.6.387.94.387.34 0 .68-.127.94-.387l5.993-5.993a1.328 1.328 0 00.02-1.88zm-6.946 6.933l-6-6 6-6 6 6-6 6zm-2.674-6.66V10h1.334V8h2.666v1.667l2.334-2.334L9.333 5v1.667H6c-.367 0-.667.3-.667.666z"
          fill={fill}
        />
      )}
    </svg>
  );
}
