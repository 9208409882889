import { MobeaButton } from "common/forms";
import { MobeaModal } from "common/modal";
import { Spinner } from "common/Spinner";
import { MobitBikeLockStatus } from "pages/mobit/hooks";
import { ReactElement } from "react";
import { Trans, useTranslation } from "react-i18next";

export interface PauseRideDialogProps {
  lockStatus: MobitBikeLockStatus;
  // in or out of parking zone
  version: "inside" | "outside";
  loading?: boolean;
  pausingInProgress?: boolean;
  onConfirm(): void;
  onClose(): void;
}

export function PauseRideDialog({
  lockStatus,
  version,
  loading = false,
  pausingInProgress = false,
  onConfirm,
  onClose,
}: PauseRideDialogProps): ReactElement {
  const { t } = useTranslation();

  const pending = lockStatus !== MobitBikeLockStatus.CLOSED;

  return (
    <MobeaModal
      className="mobea-find-bike__pause-ride"
      title={t("mobit_ride.pause_the_ride")}
      confirmButton={
        <MobeaButton
          disabled={pending}
          loading={pausingInProgress}
          onClick={onConfirm}
        >
          {t("shared.pause")}
        </MobeaButton>
      }
      onClose={onClose}
    >
      {loading && <Spinner />}

      {!loading && (
        <>
          <p>
            <Trans i18nKey="mobit_ride.pause_the_ride_dialog">
              First close the lock, then click on Pause.
            </Trans>
          </p>

          <p>
            {version === "inside" && (
              <Trans i18nKey="mobit_ride.pause_in_zone_text">
                Remember to park the bike at the end of your ride in the
                indicated zones. Otherwise, you will be charged extra!
              </Trans>
            )}

            {version === "outside" && (
              <Trans i18nKey="mobit_ride.pause_outside_zone_text">
                Currently, your bike is <strong>not parked</strong> in the
                indicated zone. Make sure that its there at the end of all your
                rides, otherwise you will be given a fine.
              </Trans>
            )}
          </p>
        </>
      )}
    </MobeaModal>
  );
}
