import { BriefcaseIcon } from "icons/BriefcaseIcon";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ApplicationVariant, BUDGET_COOLDOWN_PERIOD_DAYS } from "utils";
import { AppColors } from "utils/colors";
import { applicationVariant } from "utils/configure";
import { Accordion } from "../Accordion";
import { BusinessWallet } from "./BusinessWallet";
import { WalletHeader } from "./WalletHeader";

export interface PlanOverviewProps {
  id?: string;
  onExpandedHeightChange(height: number): void;
}

export const PlanOverview = forwardRef<HTMLDivElement, PlanOverviewProps>(
  ({ id, onExpandedHeightChange }, ref) => {
    const { t } = useTranslation();

    const { outOfBudgetPeriod, remainingDays, amount, privateAmount } =
      useSelector((state) => state.user);

    const remainingAmount =
      outOfBudgetPeriod && remainingDays <= -BUDGET_COOLDOWN_PERIOD_DAYS
        ? 0
        : Math.max(0, amount);

    const privateAmountFixed = Math.max(privateAmount, 0);

    const singleWallet = applicationVariant === ApplicationVariant.EA;

    const businessWallet = (
      <BusinessWallet
        singleWallet={singleWallet}
        ref={singleWallet ? ref : undefined}
      />
    );

    if (singleWallet) {
      return businessWallet;
    }

    return (
      <div id={id}>
        <h2
          css={{
            fontSize: "0.875rem",
            textTransform: "uppercase",
            fontWeight: "bold",
            color: AppColors.GRAY_300,
            margin: "0 2rem 1rem",
          }}
        >
          {t("home.wallets")}
        </h2>

        <div css={{ backgroundColor: "white" }} ref={ref}>
          <Accordion
            onExpandedHeightChange={onExpandedHeightChange}
            items={[
              {
                header(expanded) {
                  return (
                    <WalletHeader
                      icon={<BriefcaseIcon />}
                      title={t("home.business_wallet")}
                      expanded={expanded}
                      amount={remainingAmount}
                      outOfBudgetPeriod={outOfBudgetPeriod}
                    />
                  );
                },
                body: businessWallet,
              },
              // {
              //   header(expanded) {
              //     return (
              //       <WalletHeader
              //         amount={privateAmountFixed}
              //         title={t("home.private_wallet")}
              //         icon={<UserIcon />}
              //         expanded={expanded}
              //       />
              //     );
              //   },
              //   body: <PrivateWallet />,
              // },
            ]}
          />
        </div>
      </div>
    );
  }
);

PlanOverview.displayName = "PlanOverview";
