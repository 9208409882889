import { ToggleDirectionIcon } from "icons/ToggleDirectionIcon";
import { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { formatDateWithLocaleAsDefault, TravelPassProvider } from "utils";
import { ProviderName } from "../ProviderName";
import "./TravelPassNameDate.scss";

type TravelPassValidityRange = {
  start: number;
  end: number;
};

export interface TravelPassNameDateProps {
  provider: TravelPassProvider;
  locale: string;
  activation?: number;
  expiration?: number;
  isReturnJourney?: boolean;
}

export function TravelPassNameDate({
  provider,
  locale,
  activation,
  expiration,
  isReturnJourney = false,
}: TravelPassNameDateProps): ReactElement {
  const { t } = useTranslation();

  const validityRange: TravelPassValidityRange | undefined = useMemo(() => {
    return activation && expiration
      ? {
          start: activation,
          end: expiration,
        }
      : undefined;
  }, [activation, expiration]);

  const toValidityDates = () => {
    if (!validityRange) {
      return t("shared.not_active");
    }

    const startFormatted = formatDateWithLocaleAsDefault(
      validityRange.start,
      locale
    );

    const endFormatted = formatDateWithLocaleAsDefault(
      validityRange.end,
      locale
    );

    return isReturnJourney ? (
      <span>
        {startFormatted} <ToggleDirectionIcon className="return-icon" />
        {endFormatted}
      </span>
    ) : startFormatted === endFormatted ? (
      startFormatted
    ) : (
      <span>
        {startFormatted} - {endFormatted}
      </span>
    );
  };

  return (
    <div className="mobea__travel-pass__header__provider">
      <h3 className="mobea__travel-pass__header__provider__name">
        <ProviderName provider={provider} />
      </h3>

      <p className="mobea__travel-pass__header__provider__dates">
        {toValidityDates()}
      </p>
    </div>
  );
}
