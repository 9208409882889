import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

export function ParkingIcon({
  fill = "currentColor",
  className = "",
  height = "18",
  width = "18",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 18 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 1h12a2 2 0 012 2v12a2 2 0 01-2 2H3a2 2 0 01-2-2V3a2 2 0 012-2z"
        stroke={fill}
        fill="transparent"
      />
      <path
        d="M9.59 4H6v10h2.051v-3.333H9.59c1.697 0 3.077-1.495 3.077-3.334S11.287 4 9.59 4zm.102 4.444h-1.64V6.222h1.64c.564 0 1.026.5 1.026 1.111s-.462 1.111-1.026 1.111z"
        fill={fill}
      />
    </svg>
  );
}
