export enum ProviderFilterType {
  All = 0,
  PublicTransport = 1,
  Bikes = 2,
  Kickscooters = 3,
  CarsOrTaxi = 4,
  Scooters = 5,
  Walking = 6,
  Services = 7,
}
