import { SupportedLanguage } from "./intl";
import { VertsStatus } from "./VertsStatus";

export const ADMIN_API_BASE = process.env.ADMIN_DOMAIN + process.env.API;
export const CORE_API_BASE = process.env.CORE_DOMAIN + process.env.API;
export const CONFIG_PATH = ADMIN_API_BASE + process.env.APP_CONFIG_PATH;

export const URL_TOKEN_NAME = "token";
export const CUSTOMER_TOKEN_NAME = "customer";

// @TODO customize keys if sub-clients does not have own domains!
export const LOCAL_STORAGE_KEY = "mobea-pwa";
export const LOCAL_STORAGE_TRACKING_KEY = "mobea-tracking-allowed";
export const LOCAL_STORAGE_AUTH_KEY = "mobea--user-auth";
// client configuration key
export const LOCAL_STORAGE_CONFIG_KEY = process.env.CONFIG_KEY;

export const VERTS_SUPPORT_PHONE_NUMBER = "+3223494949";
export const MOBIT_FAQ_URL = "https://www.mobit.eu/faq.html";
export const MOBIT_SUPPORT_PHONE_NUMBER = "+32-9-278-72-56";

export const VELO_SUPPORT_PHONE_NUMBER = "+3232065030";

export const BUDGET_COOLDOWN_PERIOD_DAYS = 7;

export const MAX_PRIVATE_AMOUNT_BEFORE_TOP_UP = 290;
export const MAX_PRIVATE_AMOUNT = 300; // before + min
export const PRIVATE_TOP_UP_MIN = 10;
export const PRIVATE_TOP_UP_MAX = 150;

// @see https://en.wikipedia.org/wiki/List_of_mobile_telephone_prefixes_by_country
export const SUPPORTED_PHONE_PREFIXES: PhonePrefix[] = [
  { code: "+32", minLength: 9 },
  { code: "+33", minLength: 9 },
  { code: "+49", minLength: 10 },
  { code: "+352", minLength: 9 },
  { code: "+351", minLength: 9 },
  { code: "+31", minLength: 9 },
  { code: "+45", minLength: 8 },
  { code: "+54", minLength: 10 },
  { code: "+43", minLength: 10 },
  { code: "+591", minLength: 8 },
  { code: "+55", minLength: 10 },
  { code: "+56", minLength: 9 },
  { code: "+86", minLength: 11 },
  { code: "+57", minLength: 10 },
  { code: "+506", minLength: 8 },
  { code: "+53", minLength: 8 },
  { code: "+357", minLength: 8 },
  { code: "+1809", minLength: 10 },
  { code: "+593", minLength: 9 },
  { code: "+20", minLength: 10 },
  { code: "+503", minLength: 8 },
  { code: "+372", minLength: 8 },
  { code: "+502", minLength: 8 },
  { code: "+504", minLength: 8 },
  { code: "+91", minLength: 10 },
  { code: "+62", minLength: 9 },
  { code: "+39", minLength: 9 },
  { code: "+81", minLength: 10 },
  { code: "+965", minLength: 8 },
  { code: "+370", minLength: 8 },
  { code: "+52", minLength: 10 },
  { code: "+377", minLength: 8 },
  { code: "+505", minLength: 8 },
  { code: "+968", minLength: 8 },
  { code: "+507", minLength: 8 },
  { code: "+595", minLength: 9 },
  { code: "+51", minLength: 9 },
  { code: "+48", minLength: 9 },
  { code: "+226", minLength: 8 },
  { code: "+378", minLength: 9 },
  { code: "+966", minLength: 9 },
  { code: "+421", minLength: 9 },
  { code: "+27", minLength: 9 },
  { code: "+34", minLength: 9 },
  { code: "+41", minLength: 9 },
  { code: "+380", minLength: 10 },
  { code: "+971", minLength: 9 },
  { code: "+44", minLength: 10 },
  { code: "+598", minLength: 9 },
  { code: "+379", minLength: 8 },
  { code: "+58", minLength: 10 },
  { code: "+84", minLength: 9 },
];

export interface PhonePrefix {
  code: string;
  minLength: number;
}

// on purpose we are unprecise as mobiles uses this simplification often
export const ATTACHMENT_SIZE_LIMIT = 10 * 1000 * 1000;

export const ACTIVE_PASSES_REFRESH_INTERVAL = 30 * 1000; // 30 seconds

export const VERTS_PASS_REFRESH_INTERVAL = 5 * 1000; // 5 seconds

export const VERTS_REFRESHABLE_STATUSES = [
  VertsStatus.Searching,
  VertsStatus.Ordered,
  VertsStatus.Arriving,
  VertsStatus.Waiting,
  VertsStatus.InProgress,
];

export const MIVB_OUTLET_URL =
  "https://www.stib-mivb.be/article.html?_guid=30d93c3b-2b83-3410-94b5-eec92fbd03ff&l=nl";

export const MOBIT_DEFAULT_DURATION = 24;
export const MOBIT_DEFAULT_DAY_LIMIT = 5;

export const LOCATION_SUGGESTIONS_LIMIT = 5;

export const ROUTE_PLANNER_TRIP_LIMIT = 10;

export enum LoginResultEnum {
  SUCCESS,
  INVALID,
  ERROR,
}

export enum ApplicationVariant {
  EA = "ea",
  ARGENTA = "argenta",
  MOVEASY = "moveasy",
}

export const EURO = "€";

export const MOVEASY_TERMS_LINKS: Record<SupportedLanguage, string> = {
  en: "/en/terms-and-conditions",
  fr: "/fr/conditions-generales",
  nl: "/nl/algemene-voorwaarden",
};

export enum TermsOrPrivacy {
  TERMS = "moveasy_terms",
  PRIVACY = "moveasy_privacy",
}

export enum WalletType {
  // t("wallet.business")
  BUSINESS = "business",
  // t("wallet.private")
  PRIVATE = "private",
}

export const MODAL_ROOT_ELEMENT = document.getElementById(
  "root"
) as HTMLElement;

export enum TravelPassProvider {
  // t("providers_names.delijn")
  delijn = "delijn",

  // t("providers_names.nmbs")
  nmbs = "nmbs",

  // t("providers_names.mobit")
  mobit = "mobit",

  // t("providers_names.lime")
  lime = "lime",

  // t("providers_names.mivb")
  mivb = "mivb",

  // t("providers_names.verts")
  verts = "verts",

  // t("providers_names.tec")
  tec = "tec",

  // t("providers_names.uber")
  uber = "uber",

  // t("providers_names.billy-bike")
  "billy-bike" = "billy-bike",

  // t("providers_names.bird")
  bird = "bird",

  // t("providers_names.dott")
  dott = "dott",

  // t("providers_names.li-bia-velo")
  "li-bia-velo" = "li-bia-velo",

  // t("providers_names.poppy")
  "poppy" = "poppy",

  // t("providers_names.velo-antwerpen")
  "velo-antwerpen" = "velo-antwerpen",

  // t("providers_names.villo")
  villo = "villo",

  // t("providers_names.wheels")
  wheels = "wheels",

  // t("providers_names.donkey-republic")
  "donkey-republic" = "donkey-republic",

  // t("providers_names.de-fietsambassade")
  "de-fietsambassade" = "de-fietsambassade",

  // t("providers_names.felyx")
  felyx = "felyx",

  // t("providers_names.cozycar")
  cozycar = "cozycar",

  // t("providers_names.getaround")
  getaround = "getaround",

  // t("providers_names.degage")
  degage = "degage",
  // t("providers_names.blue-bike")
  "blue-bike" = "blue-bike",
  // t("providers_names.deskalot")
  deskalot = "deskalot",
  // t("providers.other")
  other = "other",
}

export enum TravelPassType {
  Bus = "bus", // BUS
  Train = "train", // TRAIN
  Tram = "tram", // TRAM
  Metro = "metro", // METRO
  Bike = "bike", // BIKE
  ElectricBike = "e-bike", // E-BIKE
  Taxi = "taxi", // TAXI
  Kickscooter = "kickscooter", // KICKSCOOTER
  ElectricScooter = "e-scooter", // E-SCOOTER
  Car = "car", // CAR
  Uber = "uber-car", // UBER-CAR
  Workspace = "workspace", // WORKSPACE
  Unknown = "unknown",
}

export type ProviderToTypeMappingType = Record<
  TravelPassProvider,
  TravelPassType[]
>;

export type AppOrWebsiteLinks =
  | { ios: string; android: string; website?: undefined }
  | { website: string; android?: undefined; ios?: undefined };

export interface ProviderActions {
  // keyword with info text about ordering the app
  // "_app" suffix is added if external provider is used to order ticket
  // so don't forget to provide both in translations!
  info: string;
  primaryAction?: string;
  app?: AppOrWebsiteLinks;
}

export const providerToActionsMapping: Record<
  TravelPassProvider,
  ProviderActions
> = {
  delijn: { info: "ticket" },
  nmbs: {
    info: "ticket",
    app: {
      android:
        "https://play.google.com/store/apps/details?id=be.sncbnmbs.b2cmobapp",
      ios: "https://apps.apple.com/sk/app/sncb-international/id577730301",
    },
  },
  mobit: {
    info: "pass",
    primaryAction: "order_pass",
    app: {
      android:
        "https://play.google.com/store/apps/details?id=com.ionicframework.bikev2171888",
      ios: "https://apps.apple.com/app/id1264033761",
    },
  },
  lime: {
    info: "kickscooter",
    app: {
      android: "https://play.google.com/store/apps/details?id=com.limebike",
      ios: "https://apps.apple.com/app/id1199780189",
    },
  },
  mivb: {
    info: "mivb",
    app: {
      android:
        "https://play.google.com/store/apps/details?id=be.stib.mivb.mobile",
      ios: "https://apps.apple.com/app/id1325048789",
    },
  },
  verts: {
    info: "taxi",
    primaryAction: "order_taxi",
    app: {
      android:
        "https://play.google.com/store/apps/details?id=com.taxisverts.booker",
      ios: "https://apps.apple.com/app/id1455851406",
    },
  },
  tec: {
    info: "ticket",
    app: {
      android: "https://play.google.com/store/apps/details?id=be.otw.tecmobile",
      ios: "https://apps.apple.com/app/id1438618535",
    },
  },
  uber: {
    info: "uber",
    app: {
      android: "https://play.google.com/store/apps/details?id=com.ubercab",
      ios: "https://apps.apple.com/app/id368677368",
    },
  },
  "billy-bike": {
    info: "bike",
    app: {
      android: "https://go.onelink.me/app/BillyAndroid",
      ios: "https://go.onelink.me/app/BillyiOS",
    },
  },
  bird: {
    info: "kickscooter",
    app: {
      android: "https://play.google.com/store/apps/details?id=co.bird.android",
      ios: "https://apps.apple.com/app/id1260842311",
    },
  },
  dott: {
    info: "kickscooter",
    app: {
      android:
        "https://play.google.com/store/apps/details?id=com.ridedott.rider",
      ios: "https://apps.apple.com/app/id1440301673",
    },
  },
  "li-bia-velo": {
    info: "bike",
    app: {
      android: "http://en.libiavelo.be",
      ios: "http://en.libiavelo.be",
    },
  },
  poppy: {
    info: "car_scooter",
    app: {
      android: "https://play.google.com/store/apps/details?id=com.gopoppy.app",
      ios: "https://apps.apple.com/be/app/poppy-mobility/id1503328765",
    },
  },
  "velo-antwerpen": {
    info: "velo",
    primaryAction: "buy_day_pass",
    app: {
      android:
        "https://play.google.com/store/apps/details?id=be.antwerpen.velo",
      ios: "https://apps.apple.com/app/id534476508",
    },
  },
  villo: {
    info: "bike",
    app: {
      android:
        "https://play.google.com/store/apps/details?id=com.jcdecaux.vls.bruxelles",
      ios: "https://apps.apple.com/app/id1461467617",
    },
  },
  wheels: {
    info: "bike",
    app: {
      android:
        "https://play.google.com/store/apps/details?id=com.getwheelsapp.wheels",
      ios: "https://apps.apple.com/app/id1436122530",
    },
  },
  "donkey-republic": {
    info: "bike",
    app: {
      android:
        "https://play.google.com/store/apps/details?id=com.donkeyrepublic.bike.android",
      ios: "https://apps.apple.com/app/id933526449",
    },
  },
  "de-fietsambassade": {
    info: "bike",
    app: {
      android: "https://fietsambassade.gent.be",
      ios: "https://fietsambassade.gent.be",
    },
  },
  felyx: {
    info: "scooter",
    app: {
      android:
        "https://play.google.com/store/apps/details?id=com.felyx.android",
      ios: "https://apps.apple.com/app/id1250107307",
    },
  },
  cozycar: {
    info: "car",
    app: {
      android: "https://www.cozywheels.be/",
      ios: "https://www.cozywheels.be/",
    },
  },
  getaround: {
    info: "car",
    app: {
      android:
        "https://play.google.com/store/apps/details?id=com.c4mprod.voiturelib",
      ios: "https://apps.apple.com/app/id492238016",
    },
  },
  degage: {
    info: "car",
    app: {
      android: "https://www.degage.be",
      ios: "https://www.degage.be",
    },
  },
  "blue-bike": {
    info: "blue-bike",
    app: {
      android:
        "https://play.google.com/store/apps/details?id=be.bluebike.rider",
      ios: "https://apps.apple.com/us/app/blue-bike/id6448990220?l=nl",
    },
  },
  deskalot: {
    info: "workspace",
    app: {
      website: "https://www.deskalot.com/",
    },
  },
  // ignore other as it is not displayed in standard way
  other: {
    info: "",
  },
};
