import { VisibilityIcon } from "icons/VisibilityIcon";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { AppColors } from "utils/colors";
import "./NoProvidersAvailable.scss";

export function NoProvidersAvailable(): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="no-providers-available">
      <VisibilityIcon fill={AppColors.GRAY_200} width={24} height={24} />
      <div className="no-providers-available__text">
        {t("map.no_providers_available")}
      </div>
    </div>
  );
}
