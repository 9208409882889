import { ReactElement } from "react";
import { DefaultIconProps } from "../props";

export function WaitIcon({
  fill = "currentColor",
  className = "",
  height = "20",
  width = "20",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="3 3 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.912 6.018c0 .73-.587 1.328-1.306 1.328A1.321 1.321 0 0 1 6.3 6.018c0-.73.588-1.329 1.306-1.329.719 0 1.306.598 1.306 1.329ZM5.77 9.688l-.34 9.639h1.36l.34-6.07.83 2.084v3.986h1.306v-4.982l-.435-1.444-.34-1.786V9.331l1.02.714-.68 1.428.952.379s.884-1.462.884-1.939v-.225c0-.357-1.963-2.019-2.38-2.077a10.09 10.09 0 0 0-1.157-.065c-.197 0-1.36 0-1.744.423 0 0-.806 1.005-.976 1.362-.17.357 0 3.927 0 3.927h1.02v-3.213l.34-.357Zm7.373-4.938a6.3 6.3 0 0 1 3.281.513 6.43 6.43 0 0 1 2.585 2.113 6.64 6.64 0 0 1 .709 6.469 6.51 6.51 0 0 1-2.061 2.64 6.3 6.3 0 0 1-6.37.74.75.75 0 0 1 .608-1.372c.787.349 1.65.48 2.502.381a4.84 4.84 0 0 0 2.356-.947 5.008 5.008 0 0 0 1.586-2.032 5.14 5.14 0 0 0-.547-5.002 4.93 4.93 0 0 0-1.982-1.62 4.8 4.8 0 0 0-4.86.545.75.75 0 0 1-.899-1.2 6.339 6.339 0 0 1 3.092-1.228Zm3.16 8.966.537-.91-2.647-1.577V8.076h-1.145v3.713l3.256 1.927Z"
        fill={fill}
      />
    </svg>
  );
}
