import { MobeaButton } from "common/forms";
import { useModal } from "common/hooks/useModal";
import { useTranslation } from "react-i18next";
import { AppColors } from "utils/colors";
import { getConfig } from "utils/configure";
import { ContactUsImage } from "./ContactUsImage";

export interface ContactUsModalProps {
  onClose?: () => void;
}

export function useContactUsModal(props?: ContactUsModalProps) {
  const { onClose = () => undefined } = props ?? {};
  const { t } = useTranslation();

  const { supportPhone } = getConfig();

  return useModal({
    title: t("contact_us.need_help"),
    image: (
      <ContactUsImage
        css={{
          color: AppColors.PRIMARY,
        }}
      />
    ),
    className: "mobea__contact-us",
    showCloseButton: true,
    confirmButton: (
      <MobeaButton
        onClick={() => {
          window.open(`tel:${supportPhone}`);
        }}
      >
        {t("contact_us.call_us")}
      </MobeaButton>
    ),
    onClose,
    children: <p>{t("contact_us.need_help_text")}</p>,
  });
}
