import { MobeaButton } from "common/forms";
import { useBooleanState } from "common/hooks";
import { NestedPageFull } from "common/page/NestedPageFull";
import {
  getPasswordStrength,
  PasswordInput,
} from "common/passwordInput/PasswordInput";
import { ProfileUpdateSuccessDialog } from "pages/profile/dialogs/ProfileUpdateSuccessDialog";
import { useSomethingWentWrongModal } from "pages/setPassword/useSomethingWentWrongModal";
import { FormEvent, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { setCustomerPassword } from "services/customerService";
import { ApiErrors, Routes } from "utils";

export function PasswordPage(): ReactElement {
  const { t } = useTranslation();

  const user = useSelector((state) => state.user);

  const { customerId } = user;

  const [password, setPassword] = useState("");

  const [oldPassword, setOldPassword] = useState("");

  const [saving, setSaving] = useState(false);

  const history = useHistory();

  const setPasswordErrorModal = useSomethingWentWrongModal();

  const [wrongPw, setWrongPw, resetWrongPw] = useBooleanState(false);

  const [pwChanged, setPwChanged] = useBooleanState(false);

  useEffect(() => {
    resetWrongPw();
  }, [oldPassword, resetWrongPw]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    setSaving(true);

    setCustomerPassword(customerId, history, password, oldPassword)
      .then((response) => {
        if (response.error_code === ApiErrors.PASSWORD_DOESNT_MATCH) {
          setWrongPw();
        } else if (response.error_code) {
          setPasswordErrorModal.show();
        } else {
          setPwChanged();
        }
      })
      .catch(() => {
        setPasswordErrorModal.show();
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const goToSettings = () => history.push(Routes.Settings);

  return (
    <NestedPageFull
      pageClassName="mobea-change-password"
      title={t("change_password.change_password")}
      css={{
        padding: 32,
        ".m_page__content": {
          marginTop: 20,
          ".mobea-password": {
            margin: "-16px 0 40px",
          },
        },
      }}
    >
      <form onSubmit={handleSubmit}>
        <PasswordInput
          label={t("change_password.current_password")}
          value={oldPassword}
          onChange={setOldPassword}
          noCheck
          error={wrongPw ? t("change_password.incorrect_password") : undefined}
        />

        <PasswordInput
          label={t("change_password.new_password")}
          value={password}
          onChange={setPassword}
        />

        <MobeaButton
          disabled={
            saving || getPasswordStrength(password) !== "ok" || !oldPassword
          }
          loading={saving}
        >
          {saving ? t("shared.saving") : t("shared.save")}
        </MobeaButton>

        {pwChanged && <ProfileUpdateSuccessDialog onConfirm={goToSettings} />}
      </form>
    </NestedPageFull>
  );
}
