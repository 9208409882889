import { useLayoutEffect, useRef } from "react";
import "./CancelOverlay.scss";

export type CancelOverlayProps = {
  className?: string;
  active?: boolean;
  cancelOnTouch?: boolean;
  onCancel(): void;
};

export function CancelOverlay({
  className = "",
  active = true,
  onCancel,
}: CancelOverlayProps) {
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const element = ref.current;

    const prevent = (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
    };

    element?.addEventListener("touchmove", prevent, {
      passive: false,
      capture: true,
    });

    return () => {
      element?.removeEventListener("touchmove", prevent);
    };
  }, [active]);

  if (!active) {
    return null;
  }

  return (
    <div
      ref={ref}
      className={`mobea__cancel-overlay ${className}`}
      onClick={onCancel}
    />
  );
}
