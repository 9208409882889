import { MobeaModal } from "common/modal";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { ApiErrors } from "utils";

export type SendForgottenPasswordErrorType = ApiErrors | "external";

interface Props {
  errorType: SendForgottenPasswordErrorType;
  tryAgain(): void;
  contactUs(): void;
}

export function SendForgottenPasswordErrorDialog({
  errorType,
  tryAgain,
  contactUs,
}: Props): ReactElement {
  const { t } = useTranslation();

  switch (errorType) {
    case ApiErrors.CUSTOMER_DONT_EXIST:
      return (
        <MobeaModal
          confirmText={t("shared.ok")}
          cancelText={t("verification.need_help")}
          cancelButtonType="secondary"
          title={t("verification.sorry_but")}
          type="error"
          onConfirm={tryAgain}
          onCancel={contactUs}
        >
          <p>{t("forgotten_password.error_no_plan_text")}</p>
        </MobeaModal>
      );
    case ApiErrors.NO_ACTIVE_PLAN:
    case ApiErrors.CLIENT_NOT_ACTIVE:
      return (
        <MobeaModal
          title={t("shared.sorry_but")}
          cancelText={t("verification.need_help")}
          confirmText={t("shared.ok")}
          cancelButtonType="secondary"
          type="error"
          onCancel={contactUs}
          onConfirm={tryAgain}
        >
          <p>{t("verification.error_client_inactive_text")}</p>
        </MobeaModal>
      );

    default:
      return (
        <MobeaModal
          confirmText={t("verification.try_again")}
          title={t("shared.sorry_but")}
          type="error"
          onConfirm={tryAgain}
        >
          <p>{t("verification.error_external")}</p>
        </MobeaModal>
      );
  }
}
