import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getMobitOrderData } from "services/mobitService";
import { setMobitPricingAction } from "state/actions";
import { MOBIT_DEFAULT_DAY_LIMIT, MOBIT_DEFAULT_DURATION } from "utils";

export function useMobitOrderData() {
  const history = useHistory();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let canceled = false;

    async function fetchMobitOrderData() {
      try {
        setLoading(true);
        const orderJson = await getMobitOrderData(history);

        if (!orderJson.error) {
          const dayPassData = {
            price: orderJson.price || 0,
            duration: orderJson.duration || MOBIT_DEFAULT_DURATION,
            dayLimit: orderJson.dayLimit || MOBIT_DEFAULT_DAY_LIMIT,
          };

          if (!canceled) {
            dispatch(setMobitPricingAction(dayPassData));

            setLoading(false);
          }
        } else {
          !canceled && setLoading(false);

          console.error(
            "Failed to fetch Mobit order data",
            orderJson.error_description
          );
        }
      } catch (error) {
        !canceled && setLoading(false);

        console.error("Failed to fetch Mobit order data", error);
      }
    }

    fetchMobitOrderData();

    return () => {
      canceled = true;

      console.debug("Canceled hook fetchMobitOrderData");
    };
  }, [dispatch, history]);

  return [loading] as [boolean];
}
