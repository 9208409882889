import { ForwardedRef, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  BUDGET_COOLDOWN_PERIOD_DAYS,
  formatCurrency,
  formatCurrencyCustom,
  formatDateWithLocaleAsDigits,
} from "utils";
import { AppColors, AppFonts } from "utils/colors";
import { AmountChart } from "./AmountChart";
import { Wallet } from "./Wallet";

function BusinessWalletInt(
  { singleWallet }: { singleWallet?: boolean },
  ref: ForwardedRef<HTMLElement>
) {
  const { t } = useTranslation();

  const {
    outOfBudgetPeriod,
    remainingDays,
    language: locale,
    startDate,
    endDate,
    totalAmount,
    amount,
  } = useSelector((state) => state.user);

  const budgetNotStartedYet = startDate ? Date.now() < startDate : false;

  const remainingAmount =
    outOfBudgetPeriod && remainingDays <= -BUDGET_COOLDOWN_PERIOD_DAYS
      ? 0
      : Math.max(0, amount);

  const startDateFormatted = startDate
    ? formatDateWithLocaleAsDigits(startDate, locale)
    : "";

  const endDateFormatted = endDate
    ? formatDateWithLocaleAsDigits(endDate, locale)
    : "";

  const formattedRemainingAmount = formatCurrency(remainingAmount, locale);

  const formattedTotalAmount = formatCurrencyCustom(totalAmount, locale, {
    currencyDisplay: "symbol",
    style: "currency",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  const remainingAmountText = budgetNotStartedYet
    ? t("home.budget_not_started")
    : outOfBudgetPeriod
    ? t("home.budget_expired")
    : remainingAmount == totalAmount
    ? null
    : t("home.money_left");

  return (
    <Wallet
      ref={ref}
      totalBudget={formattedTotalAmount}
      validPeriod={`${startDateFormatted} - ${endDateFormatted}`}
      theme={singleWallet ? "verbose" : "brief"}
    >
      <AmountChart
        amount={remainingAmount}
        totalAmount={totalAmount}
        css={{ maxWidth: singleWallet ? "10rem" : "8rem", width: "100%" }}
      />

      <div
        css={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: AppColors.PRIMARY,
          fontWeight: 700,
          fontFamily: AppFonts.NUMBERS,
        }}
      >
        <div css={{ fontSize: "1.25rem", lineHeight: 1.5 }}>
          {formattedRemainingAmount}
        </div>

        {remainingAmountText && (
          <div
            css={{
              opacity: 0.7,
              fontSize: "0.75rem",
              lineHeight: 1.5,
              maxWidth: "80%",
              textAlign: "center",
            }}
          >
            {remainingAmountText}
          </div>
        )}
      </div>
    </Wallet>
  );
}

export const BusinessWallet = forwardRef(BusinessWalletInt);
