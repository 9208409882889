import { ReactElement, ReactNode } from "react";

type Props = {
  label: ReactNode;
  children: ReactNode;
  type?: "block" | "inline" | "column";
};

export function NmbsTravelPassItem({
  label,
  type = "column",
  children,
}: Props): ReactElement {
  return (
    <div className="mobea__nmbs-ticket__item">
      <p className={`mobea__nmbs-ticket__item__label ${type}`}>{label}</p>
      <p className={`mobea__nmbs-ticket__item__value ${type}`}>{children}</p>
    </div>
  );
}
