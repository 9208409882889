import { FieldLabel, MobeaButton } from "common/forms";
import { useBooleanState, useCustomerData } from "common/hooks";
import { useModal } from "common/hooks/useModal";
import { useContactUsModal } from "common/modal";
import { FullScreenPage } from "common/page/FullScreenPage";
import { PasswordInput } from "common/passwordInput/PasswordInput";
import { usePhoneNumbersValidation } from "common/phoneNumberPicker";
import { PhoneNumberPicker } from "common/phoneNumberPicker/PhoneNumberPicker";
import { LogoWithLangHeader } from "pages/setPassword/LogoWithLangHeader";
import { useSomethingWentWrongModal } from "pages/setPassword/useSomethingWentWrongModal";
import { FormEvent, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Auth } from "services/authTokenStore";
import { setCustomerIdAction } from "state/actions";
import { ApiErrors, removeSpaces, Routes } from "utils";
import { AppColors } from "utils/colors";
import { LoginTitle } from "./LoginTitle";

export function LoginPage(): ReactElement {
  const { t } = useTranslation();

  const [password, setPassword] = useState("");

  const [loggingIn, setLoggingIn] = useState(false);

  const history = useHistory();

  const [
    prefix,
    localNumberFormatted,
    valid,
    onPrefixChange,
    updatePhoneNumber,
  ] = usePhoneNumbersValidation();

  const dispatch = useDispatch();

  const { show: showContactUsModal } = useContactUsModal();

  const notActiveModal = useModal({
    cancelText: t("log_in.need_help"),
    cancelButtonType: "secondary",
    confirmText: t("shared.ok"),
    title: t("shared.sorry_but"),
    type: "error",
    onCancel: showContactUsModal,
    children: <p>{t("verification.error_client_inactive_text")}</p>,
  });

  const wrongPhoneOrPwErrorModal = useModal({
    confirmText: t("shared.ok"),
    cancelText: t("log_in.need_help"),
    cancelButtonType: "secondary",
    title: t("shared.sorry_but"),
    type: "error",
    onCancel: showContactUsModal,
    children: <p>{t("log_in.wrong_phone_or_password")}</p>,
  });

  const setPasswordErrorModal = useSomethingWentWrongModal();

  const [loggedIn, setLoggedIn] = useBooleanState();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    setLoggingIn(true);

    Auth.logIn(prefix.code, removeSpaces(localNumberFormatted), password)
      .then((body) => {
        if (
          body.error_code === ApiErrors.NO_ACTIVE_PLAN ||
          body.error_code === ApiErrors.CLIENT_NOT_ACTIVE
        ) {
          notActiveModal.show();
        } else if (body.error_code === ApiErrors.CUSTOMER_PW_NOT_SET) {
          missingPasswordModal.show();
        } else if (
          body.error_code === ApiErrors.WRONG_PASSWORD ||
          body.error_code === ApiErrors.CUSTOMER_DONT_EXIST
        ) {
          wrongPhoneOrPwErrorModal.show();
        } else if (body.error_code) {
          setPasswordErrorModal.show();
        } else {
          const customerId = Auth.getCustomerId();

          if (!customerId) {
            throw new Error("missing customerId");
          }

          dispatch(setCustomerIdAction(customerId));

          setLoggedIn();
        }
      })
      .catch((e) => {
        console.error(e);

        setPasswordErrorModal.show();
      })
      .finally(() => {
        setLoggingIn(false);
      });
  };

  const loading = useCustomerData();

  // redirect only after we have the user, otherwise pending tutorial won't start
  useEffect(() => {
    if (!loading && loggedIn) {
      history.replace(Routes.Default);
    }
  }, [history, loading, loggedIn]);

  const missingPasswordModal = useModal({
    className: "missing-pw-modal",
    title: t("log_in.missing_password_title"),
    type: "error",
    cancelButtonType: "secondary",
    confirmText: t("log_in.set_password"),
    cancelText: t("log_in.need_help"),
    onCancel() {
      showContactUsModal();
    },
    onConfirm() {
      history.push(Routes.ForgottenPassword);
    },
    children: t("log_in.missing_password_body"),
  });

  return (
    <FullScreenPage
      pageClassName="m_login"
      padding={{
        left: 32,
        right: 32,
      }}
    >
      <LogoWithLangHeader />

      <form
        onSubmit={handleSubmit}
        css={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
      >
        <div
          css={{
            flexGrow: 1,
            justifyContent: "flex-end",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <LoginTitle title={t("log_in.title")} text={t("log_in.text")} />

          <FieldLabel>{t("shared.phone_number")}</FieldLabel>

          <PhoneNumberPicker
            prefix={prefix}
            localNumberFormatted={localNumberFormatted}
            valid={valid}
            onPrefixChange={onPrefixChange}
            onPhoneNumberChange={updatePhoneNumber}
          />

          <div
            css={{
              height: 32,
            }}
          ></div>

          <FieldLabel>{t("log_in.password_placeholder")}</FieldLabel>

          <PasswordInput
            variant="outlined"
            value={password}
            onChange={(value) => setPassword(value)}
            noCheck
          />
        </div>

        <div
          css={{
            flexGrow: 1,
            justifyContent: "flex-end",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <MobeaButton disabled={!valid || !password} loading={loggingIn}>
            {t("log_in.log_in")}
          </MobeaButton>
          {/*<MobeaButton type="secondary" onClick={() => history.push(Routes.Register)}>*/}
          {/*  {t("log_in.register")}*/}
          {/*</MobeaButton>*/}
          <Link
            css={{
              marginTop: "1.5rem",
              display: "block",
              fontSize: "0.875rem",
              textDecoration: "none",
              textAlign: "center",
              color: AppColors.PRIMARY,
              fontWeight: "bold",
            }}
            to={Routes.ForgottenPassword}
          >
            {t("log_in.forgot_pw")}
          </Link>
        </div>
      </form>
    </FullScreenPage>
  );
}
