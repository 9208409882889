import styled from "@emotion/styled";
import { BirdLogo } from "icons/logos/BirdLogo";
import { DeLijnLogo } from "icons/logos/DeLijnLogo";
import { LimeLogo } from "icons/logos/LimeLogo";
import { NmbsLogo } from "icons/logos/NmbsLogo";
import { PoppyLogo } from "icons/logos/PoppyLogo";
import { VeloAntwerpenLogo } from "icons/logos/VeloAntwerpsLogo";
import { useTranslation } from "react-i18next";
import { AppColors } from "utils/colors";

const Item = styled.div({
  display: "flex",
  width: "100%",
  flexDirection: "column",
  alignItems: "center",
  height: "4rem",
  "& :first-child": {
    flexGrow: 1,
    width: "3rem",
    marginBottom: "0.5rem",
    display: "flex",
    alignItems: "center",
    "& > *": {
      maxHeight: "2rem",
    },
  },
  "& :last-child": {
    color: AppColors.GRAY_300,
    fontSize: "0.75rem",
  },
});

export function Step4() {
  const { t } = useTranslation();

  return (
    <>
      <h1>{t("moveasy_tutorial.step4_title")}</h1>

      <p>{t("moveasy_tutorial.step4_text")}</p>

      <div
        css={{
          animation: "fadeIn 200ms",
          margin: "1.5rem 1.5rem 0 1.5rem",
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "1.5rem calc(0rem + 10vw)",
          justifyItems: "center",
          alignItems: "center",
        }}
      >
        <Item>
          <div>
            <BirdLogo />
          </div>
          <div>Kickscooter</div>
        </Item>
        <Item>
          <div>
            <DeLijnLogo />
          </div>
          <div>Bus / tram</div>
        </Item>
        <Item>
          <div>
            <LimeLogo />
          </div>
          <div>Kickscooter</div>
        </Item>
        <Item>
          <div>
            <NmbsLogo />
          </div>
          <div>Train</div>
        </Item>
        <Item>
          <div>
            <PoppyLogo />
          </div>
          <div>Car / E-Scooter</div>
        </Item>
        <Item>
          <div>
            <VeloAntwerpenLogo />
          </div>
          <div>Bike</div>
        </Item>
      </div>
    </>
  );
}
