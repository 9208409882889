import { AppLoader } from "common/AppLoader";
import { OnboardingGuardEA } from "guards/OboardingGuardEA";
import { OnboardingGuardMoveasy } from "guards/OnboardingGuardMoveasy";
import { BlueBikeMultiTicketDetail } from "pages/blueBike/detail/BlueBikeMultiTicketDetail";
import { BlueBikeTicketDetail } from "pages/blueBike/detail/BlueBikeTicketDetail";
import { BlueBikeMapPage } from "pages/blueBike/map/BlueBikeMapPage";
import { BlueBikeOrderPage } from "pages/blueBike/order/BlueBikeOrderPage";
import { TermsAndConditionsPage } from "pages/conditions/TermsAndConditionsPage";
import { CookiesPage } from "pages/cookies/CookiesPage";
import { DeLijnTicketDetail } from "pages/delijn/detail/DeLijnTicketDetail";
import { DelijnOrderPage } from "pages/delijn/order/DelijnOrderPage";
import { AddExpensePage } from "pages/expense/add/AddExpensePage";
import { ExpenseDetailPage } from "pages/expense/detail/ExpenseDetailPage";
import { MapPage } from "pages/map/MapPage";
import { MobitTicketDetail } from "pages/mobit/detail/MobitTicketDetail";
import { MobitMap } from "pages/mobit/map/MobitMap";
import { MobitOrderPage } from "pages/mobit/order/MobitOrderPage";
import { NmbsMultiTicketDetail } from "pages/nmbs/detail/NmbsMultiTicketDetail";
import { NmbsTicketDetail } from "pages/nmbs/detail/NmbsTicketDetail";
import { NmbsOrderPage } from "pages/nmbs/order/NmbsOrderPage";
import { ProvidersPage } from "pages/providers/ProvidersPage";
import { TutorialsPage } from "pages/tutorials/TutorialsPage";
import { VeloMultiTicketDetail } from "pages/velo/detail/VeloMultiTicketDetail";
import { VeloTicketDetail } from "pages/velo/detail/VeloTicketDetail";
import { VeloMap } from "pages/velo/map/VeloMap";
import { VeloOrderPage } from "pages/velo/VeloOrderPage";
import { VertsMap, VertsOrderPage, VertsTicketDetail } from "pages/verts";
import { Route } from "react-router-dom";
import { Routes } from "utils";

export const RoutesSharedPublic = [
  <Route key={Routes.TermsAndConditions} path={Routes.TermsAndConditions}>
    <TermsAndConditionsPage />
  </Route>,
  <Route key={Routes.Reload} path={Routes.Reload}>
    <AppLoader />
  </Route>,

  /* Redirect user to welcome, dashboard or tutorial when he opens app based on onboarding state */

                                                        
  <Route key="OnboardingGuard">
    <OnboardingGuardMoveasy />
  </Route>,
            

                          
                               
                         
           
            
];

function getRoutesSharedSecured(queryParams: Map<string, any>) {
  return [
    <Route key={Routes.Map} path={Routes.Map}>
      <MapPage />
    </Route>,
    <Route key={Routes.GetTravelPass} path={Routes.GetTravelPass}>
      <ProvidersPage />
    </Route>,
    <Route key={Routes.ExpenseAdd} path={Routes.ExpenseAdd}>
      <AddExpensePage />
    </Route>,
    <Route key={Routes.ExpenseDetail} path={Routes.ExpenseDetail}>
      <ExpenseDetailPage />
    </Route>,
    <Route
      key={Routes.HistoryExpensesDetail}
      path={Routes.HistoryExpensesDetail}
    >
      <ExpenseDetailPage backButtonBehaviour="goBack" />
    </Route>,
    <Route key={Routes.DeLijnOrder} path={Routes.DeLijnOrder}>
      <DelijnOrderPage />
    </Route>,
    <Route key={Routes.DeLijnTicketDetail} path={Routes.DeLijnTicketDetail}>
      <DeLijnTicketDetail />
    </Route>,
    <Route
      key={Routes.DeLijnTicketCostDetail}
      path={Routes.DeLijnTicketCostDetail}
    >
      <DeLijnTicketDetail backButtonBehaviour="goBack" />
    </Route>,
    <Route key={Routes.NmbsOrder} path={Routes.NmbsOrder}>
      <NmbsOrderPage />
    </Route>,
    <Route key={Routes.NmbsTicketDetail} path={Routes.NmbsTicketDetail}>
      <NmbsTicketDetail />
    </Route>,
    <Route
      key={Routes.NmbsMultiTicketDetail}
      path={Routes.NmbsMultiTicketDetail}
    >
      <NmbsMultiTicketDetail
        backButtonBehaviour={queryParams.get("back") || "goHome"}
      />
    </Route>,
    <Route key={Routes.NmbsTicketCostDetail} path={Routes.NmbsTicketCostDetail}>
      <NmbsTicketDetail backButtonBehaviour="goBack" />
    </Route>,
    <Route key={Routes.MobitOrder} path={Routes.MobitOrder}>
      <MobitOrderPage />
    </Route>,
    <Route key={Routes.MobitTicketDetail} path={Routes.MobitTicketDetail}>
      <MobitTicketDetail backButtonBehaviour={queryParams.get("back")} />
    </Route>,
    <Route
      key={Routes.MobitTicketCostDetail}
      path={Routes.MobitTicketCostDetail}
    >
      <MobitTicketDetail backButtonBehaviour="goBack" />
    </Route>,
    <Route key={Routes.MobitStartRide} path={Routes.MobitStartRide}>
      <MobitMap initialIntent="start" />
    </Route>,
    <Route key={Routes.MobitManageRide} path={Routes.MobitManageRide}>
      <MobitMap initialIntent="unknown" />
    </Route>,
    <Route key={Routes.VertsOrder} path={Routes.VertsOrder}>
      <VertsOrderPage />
    </Route>,
    <Route key={Routes.VertsTicketDetail} path={Routes.VertsTicketDetail}>
      <VertsTicketDetail
        backButtonBehaviour={queryParams.get("back") || "goHome"}
      />
    </Route>,
    <Route key={Routes.VertsMap} path={Routes.VertsMap}>
      <VertsMap backButtonBehavior={queryParams.get("back")} />
    </Route>,
    <Route key={Routes.VeloOrder} path={Routes.VeloOrder}>
      <VeloOrderPage />
    </Route>,
    <Route key={Routes.VeloTicketDetail} path={Routes.VeloTicketDetail}>
      <VeloTicketDetail
        backButtonBehaviour={queryParams.get("back") || "goHome"}
      />
    </Route>,
    <Route
      key={Routes.VeloMultiTicketDetail}
      path={Routes.VeloMultiTicketDetail}
    >
      <VeloMultiTicketDetail
        backButtonBehaviour={queryParams.get("back") || "goHome"}
      />
    </Route>,
    <Route key={Routes.VeloMap} path={Routes.VeloMap}>
      <VeloMap />
    </Route>,
    <Route key={Routes.BlueBikeOrder} path={Routes.BlueBikeOrder}>
      <BlueBikeOrderPage />
    </Route>,

    <Route key={Routes.BlueBikeTicketDetail} path={Routes.BlueBikeTicketDetail}>
      <BlueBikeTicketDetail
        backButtonBehaviour={queryParams.get("back") || "goHome"}
      />
    </Route>,

    <Route
      key={Routes.BlueBikeMultiTicketDetail}
      path={Routes.BlueBikeMultiTicketDetail}
    >
      <BlueBikeMultiTicketDetail
        backButtonBehaviour={queryParams.get("back") || "goHome"}
      />
    </Route>,

    <Route key={Routes.BlueBikeMap} path={Routes.BlueBikeMap}>
      <BlueBikeMapPage />
    </Route>,
    <Route key={Routes.Cookies} path={Routes.Cookies}>
      <CookiesPage />
    </Route>,
    <Route key={Routes.Tutorials} path={Routes.Tutorials}>
      <TutorialsPage />
    </Route>,
  ];
}

export function getRoutesSharedAll(queryParams: Map<string, any>) {
  return getRoutesSharedSecured(queryParams).concat(RoutesSharedPublic);
}
