import { DefaultIconProps } from "icons/props";
import { ReactElement } from "react";

export function ReceiptIcon({
  className = "",
  fill = "currentColor",
  height = "80",
  width = "80",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 80 80"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="none" d="M0 0h80v80H0z" />
      <path
        d="M65 11.67l-5-5-5 5-5-5-5 5-5-5-5 5-5-5-5 5-5-5v46.66H10v10c0 5.54 4.47 10 10 10h40c5.53 0 10-4.46 10-10V6.67zm-1.67 51.66c0 1.84-1.5 3.34-3.33 3.34a3.34 3.34 0 01-3.33-3.34v-10h-30V16.67h36.66z"
        fill={fill}
      />
      <path
        fill={fill}
        d="M30 23.33h20V30H30zM53.33 23.33H60V30h-6.67zM30 33.33h20V40H30zM53.33 33.33H60V40h-6.67z"
      />
    </svg>
  );
}
