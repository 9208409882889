import { CheckboxGroup, MobeaButton } from "common/forms";
import { useBooleanState } from "common/hooks";
import { MobeaModal } from "common/modal";
import { NestedPageOverlay } from "common/page/NestedPageOverlay";
import { ProviderLogo } from "common/travelPasses";
import { useProviderTerms } from "pages/conditions/hooks/useProviderTerms";
import { ReactElement, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { TravelPassProvider } from "utils";
import "./ProviderTermsAndConditions.scss";

type Props = {
  className?: string;
  title: string;
  provider: TravelPassProvider;
  description: string;
  termsExternalLink: string;
};

export function ProviderTermsAndConditions({
  className = "",
  title,
  provider,
  description,
  termsExternalLink,
}: Props): ReactElement | null {
  const { t } = useTranslation();

  const allProviders = useSelector((state) => state.passes.providers);

  const travelProvider = allProviders.find(
    (fullProvider) => fullProvider.provider === provider
  );

  const [acceptChecked, setAcceptChecked] = useState(false);

  const [termsAccepted, accept, accepting, error] = useProviderTerms(provider);

  const [errorDialogVisible, showErrorDialog, hideErrorDialog] =
    useBooleanState();

  useEffect(() => {
    if (error) {
      showErrorDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  if (termsAccepted) {
    return null;
  }

  return (
    <NestedPageOverlay
      className={`provider-terms-conditions ${className}`}
      pageClassName="provider-terms-conditions__page"
      title={title}
      headerHeight="compact"
    >
      <ProviderLogo provider={provider} />

      <section className="provider-terms-conditions__main">
        <div
          className="provider-terms-conditions__main__title"
          css={{ color: travelProvider?.textColor }}
        >
          {t(`providers_names.${provider}`)}
        </div>

        <div className="provider-terms-conditions__main__description">
          {description}
        </div>

        <div className="provider-terms-conditions__main__spacer" />

        <CheckboxGroup
          className="provider-terms-conditions__main__checkbox"
          name="accept-terms-check"
          label={
            <Trans i18nKey="providers.accept_terms">
              <a
                href={termsExternalLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Accept
              </a>{" "}
              {{ provider: t(`providers_names.${provider}`) }}
            </Trans>
          }
          isChecked={acceptChecked}
          onChange={setAcceptChecked}
        />

        <MobeaButton
          disabled={!acceptChecked || accepting}
          onClick={accept}
          loading={accepting}
        >
          {accepting ? t("shared.in_progress") : t("shared.continue")}
        </MobeaButton>
      </section>

      {errorDialogVisible && (
        <MobeaModal
          type="error"
          confirmText={t("shared.ok")}
          title={t("shared.oops")}
          onConfirm={hideErrorDialog}
        >
          {t("shared.network_error_text")}
        </MobeaModal>
      )}
    </NestedPageOverlay>
  );
}
