import { History } from "history";
import {
  ADMIN_API_BASE,
  ApiErrorResponse,
  CORE_API_BASE,
  WalletType,
} from "utils";
import { callSecuredApiEndpoint } from "./apiCall";

export type DeLijnOrderDataResponse =
  | {
      error: false;
      ok: boolean;
      ticketTypes: {
        productType: "TICKET";
        sku: string; // "MT60"
        tariff: number;
        ticketTypeId: number; //4007
        ticketTypeName: string; // MT60
        validFrom: string; // date string
        validTo?: string;
        validityMinutes: number;
        validityUnit: "MINUTE";
        validityValue: number;
      }[];
    }
  | ApiErrorResponse;

function getDeLijnOrderDataRequest(authorization: string) {
  return fetch(`${CORE_API_BASE}providers/delijn/tickets-pricing`, {
    method: "GET",
    headers: new window.Headers({
      Authorization: authorization,
    }),
  }).then((response) => response.json()) as Promise<DeLijnOrderDataResponse>;
}

export function getDeLijnOrderData(history: History) {
  return callSecuredApiEndpoint(getDeLijnOrderDataRequest, history);
}

export interface RawDeLijnSingleTicket {
  ok: boolean;
  txnId: string;
  msgId: string;
  ticketTypeCode: "MT60";
  ticketTypeName: "MT60";
  originatingAddress: string;
  // "10/A8 Geldig op alle voertuigen van De Lijn tot 15u02 op 06/04/2020. Prijs: 1,80 euro 140207P06nw921A8e167228"
  message: string;
  serial: string;
  validityStart: string; // Datetime
  validityEnd: string; // Datetime
  checksum: string;
  quickChecksum: string; // "10/A8"
  daycode: string;
  msisdn: string; // phone number
  tariff: number; // price in cents?
  transaction_id: string;
}

export type DeLijnOrderSingleResponse =
  | ({
      error: false;
    } & RawDeLijnSingleTicket)
  | ApiErrorResponse;

export interface DeLijnTicketOrderPayload {
  tariff: number;
  ticketTypeId: number;
  wallet: WalletType;
}

function orderDeLijnSingleTicketRequest(
  orderPayload: DeLijnTicketOrderPayload
) {
  return (authorization: string) =>
    fetch(`${ADMIN_API_BASE}delijn/orders`, {
      method: "POST",
      headers: new window.Headers({
        "Content-Type": "application/json",
        Authorization: authorization,
      }),
      body: JSON.stringify({
        data: {
          plan_type: orderPayload.wallet,
          tariff: orderPayload.tariff,
          ticket_type_id: orderPayload.ticketTypeId,
        },
      }),
    })
      .then((response) => response.json())
      .then((json) => ({
        ...json,
        error: !!json.error_code,
      })) as Promise<DeLijnOrderSingleResponse>;
}

export function orderDeLijnSingleTicket(
  orderPayload: DeLijnTicketOrderPayload,
  history: History
) {
  return callSecuredApiEndpoint(
    orderDeLijnSingleTicketRequest(orderPayload),
    history
  );
}

function acknowledgeDeLijnTicketDeliveryRequest(msgId: string, txnId: string) {
  return (authorization: string) =>
    fetch(`${CORE_API_BASE}delijn/orders/${msgId}?txnId=${txnId}`, {
      method: "GET",
      headers: new window.Headers({
        Authorization: authorization,
      }),
    }).then((response) => response.json()) as Promise<any>;
}

export function acknowledgeDeLijnTicketDelivery(
  msgId: string,
  txnId: string,
  history: History
) {
  return callSecuredApiEndpoint(
    acknowledgeDeLijnTicketDeliveryRequest(msgId, txnId),
    history
  );
}
