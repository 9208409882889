import { MobeaButton, ReactCodeInput } from "common/forms";
import { useQrReader } from "common/hooks/useQrReader";
import { NestedPageOverlay } from "common/page/NestedPageOverlay";
import { QrCodeIcon } from "icons/QrCodeIcon";
import { TorchIcon } from "icons/TorchIcon";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { mobea, Routes } from "utils";
import { MobitBikeLockStatus, useBikeLockUpdate } from "../hooks";
import { useMobitOpenBike } from "../hooks/useMobitOpenBike";
import "./MobitUnlockBike.scss";
import { UnlockBikeDialogs } from "./UnlockBikeDialogs";

const noop = () => undefined;

export interface MobitUnlockBikePageProps {
  bikeCode?: string;
  goToTicket(): void;
  goToScanCode(): void;
  goBack(): void;
}

export function MobitUnlockBikePage({
  bikeCode = "",
  goToTicket,
  goToScanCode,
  goBack,
}: MobitUnlockBikePageProps): ReactElement {
  const history = useHistory();

  const { t } = useTranslation();

  // if bike code is passed we resume ride so disable changes to input
  const [bikeToOpen, setBikeToOpen] = useState<{ code: string } | null>(null);

  const [code, setCode] = useState(bikeCode);

  const [codeValid, setCodeValid] = useState(
    bikeCode ? bikeCode.length === 10 : false
  );

  const [keyboardOpened, setKeyboardOpened] = useState(false);

  const [openResult, openFailed, openLoading, openFailureCode] =
    useMobitOpenBike(bikeToOpen);

  const [pendingUpdate] = useBikeLockUpdate(
    openResult?.ok ? code : null,
    5000,
    MobitBikeLockStatus.CLOSED
  );

  const [errorDialogNotClosed, setErrorDialogNotClosed] = useState(true);

  const [, , flashAvailable, , , toggleLight] = useQrReader(
    "hidden-video-element",
    noop,
    false
  );

  const unlockBike = () => {
    setBikeToOpen({ code });
    setErrorDialogNotClosed(true);
  };

  const closeError = () => setErrorDialogNotClosed(false);

  const buyDayPass = () => history.push(Routes.MobitOrder);

  const openRequested = !!openResult && openResult.ok;

  const waitingForUpdate = openRequested && pendingUpdate;

  const isMobile = mobea.isAndroid || mobea.isIos;

  return (
    <NestedPageOverlay
      pageClassName="mobea-mobit-unlock"
      title={t("mobit_unlock.activate_your_bike")}
      headerHeight="minimal"
      onNavBack={goBack}
    >
      <section className="mobea-mobit-unlock__code">
        <p className="mobea-mobit-unlock__code__text">
          {t("mobit_unlock.activate_your_bike_text")}
        </p>
        <ReactCodeInput
          autoFocus={false}
          values={code.split("")}
          type="number"
          className="mobea-mobit-unlock__code__input"
          fields={10}
          title=""
          inputMode="numeric"
          fieldWidth={25}
          fieldHeight={50}
          disabled={!!bikeCode}
          onChange={(value) => {
            setCode(value);
            codeValid && setCodeValid(false);
          }}
          onComplete={() => {
            setCodeValid(true);
          }}
          onFocus={() => setKeyboardOpened(true)}
          onBlur={() => setKeyboardOpened(false)}
        />
      </section>
      <section className="mobea-mobit-unlock__image">
        <div className="mobea-mobit-scan__preview__controls">
          <button
            className="mobea-mobit-scan__preview__controls__button"
            disabled={!flashAvailable}
            onClick={toggleLight}
          >
            <TorchIcon />
          </button>
          <button
            className="mobea-mobit-scan__preview__controls__button"
            onClick={goToScanCode}
          >
            <QrCodeIcon />
          </button>
        </div>
        <img
          src="/static/images/mobit-bike.png"
          className={keyboardOpened && isMobile ? "hidden" : ""}
          alt="bike image"
        />
        <div className="mobea-mobit-unlock__image__empty-space"></div>
        <MobeaButton
          className="mobea-mobit-unlock__image__unlock"
          disabled={!codeValid || openLoading || waitingForUpdate}
          loading={openLoading || waitingForUpdate}
          onClick={unlockBike}
        >
          {openLoading || waitingForUpdate
            ? t("shared.in_progress")
            : t("mobit_unlock.activate_bike")}
        </MobeaButton>
      </section>
      <video id="hidden-video-element"></video>
      <UnlockBikeDialogs
        bikeOpened={openRequested && !pendingUpdate}
        errorDialogNotClosed={errorDialogNotClosed}
        openFailed={openFailed}
        openFailureCode={openFailureCode}
        buyDayPass={buyDayPass}
        goToTicket={goToTicket}
        closeError={closeError}
      />
    </NestedPageOverlay>
  );
}
