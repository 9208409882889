import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

export function WalkIcon({
  className = "",
  fill = "currentColor",
  height = "20",
  width = "20",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.25 4.583c.917 0 1.667-.75 1.667-1.666 0-.917-.75-1.667-1.667-1.667S9.583 2 9.583 2.917c0 .916.75 1.666 1.667 1.666zM8.167 7.417l-2.334 11.75h1.75l1.5-6.667 1.75 1.667v5H12.5v-6.25l-1.75-1.667.5-2.5a6.102 6.102 0 004.583 2.083V9.167c-1.583 0-2.916-.834-3.583-2l-.833-1.334C11.083 5.333 10.583 5 10 5c-.25 0-.417.083-.667.083L5 6.917v3.916h1.667V8l1.5-.583"
        fill={fill}
      />
    </svg>
  );
}
