import { usePreviousValue, useTicketDetail } from "common/hooks";
import { NestedPage } from "common/page/NestedPage";
import { Spinner } from "common/Spinner";
import { VertsTravelPass } from "common/travelPasses/verts/VertsTravelPass";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { VertsTravelPass as VertsTravelPassType } from "state/actions";
import {
  pushLocation,
  Routes,
  VERTS_PASS_REFRESH_INTERVAL,
  VERTS_REFRESHABLE_STATUSES,
} from "utils";
import { BackButtonBehaviour } from "utils/routing";
import { VertsStatus } from "utils/VertsStatus";

type Props = {
  backButtonBehaviour?: BackButtonBehaviour;
};

export function VertsTicketDetail({
  backButtonBehaviour = "goHome",
}: Props): ReactElement {
  const history = useHistory();

  const { id = "" } = useParams<{ id: string }>();

  const { t } = useTranslation();

  const decodedId = decodeURIComponent(id);

  const [loadedTravelPass, loadingFailed, loadingDetail, , reload] =
    useTicketDetail<VertsTravelPassType>(decodedId !== "" ? decodedId : null);

  const locale = useSelector((state) => state.user.language);

  const { active, purchases } = useSelector((state) => state.passes);

  const currentPass =
    loadedTravelPass ||
    (active.concat(purchases).find((pass) => pass.id === decodedId) as
      | VertsTravelPassType
      | undefined);
  const previousCurrentPass = usePreviousValue(currentPass);

  const [driverFound, setDriverFound] = useState(false);

  // refresh ticket in intervals to see the latest status
  useEffect(() => {
    if (
      !currentPass ||
      !VERTS_REFRESHABLE_STATUSES.includes(currentPass.status)
    ) {
      return;
    }

    const intervalHandle = window.setInterval(
      reload,
      VERTS_PASS_REFRESH_INTERVAL
    );

    return () => {
      window.clearInterval(intervalHandle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPass?.status]);

  // set driver found when on screen and searching was previous status
  useEffect(() => {
    if (
      previousCurrentPass?.status === VertsStatus.Searching &&
      currentPass?.status === VertsStatus.Arriving
    ) {
      setDriverFound(true);

      const timeoutHandle = window.setTimeout(() => {
        setDriverFound(false);
      }, 5000);

      return () => {
        window.clearTimeout(timeoutHandle);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPass]);

  const goBack = () => {
    if (backButtonBehaviour === "goHome") {
      pushLocation(history, Routes.Home);
    } else {
      history.goBack();
    }
  };

  return (
    <NestedPage
      pageClassName="verts-ticket-detail"
      title={t("ticked_detail.my_ticket")}
      padding={{
        left: 16,
        right: 16,
        bottom: 16,
      }}
      background="default"
      onNavBack={goBack}
    >
      {loadingDetail && !currentPass && <Spinner />}
      {currentPass && (
        <VertsTravelPass
          {...currentPass}
          locale={locale}
          status={driverFound ? VertsStatus.DriverFound : currentPass.status}
          refresh={reload}
          backButtonBehaviour={
            backButtonBehaviour === "goBack" ? "goBack" : "goTicket"
          }
        />
      )}
      {!loadingDetail && loadingFailed && !currentPass && (
        <h2>Sorry cannot find travel pass.</h2>
      )}
    </NestedPage>
  );
}
