import { LocationSearchPlace } from "services/mapService";
import { TravelPassProvider } from "utils";

export interface DeLijnSingleTicket {
  amount: number;
  price: number;
  tariff: number;
  duration: number;
  ticketTypeId: number;
}

export const setDeLijnSingleTicketDataAction = (data: DeLijnSingleTicket) => ({
  type: "SET_DE_LIJN_SINGLE_TICKET_DATA" as const,
  data,
});

export interface NmbsStation {
  id: number;
  language: string;
  label: string;
}

export const setNmbsStationsAction = (stations: NmbsStation[]) => ({
  type: "SET_NMBS_STATIONS" as const,
  stations,
});

export interface MobitDayPassData {
  price: number;
  duration: number; // hours
  dayLimit: number;
}

export const setMobitPricingAction = (pricing: MobitDayPassData) => ({
  type: "SET_MOBIT_PRICING" as const,
  pricing,
});

export const setVertsLastLocationsAction = (
  origin: LocationSearchPlace,
  destination: LocationSearchPlace
) => ({
  type: "SET_VERTS_LAST_LOCATIONS" as const,
  origin,
  destination,
});

export const providerTermsAcceptedAction = (
  providers: Partial<Record<TravelPassProvider, boolean>>
) => ({
  type: "SET_TERMS_ACCEPTED" as const,
  providers,
});

export interface VeloData {
  minPasses: number;
  maxPasses: number;
  price: number;
  duration: number;
  validity: number;
  loaded: boolean;
}

export const setVeloDataAction = (data: VeloData) => ({
  type: "SET_VELO_DATA" as const,
  data,
});

export interface BlueBikeData {
  minPasses: number;
  maxPasses: number;
  price: number;
  validity: number;
  loaded: boolean;
  returnTime: number;
}

export const setBlueBikeDataAction = (data: BlueBikeData) => ({
  type: "SET_BLUE_BIKE_DATA" as const,
  data,
});

export type OrderingActions =
  | ReturnType<typeof setDeLijnSingleTicketDataAction>
  | ReturnType<typeof setNmbsStationsAction>
  | ReturnType<typeof setMobitPricingAction>
  | ReturnType<typeof setVertsLastLocationsAction>
  | ReturnType<typeof setBlueBikeDataAction>
  | ReturnType<typeof providerTermsAcceptedAction>
  | ReturnType<typeof setVeloDataAction>;
