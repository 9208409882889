import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

type Props = DefaultIconProps & {
  outlined?: boolean;
};

export function PhoneIcon({
  fill = "currentColor",
  className = "",
  height = "24",
  width = "24",
  outlined = false,
}: Props): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      {outlined ? (
        <path
          d="M6.54 5c.06.89.21 1.76.45 2.59l-1.2 1.2c-.41-1.2-.67-2.47-.76-3.79h1.51m9.86 12.02c.85.24 1.72.39 2.6.45v1.49c-1.32-.09-2.59-.35-3.8-.75l1.2-1.19M7.5 3H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.49c0-.55-.45-1-1-1-1.24 0-2.45-.2-3.57-.57a.84.84 0 00-.31-.05c-.26 0-.51.1-.71.29l-2.2 2.2a15.149 15.149 0 01-6.59-6.59l2.2-2.2c.28-.28.36-.67.25-1.02A11.36 11.36 0 018.5 4c0-.55-.45-1-1-1z"
          fill={fill}
        />
      ) : (
        <path
          d="M6.6 10.8c1.5 2.8 3.8 5.1 6.6 6.6l2.2-2.2a1 1 0 011-.3c1.2.4 2.4.6 3.6.6.6 0 1 .5 1 1V20c0 .6-.4 1-1 1A17 17 0 013 4c0-.5.5-1 1-1h3.5c.6 0 1 .5 1 1 0 1.3.2 2.5.6 3.6 0 .3 0 .7-.3 1l-2.2 2.2z"
          fill={fill}
        />
      )}
    </svg>
  );
}
