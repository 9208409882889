import { MobeaModal, MobeaModalProps } from "common/modal/MobeaModal";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

type MobeaModalPropsEx = MobeaModalProps & {
  confirmHides?: boolean;
  cancelHides?: boolean;
  showCloseButton?: boolean;
};

type ModalContextType = {
  setProps(id: number, props: MobeaModalPropsEx): void;
  destroy(id: number): void;
  setVisible(id: number, visible: boolean): void;
};

const ModalContext = createContext<ModalContextType | undefined>(undefined);

let counter = 1;

export function useModal(props: MobeaModalPropsEx) {
  const [id] = useState(counter++);

  const ctx = useContext(ModalContext);

  if (!ctx) {
    throw new Error("Missing ModalContext");
  }

  useEffect(() => {
    ctx.setProps(id, props);
  }, [ctx, id, props]);

  useEffect(() => {
    return () => {
      ctx.destroy(id);
    };
  }, [ctx, id]);

  return useMemo(
    () => ({
      show() {
        ctx.setVisible(id, true);
      },
      hide() {
        ctx.setVisible(id, false);
      },
    }),
    [ctx, id]
  );
}

type Props = {
  children: ReactNode;
};

export function ModalProvider({ children }: Props) {
  const [propsMap, setPropsMap] = useState(
    new Map<number, MobeaModalPropsEx>()
  );

  const [visibleIds, setVisibleIds] = useState<number[]>([]);

  const [visibleId, setVisibleId] = useState<number>();

  const [props, setProps] = useState<MobeaModalPropsEx>();

  const [timingOut, setTimingOut] = useState(false);

  function setVisible(id: number, visible: boolean) {
    setVisibleIds((ids) =>
      visible
        ? ids.includes(id)
          ? ids
          : [...ids, id]
        : ids.includes(id)
        ? ids.filter((id1) => id1 !== id)
        : ids
    );

    setVisibleId((prevVisibleId) =>
      !prevVisibleId
        ? visible
          ? id
          : undefined
        : prevVisibleId === id
        ? visible
          ? id
          : undefined
        : prevVisibleId
    );
  }

  const ctx: ModalContextType = useMemo(
    () => ({
      destroy(id) {
        setPropsMap((map) => {
          const m = new Map(map);
          m.delete(id);
          return m;
        });

        setVisibleId((prevId) => (prevId === id ? undefined : prevId));

        setVisibleIds((prevIds) =>
          prevIds.includes(id) ? prevIds.filter((id1) => id1 !== id) : prevIds
        );
      },

      setProps(id, props) {
        setPropsMap((map) => {
          const m = new Map(map);
          m.set(id, props);
          return m;
        });
      },

      setVisible,
    }),
    []
  );

  const tidRef = useRef<number | undefined>(undefined);

  useEffect(() => {
    if (timingOut) {
      return;
    } else if (visibleId) {
      setProps(propsMap.get(visibleId));
    } else {
      tidRef.current = window.setTimeout(() => {
        setProps(undefined);

        setTimingOut(false);

        tidRef.current = undefined;
      }, 200);

      setTimingOut(true);
    }
  }, [visibleId, propsMap, timingOut]);

  useEffect(
    () => () => {
      window.clearTimeout(tidRef.current);

      setTimingOut(false);

      tidRef.current = undefined;
    },
    []
  );

  const nextVisibleId =
    !props && visibleIds.length > 0 ? visibleIds[0] : undefined;

  useEffect(() => {
    if (nextVisibleId) {
      setVisibleId(nextVisibleId);
    }
  }, [nextVisibleId]);

  return (
    <ModalContext.Provider value={ctx}>
      {children}
      {props && (
        <MobeaModal
          {...props}
          onClose={
            props.showCloseButton
              ? () => {
                  props.onClose?.();

                  if (visibleId) {
                    setVisible(visibleId, false);
                  }
                }
              : props.onClose
          }
          onCancel={
            props.cancelHides !== false &&
            (props.onCancel || props.cancelText !== undefined)
              ? ((() => {
                  props.onCancel?.();

                  if (visibleId) {
                    setVisible(visibleId, false);
                  }
                }) as any)
              : props.onCancel
          }
          onConfirm={
            props.confirmHides !== false &&
            (props.onConfirm || props.confirmText !== undefined)
              ? ((() => {
                  props.onConfirm?.();

                  if (visibleId) {
                    setVisible(visibleId, false);
                  }
                }) as any)
              : props.onConfirm
          }
          visible={!timingOut && !!visibleId}
        />
      )}
    </ModalContext.Provider>
  );
}
