import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  getNmbsProducts,
  NmbsGetProductsPayload,
  NmbsProduct,
  NmbsTicket,
  NmbsTicketType,
  toNmbsTicket,
} from "services/nmbsService";

export function useNmbsGetTickets(
  productRequest: NmbsGetProductsPayload | null
) {
  const history = useHistory();

  const [result, setResult] = useState<NmbsTicket[]>([]);

  const [failed, setFailed] = useState(false);

  const [loading, setLoading] = useState(false);

  const [failureCode, setFailureCode] = useState("");

  useEffect(() => {
    let canceled = false;
    let isLoading = false;

    setFailureCode("");

    setFailed(false);

    const getTickets = async (productRequest: NmbsGetProductsPayload) => {
      const isUsableTicket = (product: NmbsProduct) =>
        Object.values(NmbsTicketType).includes(product.ProductId);

      try {
        setLoading(true);
        isLoading = true;

        const productsJson = await getNmbsProducts(productRequest, history);

        if (!productsJson.error) {
          if (!canceled) {
            setResult(
              productsJson.Product.filter(isUsableTicket).map(toNmbsTicket)
            );

            setLoading(false);
          }
        } else {
          if (!canceled) {
            setFailed(true);

            setLoading(false);

            setFailureCode(productsJson.error_code);
          }

          console.error(
            "Failed to fetch Nmbs tickets",
            productsJson.error_description
          );
        }
        isLoading = false;
      } catch (error) {
        if (!canceled) {
          setFailed(true);

          setLoading(false);
          isLoading = false;
        }

        console.error("Failed to fetch NMBS product pricing data", error);
      }
    };

    productRequest !== null && getTickets(productRequest);

    return () => {
      canceled = true;

      isLoading && console.debug("Canceled hook useNmbsCalcPrice");
    };
  }, [productRequest, history]);

  const resetResult = () => {
    setResult([]);

    setLoading(false);

    setFailed(false);

    setFailureCode("");
  };

  return [result, failed, loading, failureCode, resetResult] as const;
}
