import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getExpensesHistory } from "services/historyService";
import { Expense, setExpensesAction } from "state/actions";
import { normalizeExpense } from "utils/normalizers/expenseNormalizer";

const DEBOUNCE_INTERVAL = 30 * 1000; // update only after half minute

let lastCallTimestamp = 0;

export function useExpenses() {
  const history = useHistory();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [key, setKey] = useState(Date.now());

  useEffect(() => {
    let canceled = false;

    let loading = true;

    async function fetchPurchasedTravelPasses() {
      try {
        setLoading(true);

        const json = await getExpensesHistory(history);

        if (!json.error) {
          const normalizedExpenses: Expense[] = json.expenses.map(
            (rawExpense) =>
              normalizeExpense({
                ...rawExpense,
                attachments: [],
              })
          );

          if (!canceled) {
            dispatch(setExpensesAction(normalizedExpenses));

            setLoading(false);

            loading = false;
          }
        } else {
          if (!canceled) {
            setLoading(false);

            loading = false;
          }

          console.error(
            "Failed to fetch expense history",
            json.error_code,
            json.error_description
          );
        }
      } catch (error) {
        if (!canceled) {
          setLoading(false);
        }

        console.error("Failed to fetch expense history", error);
      }
    }

    if (Date.now() - lastCallTimestamp > DEBOUNCE_INTERVAL) {
      lastCallTimestamp = Date.now();

      fetchPurchasedTravelPasses();
    }

    return () => {
      canceled = true;

      if (loading) {
        console.debug("Canceled hook useExpenses");
      }
    };
  }, [key, dispatch, history]);

  const reload = () => setKey(Date.now());

  return [loading, reload] as const;
}
